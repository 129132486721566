import React from "react";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { removeProptechAccessToken, removeProptechLocalStorageUserData } from "../../../utils/session";
import HomeIcon from '../../../assets/Icons/icon-home.svg';
import logoutIcon from '../../../assets/Icons/icon-logout.svg';

function SignupDetailsNavbar() {
  const handleLogoutClick = () => {
    removeProptechAccessToken()
    removeProptechLocalStorageUserData()
    window.location.href = '/proptech/login';
  }

  return (
    <div className="w-full h-12 px-10 py-4 flex justify-between items-center bg-[#111D43] proptech-signu-nav fixed inset-x-0 w-full z-[99]">
      <section className="flex">
        <img src={HomeIcon} alt="Icon" />
        <div className="ml-7 font-normal text-xs leading-5 not-italic text-[#FFFFFF]">
          <span>Signup</span>
          <KeyboardArrowRightOutlinedIcon fontSize="small" />
          <span>Details</span>
        </div>
      </section>
      <div>
        <img src={logoutIcon} title="Log out" alt="Icon" width={16} height={18} className="cursor-pointer" onClick={handleLogoutClick} />
      </div>
    </div>
  )
}

export default SignupDetailsNavbar;
