export default function vsDashboard(
  initialState = {
    profile: [],
    isLoading: false,
    statistics: {
      onboardedProptechs: 0,
      rejectedProptechs: 0,
      favorite: 0,
      notFavorite: 0,
      totalProptechMeetings: 0,
      cancelProptechMeetings: 0,
    },
    chartData: {
      labels: [],
      proptechCounts: [],
      meetingCounts: [],
    },
  },
  action
) {
  switch (action.type) {
    case 'VS_PROFILE_SET':
      return {
        ...initialState,
        profile: action.payload,
      };

    case 'VS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'VS_SET_STATISTICS':
      return {
        ...initialState,
        statistics: action.payload,
      };
    case 'VS_SET_CHART':
      return {
        ...initialState,
        chartData: action.payload,
      };

    case 'VS_DASHBOARD_RESET':
      return {
        ...initialState,
        profile: [],
        isLoading: false,
      };

    default:
      return initialState;
  }
}
