import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moreIcon from '../../assets/Icons/ic-more.svg';

export default function MenuButton(props) {
  //  ***** props *****
  const {
    menuItems,
    id,
    btnid,
    handleOnClickEvent,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {/* <Tooltip title={"Notification settings"} style={{ display: showTooltip }}>
        <IconButton
          aria-label="more"
          id={btnid ? btnid : ""}
          className={open ? 'open-more' : ""}
          aria-controls={open ? 'more-btn' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img src={moreIcon} alt="Icon" />
        </IconButton>
      </Tooltip> */}
      <IconButton
        aria-label="more"
        id={btnid ? btnid : ""}
        className={open ? 'open-more' : ""}
        aria-controls={open ? 'more-btn' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={moreIcon} alt="Icon" />
      </IconButton>
      <Menu
        id={id ? id : ""}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl ? anchorEl : null}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 12px 32px rgba(17, 29, 67, 0.12))',
            borderRadius: '8px',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleOnClickEvent(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}