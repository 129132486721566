export default function rffProptechDetails(
  initialState = {
    proptechDetails: null,
    overviewDetails: null,
    marketDetails: null,
    numbersDetails: null,
    deployAndUseDetails: null,
    designReviewDetails: null,
    marketInsightsDetails: null,
    companyProfileDetails: null,
    apiTimeout: {},
    keyAttributesDetails: null,
    pastProjects: null,
  },
  action
) {
  switch (action.type) {
    case "SET_RFF_PROPTECH_DETAILS":
      return {
        ...initialState,
        proptechDetails: action.payload,
      };

    case "SET_RFF_OVERVIEW_DETAILS":
      return {
        ...initialState,
        overviewDetails: action.payload,
      };

    case "SET_RFF_MARKET_DETAILS":
      return {
        ...initialState,
        marketDetails: action.payload,
      };

    case "SET_RFF_NUMBERS_DETAILS":
      return {
        ...initialState,
        numbersDetails: action.payload,
      };

    case "SET_RFF_DEPLOY_AND_USE_DETAILS":
      return {
        ...initialState,
        deployAndUseDetails: action.payload,
      };

    case "SET_RFF_DESIGNREVIEW_DETAILS":
      return {
        ...initialState,
        designReviewDetails: action.payload,
      };

    case "SET_RFF_MARKETINSIGHTS_DETAILS":
      return {
        ...initialState,
        marketInsightsDetails: action.payload,
      };

    case "SET_RFF_COMPANYPROFILE_DETAILS":
      return {
        ...initialState,
        companyProfileDetails: action.payload,
      };

    case "SET_RFF_APITIMEOUT":
      return {
        ...initialState,
        apiTimeout: { ...initialState.apiTimeout, ...action.payload },
      };

    case "SET_RFF_KEY_ATTRIBUTES_DETAILS":
      return {
        ...initialState,
        keyAttributesDetails: action.payload,
      };

    case "SET_RFF_PAST_PROJECT_DETAILS":
      return {
        ...initialState,
        pastProjects: action.payload,
      };

    case "RFF_PROPTECH_DETAILS_RESET":
      return {
        ...initialState,
        proptechDetails: null,
        overviewDetails: null,
        marketDetails: null,
        numbersDetails: null,
        deployAndUseDetails: null,
        designReviewDetails: null,
        marketInsightsDetails: null,
        companyProfileDetails: null,
        apiTimeout: {},
        keyAttributesDetails: null,
        pastProjects: null,
      };

    default:
      return initialState;
  }
}
