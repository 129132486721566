import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { teamTabDTO } from "../dtos/teamTab"
import { getKeyHireMappingsData, getNoOfEmployeesData, getTeamSkillsData } from "../dropdowns"

export const loadTeamsTabData = (id, adminAnalysisTab, adminDropdowns) => {
  if (!adminAnalysisTab.teamsTabData) {
    getTeamDetails(id)
  }
  loadTeamTabDropdowns(adminDropdowns.noOfEmployees, adminDropdowns.teamSkills, adminDropdowns.keyHireMappings)
}

export const getTeamDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/team`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetTeamsTabData, teamTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateTeamDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/team`, data)
    .then(res => {
      handleSuccess(res)
      getTeamDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const loadTeamTabDropdowns = (noOfEmployees, teamSkills, keyHireMappings) => {
  if (!noOfEmployees.length) {
    getNoOfEmployeesData();
  }
  if (!teamSkills.length) {
    getTeamSkillsData();
  }
  if (!keyHireMappings.length) {
    getKeyHireMappingsData();
  }
}