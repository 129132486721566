import React from 'react'
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import { VC_API } from '../../../utils/utility/API-call';
import { VC_MEETINGSBASE_API } from '../../../constants/APIs'; 
import PTButton from '../../../components/GenericComponents/PTButton'
import MenuButton from '../../../components/MenuButton';
import spleetImg from '../../../assets/Icons/img-spleet.jpg';
import watchIcon from '../../../assets/Icons/ic-watch.svg';
import { formatTime } from '../../../utils/utility/utility'
import { DateFormat, meetingStatus } from '../../../constants/General';

export default function MeetingCardUpcoming({ handleMeetingSchedule, event }) {
  const navigate = useNavigate();
  const redirectToProptechDetail = () => {
    navigate(`/investor/proptech/${event.proptechCompanyDetails.id}`)
  }

  // Link for meeting
  const redirectToMeeting = async () => {
    if (event.meetingLink) {
      await VC_API().put(`${VC_MEETINGSBASE_API}/${event.id}/join`);
      window.open(event?.meetingLink?.match(/^https?:/) ? event?.meetingLink : '//' + event?.meetingLink)
    }
  }

  return (
    <div className="event-item">
      <div className="left_col">
        <div className="nav-top flex">
          <i className="image-wrapper cursor-pointer" onClick={() => redirectToProptechDetail()}>
            <img src={event?.proptechCompanyDetails?.companyLogo || spleetImg} alt="Icon" />
          </i>
          <div className="card-info">
            <h2 className='cursor-pointer' onClick={() => redirectToProptechDetail()}>{event?.proptechCompanyDetails?.proptechCompany?.name || "-"}</h2>
            <ul className="listing-item">
              <li>
                <img src={watchIcon} alt="Icon" />
                {formatTime(event.startTime, DateFormat.DayMonthFormat4)}
              </li>
              <span className="ml-3 text-xs font-semibold">Scheduled by : {event.vcUserName}</span>
            </ul>
          </div>
        </div>
      </div>
      <div className="right_col">
        {[ meetingStatus.pending, meetingStatus.inProgress].includes(event.status) && moment().isBetween(moment(event.startTime).subtract(5, 'minutes'), moment(event.endTime)) && <PTButton label='Join zoom meeting' className="normal-case fill-orange btn small-size" handleOnClickEvent={redirectToMeeting} />}
        <PTButton label='View proptech details' className='min-w-[200px] normal-case fill-gray btn small-size' handleOnClickEvent={redirectToProptechDetail} />
        {event?.requestedRescheduleTime && event?.rescheduleMeetingCount === 1 && <div className="font-['Poppins'] text-xs text-[#70778E] mr-2 text-start">
          <div className='font-bold'>Requested Schedule Time:</div>
          <div>{formatTime(event.requestedRescheduleTime, DateFormat.DayMonthFormat4)}</div>
        </div>}
        <MenuButton
          btnid="more-menu"
          id="more-btn"
          anchorEl="anchorEl1"
          menuItems={['Reschedule', 'Cancel']}
          handleOnClickEvent={(option) => handleMeetingSchedule(option, event)}
        />
      </div>
    </div>
  )
}
