import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import * as Yup from 'yup';
import PTButton from '../../../components/GenericComponents/PTButton'
import LeftLogo from '../../../components/LeftLogo';
import PTInput from '../../../components/GenericComponents/PTInput';
import { startLoading, stopLoading } from '../../../utils/utility/loader';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { VS_RESET_PASSWORD_API } from '../../../constants/APIs';
import { ResetPasswordLabels } from '../../constants/general';
import { FORM_FIELDS } from '../../../vc/constants';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import '../../../components/ChangePassword/ChangePassword.scss';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(FORM_FIELDS.required)
        .min(6, FORM_FIELDS.sizeLimit.tooShortPassword)
        .matches(FORM_FIELDS.passwordRegex, FORM_FIELDS.sizeLimit.passwordContain),
      confirmPassword: Yup.string()
        .required(FORM_FIELDS.required)
        .oneOf([Yup.ref(FORM_FIELDS.password), null], FORM_FIELDS.passwordNotMatch),
    }),
    onSubmit: (values, { resetForm }) => {
      resetPassword({
        forgotPasswordToken: pathName[1],
        password: values.password
      }, resetForm)
    }
  });

  const location = useLocation();
  const pathName = location?.search.split("=");

  const resetPassword = (payload, resetForm) => {
    startLoading()
    axios.put(VS_RESET_PASSWORD_API, payload)
      .then(res => {
        stopLoading();
        resetForm({ values: '' });
        handleSuccess(res)
        navigate('/venture-scout/login', { replace: true });
      })
      .catch((error) => {
        stopLoading();
        handleError(error)
      })
  }

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="justify-start flex-col">
            <div className="text-wrapper"><h2 className='p-0'>{ResetPasswordLabels.title}</h2></div>
            <form onSubmit={formik.handleSubmit}>
              <div className='form-wrapper p-0'>
                <div className={"form-group " + (formik.touched.password && formik.errors.password ? "error-parent" : "") + (!formik.errors.password && formik.values.password ? "success-attempt-password" : "")}>
                  <PTInput
                    id={FORM_FIELDS.password}
                    name={FORM_FIELDS.password}
                    type={showNewPassword ? "text" : "password"}
                    placeholder={ResetPasswordLabels.newPassword}
                    label={ResetPasswordLabels.newPassword}
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    icon={<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>}
                  />
                  {formik.touched.password && formik.errors.password && <span className="error-txt">{formik.errors.password}</span>}
                </div>
                <div className="password-requirement">
                  <p>{FORM_FIELDS.mustContain}</p>
                  <ul>
                    <li>{FORM_FIELDS.upperCase}</li>
                    <li>{FORM_FIELDS.number}</li>
                    <li>{FORM_FIELDS.special}</li>
                  </ul>
                </div>
                <div className={"form-group " + (formik.touched.confirmPassword && formik.errors.confirmPassword ? "error-parent" : "") + (!formik.errors.confirmPassword && formik.values.confirmPassword ? "success-attempt-password" : "")}>
                  <PTInput
                    id={FORM_FIELDS.confirmPassword}
                    name={FORM_FIELDS.confirmPassword}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={ResetPasswordLabels.confirmNewPassword}
                    label={ResetPasswordLabels.confirmNewPassword}
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    icon={<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && <span className="error-txt">{formik.errors.confirmPassword}</span>}
                </div>
                <PTButton label={ResetPasswordLabels.submit} type="submit" className="mt-2 normal-case fill-orange btn" size='large' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
