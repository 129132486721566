import { ADMIN_MASTER_API, ADMIN_TECH_BUYERS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
// import { INVESTOR_TAB_FILTERS } from "../../constants"
import { buyerInteractionProptechsListDTO, buyerListDTO, buyerWatchlistProptechsListDTO } from "../dtos/adminTechBuyer"
import { loadProjectOverviewTabData } from "./projectOverviewTab"
import { loadTechBuyerForYouData } from "./techBuyerForYouTab"
import { loadTechBuyerHomeData } from "./techBuyerHomeTab"
import { loadTechBuyerProfileTabData } from "./techBuyerProfileTab"

export const getTechBuyers = (isArchived, activeSort, sortOrder, search, clear = true) => {
  const params = {
    filter: { "isArchived": isArchived },
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { 'order': sortOrder } : {}),
    ...(!!activeSort ? { 'orderBy': activeSort } : {}),
  }
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS, { params })
    .then(res => {
      handleSuccess(res)
      let buyers = buyerListDTO(res?.data?.data?.rows)
      dispatch(Actions.AdminTechBuyer.TechBuyersListSet, buyers)
      if (clear) {
        dispatch(Actions.AdminTechBuyer.SetSelectedTechBuyer, null)
        dispatch(Actions.AdminTechBuyer.SetIsSelectedTechBuyer, false)
      }
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err)
    })
}

export const updateArchiveStatus = (id, status, isArchived, activeSort, sortOrder, search, setOpenModal) => {
  ADMIN_API().put(`${ADMIN_TECH_BUYERS}/${id}/archive`, status)
    .then(res => {
      handleSuccess(res);
      getTechBuyers(isArchived, activeSort, sortOrder, search);
      setOpenModal(false)
    }).catch(err => {
      handleError(err)
      setOpenModal(false)
    });
}

export const updateActivetatus = (id, status, data, isArchived, activeSort, sortOrder, search, setOpenModal2) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().put(`${ADMIN_TECH_BUYERS}/${id}/status`, status)
    .then(res => {
      handleSuccess(res);
      getTechBuyers(isArchived, activeSort, sortOrder, search);
      dispatch(Actions.AdminTechBuyer.SetSelectedTechBuyer, { ...data, ...status })
      setOpenModal2(false)
    })
    .catch(err => {
      handleError(err)
      setOpenModal2(false)
    });
}

export const getInteractionsProptech = (id, status) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)

  const params = {
    ...(!!status ? { status } : {}),
  }
  ADMIN_API().get(`${ADMIN_TECH_BUYERS}/${id}/meetingInteractions`, { params })
    .then(res => {
      handleSuccess(res);
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetInterationsProptechs, buyerInteractionProptechsListDTO(res.data.data.rows))
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminTechBuyer.SetInterationsProptechs, [])
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
    });
}

export const deleteTechBuyer = (id, isArchived, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().delete(`${ADMIN_TECH_BUYERS}/permanently-delete/${id}`)
    .then(res => {
      handleSuccess(res);
      getTechBuyers(isArchived, activeSort, sortOrder, search);
      handleClose(false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
    });
}

export const getWatchlistProptech = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(`${ADMIN_TECH_BUYERS}/${id}/watchList`)
    .then(res => {
      handleSuccess(res);
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetWatchlistProptechsData, buyerWatchlistProptechsListDTO(res.data.data.rows))
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
    });
}

export const getAllTabs = (id) => {
  getAdminNavTabs()
  getAdminSelectedTbTabs(id)
}
export const getAdminNavTabs = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(`${ADMIN_MASTER_API}/navigations`)
  .then(res => {
    handleSuccess(res);
    dispatch(Actions.AdminTechBuyer.SetLoading, false)
    dispatch(Actions.AdminTechBuyer.SetAdminNavigationTbTabs, res.data.data)
  })
  .catch(err => {
    handleError(err)
    dispatch(Actions.AdminTechBuyer.SetLoading, false)
  });
}

export const getAdminSelectedTbTabs = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(`${ADMIN_TECH_BUYERS}/getProptechTabs/`+id)
  .then(res => {
    handleSuccess(res);
    dispatch(Actions.AdminTechBuyer.SetLoading, false)
    dispatch(Actions.AdminTechBuyer.SetAdminSelectedProptechTbTabs, res.data.data?.rows)
  })
  .catch(err => {
    console.log(err)
    handleError(err)
    dispatch(Actions.AdminTechBuyer.SetLoading, false)
  });
}

export const updateSelectedTbTabs = (data, id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().post(`${ADMIN_TECH_BUYERS}/assignProptechTab/`+id, data)
  .then(res => {
    handleSuccess(res);
    dispatch(Actions.AdminTechBuyer.SetLoading, false)
    getAdminSelectedTbTabs(id)
  })
  .catch(err => {
    console.log(err)
    handleError(err)
    dispatch(Actions.AdminTechBuyer.SetLoading, false)
  });
}


export const setActiveTab = (tab, id, adminTechBuyer, adminDropdowns) => {
  switch (tab) {
    case 0: id && loadTechBuyerProfileTabData(id, adminTechBuyer, adminDropdowns)
      break;
    
    case 1: id && loadProjectOverviewTabData(id, adminTechBuyer, adminDropdowns)
      break;

    case 2: id && loadTechBuyerHomeData(id, adminTechBuyer, adminDropdowns)
      break;

    case 3: id && loadTechBuyerForYouData(id, adminTechBuyer, adminDropdowns)
      break;

    case 4: id && getWatchlistProptech(id, adminTechBuyer, adminDropdowns)
      break;

    case 6: id && getAllTabs(id, adminTechBuyer, adminDropdowns)
      break;

    default:
      break;
  }
}