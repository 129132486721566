export function notificationsListDTO(data) {
  let notificationsList = [];
  if (data && data.length) {
    notificationsList = data.map((user) => {
      return {
        id: user?.id || 0,
        text: user?.text || '',
        category: user?.category || 0,
        type: user?.type || 0,
        vcUserId: user?.vcUserId || 0,
        data: user?.data ? {
          proptechCompanyDetailsId: user?.data?.proptechCompanyDetailsId || 0
        } : null,
        createdAt: user?.createdAt || null,
        updatedAt: user?.updatedAt || null,
        notificationsLog: user?.proptechNotificationsLog?.length ? user?.proptechNotificationsLog.map(log => {
          return {
            id: log?.id || 0
          }
        }) : [],
      }
    });
  }

  return notificationsList;
}