import { Delete } from "@material-ui/icons";
import { Download, UploadFile } from "@mui/icons-material";
import React, { useState } from "react";
import { uploadImage } from "../../../../utils/middleware/companyTab";
import {
  deleteProjectAttachment,
  uploadProjectAttachment,
} from "../../../../utils/middleware/projectAttachmentsTab";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loading";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import { FormHelperText, Tooltip } from "@mui/material";
import ConfirmModal from "../../../ConfirmModal";
import { extractFilenameFromURL } from "../../../../../utils/utility/utility";

const Attachments = () => {
  const attachmentsData = useSelector(
    (state) => state.adminProjectTab.attachmentsData || []
  );
  const { isLoading } = useSelector((state) => state.adminUser);
  const { selectedTechBuyer } = useSelector((state) => state.adminTechBuyer);

  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [fileError, setFileError] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  function acceptPdfFile(file) {
    // Check if the file exists
    if (!file) {
      return false;
    }

    // Check if the file type is PDF
    const fileType = file.type;
    if (fileType !== "application/pdf") {
      return false;
    }

    return true;
  }

  const onFileUpload = async () => {
    try {
      if (!file || !acceptPdfFile(file)) {
        setFileError(true);
        return;
      } else {
        setFileError(false);
      }
      setIsLoading(true);
      let res = await uploadImage(
        file,
        "tb-project-attachments",
        "application/pdf"
      );
      if (res.data?.fileName) {
        await uploadProjectAttachment(selectedTechBuyer?.id, {
          attachment: res.data.fileName,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onDownloadFile = (link) => {
    window.open(link, "_blank");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setSelectedFileId(id);
  };

  const onDeleteFile = async () => {
    handleCloseModal();
    await deleteProjectAttachment(selectedTechBuyer?.id, selectedFileId);
    setSelectedFileId(null);
  };

  return (
    <>
      {isLoading || (loading && <Loader />)}

      <div className="mt-4 mb-[22px] w-[95%]">
        <div className="dropdown-title">
          <div className="mb-2 flex justify-between items-center">
            <div className="flex flex-col">
              <input
                id="fileInput"
                type="file"
                onChange={handleFileChange}
                accept="application/pdf, .pdf"
              />
              <div>
              <span className="text-xs opacity-80">*Upload .pdf files</span>
              {fileError && (
                <FormHelperText error={fileError}>Select a valid file</FormHelperText>
              )}
              </div>
            </div>
            <PTButton
              label="Upload"
              handleOnClickEvent={onFileUpload}
              startIcon={<UploadFile />}
            />
          </div>
        </div>
        <p className="text-[#111D43] mb-2">Attachments</p>
        {attachmentsData?.projectAttachment?.length === 0 || attachmentsData?.length === 0 ? (
          <div className="border p-4 rounded border-grey mt-2 opacity-80">
            No Attachments added
          </div>
        ) : (
          attachmentsData?.projectAttachment?.map((i, idx) => (
            <div
              key={idx}
              className=" p-2 flex items-center w-full justify-between border border-grey rounded mb-2"
            >
              <div className="flex items-center gap-2">
                <p className="text-sm w-fit">
                  {extractFilenameFromURL(i?.link)}
                </p>
              </div>
              {i.link && (
                <div className="gap-4 flex">
                  <Tooltip title="Download" className="cursor-pointer">
                    <Download onClick={() => onDownloadFile(i.link)} />
                  </Tooltip>
                  <Tooltip
                    title="Delete"
                    className="cursor-pointer"
                    onClick={() => handleOpenModal(i.id)}
                  >
                    <Delete />
                  </Tooltip>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      {openModal && (
        <ConfirmModal
          open={openModal}
          handleCloseModal={handleCloseModal}
          handleSucces={onDeleteFile}
        />
      )}
    </>
  );
};

export default Attachments;
