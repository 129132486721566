import { MenuItem } from "@mui/material";
import React from "react";
import PTInput from "../../../components/GenericComponents/PTInput";
import { AnalysisLabelStyle } from "../../../proptech/constants/General";

export default function DropdownWithMultiTextField(props) {
  // Props
  const {
    errors,
    dropdown,
    dropdownOptions,
    dropdownLabel,
    dropdownValue,
    handleOnChangeEventDropdown,
    disabled,
    value1,
    value2,
    value3,
    label1,
    label2,
    label3,
    value1Error,
    value2Error,
    value3Error,
    handleOnChangeEvent1,
    handleOnChangeEvent2,
    handleOnChangeEvent3,
  } = props;
  return (
    <div className="dropdown-items flex">
      <div className="dropdown-inner-item mb-2">
        <PTInput
          labelStyle={AnalysisLabelStyle}
          variant="standard"
          label={dropdownLabel}
          placeholder=""
          maxLength={70}
          select={dropdown || false}
          className={dropdown && "custom-dropdown-icon"}
          selectChildren={
            dropdown &&
            dropdownOptions &&
            dropdownOptions.map((option) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))
          }
          value={dropdownValue}
          handleOnChangeEvent={handleOnChangeEventDropdown}
          error={errors}
          helperText={errors && `${dropdownLabel} Required.`}
          disabled={disabled}
        />
      </div>
      <div className="dropdown-inner2-item w-full">
        <div className="mb-2">
          <PTInput
            labelStyle={AnalysisLabelStyle}
            variant="standard"
            label={label1}
            placeholder=""
            multiline
            maxRows={4}
            maxLength={255}
            value={value1}
            handleOnChangeEvent={handleOnChangeEvent1}
            error={value1Error}
            helperText={value1Error && `${label1} Required.`}
            disabled={disabled}
            className={"w-[90%] mb-2"}
          />
          <PTInput
            labelStyle={AnalysisLabelStyle}
            variant="standard"
            label={label2}
            placeholder=""
            multiline
            maxRows={4}
            maxLength={255}
            value={value2}
            handleOnChangeEvent={handleOnChangeEvent2}
            error={value2Error}
            helperText={value2Error && `${label2} Required.`}
            disabled={disabled}
            className={"w-[90%] mb-2"}
          />
          <PTInput
            labelStyle={AnalysisLabelStyle}
            variant="standard"
            label={label3}
            placeholder=""
            multiline
            maxRows={4}
            maxLength={255}
            value={value3}
            handleOnChangeEvent={handleOnChangeEvent3}
            error={value3Error}
            helperText={value3Error && `${label3} Required.`}
            disabled={disabled}
            className={"w-[90%] mb-2"}
          />

          {/* {showChar && (
              <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
                {editedDetails?.[textValue] !== null
                  ? editedDetails?.[textValue]?.length
                  : data?.[textValue]?.length || 0}
                /<span>255</span> CHARACTERS
              </p>
            )} */}
        </div>
      </div>
    </div>
  );
}
