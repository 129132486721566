import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import IconDown from '../../../assets/Icons/ic-down-arrow.svg';
import IconDownArrow from '../../../assets/Icons/ic_down-arrow.svg';
import PTButton from '../../../components/GenericComponents/PTButton';
import PTSelect from '../../../components/GenericComponents/PTSelect';
import PTCheckbox from '../../../components/GenericComponents/PTCheckbox';
import Loader from '../../../components/Loading';
import { getForyou, getProptechs } from '../../utils/middleware/Dashboard';
import { getInvestmentRoundList, getRegionList, getRevenueList } from '../../utils/middleware/Dropdowns';
import './style.scss';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import { sortByDefaultOption } from '../../constants';

const noneRevenueValue = { id: 0, label: "None", value: 0 }

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const menuStyle = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  borderRadius: '8px',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

//Component for vc dashboard Sorting and filter block
export default function HeaderNavigation({ tab }) {

  // More filter Modal
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const [rangeMenu, setRangeMenu] = useState();
  const [regionOption, setRegionOption] = useState([]);
  const [revenueOption, setRevenueOption] = useState([]);
  const [roundOption, setRoundOption] = useState([]);
  const [filterOption, setFilterOption] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState(null);
  const [clearSlider, setClearSlider] = useState(false);

  // Store
  const { orderBy, order, revenueRangeId, vcMasterData } = useSelector(state => state.vcProptechs);

  const open = Boolean(rangeMenu);
  const handleClick = (event) => {
    setRangeMenu(event.currentTarget);
  };

  //Api call For getting filter and sorting data
  useEffect(() => {
    if (!vcMasterData) {
      getMasterData()
    } else {
      setRegionOption(vcMasterData?.regionData)
      setRevenueOption(vcMasterData?.revenueData)
      setRoundOption(vcMasterData?.investmentRoundData)
    }
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const getMasterData = async () => {
    let regionData = await getRegionList();
    let revenueData = await getRevenueList();
    let investmentRoundData = await getInvestmentRoundList();

    revenueData.forEach((revenue) => {
      revenue.label = revenue.name
      revenue.value = parseInt(revenue.name.replace(/[^0-9]/g, ''))
      delete revenue.name
    })

    Store.dispatch({
      type: Actions.VCProptechs.VCSetMasterData, payload: {
        regionData: regionData,
        revenueData: [...revenueData, noneRevenueValue],
        revenueData2: [...revenueData],
        investmentRoundData: investmentRoundData,
      }
    })

    regionData.length ? setRegionOption(regionData) : setRegionOption([])
    revenueData.length ? setRevenueOption([...revenueData, noneRevenueValue]) : setRevenueOption([])
    investmentRoundData.length ? setRoundOption(investmentRoundData) : setRoundOption([])
  }

  // For Revenue modal
  const handleClose = () => {
    setRangeMenu(null);
  };

  const handlelRevenueFilter = async () => {
    handlefilterData()
    handleClose()
  }

  // Function for handling to get filter fields
  const handlefilterData = async (v, orderByValue, orderValue) => {
    let revenueRangeId2 = sliderValue || sliderValue === 0 ? revenueOption.filter((r) => r.value === sliderValue)[0].id : revenueRangeId ? revenueRangeId : undefined;
    Store.dispatch({ type: Actions.VCProptechs.VCSetRevenueRangeId, payload: revenueRangeId2 })
    setFilterOption({
      ...filterOption,
      ...v
    })
    Store.dispatch({ type: Actions.VCProptechs.VCSetPage, payload: 0 })
    Store.dispatch({ type: Actions.VCForYou.VCSetPage, payload: 0 })
    Store.dispatch({
      type: Actions.VCProptechs.VCSetFilter, payload: {
        ...filterOption,
        ...v
      }
    })
    setIsLoading(true)
    try {
      if (tab === 0) {
        await getProptechs({
          ...filterOption,
          ...v
        }, orderByValue || orderBy, orderValue || order, revenueRangeId2);
      } else if (tab === 1) {
        await getForyou({
          ...filterOption,
          ...v
        }, orderByValue || orderBy, orderValue || order, revenueRangeId2);
      }
    } catch {
      setIsLoading(false)
      console.log('error')
    }
    setIsLoading(false)
  }

  const valueLabelFormat = (value) => {
    if (value) {
      return `<$${value}m`
    } else {
      return `None`
    }
  }

  const handleClearSlider = () => {
    setSliderValue(0)
    setClearSlider(true)
  }

  // For null revenue value
  useEffect(() => {
    if (sliderValue === 0 && clearSlider) {
      handlelRevenueFilter()
      setClearSlider(false)
    }
  }, [sliderValue])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="navigation-header">
        {/* Regions filter */}
        <PTSelect
          handlefilterData={(v, orderBy, order) => handlefilterData(v, orderBy, order)}
          optionValue={regionOption}
          multiple
          checked
          Isbutton
          labelValue="Regions"
          labelId="regionId"
          id="region-select"
          className="custom-select"
        />
        {/* Revenue slider */}
        <div className="nav-menu">
          <IconButton
            onClick={(e) => handleClick(e)}
            aria-controls={open ? 'open' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className={"navigation-item " + (open ? "open-nav-menu" : " ")}
          >
            {sliderValue ? `<$${sliderValue}m` : sliderValue === 0 ? 'Revenue' :
              revenueRangeId ? revenueOption.find((r) => r.id === revenueRangeId)?.label : 'Revenue'}
            <img src={IconDown} alt="Icon" />
          </IconButton>
          <Menu
            anchorEl={rangeMenu}
            id="open"
            className="range_dropdown_menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: menuStyle,
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem className="range-wrapper">
              <Box className="rangeSlider">
                <Slider
                  defaultValue={25}
                  step={null}
                  value={sliderValue || sliderValue === 0 ? sliderValue :
                    revenueRangeId ? revenueOption.find((r) => r.id === revenueRangeId)?.value : 0}
                  onChange={(_, newValue) => {
                    if (newValue !== sliderValue) {
                      setSliderValue(newValue);
                    }
                  }}
                  valueLabelDisplay="auto"
                  marks={revenueOption}
                  scale={(v) => v}
                  min={0}
                  max={25}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                />
              </Box>
              <div className="btn-wrapper">
                <span>Amounts are in USD</span>
                <div>
                  <PTButton
                    label='Clear'
                    className="normal-case fill-gray btn"
                    size='large'
                    handleOnClickEvent={() => handleClearSlider()}
                  />
                  <PTButton
                    label='Apply'
                    className="normal-case fill-orange btn"
                    size='large'
                    handleOnClickEvent={() => handlelRevenueFilter()} />
                </div>
              </div>
            </MenuItem>
          </Menu>
        </div>
        {/* Revenue slider */}
        <PTSelect
          handlefilterData={(v, orderBy, order) => handlefilterData(v, orderBy, order)}
          optionValue={roundOption}
          multiple
          checked
          Isbutton
          labelValue="Round"
          labelId="investmentRoundId"
          id="round-select"
          className="custom-select"
        />
        {/* More Filters option */}
        {/* <div className="nav-menu">
          <IconButton onClick={handleOpenModal}>
            More filters
          </IconButton>
        </div> */}

        {/* Sorting option */}
        <div className="nav-menu last-menu">
          <span className="text">Sort by: </span>
          <PTSelect
            handlefilterData={(v, orderBy, order) => handlefilterData(v, orderBy, order)}
            optionValue={sortByDefaultOption}
            labelValue="Sort by"
            labelId="orderBy"
            id="sort-by-select"
            className="custom-select"
          />
        </div>
      </div>
      {/* More filters modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="more-filter-modal modal"
      >
        <>
          <Box sx={style} >
            <div className="modal-inner">
              <h2>More filters</h2>
              <div className="filter-wrapper">
                <div className="filter-item">
                  <h2>Megatrends</h2>
                  <ul>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li className="show-more">
                      <a href={() => false}>
                        see 12 more
                        <img src={IconDownArrow} alt='Arrow' />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="filter-item">
                  <h2>Tech buyer</h2>
                  <ul>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li className="show-more">
                      <a href={() => false}>
                        see 12 more
                        <img src={IconDownArrow} alt='Arrow' />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="filter-item">
                  <h2>Industries</h2>
                  <ul>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li>
                      <PTCheckbox label="label" className="form-checkbox" id="label1" />
                    </li>
                    <li className="show-more">
                      <a href={() => false}>
                        see 12 more
                        <img src={IconDownArrow} alt='Arrow' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <PTButton label='Clear All' className="normal-case fill-gray btn" size='large' />
              <div>
                <PTButton label='Clear' className="normal-case fill-gray btn" size='large' />
                <PTButton label='Apply' className="normal-case fill-orange btn" size='large' />
              </div>
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
}
