import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpIcon from '../../../../assets/Icons/admin-arrow-up.svg';
import ArrowDownIcon from '../../../../assets/Icons/admin-arrow-down.svg';
import PTInput from '../../../../components/GenericComponents/PTInput'
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import ContactCard from '../ContactCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContectFilterMenu from '../ContectFilterMenu';

export default function ContactsListCard(props) {
  const { unsaveChange } = props

  // State
  const [activeSearchbar, setActiveSearchbar] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [userMenu, setUserMenu] = useState();

  const openUser = Boolean(userMenu);

  const handleClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  const handleClose = () => {
    setUserMenu(null);
  };

  // Store
  const { contacts, selectedContact, activeSort, sortOrder, isPrimaryUser, activefilter } = useSelector(state => state.adminContacts);

  const handleSort = (sort) => {
    dispatch(Actions.Contacts.SetActiveSort, sort)
    dispatch(Actions.Contacts.SetSortOrder, sort === activeSort ? sortOrder === 'ASC' ? 'DESC' : 'ASC' : 'ASC')
  }

  const handleIsPrimarySelection = () => {
    dispatch(Actions.Contacts.SetIsPrimaryUser, !isPrimaryUser)
  }

  const handleSearch = (e) => {
    let text = e.target.value ? e.target.value.trim() : ''
    setSearchText(e.target.value)
    if (e.key === 'Enter') {
      setSearchText(text)
      dispatch(Actions.Contacts.SetSearch, text)
      dispatch(Actions.Contacts.SetIsSelectedContact, false)
      dispatch(Actions.Contacts.SetSelectedContact, null)
    }
  }

  const handleCloseSearch = () => {
    dispatch(Actions.Contacts.SetSearch, '')
    setActiveSearchbar(false)
    setSearchText('')
  }

  useEffect(() => {
    const element = document.getElementById('contactsList');
    let totalHight = 0;
    if (contacts?.length) {
      contacts.every(v => {
        let cardHeight = document.getElementById(`contact-${v?.id}`)?.clientHeight || 0
        if (selectedContact?.id && selectedContact?.id === v?.id && selectedContact?.userType === v?.userType) {
          dispatch(Actions.Contacts.SetSelectedContact, v)
          return false
        } else totalHight += cardHeight;
        return true;
      });
      if (selectedContact?.id && element.clientHeight < totalHight) {
        element.scrollTo(0, totalHight);
      }
    }
  }, [selectedContact, contacts])

  return (
    <>
      <div className='w-full h-[80px] flex items-center justify-between box-border pl-[15px] pr-[15px] xlg:pl-[30px] xlg:pr-[24px] bg-[#E7E8EC]'>
        {activeSearchbar ?
          <PTInput label="Search" fullWidth value={searchText} onKeyPress={handleSearch} handleOnChangeEvent={handleSearch} variant="standard" placeholder='' icon={<CloseIcon className='cursor-pointer' onClick={handleCloseSearch} />} /> :
          <>
            <div className='search-icon-wrap h-[30px] w-[30px] rounded-full bg-[#fff] cursor-pointer inline-flex items-center justify-center' onClick={() => setActiveSearchbar(true)}>
              <SearchIcon className='cursor-pointer text-[#70778E] w-[20px] h-[20px]' />
            </div>
            <div className='flex items-center font-semibold text-[12px] whitespace-nowrap not-italic uppercase'>
              <div className={`flex cursor-pointer opacity-${isPrimaryUser ? '100' : '40'}`} style={{ marginRight: '10px' }} onClick={handleIsPrimarySelection}>
                <input type="checkbox" checked={isPrimaryUser} className='mr-[6px] bg-[#E7E8EC] accent-[#111D43]' />
                <span>Primary user</span>
              </div>
              <div className={`flex cursor-pointer opacity-${activeSort === 'name' ? '100' : '40'}`} style={{ marginRight: '10px' }} onClick={() => handleSort('name')}>
                <img src={activeSort === 'name' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>A-Z</span>
              </div>
              <div className={`flex cursor-pointer opacity-${activeSort === 'userCreatedDate' ? '100' : '40'}`} style={{ marginRight: '6px' }} onClick={() => handleSort('userCreatedDate')}>
                <img src={activeSort === 'userCreatedDate' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>Date Created</span>
              </div>
              <MoreVertIcon className='text-[13px] cursor-pointer' onClick={handleClick} />
            </div>
          </>}
      </div>
      <div id="contactsList" className='mr-[-4px] md:mr-[0] h-[168px] md:h-[calc(100%-50px)] xlg:h-[calc(100%-80px)] overflow-y-auto hide-scrollbar'>
        {contacts.length !== 0 && contacts.map((contact, index) => {
          return <ContactCard
            key={index}
            contact={contact}
            unsaveChange={unsaveChange}
          />
        })}
      </div>

      <ContectFilterMenu anchorEl={userMenu} open={openUser}
        handleClose={handleClose}
        activefilter={activefilter}
      />
    </>
  )
}
