import React, { useState } from 'react'
import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Link, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ChangePlanModalStyle, planFeatures, planTexts } from '../../constants/General'
import TickIcon from '../../../assets/Icons/ic-tickmark-white.svg';
import CrossIcon from '../../../assets/Icons/ic-cross-white.svg';
import PTButton from '../../../components/GenericComponents/PTButton';
import { getProptechLocalStorageUserData } from '../../../utils/session';
import InfoIcon from '../../../components/InfoIcon';

export default function ChangePlanModal(props) {
  // Props
  const { openChangePlanModal, closeChangePlanModal, plans, selectedPlan, setSelectedPlan, termAndConditions, setTermAndConditions, handleChange } = props

  // State
  const [handleInfo, setHandleInfo] = useState([])

  return <Modal
    open={openChangePlanModal}
    onClose={closeChangePlanModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={ChangePlanModalStyle} >
      <Typography id="modal-modal-title" variant="h6" className='mb-3' component="h2">
        {getProptechLocalStorageUserData()?.isPlanExpired ? 'Choose plan' : planTexts.title}
      </Typography>
      <div className='h-[600px] overflow-y-auto'>
        {plans?.length !== 0 ? <>
          <TableContainer component={Paper} className="package-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-lg font-semibold leading-tight font-['Poppins']">Features</TableCell>
                  <TableCell className={`text-lg font-semibold leading-tight font-['Poppins'] w-[118px] ${selectedPlan === 0 && 'selected-package-header'}`} align="center">{plans[0]?.name}</TableCell>
                  <TableCell className={`text-lg font-semibold leading-tight font-['Poppins'] w-[118px] ${selectedPlan === 1 && 'selected-package-header'}`} align="center">{plans[1]?.name}</TableCell>
                  <TableCell className={`text-lg font-semibold leading-tight font-['Poppins'] w-[118px] ${selectedPlan === 2 && 'selected-package-header'}`} align="center">{plans[2]?.name}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planFeatures.map((data, index) => {
                  return <TableRow>
                    <TableCell className="py-2.5">
                      <div className="feature-info flex items-center">
                        <p className="flex-1 pr-4 leading-tight font-['Poppins'] text-xs text-[#111D43]">{data.label}</p>
                        <InfoIcon
                          className="w-5 h-5"
                          label={data.tooltipText}
                          info={handleInfo}
                          handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                          handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                        />
                      </div>
                    </TableCell>
                    <TableCell className={`py-2.5 ${selectedPlan === 0 && (index === planFeatures?.length - 1 ? 'selected-package-footer' : 'selected-package-data')}`} align="center">
                      {data.icon ? plans[0]?.[data.value] ?
                        <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                          <img src={TickIcon} alt="Info" />
                        </span> : <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                          <img src={CrossIcon} alt="Cross Icon" />
                        </span>
                        : <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">{plans[0]?.[data.value] && data.value === 'additionalMeetingsWithPTC' ? `${plans[0]?.[data.value]} hours` : plans[0]?.[data.value] || 'Nil'}</p>}
                    </TableCell>
                    <TableCell className={`py-2.5 ${selectedPlan === 1 && (index === planFeatures?.length - 1 ? 'selected-package-footer' : 'selected-package-data')}`} align="center">
                      {data.icon ? plans[1]?.[data.value] ?
                        <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                          <img src={TickIcon} alt="Info" />
                        </span> : <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                          <img src={CrossIcon} alt="Cross Icon" />
                        </span>
                        : <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">{plans[1]?.[data.value] && data.value === 'additionalMeetingsWithPTC' ? `${plans[1]?.[data.value]} hours` : plans[1]?.[data.value] || 'Nil'}</p>}
                    </TableCell>
                    <TableCell className={`py-2.5 ${selectedPlan === 2 && (index === planFeatures?.length - 1 ? 'selected-package-footer' : 'selected-package-data')}`} align="center">
                      {data.icon ? plans[2]?.[data.value] ?
                        <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                          <img src={TickIcon} alt="Info" />
                        </span> : <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                          <img src={CrossIcon} alt="Cross Icon" />
                        </span>
                        : <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">{plans[2]?.[data.value] && data.value === 'additionalMeetingsWithPTC' ? `${plans[2]?.[data.value]} hours` : plans[2]?.[data.value] || 'Nil'}</p>}
                    </TableCell>
                  </TableRow>
                })}
                <TableRow>
                  <TableCell className="py-4"></TableCell>
                  {Array.from(Array(3).keys()).map((data, index) => {
                    return <TableCell className="py-4" align="center">
                      <PTButton variant="contained" label="Select" disabled={!plans[index]?.price} handleOnClickEvent={() => setSelectedPlan(data)} className="font-semibold font-['Poppins'] !h-12 !w-20 shadow-none normal-case !rounded-xl !border-solid !bg-[#111D43] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]" />
                    </TableCell>
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="cost-section">
            <div className="cost-wrapper flex flex-wrap justify-between items-center border-t-2 border-solid border-[#FF4100]">
              <div className="cost-inner review-title pl-0">
                <div className="text-lg font-semibold leading-tight font-['Poppins'] text-[#FF4100] max-w-[225px]">{planTexts.reviewSelection}</div>
              </div>
              <div className="w-1/4 cost-inner text-center bg-[#FF4100] sm:pt-7 sm:pb-7 pt-4 pb-4">
                <span className="text-lg font-semibold leading-tight font-['Poppins'] mb-1.5 block text-[#FFFFFF]">{selectedPlan !== null ? `$${plans[selectedPlan]?.price}` : 'Select plan'}</span>
                <p className="text-[10px] leading-3	font-semibold uppercase font-['Poppins'] text-[#FFFFFF]">{planTexts.totalCost}</p>
              </div>
            </div>
            <div className="terms-condition-section flex items-center justify-between mt-6">
              <p className="text-sm leading-tight text-[#111D43]">{planTexts.termAndConditions}<Link href={planTexts.termAndConditionURL} className="decoration-[#111D43]" target="_blank">here</Link>.</p>
              <FormGroup>
                <FormControlLabel checked={termAndConditions} control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />} label="Agree and continue" onChange={(e) => setTermAndConditions(e.target.checked)} />
              </FormGroup>
            </div>
          </div>
        </> : <div className='w-full h-full flex justify-center items-center'>
          <CircularProgress />
        </div>}
      </div>
      <div className="mt-5 proptech-stepper">
        <div className="flex justify-end">
          <PTButton variant="contained"
            label={<span className="!font-semibold !h-5 !text-sm !leading-5 !text-center !not-italic !w-20 !text-[#111D43]">Cancel</span>}
            className='!h-12 !w-32 !mr-5 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
            handleOnClickEvent={closeChangePlanModal} />
          <PTButton variant="contained" label="Change"
            disabled={!(selectedPlan !== null && termAndConditions && plans[selectedPlan]?.price)}
            className="!h-12 !w-32 !rounded-xl !border-solid !bg-[#111D43] !font-['Poppins'] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]"
            handleOnClickEvent={handleChange} />
        </div>
      </div>
    </Box>
  </Modal >
}
