export function proptechsListDTO(data) {
  let proptechList = [];
  if (data && data.length) {
    proptechList = data.map((proptech) => {
      return {
        id: proptech?.id ? proptech.id : 0,
        primaryUserId: proptech?.primaryUserId || 0,
        ventureScoutCode: proptech?.ventureScoutCode || '',
        websiteURL: proptech?.websiteURL || '',
        linkedInURL: proptech?.linkedInURL || '',
        countryId: proptech?.countryId || 0,
        regionId: proptech?.regionId || 0,
        currencyId: proptech?.currencyId || 0,
        investmentRoundId: proptech?.investmentRoundId || 0,
        investmentRangeId: proptech?.investmentRangeId || 0,
        totalMeetingCount: proptech?.totalMeetingCount || 0,
        companyLogo: proptech?.companyLogo || '',
        revenueRangeId: proptech?.revenueRangeId || 0,
        megaTrendId: proptech?.megaTrendId || 0,
        languageId: proptech?.languageId || 0,
        elevatorPitch: proptech?.elevatorPitch || '',
        technologyProduct: proptech?.technologyProduct || '',
        marketOpportunity: proptech?.marketOpportunity || '',
        futureGoals: proptech?.futureGoals || '',
        investability: proptech?.investability || '',
        teamStrength: proptech?.teamStrength || '',
        KPIs: proptech?.KPIs || '',
        elevatorPitchAdmin: proptech?.elevatorPitchAdmin || '',
        technologyProductAdmin: proptech?.technologyProductAdmin || '',
        marketOpportunityAdmin: proptech?.marketOpportunityAdmin || '',
        futureGoalsAdmin: proptech?.futureGoalsAdmin || '',
        investabilityAdmin: proptech?.investabilityAdmin || '',
        teamStrengthAdmin: proptech?.teamStrengthAdmin || '',
        KPIsAdmin: proptech?.KPIsAdmin || '',
        createdAt: proptech?.createdAt || '',
        updatedAt: proptech?.updatedAt || '',
        publishedAt: proptech?.publishedAt || '',
        isPublished: proptech?.isPublished,
        isArchived: proptech?.isArchived,
        status: proptech?.status || 0,
        signupCompletedAt: proptech?.signupCompletedAt || '',
        primaryUserEmail: proptech?.primaryUserEmail || '',
        planExpiryDate: proptech?.planExpiryDate || '',
        proptechPlanId: proptech?.proptechPlanId || '',
        proptechPlanDetails: {
          freeNoOfMeetings: proptech?.proptechPlanDetails?.freeNoOfMeetings ? proptech.proptechPlanDetails.freeNoOfMeetings : 0,
          durationOfDays: proptech?.proptechPlanDetails?.durationOfDays ? proptech.proptechPlanDetails.durationOfDays : '',
          name: proptech?.proptechPlanDetails?.name ? proptech.proptechPlanDetails.name : '',
          price: proptech?.proptechPlanDetails?.price ? proptech.proptechPlanDetails.price : '',
        },
        proptechCompany: {
          id: proptech?.proptechCompany?.id ? proptech.proptechCompany.id : 0,
          name: proptech?.proptechCompany?.name ? proptech.proptechCompany.name : '',
          proptechUsers: proptech?.proptechCompany?.proptechUsers?.length ? proptech?.proptechCompany?.proptechUsers.map(user => {
            return {
              id: user?.id ? user.id : 0,
              name: user?.name ? user.name : '',
              email: user?.email ? user.email : '',
            }
          }) : []
        },
        proptechUser: {
          id: proptech?.proptechUser?.id || 0,
          primaryUserName: proptech?.proptechUser?.primaryUserName || '',
          email: proptech?.proptechUser?.email || '',
        },
        country: {
          id: proptech?.country?.id || 0,
          name: proptech?.country?.name || '',
          regionId: proptech?.country?.regionId || 0,
        },
        region: {
          id: proptech?.region?.id || 0,
          name: proptech?.region?.name || '',
        },
        currency: {
          id: proptech?.currency?.id || 0,
          name: proptech?.currency?.name || '',
        },
        investmentRound: {
          id: proptech?.investmentRound?.id || 0,
          name: proptech?.investmentRound?.name || '',
        },
        investmentRange: {
          id: proptech?.investmentRange?.id || 0,
          name: proptech?.investmentRange?.name || '',
        },
        revenueRange: {
          id: proptech?.revenueRange?.id || 0,
          name: proptech?.revenueRange?.id || '',
        },
        megaTrend: {
          id: proptech?.megaTrend?.id || 0,
          name: proptech?.megaTrend?.name || '',
        },
        language: {
          id: proptech?.language?.id || 0,
          name: proptech?.language?.name || '',
        },
      }
    })
  }

  return proptechList;
}