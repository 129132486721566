import React from 'react'
import { useSelector } from 'react-redux';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { getInteractionsProptech } from '../../../utils/middleware/adminInvestors';
import { INVESTOR_INTERACTIONS_TAB_FILTERS, INVESTOR_TAB_FILTERS } from '../../../constants';
import { setActivetabData } from '../../../utils/middleware/adminProptechTab';
import InvestorCardDetails from '../InvestorCardDetails'

export default function InteractionsTab() {
  // Store
  const { selectedInvestor, interactionFilter, interactionsProptechs } = useSelector(state => state.adminInvestor);
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)

  const handleChange = (value, checked) => {
    if (checked) {
      dispatch(Actions.AdminInvestor.SetInterationsFilter, value)
      getInteractionsProptech(selectedInvestor?.id, value)
    }
  }

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminUser.SetIsSelectedProptech, true)
    dispatch(Actions.AdminUser.SetSelectedProptech, { id })
    setActivetabData(2, id, adminAnalysisTab, adminDropdowns)
  }

  return (
    <>
      <div className="investor-radio-wrapper">
        <FormControl className="flex items-center flex-row">
          <FormLabel id="booking" className="text-sm text-[#111D43] font-['Poppins'] font-light uppercase tracking-[0.07em] mr-3.5">{INVESTOR_INTERACTIONS_TAB_FILTERS.bookings}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="booking"
            name="row-radio-buttons-group"
            value={interactionFilter}
          >
            <FormControlLabel value={INVESTOR_TAB_FILTERS.total} control={<Radio />} label={INVESTOR_INTERACTIONS_TAB_FILTERS.total} onChange={(e, value) => handleChange(INVESTOR_TAB_FILTERS.total, value)} />
            <FormControlLabel value={INVESTOR_TAB_FILTERS.upcoming} control={<Radio />} label={INVESTOR_INTERACTIONS_TAB_FILTERS.upcoming} onChange={(e, value) => handleChange(INVESTOR_TAB_FILTERS.upcoming, value)} />
            <FormControlLabel value={INVESTOR_TAB_FILTERS.past} control={<Radio />} label={INVESTOR_INTERACTIONS_TAB_FILTERS.past} onChange={(e, value) => handleChange(INVESTOR_TAB_FILTERS.past, value)} />
            <FormControlLabel value={INVESTOR_TAB_FILTERS.cancelled} control={<Radio />} label={INVESTOR_INTERACTIONS_TAB_FILTERS.cancelled} onChange={(e, value) => handleChange(INVESTOR_TAB_FILTERS.cancelled, value)} />
          </RadioGroup>
        </FormControl>
      </div>
      <div className='investor-card-wrapper'>
        {interactionsProptechs?.length !== 0 && interactionsProptechs.map(proptech => {
          return <InvestorCardDetails proptech={proptech} onCardClick={() => handleCardClick(proptech?.id)} />
        })}
      </div>
    </>
  )
}
