import React from 'react'
import { useSelector } from 'react-redux';
import defaultProfile from '../../../../assets/Icons/default-profile.svg';
import PTButton from '../../../../components/GenericComponents/PTButton';
import { portal, contactDetails, userType, DateFormat, userTypeConst } from '../../../../constants/General';
import { changeStatus, getContacts } from '../../../utils/middleware/adminContacts';
import { formatTime } from '../../../../utils/utility/utility'
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import './ContactCardDetails.scss'

export default function ContactCardDetails() {
  // Store
  const selectedContact = useSelector(state => state.adminContacts.selectedContact);
  const { contacts, activeSort, sortOrder, isPrimaryUser, activefilter, search } = useSelector(state => state.adminContacts);

  const handleContactClick = (user) => {
    if (isPrimaryUser) {
      dispatch(Actions.Contacts.SetIsPrimaryUser, !isPrimaryUser)
      dispatch(Actions.Contacts.SetAlreadySelectedContact, { id: user?.id, userType: selectedContact?.userType })
      getContacts(!isPrimaryUser, activeSort, sortOrder, search, activefilter, { id: user?.id, userType: selectedContact?.userType })
    } else {
      if (contacts?.length) {
        contacts.every(contact => {
          if (contact?.id === user?.id && contact?.userType === selectedContact?.userType) {
            dispatch(Actions.Contacts.SetSelectedContact, contact)
            dispatch(Actions.Contacts.SetIsSelectedContact, true)
            return false;
          }
          return true
        })
      }
    }
  }

  return (
    <div className='contact-card-details-container'>
      <div className='left-side-details'>
        <img
          src={selectedContact?.profileImage || defaultProfile}
          alt='Profile' />
        <div className="button-wrapper flex flex-col items-center mt-8">
          <PTButton
            label="Email"
            color='primary'
            size='large'
            handleOnClickEvent={() => window.location.href = `mailto:${selectedContact?.email}`}
            className='text-[10px] btn uppercase tracking-[0.07em] blue-border w-[120px] xl:mb-[12px] mb-[8px]' />
          <PTButton
            label={ selectedContact?.userType!== userTypeConst.VS ? selectedContact?.userStatus  ? 'Deactivate' : 'Active' :  selectedContact?.companyStatus===1 ? 'Deactivate' : 'Active'}
            color='primary'
            size='large'
            handleOnClickEvent={() => changeStatus(selectedContact?.id, userType?.[portal[selectedContact?.userType]], selectedContact?.userType=== userTypeConst.VS ? !(selectedContact?.companyStatus===1) : !selectedContact?.userStatus, isPrimaryUser, activeSort, sortOrder, search, activefilter)}
            className='text-[10px] btn uppercase tracking-[0.07em] fill-blue-admin w-[120px]' />
        </div>
      </div>
      <div className='right-side-details'>
        <div className='contact-name'>
          {selectedContact.name}
          <span className="badge green-badge ml-3">{selectedContact?.companyStatus === 0 ? 'Pending' : selectedContact?.companyStatus === 1 ? 'Accepted' : 'Rejected'}</span>
          {selectedContact?.userType === 'Proptech' && selectedContact?.isPublished && <span className="badge green-badge ml-3">Published</span>}
        </div>
        {contactDetails.map(details => {
          return <div className='mb-5'>
            <div className='contact-heading'>{details?.textLabel}</div>
            <div className='contact-value'>{selectedContact?.[details?.textValue] || '-'}</div>
          </div>
        })}
        <div className='mb-5'>
          <div className='contact-heading'>Last active</div>
          <div className='contact-value'>{selectedContact?.lastLoginAt ? formatTime(selectedContact?.lastLoginAt, DateFormat.DayMonthFormat) : '-'}</div>
        </div>
        <div className='mb-5'>
          <div className='contact-heading mb-2'>ASSOCIATED CONTACTS</div>
          {selectedContact?.associatedContactList?.length ? selectedContact.associatedContactList.map((user) => {
            return (
              <PTButton
                key={user?.id}
                variant="contained"
                label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">{user?.name}</span>}
                type="submit"
                className='!rounded-xl !bg-[rgba(17,29,67,0.1)] mr-3 mb-2 gray-btn'
                handleOnClickEvent={() => handleContactClick(user)}
              />
            )
          }) : `-`}
        </div>
      </div>
    </div>
  )
}
