import { Navigate } from 'react-router-dom';
import { getVsAccessToken } from '../../../utils/session';

// Access of Routes For logged in user
const PrivateRoute = ({ children }) => {
    const isLogin = getVsAccessToken()

    return (
      <>
        {isLogin ? children : <Navigate to='/venture-scout/login' />}
      </>
    );
}
export default PrivateRoute;