import React, { useEffect, useState } from "react";
import PTInput from "../../../../../components/GenericComponents/PTInput";
import { AnalysisLabelStyle } from "../../../../../proptech/constants/General";
import DropdownColumn from "../../../DropdownColumn";
import { useSelector } from "react-redux";
import { FormHelperText, MenuItem, TextField } from "@mui/material";
import { CustomSlider, StyledValueLabel } from "../../../Slider";
import { investmentSize } from "../../../../constants";
import { numFormatter, trimQuery } from "../../../../../utils/utility/utility";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tags from "../../../Tags";
import {
  PROJECT_STRATEGY_ERROR,
  PROJECT_STRATEGY_PAYLOAD,
} from "../../../../utils/payloads/payloads";
import moment from "moment";
import Loader from "../../../../../components/Loading";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import ConfirmModal from "../../../ConfirmModal";
import { updateProjectStrategyDetails } from "../../../../utils/middleware/projectStrategyTab";
import { dispatch } from "../../../../../utils/utility/store";
import { Actions } from "../../../../../store/actions";
import { isUnsaveChanges } from "../../../../utils/middleware/projectTab";

const StrategyDiagnostic = (props) => {
  const { setUnsaveChange } = props;

  const strategyDiagnosticData = useSelector(
    (state) => state.adminProjectTab.strategyDiagnosticData
  );
  const { selectedTechBuyer } = useSelector((state) => state.adminTechBuyer);
  const { isLoading } = useSelector((state) => state.adminUser);
  const { keyPainPoints, useCases, valueKPIs, assetTypes, regions } =
    useSelector((state) => state.adminDropdowns);

  const [editedDetails, setEditedDetails] = useState(PROJECT_STRATEGY_PAYLOAD);
  const [errors, setErrors] = useState(PROJECT_STRATEGY_ERROR);
  const [selectedRegions, setSelectedRegions] = useState(null);
  const [regionsError, setRegionsError] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange);
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !strategyDiagnosticData?.[error],
      };
    });
    setErrors(data);
    let allRegions =
      selectedRegions !== null
        ? selectedRegions?.map((i) => i.id)
        : strategyDiagnosticData?.projectRegions?.map((i) => i?.regionId);
    if (!allRegions || allRegions?.length === 0) {
      setRegionsError(true);
      return true;
    } else {
      setRegionsError(false);
    }
    return Object.keys(data).some((k) => data[k]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(
        ([key, value]) => (newData[key] = strategyDiagnosticData?.[key])
      );

    handleCloseModal();
    let allRegions =
      selectedRegions !== null
        ? selectedRegions?.map((i) => i.id)
        : strategyDiagnosticData?.projectRegions?.map((i) => i?.regionId);

    try {
      let res = await updateProjectStrategyDetails(
        selectedTechBuyer?.id,
        {
          ...Object.fromEntries(
            Object.entries(editedDetails).filter(([_, v]) => !!v)
          ),
          ...Object.fromEntries(
            Object.entries(newData).filter(([_, v]) => !!v)
          ),
        },
        allRegions
      );
      if (res === 200) setEditedDetails(PROJECT_STRATEGY_PAYLOAD);
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="mb-[22px] pb-10 w-[95%]">
        <div>
          <div className="url-list-item my-2">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Problem"
              fullWidth
              placeholder=""
              multiline
              maxRows={4}
              maxLength={400}
              value={
                editedDetails?.problem !== null
                  ? editedDetails?.problem
                  : strategyDiagnosticData?.problem || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  problem: e.target.value,
                })
              }
              error={errors.problem}
              helperText={errors.problem && "Problem Required."}
            />
            <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
              {editedDetails?.problem !== null
                ? editedDetails?.problem?.length
                : strategyDiagnosticData?.problem?.length || 0}
              /<span>400</span> CHARACTERS
            </p>
          </div>
          <div className="url-list-item my-2">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="PTC remit"
              fullWidth
              placeholder=""
              multiline
              maxRows={4}
              maxLength={400}
              value={
                editedDetails?.ptcRemit !== null
                  ? editedDetails?.ptcRemit
                  : strategyDiagnosticData?.ptcRemit || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  ptcRemit: e.target.value,
                })
              }
              error={errors.ptcRemit}
              helperText={errors.ptcRemit && "Ptc remit Required."}
            />
            <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
              {editedDetails?.ptcRemit !== null
                ? editedDetails?.ptcRemit?.length
                : strategyDiagnosticData?.ptcRemit?.length || 0}
              /<span>400</span> CHARACTERS
            </p>
          </div>
          <div className="mb-[22px] mt-4 flex analysis-dropdown-wrapper">
            <DropdownColumn
              header="Key Pain points"
              dropdown1Label="Key Pain points 1"
              dropdown1Options={keyPainPoints}
              dropdown1Value={
                editedDetails?.keyPainPoint1 ||
                strategyDiagnosticData?.keyPainPoint1
              }
              dropdown1HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  keyPainPoint1: e.target.value,
                })
              }
              dropdown1Error={errors.keyPainPoint1}
              dropdown1HelperText={
                errors.keyPainPoint1 && "Key pain point 1 Required."
              }
              dropdown2Label="Key Pain points 2"
              dropdown2Options={keyPainPoints}
              dropdown2Value={
                editedDetails?.keyPainPoint2 ||
                strategyDiagnosticData?.keyPainPoint2
              }
              dropdown2HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  keyPainPoint2: e.target.value,
                })
              }
              dropdown2Error={errors.keyPainPoint2}
              dropdown2HelperText={
                errors.keyPainPoint2 && "Key pain point 2 Required."
              }
              dropdown3Label="Key Pain points 3"
              dropdown3Options={keyPainPoints}
              dropdown3Value={
                editedDetails?.keyPainPoint3 ||
                strategyDiagnosticData?.keyPainPoint3
              }
              dropdown3HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  keyPainPoint3: e.target.value,
                })
              }
              dropdown3Error={errors.keyPainPoint3}
              dropdown3HelperText={
                errors.keyPainPoint3 && "Key pain point 3 Required."
              }
            />
            <DropdownColumn
              header="Project Themes"
              dropdown1Label="Project Themes 1"
              dropdown1Options={useCases}
              dropdown1Value={
                editedDetails?.theme1 || strategyDiagnosticData?.theme1
              }
              dropdown1HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  theme1: e.target.value,
                })
              }
              dropdown1Error={errors.theme1}
              dropdown1HelperText={errors.theme1 && "Project theme 1 Required."}
              dropdown2Label="Project Themes 2"
              dropdown2Options={useCases}
              dropdown2Value={
                editedDetails?.theme2 || strategyDiagnosticData?.theme2
              }
              dropdown2HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  theme2: e.target.value,
                })
              }
              dropdown2Error={errors.theme2}
              dropdown2HelperText={errors.theme2 && "Project theme 2 Required."}
              dropdown3Label="Project Themes 3"
              dropdown3Options={useCases}
              dropdown3Value={
                editedDetails?.theme3 || strategyDiagnosticData?.theme3
              }
              dropdown3HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  theme3: e.target.value,
                })
              }
              dropdown3Error={errors.theme3}
              dropdown3HelperText={errors.theme3 && "Project theme 3 Required."}
            />
            <DropdownColumn
              header="Project deliverables,"
              dropdown1Label="Project deliverables 1"
              dropdown1Options={valueKPIs}
              dropdown1Value={
                editedDetails?.deliverables1 ||
                strategyDiagnosticData?.deliverables1
              }
              dropdown1HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  deliverables1: e.target.value,
                })
              }
              dropdown1Error={errors.deliverables1}
              dropdown1HelperText={
                errors.deliverables1 && "Project deliverables 1 Required."
              }
              dropdown2Label="Project deliverables 2"
              dropdown2Options={valueKPIs}
              dropdown2Value={
                editedDetails?.deliverables2 ||
                strategyDiagnosticData?.deliverables2
              }
              dropdown2HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  deliverables2: e.target.value,
                })
              }
              dropdown2Error={errors.deliverables2}
              dropdown2HelperText={
                errors.deliverables2 && "Project deliverables 2 Required."
              }
              dropdown3Label="Project deliverables 3"
              dropdown3Options={valueKPIs}
              dropdown3Value={
                editedDetails?.deliverables3 ||
                strategyDiagnosticData?.deliverables3
              }
              dropdown3HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  deliverables3: e.target.value,
                })
              }
              dropdown3Error={errors.deliverables3}
              dropdown3HelperText={
                errors.deliverables3 && "Project deliverables 3 Required."
              }
              children={
                <>
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    className="custom-dropdown-icon"
                    variant="standard"
                    label="Project deliverables 4"
                    InputPropsStyle={{ fontSize: "12px" }}
                    fullWidth
                    select
                    selectChildren={valueKPIs.map((option) => (
                      <MenuItem value={option.id}>{option.name}</MenuItem>
                    ))}
                    placeholder=""
                    value={
                      editedDetails?.deliverables4 ||
                      strategyDiagnosticData?.deliverables4
                    }
                    handleOnChangeEvent={(e) =>
                      setEditedDetails({
                        ...editedDetails,
                        deliverables4: e.target.value,
                      })
                    }
                    error={errors.deliverables4}
                    helperText={
                      errors.deliverables4 && "Project deliverables 4 Required."
                    }
                  />
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    className="custom-dropdown-icon"
                    variant="standard"
                    label="Project deliverables 5"
                    InputPropsStyle={{ fontSize: "12px" }}
                    fullWidth
                    select
                    selectChildren={valueKPIs.map((option) => (
                      <MenuItem value={option.id}>{option.name}</MenuItem>
                    ))}
                    placeholder=""
                    value={
                      editedDetails?.deliverables5 ||
                      strategyDiagnosticData?.deliverables5
                    }
                    handleOnChangeEvent={(e) =>
                      setEditedDetails({
                        ...editedDetails,
                        deliverables5: e.target.value,
                      })
                    }
                    error={errors.deliverables5}
                    helperText={
                      errors.deliverables5 && "Project deliverables 5 Required."
                    }
                  />
                </>
              }
            />
          </div>
          <div className="rangeslider-wrapper">
            <div className="rangeSlider">
              <div className="rangeslider-wrapper">
                <label className="slider-label">Budget</label>
                <div
                  className="rangeSlider flex justify-between items-center"
                  style={{ marginLeft: "12px" }}
                >
                  <p className="mr-4 text-xs font-bold opacity-40">
                    {investmentSize[0].label}
                  </p>
                  <CustomSlider
                    track={false}
                    defaultValue={0}
                    step={100000}
                    value={
                      editedDetails?.budget !== null
                        ? editedDetails?.budget
                        : Number(strategyDiagnosticData?.budget)
                    }
                    onChange={(_, newValue) =>
                      setEditedDetails({
                        ...editedDetails,
                        budget: newValue,
                      })
                    }
                    valueLabelDisplay="on"
                    marks={investmentSize.map((i) => i.value)}
                    min={0}
                    max={10000000}
                    ValueLabelComponent={StyledValueLabel}
                    valueLabelFormat={(v) => numFormatter(v)}
                    helperText={"hello"}
                  />
                  <p className="ml-4 text-xs font-bold opacity-40">
                    {investmentSize[1].label}
                  </p>
                </div>
                <FormHelperText className="mt-4 ml-8" error={errors.budget}>
                  {errors.budget && "Budget required"}
                </FormHelperText>
              </div>
              <div className="flex my-4 w-full gap-4 pt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Deadline"
                    className="custom-date-picker"
                    views={["year", "month"]}
                    inputFormat="MM/yyyy"
                    value={
                      editedDetails?.deadlineDate !== null
                        ? editedDetails?.deadlineDate
                        : moment
                            .tz(
                              strategyDiagnosticData?.deadlineDate,
                              "Australia/Sydney"
                            )
                            .format("YYYY-MM-DD")
                        ? moment
                            .tz(
                              strategyDiagnosticData?.deadlineDate,
                              "Australia/Sydney"
                            )
                            .format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(date) =>
                      setEditedDetails({
                        ...editedDetails,
                        deadlineDate: moment
                          .tz(date, "Australia/Sydney")
                          .format("YYYY-MM-DD"),
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        value={
                          editedDetails?.deadlineDate !== null
                            ? editedDetails?.deadlineDate
                            : strategyDiagnosticData?.deadlineDate
                            ? strategyDiagnosticData?.deadlineDate
                            : ""
                        }
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          style: { fontSize: "12px" },
                        }}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          style: AnalysisLabelStyle,
                        }}
                        sx={{
                          svg: { color: "#111D43" },
                          input: { color: "#111D43" },
                          label: { color: "#111D43" },
                        }}
                        error={errors.deadlineDate}
                        helperText={errors.deadlineDate && "Deadline Required."}
                      />
                    )}
                  />
                </LocalizationProvider>
                <PTInput
                  labelStyle={AnalysisLabelStyle}
                  className="custom-dropdown-icon"
                  variant="standard"
                  label="Asset Type"
                  InputPropsStyle={{ fontSize: "12px" }}
                  fullWidth
                  select
                  selectChildren={assetTypes?.map((option) => (
                    <MenuItem value={option.id}>{option.name}</MenuItem>
                  ))}
                  placeholder="Asset Type"
                  value={
                    editedDetails?.assetType ||
                    strategyDiagnosticData?.assetType
                  }
                  handleOnChangeEvent={(e) =>
                    setEditedDetails({
                      ...editedDetails,
                      assetType: e.target.value,
                    })
                  }
                  error={errors.assetType}
                  helperText={errors.assetType && "Asset type Required."}
                />
              </div>
              <div className="mt-4">
                <p className=" text-[#111D43] text-sm">Buildings</p>
                <p className=" text-[#111D43] text-xs opacity-70">Region</p>
                <Tags
                  value={
                    selectedRegions !== null
                      ? selectedRegions
                      : strategyDiagnosticData?.projectRegions?.length
                      ? strategyDiagnosticData?.projectRegions?.map((i) => {
                          return {
                            id: i?.regionId,
                            name: i?.tbRegions?.name,
                          };
                        })
                      : []
                  }
                  onChange={(e, value) => setSelectedRegions(value)}
                  options={regions}
                />
                {regionsError && (
                  <FormHelperText error={regionsError}>
                    Regions required
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end bottom-btn-wrapper">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails(PROJECT_STRATEGY_PAYLOAD);
              setSelectedRegions(null);
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
};

export default StrategyDiagnostic;
