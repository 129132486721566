export default function vcAuth(
  initialState = {
    loggedIn: false,
  },
  action
) {
  switch (action.type) {
    case 'VC_AUTH_LOGIN_SUCCESS':
      return {
        ...initialState,
        loggedIn: true,
      };

    case 'VC_AUTH_LOGIN_FAILED':
      return {
        ...initialState,
        loggedIn: false,
      };

    case 'VC_AUTH_RESET':
      return {
        ...initialState,
        loggedIn: false,
      };

    default:
      return initialState;
  }
}
