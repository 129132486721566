import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { overviewTabDTO } from "../dtos/overviewTab"
import { getSectorsData, getTechOfferingsData, getUsecasesData } from "../dropdowns"

export const loadOverviewTabData = (id, adminAnalysisTab, adminDropdowns) => {
  if (!adminAnalysisTab.overviewTabData) {
    getOverviewDetails(id)
  }
  loadOverviewTabDropdowns(adminDropdowns.sectors, adminDropdowns.useCases, adminDropdowns.techOfferings)
}

export const getOverviewDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/overview`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetOverviewTabData, overviewTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateOverviewDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/overview`, data)
    .then(res => {
      handleSuccess(res)
      getOverviewDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const loadOverviewTabDropdowns = (sectors, useCases, techOfferings) => {
  if (!sectors.length) {
    getSectorsData();
  }
  if (!useCases.length) {
    getUsecasesData();
  }
  if (!techOfferings.length) {
    getTechOfferingsData();
  }
}