export function featuredListDTO(response) {
  let featuredList = [];
  if (response && response.rows.length) {
    featuredList.count = response?.count || 0;
    featuredList.rows = response.rows.map((data) => {
      return {
        id: data?.id || 0,
        companyLogo: data?.companyLogo || '',
        watchingCount: data?.watchingCount || 0,
        publishedAt: data?.publishedAt || null,
        createdAt: data?.createdAt || null,
        proptechCompany: {
          id: data?.proptechCompany?.id || 0,
          name: data?.proptechCompany?.name || '',
        },
        proptechAnalysisOverview: {
          id: data?.proptechAnalysisOverview?.id || 0,

          proptechAnalysisUseCase1: {
            id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.id || 0,
            icon: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.icon || null,
            name: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.name || '',
          },
          proptechAnalysisUseCase2: {
            id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.id || 0,
            icon: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.icon || null,
            name: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.name || '',
          },
          proptechAnalysisUseCase3: {
            id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.id || 0,
            icon: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.icon || null,
            name: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.name || '',
          }
        }
      }
    })
  }

  return featuredList;
}
