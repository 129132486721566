import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Line } from 'react-chartjs-2';
import { DASHBOARD } from '../../constants/dashboard';
import { getVSChartData } from '../../utils/middleware/Dashboard';
import MenuButton from '../../../components/MenuButton';

export default function VsChart() {
  const { chartData } = useSelector((state) => state.vsDashboard);

  const [loading, setLoading] = React.useState(false);
  const endDate = moment().format();
  const [startDate, setStartDate] = React.useState(moment().subtract(2, 'months').format());
  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxHeight: 1,
          boxWidth: 30,
        },
        align: 'end',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  React.useEffect(() => {
    getVSChartData(startDate, endDate, setLoading);
  }, [startDate]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleVSChartData = (option) => {
    switch (option) {
      case 'Last 3 months':
        setStartDate(moment(endDate).subtract(2, 'months').format());
        break;
      case 'Last 6 months':
        setStartDate(moment(endDate).subtract(5, 'months').format());
        break;
      default:
        setStartDate(moment(endDate).subtract(0, 'months').format());
        break;
    }
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Proptechs',
        data: chartData.proptechCounts,
        borderColor: '#44D0F4',
        lineTension: 0.5,
        pointRadius: 2,
      },
      {
        label: 'Meetings',
        data: chartData.meetingCounts,
        borderColor: '#111D43',
        lineTension: 0.5,
        pointRadius: 2,
      },
    ],
  };
  return (
    <div className="dashboard-inner px-3 pb-8 full-width">
      <div className="dashboard-widget py-8 px-9 rounded-[32px] h-full bg-[#ffffff]">
        <div className="flex justify-between items-center mb-4 number-wrapper">
          <div className="font-semibold text-lg leading-7 text-[#111D43] main-title">
            {DASHBOARD.yourNum}
            <InfoOutlined
              sx={{
                color: (theme) => theme.palette.grey[400],
              }}
              className="cursor-pointer ml-2"
            />
          </div>
          <div className="flex">
            <MenuButton
              btnid="more-menu"
              id="more-btn"
              anchorEl="anchorEl1"
              menuItems={['Last 3 months', 'Last 6 months']}
              handleOnClickEvent={(option) => {
                handleVSChartData(option);
              }}
            />
          </div>
        </div>
        {loading ? (
          <Box height="300px" display="flex" alignItems="center" justifyContent="center">
            Loading...
          </Box>
        ) : (
          <Line data={data} height={'128px'} options={lineChartOptions} />
        )}
      </div>
    </div>
  );
}
