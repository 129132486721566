import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import Loader from '../../../../components/Loading'
import ProptechListCard from './ProptechListCard';
import { setActivetabData } from '../../../utils/middleware/adminProptechTab';

export default function WatchlistTab() {
  // Store
  const watchlistProptechs = useSelector(state => state.adminTechBuyer.watchlistProptechs)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminUser.SetIsSelectedProptech, true)
    dispatch(Actions.AdminUser.SetSelectedProptech, { id })
    setActivetabData(2, id, adminAnalysisTab, adminDropdowns)
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='overflow-y-auto'>
        {!!watchlistProptechs?.length && watchlistProptechs.map((proptech, index) => {
          return <ProptechListCard key={index} logo={proptech?.proptechCompanyDetails?.companyLogo} onCardClick={() => handleCardClick(proptech?.proptechCompanyDetails?.id)} title={proptech.proptechCompanyDetails.proptechCompany.name} updatedAt={proptech.createdAt} />
        })}
      </div>
    </>
  )
}
