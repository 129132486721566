import { ADMIN_RFF } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
import { getTagsData } from "../dropdowns";
import { buyerHomeProptechsListDTO } from "../dtos/adminRFF";

export const loadRFFHomeData = (id, adminRFF, adminDropdowns) => {
  getRFFAllProptechs(id, adminRFF.searchProptech);
  getRFFHomeProptechs(id);
  loadRFFHomeDropdowns(adminDropdowns.tags);
};

export const getRFFAllProptechs = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  };

  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF + `/${id}/proptechs/home`, { params })
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetProptechsData,
        buyerHomeProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      console.log(err);
      handleError(err);
    });
};

export const getRFFHomeProptechs = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF + `/${id}/home`)
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetHomeProptechsData,
        buyerHomeProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const updateRFFHomeProptechs = (
  id,
  data,
  searchProptech,
  adminDropdowns
) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .put(ADMIN_RFF + `/${id}/home`, data)
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      loadRFFHomeData(id, { searchProptech }, adminDropdowns);
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      console.log(err);
      handleError(err);
    });
};

export const updateRFFHomeTags = (id, data, clearData) => {
  ADMIN_API()
    .put(ADMIN_RFF + `/${id}/forYouTags`, data)
    .then((res) => {
      handleSuccess(res);
      getRFFHomeProptechs(id);
      clearData();
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const loadRFFHomeDropdowns = (tags) => {
  if (!tags.length) {
    getTagsData();
  }
};
