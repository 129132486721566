import { PROPTECH_NOTIFICATIONS_API } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { PROPTECH_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { notificationsListDTO } from "../dtos/notifications"

export const setBadgeVisibility = () => {
  PROPTECH_API().get(PROPTECH_NOTIFICATIONS_API + '/checkUnread').then(res => {
    handleSuccess(res)
    dispatch(Actions.ProptechUser.SetNotificationCheckUnread, res.data.data)
  }).catch((error) => handleError(error))
}

export const getNotifications = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(PROPTECH_NOTIFICATIONS_API).then(res => {
    handleSuccess(res)
    dispatch(Actions.ProptechUser.SetNotifications, notificationsListDTO(res.data.data.rows))
    dispatch(Actions.ProptechUser.SetNotificationCheckUnread, false)
    dispatch(Actions.ProptechUser.SetLoading, false)
  }).catch((error) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(error)
  })
}

export const readNotification = (proptechNotificationId) => {
  PROPTECH_API().post(PROPTECH_NOTIFICATIONS_API, {
    proptechNotificationId
  }).then(res => {
    // handleSuccess(res)
    getNotifications()
  }).catch((error) => handleError(error))
}

export const clearNotification = () => {
  PROPTECH_API().post(`${PROPTECH_NOTIFICATIONS_API}/clearAllNotification`)
    .then(res => {
      dispatch(Actions.ProptechUser.SetNotifications, [])
      handleSuccess(res)
    }).catch((error) => handleError(error))
}