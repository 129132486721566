import { ADMIN_RFF } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
import { getCountriesData } from "../dropdowns";
import { buyerProfileTabTagsListDTO } from "../dtos/adminRFF";
import { getRFFs } from "./adminRFF";

export const loadRFFProfileTabData = (id, adminRFF, adminDropdowns) => {
  getRFFProfileDetails(id);
  // getRFFProfileTagDetails(id)
  loadRFFProfileTabDropdowns(adminDropdowns.countries);
};

export const getRFFProfileDetails = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF + `/${id}`)
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(Actions.AdminRFF.SetProfileTabData, res.data.data);
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const getRFFProfileTagDetails = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF + `/${id}/tags`)
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetProfileTabTagsData,
        buyerProfileTabTagsListDTO(res.data.data)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const updateRFFProfileDetails = (
  id,
  data,
  tags,
  isArchived,
  activeSort,
  sortOrder,
  search,
  otherCountries
) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  if (otherCountries) {
    data.otherCountries = otherCountries;
  }
  if (tags) {
    updateRFFProfileTagDetails(id, tags);
  }

  ADMIN_API()
    .put(ADMIN_RFF + `/${id}`, data)
    .then((res) => {
      handleSuccess(res);
      getRFFProfileDetails(id);
      getRFFs(isArchived, activeSort, sortOrder, search, false);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const updateRFFProfileTagDetails = (id, data) => {
  ADMIN_API()
    .put(ADMIN_RFF + `/${id}/tags`, { tagIds: data.map((a) => a.id) })
    .then((res) => {
      handleSuccess(res);
      getRFFProfileTagDetails(id);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const loadRFFProfileTabDropdowns = (countries) => {
  if (!countries.length) {
    getCountriesData();
  }
};
