import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import Ratings from '../../../../components/GenericComponents/Ratings';
import DropdownColumn from '../../DropdownColumn';
import { updateNumbersDetails } from '../../../utils/middleware/numbersTab';
import ConfirmModal from '../../ConfirmModal';
import Loader from '../../../../components/Loading';
import Chart from '../../Chart';
import { NUMBERS_TAB_ERRORS, NUMBERS_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { trimQuery } from '../../../../utils/utility/utility';
import { RATING_CHART } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function NumbersTab(props) {
  const { setUnsaveChange } = props

  // Store
  const { numbersTabData, radarDetails} = useSelector(state => state.adminAnalysisTab)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { noOfCustomers, noOfCountries, grossMargins, growthBlockers } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(NUMBERS_TAB_PAYLOAD);
  const [errors, setErrors] = useState(NUMBERS_TAB_ERRORS);
  const [openModal, setOpenModal] = useState(false);
  const [placeRadars, setPlaceRadars] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const setPlaceRadarDetails = () => {
    let updatedData = numbersTabData?.placeRadars?.length
      ? radarDetails?.numbersRadar?.rows?.map(i => ({
          ...i,
          value: Number(numbersTabData?.placeRadars?.find(j => j.placeRadarPoint.id === i.id)?.value),
        }))
      : radarDetails?.numbersRadar?.rows?.map(i => ({ ...i, value: 0 }));
    setPlaceRadars(updatedData || []);
  };

  useEffect(() => {
    setPlaceRadarDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange, placeRadars, numbersTabData?.placeRadars)
  }, [editedDetails, placeRadars]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : !numbersTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = numbersTabData?.[key]));

    handleCloseModal()
    try {
      await updateNumbersDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null)),
        }, placeRadars)
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const totalStartCount = () => {
    return (editedDetails?.cashProfile !== null ? editedDetails?.cashProfile : numbersTabData?.cashProfile) +
      (editedDetails?.profitability !== null ? editedDetails?.profitability : numbersTabData?.profitability) +
      (editedDetails?.stickiness !== null ? editedDetails?.stickiness : numbersTabData?.stickiness) +
      (editedDetails?.speedToScale !== null ? editedDetails?.speedToScale : numbersTabData?.speedToScale) +
      (editedDetails?.salesConversion !== null ? editedDetails?.salesConversion : numbersTabData?.salesConversion) +
      (editedDetails?.customerType !== null ? editedDetails?.customerType : numbersTabData?.customerType) || 0
  }

  const onChangePlaceRadarValue = (id, val) => {
    setPlaceRadars(placeRadars?.map(i => (i.id === id ? { ...i, value: val } : i)));
  };

  const totalPlaceRadarsCount = () => {
    return placeRadars.reduce((sum, item) => sum + Number(item.value), 0);
  }

  const onResetRadarPoint = (id) => {
    setPlaceRadars(placeRadars.map(i => (i.id === id ? { ...i, value: 0 } : i)));
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className='flex flex-col justify-between pb-7'>
        <h2 className='mb-2'>Connect radar</h2>
        <div className='analysis-rating-chart-block flex'>
          <div className='rating-block'>
            <Ratings className='flex justify-between rating-items' label="Cash profile" value={editedDetails?.cashProfile !== null ? editedDetails?.cashProfile : numbersTabData?.cashProfile} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, cashProfile: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, cashProfile: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Profitability" value={editedDetails?.profitability !== null ? editedDetails?.profitability : numbersTabData?.profitability} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, profitability: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, profitability: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Stickiness" value={editedDetails?.stickiness !== null ? editedDetails?.stickiness : numbersTabData?.stickiness} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, stickiness: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, stickiness: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Speed to scale" value={editedDetails?.speedToScale !== null ? editedDetails?.speedToScale : numbersTabData?.speedToScale} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, speedToScale: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, speedToScale: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Sales conversion" value={editedDetails?.salesConversion !== null ? editedDetails?.salesConversion : numbersTabData?.salesConversion} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, salesConversion: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, salesConversion: 0 })} />
            <Ratings className='flex justify-between last-rating-items' label="Customer type" value={editedDetails?.customerType !== null ? editedDetails?.customerType : numbersTabData?.customerType} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, customerType: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, customerType: 0 })}
              total={<p className={`text-xs text-right font-semibold uppercase ${totalStartCount() >= RATING_CHART.limitForError ? 'text-error' : 'text-light-grey'}`}>
                {totalStartCount()}/<span>{RATING_CHART.maxLimit}</span></p>} />
          </div>
          <div className='chart-block'>
            <div className='box-border bg-[#F3F3F5]'>
              <Chart labels={["Cash profile", "Profitability", "Stickiness", "Speed to scale", ["Sales", "conversion"], ["Customer", "type"]]} items={[
                editedDetails?.cashProfile !== null ? editedDetails?.cashProfile : numbersTabData?.cashProfile,
                editedDetails?.profitability !== null ? editedDetails?.profitability : numbersTabData?.profitability,
                editedDetails?.stickiness !== null ? editedDetails?.stickiness : numbersTabData?.stickiness,
                editedDetails?.speedToScale !== null ? editedDetails?.speedToScale : numbersTabData?.speedToScale,
                editedDetails?.salesConversion !== null ? editedDetails?.salesConversion : numbersTabData?.salesConversion,
                editedDetails?.customerType !== null ? editedDetails?.customerType : numbersTabData?.customerType
              ]}
                step={2}
                maximumValue={10} />
            </div>
          </div>
        </div>
        <h2 className='mb-2'>Place radar</h2>
        <div className='analysis-rating-chart-block flex'>
          <div className='rating-block'>
              {
                placeRadars?.map((item, idx) => (
                  <Ratings color="#009BE2" key={item?.id} className='flex justify-between rating-items' label={item?.radarName} value={item?.value} max={10} onChange={(event, newValue) => onChangePlaceRadarValue(item.id, newValue)} onResetClick={() => onResetRadarPoint(item.id)} 
                  total={idx === placeRadars.length -1 && <p className={`text-xs text-right font-semibold uppercase ${totalPlaceRadarsCount() >= RATING_CHART.limitForError ? 'text-error' : 'text-light-grey'}`}>
                  {totalPlaceRadarsCount()}/<span>{RATING_CHART.maxLimit}</span></p>} 
                  />
                ))
              }
            </div>
            <div className='chart-block'>
              <div className='box-border bg-[#F3F3F5]'>
                <Chart color="#009BE2" bgColor="#009BE233" labels={placeRadars?.map(i => i.radarName?.split(" "))} items={placeRadars?.map(i => i.value)}
                  step={2}
                  maximumValue={10} />
              </div>
            </div>
        </div>
        <div className='flex justify-between analysis-dropdown-wrapper'>
          <DropdownColumn
            header="Current"
            dropdown1Label="No. of customers"
            dropdown1Options={noOfCustomers}
            dropdown1Value={editedDetails?.currentNoOfCustomers || numbersTabData?.currentNoOfCustomers}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, currentNoOfCustomers: e.target.value })}
            dropdown1Error={errors.currentNoOfCustomers}
            dropdown1HelperText={errors.currentNoOfCustomers && "No. of Customers Required."}
            dropdown2Label="No. of countries"
            dropdown2Options={noOfCountries}
            dropdown2Value={editedDetails?.currentNoOfCountries || numbersTabData?.currentNoOfCountries}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, currentNoOfCountries: e.target.value })}
            dropdown2Error={errors.currentNoOfCountries}
            dropdown2HelperText={errors.currentNoOfCountries && "No. of Countries Required."}
            dropdown3Label="Gross margin %"
            dropdown3Options={grossMargins}
            dropdown3Value={editedDetails?.currentGrossMargin || numbersTabData?.currentGrossMargin}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, currentGrossMargin: e.target.value })}
            dropdown3Error={errors.currentGrossMargin}
            dropdown3HelperText={errors.currentGrossMargin && "Gross Margin % Required."}
          />
          <DropdownColumn
            header="Future"
            dropdown1Label="No. of customers"
            dropdown1Options={noOfCustomers}
            dropdown1Value={editedDetails?.futureNoOfCustomers || numbersTabData?.futureNoOfCustomers}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, futureNoOfCustomers: e.target.value })}
            dropdown1Error={errors.futureNoOfCustomers}
            dropdown1HelperText={errors.futureNoOfCustomers && "No. of Customers Required."}
            dropdown2Label="No. of countries"
            dropdown2Options={noOfCountries}
            dropdown2Value={editedDetails?.futureNoOfCountries || numbersTabData?.futureNoOfCountries}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, futureNoOfCountries: e.target.value })}
            dropdown2Error={errors.futureNoOfCountries}
            dropdown2HelperText={errors.futureNoOfCountries && "No. of Countries Required."}
            dropdown3Label="Gross margin %"
            dropdown3Options={grossMargins}
            dropdown3Value={editedDetails?.futureGrossMargin || numbersTabData?.futureGrossMargin}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, futureGrossMargin: e.target.value })}
            dropdown3Error={errors.futureGrossMargin}
            dropdown3HelperText={errors.futureGrossMargin && "Gross Margin % Required."}
          />
          <DropdownColumn
            header="Growth blockers"
            dropdown1Label="Growth blocker 1"
            dropdown1Options={growthBlockers}
            dropdown1Value={editedDetails?.growthBlocker1 || numbersTabData?.growthBlocker1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, growthBlocker1: e.target.value })}
            dropdown1Error={errors.growthBlocker1}
            dropdown1HelperText={errors.growthBlocker1 && "Growth Blocker 1 Required."}
            dropdown2Label="Growth blocker 2"
            dropdown2Options={growthBlockers}
            dropdown2Value={editedDetails?.growthBlocker2 || numbersTabData?.growthBlocker2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, growthBlocker2: e.target.value })}
            dropdown2Error={errors.growthBlocker2}
            dropdown2HelperText={errors.growthBlocker2 && "Growth Blocker 2 Required."}
            dropdown3Label="Growth blocker 3"
            dropdown3Options={growthBlockers}
            dropdown3Value={editedDetails?.growthBlocker3 || numbersTabData?.growthBlocker3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, growthBlocker3: e.target.value })}
            dropdown3Error={errors.growthBlocker3}
            dropdown3HelperText={errors.growthBlocker3 && "Growth Blocker 3 Required."}
          />
        </div>
        <div className='flex justify-end bottom-btn-wrapper'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit"
            className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => {
              setEditedDetails(NUMBERS_TAB_PAYLOAD)
              setPlaceRadarDetails()
            }} />
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>}
            type="submit"
            className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()} />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div>
    </>
  )
}
