export function adminForYouProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        updatedAt: datum?.updatedAt || "",
        createdAt: datum?.createdAt || "",
        vcCompanyDetails: datum?.vcCompanyDetails
          ? {
              id: datum?.vcCompanyDetails?.id || 0,
              companyLogo: datum?.vcCompanyDetails?.companyLogo || "",
              vcCompany: datum?.vcCompanyDetails?.vcCompany
                ? {
                    id: datum?.vcCompanyDetails?.vcCompany?.id || 0,
                    name: datum?.vcCompanyDetails?.vcCompany?.name || "",
                  }
                : null,
            }
          : null,
        vcProptechForYouTag:
          datum?.vcProptechForYouTag?.length !== 0
            ? datum?.vcProptechForYouTag?.map((tag) => {
                return {
                  id: tag?.tag?.id || 0,
                  name: tag?.tag?.name || "",
                };
              })
            : [],
      };
    });
  }

  return proptechs;
}

export function adminForYouProptechsPlaceListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        updatedAt: datum?.updatedAt || "",
        createdAt: datum?.createdAt || "",
        tbCompanyDetails: datum?.tbCompanyDetails
          ? {
              id: datum?.tbCompanyDetails?.id || 0,
              companyLogo: datum?.tbCompanyDetails?.companyLogo || "",
              tbCompany: datum?.tbCompanyDetails?.tbCompany
                ? {
                    id: datum?.tbCompanyDetails?.tbCompany?.id || 0,
                    name: datum?.tbCompanyDetails?.tbCompany?.name || "",
                  }
                : null,
            }
          : null,
        tbProptechForYouTag:
          datum?.tbProptechForYouTag?.length !== 0
            ? datum?.tbProptechForYouTag?.map((tag) => {
                return {
                  id: tag?.tag?.id || 0,
                  name: tag?.tag?.name || "",
                };
              })
            : [],
      };
    });
  }

  return proptechs;
}

export function adminForYouProptechsRFFListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        updatedAt: datum?.updatedAt || "",
        createdAt: datum?.createdAt || "",
        rffCompanyDetails: datum?.rffCompanyDetails
          ? {
              id: datum?.rffCompanyDetails?.id || 0,
              companyLogo: datum?.rffCompanyDetails?.companyLogo || "",
              rffCompany: datum?.rffCompanyDetails?.rffCompany
                ? {
                    id: datum?.rffCompanyDetails?.rffCompany?.id || 0,
                    name: datum?.rffCompanyDetails?.rffCompany?.name || "",
                  }
                : null,
            }
          : null,
        rffProptechForYouTag:
          datum?.rffProptechForYouTag?.length !== 0
            ? datum?.rffProptechForYouTag?.map((tag) => {
                return {
                  id: tag?.tag?.id || 0,
                  name: tag?.tag?.name || "",
                };
              })
            : [],
      };
    });
  }

  return proptechs;
}

export function adminWatchlistProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || "",
        vcCompanyDetails: datum?.vcCompanyDetails
          ? {
              id: datum?.vcCompanyDetails?.id || 0,
              companyLogo: datum?.vcCompanyDetails?.companyLogo || "",
              vcCompany: datum?.vcCompanyDetails?.vcCompany
                ? {
                    id: datum?.vcCompanyDetails?.vcCompany?.id || 0,
                    name: datum?.vcCompanyDetails?.vcCompany?.name || "",
                  }
                : null,
            }
          : null,
      };
    });
  }

  return proptechs;
}
export function adminWatchlistProptechsPlaceListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || "",
        tbCompanyDetails: datum?.tbCompanyDetails
          ? {
              id: datum?.tbCompanyDetails?.id || 0,
              companyLogo: datum?.tbCompanyDetails?.companyLogo || "",
              tbCompany: datum?.tbCompanyDetails?.tbCompany
                ? {
                    id: datum?.tbCompanyDetails?.tbCompany?.id || 0,
                    name: datum?.tbCompanyDetails?.tbCompany?.name || "",
                  }
                : null,
            }
          : null,
      };
    });
  }

  return proptechs;
}

export function adminWatchlistProptechsRFFListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || "",
        rffCompanyDetails: datum?.rffCompanyDetails
          ? {
              id: datum?.rffCompanyDetails?.id || 0,
              companyLogo: datum?.rffCompanyDetails?.companyLogo || "",
              rffCompany: datum?.rffCompanyDetails?.rffCompany
                ? {
                    id: datum?.rffCompanyDetails?.rffCompany?.id || 0,
                    name: datum?.rffCompanyDetails?.rffCompany?.name || "",
                  }
                : null,
            }
          : null,
      };
    });
  }

  return proptechs;
}
