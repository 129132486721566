import { ADMIN_PROPTECHS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
import {
  adminForYouProptechsListDTO,
  adminForYouProptechsPlaceListDTO,
  adminForYouProptechsRFFListDTO,
  adminWatchlistProptechsListDTO,
  adminWatchlistProptechsPlaceListDTO,
  adminWatchlistProptechsRFFListDTO,
} from "../dtos/adminProptechTab";
import { loadCompanyTabData } from "./companyTab";

/**
 * Set active tab info for admin proptech tabs
 * @param {number} tab - selected active proptech tab id eg: foryou
 * @param {number} id  - selected proptech id
 * @param {object} adminAnalysisTab - admin analysis tab
 * @param {array} adminDropdowns - admin dropdowns
 * @param {number} forYouTabType - selected for you tab id
 * @param {number} watchlistTabType - selected watchlist tab id
 * @param {number} homeTabType - selected home tab id
 */
export const setActivetabData = (
  tab,
  id,
  adminAnalysisTab,
  adminDropdowns,
  forYouTabType = 0,
  watchlistTabType = 0,
  homeTabType = 0
) => {
  dispatch(Actions.AdminUser.SetProptechActiveTabIndex, tab);

  switch (tab) {
    case 2:
      id && loadCompanyTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 3:
      id && getHomeProptechs(id, homeTabType);
      break;

    case 4:
      id && getForYouProptechs(id, forYouTabType);
      break;

    case 5:
      id && getWatchlistProptechs(id, watchlistTabType);
      break;

    default:
      break;
  }
};

export const getForYouProptechs = (id, tabType) => {
  switch (tabType) {
    case 0:
      id && getProptechTabForYouConnect(id);
      break;
    case 1:
      id && getProptechTabForYouPlace(id);
      break;
    case 2:
      id && getProptechTabForYouRFF(id);
      break;
    default:
      break;
  }
};

const getProptechTabForYouConnect = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/forYou`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetForYouProptechs,
        adminForYouProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

const getProptechTabForYouPlace = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/place/forYou`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetForYouProptechsPlace,
        adminForYouProptechsPlaceListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

const getProptechTabForYouRFF = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/rff/forYou`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetForYouProptechsRFF,
        adminForYouProptechsRFFListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

export const getHomeProptechs = (id, tabType) => {
  switch (tabType) {
    case 0:
      id && getHomeProptechsConnect(id);
      break;
    case 1:
      id && getHomeProptechsPlace(id);
      break;
    case 2:
      id && getHomeProptechsRFF(id);
      break;
    default:
      break;
  }
};

const getHomeProptechsConnect = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/vc/home`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetHomeProptechs,
        adminWatchlistProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

const getHomeProptechsPlace = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/tb/home`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetHomeProptechsPlace,
        adminWatchlistProptechsPlaceListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

const getHomeProptechsRFF = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/rff/home`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetHomeProptechsRFF,
        adminWatchlistProptechsRFFListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

export const getWatchlistProptechs = (id, tabType) => {
  switch (tabType) {
    case 0:
      id && getWatchlistProptechsConnect(id);
      break;
    case 1:
      id && getWatchlistProptechsPlace(id);
      break;
    case 2:
      id && getWatchlistProptechsRFF(id);
    default:
      break;
  }
};

const getWatchlistProptechsConnect = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/watchList`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetWatchlistProptechs,
        adminWatchlistProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

const getWatchlistProptechsPlace = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/place/watchList`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetWatchlistProptechsPlace,
        adminWatchlistProptechsPlaceListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

const getWatchlistProptechsRFF = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/rff/watchList`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminUser.SetWatchlistProptechsRFF,
        adminWatchlistProptechsRFFListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};
