import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import ProptechCard from "./ProptechCard";
import { updateSelectedRFFTabs } from "../../../utils/middleware/adminRFF";
import Loader from "../../../../components/Loading";
import { dispatch } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PermissionTab() {
  const {
    navigationTabs,
    selectedProptechTabs,
    selectedRFF,
    activePermissionsTab,
    isLoading,
  } = useSelector((state) => state.adminRFF);

  const handleChange = (event, newValue) => {
    dispatch(Actions.AdminInvestor.SetActivePermissionsTab, newValue);
  };

  const onChangeStatus = (e, id) => {
    updateSelectedRFFTabs(
      {
        navigationId: activePermissionsTab + 1,
        tabId: id,
        status: e.target.checked,
      },
      selectedRFF?.id
    );
  };

  return (
    <>
      {isLoading && <Loader />}

      <Box sx={{ width: "100%", paddingBottom: "60px" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "sticky",
            top: 0,
          }}
          className="bg-white z-10"
        >
          <Tabs
            value={activePermissionsTab}
            onChange={handleChange}
            aria-label="tabs"
            variant="scrollable"
          >
            {navigationTabs.length !== 0 &&
              navigationTabs.map((item, index) => (
                <Tab label={item.name} {...a11yProps(index)} key={item.id} />
              ))}
          </Tabs>
        </Box>

        <TabPanel value={activePermissionsTab} index={activePermissionsTab}>
          {selectedProptechTabs.length !== 0 &&
            selectedProptechTabs.map((item, index) => (
              <ProptechCard
                key={item.id}
                title={item.name}
                checked={item?.rffTabsAssigned?.some(
                  (i) => i.navigationId === activePermissionsTab + 1
                )}
                onChecked={(e) => onChangeStatus(e, item.id)}
              />
            ))}
        </TabPanel>
      </Box>
    </>
  );
}
