import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import OverviewTab from "../../../../vc/components/ProptechDetails/OverviewTab";
import MarketTab from "../../../../vc/components/ProptechDetails/MarketTab";
import RaiseTab from "../../../../vc/components/ProptechDetails/RaiseTab";
import NumbersTab from "../../../../vc/components/ProptechDetails/NumberTab";
import RaiseOverview from "../../../../vc/components/ProptechDetails/RaiseOverview";
import TeamTab from "../../../../vc/components/ProptechDetails/TeamTab";
import DesignReview from "../../../../vc/components/ProptechDetails/DesignReview";
import eyeIcon from "../../../../assets/Icons/ic-eye.svg";
import calenderIcon from "../../../../assets/Icons/ic-calender.svg";
import mapIcon from "../../../../assets/Icons/ic-map.svg";
import paymentIcon from "../../../../assets/Icons/ic-payment.svg";
import trendIcon from "../../../../assets/Icons/ic-trend.svg";
import {
  getOverviewDetails,
  getMarketDetails,
  getNumbersDetails,
  getRaiseDetails,
  getProptechDetails,
  getTeamsDetails,
  getDesignReviewDetails,
  getRaiseOverviewDetails,
  getMarketInsightsDetails,
  getCompanyProfileDetails,
} from "../../../utils/middleware/previewScreen";
import { dispatchNoPayload } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";
import "../previewScreen.scss";
import MarketInsightTab from "../../../../vc/components/ProptechDetails/MarketInsightTab";
import CompanyProfileTab from "../../../../vc/components/ProptechDetails/CompanyProfileTab";
import Loader from "../../../../components/Loading";
import CompanyLogo from "../../../../place/components/CompanyLogo";
import { Tooltip } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full"
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="h-full">
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ConnectPreviewScreen({tabId}) {
  const [value, setValue] = useState(0);

  const { proptechDetails } = useSelector((state) => state.vcProptechDetails);
  const selectedProptech = useSelector(
    (state) => state.adminUser.selectedProptech
  );
  const vcProptechDetails = useSelector((state) => state.vcProptechDetails);
  const isLoading = useSelector((state) => state.adminUser.isLoading);

  useEffect(() => {
    if (selectedProptech?.id && tabId === 1) {
      if (!vcProptechDetails?.proptechDetails  && tabId === 1) {
        getProptechDetails(selectedProptech.id);
      }
      if (!vcProptechDetails?.raiseOverviewDetails) {
        getRaiseOverviewDetails(selectedProptech.id);
      }
      setActiveTabData(0, selectedProptech.id, vcProptechDetails);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setActiveTabData(tab, id, vcProptechDetails) {
    switch (tab) {
      case 0:
        !vcProptechDetails?.overviewDetails && getOverviewDetails(id);
        break;

      case 1:
        !vcProptechDetails?.marketDetails && getMarketDetails(id);
        break;

      case 2:
        !vcProptechDetails?.numbersDetails && getNumbersDetails(id);
        break;

      case 3:
        !vcProptechDetails?.raiseDetails && getRaiseDetails(id);
        break;

      case 4:
        !vcProptechDetails?.teamDetails && getTeamsDetails(id);
        break;

      case 5:
        !vcProptechDetails?.designReviewDetails && getDesignReviewDetails(id);
        break;

      case 6:
        !vcProptechDetails?.marketInsightsDetails &&
          getMarketInsightsDetails(id);
        break;

      case 7:
        !vcProptechDetails?.companyProfileDetails &&
          getCompanyProfileDetails(id);
        break;

      default:
        !vcProptechDetails?.overviewDetails && getOverviewDetails(id);
        break;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTabData(newValue, selectedProptech.id, vcProptechDetails);
  };

  useEffect(
    () => () =>
      dispatchNoPayload(Actions.VCProptechDetails.ProptechDetailsReset),
    []
  );
  return (
    <>
      {isLoading && <Loader />}

      <div className="w-full h-full flex flex-col proptech-detail-wrapper overflow-y-auto md:overflow-inherit">
        <div className="top-menu bg-[#F7F7F7]">
          <div className="left_col">
            <div className="nav-top flex">
              <CompanyLogo
                companyLogo={proptechDetails?.companyLogo}
                name={proptechDetails?.proptechCompany?.name}
              />
              <div className="card-info">
                <h2>{proptechDetails?.proptechCompany?.name || "No Name"}</h2>
                <ul className="listing-item">
                  <li>
                    <img src={eyeIcon} alt="Icon" />
                    {proptechDetails?.watchingCount} watching
                  </li>
                  <li>
                    <img src={calenderIcon} alt="Icon" />
                    {proptechDetails?.publishedAt ? (
                      <>
                        Listed{" "}
                        {moment
                          .tz(
                            proptechDetails?.publishedAt,
                            "YYYYMMDD",
                            "Australia/Sydney"
                          )
                          .fromNow()}
                      </>
                    ) : (
                      `Not listed`
                    )}
                  </li>
                </ul>
                <ul className="listing-item badge-item">
                  <Tooltip title={"Region"} arrow>
                    <li>
                      <img src={mapIcon} alt="Icon" />
                      {proptechDetails?.region?.name || "-"}
                    </li>
                  </Tooltip>
                  <Tooltip title={"Amount raising"} arrow>
                    <li>
                      <img src={trendIcon} alt="Icon" />
                      {proptechDetails?.revenueRange?.name || "-"}
                    </li>
                  </Tooltip>

                  <Tooltip title={"Investment Round"} arrow>
                    <li>
                      <img src={paymentIcon} alt="Icon" />
                      {proptechDetails?.investmentRound?.name || "-"}
                    </li>
                  </Tooltip>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="protech-detail-inner ">
          <div className="tab-wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className="tab-heading-wrapper md:sticky lg:top-0  z-10">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Market" {...a11yProps(1)} />
                  <Tab label="Numbers" {...a11yProps(2)} />
                  <Tab label="Raise" {...a11yProps(3)} />
                  <Tab label="Team" {...a11yProps(4)} />
                  <Tab label="Design Review" {...a11yProps(5)} />
                  <Tab
                    label={
                      <div>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="16" height="16" rx="8" fill="#FFD84F" />
                          <path
                            d="M13 6.42V6.57C13.0002 6.68683 12.9596 6.80004 12.885 6.89L8.38501 12.32C8.28983 12.4343 8.14873 12.5002 8.00001 12.5C7.8513 12.5002 7.71019 12.4343 7.61501 12.32L3.11501 6.89C3.04047 6.80004 2.99979 6.68683 3.00001 6.57V6.42C2.99909 6.29436 3.0455 6.17297 3.13001 6.08L5.20501 3.825C5.39379 3.61865 5.66035 3.50079 5.94001 3.5H10.06C10.3397 3.50079 10.6062 3.61865 10.795 3.825L12.87 6.08C12.9545 6.17297 13.0009 6.29436 13 6.42Z"
                            fill="#FFF5CB"
                          />
                        </svg>
                        Market insights
                      </div>
                    }
                    {...a11yProps(6)}
                  />
                  <Tab
                    label={
                      <div>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="16" height="16" rx="8" fill="#FFD84F" />
                          <path
                            d="M13 6.42V6.57C13.0002 6.68683 12.9596 6.80004 12.885 6.89L8.38501 12.32C8.28983 12.4343 8.14873 12.5002 8.00001 12.5C7.8513 12.5002 7.71019 12.4343 7.61501 12.32L3.11501 6.89C3.04047 6.80004 2.99979 6.68683 3.00001 6.57V6.42C2.99909 6.29436 3.0455 6.17297 3.13001 6.08L5.20501 3.825C5.39379 3.61865 5.66035 3.50079 5.94001 3.5H10.06C10.3397 3.50079 10.6062 3.61865 10.795 3.825L12.87 6.08C12.9545 6.17297 13.0009 6.29436 13 6.42Z"
                            fill="#FFF5CB"
                          />
                        </svg>
                        Company profile
                      </div>
                    }
                    {...a11yProps(7)}
                  />
                </Tabs>
              </Box>
              <div className="relative md:flex md:items-start">
                <div className="tab-panels">
                  <TabPanel value={value} index={0}>
                    <OverviewTab />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <MarketTab />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <NumbersTab />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <RaiseTab />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <TeamTab />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <DesignReview />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <MarketInsightTab />
                  </TabPanel>
                  <TabPanel value={value} index={7}>
                    <CompanyProfileTab />
                  </TabPanel>
                </div>
                <RaiseOverview />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
