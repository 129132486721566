import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../../utils/utility/store'
import { Actions } from '../../../../../store/actions'
import Loader from '../../../../../components/Loading'
import { setActiveTab } from '../../../../utils/middleware/adminInvestors'
import ProptechListCard from '../ProptechListCard'
import { forYouTabMessage1 } from '../../../../constants'

// Get for you Component in Proptech Tab
export default function ForYouTabAdminConnect() {
  // Store
  const forYouProptechs = useSelector(state => state.adminUser.forYouProptechs)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminInvestor = useSelector(state => state.adminInvestor)

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 1)
    dispatch(Actions.AdminInvestor.SetActiveTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminInvestor.SetIsSelectedInvestor, true)
    dispatch(Actions.AdminInvestor.SetSelectedInvestor, { id })
    setActiveTab(0, id, adminInvestor, adminDropdowns)
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='overflow-y-auto'>
        {forYouProptechs?.length !== 0 ? forYouProptechs.map((proptech, index) => {
          return <ProptechListCard key={index} logo={proptech?.vcCompanyDetails?.companyLogo} onCardClick={() => handleCardClick(proptech?.vcCompanyDetails?.id)} title={proptech.vcCompanyDetails.vcCompany.name} createdAt={proptech?.createdAt} tags={proptech?.vcProptechForYouTag} />
        }) : <div>
              <h3>{forYouTabMessage1}</h3>
            </div>}
      </div>
    </>
  )
}
