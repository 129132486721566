import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import Ratings from '../../../../components/GenericComponents/Ratings';
import Loader from '../../../../components/Loading';
import { AnalysisLabelStyle } from '../../../../proptech/constants/General';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { trimQuery } from '../../../../utils/utility/utility';
import { RATING_CHART } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';
import { updateDesignReviewDetails } from '../../../utils/middleware/designReviewTab';
import { DESIGN_REVIEW_TAB_ERRORS, DESIGN_REVIEW_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import Chart from '../../Chart';
import ConfirmModal from '../../ConfirmModal';
import { designReviewChartLabels } from '../../../../place/utils/chartData';

export default function DesignReviewTab(props) {
  const { setUnsaveChange } = props

  // Store
  const { designReviewTabData, radarDetails} = useSelector(state => state.adminAnalysisTab)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)

  // State
  const [editedDetails, setEditedDetails] = useState(DESIGN_REVIEW_TAB_PAYLOAD);
  const [errors, setErrors] = useState(DESIGN_REVIEW_TAB_ERRORS);
  const [openModal, setOpenModal] = useState(false);
  const [placeRadars, setPlaceRadars] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const switchPositions = (arr) => {
    if(arr?.length <= 3)  {
      return arr
    }
    arr?.sort((a, b) => {
      let indexA = designReviewChartLabels.indexOf(a?.radarName);
      let indexB = designReviewChartLabels.indexOf(b?.radarName);

      return indexA - indexB;
    });

    return arr;
  };


  const setPlaceRadarDetails = () => {
    let updatedData = designReviewTabData?.placeRadars?.length
      ? radarDetails?.designReviewRadar?.rows?.map(i => ({
          ...i,
          value: Number(designReviewTabData?.placeRadars?.find(j => j.placeRadarPoint.id === i.id)?.value),
        }))
      : radarDetails?.designReviewRadar?.rows?.map(i => ({ ...i, value: 0 }));
    setPlaceRadars(updatedData || []);
  };

  useEffect(() => {
    setPlaceRadarDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange, placeRadars, designReviewTabData?.placeRadars)
  }, [editedDetails, placeRadars]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : !designReviewTabData?.[error]
      }
    }, placeRadars)
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = designReviewTabData?.[key]));

    handleCloseModal()
    try {
      await updateDesignReviewDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => (v !== null && v !== ''))),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null)),
        }, placeRadars)
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const totalStartCount = () => {
    return (editedDetails?.visualDesignBranding !== null ? editedDetails?.visualDesignBranding : designReviewTabData?.visualDesignBranding) +
      (editedDetails?.intuitiveness !== null ? editedDetails?.intuitiveness : designReviewTabData?.intuitiveness) +
      (editedDetails?.efficiency !== null ? editedDetails?.efficiency : designReviewTabData?.efficiency) +
      (editedDetails?.feedback !== null ? editedDetails?.feedback : designReviewTabData?.feedback) +
      (editedDetails?.errorPreventionRecovery !== null ? editedDetails?.errorPreventionRecovery : designReviewTabData?.errorPreventionRecovery) +
      (editedDetails?.support !== null ? editedDetails?.support : designReviewTabData?.support) || 0
  }

  const onChangePlaceRadarValue = (id, val) => {
    setPlaceRadars(placeRadars?.map(i => (i.id === id ? { ...i, value: val } : i)));
  };

  const totalPlaceRadarsCount = () => {
    return placeRadars.reduce((sum, item) => sum + Number(item.value), 0);
  }

  const onResetRadarPoint = (id) => {
    setPlaceRadars(placeRadars.map(i => (i.id === id ? { ...i, value: 0 } : i)));
  };

  const checkRadarPointValue = (value) => {
    return value > 5 ? 5 : value
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='flex flex-col justify-between pb-14'>
        <h2 className='mb-2'>Connect radar</h2>
        <div className='analysis-rating-chart-block flex mb-0'>
          <div className='rating-block'>
            <Ratings className='flex justify-between rating-items' label="Visual design and branding" value={editedDetails?.visualDesignBranding !== null ? editedDetails?.visualDesignBranding : designReviewTabData?.visualDesignBranding} max={5} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, visualDesignBranding: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, visualDesignBranding: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Intuitiveness" value={editedDetails?.intuitiveness !== null ? editedDetails?.intuitiveness : designReviewTabData?.intuitiveness} max={5} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, intuitiveness: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, intuitiveness: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Efficiency" value={editedDetails?.efficiency !== null ? editedDetails?.efficiency : designReviewTabData?.efficiency} max={5} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, efficiency: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, efficiency: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Feedback" value={editedDetails?.feedback !== null ? editedDetails?.feedback : designReviewTabData?.feedback} max={5} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, feedback: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, feedback: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Error prevention and recovery" value={editedDetails?.errorPreventionRecovery !== null ? editedDetails?.errorPreventionRecovery : designReviewTabData?.errorPreventionRecovery} max={5} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, errorPreventionRecovery: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, errorPreventionRecovery: 0 })} />
            <Ratings className='flex justify-between last-rating-items' label="Support" value={editedDetails?.support !== null ? editedDetails?.support : designReviewTabData?.support} max={5} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, support: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, support: 0 })}
              total={<p className={`text-xs text-right font-semibold uppercase ${totalStartCount() >= RATING_CHART.designReviewLimitForError ? 'text-error' : 'text-light-grey'}`}>
                {totalStartCount()}/<span>{RATING_CHART.designReviewMaxLimit}</span></p>} />
          </div>
          <div className='chart-block'>
            <div className='box-border bg-[#F3F3F5]'>
              <Chart labels={["Visual design and branding", "Intuitiveness", "Efficiency", "Feedback", ["Error prevention", "and recovery"], "Support"]} items={[
                editedDetails?.visualDesignBranding !== null ? editedDetails?.visualDesignBranding : checkRadarPointValue(designReviewTabData?.visualDesignBranding),
                editedDetails?.intuitiveness !== null ? editedDetails?.intuitiveness : checkRadarPointValue(designReviewTabData?.intuitiveness),
                editedDetails?.efficiency !== null ? editedDetails?.efficiency : checkRadarPointValue(designReviewTabData?.efficiency),
                editedDetails?.feedback !== null ? editedDetails?.feedback : checkRadarPointValue(designReviewTabData?.feedback),
                editedDetails?.errorPreventionRecovery !== null ? editedDetails?.errorPreventionRecovery : checkRadarPointValue(designReviewTabData?.errorPreventionRecovery),
                editedDetails?.support !== null ? editedDetails?.support : checkRadarPointValue(designReviewTabData?.support)
              ]}
                step={1}
                maximumValue={5} />
            </div>
          </div>
        </div>
        <h2 className='mb-2'>Place radar</h2>
        <div className='analysis-rating-chart-block flex mb-0'>
          <div className='rating-block'>
              {
                placeRadars?.map((item, idx) => (
                  <Ratings color="#009BE2" key={item?.id} className='flex justify-between rating-items' label={item?.radarName} value={item?.value} max={5} onChange={(event, newValue) => onChangePlaceRadarValue(item.id, newValue)} onResetClick={() => onResetRadarPoint(item.id)} 
                  total={idx === placeRadars.length -1 && <p className={`text-xs text-right font-semibold uppercase ${totalPlaceRadarsCount() >= RATING_CHART.designReviewLimitForError ? 'text-error' : 'text-light-grey'}`}>
                  {totalPlaceRadarsCount()}/<span>{RATING_CHART.designReviewMaxLimit}</span></p>} 
                  />
                ))
              }
            </div>
            <div className='chart-block'>
              <div className='box-border bg-[#F3F3F5]'>
                <Chart color="#009BE2" bgColor="#009BE233" labels={switchPositions(placeRadars)?.map(i => i.radarName)} items={switchPositions(placeRadars)?.map(i => checkRadarPointValue(i.value))}
                  step={2}
                  maximumValue={5}/>
              </div>
            </div>
        </div>
        <div className='url-list'>
          <span className="admin-portal-title">Overview</span>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Overview 1"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.overview1 !== null ? editedDetails?.overview1 : designReviewTabData?.overview1 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, overview1: e.target.value })}
              error={errors.overview1}
              helperText={errors.overview1 && "Overview 1 Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.overview1 !== null ? editedDetails?.overview1?.length : designReviewTabData?.overview1?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Overview 2"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.overview2 !== null ? editedDetails?.overview2 : designReviewTabData?.overview2 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, overview2: e.target.value })}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.overview2 !== null ? editedDetails?.overview2?.length : designReviewTabData?.overview2?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Overview 3"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.overview3 !== null ? editedDetails?.overview3 : designReviewTabData?.overview3 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, overview3: e.target.value })}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.overview3 !== null ? editedDetails?.overview3?.length : designReviewTabData?.overview3?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Overview 4"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.overview4 !== null ? editedDetails?.overview4 : designReviewTabData?.overview4 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, overview4: e.target.value })}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.overview4 !== null ? editedDetails?.overview4?.length : designReviewTabData?.overview4?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Overview 5"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.overview5 !== null ? editedDetails?.overview5 : designReviewTabData?.overview5 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, overview5: e.target.value })}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.overview5 !== null ? editedDetails?.overview5?.length : designReviewTabData?.overview5?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
        </div>
        <div className='url-list'>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Visual design and branding"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.visualDesignBrandingText !== null ? editedDetails?.visualDesignBrandingText : designReviewTabData?.visualDesignBrandingText || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, visualDesignBrandingText: e.target.value })}
              error={errors.visualDesignBrandingText}
              helperText={errors.visualDesignBrandingText && "Visual Design and Branding Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.visualDesignBrandingText !== null ? editedDetails?.visualDesignBrandingText?.length : designReviewTabData?.visualDesignBrandingText?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Intuitiveness"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.intuitivenessText !== null ? editedDetails?.intuitivenessText : designReviewTabData?.intuitivenessText || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, intuitivenessText: e.target.value })}
              error={errors.intuitivenessText}
              helperText={errors.intuitivenessText && "Intuitiveness Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.intuitivenessText !== null ? editedDetails?.intuitivenessText?.length : designReviewTabData?.intuitivenessText?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Efficiency"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.efficiencyText !== null ? editedDetails?.efficiencyText : designReviewTabData?.efficiencyText || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, efficiencyText: e.target.value })}
              error={errors.efficiencyText}
              helperText={errors.efficiencyText && "Efficiency Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.efficiencyText !== null ? editedDetails?.efficiencyText?.length : designReviewTabData?.efficiencyText?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Feedback"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.feedbackText !== null ? editedDetails?.feedbackText : designReviewTabData?.feedbackText || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, feedbackText: e.target.value })}
              error={errors.feedbackText}
              helperText={errors.feedbackText && "Feedback Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.feedbackText !== null ? editedDetails?.feedbackText?.length : designReviewTabData?.feedbackText?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Error prevention and recovery"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.errorPreventionRecoveryText !== null ? editedDetails?.errorPreventionRecoveryText : designReviewTabData?.errorPreventionRecoveryText || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, errorPreventionRecoveryText: e.target.value })}
              error={errors.errorPreventionRecoveryText}
              helperText={errors.errorPreventionRecoveryText && "Error Prevention and Recovery Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.errorPreventionRecoveryText !== null ? editedDetails?.errorPreventionRecoveryText?.length : designReviewTabData?.errorPreventionRecoveryText?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
          <div className="url-list-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label="Support"
              fullWidth
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.supportText !== null ? editedDetails?.supportText : designReviewTabData?.supportText || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, supportText: e.target.value })}
              error={errors.supportText}
              helperText={errors.supportText && "Support Required."}
            />
            <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.supportText !== null ? editedDetails?.supportText?.length : designReviewTabData?.supportText?.length || 0}/<span>255</span> CHARACTERS</p>
          </div>
        </div>
        <div className='bottom-btn-wrapper flex justify-end'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => {
              setEditedDetails(DESIGN_REVIEW_TAB_PAYLOAD)
              setPlaceRadarDetails()
            }}
          />
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>} type="submit"
            className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div >
    </>
  )
}
