import { PROPTECH_COMPANY_DETAILS_API, PROPTECH_PRE_SIGNED_URL_API } from "../../../constants/APIs";
import { PROPTECH_API } from "../../utility/API-call";
import { handleError, handleSuccess } from "../../utility/snackbar";
import { getCompanyDetails } from "../SignupDetails";

export const uploadImage = (file, bucketType) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      PROPTECH_API().post(PROPTECH_PRE_SIGNED_URL_API, {
        fileName: file.name?.replace(" ", ""),
        mimeType: file.type,
        bucketType: bucketType,
      })
        .then((response) => {
          fetch(response.data.data.preSignedUrl, {
            method: "PUT",
            body: new Blob([reader.result], {
              type: file.type,
            }),
          })
            .then(() => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    };
    reader.readAsArrayBuffer(file);
  });
};

export const uploadCompanyImage = (file, setSelectedFile, handleCloseModal, setIsLoading, setActiveStep) => {
  setIsLoading(true)
  uploadImage(file, "proptech-company-logo")
    .then((res) => {
      PROPTECH_API().post(PROPTECH_COMPANY_DETAILS_API, { companyLogo: res.data.fileName, signupProgress: 10 })
        .then((res) => {
          setSelectedFile(URL.createObjectURL(file))
          handleCloseModal()
          setIsLoading(false)
          handleSuccess(res)
          getCompanyDetails(setActiveStep)
        })
        .catch((error) => handleError(error))
    })
    .catch((err) => console.log(err));
}