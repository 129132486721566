import { VC_API } from "../../../../utils/utility/API-call";
import { VC_REGIONS_API, VC_INVESTMENT_ROUNDS_API, VC_REVENUERANGES_API, VC_KEYBIZMODELS_API, VC_ACTIVEUSERSLIST_API } from "../../../../constants/APIs";
import { handleError } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";

export const getRegionList = () => {
  return VC_API().get(VC_REGIONS_API)
    .then(res => {
      if (res.data.data) {
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error)
      console.log(error)
    })
};

export const getRevenueList = () => {
  return VC_API().get(VC_REVENUERANGES_API)
    .then(res => {
      if (res.data.data) {
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error)
      console.log(error)
    })
};

export const getInvestmentRoundList = () => {
  return VC_API().get(VC_INVESTMENT_ROUNDS_API)
    .then(res => {
      if (res.data.data) {
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error)
      console.log(error)
    })
};

export const getKeyBizModels = () => {
  dispatch(Actions.vcCompanyProfile.vcCompanyProfileSetLoading, true)
  return VC_API().get(VC_KEYBIZMODELS_API)
    .then(res => {

      dispatch(Actions.vcCompanyProfile.vcCompanyProfileSetLoading, false)
      if (res.data.data) {
        dispatch(Actions.vcCompanyProfile.vcKeybizModelsSet, res.data.data)
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {

      dispatch(Actions.vcCompanyProfile.vcCompanyProfileSetLoading, false)
      handleError(error)
      console.log(error)
    })
}

export const getActiveUsers = () => {
  return VC_API().get(VC_ACTIVEUSERSLIST_API)
    .then(res => {
      if (res?.data && res?.data?.code === 200) {
        return res?.data?.data
      }
      handleError(res?.data)
    })
    .catch((error) => {
      handleError(error)
      console.log(error)
    })
} 