import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PTButton from '../../../../components/GenericComponents/PTButton';
import FullWidthTextField from '../../FullWidthTextField';
import { NEWSFEED_ERROR, NEWSFEED_PAYLOAD } from '../../../utils/payloads/payloads';
import { uploadImage } from '../../../utils/middleware/companyTab';
import placeholderImage from '../../../../assets/Icons/placeholder.png';
import closeIcon from '../../../../assets/Icons/ic-cross.svg';
import { trimQuery } from '../../../../utils/utility/utility';
import { addNewsfeedDetails } from '../../../utils/middleware/newsfeed';
import { NewsfeedModal } from '../../../../constants/General/styles';
import './addNewsfeed.scss'

export default function AddNewsFeed(props) {
  // Props
  const { open, handleClose } = props

  // State
  const [editedDetails, setEditedDetails] = useState(NEWSFEED_PAYLOAD);
  const [errors, setErrors] = useState(NEWSFEED_ERROR);
  const [files, setFiles] = useState(null)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef();

  // Store
  const { activeSort, sortOrder, isActive, search } = useSelector(state => state.newsfeeds);

  const handleImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFiles(e.target.files[0])
      uploadImage(e.target.files[0], 'newsfeed')
        .then(res => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, image: res.data.fileName })
        })
        .catch(() => setLoading(false))
    }
  }

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: !editedDetails?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={NewsfeedModal}>
      <IconButton className="close-btn" onClick={handleClose}>
        <img src={closeIcon} alt="Icon" />
      </IconButton>
      <div className="modal-outer relative">
        <div className="add-newsfeed-modal-heading">
          <h2>Add Newsfeed</h2>
        </div>
        <div className='add-newsfeed-modal'>
          <input
            style={{ display: 'none' }}
            id="file"
            ref={inputRef}
            type="file"
            className="form-control"
            value=''
            accept={['jpg', 'jpeg', 'png']
              .map(function (x) {
                return '.' + x;
              })
              .join(',')}
            onChange={(e) => handleImage(e)}
          />
          <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            disabled={loading}
            textLabel="Title"
            textValue="title"
          />
          <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            disabled={loading}
            textLabel="Description"
            textValue="description"
          />
          <FullWidthTextField
            editedDetails={editedDetails}
            setEditedDetails={setEditedDetails}
            errors={errors}
            disabled={loading}
            textLabel="Redirection URL"
            textValue="redirectionURL"
          />
          <div className='w-full flex company-two-col-block'>
            <div className='left-form-block'>
              <div className='mt-2 flex justify-between form-item-wrap items-center'>
                <span>Image</span>
                <div className='flex flex-col'>
                  <PTButton
                    variant="contained"
                    label={!loading && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                    className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                    loading={loading}
                    handleOnClickEvent={() => {
                      inputRef.current.click()
                    }}
                  />
                  {errors.image && <span className='font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]'>Image Required.</span>}
                </div>
              </div>
            </div>
            <div className='right-image-block'>
              {files ?
                <div className='inner-image-block'><img src={URL.createObjectURL(files)} alt="Profile" width={'100%'} height={'100%'} /></div> :
                <div className='inner-image-block'><img src={placeholderImage} alt="placeholder" /></div>}
            </div>
          </div>
        </div>
      </div>
      <Typography id="modal-modal-description" sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '28px' }}>
        <PTButton
          label={<span className="font-semibold h-5 text-sm leading-5 text-center not-italic w-[87px] font-['Poppins']">Close</span>}
          className="w-[114px] h-[48px] mr-4 rounded-xl border-solid border border-[#111D43] !bg-[#111D43]"
          handleOnClickEvent={handleClose} />
        <PTButton
          label={<span className="font-semibold h-5 text-sm leading-5 text-center not-italic w-[87px] font-['Poppins']">Add</span>}
          disabled={loading}
          className="w-[114px] h-[48px] rounded-xl border-solid border-[#111D43] bg-[#111D43] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]"
          handleOnClickEvent={() => !checkValidation() && addNewsfeedDetails(
            {
              ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
            }, isActive, activeSort, sortOrder, search, handleClose)} />
      </Typography>
    </Box>
  </Modal >
}
