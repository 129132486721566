import { GoogleMap, MarkerF } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react';
import { getBounds } from 'geolib';
import { GOOGLE_MAP_STYLE } from '../../../constants/General'
import MarkerIcon from '../../../assets/Icons/marker-icon.svg'

export default function TeamMap({ locations }) {
  // State
  const [mapInstance, setMapInstance] = useState(null)

  // Mount
  useEffect(() => setBounds(mapInstance))

  const setBounds = (map) => {
    if (!map) return;
    let locationData = locations.filter(loc => loc.latitude)
    let boundsArray = locationData;
    let bounds;

    if (boundsArray.length === 1) {
      map.setCenter({
        lat: boundsArray[0].latitude,
        lng: boundsArray[0].longitude,
      });
      map.setZoom(3);
    }

    if (boundsArray.length > 1) {
      bounds = getBounds(boundsArray);
      map.fitBounds(
        {
          north: bounds.maxLat,
          south: bounds.minLat,
          east: bounds.maxLng,
          west: bounds.minLng,
        }
      );
    }
  }

  return <GoogleMap
    mapContainerStyle={{
      width: '80%',
      height: '450px',
      marginBottom: '2rem',
      borderRadius: '16px'
    }}
    zoom={3}
    center={{
      lat: -33.865143,
      lng: 151.209900
    }}
    onLoad={(map) => setMapInstance(map)}
    options={{
      zoomControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      rotateControl: false,
      scaleControl: false,
      draggable: false,
      streetViewControl: false,
      keyboardShortcuts: false,
      controlSize: 28,
      styles: GOOGLE_MAP_STYLE,
      minZoom: 1,
    }}

    defaultOptions={{
      styles: GOOGLE_MAP_STYLE
    }}
  >
    {locations.filter(loc => loc.latitude).map((position, index) => {
      return position?.latitude && position?.longitude && < MarkerF
        key={index}
        position={{
          lat: position.latitude,
          lng: position.longitude
        }}
        title={position?.title}
        options={{
          icon: {
            url: MarkerIcon,
            scaledSize: { width: 24, height: 24 },
          }
        }}
      />
    })}
  </GoogleMap>

}
