export function buyerListDTO(data) {
  let buyerList = [];
  if (data && data.length) {
    buyerList = data.map((buyer) => {
      return {
        id: buyer?.id || 0,
        companyLogo: buyer?.companyLogo || '',
        domain: buyer?.domain || null,
        lastLoginAt: buyer?.lastLoginAt || null,
        createdAt: buyer?.createdAt || null,
        updatedAt: buyer?.updatedAt || null,
        totalMeetingCount: buyer?.totalMeetingCount || 0,
        cancelledMeetingCount: buyer?.cancelledMeetingCount || 0,
        status: buyer?.status !== null ? buyer.status : 0,
        watchlistCount: buyer?.watchlistCount !== null ? buyer.watchlistCount : 0,
        isArchived: buyer?.isArchived !== null ? buyer.isArchived : false,
        planExpiryDate: buyer?.planExpiryDate || '',
        tbPlanId: buyer?.tbPlanId || '',
        tbPlanDetails: {
          freeNoOfMeetings: buyer?.tbPlanDetails?.freeNoOfMeetings ? buyer.tbPlanDetails.freeNoOfMeetings : 0,
          durationOfDays: buyer?.tbPlanDetails?.durationOfDays ? buyer.tbPlanDetails.durationOfDays : '',
          name: buyer?.tbPlanDetails?.name ? buyer.tbPlanDetails.name : '',
          price: buyer?.tbPlanDetails?.price ? buyer.tbPlanDetails.price : '',
        },
        tbCompany: buyer?.tbCompany !== null ? {
          id: buyer?.tbCompany?.id || 0,
          name: buyer?.tbCompany?.name || null,
          tbUsers: buyer?.tbCompany?.tbUsers?.length !== 0 ? buyer?.tbCompany?.tbUsers.map(user => {
            return {
              id: user?.id || 0,
              name: user?.name || null,
              email: user?.email || null,
            }
          }) : [],
        } : null,
        tbUser: buyer?.tbUser !== null ? {
          id: buyer?.tbUser?.id || 0,
          primaryUserName: buyer?.tbUser?.primaryUserName || null,
          email: buyer?.tbUser?.email || null,
        } : null,
        country: buyer?.country !== null ? {
          id: buyer?.country?.id || 0,
          name: buyer?.country?.name || null,
          regionId: buyer?.country?.regionId || 0,
        } : null,
        region: buyer?.region !== null ? {
          id: buyer?.region?.id || 0,
          name: buyer?.region?.name || null,
        } : null,
      }
    })
  }

  return buyerList;
}

export function buyerProfileTabDTO(data) {
  return {
    id: data?.id || 0,
    companyInfo: data?.companyInfo || null,
    companyLogo: data?.companyLogo || null,
    websiteURL: data?.websiteURL || null,
    countryId: data?.countryId || null,
    regionId: data?.regionId || null,
    investmentRoundId: data?.investmentRoundId || 1,
    revenueRange: data?.revenueRange || 0,
    investmentRange: data?.investmentRange || 0,
    metric1: data?.metric1 || null,
    metric1Text: data?.metric1Text || null,
    metric2: data?.metric2 || null,
    metric2Text: data?.metric2Text || null,
    metric3: data?.metric3 || null,
    metric3Text: data?.metric3Text || null,
    notes: data?.notes || null,
    isArchived: data?.isArchived !== null ? data.isArchived : false,
    status: data?.status !== null ? data.status : false,
    lastLoginAt: data?.lastLoginAt || null,
    watchlistCount: data?.watchlistCount || null,
    createdAt: data?.createdAt || null,
    updatedAt: data?.updatedAt || null,
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || '',
      regionId: data?.country?.regionId || null,
    },
    tbCompany: {
      id: data?.tbCompany?.id || 0,
      name: data?.tbCompany?.name || '',
    },
    region: {
      id: data?.region?.id || 0,
      name: data?.region?.name || '',
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || '',
    },
    keyBizModelMetric1: {
      id: data?.keyBizModelMetric1?.id || 0,
      name: data?.keyBizModelMetric1?.name || '',
    },
    keyBizModelMetric2: {
      id: data?.keyBizModelMetric2?.id || 0,
      name: data?.keyBizModelMetric2?.name || '',
    },
    keyBizModelMetric3: {
      id: data?.keyBizModelMetric3?.id || 0,
      name: data?.keyBizModelMetric3?.name || '',
    },
  }
}

export function buyerProfileTabTagsListDTO(data) {
  let tags = [];
  if (data && data.length) {
    tags = data.map((datum) => {
      return {
        id: datum?.tag?.id || 0,
        name: datum?.tag?.name || '',
      }
    })
  }

  return tags;
}

export function buyerProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || '',
        publishedAt: datum?.publishedAt || '',
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany ? {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        } : null,
        tbProptechForYou: datum?.tbProptechForYou?.length !== 0 ? datum?.tbProptechForYou.map(user => {
          return {
            id: user?.id || 0,
            status: user?.status !== null ? user.status : false,
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function buyerForYouProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || '',
        publishedAt: datum?.publishedAt || '',
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany ? {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        } : null,
        tbProptechForYou: datum?.tbProptechForYou?.length !== 0 ? datum?.tbProptechForYou.map(user => {
          return {
            id: user?.id || 0,
            status: user?.status !== null ? user.status : false,
            tbProptechForYouTag: user?.tbProptechForYouTag?.length !== 0 ? user.tbProptechForYouTag.map(tag => {
              return {
                id: tag?.tag?.id || 0,
                name: tag?.tag?.name || '',
              }
            }) : [],
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function buyerHomeProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || '',
        publishedAt: datum?.publishedAt || '',
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany ? {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        } : null,
        tbProptechHome: datum?.tbProptechHome?.length !== 0 ? datum?.tbProptechHome.map(user => {
          return {
            id: user?.id || 0,
            status: user?.status !== null ? user.status : false,
            // tbProptechHomeTag: user?.tbProptechHomeTag?.length !== 0 ? user.tbProptechHomeTag.map(tag => {
            //   return {
            //     id: tag?.tag?.id || 0,
            //     name: tag?.tag?.name || '',
            //   }
            // }) : [],
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function buyerWatchlistProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || '',
        proptechCompanyDetails: datum?.proptechCompanyDetails ? {
          id: datum?.proptechCompanyDetails?.id || 0,
          companyLogo: datum?.proptechCompanyDetails?.companyLogo || "",
          elevatorPitchAdmin: datum?.proptechCompanyDetails?.elevatorPitchAdmin || "",
          founderPitchVideo: datum?.proptechCompanyDetails?.founderPitchVideo || "",
          publishedAt: datum?.proptechCompanyDetails?.publishedAt || "",
          country: {
            id: datum?.proptechCompanyDetails?.country?.id || 0,
            name: datum?.proptechCompanyDetails?.country?.name || '',
          },
          investmentRange: {
            id: datum?.proptechCompanyDetails?.investmentRange?.id || 0,
            name: datum?.proptechCompanyDetails?.investmentRange?.name || '',
          },
          investmentRound: {
            id: datum?.proptechCompanyDetails?.investmentRound?.id || 0,
            name: datum?.proptechCompanyDetails?.investmentRound?.name || '',
          },
          language: {
            id: datum?.proptechCompanyDetails?.language?.id || 0,
            name: datum?.proptechCompanyDetails?.language?.name || '',
          },
          proptechCompany: {
            id: datum?.proptechCompanyDetails?.proptechCompany?.id || 0,
            name: datum?.proptechCompanyDetails?.proptechCompany?.name || '',
          },
          revenueRange: {
            id: datum?.proptechCompanyDetails?.revenueRange?.id || 0,
            name: datum?.proptechCompanyDetails?.revenueRange?.name || '',
          },
        } : null,
      }
    })
  }

  return proptechs;
}

export function buyerInteractionProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || "",
        elevatorPitchAdmin: datum?.elevatorPitchAdmin || "",
        founderPitchVideo: datum?.founderPitchVideo || "",
        meetingCount: datum?.meetingCount || 0,
        createdAt: datum?.createdAt || '',
        publishedAt: datum?.publishedAt || "",
        updatedAt: datum?.updatedAt || "",
        archivedAt: datum?.archivedAt || "",
        lastMeetingTime: datum?.lastMeetingTime || "",
        proptechCompany: {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || '',
        },
        country: {
          id: datum?.country?.id || 0,
          name: datum?.country?.name || '',
        },
        investmentRound: {
          id: datum?.investmentRound?.id || 0,
          name: datum?.investmentRound?.name || '',
        },
        language: {
          id: datum?.language?.id || 0,
          name: datum?.language?.name || '',
        },
        revenueRange: {
          id: datum?.revenueRange?.id || 0,
          name: datum?.revenueRange?.name || '',
        },
      }
    })
  }

  return proptechs;
}