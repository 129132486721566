import { PROPTECH_COMPANY_DETAILS_API } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { Store } from "../../../store/configureStore";
import { PROPTECH_API } from "../../utility/API-call";
import { startLoading, stopLoading } from "../../utility/loader";
import { handleError, handleSuccess } from "../../utility/snackbar";

export const sendHeading2Data = (data, companyDetails, setActiveStep, resetForm) => {
  startLoading();
  PROPTECH_API().post(PROPTECH_COMPANY_DETAILS_API, { heading2: data, signupProgress: 3 })
    .then((res) => {
      stopLoading();
      Store.dispatch({
        type: Actions.ProptechUser.ProptechSetCompanyDetails, payload: {
          ...companyDetails,
          ...data,
          signupProgress: companyDetails?.signupProgress > 3 ? companyDetails?.signupProgress : 3
        }
      });
      resetForm({ values: '' })
      setActiveStep(3)
      handleSuccess(res)
    })
    .catch((error) => {
      stopLoading();
      handleError(error);
    })
}
