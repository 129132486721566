import { getTechOfferingsData } from "../dropdowns";
import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectTransformationTabData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.transformationData) {
    getProjectTransformationDetails(id);
  }
  loadProjectTransformationTabDropdowns(adminDropdowns.techOfferings);
};

export const getProjectTransformationDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/transformation`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminProjectTab.SetTransformationData, res.data.data);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      return err?.response?.status;
    });
};

export const updateProjectTransformationDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_TECH_BUYERS + `/${id}/project/transformation`, data)
    .then((res) => {
      getProjectTransformationDetails(id);
      handleSuccess(res);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const loadProjectTransformationTabDropdowns = (techOfferings) => {
  if (!techOfferings.length) {
    getTechOfferingsData();
  }
};
