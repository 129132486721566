import React, { useEffect, useState } from 'react'
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as chartTooltip,
  Legend,
} from 'chart.js';
import { CircularProgress, Link, TextField, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { getCalendarData, getVSProfile } from '../../utils/middleware/Dashboard';
import Loader from '../../../components/Loading';
import ProfileBlock from '../../components/ProfileBlock';
import VsNavbar from '../../components/VsNavbar';
import RecommendBlock from '../../components/RecommendBlock';
import { COPYRIGHT, Previous_Day_Style, Upcoming_Day_Style } from '../../constants/dashboard';
import VsChart from '../../components/VsChart';
import moment from 'moment';
import { meetingStatus, TooltipText } from '../../../constants/General';
import './style.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  chartTooltip,
  Legend
);

export default function VsDashboard() {
  // State
  const [upcoming, setUpcoming] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [pingState, setPingState] = useState(false);

  // Store
  const { isLoading } = useSelector(state => state.vsDashboard)

  // Get meeting data from API
  const getProfile = async () => {
    try {
      await getCalendarData().then(res => {
        setUpcoming(res.upcomingProptechMeetings.map(item => moment(item.startTime).startOf('day').toISOString()))
        setPrevious(res.previousProptechMeetings.map(item => item.status !== meetingStatus.cancelled && moment(item.startTime).startOf('day').toISOString()))
        setPingState(!pingState)
      })
      await getVSProfile()
    } catch {
      console.log('err')
    }
  }

  useEffect(() => {
    getProfile()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const isToday = (day) => {
    return moment(new Date()).startOf('day').toISOString() === moment(day).startOf('day').toISOString()
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-screen h-screen vs-dashboard'>
        {/* VS Top navbar component */}
        <VsNavbar />
        <div className='w-full bg-[#E5E5E5] box-border lg:pt-[120px] sm:pt-[82px] pt-[70px] min-h-full'>
          <div className="px-9 py-12 min-h-[calc(100vh-120px)] overflow-y-auto venture-scout-dashboard-page">
            <div className='flex flex-wrap venture-scout-dashboard-wrapper min-h-[calc(100vh-260px)]'>
              {/* VS Profile component */}
              <ProfileBlock />
              <div className='dashboard-right-block px-3'>
                <div className="dashboard-outer flex flex-wrap">
                  <RecommendBlock />
                  <div className="dashboard-inner px-3 pb-8">
                    <div className="dashboard-widget py-8 px-9 rounded-[32px] h-full calender-block">
                      {/* Meeting calender */}
                      {isLoading ? <div className='w-full h-full flex justify-center items-center'>
                        <CircularProgress />
                      </div> : <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                          displayStaticWrapperAs="desktop"
                          renderInput={(params) => <TextField {...params} />}
                          renderLoading={() => <span data-mui-test="loading-progress">...</span>}
                          onChange={() => { }}
                          renderDay={(day, selectedDays, pickersDayProps) => {
                            let previousDay = false
                            let upcomingDay = false
                            const upcomingMeets = upcoming.filter(item => item === moment(day).startOf('day').toISOString());
                            const previousMeets = previous.filter(item => item === moment(day).startOf('day').toISOString());

                            if (upcoming.includes(moment(day).startOf('day').toISOString()) && !isToday(day)) {
                              upcomingDay = true
                            }
                            if (previous.includes(moment(day).startOf('day').toISOString()) && !isToday(day) && !upcomingDay) {
                              previousDay = true
                            }

                            return (
                              <Tooltip title={(upcomingMeets.length > 0 || previousMeets.length > 0) ? <ul>
                                {upcomingMeets.length > 0 && <li>{upcomingMeets.length} {TooltipText.upcomingDay}</li>}
                                {previousMeets.length > 0 && <li>{previousMeets.length} {TooltipText.previousDay}</li>}
                              </ul> : isToday(day) ? <>{TooltipText.presentDay}</> : ''}>
                                <PickersDay
                                  {...pickersDayProps}
                                  today={isToday(day)}
                                  {...(previousDay && {
                                    sx: Previous_Day_Style,
                                  })}
                                  {...(upcomingDay && {
                                    sx: Upcoming_Day_Style,
                                  })}
                                />
                              </Tooltip>
                            );
                          }}
                        />
                      </LocalizationProvider>}
                    </div>
                  </div>
                  <VsChart />
                </div>
              </div>
            </div>
            <div className='font-normal leading-tight not-italic pt-7 px-3 text-[10px] text-[#70778E]'>
              {COPYRIGHT}
              <Link href="" className="text-[#70778E]">
                {` here`}
              </Link>.
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
