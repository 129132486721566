import React from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../utils/utility/store";
import { Actions } from "../../../../../store/actions";
import Loader from "../../../../../components/Loading";
import { setActiveTab } from "../../../../utils/middleware/adminRFF";
import ProptechListCard from "../ProptechListCard";
import { forYouTabMessage3 } from "../../../../constants";

// Get for you Component in Proptech Tab
export default function ForYouTabAdminRFF() {
  // Store
  const forYouProptechsRFF = useSelector(
    (state) => state.adminUser.forYouProptechsRFF
  );
  const isLoading = useSelector((state) => state.adminUser.isLoading);
  const adminDropdowns = useSelector((state) => state.adminDropdowns);
  const adminRFF = useSelector((state) => state.adminRFF);

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 2);
    dispatch(Actions.AdminRFF.SetActiveTab, 0);
    dispatch(Actions.Admin.SetIsRedirect, true);
    dispatch(Actions.AdminRFF.SetIsSelectedRFF, true);
    dispatch(Actions.AdminRFF.SetSelectedRFF, { id });
    setActiveTab(0, id, adminRFF, adminDropdowns);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="overflow-y-auto">
        {forYouProptechsRFF?.length !== 0 ? (
          forYouProptechsRFF.map((proptech, index) => {
            return (
              <ProptechListCard
                key={index}
                logo={proptech?.rffCompanyDetails?.companyLogo}
                onCardClick={() =>
                  handleCardClick(proptech?.rffCompanyDetails?.id)
                }
                title={proptech.rffCompanyDetails.rffCompany.name}
                createdAt={proptech?.createdAt}
                tags={proptech?.rffProptechForYouTag}
              />
            );
          })
        ) : (
          <div>
            <h3>{forYouTabMessage3}</h3>
          </div>
        )}
      </div>
    </>
  );
}
