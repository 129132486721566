import React from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../utils/utility/store";
import { Actions } from "../../../../../store/actions";
import Loader from "../../../../../components/Loading";
import ProptechListCard from "../../ForYouTab/ProptechListCard";
import { setActiveTab } from "../../../../utils/middleware/adminRFF";
import { forYouTabMessage3 } from "../../../../constants";

export default function AdminRFFWatchlistTab() {
  // Store
  const watchlistProptechsRFF = useSelector(
    (state) => state.adminUser.watchlistProptechsRFF
  );
  const isLoading = useSelector((state) => state.adminUser.isLoading);
  const adminDropdowns = useSelector((state) => state.adminDropdowns);
  const adminRFF = useSelector((state) => state.adminRFF);

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 2);
    dispatch(Actions.AdminRFF.SetActiveTab, 0);
    dispatch(Actions.Admin.SetIsRedirect, true);
    dispatch(Actions.AdminRFF.SetIsSelectedRFF, true);
    dispatch(Actions.AdminRFF.SetSelectedRFF, { id });
    setActiveTab(0, id, adminRFF, adminDropdowns);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="overflow-y-auto">
        {!!watchlistProptechsRFF?.length ? (
          watchlistProptechsRFF.map((proptech, index) => {
            return (
              <ProptechListCard
                key={index}
                logo={proptech?.rffCompanyDetails?.companyLogo}
                onCardClick={() =>
                  handleCardClick(proptech?.rffCompanyDetails?.id)
                }
                title={proptech.rffCompanyDetails.rffCompany.name}
                createdAt={proptech.createdAt}
              />
            );
          })
        ) : (
          <div>
            <h3>{forYouTabMessage3}</h3>
          </div>
        )}
      </div>
    </>
  );
}
