import { VC_NOTIFICATIONS_API } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { VC_API } from "../../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar"
import { dispatch } from "../../../../utils/utility/store"
import { notificationsListDTO } from "../../dtos/notifications"

export const setBadgeVisibility = () => {
  VC_API().get(VC_NOTIFICATIONS_API + '/checkUnread').then(res => {
    handleSuccess(res)
    dispatch(Actions.VCUser.SetNotificationCheckUnread, res.data.data)
  }).catch((error) => handleError(error))
}

export const getNotifications = () => {
  dispatch(Actions.VCUser.setNotificationLoading, true)
  VC_API().get(VC_NOTIFICATIONS_API).then(res => {
    handleSuccess(res)
    dispatch(Actions.VCUser.SetNotifications, notificationsListDTO(res.data.data.rows))
    dispatch(Actions.VCUser.SetNotificationCheckUnread, false)
    dispatch(Actions.VCUser.setNotificationLoading, false)
  }).catch((error) => {
    dispatch(Actions.VCUser.setNotificationLoading, false)
    handleError(error)
  })
}

export const readNotification = (vcNotificationId) => {
  VC_API().post(VC_NOTIFICATIONS_API, {
    vcNotificationId
  }).then(res => {
    // handleSuccess(res)
    getNotifications()
  }).catch((error) => handleError(error))
}

export const clearNotification = () => {
  VC_API().post(`${VC_NOTIFICATIONS_API}/clearAllNotification`)
    .then(res => {
      dispatch(Actions.VCUser.SetNotifications, [])
      handleSuccess(res)
    }).catch((error) => handleError(error))
}