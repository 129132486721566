import { Navigate } from 'react-router-dom';
import { getProptechAccessToken } from '../../../utils/session';

// For public user
const PublicRoute = ({ children }) => {
    const isLogin = getProptechAccessToken()

    return (
      <>
        {!isLogin ? children : <Navigate to='/proptech/dashboard' />}
      </>
    );
}
export default PublicRoute;