import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, IconButton, Modal, TextField } from '@mui/material';
import { ModalFooter, RescheduleMeetingModal, } from '../../constants/General';

// icons
import closeIcon from '../../../assets/Icons/ic-cross.svg';
import PTButton from '../../../components/GenericComponents/PTButton';
import moment from 'moment';
import { DateFormat } from '../../../constants/General';

export default function RescheduleMeeting(props) {
  const { openRescheduleModal, handleCloseRescheduleMeetingModal, rescheduleTime, setRescheduleTime, rescheduleMeetingRequest } = props;

  return <Modal
    open={openRescheduleModal}
    onClose={handleCloseRescheduleMeetingModal}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={RescheduleMeetingModal} className="w-10/12 md:w-1/2">
      <IconButton className="close-btn" onClick={handleCloseRescheduleMeetingModal}>
        <img src={closeIcon} alt="Icon" />
      </IconButton>
      <div className="relative">
        <div className="manageusers-modal-heading">
          <div className='w-full'>
            <h2>Reschedule request</h2>
            <div className='w-full flex justify-between items-center mt-5'>
              <span className='text-base'>Select reschedule date and time</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Select date and time"
                  minDate={new Date()}
                  inputFormat="DD MMM YYYY hh:mm A"
                  value={rescheduleTime}
                  onChange={(newValue) => setRescheduleTime(newValue)}
                  renderInput={(params) => <TextField variant='standard' {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      <Box id="modal-modal-description" sx={ModalFooter}>
        <span className='text-xs ml-2'>(Times is in UTC Timezone)</span>
        <Box>
          <PTButton
            label={<span className="font-semibold h-5 text-sm leading-5 text-center not-italic w-[87px] font-['Poppins']">Close</span>}
            className="w-[114px] h-[48px] mr-4 rounded-xl border-solid border border-[#111D43] !bg-[#111D43]"
            handleOnClickEvent={handleCloseRescheduleMeetingModal} />
          <PTButton
            label={<span className="font-semibold h-5 text-sm leading-5 text-center not-italic w-[87px] font-['Poppins']">Submit</span>}
            className="w-[114px] h-[48px] rounded-xl border-solid border-[#111D43] bg-[#111D43] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]"
            handleOnClickEvent={() => rescheduleMeetingRequest(moment(rescheduleTime.toString()).format(DateFormat.ISODateFormat))} />
        </Box>
      </Box>
    </Box>
  </Modal>
}