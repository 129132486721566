import { ADMIN_TECH_BUYERS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { getTagsData } from "../dropdowns"
import { buyerForYouProptechsListDTO, buyerProptechsListDTO } from "../dtos/adminTechBuyer"

export const loadTechBuyerForYouData = (id, adminTechBuyer, adminDropdowns) => {
  getTechBuyerProptechs(id, adminTechBuyer.searchProptech)
  getTechBuyerForYouProptechs(id)
  loadTechBuyerForYouDropdowns(adminDropdowns.tags)
}

export const getTechBuyerProptechs = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  }

  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS + `/${id}/proptechs`, { params })
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetProptechsData, buyerProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err)
    })
}

export const getTechBuyerForYouProptechs = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS + `/${id}/forYou`)
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetForYouProptechsData, buyerForYouProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err)
    })
}

export const updateTechBuyerForYouProptechs = (id, data, searchProptech, adminDropdowns) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().put(ADMIN_TECH_BUYERS + `/${id}/forYou`, data)
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      loadTechBuyerForYouData(id, { searchProptech }, adminDropdowns)
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      console.log(err)
      handleError(err)
    })
}

export const updateTechBuyerForYouTags = (id, data, clearData) => {
  ADMIN_API().put(ADMIN_TECH_BUYERS + `/${id}/forYouTags`, data)
    .then(res => {
      handleSuccess(res)
      getTechBuyerForYouProptechs(id)
      clearData()
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err);
    })
}

export const loadTechBuyerForYouDropdowns = (tags) => {
  if (!tags.length) {
    getTagsData();
  }
}