import { MenuItem } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PTButton from "../../../../components/GenericComponents/PTButton";
import PTInput from "../../../../components/GenericComponents/PTInput";
import Loader from "../../../../components/Loading";
import { AnalysisLabelStyle } from "../../../../proptech/constants/General";
import { Actions } from "../../../../store/actions";
import { dispatch } from "../../../../utils/utility/store";
import { trimQuery } from "../../../../utils/utility/utility";
import {
  COMPANY_PROFILE_TAB_BUSINESS_MODEL,
  COMPANY_PROFILE_TAB_COMMERCIAL_PROBLEM,
  COMPANY_PROFILE_TAB_CUSTOMER_BREAKDOWN,
  COMPANY_PROFILE_TAB_CUSTOMER_KEY_POINT,
  COMPANY_PROFILE_TAB_DEFENSIBILITY_AND_TIMING,
  COMPANY_PROFILE_TAB_FORECAST_AND_METRICS,
  COMPANY_PROFILE_TAB_GO_TO_MARKET,
  COMPANY_PROFILE_TAB_SKILL,
  COMPANY_PROFILE_TAB_SOLUTION,
  COMPANY_PROFILE_TAB_VALUE_PROPOSITION,
  COMPANY_PROFILE_TAB_WHY_GOOD_FIT,
} from "../../../constants";
import { updateCompanyProfileDetails } from "../../../utils/middleware/companyProfileTab";
import {
  COMPANY_PROFILE_TAB_ERROR,
  COMPANY_PROFILE_TAB_PAYLOAD,
} from "../../../utils/payloads/payloads";
import ConfirmModal from "../../ConfirmModal";
import FullWidthTextField from "../../FullWidthTextField";
import DropdownWithTextField from "../../DropdownWithTextField";
import { isUnsaveChanges } from "../../../utils/middleware/analysisTab";
import DropdownColumn from "../../DropdownColumn";
import { LabelStyle } from "../../../../proptech/constants/General";
import placeholderImage from "../../../../assets/Icons/placeholder.png";
import { uploadImage } from "../../../utils/middleware/companyTab";

export default function CompanyProfile(props) {
  const { setUnsaveChange } = props;

  // Store
  const companyProfileTabData = useSelector(
    (state) => state.adminAnalysisTab.companyProfileTabData
  );
  const selectedProptech = useSelector(
    (state) => state.adminUser.selectedProptech
  );
  const isLoading = useSelector((state) => state.adminUser.isLoading);
  const {
    keyBizModels,
    assetLifeCycles,
    defensibilityTimings,
    sectors,
    useCases,
    techOfferings,
    chargingModels,
    valueKPIs,
    contactPointVendors,
    pastProjectRange,
    projectScales,
    regions,
    integrations,
    projectRisks
  } = useSelector((state) => state.adminDropdowns);

  // State
  const [editedDetails, setEditedDetails] = useState(
    COMPANY_PROFILE_TAB_PAYLOAD
  );
  const [errors, setErrors] = useState(COMPANY_PROFILE_TAB_ERROR);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [previewField, setPreviewField] = useState("companyLogo");
  const inputRef = useRef();
  const bucketTypes = {
    companyLogo: "proptech-company-logo",
    profileImage: "proptech-user-profile-image",
    keyCustomer1Logo: "customer-logo",
    keyCustomer2Logo: "customer-logo",
    keyCustomer3Logo: "customer-logo",
  };
  const [files, setFiles] = useState({
    companyLogo: null,
    profileImage: null,
    keyCustomer1Logo: null,
    keyCustomer2Logo: null,
    keyCustomer3Logo: null,
  });
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !companyProfileTabData?.proptechAnalysisCompanyProfile?.[error],
      };
    });
    setErrors(data);
    return Object.keys(data).some((k) => data[k]);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => {
        if (bucketTypes.hasOwnProperty(key)) {
          newData[key] = key === 'profileImage' ? companyProfileTabData[key]?.split("/").slice(-2).join("/") : companyProfileTabData[key]?.split("/").slice(-2).join("/")
        } else {
          newData[key] = companyProfileTabData?.proptechAnalysisCompanyProfile?.[key]
        }
      });


    handleCloseModal();
    try {
      await updateCompanyProfileDetails(selectedProptech?.id, {
        ...Object.fromEntries(
          Object.entries(editedDetails).filter(([_, v]) => !!v)
        ),
        ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
      });
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
      console.log("error");
    }
  };

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange);
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImage = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true);
      setFiles({ ...files, [key]: e.target.files[0] });
      uploadImage(e.target.files[0], bucketTypes[key])
        .then((res) => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, [key]: res.data.fileName });
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <input
        style={{ display: "none" }}
        id="file"
        ref={inputRef}
        type="file"
        className="form-control"
        value=""
        accept={["jpg", "jpeg", "png"]
          .map(function (x) {
            return "." + x;
          })
          .join(",")}
        onChange={(e) => handleImage(e, selectedKey)}
      />
      <div className="mb-[22px] flex analysis-dropdown-wrapper">
        <DropdownColumn
          header="Use cases"
          dropdown1Label="Use Case 1"
          dropdown1Options={useCases}
          dropdown1Value={
            editedDetails?.useCase1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.useCase1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, useCase1: e.target.value })
          }
          dropdown1Error={errors.useCase1}
          dropdown1HelperText={errors.useCase1 && "Use Case 1 Required."}
          dropdown2Label="Use Case 2"
          dropdown2Options={useCases}
          dropdown2Value={
            editedDetails?.useCase2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.useCase2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, useCase2: e.target.value })
          }
          dropdown2Error={errors.useCase2}
          dropdown2HelperText={errors.useCase2 && "Use Case 2 Required."}
          dropdown3Label="Use Case 3"
          dropdown3Options={useCases}
          dropdown3Value={
            editedDetails?.useCase3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.useCase3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, useCase3: e.target.value })
          }
          dropdown3Error={errors.useCase3}
          dropdown3HelperText={errors.useCase3 && "Use Case 3 Required."}
        />
        <DropdownColumn
          header="Sectors"
          dropdown1Label="Sector 1"
          dropdown1Options={sectors}
          dropdown1Value={
            editedDetails?.sector1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.sector1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, sector1: e.target.value })
          }
          dropdown1Error={errors.sector1}
          dropdown1HelperText={errors.sector1 && "Sector 1 Required."}
          dropdown2Label="Sector 2"
          dropdown2Options={sectors}
          dropdown2Value={
            editedDetails?.sector2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.sector2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, sector2: e.target.value })
          }
          dropdown2Error={errors.sector2}
          dropdown2HelperText={errors.sector2 && "Sector 2 Required."}
          dropdown3Label="Sector 3"
          dropdown3Options={sectors}
          dropdown3Value={
            editedDetails?.sector3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.sector3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, sector3: e.target.value })
          }
          dropdown3Error={errors.sector3}
          dropdown3HelperText={errors.sector3 && "Sector 3 Required."}
        />
        <DropdownColumn
          header="Asset Life cycles"
          dropdown1Label="Asset Lifecycle 1"
          dropdown1Options={assetLifeCycles}
          dropdown1Value={
            editedDetails?.assetLifecycle1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.assetLifecycle1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              assetLifecycle1: e.target.value,
            })
          }
          dropdown1Error={errors.assetLifecycle1}
          dropdown1HelperText={
            errors.assetLifecycle1 && "Asset Lifecycle 1 Required."
          }
          dropdown2Label="Asset Lifecycle 2"
          dropdown2Options={assetLifeCycles}
          dropdown2Value={
            editedDetails?.assetLifecycle2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.assetLifecycle2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              assetLifecycle2: e.target.value,
            })
          }
          dropdown2Error={errors.assetLifecycle2}
          dropdown2HelperText={
            errors.assetLifecycle2 && "Asset Lifecycle 2 Required."
          }
          dropdown3Label="Asset Lifecycle 3"
          dropdown3Options={assetLifeCycles}
          dropdown3Value={
            editedDetails?.assetLifecycle3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.assetLifecycle3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              assetLifecycle3: e.target.value,
            })
          }
          dropdown3Error={errors.assetLifecycle3}
          dropdown3HelperText={
            errors.assetLifecycle3 && "Asset Lifecycle 3 Required."
          }
        />
      </div>
      <div className="mb-[22px] flex analysis-dropdown-wrapper">
        <DropdownColumn
          header="Tech Offerings"
          dropdown1Label="Offering 1"
          dropdown1Options={techOfferings}
          dropdown1Value={
            editedDetails?.techOffering1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.techOffering1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              techOffering1: e.target.value,
            })
          }
          dropdown1Error={errors.techOffering1}
          dropdown1HelperText={errors.techOffering1 && "Offering 1 Required."}
          dropdown2Label="Offering 2"
          dropdown2Options={techOfferings}
          dropdown2Value={
            editedDetails?.techOffering2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.techOffering2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              techOffering2: e.target.value,
            })
          }
          dropdown2Error={errors.techOffering2}
          dropdown2HelperText={errors.techOffering2 && "Offering 2 Required."}
          dropdown3Label="Offering 3"
          dropdown3Options={techOfferings}
          dropdown3Value={
            editedDetails?.techOffering3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.techOffering3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              techOffering3: e.target.value,
            })
          }
          dropdown3Error={errors.techOffering3}
          dropdown3HelperText={errors.techOffering3 && "Offering 3 Required."}
        />
        <DropdownColumn
          header="Charging model"
          dropdown1Label="Changing model 1"
          dropdown1Options={chargingModels}
          dropdown1Value={
            editedDetails?.chargingModel1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.chargingModel1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              chargingModel1: e.target.value,
            })
          }
          dropdown1Error={errors.chargingModel1}
          dropdown1HelperText={
            errors.chargingModel1 && "Changing Model 1 Required."
          }
          dropdown2Label="Changing model 2"
          dropdown2Options={chargingModels}
          dropdown2Value={
            editedDetails?.chargingModel2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.chargingModel2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              chargingModel2: e.target.value,
            })
          }
          dropdown2Error={errors.chargingModel2}
          dropdown2HelperText={
            errors.chargingModel2 && "Changing Model 2 Required."
          }
          dropdown3Label="Changing model 3"
          dropdown3Options={chargingModels}
          dropdown3Value={
            editedDetails?.chargingModel3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.chargingModel3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              chargingModel3: e.target.value,
            })
          }
          dropdown3Error={errors.chargingModel3}
          dropdown3HelperText={
            errors.chargingModel3 && "Changing Model 3 Required."
          }
        />
        <DropdownColumn
          header="Value KPI"
          dropdown1Label="Value kpi 1"
          dropdown1Options={valueKPIs}
          dropdown1Value={
            editedDetails?.valueKPI1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.valueKPI1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, valueKPI1: e.target.value })
          }
          dropdown1Error={errors.valueKPI1}
          dropdown1HelperText={errors.valueKPI1 && "Value KPI 1 Required."}
          dropdown2Label="Value kpi 2"
          dropdown2Options={valueKPIs}
          dropdown2Value={
            editedDetails?.valueKPI2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.valueKPI2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, valueKPI2: e.target.value })
          }
          dropdown2Error={errors.valueKPI2}
          dropdown2HelperText={errors.valueKPI2 && "Value KPI 2 Required."}
          dropdown3Label="Value kpi 3"
          dropdown3Options={valueKPIs}
          dropdown3Value={
            editedDetails?.valueKPI3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile?.valueKPI3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({ ...editedDetails, valueKPI3: e.target.value })
          }
          dropdown3Error={errors.valueKPI3}
          dropdown3HelperText={errors.valueKPI3 && "Value KPI 3 Required."}
          children={
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label="Value kpi 4"
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={valueKPIs.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={
                editedDetails?.valueKPI4 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.valueKPI4
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  valueKPI4: e.target.value,
                })
              }
              error={errors.valueKPI4}
              helperText={errors.valueKPI4 && "Value KPI 4 Required."}
            />
          }
        />
      </div>
      <div className="mb-[22px] flex analysis-dropdown-wrapper">
        <DropdownColumn
          header="Contact points & Vendors(P)"
          dropdown1Label="Contact points & Vendors 1"
          dropdown1Options={contactPointVendors}
          dropdown1Value={
            editedDetails?.contactPointVendor1 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.contactPointVendor1
          }
          dropdown1HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              contactPointVendor1: e.target.value,
            })
          }
          dropdown1Error={errors.contactPointVendor1}
          dropdown1HelperText={
            errors.contactPointVendor1 && "Contact points & Vendors 1 Required."
          }
          dropdown2Label="Contact points & Vendors 2"
          dropdown2Options={contactPointVendors}
          dropdown2Value={
            editedDetails?.contactPointVendor2 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.contactPointVendor2
          }
          dropdown2HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              contactPointVendor2: e.target.value,
            })
          }
          dropdown2Error={errors.contactPointVendor2}
          dropdown2HelperText={
            errors.contactPointVendor2 && "Contact points & Vendors 2 Required."
          }
          dropdown3Label="Contact points & Vendors 3"
          dropdown3Options={contactPointVendors}
          dropdown3Value={
            editedDetails?.contactPointVendor3 ||
            companyProfileTabData?.proptechAnalysisCompanyProfile
              ?.contactPointVendor3
          }
          dropdown3HandleChange={(e) =>
            setEditedDetails({
              ...editedDetails,
              contactPointVendor3: e.target.value,
            })
          }
          dropdown3Error={errors.contactPointVendor3}
          dropdown3HelperText={
            errors.contactPointVendor3 && "Contact points & Vendors 3 Required."
          }
        />
        <div className="dropdown-items">
          <div className="dropdown-title not-italic text-[#111D43]">
            Past projects(P)
          </div>
          <PTInput
            labelStyle={AnalysisLabelStyle}
            className="custom-dropdown-icon"
            variant="standard"
            label="Past roject range"
            InputPropsStyle={{ fontSize: "12px" }}
            fullWidth
            select
            selectChildren={pastProjectRange.map((option) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
            placeholder=""
            value={
              editedDetails?.pastProjectRangeId ||
              companyProfileTabData?.proptechAnalysisCompanyProfile
                ?.pastProjectRangeId
            }
            handleOnChangeEvent={(e) =>
              setEditedDetails({
                ...editedDetails,
                pastProjectRangeId: e.target.value,
              })
            }
            error={errors.pastProjectRangeId}
            helperText={errors.pastProjectRangeId && "Project range Required."}
          />
          <PTInput
            labelStyle={AnalysisLabelStyle}
            className="custom-dropdown-icon"
            variant="standard"
            label="Project Scale"
            InputPropsStyle={{ fontSize: "12px" }}
            fullWidth
            select
            selectChildren={projectScales.map((option) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
            placeholder=""
            value={
              editedDetails?.projectScaleId ||
              companyProfileTabData?.proptechAnalysisCompanyProfile
                ?.projectScaleId
            }
            handleOnChangeEvent={(e) =>
              setEditedDetails({
                ...editedDetails,
                projectScaleId: e.target.value,
              })
            }
            error={errors.projectScaleId}
            helperText={errors.projectScaleId && "Project range Required."}
          />
          <PTInput
            labelStyle={AnalysisLabelStyle}
            className="custom-dropdown-icon"
            variant="standard"
            label="Geography"
            InputPropsStyle={{ fontSize: "12px" }}
            fullWidth
            select
            selectChildren={regions.map((option) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
            placeholder=""
            value={
              editedDetails?.geographyId ||
              companyProfileTabData?.proptechAnalysisCompanyProfile?.geographyId
            }
            handleOnChangeEvent={(e) =>
              setEditedDetails({
                ...editedDetails,
                geographyId: e.target.value,
              })
            }
            error={errors.geographyId}
            helperText={errors.geographyId && "Project range Required."}
          />
        </div>
      </div>
      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title flex items-center w-[90%] justify-between text-sm not-italic text-[#111D43]">
          <p>Integration & Asset fit</p>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label={`Integration process 1`}
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={integrations.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={
                editedDetails?.integration1 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.integration1
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  integration1: e.target.value,
                })
              }
              error={errors.integration1}
              helperText={
                errors.integration1 && "Integration process 1 Required."
              }
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label={`Integration process text 1`}
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.integrationText1 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.integrationText1
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  integrationText1: e.target.value,
                })
              }
              error={errors.integrationText1}
              helperText={
                errors.integrationText1 && "Integration process text 1 Required."
              }
            />
          </div>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label={`Integration process 2`}
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={integrations.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={
                editedDetails?.integration2 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.integration2
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  integration2: e.target.value,
                })
              }
              error={errors.integration2}
              helperText={
                errors.integration2 && "Integration process 2 Required."
              }
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label={`Integration process text 2`}
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.integrationText2 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.integrationText2
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  integrationText2: e.target.value,
                })
              }
              error={errors.integrationText2}
              helperText={
                errors.integrationText2 &&
                "Integration process  text 2 Required."
              }
            />
          </div>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label={`Integration process 3`}
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={integrations.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={
                editedDetails?.integration3 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.integration3
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  integration3: e.target.value,
                })
              }
              error={errors.integration3}
              helperText={
                errors.integration3 && "Integration process 3 Required."
              }
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label={`Integration process text 3`}
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.integrationText3 ||
                companyProfileTabData?.proptechAnalysisCompanyProfile?.integrationText3
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  integrationText3: e.target.value,
                })
              }
              error={errors.integrationText3}
              helperText={
                errors.integrationText3 &&
                "Integration process text 3 Required."
              }
            />
          </div>
        </div>
      </div>
      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title flex items-center w-[90%] justify-between text-sm not-italic text-[#111D43]">
          <p>Risk incurred</p>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label={`Risk 1`}
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={projectRisks.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={editedDetails?.risk1 || companyProfileTabData?.proptechAnalysisCompanyProfile?.risk1}
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  risk1: e.target.value,
                })
              }
              error={errors.risk1}
              helperText={errors.risk1 && "Risk 1 Required."}
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label={`Risk text 1`}
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.riskText1 || companyProfileTabData?.proptechAnalysisCompanyProfile?.riskText1
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  riskText1: e.target.value,
                })
              }
              error={errors.riskText1}
              helperText={errors.riskText1 && "Risk  text 1 Required."}
            />
          </div>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label={`Risk 2`}
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={projectRisks.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={editedDetails?.risk2 || companyProfileTabData?.proptechAnalysisCompanyProfile?.risk2}
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  risk2: e.target.value,
                })
              }
              error={errors.risk2}
              helperText={errors.risk2 && "Risk 2 Required."}
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label={`Risk text 2`}
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.riskText2 || companyProfileTabData?.proptechAnalysisCompanyProfile?.riskText2
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  riskText2: e.target.value,
                })
              }
              error={errors.riskText2}
              helperText={errors.riskText2 && "Risk  text 2 Required."}
            />
          </div>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className="custom-dropdown-icon"
              variant="standard"
              label={`Risk 3`}
              InputPropsStyle={{ fontSize: "12px" }}
              fullWidth
              select
              selectChildren={projectRisks.map((option) => (
                <MenuItem value={option.id}>{option.name}</MenuItem>
              ))}
              placeholder=""
              value={editedDetails?.risk3 || companyProfileTabData?.proptechAnalysisCompanyProfile?.risk3}
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  risk3: e.target.value,
                })
              }
              error={errors.risk3}
              helperText={errors.risk3 && "Risk 3 Required."}
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              labelStyle={AnalysisLabelStyle}
              variant="standard"
              label={`Risk text 3`}
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.riskText3 || companyProfileTabData?.proptechAnalysisCompanyProfile?.riskText3
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  riskText3: e.target.value,
                })
              }
              error={errors.riskText3}
              helperText={errors.riskText3 && "Risk  text 3 Required."}
            />
          </div>
        </div>
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Where the market is going(P)
        </div>
        <div className="mb-[25px]">
          <PTInput
            labelStyle={LabelStyle}
            variant="standard"
            label="Future Synopsis"
            placeholder=""
            fullWidth
            multiline
            maxRows={4}
            maxLength={255}
            value={
              editedDetails?.futureSynopsis !== null
                ? editedDetails?.futureSynopsis
                : companyProfileTabData?.proptechAnalysisCompanyProfile
                    ?.futureSynopsis || ""
            }
            handleOnChangeEvent={(e) =>
              setEditedDetails({
                ...editedDetails,
                futureSynopsis: e.target.value,
              })
            }
            error={errors.futureSynopsis}
            helperText={errors.futureSynopsis && "Future Synopsis Required."}
          />
          <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
            {editedDetails?.futureSynopsis !== null
              ? editedDetails?.futureSynopsis?.length
              : 0}
            /<span>255</span> CHARACTERS
          </p>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={LabelStyle}
              variant="standard"
              label="Future 1 Heading"
              placeholder=""
              value={
                editedDetails?.future1Heading !== null
                  ? editedDetails?.future1Heading
                  : companyProfileTabData?.proptechAnalysisCompanyProfile
                      ?.future1Heading || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  future1Heading: e.target.value,
                })
              }
              error={errors.future1Heading}
              helperText={errors.future1Heading && "Future 1 Heading Required."}
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              variant="standard"
              label="Future 1 Text"
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.future1 !== null
                  ? editedDetails?.future1
                  : companyProfileTabData?.proptechAnalysisCompanyProfile
                      ?.future1 || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  future1: e.target.value,
                })
              }
              error={errors.future1}
              helperText={errors.future1 && "Future 1 Text Required."}
            />
            <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
              {editedDetails?.future1 !== null
                ? editedDetails?.future1?.length
                : 0}
              /<span>255</span> CHARACTERS
            </p>
          </div>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={LabelStyle}
              variant="standard"
              label="Future 2 Heading"
              placeholder=""
              value={
                editedDetails?.future2Heading !== null
                  ? editedDetails?.future2Heading
                  : companyProfileTabData?.proptechAnalysisCompanyProfile
                      ?.future2Heading || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  future2Heading: e.target.value,
                })
              }
              error={errors.future2Heading}
              helperText={errors.future2Heading && "Future 2 Heading Required."}
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              variant="standard"
              label="Future 2 Text"
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.future2 !== null
                  ? editedDetails?.future2
                  : companyProfileTabData?.proptechAnalysisCompanyProfile
                      ?.future2 || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  future2: e.target.value,
                })
              }
              error={errors.future2}
              helperText={errors.future2 && "Future 2 Text Required."}
            />
            <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
              {editedDetails?.future2 !== null
                ? editedDetails?.future2?.length
                : 0}
              /<span>255</span> CHARACTERS
            </p>
          </div>
        </div>
        <div className="dropdown-items flex">
          <div className="dropdown-inner-item">
            <PTInput
              labelStyle={LabelStyle}
              variant="standard"
              label="Future 3 Heading"
              placeholder=""
              value={
                editedDetails?.future3Heading !== null
                  ? editedDetails?.future3Heading
                  : companyProfileTabData?.proptechAnalysisCompanyProfile
                      ?.future3Heading || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  future3Heading: e.target.value,
                })
              }
              error={errors.future3Heading}
              helperText={errors.future3Heading && "Future 3 Heading Required."}
            />
          </div>
          <div className="dropdown-inner2-item">
            <PTInput
              variant="standard"
              label="Future 3 Text"
              placeholder=""
              multiline
              maxRows={4}
              maxLength={255}
              value={
                editedDetails?.future3 !== null
                  ? editedDetails?.future3
                  : companyProfileTabData?.proptechAnalysisCompanyProfile
                      ?.future3 || ""
              }
              handleOnChangeEvent={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  future3: e.target.value,
                })
              }
              error={errors.future3}
              helperText={errors.future3 && "Future 3 Text Required."}
            />
            <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
              {editedDetails?.future3 !== null
                ? editedDetails?.future3?.length
                : 0}
              /<span>255</span> CHARACTERS
            </p>
          </div>
        </div>
      </div>
      <div className="flex mb-[22px] justify-between flex-wrap">
        <div className="analysis-dropdown-wrapper horizontal">
          <div>
            <div className="dropdown-title not-italic text-[#111D43]">
              Key Customers
            </div>

            <div className="dropdown-items flex">
              <div className="dropdown-inner-item">
                <PTInput
                  labelStyle={LabelStyle}
                  variant="standard"
                  label="key Customer 1"
                  placeholder=""
                  disabled={loading}
                  value={
                    editedDetails?.keyCustomer1 !== null
                      ? editedDetails?.keyCustomer1
                      : companyProfileTabData?.keyCustomer1 || ""
                  }
                  handleOnChangeEvent={(e) =>
                    setEditedDetails({
                      ...editedDetails,
                      keyCustomer1: e.target.value,
                    })
                  }
                />
              </div>
              <div className="dropdown-inner-item">
                <div className="flex  sm:justify-center">
                  <PTButton
                    variant="contained"
                    label={
                      !(loading && selectedKey === "keyCustomer1Logo") && (
                        <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">
                          Upload
                        </span>
                      )
                    }
                    className="w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]"
                    loading={loading && selectedKey === "keyCustomer1Logo"}
                    handleOnClickEvent={() => {
                      inputRef.current.click();
                      setSelectedKey("keyCustomer1Logo");
                    }}
                  />
                  <PTButton
                    variant="contained"
                    label={
                      <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                        Preview
                      </span>
                    }
                    className="w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
                    handleOnClickEvent={() => {
                      setPreviewField("keyCustomer1Logo");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown-items flex">
            <div className="dropdown-inner-item">
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="key Customer 2"
                placeholder=""
                disabled={loading}
                value={
                  editedDetails?.keyCustomer2 !== null
                    ? editedDetails?.keyCustomer2
                    : companyProfileTabData?.keyCustomer2 || ""
                }
                handleOnChangeEvent={(e) =>
                  setEditedDetails({
                    ...editedDetails,
                    keyCustomer2: e.target.value,
                  })
                }
              />
            </div>
            <div className="dropdown-inner-item">
              <div className="flex sm:justify-center">
                <PTButton
                  variant="contained"
                  label={
                    !(loading && selectedKey === "keyCustomer2Logo") && (
                      <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">
                        Upload
                      </span>
                    )
                  }
                  className="w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]"
                  loading={loading && selectedKey === "keyCustomer2Logo"}
                  handleOnClickEvent={() => {
                    inputRef.current.click();
                    setSelectedKey("keyCustomer2Logo");
                  }}
                />
                <PTButton
                  variant="contained"
                  label={
                    <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                      Preview
                    </span>
                  }
                  className="w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
                  handleOnClickEvent={() => {
                    setPreviewField("keyCustomer2Logo");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="dropdown-items flex">
            <div className="dropdown-inner-item">
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="key Customer 3"
                className="grow"
                placeholder=""
                disabled={loading}
                value={
                  editedDetails?.keyCustomer3 !== null
                    ? editedDetails?.keyCustomer3
                    : companyProfileTabData?.keyCustomer3 || ""
                }
                handleOnChangeEvent={(e) =>
                  setEditedDetails({
                    ...editedDetails,
                    keyCustomer3: e.target.value,
                  })
                }
              />
            </div>
            <div className="dropdown-inner-item">
              <div className="flex sm:justify-center">
                <PTButton
                  variant="contained"
                  label={
                    !(loading && selectedKey === "keyCustomer3Logo") && (
                      <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">
                        Upload
                      </span>
                    )
                  }
                  className="w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]"
                  loading={loading && selectedKey === "keyCustomer3Logo"}
                  handleOnClickEvent={() => {
                    inputRef.current.click();
                    setSelectedKey("keyCustomer3Logo");
                  }}
                />
                <PTButton
                  variant="contained"
                  label={
                    <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                      Preview
                    </span>
                  }
                  className="w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
                  handleOnClickEvent={() => {
                    setPreviewField("keyCustomer3Logo");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {previewField &&
          (files?.[previewField] ||
            companyProfileTabData?.[previewField] ||
            companyProfileTabData?.[previewField]) ? (
            <div className="max-w-[180px] flex items-center justify-center">
              <img
                src={
                  files?.[previewField]
                    ? URL.createObjectURL(files?.[previewField])
                    : previewField === "profileImage"
                    ? companyProfileTabData?.[previewField]
                    : companyProfileTabData[previewField]
                }
                alt="Profile"
                width={"100%"}
                height={"100%"}
                className='object-contain'
              />
            </div>
          ) : (
            <div className="max-w-[180px] inner-image-block">
              <img src={placeholderImage} alt="placeholder" />
            </div>
          )}
        </div>
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Commercial problem
        </div>
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          data={companyProfileTabData?.proptechAnalysisCompanyProfile}
          textLabel="Problem Synopsis"
          textValue="problemSynopsis"
          showChar
        />
        {COMPANY_PROFILE_TAB_COMMERCIAL_PROBLEM.map((customer) => {
          return (
            <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">Solution</div>
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          data={companyProfileTabData?.proptechAnalysisCompanyProfile}
          textLabel="Solution Synopsis"
          textValue="solutionSynopsis"
          showChar
        />
        {COMPANY_PROFILE_TAB_SOLUTION.map((customer) => {
          return (
            <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Business model and Go to market
        </div>
        {COMPANY_PROFILE_TAB_BUSINESS_MODEL.map((customer) => {
          return (
            <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
              showChar
            />
          );
        })}
        {COMPANY_PROFILE_TAB_GO_TO_MARKET.map((customer) => {
          return (
            <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Customers and validation
        </div>
        {COMPANY_PROFILE_TAB_CUSTOMER_BREAKDOWN.map((customer) => {
          return (
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              textLabel={customer.text}
              textValue={customer.value}
              showChar
            />
          );
        })}
        {COMPANY_PROFILE_TAB_CUSTOMER_KEY_POINT.map((customer) => {
          return (
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              textLabel={customer.text}
              textValue={customer.value}
              showChar
            />
          );
        })}
        <DropdownWithTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          dropdown
          data={companyProfileTabData?.proptechAnalysisCompanyProfile}
          dropdownOptions={keyBizModels}
          dropdownLabel="Current Traction 2"
          dropdownValue="currentTraction2Stat"
          textLabel="Current Traction 2 Value"
          textValue="currentTraction2"
          showChar
        />
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Forecast and metrics
        </div>
        {COMPANY_PROFILE_TAB_FORECAST_AND_METRICS.map((customer) => {
          return (
            <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              dropdown
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              dropdownOptions={keyBizModels}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Value proposition
        </div>
        {COMPANY_PROFILE_TAB_VALUE_PROPOSITION.map((customer) => {
          return (
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              textLabel={customer.text}
              textValue={customer.value}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Why it’s a good fit
        </div>
        {COMPANY_PROFILE_TAB_WHY_GOOD_FIT.map((customer) => {
          return (
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              textLabel={customer.text}
              textValue={customer.value}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">
          Defensibility and timing
        </div>
        {COMPANY_PROFILE_TAB_DEFENSIBILITY_AND_TIMING.map((customer) => {
          return (
            <DropdownWithTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              dropdown
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              dropdownOptions={defensibilityTimings}
              dropdownLabel={customer.dropdownLabel}
              dropdownValue={customer.dropdownValue}
              textLabel={customer.textLabel}
              textValue={customer.textValue}
              showChar
            />
          );
        })}
      </div>

      <div className="analysis-dropdown-wrapper horizontal">
        <div className="dropdown-title not-italic text-[#111D43]">Team</div>
        {COMPANY_PROFILE_TAB_SKILL.map((customer) => {
          return (
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={companyProfileTabData?.proptechAnalysisCompanyProfile}
              textLabel={customer.text}
              textValue={customer.value}
              showChar
            />
          );
        })}
      </div>

      <div className="flex justify-end bottom-btn-wrapper">
        <PTButton
          variant="contained"
          label={
            <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
              Revert
            </span>
          }
          type="submit"
          className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
          handleOnClickEvent={() =>
            setEditedDetails(COMPANY_PROFILE_TAB_PAYLOAD)
          }
        />
        <PTButton
          variant="contained"
          label={
            <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
              Save & Continue
            </span>
          }
          type="submit"
          className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
          handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
        />
      </div>
      {openModal && (
        <ConfirmModal
          open={openModal}
          handleCloseModal={handleCloseModal}
          handleSucces={updateData}
        />
      )}
    </>
  );
}
