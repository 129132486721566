import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { ANALYSIS_TABS_HEIGHTS, PROPTECH_DETAILS_ANALYSIS_TAB, TabsStyle, UNSAVED_CHANGES_MESSAGE } from '../../../constants';
import { setActiveTabData } from '../../../utils/middleware/analysisTab';
import CompanyTab from '../../../components/AnalysisTabs/CompanyTab';
import OverviewTab from '../../../components/AnalysisTabs/OverviewTab';
import MarketTab from '../../../components/AnalysisTabs/MarketTab';
import NumbersTab from '../../../components/AnalysisTabs/NumbersTab';
import RaiseTab from '../../../components/AnalysisTabs/RaiseTab';
import TeamTab from '../../../components/AnalysisTabs/TeamTab';
import DesignReviewTab from '../../../components/AnalysisTabs/DesignReviewTab';
import RaiseOverviewTab from '../../../components/AnalysisTabs/RaiseOverviewTab';
import CompanyProfile from '../../../components/AnalysisTabs/CompanyProfile';
import MarketInsightsTab from '../../../components/AnalysisTabs/MarketInsightsTab';
import { showMessage } from '../../../../utils/utility/snackbar';
import { MessageType } from '../../../../constants/General';
import './AnalysisBlock.scss';
import DeployAndUse from '../../../components/AnalysisTabs/DeployAndUse';

export default function AnalysisBlock(props) {
  const { unsaveChange, setUnsaveChange } = props

  // Store
  const { activeAnalysisTab, radarDetails} = useSelector(state => state.adminAnalysisTab)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const adminDropdowns = useSelector(state => state.adminDropdowns)
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)
  const classes = TabsStyle();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className='h-full'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className='w-full h-full'>{children}</div>
        )}
      </div>
    );
  }

  useEffect(() => {
    scrollAnalysisTab()
  }, [activeAnalysisTab])  // eslint-disable-line react-hooks/exhaustive-deps


  const scrollAnalysisTab = () => {
    let totalWidth = 0;
    const element = document.getElementById('menuList');
    for (let index = 0; index < activeAnalysisTab; index++) {
      totalWidth += ANALYSIS_TABS_HEIGHTS[index] + 15;
    }
    if (element.clientWidth < totalWidth) {
      element.scrollTo(totalWidth, 0);
    }
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className='w-full h-full analysis-wrapper overflow-hidden'>
      <div className='w-full flex items-center justify-between text-[#111D43]'>
        <div id="menuList" className={`w-full flex analysis-tab-heading flex-nowrap overflow-x-auto ${classes.root}`}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={activeAnalysisTab}
              onChange={(e, index) => {
                if (activeAnalysisTab !== index && !unsaveChange.current) {
                  dispatch(Actions.AdminAnalysisTab.SetActiveAnalysisTab, index)
                  setActiveTabData(index, selectedProptech.id, adminAnalysisTab, adminDropdowns, radarDetails)
                } else {
                  showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                }
              }}
              className={classes.tabs}
              aria-label="basic tabs example"
              variant="scrollable" scrollButtons allowScrollButtonsMobile>
              {
                PROPTECH_DETAILS_ANALYSIS_TAB.map((tab, index) => {
                  return <Tab
                    className={`text-base leading-5 not-italic cursor-pointer whitespace-nowrap ${activeAnalysisTab === index ? `font-semibold` : `font-light`}`}
                    label={tab?.panel} {...a11yProps(index)} />
                })
              }
            </Tabs>
          </Box>
        </div>
      </div>
      <div className='w-full h-[calc(100%-70px)] overflow-y-auto overflow-x-hidden'>
        <TabPanel value={activeAnalysisTab} index={0}>
          <CompanyTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={1}>
          <OverviewTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={2}>
          <MarketTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={3}>
          <NumbersTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={4}>
          <RaiseTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={5}>
          <TeamTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={6}>
          <RaiseOverviewTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={7}>
          <DesignReviewTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={8}>
          <MarketInsightsTab setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={9}>
          <CompanyProfile setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeAnalysisTab} index={10}>
          <DeployAndUse setUnsaveChange={setUnsaveChange} />
        </TabPanel>
      </div>
    </div>
  )
}
