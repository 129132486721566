import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ForYouTabAdminConnect from "./connect";
import ForYouTabAdminPlace from "./place";
import ForYouTabAdminRFF from "./rff";
import { useSelector } from "react-redux";
import { setActivetabData } from "../../../utils/middleware/adminProptechTab";
import { dispatch } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";
import { adminProptechForYouTabs } from "../../../constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminProptechForYouTab() {
  const { activeTabIndex, selectedProptech, activeForYouTabIndex } =
    useSelector((state) => state.adminUser);
  const adminDropdowns = useSelector((state) => state.adminDropdowns);
  const adminAnalysisTab = useSelector((state) => state.adminAnalysisTab);

  const handleChange = (event, newValue) => {
    dispatch(Actions.AdminUser.SetProptechForYouActiveTabIndex, newValue);
    setActivetabData(
      activeTabIndex,
      selectedProptech?.id,
      adminAnalysisTab,
      adminDropdowns,
      newValue
    );
  };

  return (
    <>
      <Box sx={{ width: "100%", paddingBottom: "60px" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "sticky",
            top: 0,
          }}
          className="bg-white z-10"
        >
          <Tabs
            value={activeForYouTabIndex}
            onChange={handleChange}
            aria-label="tabs"
            variant="scrollable"
          >
            {adminProptechForYouTabs.length !== 0 &&
              adminProptechForYouTabs.map((item, index) => (
                <Tab label={item.name} {...a11yProps(index)} key={item.id} />
              ))}
          </Tabs>
        </Box>

        <TabPanel value={activeForYouTabIndex} index={0}>
          <ForYouTabAdminConnect />
        </TabPanel>

        <TabPanel value={activeForYouTabIndex} index={1}>
          <ForYouTabAdminPlace />
        </TabPanel>

        <TabPanel value={activeForYouTabIndex} index={2}>
          <ForYouTabAdminRFF />
        </TabPanel>
      </Box>
    </>
  );
}
