import { Box, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import {
  TECH_BUYER_PROJECT_TAB,
  TabsStyle,
  UNSAVED_CHANGES_MESSAGE,
} from "../../../constants";
import { dispatch } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";
import { setActiveTabData } from "../../../utils/middleware/projectTab";
import { showMessage } from "../../../../utils/utility/snackbar";
import { MessageType } from "../../../../constants/General";
import CompanyOverview from "./CompanyOverview";
import StrategyDiagnostic from "./StrategyDiagnostic";
import AssessmentCriteria from "./AssessmentCriteria";
import Transformation from "./Transformation";
import Implementation from "./Implementation";
import Adoption from "./Adoption";
import Attachments from "./Attachments";
import "./projectTab.scss"

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      className='h-full'
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className='h-full'>{children}</div>
      )}
    </div>
  );
}

export const ProjectTab = (props) => {
  const { unsaveChange, setUnsaveChange } = props
  const classes = TabsStyle();
  const activeProjectTab = useSelector(
    (state) => state.adminProjectTab.activeProjectTab
  );
  const selectedTechBuyer = useSelector(
    (state) => state.adminTechBuyer.selectedTechBuyer
  );
  const adminDropdowns = useSelector((state) => state.adminDropdowns);
  const adminProjectTab = useSelector((state) => state.adminProjectTab);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <div className="w-full h-full analysis-wrapper overflow-hidden">
      <div className="w-full flex items-center justify-between text-[#111D43]">
        <div
          id="menuList"
          className={`w-full flex analysis-tab-heading flex-nowrap overflow-x-auto ${classes.root}`}
        >
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={activeProjectTab}
              onChange={(e, index) => {
                if (activeProjectTab !== index && !unsaveChange.current) {
                  dispatch(
                    Actions.AdminProjectTab.SetActiveProjectTab,
                    index
                  );
                  setActiveTabData(
                    index,
                    selectedTechBuyer.id,
                    adminProjectTab,
                    adminDropdowns
                  );
                } else {
                  showMessage(
                    UNSAVED_CHANGES_MESSAGE.message,
                    MessageType.Error
                  );
                }
              }}
              className={classes.tabs}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {TECH_BUYER_PROJECT_TAB.map((tab, index) => {
                return (
                  <Tab
                    className={`text-base leading-5 not-italic cursor-pointer whitespace-nowrap ${
                      activeProjectTab === index
                        ? `font-semibold`
                        : `font-light`
                    }`}
                    label={tab?.panel}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </Box>
        </div>
      </div>
      <div className="w-full h-[calc(100%-70px)] overflow-y-auto overflow-x-hidden">
        <TabPanel value={activeProjectTab} index={0}>
          <CompanyOverview setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeProjectTab} index={1}>
          <StrategyDiagnostic setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeProjectTab} index={2}>
          <AssessmentCriteria setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeProjectTab} index={3}>
          <Transformation setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeProjectTab} index={4}>
          <Implementation setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeProjectTab} index={5}>
          <Adoption setUnsaveChange={setUnsaveChange} />
        </TabPanel>
        <TabPanel value={activeProjectTab} index={6}>
          <Attachments setUnsaveChange={setUnsaveChange} />
        </TabPanel>
      </div>
    </div>
  );
};
