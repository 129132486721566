import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { TextField } from "@material-ui/core";
import { LabelStyle } from "../../../proptech/constants/General";
import { IconButton, InputAdornment } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function PlaceTextField(props) {
  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.handleChange}
      onSelect={props.handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative">
          <TextField
            {...getInputProps({
              placeholder: "Search Places ...",
              variant: "standard",
            })}
            labelStyle={LabelStyle}
            fullWidth
            label={props.label}
            error={props.error}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {props.value && <IconButton
                  aria-label="toggle password visibility"
                  onClick={props.handleClearField}
                >
                  <CloseIcon />
                </IconButton>}
              </InputAdornment>,
            }}
          />
          {suggestions?.length !== 0 && <div className="w-full bg-[#FFF] absolute z-50">
            {
              <div className="w-full h-[200px] overflow-auto">
                <ul>
                  {loading ? (
                    <li>Loading...</li>
                  ) : (
                    suggestions.map((suggestion, index) => (
                      <li className="border-b px-3 py-2 hover:cursor-pointer hover:text-[#FFF] hover:bg-[#111D43]" {...getSuggestionItemProps(suggestion)} key={index}>
                        {suggestion.description?.split(",")?.length !== 0 && suggestion.description?.split(",")[0]}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            }
          </div>}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

