export function companyDetailsDTO(data) {
  return {
    id: data?.id ? data.id : 0,
    proptechCompanyId: data?.proptechCompanyId ? data.proptechCompanyId : '',
    primaryUserId: data?.primaryUserId ? data.primaryUserId : 0,
    isSignupCompleted: data?.isSignupCompleted ? data.isSignupCompleted : false,
    signupProgress: data?.signupProgress ? data.signupProgress : 0,
    websiteURL: data?.websiteURL ? data.websiteURL : '',
    linkedInURL: data?.linkedInURL ? data.linkedInURL : '',
    phone: data?.phone ? data.phone : '',
    ventureScoutCode: data?.ventureScoutCode ? data.ventureScoutCode : '',
    countryId: data?.countryId ? data.countryId : null,
    regionId: data?.regionId ? data.regionId : 0,
    currencyId: data?.currencyId ? data.currencyId : null,
    investmentRoundId: data?.investmentRoundId ? data.investmentRoundId : null,
    investmentRangeId: data?.investmentRangeId ? data.investmentRangeId : null,
    companyLogo: data?.companyLogo ? data.companyLogo : '',
    elevatorPitch: data?.elevatorPitch ? data.elevatorPitch : '',
    technologyProduct: data?.technologyProduct ? data.technologyProduct : '',
    marketOpportunity: data?.marketOpportunity ? data.marketOpportunity : '',
    futureGoals: data?.futureGoals ? data.futureGoals : '',
    investability: data?.investability ? data.investability : '',
    teamStrength: data?.teamStrength ? data.teamStrength : '',
    KPIs: data?.KPIs ? data.KPIs : '',
    elevatorPitchAdmin: data?.elevatorPitchAdmin ? data.elevatorPitchAdmin : '',
    technologyProductAdmin: data?.technologyProductAdmin ? data.technologyProductAdmin : '',
    marketOpportunityAdmin: data?.marketOpportunityAdmin ? data.marketOpportunityAdmin : '',
    futureGoalsAdmin: data?.futureGoalsAdmin ? data.futureGoalsAdmin : '',
    investabilityAdmin: data?.investabilityAdmin ? data.investabilityAdmin : '',
    teamStrengthAdmin: data?.teamStrengthAdmin ? data.teamStrengthAdmin : '',
    KPIsAdmin: data?.KPIsAdmin ? data.KPIsAdmin : '',
    status: data?.status ? data.status : false,
    proptechCompany: {
      id: data?.proptechCompany?.id ? data.proptechCompany.id : 0,
      name: data?.proptechCompany?.name ? data.proptechCompany.name : ''
    },
    proptechUser: {
      id: data?.proptechUser?.id ? data.proptechUser.id : 0,
      name: data?.proptechUser?.name ? data.proptechUser.name : '',
      email: data?.proptechUser?.email ? data.proptechUser.email : ''
    },
    region: {
      id: data?.region?.id ? data.region.id : 0,
      name: data?.region?.name ? data.region.name : ''
    },
    country: {
      id: data?.country?.id ? data.country.id : 0,
      name: data?.country?.name ? data.country.name : ''
    },
    currency: {
      id: data?.currency?.id ? data.currency.id : 0,
      name: data?.currency?.name ? data.currency.name : ''
    },
    investmentRound: {
      id: data?.investmentRound?.id ? data.investmentRound.id : 0,
      name: data?.investmentRound?.name ? data.investmentRound.name : ''
    },
    investmentRange: {
      id: data?.investmentRange?.id ? data.investmentRange.id : 0,
      name: data?.investmentRange?.name ? data.investmentRange.name : ''
    },
    isPrimaryUser: data?.isPrimaryUser ? data.isPrimaryUser : false
  }
}