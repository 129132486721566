import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete } from "@material-ui/icons";
import DropdownColumn from "../../../DropdownColumn";
import {
  PROJECT_CRITERIA_ERROR,
  PROJECT_CRITERIA_PAYLOAD,
} from "../../../../utils/payloads/payloads";
import { AnalysisLabelStyle } from "../../../../../proptech/constants/General";
import PTInput from "../../../../../components/GenericComponents/PTInput";
import ConfirmModal from "../../../ConfirmModal";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import { dispatch } from "../../../../../utils/utility/store";
import { isUnsaveChanges } from "../../../../utils/middleware/projectTab";
import { trimQuery } from "../../../../../utils/utility/utility";
import { updateProjectCriteriaDetails } from "../../../../utils/middleware/projectCriteriaTab";
import { Actions } from "../../../../../store/actions";
import Loader from "../../../../../components/Loading";
import {
  cloneDeepArray,
  cloneDeepObject,
} from "../../../../utils/dtos/projectTabs";

const AssessmentCriteria = (props) => {
  const { setUnsaveChange } = props;

  const { projectKeyUsers, valueKPIs } = useSelector(
    (state) => state.adminDropdowns
  );
  const assessmentCriteriaData = useSelector(
    (state) => state.adminProjectTab.assessmentCriteriaData
  );
  const { isLoading } = useSelector((state) => state.adminUser);
  const { selectedTechBuyer } = useSelector((state) => state.adminTechBuyer);

  const [editedDetails, setEditedDetails] = useState(PROJECT_CRITERIA_PAYLOAD);
  const [errors, setErrors] = useState(PROJECT_CRITERIA_ERROR);
  const [openModal, setOpenModal] = useState(false);
  const [errorFields, setErrorFields] = useState([]);

  useEffect(() => {
    setDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentCriteriaData]);

  useEffect(() => {
    isUnsaveChanges(
      editedDetails,
      setUnsaveChange,
      "criteria",
      assessmentCriteriaData?.projectAssesmentCriteria,
      editedDetails.criteria
    );
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let fields = editedDetails?.criteria?.map((field, index) => {
      let errorFields = {};
      Object.entries(field).forEach(([key, val], idx) => {
        if (typeof val === "string" && val?.trim().length === 0) {
          errorFields[key] = `${key} ${index + 1} is required`;
        }
      });
      return Object.values(errorFields).length && errorFields;
    });
    setErrorFields(fields);
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !assessmentCriteriaData?.[error],
      };
    });
    setErrors(data);
    if (fields?.filter(Boolean).length !== 0) {
      return true;
    }
    return Object.keys(data).some((k) => data[k]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      ?.filter(([, value]) => value === null)
      .forEach(
        ([key, value]) => (newData[key] = assessmentCriteriaData?.[key])
      );

    handleCloseModal();
    try {
      let res = await updateProjectCriteriaDetails(selectedTechBuyer?.id, {
        ...Object.fromEntries(
          Object.entries(editedDetails)?.filter(([_, v]) => !!v)
        ),
        ...Object.fromEntries(Object.entries(newData)?.filter(([_, v]) => !!v)),
      });
      if (res === 200) setEditedDetails(PROJECT_CRITERIA_PAYLOAD);
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
    }
  };

  const setDetails = () => {
    if (
      assessmentCriteriaData?.projectAssesmentCriteria?.length !== 0 &&
      !isLoading
    ) {
      setEditedDetails({
        ...editedDetails,
        criteria: cloneDeepArray(
          assessmentCriteriaData?.projectAssesmentCriteria
        ) || [{ heading: "", value: "" }],
      });
    }
  };

  const addKeyCriteriaRow = () => {
    let temp = {
      heading: "",
      value: "",
    };
    let tempState = cloneDeepObject(editedDetails);
    tempState?.criteria?.push(temp);
    setEditedDetails(tempState);
  };

  const deleteKeyCriteriaRow = (index) => {
    const filteredStops = editedDetails?.criteria?.filter(
      (item, idx) => index !== idx
    );

    let tempState = cloneDeepObject(editedDetails);
    tempState.criteria = filteredStops;
    setEditedDetails(tempState);
  };

  const handleInputChange = (value, index, name) => {
    let tempState = cloneDeepObject(editedDetails);
    tempState.criteria[index][name] = value;
    setEditedDetails(tempState);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="mb-[22px] pb-10">
        <div className="analysis-dropdown-wrapper horizontal">
          <div className="dropdown-title flex w-[90%] items-center justify-between text-sm not-italic text-[#111D43]">
            <p>Key Criteria</p>
            <p
              className="underline hover:opacity-70 cursor-pointer"
              onClick={addKeyCriteriaRow}
            >
              Add more
            </p>
          </div>
          {editedDetails?.criteria?.map((item, idx) => {
            return (
              <div className="dropdown-items flex w-[90%]" key={idx}>
                <div className="dropdown-inner-item">
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    variant="standard"
                    label={`Key criteria ${idx + 1}`}
                    placeholder=""
                    maxLength={70}
                    value={item.heading}
                    handleOnChangeEvent={(e) => {
                      handleInputChange(e.target.value, idx, "heading");
                    }}
                    error={errorFields[idx]?.heading}
                    helperText={errorFields[idx]?.heading}
                  />
                </div>
                <div className="dropdown-inner2-item">
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    variant="standard"
                    label={`Key value ${idx + 1}`}
                    placeholder=""
                    multiline
                    maxRows={4}
                    maxLength={255}
                    value={item.value}
                    handleOnChangeEvent={(e) => {
                      handleInputChange(e.target.value, idx, "value");
                    }}
                    error={errorFields[idx]?.value}
                    helperText={errorFields[idx]?.value}
                  />
                </div>
                {idx !== 0 && (
                  <Delete
                    className="cursor-pointer"
                    onClick={() => deleteKeyCriteriaRow(idx)}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="mb-[22px] flex analysis-dropdown-wrapper">
          <DropdownColumn
            header="Key Users"
            dropdown1Label="Key Users 1"
            dropdown1Options={projectKeyUsers}
            dropdown1Value={
              editedDetails?.keyUser1 || assessmentCriteriaData?.keyUser1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, keyUser1: e.target.value })
            }
            dropdown1Error={errors.keyUser1}
            dropdown1HelperText={errors.keyUser1 && "Key user 1 Required."}
            dropdown2Label="Key Users 2"
            dropdown2Options={projectKeyUsers}
            dropdown2Value={
              editedDetails?.keyUser2 || assessmentCriteriaData?.keyUser2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, keyUser2: e.target.value })
            }
            dropdown2Error={errors.keyUser2}
            dropdown2HelperText={errors.keyUser2 && "Key user 2 Required."}
            dropdown3Label="Key Users 3"
            dropdown3Options={projectKeyUsers}
            dropdown3Value={
              editedDetails?.keyUser3 || assessmentCriteriaData?.keyUser3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, keyUser3: e.target.value })
            }
            dropdown3Error={errors.keyUser3}
            dropdown3HelperText={errors.keyUser3 && "Key user 3 Required."}
          />
          <DropdownColumn
            header="Key Metrics"
            dropdown1Label="Key Metrics 1"
            dropdown1Options={valueKPIs}
            dropdown1Value={
              editedDetails?.keyMetric1 || assessmentCriteriaData?.keyMetric1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                keyMetric1: e.target.value,
              })
            }
            dropdown1Error={errors.keyMetric1}
            dropdown1HelperText={errors.keyMetric1 && "Key metric 1 Required."}
            dropdown2Label="Key Metrics 2"
            dropdown2Options={valueKPIs}
            dropdown2Value={
              editedDetails?.keyMetric2 || assessmentCriteriaData?.keyMetric2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                keyMetric2: e.target.value,
              })
            }
            dropdown2Error={errors.keyMetric2}
            dropdown2HelperText={errors.keyMetric2 && "Key metric 2 Required."}
            dropdown3Label="Key Metrics 3"
            dropdown3Options={valueKPIs}
            dropdown3Value={
              editedDetails?.keyMetric3 || assessmentCriteriaData?.keyMetric3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                keyMetric3: e.target.value,
              })
            }
            dropdown3Error={errors.keyMetric3}
            dropdown3HelperText={errors.keyMetric3 && "Key metric 3 Required."}
          />
        </div>
        <div className="flex justify-end bottom-btn-wrapper w-[95%]">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails({
                ...PROJECT_CRITERIA_PAYLOAD,
                criteria: cloneDeepArray(
                  assessmentCriteriaData?.projectAssesmentCriteria?.length
                    ? assessmentCriteriaData?.projectAssesmentCriteria
                    : [{ heading: "", value: "" }]
                ),
              });
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() =>
              !checkValidation() === true && handleOpenModal()
            }
          />
        </div>
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
};

export default AssessmentCriteria;
