export function upcomingMeetingsListDTO(data) {
  let meetingsList = [];
  if (data && data.length) {
    meetingsList = data.map((meeting) => {
      return {
        id: meeting?.id || 0,
        duration: meeting?.duration || null,
        endTime: meeting?.endTime || null,
        startTime: meeting?.startTime || null,
        meetingLink: meeting?.meetingLink || null,
        status: meeting?.status !== null ? meeting.status : false,
        rescheduleMeetingCount: meeting?.rescheduleMeetingCount !== null ? meeting.rescheduleMeetingCount : null,
        createdAt: meeting?.createdAt || null,
        updatedAt: meeting?.updatedAt || null,
        vcCompanyDetails: meeting?.vcCompanyDetails ? {
          id: meeting?.vcCompanyDetails?.id || null,
          companyLogo: meeting?.vcCompanyDetails?.companyLogo || null,
          vcCompany: meeting?.vcCompanyDetails?.vcCompany ? {
            id: meeting?.vcCompanyDetails?.vcCompany?.id || 0,
            name: meeting?.vcCompanyDetails?.vcCompany?.name || '',
          } : {},
        } : {},
      }
    })
  }

  return meetingsList;
}


export function previousMeetingsListDTO(data) {
  let meetingsList = [];
  if (data && data.length) {
    meetingsList = data.map((meeting) => {
      return {
        id: meeting?.id || 0,
        duration: meeting?.duration || null,
        endTime: meeting?.endTime || null,
        startTime: meeting?.startTime || null,
        status: meeting?.status !== null ? meeting.status : false,
        createdAt: meeting?.createdAt || null,
        updatedAt: meeting?.updatedAt || null,
        vcCompanyDetails: meeting?.vcCompanyDetails ? {
          id: meeting?.vcCompanyDetails?.id || null,
          companyLogo: meeting?.vcCompanyDetails?.companyLogo || null,
          vcCompany: meeting?.vcCompanyDetails?.vcCompany ? {
            id: meeting?.vcCompanyDetails?.vcCompany?.id || 0,
            name: meeting?.vcCompanyDetails?.vcCompany?.name || '',
          } : {},
        } : {},
      }
    })
  }

  return meetingsList;
}