export function raiseOverviewTabDTO(data) {
    return {
        id: data?.id || 0,
        investmentRangeId: data?.investmentRangeId || null,
        investmentRoundId: data?.investmentRoundId || null,
        investmentTypeId: data?.investmentTypeId || null,
        keyBizModelId: data?.keyBizModelId || null,
        priorInvestmentRangeId: data?.priorInvestmentRangeId || null,
        priorInvestmentRoundId: data?.priorInvestmentRoundId || null,
        priorInvestmentTypeId: data?.priorInvestmentTypeId || null,
        priorKeyBizModelId: data?.priorKeyBizModelId || null,
        priorRaiseDate: data?.priorRaiseDate || null,
        priorValuationRangeId: data?.priorValuationRangeId || null,
        committedId: data?.committedId || null,
        raiseDate: data?.raiseDate || null,
        isNotRaising: data?.isNotRaising || false,
        valuationRangeId: data?.valuationRangeId || null,
        investmentRange: {
            id: data?.investmentRange?.id || 0,
            name: data?.investmentRange?.name || '',
        },
        investmentRound: {
            id: data?.investmentRound?.id || 0,
            name: data?.investmentRound?.name || '',
        },
        investmentType: {
            id: data?.investmentType?.id || 0,
            name: data?.investmentType?.name || '',
        },
        keyBizModel: {
            id: data?.keyBizModel?.id || 0,
            name: data?.keyBizModel?.name || '',
        },
        priorInvestmentRange: {
            id: data?.priorInvestmentRange?.id || 0,
            name: data?.priorInvestmentRange?.name || '',
        },
        priorInvestmentRound: {
            id: data?.priorInvestmentRound?.id || 0,
            name: data?.priorInvestmentRound?.name || '',
        },
        priorInvestmentType: {
            id: data?.priorInvestmentType?.id || 0,
            name: data?.priorInvestmentType?.name || '',
        },
        priorKeyBizModel: {
            id: data?.priorKeyBizModel?.id || 0,
            name: data?.priorKeyBizModel?.name || '',
        },
        priorValuationRange: {
            id: data?.priorValuationRange?.id || 0,
            name: data?.priorValuationRange?.name || '',
        },
        valuationRange: {
            id: data?.valuationRange?.id || 0,
            name: data?.valuationRange?.name || '',
        },
    }
}