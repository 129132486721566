import React, { useEffect, useState } from "react";
import SignupDetailsNavbar from "../../components/SignupDetailsNavbar";
import LeftSideSignupDetailsData from '../../components/LeftSideSignupDetailsData';
import BasicInfo from "../../components/BasicInfo";
import Heading1 from "../../components/Heading1";
import Heading2 from "../../components/Heading2";
import Review from "../../components/Review";
import { getCountriesData, getCurrenciesData, getInvestmentRangesData, getInvestmentRoundsData } from "../../../utils/middleware/Dropdowns";
import { getCompanyDetails } from "../../../utils/middleware/SignupDetails";
import SelectPackage from "../../components/SelectPackage";
import { PROPTECH_API } from "../../../utils/utility/API-call";
import { PROPTECH_PLANS_API } from "../../../constants/APIs";
import { handleError } from "../../../utils/utility/snackbar";

function SignupDetails() {
  // State
  const [activeStep, setActiveStep] = useState(0)
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [investmentRounds, setInvestmentRounds] = useState([]);
  const [investmentRanges, setInvestmentRanges] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Handle active tab  
  const getStepperTab = (step) => {
    switch (step) {
      case 0: return <BasicInfo setActiveStep={setActiveStep} countries={countries} currencies={currencies} investmentRounds={investmentRounds} investmentRanges={investmentRanges} />
      case 1: return <Heading1 setActiveStep={setActiveStep} />
      case 2: return <Heading2 setActiveStep={setActiveStep} />
      case 3: return <Review setActiveStep={setActiveStep} />
      case 4: return <SelectPackage setActiveStep={setActiveStep} plans={plans} activePlan={selectedPlan} />
      default: return <BasicInfo setActiveStep={setActiveStep} />
    }
  }

  // Api call for CompanyDetails, CountriesData, CurrenciesData, InvestmentRoundsData, InvestmentRangesData
  useEffect(() => {
    getCompanyDetails(setActiveStep)
    getCountriesData(setCountries);
    getCurrenciesData(setCurrencies);
    getInvestmentRoundsData(setInvestmentRounds);
    getInvestmentRangesData(setInvestmentRanges);
  }, [])

  // Fetching proptech plans
  useEffect(() => {
    if (activeStep === 4) {
      PROPTECH_API().get(PROPTECH_PLANS_API).then(res => {
        setPlans(res.data.data.plans)
        setSelectedPlan(res.data.data.activePlan ? res.data.data.activePlan - 1 : res.data.data.activePlan)
      }).catch(err => handleError(err))
    }
  }, [activeStep])

  return (
    <div className="w-full h-full flex flex-col overflow-x-hidden ">
      <SignupDetailsNavbar />
      <div className="w-full bg-[#E7E8EC] flex box-border p-12 grow proptech-signup-detail-wrapper flex-wrap">
        <LeftSideSignupDetailsData setActiveStep={setActiveStep} />
        {getStepperTab(activeStep)}
      </div>
    </div>
  )
}

export default SignupDetails;
