import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PTButton from '../../../components/GenericComponents/PTButton';
import Check from '../../../assets/Icons/green-check.svg';

export default function ConfirmModal(props) {
  // Props
  const { open, handleCloseModal, message, handleSucces } = props

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: message ? 450 : 400, bgcolor: 'background.paper', borderRadius: 4, border: 'none', p: 4,
      }}>
        <Typography id="modal-modal-description" sx={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
          <div className="h-full flex flex-col justify-center items-center">
            <img src={Check} alt="Icon" width={69} height={69} />
            {message ? <div className="w-[360px] mt-7 font-semibold h-5 text-base leading-5 text-center not-italic font-['Poppins']">{message}</div> :
              <div className="w-[303px] mt-14 font-semibold h-5 text-4xl leading-5 text-center not-italic font-['Poppins']">Are you sure?</div>}
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '60px' }}>
          <PTButton label={<span className="font-semibold h-5 text-sm leading-5 text-center not-italic w-[87px] font-['Poppins']">Yes</span>} className="w-[114px] h-[48px] rounded-xl border-solid border border-[#111D43] !bg-[#111D43]" handleOnClickEvent={handleSucces} />
          <PTButton label={<span className="font-semibold h-5 text-sm leading-5 text-center not-italic w-[87px] font-['Poppins']">No</span>} className="w-[114px] h-[48px] rounded-xl border-solid border border-[#111D43] !bg-[#111D43]" handleOnClickEvent={handleCloseModal} />
        </Typography>
      </Box>
    </Modal >
  )
}
