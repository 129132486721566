import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Modal from '@mui/material/Modal';
import PTButton from '../../../components/GenericComponents/PTButton';
import Loader from '../../../components/Loading';
import { uploadCompanyImage } from '../../../utils/middleware/LeftSideSignupDetails';

function LeftSideSignupDetailsData({ setActiveStep }) {
  // Store
  const { companyDetails } = useSelector(state => state.proptechUser)

  // State
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [file, setFile] = useState(null)
  const inputRef = useRef();

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setFile(null)
  }

  // handle set image file 
  const handleImage = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0])
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="left-side-bar">
        <div className="p-14 rounded-3xl bg-[#FFFFFF] h-full boxshadow-box">
          <div className="box-border pb-4 md:pb-10 flex flex-col justify-between items-center">
            <div className="w-20 sm:w-40 h-20 sm:h-40 rounded-full flex justify-center items-center cursor-pointer bg-[#E7E8EC] overflow-hidden" onClick={() => companyDetails?.isPrimaryUser ? handleOpenModal() : null}>
              {!selectedFile && !companyDetails?.companyLogo ? <FileUploadOutlinedIcon /> :
                <img src={selectedFile ? selectedFile : companyDetails?.companyLogo} alt="Logo" className="h-full w-full max-w-none object-cover" />}</div>
            <div className="mt-2 md:mt-10">
              <div className="box-border font-semibold text-lg leading-5 text-center not-italic text-[#111D43]">{companyDetails?.proptechCompany?.name}</div>
              <div className="font-semibold mt-1 text-xs leading-4 text-center not-italic uppercase"><span className="text-[#111D43] opacity-40">Status</span> {companyDetails?.status ? <span className="text-[#39B54A] opacity-100">Active</span> : <span className="text-[#FF4100] opacity-100">Inactive</span>}</div>
            </div>
          </div>
          <div className="border-t" />
          {/* Proptech user details block */}
          <div>
            <div className="mt-4 md:mt-10">
              <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">Primary Contact</div>
              <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43]">{companyDetails?.proptechUser?.name}</div>
            </div>
            <div className="mt-4 md:mt-10">
              <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">business email</div>
              <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43]">{companyDetails?.proptechUser?.email}</div>
            </div>
            <div className="mt-4 md:mt-10">
              <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">business phone</div>
              <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43]">{companyDetails?.phone}</div>
            </div>
            <div className="mt-4 md:mt-10">
              <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">linkedin url</div>
              <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43]">{companyDetails?.linkedInURL}</div>
            </div>
            <div className="mt-4 md:mt-10">
              <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">Country</div>
              <div className="mt-2 font-normal text-sm leading-5 text-center not-italic capitalize text-[#111D43]">{companyDetails?.country?.name}</div>
            </div>
          </div>
          {/* Company logo upload modal */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: 4, border: 'none', p: 4,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Company Logo
              </Typography>
              <Typography id="modal-modal-description" title="Click to change Company Logo" sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', marginTop: '24px' }} onClick={() => inputRef.current.click()}>
                {!(file || companyDetails?.companyLogo) ?
                  <div className="w-[300px] h-[300px] rounded-full flex justify-center items-center bg-[#E7E8EC]">
                    <FileUploadOutlinedIcon fontSize="large" />
                  </div> :
                  <img src={file ? URL.createObjectURL(file) : companyDetails?.companyLogo} width={300} alt="Logo" style={{ height: "300px", borderRadius: "50%", objectFit: "cover" }} />}
              </Typography>
              <input
                style={{ display: 'none' }}
                id="file"
                ref={inputRef}
                type="file"
                className="form-control"
                accept={['jpg', 'jpeg', 'png']
                  .map(function (x) {
                    return '.' + x;
                  })
                  .join(',')}
                onChange={handleImage}
              />
              <Typography id="modal-modal-description" sx={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                <PTButton label="Upload" disabled={!file} className="w-[165px] bg-[#111D43] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]" handleOnClickEvent={() => uploadCompanyImage(file, setSelectedFile, handleCloseModal, setIsLoading, setActiveStep)} />
              </Typography>
            </Box>
          </Modal>
        </div>
      </div >
    </>
  )
}

export default LeftSideSignupDetailsData;
