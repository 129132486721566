export function teamTabDTO(data) {
    return {
        id: data?.id || 0,
        noOfOffices: data?.noOfOffices || null,
        productVideo: data?.productVideo || null,
        yearFounded: data?.yearFounded || null,
        noOfEmployees: data?.noOfEmployees || null,
        keyHireMapping1: data?.keyHireMapping1 || null,
        keyHireMapping2: data?.keyHireMapping2 || null,
        keyHireMapping3: data?.keyHireMapping3 || null,
        skill1: data?.skill1 || null,
        skill2: data?.skill2 || null,
        skill3: data?.skill3 || null,
        award1: data?.award1 || null,
        award1Image: data?.award1Image || "",
        award2: data?.award2 || null,
        award2Image: data?.award2Image || "",
        award3: data?.award3 || null,
        award3Image: data?.award3Image || "",
        location1: data?.location1 || null,
        location1Latitude: data?.location1Latitude || null,
        location1Longitude: data?.location1Longitude || null,
        location2: data?.location2 || null,
        location2Latitude: data?.location2Latitude || null,
        location2Longitude: data?.location2Longitude || null,
        location3: data?.location3 || null,
        location3Latitude: data?.location3Latitude || null,
        location3Longitude: data?.location3Longitude || null,
        proptechAnalysisKeyHireMapping1: {
            id: data?.proptechAnalysisKeyHireMapping1?.id || 0,
            name: data?.proptechAnalysisKeyHireMapping1?.name || '',
        },
        proptechAnalysisKeyHireMapping2: {
            id: data?.proptechAnalysisKeyHireMapping2?.id || 0,
            name: data?.proptechAnalysisKeyHireMapping2?.name || '',
        },
        proptechAnalysisKeyHireMapping3: {
            id: data?.proptechAnalysisKeyHireMapping3?.id || 0,
            name: data?.proptechAnalysisKeyHireMapping3?.name || '',
        },
        proptechCompanyDetails: {
            id: data?.proptechCompanyDetails?.id || 0,
            teamStrengthAdmin: data?.proptechCompanyDetails?.teamStrengthAdmin || '',
        },
        proptechAnalysisNoOfEmployee: {
            id: data?.proptechAnalysisNoOfEmployee?.id || 0,
            name: data?.proptechAnalysisNoOfEmployee?.name || '',
        },
        proptechAnalysisTeamSkill1: {
            id: data?.proptechAnalysisTeamSkill1?.id || 0,
            name: data?.proptechAnalysisTeamSkill1?.name || '',
        },
        proptechAnalysisTeamSkill2: {
            id: data?.proptechAnalysisTeamSkill2?.id || 0,
            name: data?.proptechAnalysisTeamSkill2?.name || '',
        },
        proptechAnalysisTeamSkill3: {
            id: data?.proptechAnalysisTeamSkill3?.id || 0,
            name: data?.proptechAnalysisTeamSkill3?.name || '',
        },
    }
}