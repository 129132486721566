import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Proptechcard from './ProptechCard';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { setActivetabData } from '../../../utils/middleware/adminProptechTab';
import { getProptechsForReport, updateProptechsForReport } from '../../../utils/middleware/AdminMarketInsightReports';
import Loader from '../../../../components/Loading';

export default function AssignMarketInsightReport() {
  // Store
  const { selectedMarketInsightReport, isLoading } = useSelector(state => state.adminMarketInsightReports);
  const { proptechsForReports, selectedProptechsForReport } = useSelector(state => state.adminMarketInsightReports);
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)

  // State
  const [searchText, setSearchText] = useState('')

  const handleCheckboxSelection = (e, id) => {
    updateProptechsForReport(selectedMarketInsightReport?.id, {
      proptechCompanyDetailsId: id,
      status: e.target.checked
    }, null, adminDropdowns)
  }

  const handleSearch = (e) => {
    let text = e.target.value ? e.target.value.trim() : ''
    setSearchText(e.target.value)
    if (e.key === 'Enter') {
      setSearchText(text)
      getProptechsForReport(selectedMarketInsightReport?.id, text)
      // dispatch(Actions.AdminInvestor.SetSearchProptech, text)
    }
  }

  const handleCloseSearch = () => {
    dispatch(Actions.AdminInvestor.SetSearchProptech, '')
    getProptechsForReport(selectedMarketInsightReport?.id)
    setSearchText('')
  }

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminUser.SetIsSelectedProptech, true)
    dispatch(Actions.AdminUser.SetSelectedProptech, { id })
    setActivetabData(0, id, adminAnalysisTab, adminDropdowns)
  }

  return (
    <>
    {isLoading ? <Loader /> : null}
    <div className='for-you-tab-reports'>
      <PTInput label="Search" className='w-1/2' value={searchText} onKeyPress={handleSearch} handleOnChangeEvent={handleSearch} variant="standard" placeholder='' icon={<CloseIcon className='cursor-pointer' onClick={handleCloseSearch} />} />
      <div className='main-container'>
        <div className='left-column'>
          <div className='heading'>All</div>
          {!!proptechsForReports?.length && proptechsForReports.map((proptech, index) => {
            return <Proptechcard logo={proptech.companyLogo} key={index} onCardClick={() => handleCardClick(proptech?.id)} title={proptech.proptechCompany.name} publishedAt={proptech.publishedAt} checked={proptech?.proptechMarketInsight?.some(i => i?.status)} onChecked={(e) => handleCheckboxSelection(e, proptech.id)} meetings={proptech?.totalMeetingCount} type="all" assignedReport={proptech?.proptechMarketInsight?.filter(i => i?.status)}/>
          })}
        </div>
        <div className='right-column'>
          <div className='heading'>Selected</div>
          {!!selectedProptechsForReport?.length && selectedProptechsForReport.map((proptech, index) => {
            return <Proptechcard logo={proptech.companyLogo} key={index} onCardClick={() => handleCardClick(proptech?.id)} title={proptech.proptechCompany.name} publishedAt={proptech.publishedAt} checked={proptech?.proptechMarketInsight?.some(i => i?.status)} onChecked={(e) => handleCheckboxSelection(e, proptech.id)} meetings={proptech?.totalMeetingCount} />
          })}
        </div>
      </div>
    </div>
    </>
  )
}
