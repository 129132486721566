import { MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { LabelStyle } from '../../../../proptech/constants/General';
import { fetchCalendlyUrl, updateCompanyDetails, uploadImage } from '../../../utils/middleware/companyTab';
import ConfirmModal from '../../ConfirmModal';
import Loader from '../../../../components/Loading';
import placeholderImage from '../../../../assets/Icons/placeholder.png';
import { COMPANY_TAB_ERRORS, COMPANY_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { trimQuery, urlValidate } from '../../../../utils/utility/utility';
import { COMPANY_ERROR_TEXT } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function CompanyTab(props) {
  const { setUnsaveChange } = props

  // Store
  const companyTabData = useSelector(state => state.adminAnalysisTab.companyTabData)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const { countries, revenueRanges, megaTrends, languages } = useSelector(state => state.adminDropdowns)
  const { isLoading, activeSort, sortOrder, isArchived, search } = useSelector(state => state.adminUser);

  // State
  const [editedDetails, setEditedDetails] = useState(COMPANY_TAB_PAYLOAD);
  const [errors, setErrors] = useState(COMPANY_TAB_ERRORS);
  const [selectedKey, setSelectedKey] = useState('');
  const [previewField, setPreviewField] = useState('companyLogo');
  const [files, setFiles] = useState({
    companyLogo: null,
    profileImage: null,
    keyCustomer1Logo: null,
    keyCustomer2Logo: null,
    keyCustomer3Logo: null,
  })
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef();

  const bucketTypes = {
    companyLogo: "proptech-company-logo",
    profileImage: "proptech-user-profile-image",
    keyCustomer1Logo: "customer-logo",
    keyCustomer2Logo: "customer-logo",
    keyCustomer3Logo: "customer-logo",
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ?
          (error === 'calendlyURL' || error === 'founderPitchVideo') ? editedDetails?.[error] ? !urlValidate(editedDetails?.[error]) : false : !editedDetails?.[error] :
          (error === 'calendlyURL' || error === 'founderPitchVideo') ? companyTabData?.[error] ? !urlValidate(companyTabData?.[error]) : false : !companyTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleImage = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFiles({ ...files, [key]: e.target.files[0] })
      uploadImage(e.target.files[0], bucketTypes[key])
        .then(res => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, [key]: res.data.fileName })
        })
        .catch(() => setLoading(false))
    }
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => {
        if (bucketTypes.hasOwnProperty(key)) {
          newData[key] = key === 'profileImage' ? companyTabData.proptechUser[key]?.split("/").slice(-2).join("/") : companyTabData[key]?.split("/").slice(-2).join("/")
        } else {
          newData[key] = companyTabData[key]
        }
      });

    handleCloseModal()
    try {
      await updateCompanyDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => !!v)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v))
        }, isArchived, activeSort, sortOrder, search)
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }

  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='h-full flex flex-col overflow-x-hidden overflow-y-auto'>
        <div className='w-full flex company-two-col-block mb-12'>
          <input
            style={{ display: 'none' }}
            id="file"
            ref={inputRef}
            type="file"
            className="form-control"
            value=''
            accept={['jpg', 'jpeg', 'png']
              .map(function (x) {
                return '.' + x;
              })
              .join(',')}
            onChange={(e) => handleImage(e, selectedKey)}
          />
          <div className='left-form-block'>
            <div className='flex justify-between form-item-wrap items-center'>
              <span>Company logo</span>
              <div>
                <div className='flex'>
                  <PTButton
                    variant="contained"
                    label={!(loading && selectedKey === 'companyLogo') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                    className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                    loading={loading && selectedKey === 'companyLogo'}
                    handleOnClickEvent={() => {
                      inputRef.current.click()
                      setSelectedKey('companyLogo')
                    }}
                  />
                  <PTButton
                    variant="contained"
                    label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                    className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                    handleOnClickEvent={() => {
                      setPreviewField('companyLogo')
                    }} />
                </div>
                <span className='font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]'>{errors.companyLogo && "Company Logo Required."}</span>
              </div>
            </div>
            <div className='flex justify-between form-item-wrap items-center'>
              <span>Personal profile image</span>
              <div className='flex'>
                <PTButton variant="contained"
                  label={!(loading && selectedKey === 'profileImage') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'profileImage'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('profileImage')
                  }} />
                <PTButton variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  type="submit" className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('profileImage')
                  }} />
              </div>
            </div>
            <div className='flex justify-between form-item-wrap items-end'>
              <span>Select country</span>
              <PTInput
                variant="standard"
                label="Country"
                className="custom-dropdown-icon"
                select
                selectChildren={countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                placeholder=''
                disabled={loading}
                value={editedDetails?.countryId || companyTabData?.countryId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, countryId: e.target.value })}
                error={errors.countryId}
                helperText={errors.countryId && "Country Required."}
              />
            </div>
            <div className='flex justify-between form-item-wrap items-end'>
              <span>Revenue range</span>
              <PTInput
                variant="standard"
                label="Revenue range"
                className="custom-dropdown-icon"
                select
                selectChildren={revenueRanges.map(revenueRange => <MenuItem value={revenueRange.id}>{revenueRange.name}</MenuItem>)}
                placeholder=''
                disabled={loading}
                value={editedDetails?.revenueRangeId || companyTabData?.revenueRangeId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, revenueRangeId: e.target.value })}
              />
            </div>
            <div className='flex justify-between form-item-wrap items-end'>
              <span>Megatrend</span>
              <PTInput
                variant="standard"
                label="Megatrend"
                className="custom-dropdown-icon"
                select
                selectChildren={megaTrends.map(megaTrend => <MenuItem value={megaTrend.id}>{megaTrend.name}</MenuItem>)}
                placeholder=''
                disabled={loading}
                value={editedDetails?.megaTrendId || companyTabData?.megaTrendId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, megaTrendId: e.target.value })}
              />
            </div>
            <div className='flex justify-between form-item-wrap items-end'>
              <span>Language</span>
              <PTInput
                variant="standard"
                label="Language"
                className="custom-dropdown-icon"
                select
                selectChildren={languages.map(language => <MenuItem value={language.id}>{language.name}</MenuItem>)}
                placeholder=''
                disabled={loading}
                value={editedDetails?.languageId || companyTabData?.languageId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, languageId: e.target.value })}
              />
            </div>
            <div className='url-list'>
              <div className='url-list-item'>
                <PTInput
                  labelStyle={LabelStyle}
                  variant="standard"
                  label="Vimeo url"
                  fullWidth
                  placeholder=''
                  disabled={loading}
                  value={editedDetails?.founderPitchVideo !== null ? editedDetails?.founderPitchVideo : companyTabData?.founderPitchVideo || ''}
                  handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, founderPitchVideo: e.target.value })}
                  error={errors.founderPitchVideo}
                  helperText={errors.founderPitchVideo && COMPANY_ERROR_TEXT.url}
                />
              </div>
              <div className='url-list-item fetch-url-button'>
                <PTInput
                  labelStyle={LabelStyle}
                  variant="standard"
                  label="calendly URL"
                  fullWidth
                  placeholder=''
                  disabled
                  value={editedDetails?.calendlyURL !== null ? editedDetails?.calendlyURL : companyTabData?.calendlyURL || ''}
                  error={errors.calendlyURL}
                  helperText={errors.calendlyURL && COMPANY_ERROR_TEXT.url}
                />
                <PTButton variant="outlined" className='ml-5 mt-3' label={!loading && "Fetch"} color='primary'
                  handleOnClickEvent={() => fetchCalendlyUrl(selectedProptech?.id, setLoading).then((res) => setEditedDetails({ ...editedDetails, calendlyURL: res })).catch()} loading={loading} />
              </div>
            </div>
          </div>
          <div className='right-image-block'>
            {previewField && (files?.[previewField] || companyTabData?.[previewField] || companyTabData?.proptechUser?.[previewField]) ?
              <div className='inner-image-block'><img src={files?.[previewField] ? URL.createObjectURL(files?.[previewField]) : previewField === 'profileImage' ? companyTabData?.proptechUser?.[previewField] : companyTabData[previewField]} alt="Profile" width={'100%'} height={'100%'} className='object-contain'/></div> :
              <div className='inner-image-block'><img src={placeholderImage} alt="placeholder" /></div>}
          </div>
        </div>
        <div className='analysis-dropdown-wrapper horizontal'>
          <div className="dropdown-title not-italic text-[#111D43]">Key Customers</div>
          <div className='dropdown-items flex'>
            <div className='dropdown-inner-item'>
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="key Customer 1"
                placeholder=''
                disabled={loading}
                value={editedDetails?.keyCustomer1 !== null ? editedDetails?.keyCustomer1 : companyTabData?.keyCustomer1 || ''}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, keyCustomer1: e.target.value })}
              />
            </div>
            <div className='dropdown-inner-item'>
              <div className='flex  sm:justify-center'>
                <PTButton
                  variant="contained"
                  label={!(loading && selectedKey === 'keyCustomer1Logo') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'keyCustomer1Logo'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('keyCustomer1Logo')
                  }}
                />
                <PTButton
                  variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('keyCustomer1Logo')
                  }} />
              </div>
            </div>
          </div>
          <div className="dropdown-items flex">
            <div className='dropdown-inner-item'>
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="key Customer 2"
                placeholder=''
                disabled={loading}
                value={editedDetails?.keyCustomer2 !== null ? editedDetails?.keyCustomer2 : companyTabData?.keyCustomer2 || ''}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, keyCustomer2: e.target.value })}
              />
            </div>
            <div className='dropdown-inner-item'>
              <div className='flex sm:justify-center'>
                <PTButton
                  variant="contained"
                  label={!(loading && selectedKey === 'keyCustomer2Logo') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'keyCustomer2Logo'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('keyCustomer2Logo')
                  }}
                />
                <PTButton
                  variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('keyCustomer2Logo')
                  }} />
              </div>
            </div>
          </div>
          <div className="dropdown-items flex">
            <div className="dropdown-inner-item">
              <PTInput
                labelStyle={LabelStyle}
                variant="standard"
                label="key Customer 3"
                className="grow"
                placeholder=''
                disabled={loading}
                value={editedDetails?.keyCustomer3 !== null ? editedDetails?.keyCustomer3 : companyTabData?.keyCustomer3 || ''}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, keyCustomer3: e.target.value })}
              />
            </div>
            <div className="dropdown-inner-item">
              <div className='flex sm:justify-center'>
                <PTButton
                  variant="contained"
                  label={!(loading && selectedKey === 'keyCustomer3Logo') && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading && selectedKey === 'keyCustomer3Logo'}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                    setSelectedKey('keyCustomer3Logo')
                  }}
                />
                <PTButton
                  variant="contained"
                  label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Preview</span>}
                  className='w-[120px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
                  handleOnClickEvent={() => {
                    setPreviewField('keyCustomer3Logo')
                  }} />
              </div>
            </div>
          </div>
        </div>

        <div className='bottom-btn-wrapper flex justify-end'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
            type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => {
              setEditedDetails(COMPANY_TAB_PAYLOAD)
              setPreviewField('companyLogo')
              setFiles({
                companyLogo: null,
                profileImage: null,
                keyCustomer1Logo: null,
                keyCustomer2Logo: null,
                keyCustomer3Logo: null,
              })
            }} />
          <PTButton
            variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">Save & Continue</span>}
            type="submit"
            className='w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div></>
  )
}
