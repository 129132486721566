import { PROPTECH_PROFILE_API } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { PROPTECH_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
import { companyProfileTabVCDTO } from "../../../vc/utils/dtos/companyProfileDTO";
import { designReviewTabVCDTO } from "../../../vc/utils/dtos/designReviewVC";
import { marketInsightsTabVCDTO } from "../../../vc/utils/dtos/marketInsightsVC";
import { marketTabVCDTO } from "../../../vc/utils/dtos/marketVC";
import { numbersTabVCDTO } from "../../../vc/utils/dtos/numbersVC";
import { overviewTabVCDTO } from "../../../vc/utils/dtos/overviewVC";
import { raiseOverviewTabVCDTO } from "../../../vc/utils/dtos/raiseOverviewVC";
import { raiseTabVCDTO } from "../../../vc/utils/dtos/raiseVc";
import { teamTabVCDTO } from "../../../vc/utils/dtos/teamTabVC";

export const getProptechDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(PROPTECH_PROFILE_API).then((res) => {
    dispatch(Actions.VCProptechDetails.setProptechDetails, res?.data?.data);
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getOverviewDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/overview`).then((res) => {
    dispatch(Actions.VCProptechDetails.setOverviewDetails, overviewTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getMarketDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/market`).then((res) => {
    dispatch(Actions.VCProptechDetails.setMarketDetails, marketTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getNumbersDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/numbers`).then((res) => {
    dispatch(Actions.VCProptechDetails.setNumbersDetails, numbersTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getRaiseDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/raise`).then((res) => {
    dispatch(Actions.VCProptechDetails.setRaiseDetails, raiseTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getTeamsDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/team`).then((res) => {
    dispatch(Actions.VCProptechDetails.setTeamsDetails, teamTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getDesignReviewDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/designReview`).then((res) => {
    dispatch(Actions.VCProptechDetails.setDesignReviewDetails, designReviewTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getRaiseOverviewDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/raiseOverview`).then((res) => {
    dispatch(Actions.VCProptechDetails.setRaiseOverview, raiseOverviewTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getMarketInsightsDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/marketInsights`).then((res) => {
    dispatch(Actions.VCProptechDetails.setMarketInsightsDetails, marketInsightsTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}

export const getCompanyProfileDetails = () => {
  dispatch(Actions.ProptechUser.SetLoading, true)
  PROPTECH_API().get(`${PROPTECH_PROFILE_API}/companyProfile`).then((res) => {
    dispatch(Actions.VCProptechDetails.setCompanyProfileDetails, companyProfileTabVCDTO(res?.data?.data));
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleSuccess(res)
  }).catch((err) => {
    dispatch(Actions.ProptechUser.SetLoading, false)
    handleError(err)
  })
}