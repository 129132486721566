export default function admin(
  initialState = {
    activeTab: 0,
    isRedirect: false
  },
  action
) {
  switch (action.type) {
    case 'SET_ADMIN_ACTIVE_TAB':
      return {
        ...initialState,
        activeTab: action.payload,
      };

    case 'SET_ADMIN_IS_REDIRECT':
      return {
        ...initialState,
        isRedirect: action.payload,
      };

    case 'ADMIN_RESET':
      return {
        ...initialState,
        activeTab: 0,
        isRedirect: false
      };

    default:
      return initialState;
  }
}
