import React from 'react';
import PTInput from '../../../components/GenericComponents/PTInput';
import { AnalysisLabelStyle } from '../../../proptech/constants/General';
import { COMPANY_ERROR_TEXT } from '../../constants';

export default function FullWidthTextField(props) {
  // Props
  const { editedDetails, setEditedDetails, type, errors, disabled, textLabel, textValue, maxLength, data, showChar, validUrlText } = props;

  return (
    <div className='mb-[25px]'>
      <PTInput
        labelStyle={AnalysisLabelStyle}
        variant="standard"
        label={textLabel}
        placeholder=''
        fullWidth
        multiline={!type}
        type="number"
        maxRows={4}
        maxLength={maxLength === undefined ? 255 : maxLength}
        disabled={disabled}
        value={editedDetails?.[textValue] !== null ? editedDetails?.[textValue] : data?.[textValue] || (type ? 0 : '')}
        handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, [textValue]: e.target.value })}
        error={errors?.[textValue]}
        helperText={errors?.[textValue] && `${validUrlText ? COMPANY_ERROR_TEXT.url : `${textLabel} Required.`}`}
      />
      {showChar && <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.[textValue] !== null ? editedDetails?.[textValue]?.length : data?.[textValue]?.length || 0}/<span>{maxLength ? maxLength : '255'}</span> CHARACTERS</p>}
    </div>
  )
}
