import { PROPTECH_COMPANY_DETAILS_API } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { Store } from "../../../store/configureStore";
import { PROPTECH_API } from "../../utility/API-call";
import { startLoading, stopLoading } from "../../utility/loader";
import { handleError, handleSuccess } from "../../utility/snackbar";

export const sendBasicDetailData = (data, companyDetails, countries, currencies, investmentRounds, investmentRanges, setActiveStep, resetForm) => {
  startLoading();
  PROPTECH_API().post(PROPTECH_COMPANY_DETAILS_API, { basicInfo: data, signupProgress: 1 })
    .then((res) => {
      stopLoading();
      Store.dispatch({
        type: Actions.ProptechUser.ProptechSetCompanyDetails,
        payload: {
          ...companyDetails,
          ...data,
          country: countries.find(country => country.id === data.countryId),
          currency: currencies.find(currency => currency.id === data.currencyId),
          investmentRound: investmentRounds.find(round => round.id === data.investmentRoundId),
          investmentRange: investmentRanges.find(range => range.id === data.investmentRangeId),
          signupProgress: companyDetails?.signupProgress > 1 ? companyDetails?.signupProgress : 1
        }
      });
      resetForm({ values: '' })
      handleSuccess(res)
      setActiveStep(1)
    })
    .catch((error) => {
      stopLoading();
      handleError(error);
    })
}
