import { VC_PROPTECH_LISTING } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { Store } from "../../../../store/configureStore";
import { PUBLIC_API } from "../../../../utils/utility/API-call";
import { handleError } from "../../../../utils/utility/snackbar";
import { apiTimeoutTime } from "../../../constants";
import { marketTabVCDTO } from "../../dtos/marketVC";
import { numbersTabVCDTO } from "../../dtos/numbersVC";
import { overviewTabVCDTO } from "../../dtos/overviewVC";
import { proptechDetailsDTO } from "../../dtos/proptechDetailsDTO";
import { raiseTabVCDTO } from "../../dtos/raiseVc";
import { teamTabVCDTO } from "../../dtos/teamTabVC";

export const getOverviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${VC_PROPTECH_LISTING}/overview/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.VCProptechDetails.setOverviewDetails,
        payload: overviewTabVCDTO(res?.data?.data?.proptechCompanyOverviewDetails),
      });
      Store.dispatch({
        type: Actions.VCProptechDetails.setProptechDetails,
        payload: proptechDetailsDTO(res?.data?.data?.proptechCompanyDetails),
      });
      Store.dispatch({
        type: Actions.VCProptechDetails.setApiTimeout,
        payload: { overview: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.VCProptechDetails.setApiTimeout,
          payload: { overview: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      handleError(err);
      setIsLoading(false);
      return err?.status;
    });
};

export const getMarketAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${VC_PROPTECH_LISTING}/market/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.VCProptechDetails.setMarketDetails,
        payload: marketTabVCDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.VCProptechDetails.setApiTimeout,
        payload: { market: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.VCProptechDetails.setApiTimeout,
          payload: { market: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getNumbersAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${VC_PROPTECH_LISTING}/numbers/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.VCProptechDetails.setNumbersDetails,
        payload: numbersTabVCDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.VCProptechDetails.setApiTimeout,
        payload: { numbers: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.VCProptechDetails.setApiTimeout,
          payload: { numbers: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getRaiseAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${VC_PROPTECH_LISTING}/raise/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.VCProptechDetails.setRaiseDetails,
        payload: raiseTabVCDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.VCProptechDetails.setApiTimeout,
        payload: { raise: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.VCProptechDetails.setApiTimeout,
          payload: { raise: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getTeamAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${VC_PROPTECH_LISTING}/team/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.VCProptechDetails.setTeamsDetails,
        payload: teamTabVCDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.VCProptechDetails.setApiTimeout,
        payload: { team: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.VCProptechDetails.setApiTimeout,
          payload: { team: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};
