import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import HomeTab from '../HomeTab';
import HeaderRight from '../../components/HeaderRight';
import NavBar from '../../components/NavBar';
import HeaderNavigation from '../../components/HeaderNavigation';
import WelcomeModal from '../../components/WelcomeModal';
import Loader from '../../../components/Loading';
import Logo from '../../../assets/Icons/connect-logo.svg';
import MeetingsTab from '../MeetingsTab';
import ForYouTab from '../ForYouTab';
import { getForyou, getProptechs, getWatchlist, getFeaturedlist } from '../../utils/middleware/Dashboard';
import { Actions } from '../../../store/actions';
import { Store } from '../../../store/configureStore';
import WatchlistTab from '../WatchListTab';
import ChangePassword from '../../../components/ChangePassword';
import { VC_API } from '../../../utils/utility/API-call';
import { VC_CHANGE_PASSWORD_API, VC_UPCOMINGMEETING_API } from '../../../constants/APIs';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import Featured from '../../components/Featured';
import { setBadgeVisibility } from '../../utils/middleware/Dashboard/notifications';
import { meetingDTO } from '../../utils/dtos/meetingDTO';
import { dispatch } from '../../../utils/utility/store';
import './dashboard.scss';

// vc dashboard
export default function Dashboard(props) {
  // State
  const [showNavigation, setShowNavigation] = useState(true);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  // Store
  const { vcFeaturedList, vcProptechs, vcWatchList, vcWatchListPage, vcWatchListPageSize, showModal, filter, revenueRangeId, orderBy, order, page, size, activeTabId, isLoading } = useSelector(state => state.vcProptechs);
  const { vcForYou, forYouPage, forYouPageSize } = useSelector(state => state.vcForYou);

  // Handle vertical tab panel
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>{children}</div>
        )}
      </div>
    );
  }

  function mobilemenu() {
    document.body.classList.toggle('mobile-menu-open');
  }

  const handleGetProptechs = async () => {
    setShowNavigation(true)
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
    try {
      await getProptechs(filter, orderBy, order, revenueRangeId, page + 1, size);
      document.getElementById("proptechList").scrollTo(0, 0)
      Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    } catch {
      console.log('error')
      Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
    }
  }

  // set Active Tab Data 
  useEffect(() => {
    Store.dispatch({ type: Actions.VCProptechDetails.ProptechDetailsReset });
    setActiveTabData(activeTabId)
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTab = (v) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetActiveTab, payload: v });
    setActiveTabData(v)
  }

  // To get all upcoming meeting
  const getUpcomingEvents = () => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
    VC_API().get(VC_UPCOMINGMEETING_API)
      .then((res) => {
        const meetings = meetingDTO(res.data.data.rows);
        if (meetings?.length) {
          const weeks = [], upcomings = [];
          const endWeekDate = moment().endOf('week').format();
          meetings.forEach((meet) => {
            if (moment(meet.startTime).isBetween(moment(), moment(endWeekDate))) {
              weeks.push(meet);
            } else {
              upcomings.push(meet);
            }
          });
          dispatch(Actions.VCUser.SetUpcomingMeetings, { weekMeetings: weeks, upcomingMeetings: [...weeks, ...upcomings] })
        }
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
      }).catch((err) => {
        handleError(err);
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
      })
  }

  // Get proptech list on for you and watchlish page+
  useEffect(() => {
    if (vcForYou && activeTabId === 1) {
      getForyou(filter, orderBy, order, revenueRangeId, forYouPage + 1, forYouPageSize)
    } else if (activeTabId === 2) {
      getWatchlist(vcWatchListPage + 1, vcWatchListPageSize)
    }
  }, [forYouPage, forYouPageSize, vcWatchListPage, vcWatchListPageSize]) // eslint-disable-line react-hooks/exhaustive-deps


  // Get proptech list on home page
  useEffect(() => {
    handleGetProptechs()
  }, [page, size, activeTabId]) // eslint-disable-line react-hooks/exhaustive-deps

  // Handle tab changing
  function setActiveTabData(tab) {
    Store.dispatch({ type: Actions.VCProptechs.VCResetList });
    switch (tab) {
      case 0:
        Store.dispatch({ type: Actions.VCUser.SetActiveMainTab, payload: tab + 1 });
        getFeaturedlist();
        setBadgeVisibility();
        handleGetProptechs()
        break;

      case 1:
        Store.dispatch({ type: Actions.VCUser.SetActiveMainTab, payload: tab + 1 });
        getForyou(filter, orderBy, order, revenueRangeId, forYouPage + 1, forYouPageSize);
        setShowNavigation(true);
        break;

      case 2: 
        getWatchlist(vcWatchListPage + 1, vcWatchListPageSize)
        setShowNavigation(false)
        break;

      case 3:
        getUpcomingEvents();
        setShowNavigation(false)
        break;

      default: handleGetProptechs()
        break;
    }
  }

  const changePassword = (payload, resetForm) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
    VC_API().put(VC_CHANGE_PASSWORD_API, payload)
      .then(res => {
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
        resetForm({ values: '' });
        handleSuccess(res);
        setChangePasswordModal(false)
      })
      .catch((error) => {
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
        handleError(error);
      })
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="h-screen w-screen flex dashboard-wrapper">
        {/* Left vertical navbar */}
        <div className="left-col h-full relative flex flex-col justify-between">
          <div className="left-wrapper bg-[#111D43]">
            <div className="left-inner h-full">
              <div className="logo-left">
                <img src={Logo} alt="Connect by the PTC" className='cursor-pointer' onClick={() => window.location.reload(false)} />
              </div>
              <NavBar value={activeTabId} setValue={(v) => handleChangeTab(v)} />
            </div>
          </div>
        </div>
        <div className="grow bg-[#E5E5E5] right-col sm:overflow-hidden">
          <div className="px-4 lg:px-6 xl:px-12 ">
            <div className="hamburger-menu lg:hidden" onClick={mobilemenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            {/* Top navigation block */}
            <div className="navigation-top dashboard-navigation flex flex-col">
              <div className='right-nav'>
                <HeaderRight setChangePasswordModal={setChangePasswordModal} />
              </div>

            </div>
          </div>
          <div id="proptechList" className="px-4 lg:px-6 xl:px-12 sm:h-[calc(100%-70px)] sm:overflow-y-auto overflow-x-hidden">
            {/* Featured proptech listing */}
            {activeTabId === 0 && vcFeaturedList !== null && vcFeaturedList?.rows?.length ? <div className='w-full'>
              <Featured />
            </div> : null}
            {activeTabId !== 3 ?
              <div className='w-full flex justify-between bg-[#E5E5E5] navigation-outer-header'>
                <div className='py-[15px] font-semibold opacity-40 proptech-count-wrapper'>
                  {activeTabId !== 3 ?
                    <span className="pb-1 proptech-count">
                      {activeTabId === 0 && vcProptechs?.count ? vcProptechs?.count :
                        activeTabId === 1 && vcForYou?.count ? vcForYou?.count :
                          activeTabId === 2 && vcWatchList?.count ? vcWatchList?.count :
                            0} Proptechs
                    </span>
                    : <span className="pb-1 proptech-count"></span>}
                </div>
                {showNavigation &&
                  <HeaderNavigation tab={activeTabId} />
                }
              </div>
              : null
            }
            <div className='vc-dashboard-right-wrapper sm:pt-48'>
              <TabPanel value={activeTabId} index={0}>
                <HomeTab isLoading={isLoading} tab={activeTabId} />
              </TabPanel>
              <TabPanel value={activeTabId} index={1}>
                <ForYouTab isLoading={isLoading} tab={activeTabId} />
              </TabPanel>
              <TabPanel value={activeTabId} index={2}>
                <WatchlistTab tab={activeTabId} />
              </TabPanel>
              <TabPanel value={activeTabId} index={3}>
                <MeetingsTab tab={activeTabId} getUpcomingEvents={getUpcomingEvents} />
              </TabPanel>
            </div>
          </div>
          {showModal ? <WelcomeModal /> : null}
          {/* Change pasword modal */}
          {changePasswordModal &&
            <ChangePassword
              open={changePasswordModal}
              handleClose={() => setChangePasswordModal(false)}
              handleSuccess={changePassword}
              cancelButtonLabel="Cancel"
              cancelButtonStyle="mr-4 fill-gray btn"
              changeButtonLabel="Change"
              changeButtonStyle='fill-orange btn small-size' />}
        </div>
      </div>
    </>
  )
}
