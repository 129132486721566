export function companyTabDTO(data) {
  return {
    id: data?.id || 0,
    calendlyURL: data?.calendlyURL || null,
    companyLogo: data?.companyLogo || null,
    countryId: data?.countryId || null,
    founderPitchVideo: data?.founderPitchVideo || null,
    keyCustomer1: data?.keyCustomer1 || null,
    keyCustomer1Logo: data?.keyCustomer1Logo || null,
    keyCustomer2: data?.keyCustomer2 || null,
    keyCustomer2Logo: data?.keyCustomer2Logo || null,
    keyCustomer3: data?.keyCustomer3 || null,
    keyCustomer3Logo: data?.keyCustomer3Logo || null,
    languageId: data?.languageId || null,
    megaTrendId: data?.megaTrendId || null,
    revenueRangeId: data?.revenueRangeId || null,
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || '',
    },
    language: {
      id: data?.language?.id || 0,
      name: data?.language?.name || '',
    },
    megaTrend: {
      id: data?.megaTrend?.id || 0,
      name: data?.megaTrend?.name || '',
    },
    proptechUser: {
      id: data?.proptechUser?.id || 0,
      name: data?.proptechUser?.name || '',
      profileImage: data?.proptechUser?.profileImage || null,
    },
    revenueRange: {
      id: data?.revenueRange?.id || 0,
      name: data?.revenueRange?.name || '',
    },
  }
}