import { Navigate } from "react-router-dom";
import { getAdminAccessToken } from "../../../utils/session";
import { Helmet } from "react-helmet";
import AdminLogo from "../../../assets/Icons/admin-green-logo.png"

const PrivateRoute = ({ children }) => {
  const isLogin = getAdminAccessToken();

  return (
    <>
      <Helmet>
        <title>PTC Admin</title>
        <link rel="icon" type="image/x-icon" href={AdminLogo} />
      </Helmet>
      {isLogin ? children : <Navigate to="/admin" />}
    </>
  );
};
export default PrivateRoute;