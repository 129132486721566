import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { Tooltip } from '@mui/material';
import OverviewTab from "../../../components/ProptechDetails/OverviewTab";
import MarketTab from "../../../components/ProptechDetails/MarketTab";
import RaiseTab from "../../../components/ProptechDetails/RaiseTab";
import NumbersTab from "../../../components/ProptechDetails/NumberTab";
import TeamTab from "../../../components/ProptechDetails/TeamTab";
import mapIcon from "../../../../assets/Icons/ic-map.svg";
import paymentIcon from "../../../../assets/Icons/ic-payment.svg";
import trendIcon from "../../../../assets/Icons/ic-trend.svg";
import {
  getOverviewAPI,
  getMarketAPI,
  getNumbersAPI,
  getRaiseAPI,
  getTeamAPI,
} from "../../../utils/middleware/PublicProptechDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CompanyLogo from "../../../../place/components/CompanyLogo";
import Loader from "../../../../components/Loading";
import { Actions } from "../../../../store/actions";
import { dispatch, dispatchNoPayload } from "../../../../utils/utility/store";
import { TooltipText } from "../../../../constants/General";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full"
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="h-full">
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PublicVCProptechDetails() {
  const [value, setValue] = useState(0);
  const [verified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { proptechDetails } = useSelector((state) => state.vcProptechDetails);
  const vcProptechDetails = useSelector((state) => state.vcProptechDetails);
  const publicProptechUser = useSelector((state) => state.publicProptechUser);

  useEffect(() => {
    if (publicProptechUser?.isVerified) {
      setIsVerified(true);
      setActiveTabData(0, publicProptechUser?.id, vcProptechDetails);
    } else if (location?.state?.isVerified) {
      let { isVerified, id } = location?.state;
      dispatch(Actions.PublicProptechUser.SetIsVerified, isVerified);
      dispatch(Actions.PublicProptechUser.SetId, id);
      setIsVerified(true);
      setActiveTabData(0, id, vcProptechDetails);
    } else {
      navigate(`/${location.pathname.split('/')[1]}/${params.id}`, {replace: true});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setActiveTabData(tab, id, vcProptechDetails) {
    switch (tab) {
      case 0:
        !vcProptechDetails?.overviewDetails && getOverviewAPI(id, setIsLoading);
        break;

      case 1:
        !vcProptechDetails?.marketDetails && getMarketAPI(id, setIsLoading);
        break;

      case 2:
        !vcProptechDetails?.numbersDetails && getNumbersAPI(id, setIsLoading);
        break;

      case 3:
        !vcProptechDetails?.raiseDetails && getRaiseAPI(id, setIsLoading);
        break;

      case 4:
        !vcProptechDetails?.teamDetails && getTeamAPI(id, setIsLoading);
        break;

      default:
        !vcProptechDetails?.overviewDetails && getOverviewAPI(id, setIsLoading);
        break;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTabData(newValue, params.id, vcProptechDetails);
  };

  useEffect(
    () => () =>
      dispatchNoPayload(Actions.VCProptechDetails.ProptechDetailsReset),
    []
  );
  return (
    <>
      {isLoading && <Loader />}

      {verified === true && (
        <div className="w-full h-full flex flex-col proptech-detail-wrapper overflow-y-auto md:overflow-inherit">
          <div className="top-menu bg-[#F7F7F7]">
            <div className="left_col">
              <div className="nav-top flex">
                <CompanyLogo
                  companyLogo={proptechDetails?.companyLogo}
                  name={proptechDetails?.proptechCompany?.name}
                />
                <div className="card-info">
                  <h2>{proptechDetails?.proptechCompany?.name || "No Name"}</h2>
                  <ul className="listing-item"></ul>
                  <ul className="listing-item badge-item">
                    <Tooltip title={TooltipText.companyRegion} arrow>
                      <li>
                        <img src={mapIcon} alt="Icon" />
                        {proptechDetails?.region?.name || "-"}
                      </li>
                    </Tooltip>
                    <Tooltip title={TooltipText.revenueRange} arrow>
                      <li>
                        <img src={trendIcon} alt="Icon" />
                        {proptechDetails?.revenueRange?.name || "-"}
                      </li>
                    </Tooltip>

                    <Tooltip title={TooltipText.investmentRound} arrow>
                      <li>
                        <img src={paymentIcon} alt="Icon" />
                        {proptechDetails?.investmentRound?.name || "-"}
                      </li>
                    </Tooltip>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="protech-detail-inner ">
            <div className="tab-wrapper">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box className="tab-heading-wrapper md:sticky lg:top-0  z-10">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Market" {...a11yProps(1)} />
                    <Tab label="Numbers" {...a11yProps(2)} />
                    <Tab label="Raise" {...a11yProps(3)} />
                    <Tab label="Team" {...a11yProps(4)} />
                  </Tabs>
                </Box>
                <div className="relative md:flex md:items-start">
                  <div className="tab-panels !max-w-full mb-2">
                    <TabPanel value={value} index={0}>
                      <OverviewTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <MarketTab />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <NumbersTab />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <RaiseTab />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <TeamTab />
                    </TabPanel>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
