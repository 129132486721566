import { ADMIN_VENTURE_SCOUTS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { getCountriesData } from "../dropdowns"
import { ventureScoutProfileTabDTO } from "../dtos/adminVentureScout"
import { getVentureScouts } from "./adminVentureScout"

export const loadVentureScoutProfileTabData = (id, adminVentureScout, adminDropdowns) => {
  getVentureScoutProfileDetails(id)
  if (!adminDropdowns.countries.length) {
    getCountriesData();
  }
}

export const getVentureScoutProfileDetails = (id) => {
  dispatch(Actions.VentureScouts.SetLoading, true)
  ADMIN_API().get(`${ADMIN_VENTURE_SCOUTS}/${id}`)
    .then(res => {
      dispatch(Actions.VentureScouts.SetLoading, false)
      dispatch(Actions.VentureScouts.SetProfileTabData, ventureScoutProfileTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.VentureScouts.SetLoading, false)
      handleError(err)
    })
}

export const updateVentureScoutProfileDetails = (id, data, activeSort, sortOrder, search) => {
  dispatch(Actions.VentureScouts.SetLoading, true)
  ADMIN_API().put(`${ADMIN_VENTURE_SCOUTS}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      getVentureScoutProfileDetails(id)
      getVentureScouts(activeSort, sortOrder, search, false);
    }).catch(err => {
      dispatch(Actions.VentureScouts.SetLoading, false)
      handleError(err);
    })
}