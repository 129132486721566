import { ADMIN_PROPTECHS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { dispatch } from "../../../utils/utility/store";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import {
  getIntegrationsData,
  getProjectRisksData,
  getTechAlignmentData,
} from "../dropdowns";
import { deployAndUseDTO } from "../dtos/deployAndUseTab";

export const loadDeployAndUseTabData = (
  id,
  adminAnalysisTab,
  adminDropdowns,
  radarDetails
) => {
  if (!adminAnalysisTab.deployAndUseTabData) {
    getDeployAndUseDetails(id);
  }
  if (!radarDetails?.deployAndUseRadar) {
    getDeployAndUseRadarDetails()
  }
  loadDeployAndUseTabDropdowns(
    adminDropdowns.projectRisks,
    adminDropdowns.integrations,
    adminDropdowns.techAlignment
  );
};

export const getDeployAndUseDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_PROPTECHS + `/${id}/deployUse`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(Actions.AdminAnalysisTab.SetDeployAndUseTabData, deployAndUseDTO(res.data.data));
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
    });
};

export const updateDeployAndUseDetails = (id, data, radars) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  if(radars?.length) {
    data['placeRadarPoints'] = radars?.map(i => ({radarId: i.id, value: i.value}))
  }
  return ADMIN_API()
    .put(ADMIN_PROPTECHS + `/${id}/deployUse`, data)
    .then((res) => {
      handleSuccess(res);
      getDeployAndUseDetails(id);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

//deploy and use tab radar
export const getDeployAndUseRadarDetails = () => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/radar/deploy_use`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetRadarDetails, { deployAndUseRadar: res.data.data })
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const loadDeployAndUseTabDropdowns = (
  projectRisks,
  integrations,
  techAlignment
) => {
  if (!projectRisks.length) {
    getProjectRisksData();
  }
  if (!integrations.length) {
    getIntegrationsData();
  }
  if (!techAlignment.length) {
    getTechAlignmentData();
  }
};
