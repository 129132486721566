export default function adminMarketInsightReports(
    initialState = {
      isLoading: false,
      marketInsightReports: [],
      selectedMarketInsightReport: null,
      isMarketInsightSelected: false,
      activeSort: 'createdAt',
      sortOrder: 'DESC',
      isArchived: false,
      search: '',
      preview: false,
      searchProptech: '',
      activeMarketInsightTab: 0,
      marketInsightsTabDetails: null,
      proptechsForReports: [],
      selectedProptechsForReport: []
    },
    action
  ) {
    switch (action.type) {
      case 'MARKET_INSIGHT_REPORTS_SET_LOADING':
        return {
          ...initialState,
          isLoading: action.payload,
        };

      case 'MARKET_INSIGHT_REPORTS_SET':
        return {
          ...initialState,
          marketInsightReports: action.payload,
        };
  
      case 'SELECTED_MARKET_INSIGHT_REPORT_SET':
        return {
          ...initialState,
          selectedMarketInsightReport: action.payload,
        };
  
      case 'SET_IS_SELECTED_MARKET_INSIGHT_REPORT':
        return {
          ...initialState,
          isMarketInsightSelected: action.payload,
        };
  
      case 'SET_ACTIVE_SORT':
        return {
          ...initialState,
          activeSort: action.payload,
        };
  
      case 'SET_SORT_ORDER':
        return {
          ...initialState,
          sortOrder: action.payload,
        };
  
      case 'SET_IS_ARCHIVED':
        return {
          ...initialState,
          isArchived: action.payload,
        };
  
      case 'SET_SEARCH':
        return {
          ...initialState,
          search: action.payload,
        };
  
      case 'SET_PREVIEW':
        return {
          ...initialState,
          preview: action.payload,
        };

      case 'SET_ADMIN_PROPTECHS_FOR_REPORTS':
          return {
            ...initialState,
            proptechsForReports: action.payload,
          };
    
      case 'SET_SELECTED_ADMIN_PROPTECHS_FOR_REPORT':
          return {
            ...initialState,
            selectedProptechsForReport: action.payload,
          };
  
      case 'SET_MARKET_INSIGHTS_TAB_ACTIVE_TAB_INDEX':
        return {
          ...initialState,
          activeMarketInsightTab: action.payload,
        };
  
      case 'SET_MARKET_INSIGHTS_DETAILS':
        return {
          ...initialState,
          marketInsightsTabDetails: action.payload,
        };
  
      case 'ADMIN_REPORTS_RESET':
        return {
          ...initialState,
          isLoading: false,
          marketInsightReports: [],
          selectedMarketInsightReport: null,
          isMarketInsightSelected: false,
          activeSort: 'createdAt',
          sortOrder: 'DESC',
          isArchived: false,
          search: '',
          preview: false,
          activeMarketInsightTab: 0,
          marketInsightsTabDetails: null,
          proptechsForReports: [],
          selectedProptechsForReport: []
        };
  
      default:
        return initialState;
    }
  }
  