import React, { useState } from 'react';
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PTInput from "../../../../components/GenericComponents/PTInput";
import ArrowUpIcon from '../../../../assets/Icons/admin-arrow-up.svg';
import ArrowDownIcon from '../../../../assets/Icons/admin-arrow-down.svg';
import { Actions } from "../../../../store/actions";
import { dispatch } from "../../../../utils/utility/store";
import NewsfeedCard from '../NewsfeedCard';

export default function NewsfeedListCard(props) {
  const { unsaveChange } = props

  // State
  const [activeSearchbar, setActiveSearchbar] = useState(false)
  const [searchText, setSearchText] = useState('')

  // Store
  const { newsfeeds, activeSort, sortOrder, isActive } = useSelector(state => state.newsfeeds)

  const handleSort = (sort) => {
    dispatch(Actions.Newsfeeds.SetActiveSort, sort)
    dispatch(Actions.Newsfeeds.SetSortOrder, sort === activeSort ? sortOrder === 'ASC' ? 'DESC' : 'ASC' : 'ASC')
  }

  const handleArchived = () => {
    dispatch(Actions.Newsfeeds.SetIsActive, !isActive)
  }

  const handleSearch = (e) => {
    let text = e.target.value ? e.target.value.trim() : ''
    setSearchText(e.target.value)
    if (e.key === 'Enter') {
      setSearchText(text)
      dispatch(Actions.Newsfeeds.SetSearch, text)
      dispatch(Actions.Newsfeeds.SetIsSelectedNewsfeed, false)
      dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, null)
    }
  }

  const handleCloseSearch = () => {
    dispatch(Actions.Newsfeeds.SetSearch, '')
    setActiveSearchbar(false)
    setSearchText('')
  }

  return (
    <>
      <div className='w-full h-[80px] flex items-center justify-between box-border pl-[15px] pr-[15px] xlg:pl-[30px] xlg:pr-[24px] bg-[#E7E8EC]'>
        {activeSearchbar ?
          <PTInput label="Search" fullWidth value={searchText} onKeyPress={handleSearch} handleOnChangeEvent={handleSearch} variant="standard" placeholder='' icon={<CloseIcon className='cursor-pointer' onClick={handleCloseSearch} />} /> :
          <>
            <div className='search-icon-wrap h-[30px] w-[30px] rounded-full bg-[#fff] cursor-pointer inline-flex items-center justify-center' onClick={() => setActiveSearchbar(true)}>
              <SearchIcon className='cursor-pointer text-[#70778E] w-[20px] h-[20px]' />
            </div>
            <div className='flex font-semibold text-[12px] whitespace-nowrap not-italic uppercase'>
              <div className={`flex cursor-pointer opacity-${isActive ? '100' : '40'}`} style={{ marginRight: '10px' }} onClick={handleArchived}>
                <input type="checkbox" checked={isActive} className='mr-[6px] bg-[#E7E8EC] accent-[#111D43]' />
                <span>active</span>
              </div>
              <div className={`flex cursor-pointer opacity-${activeSort === 'title' ? '100' : '40'}`} style={{ marginRight: '10px' }} onClick={() => handleSort('title')}>
                <img src={activeSort === 'title' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>A-Z</span>
              </div>
              <div className={`flex cursor-pointer opacity-${activeSort === 'createdAt' ? '100' : '40'}`} style={{ marginRight: '' }} onClick={() => handleSort('createdAt')}>
                <img src={activeSort === 'createdAt' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>Date Created</span>
              </div>
            </div>
          </>}
      </div>
      <div className='mr-[-4px] md:mr-[0] h-[168px] md:h-[calc(100%-50px)] xlg:h-[calc(100%-80px)] overflow-y-auto hide-scrollbar'>
        {newsfeeds.length !== 0 && newsfeeds.map(newsfeed => {
          return <NewsfeedCard
            key={newsfeed?.id}
            newsfeed={newsfeed}
            unsaveChange={unsaveChange}
          />
        })}
      </div>
      {/* <ProptechSortMenu anchorEl={anchorEl} open={open} handleClose={handleClose} setSelectesFilter={setSelectesFilter} /> */}
    </>
  )
}
