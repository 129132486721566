import moment from 'moment';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import InfoIcon from '../../../../components/InfoIcon';
import { TooltipText } from '../../../../constants/General';

export default function RaiseOverview() {
  // Store
  const { raiseOverviewDetails } = useSelector(state => state.vcProptechDetails)
  
  const [handleInfo, setHandleInfo] = useState([])

  return (
    <>
      {
        raiseOverviewDetails ?
          <div className="raise-detail-wrapper">
            <div className='flex items-start'>
              <h2 className='pr-4'>Raise overview</h2>
              <InfoIcon
                label={TooltipText.raiseOverview}
                icon
                info={handleInfo}
                handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
              />
            </div>
            {
              raiseOverviewDetails?.isNotRaising ? <div><h3>We are not raising currently</h3></div> : 
              <>
                             {/*Current round block */}
            <div className="raise-item">
              <h2>Current round</h2>
              <ul>
                <li>Round<span>{raiseOverviewDetails?.investmentRound?.name}</span></li>
                <li>Raising range<span>{raiseOverviewDetails?.investmentRange?.name}</span></li>
                <li>Val range<span>{raiseOverviewDetails?.valuationRange?.name}</span></li>
                <li>Instrument<span>{raiseOverviewDetails?.investmentType?.name}</span></li>
                <li>Closing date<span>{raiseOverviewDetails?.raiseDate ? moment(raiseOverviewDetails?.raiseDate, 'YYYY-MM-DD').format('MMM YYYY') : '-'}</span></li>
                <li>Pivotal metric<span>{raiseOverviewDetails?.keyBizModel?.name}</span></li>
              </ul>
            </div>
            {/*Prior round block */}
            <div className="raise-item">
              <h2>Prior round</h2>
              <ul>
                <li>Round<span>{raiseOverviewDetails?.priorInvestmentRound?.name}</span></li>
                <li>Raising range<span>{raiseOverviewDetails?.priorInvestmentRange?.name}</span></li>
                <li>Val range<span>{raiseOverviewDetails?.priorValuationRange?.name}</span></li>
                <li>Instrument<span>{raiseOverviewDetails?.priorInvestmentType?.name}</span></li>
                <li>Closing date<span>{raiseOverviewDetails?.priorRaiseDate ? moment(raiseOverviewDetails?.priorRaiseDate, 'YYYY-MM-DD').format('MMM YYYY') : '-'}</span></li>
                <li>Pivotal metric<span>{raiseOverviewDetails?.priorKeyBizModel?.name}</span></li>
              </ul>
            </div>
              </>
            }
          </div >
          : null
      }
    </>
  )
}
