import * as React from 'react';
import { useSelector } from 'react-redux';
import { PopupModal } from 'react-calendly';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import PTButton from '../../../components/GenericComponents/PTButton';
import PTInput from '../../../components/GenericComponents/PTInput';
import closeIcon from '../../../assets/Icons/ic-cross.svg';
import PTCheckbox from '../../../components/GenericComponents/PTCheckbox';
import MeetingCardUpcoming from '../../components/MeetingCardUpcoming';
import MeetingCardPrevious from '../../components/MeetingCardPrevious';
import { VC_API } from '../../../utils/utility/API-call';
import { VC_MEETINGSBASE_API, VC_PREVIOUSMEETING_API } from '../../../constants/APIs';
import { meetingDTO } from '../../utils/dtos/meetingDTO';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import Loader from '../../../components/Loading';
import { Actions } from '../../../store/actions';
import { dispatch } from '../../../utils/utility/store';
import { FEEDBACK_TYPE } from '../../constants';
import './style.scss';

function TabPanel(props) {
  // Props
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MeetingsTab({ getUpcomingEvents }) {

  // Modal
  const [openModal, setOpenModal] = React.useState(false);
  const [openCancelModal, setOpenCanceModal] = React.useState(false);
  const [openThankYouModal, setOpenThankyouModal] = React.useState(false);
  const [cancelReason, setCancelReason] = React.useState(null);
  const [openCalendly, setOpenCalendly] = React.useState(false);
  const [meeting, setMeeting] = React.useState(false);
  const { isLoading, meetingTabId } = useSelector((state) => state.vcProptechs);
  const handleCloseModal = () => setOpenModal(false);
  const openMeetingCancelModal = () => setOpenCanceModal(true);
  const handleMeetingCancelCloseModal = () => setOpenCanceModal(false);
  const openThankyouModal = () => setOpenThankyouModal(true);
  const handleThankyouCloseModal = () => setOpenThankyouModal(false);

  // Store
  const previousMeetings = useSelector(state => state.vcUser.previousMeetings)
  const { weekMeetings, upcomingMeetings } = useSelector(state => state.vcUser.upcomingMeetings)

  const setLoading = (isLoading) => {
    dispatch(Actions.VCProptechs.VCSetLoading, isLoading);
  }

  const setMeetingActiveTab = (tabIndex) => {
    dispatch(Actions.VCProptechs.VCSetMeetingTabId, tabIndex);
  }

  // To get all previous meeting  
  const getPreviousEvents = () => {
    setLoading(true);
    VC_API().get(VC_PREVIOUSMEETING_API)
      .then((res) => {
        dispatch(Actions.VCUser.SetPreviousMeetings, meetingDTO(res.data.data.rows))
        setLoading(false);
      }).catch((err) => {
        handleError(err);
        setLoading(false);
      })
  }

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      getUpcomingEvents();
    } else if (newValue === 1) {
      getPreviousEvents();
    }
    setMeetingActiveTab(newValue);
  };

  const handleMeetingSchedule = (option, event) => {
    setMeeting(event);
    if (option === "Reschedule") {
      setOpenCalendly(true);
    } else if (option === "Cancel") {
      openMeetingCancelModal();
    }
  }

  const handleMeetingCancel = () => {
    setLoading(true);
    VC_API()
      .put(`${VC_MEETINGSBASE_API}/${meeting.id}/cancel`, cancelReason ? { cancelReason } : {})
      .then((res) => {
        handleSuccess(res);
        setMeeting({});
        handleMeetingCancelCloseModal();
        setLoading(false);
        getUpcomingEvents();
      })
      .catch((err) => {
        handleError(err);
        setLoading(false);
      });
  };

  const handleRescheduleMeeting = () => {
    setLoading(true);
    VC_API()
      .put(`${VC_MEETINGSBASE_API}/${meeting.id}/reschedule`)
      .then((res) => {
        handleSuccess(res);
        setMeeting({});
        setLoading(false);
        getUpcomingEvents();
      })
      .catch((err) => {
        handleError(err);
        setLoading(false);
      });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const formik = useFormik({
    initialValues: {
      feedbackType: '',
      message: '',
      feedbackTag: []
    },
    onSubmit: (values, { resetForm }) => {
      const feedback = {
        ...values,
        feedbackTag: values.feedbackTag.join(","),
      }
      VC_API().put(`${VC_MEETINGSBASE_API}/${meeting.id}/feedback`, feedback)
        .then((res) => {
          handleSuccess(res);
          setMeeting({});
          getPreviousEvents();
          openThankyouModal();
          handleCloseModal();
        }).catch((err) => {
          handleError(err);
        })
    }
  });

  const handleOpenModal = (fdType, meetingData) => {
    setMeeting(meetingData);
    formik.setFieldValue("feedbackType", fdType);
    setOpenModal(true);
  };

  const handleFeedbackTag = (tag, value) => {
    const tags = formik.values.feedbackTag;
    if (value) {
      tags.push(tag);
      formik.setFieldValue("feedbackTag", tags);
    } else {
      formik.setFieldValue("feedbackTag", tags.filter(t => t !== tag));
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      {openCalendly && <PopupModal
        url={meeting.rescheduleLink}
        rootElement={document.getElementById("event-container")}
        open={openCalendly}
        onModalClose={() => {
          handleRescheduleMeeting();
          setOpenCalendly(false);
        }}
      />}
      <div className="meeting-tab-wrapper" id="event-container">
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={meetingTabId} onChange={handleChange}>
              <Tab label="Upcoming" {...a11yProps(0)} />
              <Tab label="Previous" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={meetingTabId} index={0}>
            <div className="upcoming-tab event-wrapper">
              {weekMeetings && (
                <div className="event-outer">
                  <p className="title-txt">
                    This week <span className="counter-badge">{weekMeetings.length}</span><span className='text-[9px] ml-2'>(All times is in UTC Timezone)</span>
                  </p>
                  {weekMeetings?.length > 0 ? (
                    weekMeetings.map((event) => {
                      return <MeetingCardUpcoming handleMeetingSchedule={handleMeetingSchedule} event={event} />;
                    })
                  ) : (
                    <p className="title-txt">You have no meetings this week</p>
                  )}
                </div>
              )}
              {upcomingMeetings && (
                <div className="event-outer">
                  <p className="title-txt">
                    All upcoming <span className="counter-badge">{upcomingMeetings.length}</span><span className='text-[9px] ml-2'>(All times is in UTC Timezone)</span>
                  </p>
                  {upcomingMeetings.length > 0 ? (
                    upcomingMeetings.map((event) => {
                      return <MeetingCardUpcoming handleMeetingSchedule={handleMeetingSchedule} event={event} />;
                    })
                  ) : (
                    <p className="title-txt">You have no upcoming meetings</p>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={meetingTabId} index={1}>
            {previousMeetings && (
              <div className="upcoming-tab event-wrapper">
                <div className="event-outer">
                  <p className="title-txt">
                    All previous <span className="counter-badge">{previousMeetings.length}</span><span className='text-[9px] ml-2'>(All times is in UTC Timezone)</span>
                  </p>
                  {previousMeetings.length > 0 ? (
                    previousMeetings.map((event) => {
                      return (
                        <MeetingCardPrevious
                          event={event}
                          openModal={handleOpenModal}
                          attended={event.status === 1}
                          feedbackDone={[FEEDBACK_TYPE.positive, FEEDBACK_TYPE.negative].includes(
                            event.feedbackType
                          )}
                        />
                      );
                    })
                  ) : (
                    <p className="title-txt">You have no previous meetings</p>
                  )}
                </div>
              </div>
            )}
          </TabPanel>
          {/* <span style={{ padding: "15px", cursor: "pointer", background: '#111D43', color: "#fff", margin: "15px", display: "inline-block" }} onClick={openMeetingCancelModal}>Cancel meeting</span>
          <span style={{ padding: "15px", cursor: "pointer", background: '#111D43', color: "#fff", margin: "15px", display: "inline-block" }} onClick={openThankyouModal}>Thank you</span> */}
        </Box>
      </div>
      {/* Feedback modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="meeting-modal modal"
      >
        <>
          <Box sx={style} >
            <IconButton className="close-btn" onClick={handleCloseModal}>
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>How was your meeting?</h2>
              </div>
              <form>
                <div className="form-wrapper">
                  <div className="review-meeting">
                    <IconButton
                      className={`like-btn review-btn ${formik.values.feedbackType === FEEDBACK_TYPE.positive ? 'submit-review' : ''}`}
                      onClick={() => {
                        formik.setFieldValue("feedbackType", FEEDBACK_TYPE.positive);
                      }}
                    >
                      <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14H36C38.2091 14 40 15.7909 40 18V23.68C39.997 25.2076 39.7052 26.7208 39.14 28.14L37 33.48C36.3962 34.9961 34.9319 35.9934 33.3 36H20.82C19.0117 35.9987 17.278 35.2793 16 34C14.7058 32.7573 12.974 32.0746 11.18 32.1H10V16.1H11.78C13.162 16.1028 14.4475 15.3919 15.18 14.22L17.62 10.22C18.5212 8.78253 19.8501 7.66348 21.42 7.02L22.28 6.68C23.367 6.24277 24.2077 5.35093 24.58 4.24L25.78 0.68C25.9164 0.276162 26.2938 0.00316567 26.72 0H27C28.6569 0 30 1.34315 30 3V3.78C30.0392 5.16762 29.7298 6.54291 29.1 7.78L26 14ZM5 34C5.55228 34 6 33.5523 6 33V15C6 14.4477 5.55228 14 5 14H1C0.447715 14 0 14.4477 0 15V33C0 33.5523 0.447715 34 1 34H5Z" />
                      </svg>
                    </IconButton>
                    <IconButton
                      className={`dislike-btn review-btn ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? 'submit-review' : ''}`}
                      onClick={() => {
                        formik.setFieldValue("feedbackType", FEEDBACK_TYPE.negative);
                      }}
                    >
                      <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14H36C38.2091 14 40 15.7909 40 18V23.68C39.997 25.2076 39.7052 26.7208 39.14 28.14L37 33.48C36.3962 34.9961 34.9319 35.9934 33.3 36H20.82C19.0117 35.9987 17.278 35.2793 16 34C14.7058 32.7573 12.974 32.0746 11.18 32.1H10V16.1H11.78C13.162 16.1028 14.4475 15.3919 15.18 14.22L17.62 10.22C18.5212 8.78253 19.8501 7.66348 21.42 7.02L22.28 6.68C23.367 6.24277 24.2077 5.35093 24.58 4.24L25.78 0.68C25.9164 0.276162 26.2938 0.00316567 26.72 0H27C28.6569 0 30 1.34315 30 3V3.78C30.0392 5.16762 29.7298 6.54291 29.1 7.78L26 14ZM5 34C5.55228 34 6 33.5523 6 33V15C6 14.4477 5.55228 14 5 14H1C0.447715 14 0 14.4477 0 15V33C0 33.5523 0.447715 34 1 34H5Z" fill="#70778E" />
                      </svg>
                    </IconButton>
                  </div>
                  <ul className="badge-wrapper badge-checkbox-list-wrapper">
                    <li>
                      <PTCheckbox
                        label="Well prepared"
                        className={`form-checkbox ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? "red-check" : ""}`}
                        id="well-prepared"
                        onSelection={(e) => handleFeedbackTag("well-prepared", e.target.value)} />
                    </li>
                    <li>
                      <PTCheckbox
                        label="Professional"
                        className={`form-checkbox ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? "red-check" : ""}`}
                        id="professional"
                        onSelection={(e) => handleFeedbackTag("professional", e.target.value)} />
                    </li>
                    <li>
                      <PTCheckbox
                        label="good-deal"
                        className={`form-checkbox ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? "red-check" : ""}`}
                        id="good-deal"
                        onSelection={(e) => handleFeedbackTag("good-deal", e.target.value)} />
                    </li>
                    <li>
                      <PTCheckbox
                        label="Confident"
                        className={`form-checkbox ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? "red-check" : ""}`}
                        id="confident"
                        onSelection={(e) => handleFeedbackTag("confident", e.target.value)} />
                    </li>
                    {/* TODO: Red Check */}
                    {/* <li><PTCheckbox label="Clear" className="form-checkbox red-check" id="clear" /></li> */}
                    <li>
                      <PTCheckbox
                        label="Clear"
                        className={`form-checkbox ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? "red-check" : ""}`}
                        id="clear"
                        onSelection={(e) => handleFeedbackTag("clear", e.target.value)} />
                    </li>
                    <li>
                      <PTCheckbox
                        label="Well
                        presented"
                        className={`form-checkbox ${formik.values.feedbackType === FEEDBACK_TYPE.negative ? "red-check" : ""}`}
                        id="well-presented"
                        onSelection={(e) => handleFeedbackTag("well-presented", e.target.value)} />
                    </li>
                  </ul>
                  <div className="form-group">
                    <PTInput id="message" name="message" multiline="multiline" placeholder="Add a short message" label="Add a short message" fullWidth className='form-control textarea-input' value={formik.values.message} handleOnChangeEvent={formik.handleChange} />
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrapper">
              <PTButton
                label='Cancel'
                className="fill-gray btn"
                handleOnClickEvent={handleCloseModal} />
              <PTButton
                label='Submit'
                loading={isLoading}
                disabled={isLoading}
                className="fill-orange btn small-size"
                handleOnClickEvent={() => {
                  formik.handleSubmit();
                }}
              />
            </div>
          </Box>
        </>
      </Modal>
      {/* Cancel meeting modal */}
      <Modal
        open={openCancelModal}
        onClose={handleMeetingCancelCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="meeting-cancel-modal modal"
      >
        <>
          <Box sx={style} >
            <IconButton className="close-btn" onClick={handleMeetingCancelCloseModal}>
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>Are you sure?</h2>
                <p>If you cancel this meeting, the Proptech will be informed and it will be removed from both of your calendars.</p>
              </div>
              <form>
                <div className="form-wrapper">
                  <div className="form-group">
                    <PTInput value={cancelReason} handleOnChangeEvent={(e) => setCancelReason(e.target.value)} id="message" name="message" multiline="multiline" placeholder="Let them know why (optional)" label="Let them know why (optional)" fullWidth className='form-control textarea-input' />
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrapper">
              <PTButton
                label='Cancel meeting'
                className="fill-gray btn"
                handleOnClickEvent={handleMeetingCancel}
                loading={isLoading}
                disabled={isLoading} />
              <PTButton label='Back' className="fill-orange btn" handleOnClickEvent={handleMeetingCancelCloseModal} />
            </div>
          </Box>
        </>
      </Modal>
      {/* Thank you modal */}
      <Modal
        open={openThankYouModal}
        onClose={handleThankyouCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="thank-you-modal modal"
      >
        <>
          <Box sx={style} >
            <IconButton className="close-btn" onClick={handleThankyouCloseModal}>
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>Thank you!</h2>
                <p>We appreciate you taking the time to provide feedback on your meetings. It allows the PTC to improve the overall platform experience.</p>
              </div>
            </div>
            <div className="btn-wrapper">
              <PTButton label='Back' className="fill-gray btn" handleOnClickEvent={handleThankyouCloseModal} />
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
}
