import React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from '../../../store/actions';
import { dispatch } from '../../../utils/utility/store';
import Pagination from '../../components/Pagination';
import ProptechCard from '../../components/ProtechCard';

export default function HomeTab({ tab }) {
  // State
  const { page, size, vcProptechs } = useSelector(state => state.vcProptechs);

  // Set page no. in store on page change in pagination
  const handlePageChange = (v) => {
    dispatch(Actions.VCProptechs.VCSetPage, v)
  }
  
  // Set page no. and size in store on page change in pagination
  const handleRowChange = (v) => {
    dispatch(Actions.VCProptechs.VCSetPageSize, v)
    dispatch(Actions.VCProptechs.VCSetPage, 0)
  }

  return (
    <>
      <div className="proptech-card-wrapper">
        {/* Proptech card */}
        {
          vcProptechs?.rows && vcProptechs?.rows.length ? vcProptechs?.rows.map((proptech) => {
            return (<ProptechCard proptech={proptech} key={proptech?.id} tab={tab} />)
          }) : null
        }
      </div>
      {/* Pagination */}
      <div className='flex justify-between items-center'>
        {vcProptechs?.count ? <span className='text-sm font-normal'>All values in USD</span> : null}
        {vcProptechs?.count ?
          <Pagination
            length={vcProptechs?.count}
            tab="Proptechs"
            page={page}
            size={size}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
          : null}
      </div>
    </>
  )
}
