import React, { useEffect, useState } from "react";
import { Delete } from "@material-ui/icons";
import DropdownColumn from "../../../DropdownColumn";
import { useSelector } from "react-redux";
import {
  PROJECT_IMPLEMENTATION_ERROR,
  PROJECT_IMPLEMENTATION_PAYLOAD,
} from "../../../../utils/payloads/payloads";
import { AnalysisLabelStyle } from "../../../../../proptech/constants/General";
import PTInput from "../../../../../components/GenericComponents/PTInput";
import { MenuItem } from "@mui/material";
import { trimQuery } from "../../../../../utils/utility/utility";
import ConfirmModal from "../../../ConfirmModal";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import { isUnsaveChanges } from "../../../../utils/middleware/projectTab";
import { dispatch } from "../../../../../utils/utility/store";
import { Actions } from "../../../../../store/actions";
import { updateProjectImplementationDetails } from "../../../../utils/middleware/projectImplementationTab";
import Loader from "../../../../../components/Loading";
import {
  cloneDeepArray,
  cloneDeepObject,
} from "../../../../utils/dtos/projectTabs";

const Implementation = (props) => {
  const { setUnsaveChange } = props;

  const { keyIssues, keyRisks, integrations } = useSelector(
    (state) => state.adminDropdowns
  );
  const implementationData = useSelector(
    (state) => state.adminProjectTab.implementationData
  );
  const { isLoading } = useSelector((state) => state.adminUser);
  const { selectedTechBuyer } = useSelector((state) => state.adminTechBuyer);

  const [editedDetails, setEditedDetails] = useState(
    PROJECT_IMPLEMENTATION_PAYLOAD
  );
  const [errors, setErrors] = useState(PROJECT_IMPLEMENTATION_ERROR);
  const [errorFields, setErrorFields] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (
      implementationData?.projectSystemIntegration?.length !== 0 &&
      !isLoading
    ) {
      setEditedDetails({
        ...editedDetails,
        systemIntegration: cloneDeepArray(
          implementationData?.projectSystemIntegration
        ) || [{ key: "", value: "" }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implementationData]);

  useEffect(() => {
    isUnsaveChanges(
      editedDetails,
      setUnsaveChange,
      "systemIntegration",
      implementationData?.projectSystemIntegration,
      editedDetails.systemIntegration
    );
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const addIntegrationRow = () => {
    let temp = {
      key: "",
      value: "",
    };
    let tempState = cloneDeepObject(editedDetails);
    tempState?.systemIntegration?.push(temp);
    setEditedDetails(tempState);
  };

  const deleteSystemIntegrationRow = (index) => {
    const filteredStops = editedDetails.systemIntegration.filter(
      (item, idx) => index !== idx
    );

    let tempState = cloneDeepObject(editedDetails);
    tempState.systemIntegration = filteredStops;
    setEditedDetails(tempState);
  };

  const handleInputChange = (value, index, name) => {
    let tempState = cloneDeepObject(editedDetails);
    tempState.systemIntegration[index][name] = value;
    setEditedDetails(tempState);
  };

  const checkValidation = () => {
    let fields = editedDetails.systemIntegration.map((field, index) => {
      let errorFields = {};
      Object.entries(field).forEach(([key, val], idx) => {
        if (!val) {
          errorFields[key] = `${key} ${index + 1} is required`;
        }
      });
      return Object.values(errorFields).length && errorFields;
    });
    setErrorFields(fields);
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !implementationData?.[error],
      };
    });
    setErrors(data);
    if (fields.filter(Boolean).length !== 0) {
      return true;
    }
    return Object.keys(data).some((k) => data[k]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = implementationData?.[key]));

    handleCloseModal();
    try {
      let res = await updateProjectImplementationDetails(
        selectedTechBuyer?.id,
        {
          ...Object.fromEntries(
            Object.entries(editedDetails).filter(([_, v]) => !!v)
          ),
          ...Object.fromEntries(
            Object.entries(newData).filter(([_, v]) => !!v)
          ),
        }
      );
      if (res === 200) setEditedDetails(PROJECT_IMPLEMENTATION_PAYLOAD);
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="mt-4 mb-[22px]">
        <div>
          <div className="analysis-dropdown-wrapper horizontal">
            <div className="dropdown-title flex items-center w-[90%] justify-between text-sm not-italic text-[#111D43]">
              <p>System Integration</p>
              <p
                className="underline hover:opacity-70 cursor-pointer"
                onClick={addIntegrationRow}
              >
                Add more
              </p>
            </div>
            {editedDetails?.systemIntegration?.map((item, idx) => {
              return (
                <div className="dropdown-items flex w-[90%]" key={idx}>
                  <div className="dropdown-inner-item">
                    <PTInput
                      labelStyle={AnalysisLabelStyle}
                      className="custom-dropdown-icon"
                      variant="standard"
                      label={`System integration ${idx + 1}`}
                      InputPropsStyle={{ fontSize: "12px" }}
                      fullWidth
                      select
                      selectChildren={integrations.map((option) => (
                        <MenuItem value={option.id}>{option.name}</MenuItem>
                      ))}
                      placeholder=""
                      value={item.key}
                      handleOnChangeEvent={(e) =>
                        handleInputChange(e.target.value, idx, "key")
                      }
                      error={errorFields[idx]?.key}
                      helperText={errorFields[idx]?.key}
                    />
                  </div>
                  <div className="dropdown-inner2-item">
                    <PTInput
                      labelStyle={AnalysisLabelStyle}
                      variant="standard"
                      label={`System integration value ${idx + 1}`}
                      placeholder=""
                      multiline
                      maxRows={4}
                      maxLength={255}
                      value={item.value}
                      handleOnChangeEvent={(e) => {
                        handleInputChange(e.target.value, idx, "value");
                      }}
                      error={errorFields[idx]?.value}
                      helperText={errorFields[idx]?.value}
                    />
                  </div>
                  {idx !== 0 && (
                    <Delete
                      className="cursor-pointer"
                      onClick={() => deleteSystemIntegrationRow(idx)}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="mb-[22px] flex analysis-dropdown-wrapper">
            <DropdownColumn
              header="Key Issues"
              dropdown1Label="Key Issues 1"
              dropdown1Options={keyIssues}
              dropdown1Value={
                editedDetails?.keyIssue1 || implementationData?.keyIssue1
              }
              dropdown1HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  keyIssue1: e.target.value,
                })
              }
              dropdown1Error={errors.keyIssue1}
              dropdown1HelperText={errors.keyIssue1 && "Key issue 1 Required."}
              dropdown2Label="Key Issues 2"
              dropdown2Options={keyIssues}
              dropdown2Value={
                editedDetails?.keyIssue2 || implementationData?.keyIssue2
              }
              dropdown2HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  keyIssue2: e.target.value,
                })
              }
              dropdown2Error={errors.keyIssue2}
              dropdown2HelperText={errors.keyIssue2 && "Key issue 2 Required."}
              dropdown3Label="Key Issues 3"
              dropdown3Options={keyIssues}
              dropdown3Value={
                editedDetails?.keyIssue3 || implementationData?.keyIssue3
              }
              dropdown3HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  keyIssue3: e.target.value,
                })
              }
              dropdown3Error={errors.keyIssue3}
              dropdown3HelperText={errors.keyIssue3 && "Key issue 3 Required."}
            />
            <DropdownColumn
              header="Risks"
              dropdown1Label="Risks 1"
              dropdown1Options={keyRisks}
              dropdown1Value={editedDetails?.risk1 || implementationData?.risk1}
              dropdown1HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  risk1: e.target.value,
                })
              }
              dropdown1Error={errors.risk1}
              dropdown1HelperText={errors.risk1 && "Risk 1 Required."}
              dropdown2Label="Risks 2"
              dropdown2Options={keyRisks}
              dropdown2Value={editedDetails?.risk2 || implementationData?.risk2}
              dropdown2HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  risk2: e.target.value,
                })
              }
              dropdown2Error={errors.risk2}
              dropdown2HelperText={errors.risk2 && "Risk 2 Required."}
              dropdown3Label="Risks 3"
              dropdown3Options={keyRisks}
              dropdown3Value={editedDetails?.risk3 || implementationData?.risk3}
              dropdown3HandleChange={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  risk3: e.target.value,
                })
              }
              dropdown3Error={errors.risk3}
              dropdown3HelperText={errors.risk3 && "Risk 3 Required."}
            />
          </div>
        </div>
        <div className="flex justify-end bottom-btn-wrapper w-[95%]">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails({
                ...PROJECT_IMPLEMENTATION_PAYLOAD,
                systemIntegration: cloneDeepArray(
                  implementationData?.projectSystemIntegration?.length
                    ? implementationData?.projectSystemIntegration
                    : [{ key: "", value: "" }]
                ),
              });
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() =>
              !checkValidation() === true && handleOpenModal()
            }
          />
        </div>
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
};

export default Implementation;
