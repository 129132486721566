import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PTButton from "../../../../components/GenericComponents/PTButton";
import Ratings from "../../../../components/GenericComponents/Ratings";
import DropdownColumn from "../../DropdownColumn";
import ConfirmModal from "../../ConfirmModal";
import Loader from "../../../../components/Loading";
import Chart from "../../Chart";
import { Actions } from "../../../../store/actions";
import { dispatch } from "../../../../utils/utility/store";
import { trimQuery } from "../../../../utils/utility/utility";
import {
  DEPLOY_AND_USE_TAB_ERROR,
  DEPLOY_AND_USE_TAB_PAYLOAD,
} from "../../../utils/payloads/payloads";
import { RATING_CHART } from "../../../constants";
import { isUnsaveChanges } from "../../../utils/middleware/analysisTab";
import PTInput from "../../../../components/GenericComponents/PTInput";
import { AnalysisLabelStyle } from "../../../../proptech/constants/General";
import { updateDeployAndUseDetails } from "../../../utils/middleware/deployAndUseTab";

export default function DeployAndUse(props) {
  const { setUnsaveChange } = props;

  // Store
  const { deployAndUseTabData, radarDetails } = useSelector(
    (state) => state.adminAnalysisTab
  );
  const selectedProptech = useSelector(
    (state) => state.adminUser.selectedProptech
  );
  const isLoading = useSelector((state) => state.adminUser.isLoading);
  const { projectRisks, integrations, techAlignment } = useSelector(
    (state) => state.adminDropdowns
  );
  // State
  const [editedDetails, setEditedDetails] = useState(
    DEPLOY_AND_USE_TAB_PAYLOAD
  );
  const [errors, setErrors] = useState(DEPLOY_AND_USE_TAB_ERROR);
  const [openModal, setOpenModal] = useState(false);
  const [placeRadars, setPlaceRadars] = useState([]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const setPlaceRadarDetails = () => {
    let updatedData = deployAndUseTabData?.placeRadars?.length
      ? radarDetails?.deployAndUseRadar?.rows?.map((i) => ({
          ...i,
          value: Number(
            deployAndUseTabData?.placeRadars?.find(
              (j) => j.placeRadarPoint.id === i.id
            )?.value
          ),
        }))
      : radarDetails?.deployAndUseRadar?.rows?.map((i) => ({ ...i, value: 0 }));
    setPlaceRadars(updatedData || []);
  };

  useEffect(() => {
    setPlaceRadarDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isUnsaveChanges(
      editedDetails,
      setUnsaveChange,
      placeRadars,
      deployAndUseTabData?.placeRadars
    );
  }, [editedDetails, placeRadars]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !deployAndUseTabData?.[error],
      };
    });
    setErrors(data);
    return Object.keys(data).some((k) => data[k]);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = deployAndUseTabData?.[key]));

    handleCloseModal();
    try {
      await updateDeployAndUseDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(
            Object.entries(editedDetails).filter(([_, v]) => v !== null)
          ),
          ...Object.fromEntries(
            Object.entries(newData).filter(([_, v]) => v !== null)
          ),
        },
        placeRadars
      );
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
      console.log("error");
    }
  };

  const onChangePlaceRadarValue = (id, val) => {
    setPlaceRadars(
      placeRadars?.map((i) => (i.id === id ? { ...i, value: val } : i))
    );
  };

  const totalPlaceRadarsCount = () => {
    return placeRadars.reduce((sum, item) => sum + Number(item.value), 0);
  };

  const onResetRadarPoint = (id) => {
    setPlaceRadars(
      placeRadars.map((i) => (i.id === id ? { ...i, value: 0 } : i))
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="flex flex-col justify-between pb-7">
        <div className="analysis-rating-chart-block flex">
          <div className="rating-block">
            {placeRadars?.map((item, idx) => (
              <Ratings
                color="#009BE2"
                key={item?.id}
                className="flex justify-between rating-items"
                label={item?.radarName}
                value={item?.value}
                max={10}
                onChange={(event, newValue) =>
                  onChangePlaceRadarValue(item.id, newValue)
                }
                onResetClick={() => onResetRadarPoint(item.id)}
                total={
                  idx === placeRadars.length - 1 && (
                    <p
                      className={`text-xs text-right font-semibold uppercase ${
                        totalPlaceRadarsCount() >= RATING_CHART.limitForError
                          ? "text-error"
                          : "text-light-grey"
                      }`}
                    >
                      {totalPlaceRadarsCount()}/
                      <span>{RATING_CHART.maxLimit}</span>
                    </p>
                  )
                }
              />
            ))}
          </div>
          <div className="chart-block">
            <div className="box-border bg-[#F3F3F5]">
              <Chart
                color="#009BE2"
                bgColor="#009BE233"
                labels={placeRadars?.map((i) => i.radarName?.split(" "))}
                items={placeRadars?.map((i) => i.value)}
                step={2}
                maximumValue={10}
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
            <span className="admin-portal-title">Asset fit</span>
            <div className="url-list-item">
              <PTInput
                labelStyle={AnalysisLabelStyle}
                variant="standard"
                label="Asset fit"
                fullWidth
                placeholder=""
                multiline
                maxRows={4}
                maxLength={255}
                value={
                  editedDetails?.assetFit !== null
                    ? editedDetails?.assetFit
                    : deployAndUseTabData?.assetFit || ""
                }
                handleOnChangeEvent={(e) =>
                  setEditedDetails({
                    ...editedDetails,
                    assetFit: e.target.value,
                  })
                }
                error={errors.assetFit}
                helperText={errors.assetFit && "Asset fit Required."}
              />
              <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
                {editedDetails?.assetFit !== null
                  ? editedDetails?.assetFit?.length
                  : deployAndUseTabData?.assetFit?.length || 0}
                /<span>255</span> CHARACTERS
              </p>
            </div>
          </div>
        <div className="flex justify-between analysis-dropdown-wrapper">
          <DropdownColumn
            header="Integration process"
            dropdown1Label="Integration process 1"
            dropdown1Options={integrations}
            dropdown1Value={
              editedDetails?.integration1 ||
              deployAndUseTabData?.integration1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                integration1: e.target.value,
              })
            }
            dropdown1Error={errors.integration1}
            dropdown1HelperText={
              errors.integration1 && "Integration process 1 Required."
            }
            dropdown2Label="Integration process 2"
            dropdown2Options={integrations}
            dropdown2Value={
              editedDetails?.integration2 ||
              deployAndUseTabData?.integration2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                integration2: e.target.value,
              })
            }
            dropdown2Error={errors.integration2}
            dropdown2HelperText={
              errors.integration2 && "Integration process 2 Required."
            }
            dropdown3Label="Integration process 3"
            dropdown3Options={integrations}
            dropdown3Value={
              editedDetails?.integration3 ||
              deployAndUseTabData?.integration3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                integration3: e.target.value,
              })
            }
            dropdown3Error={errors.integration3}
            dropdown3HelperText={
              errors.integration3 && "Integration process 3 Required."
            }
          />
          <DropdownColumn
            header="Risk incurred"
            dropdown1Label="Risk incurred 1"
            dropdown1Options={projectRisks}
            dropdown1Value={
              editedDetails?.risk1 || deployAndUseTabData?.risk1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, risk1: e.target.value })
            }
            dropdown1Error={errors.risk1}
            dropdown1HelperText={errors.risk1 && "Risk incurred 1 Required."}
            dropdown2Label="Risk incurred 2"
            dropdown2Options={projectRisks}
            dropdown2Value={
              editedDetails?.risk2 || deployAndUseTabData?.risk2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, risk2: e.target.value })
            }
            dropdown2Error={errors.risk2}
            dropdown2HelperText={errors.risk2 && "Risk incurred 2 Required."}
            dropdown3Label="Risk incurred 3"
            dropdown3Options={projectRisks}
            dropdown3Value={
              editedDetails?.risk3 || deployAndUseTabData?.risk3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, risk3: e.target.value })
            }
            dropdown3Error={errors.risk3}
            dropdown3HelperText={errors.risk3 && "Risk incurred 3 Required."}
          />
          <DropdownColumn
            header="Tech buyer alignment"
            dropdown1Label="Tech buyer alignment 1"
            dropdown1Options={techAlignment}
            dropdown1Value={
              editedDetails?.techBuyer1 || deployAndUseTabData?.techBuyer1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, techBuyer1: e.target.value })
            }
            dropdown1Error={errors.techBuyer1}
            dropdown1HelperText={
              errors.techBuyer1 && "Tech buyer alignment 1 Required."
            }
            dropdown2Label="Tech buyer alignment 2"
            dropdown2Options={techAlignment}
            dropdown2Value={
              editedDetails?.techBuyer2 || deployAndUseTabData?.techBuyer2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, techBuyer2: e.target.value })
            }
            dropdown2Error={errors.techBuyer2}
            dropdown2HelperText={
              errors.techBuyer2 && "Tech buyer alignment 2 Required."
            }
            dropdown3Label="Tech buyer alignment 3"
            dropdown3Options={techAlignment}
            dropdown3Value={
              editedDetails?.techBuyer3 || deployAndUseTabData?.techBuyer3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, techBuyer3: e.target.value })
            }
            dropdown3Error={errors.techBuyer3}
            dropdown3HelperText={
              errors.techBuyer3 && "Tech buyer alignment 3 Required."
            }
          />
        </div>
        <div className="flex justify-end bottom-btn-wrapper">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails(DEPLOY_AND_USE_TAB_PAYLOAD);
              setPlaceRadarDetails();
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>

        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
}
