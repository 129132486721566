import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loading";
import { MessageType } from "../../../../constants/General";
import { Actions } from "../../../../store/actions";
import { showMessage } from "../../../../utils/utility/snackbar";
import { dispatch, dispatchNoPayload } from "../../../../utils/utility/store";
import {
  DeleteUserMessage,
  RFF_DETAILS_TAB,
  UNSAVED_CHANGES_MESSAGE,
} from "../../../constants";
import {
  deleteRFF,
  getRFFs,
  setActiveTab,
} from "../../../utils/middleware/adminRFF";
import ConfirmModal from "../../ConfirmModal";
import ForYouTab from "../ForYouTab";
import RFFListCard from "../RFFListCard";
import RFFProfileTab from "../RFFProfileTab";
import PlanTab from "../PlanTab";
import WatchlistTab from "../WatchlistTab";
import "./RFF.scss";
import HomeTab from "../HomeTab";
import PermissionTab from "../PermissionsTab";
// import { ProjectTab } from "../ProjectTab";
import { Tooltip } from "@mui/material";
import { DeleteOutlineOutlined } from "@material-ui/icons";

export default function RFFTab(props) {
  const { unsaveChange, setUnsaveChange } = props;

  // Store
  const {
    isLoading,
    isRFFSelected,
    selectedRFF,
    activeSort,
    sortOrder,
    isArchived,
    search,
    activeTab,
  } = useSelector((state) => state.adminRFF);
  const adminRFF = useSelector((state) => state.adminRFF);
  const adminDropdowns = useSelector((state) => state.adminDropdowns);
  const isRedirect = useSelector((state) => state.admin.isRedirect);

  // State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    getRFFs(isArchived, activeSort, sortOrder, search, !isRedirect);
    console.log({ isArchived, activeSort, sortOrder, search, isRedirect });
  }, [activeSort, sortOrder, isArchived, search]); // eslint-disable-line react-hooks/exhaustive-deps

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className="h-full"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <div className="h-full">{children}</div>}
      </div>
    );
  }

  useEffect(
    () => () => {
      isRedirect
        ? dispatch(Actions.Admin.SetIsRedirect, false)
        : dispatchNoPayload(Actions.AdminRFF.AdminRFFReset);
    },
    []
  );
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <Loader />}
      <div className="w-full h-full box-border p-[30px] bg-[#FAFAFB] admin-proptech-tab investor-proptech-tab">
        <div className="w-full h-full flex flex-wrap rounded-[32px] overflow-hidden bg-[#FFFFFF]">
          <div className="h-full left-block">
            <RFFListCard unsaveChange={unsaveChange} />
          </div>
          <div className="h-full right-block">
            <>
              <div className="flex items-center justify-between right-top-heading-admin bg-[#111D43] text-[#FFFFFF] right-top-heading">
                <div className=" flex top-heading-listing-admin">
                  {RFF_DETAILS_TAB.map((tab, index) => {
                    return (
                      <div key={index}>
                        <span
                          className={`text-sm not-italic uppercase cursor-pointer ${
                            activeTab === index ? `font-semibold` : `font-light`
                          }`}
                          onClick={() => {
                            if (!unsaveChange.current) {
                              setActiveTab(
                                index,
                                selectedRFF?.id,
                                adminRFF,
                                adminDropdowns
                              );
                              dispatch(Actions.AdminRFF.SetActiveTab, index);
                            } else {
                              showMessage(
                                UNSAVED_CHANGES_MESSAGE.message,
                                MessageType.Error
                              );
                            }
                          }}
                        >
                          {tab?.panel}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {isRFFSelected && (
                    <Tooltip
                      className="cursor-pointer"
                      title={`Delete`}
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      <DeleteOutlineOutlined style={{ color: "red" }} />
                    </Tooltip>
                  )}
                </div>
              </div>
              {isRFFSelected && (
                <div className="md:h-[calc(100%-80px)] md:overflow-auto">
                  <TabPanel value={activeTab} index={0}>
                    <RFFProfileTab setUnsaveChange={setUnsaveChange} />
                  </TabPanel>
                  {/* <TabPanel value={activeTab} index={1}>
                    <ProjectTab
                      setUnsaveChange={setUnsaveChange}
                      unsaveChange={unsaveChange}
                    />
                  </TabPanel> */}
                  <TabPanel value={activeTab} index={2}>
                    <HomeTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={3}>
                    <ForYouTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={4}>
                    <WatchlistTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={5}>
                    <PlanTab />
                  </TabPanel>
                  <TabPanel value={activeTab} index={6}>
                    <PermissionTab />
                  </TabPanel>
                </div>
              )}
            </>
          </div>
        </div>
      </div>

      {openDeleteModal && (
        <ConfirmModal
          open={openDeleteModal}
          handleCloseModal={() => setOpenDeleteModal(false)}
          message={DeleteUserMessage}
          handleSucces={() =>
            deleteRFF(
              selectedRFF?.rffCompany?.id,
              isArchived,
              activeSort,
              sortOrder,
              search,
              setOpenDeleteModal
            )
          }
        />
      )}
    </>
  );
}
