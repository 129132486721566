import { ADMIN_INVESTORS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { getTagsData } from "../dropdowns"
import { investorForYouProptechsListDTO, investorProptechsListDTO } from "../dtos/adminInvestor"

export const loadInvestorForYouData = (id, adminInvestor, adminDropdowns) => {
  getInvestorProptechs(id, adminInvestor.searchProptech)
  getInvestorForYouProptechs(id)
  loadInvestorForYouDropdowns(adminDropdowns.tags)
}

export const getInvestorProptechs = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  }

  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS + `/${id}/proptechs`, { params })
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetProptechsData, investorProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const getInvestorForYouProptechs = (id) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS + `/${id}/forYou`)
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetForYouProptechsData, investorForYouProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const updateInvestorForYouProptechs = (id, data, searchProptech, adminDropdowns) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().put(ADMIN_INVESTORS + `/${id}/forYou`, data)
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      loadInvestorForYouData(id, { searchProptech }, adminDropdowns)
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      console.log(err)
      handleError(err)
    })
}

export const updateInvestorForYouTags = (id, data, clearData) => {
  ADMIN_API().put(ADMIN_INVESTORS + `/${id}/forYouTags`, data)
    .then(res => {
      handleSuccess(res)
      getInvestorForYouProptechs(id)
      clearData()
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err);
    })
}

export const loadInvestorForYouDropdowns = (tags) => {
  if (!tags.length) {
    getTagsData();
  }
}