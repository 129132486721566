import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Actions } from "../../../../store/actions";
import { dispatch, dispatchNoPayload } from "../../../../utils/utility/store";
import ProptechCardLabel from "../../ProptechCardLabel";
import ArrowRightIcon from "../../../../assets/Icons/admin-arrow-right.svg";
import ArrowOpened from "../../../../assets/Icons/admin-arrow-opened.svg";
import defaultProfile from "../../../../assets/Icons/default-profile.svg";
import PTButton from "../../../../components/GenericComponents/PTButton";
import ConfirmModal from "../../ConfirmModal";
import {
  setActiveTab,
  updateActivetatus,
  updateArchiveStatus,
} from "../../../utils/middleware/adminTechBuyer";
import { showMessage } from "../../../../utils/utility/snackbar";
import { UNSAVED_CHANGES_MESSAGE } from "../../../constants";
import { MessageType } from "../../../../constants/General";

export default function TechBuyerCard(props) {
  //  **** props ****
  const { buyer, unsaveChange } = props;

  // State
  const [activeStatus, setActiveStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  // Store
  const {
    isTechBuyerSelected,
    selectedTechBuyer,
    activeSort,
    sortOrder,
    isArchived,
    search,
    activeTab,
  } = useSelector((state) => state.adminTechBuyer);
  const adminDropdowns = useSelector((state) => state.adminDropdowns);

  const handleSelected = () => {
    if (!unsaveChange.current) {
      let isSelected =
        selectedTechBuyer?.id !== buyer.id ? true : !isTechBuyerSelected;
      dispatch(Actions.AdminTechBuyer.SetIsSelectedTechBuyer, isSelected);
      dispatch(Actions.AdminTechBuyer.SetSearchProptech, "");
      dispatch(Actions.AdminProjectTab.SetActiveProjectTab, 0);
      if (isSelected) {
        if (buyer.id !== selectedTechBuyer?.id) {
          dispatchNoPayload(Actions.AdminProjectTab.AdminProjectTabReset);
          dispatch(Actions.AdminTechBuyer.SetIsSelectedTechBuyer, true);
          dispatch(Actions.AdminTechBuyer.SetSelectedTechBuyer, buyer);
        }
        dispatch(Actions.AdminTechBuyer.SetSelectedTechBuyer, buyer);
        setActiveTab(activeTab, buyer?.id, selectedTechBuyer, adminDropdowns);
      }
      dispatch(Actions.AdminTechBuyer.SetSelectedTechBuyer, buyer);
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error);
    }
  };
  return (
    <>
      <div className="border-solid border-t border-[#111D43] opacity-40" />
      <div
        id={`buyer-${buyer?.id}`}
        className={`w-full cursor-pointer ${
          buyer?.status === 1
            ? "bg-[#DFEEE2]"
            : buyer?.status === 2
            ? "bg-[#E7E8EC]"
            : `bg-[#FFFFFF]`
        } ${buyer?.isArchived && "bg-[#C3CBD4]"}`}
        onClick={() => handleSelected()}
      >
        <div className="proptech-tab-heading">
          <div className="flex box-border items-center ">
            <div className="profile-img mr-[15px] w-[35px] h-[35px] xlg:h-[46px] xlg:w-[46px] rounded-full min-w-[35px] xlg:min-w-[46px]">
              <img
                src={buyer?.companyLogo || defaultProfile}
                className="rounded-full bg-[#70778E] h-full w-full object-contain"
                alt="Profile"
              />
            </div>
            <div className="grow">
              <div className="flex justify-between">
                <span className="font-medium text-sm leading-5 not-italic text-[#111D43]">
                  {buyer.tbCompany.name}
                </span>
              </div>
              <div className="flex">
                <ProptechCardLabel
                  header="Country"
                  className="xlg:min-w-[104px] has-align-start"
                  label={buyer?.country?.name ? buyer?.country.name : "-"}
                  style={{ marginRight: "4px" }}
                />
                <ProptechCardLabel
                  header="Meetings"
                  className="xlg:min-w-[104px] has-align-start"
                  label={buyer?.totalMeetingCount || 0}
                  style={{ marginRight: "4px" }}
                />
              </div>
              <div className="flex">
                <ProptechCardLabel
                  header="Created"
                  className="xlg:min-w-[104px] has-align-start"
                  label={
                    buyer?.createdAt
                      ? moment
                          .tz(buyer?.createdAt, "Australia/Sydney")
                          .format("DD MMM YYYY")
                      : "-"
                  }
                  style={{ marginRight: "4px" }}
                />
                <ProptechCardLabel
                  header="Last active"
                  className="xlg:min-w-[104px] has-align-start"
                  label={
                    buyer?.lastLoginAt
                      ? moment
                          .tz(buyer?.lastLoginAt, "Australia/Sydney")
                          .format("DD MMM YYYY")
                      : "-"
                  }
                  style={{ marginRight: "4px" }}
                />
              </div>
            </div>
            <img
              src={
                isTechBuyerSelected && selectedTechBuyer.id === buyer.id
                  ? ArrowOpened
                  : ArrowRightIcon
              }
              alt="Icon"
              className="ml-[20px]"
            />
          </div>
          <div className="w-full box-border items-center flex justify-end proptech-heading-btn">
            {buyer?.status === 0 ? (
              <PTButton
                variant="contained"
                label={
                  <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                    Reject
                  </span>
                }
                type="submit"
                className="mx-3 !rounded-xl !border-solid !bg-[#111D43]"
                handleOnClickEvent={(e) => {
                  e.stopPropagation();
                  if (!unsaveChange.current) {
                    setOpenModal2(true);
                    setActiveStatus(2);
                  } else {
                    showMessage(
                      UNSAVED_CHANGES_MESSAGE.message,
                      MessageType.Error
                    );
                  }
                }}
              />
            ) : null}
            {buyer?.status !== 1 ? (
              <PTButton
                variant="contained"
                label={
                  <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                    Accept
                  </span>
                }
                type="submit"
                className="mx-3 !rounded-xl !border-solid !bg-[#111D43]"
                handleOnClickEvent={(e) => {
                  e.stopPropagation();
                  if (!unsaveChange.current) {
                    setOpenModal2(true);
                    setActiveStatus(1);
                  } else {
                    showMessage(
                      UNSAVED_CHANGES_MESSAGE.message,
                      MessageType.Error
                    );
                  }
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      {isTechBuyerSelected && selectedTechBuyer.id === buyer.id && (
        <>
          <div className="border-solid border-t border-[#111D43] opacity-40" />
          <div
            className={` w-full box-border 
            ${
              buyer?.status === 1
                ? "bg-[#DFEEE2]"
                : buyer?.status === 2
                ? "bg-[#E7E8EC]"
                : `bg-[#FFFFFF]`
            } 
            ${buyer?.isArchived && "bg-[#C3CBD4]"}
            detail-block`}
          >
            <div className="sidebar-info-wrap">
              <span className="detail-label">Primary contact</span>
              <p className="text-sm">{buyer.tbUser.primaryUserName || "-"}</p>
            </div>
            <div className="my-4 xlg:my-7 sidebar-info-wrap">
              <span className="detail-label">contact email</span>
              <p className="text-sm">{buyer.tbUser.email || `-`}</p>
            </div>
            <div className="my-4 xlg:my-7 sidebar-info-wrap">
              <span className="detail-label">associated contacts</span>
              {buyer?.tbCompany?.tbUsers.length
                ? buyer.tbCompany.tbUsers.map((user) => {
                    return (
                      <PTButton
                        key={user?.id}
                        variant="contained"
                        label={
                          <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">
                            {user?.name}
                          </span>
                        }
                        type="submit"
                        className="!rounded-xl !bg-[rgba(17,29,67,0.1)] mr-3 mb-2 gray-btn"
                        handleOnClickEvent={() => {
                          dispatch(Actions.Admin.SetActiveAdminTab, 6);
                          dispatch(Actions.Contacts.SetAlreadySelectedContact, {
                            id: user?.id,
                            userType: "TB",
                          });
                          dispatch(Actions.Contacts.SetIsSelectedContact, true);
                        }}
                      />
                    );
                  })
                : `-`}
            </div>
            <div className="my-4 xlg:my-7 sidebar-info-wrap">
              <span className="detail-label">total users</span>
              <p className="text-sm">
                {buyer?.tbCompany?.tbUsers?.length + 1 || `-`}
              </p>
            </div>
            <div className="my-4 xlg:my-7 sidebar-info-wrap">
              <span className="detail-label">meetings cancelled</span>
              <p className="text-sm">{buyer?.cancelledMeetingCount || 0}</p>
            </div>
            <div className="flex justify-between items-center">
              <PTButton
                label={`${buyer?.isArchived ? `UNARCHIVE` : `ARCHIVE`}`}
                color="primary"
                size="large"
                className="text-sm btn"
                handleOnClickEvent={() => {
                  if (!unsaveChange.current) {
                    setOpenModal(true);
                  } else {
                    showMessage(
                      UNSAVED_CHANGES_MESSAGE.message,
                      MessageType.Error
                    );
                  }
                }}
              />
            </div>
          </div>
        </>
      )}

      {openModal && (
        <ConfirmModal
          open={openModal}
          handleCloseModal={() => setOpenModal(false)}
          handleSucces={() =>
            updateArchiveStatus(
              buyer?.id,
              { isArchived: !buyer?.isArchived },
              isArchived,
              activeSort,
              sortOrder,
              search,
              setOpenModal
            )
          }
        />
      )}
      {openModal2 && (
        <ConfirmModal
          open={openModal2}
          handleCloseModal={() => setOpenModal2(false)}
          handleSucces={() =>
            updateActivetatus(
              buyer?.id,
              { status: activeStatus },
              buyer,
              isArchived,
              activeSort,
              sortOrder,
              search,
              setOpenModal2
            )
          }
        />
      )}
    </>
  );
}
