// Vc session
export const setVcAccessToken = (data) => {
    localStorage.setItem('vcAccessToken', JSON.stringify(data));
};

export const getVcAccessToken = () => {
    return JSON.parse(localStorage.getItem('vcAccessToken'));
};

export const setRemenberIdVC = (data) => {
    localStorage.setItem('vcEmailToRemember', JSON.stringify(data));
}

export const getRemenberIdVC = () => {
    return JSON.parse(localStorage.getItem('vcEmailToRemember'));
}

export const setVcLocalStorageUserData = (data) => {
    localStorage.setItem('vcUserData', JSON.stringify(data));
};

export const getVcLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('vcUserData'));
};

export const removeVcSessionToken = () => {
    localStorage.removeItem('vcUserData');
    localStorage.removeItem('vcAccessToken');
    window.location.href = '/investor/login';
};

//tb session

export const setTbAccessToken = (data) => {
    localStorage.setItem('tbAccessToken', JSON.stringify(data));
};

export const getTbAccessToken = () => {
    return JSON.parse(localStorage.getItem('tbAccessToken'));
};

export const setRememberIdTB = (data) => {
    localStorage.setItem('tbEmailToRemember', JSON.stringify(data));
}

export const getRememberIdTB = () => {
    return JSON.parse(localStorage.getItem('tbEmailToRemember'));
}

export const getTbLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('tbUserData'));
};

export const setTbLocalStorageUserData = (data) => {
    localStorage.setItem('tbUserData', JSON.stringify(data));
};

export const removeTbSessionToken = () => {
    localStorage.removeItem('tbUserData');
    localStorage.removeItem('tbAccessToken');
    window.location.href = '/place/login';
};

// Proptech session
export const setProptechAccessToken = (data) => {
    localStorage.setItem('proptechAccessToken', JSON.stringify(data))
};

export const getProptechAccessToken = () => {
    return JSON.parse(localStorage.getItem('proptechAccessToken'))
};

export const setRemenberIdPtc = (data) => {
    localStorage.setItem('ptcEmailToRemember', JSON.stringify(data));
}

export const getRemenberIdPtc = () => {
    return JSON.parse(localStorage.getItem('ptcEmailToRemember'));
}


export const removeProptechAccessToken = () => {
    localStorage.removeItem('proptechAccessToken')
};

export const setProptechLocalStorageUserData = (data) => {
    localStorage.setItem('proptechUserData', JSON.stringify(data))
};

export const getProptechLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('proptechUserData'))
};

export const removeProptechLocalStorageUserData = () => {
    localStorage.removeItem('proptechUserData')
};

// Venture Scout session
export const setVsAccessToken = (data) => {
    localStorage.setItem('vsAccessToken', JSON.stringify(data))
};

export const getVsAccessToken = () => {
    return JSON.parse(localStorage.getItem('vsAccessToken'))
};

export const setRemenberIdVs = (data) => {
    localStorage.setItem('vsEmailToRemember', JSON.stringify(data));
}

export const getRemenberIdVs = () => {
    return JSON.parse(localStorage.getItem('vsEmailToRemember'));
}

export const setVsLocalStorageUserData = (data) => {
    localStorage.setItem('vsUserData', JSON.stringify(data))
};

export const getVsLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('vsUserData'))
};

export const removeVsAccessToken = () => {
    localStorage.removeItem('vsAccessToken')
    window.location.href = '/venture-scout/login';
};

export const removeVsLocalStorageUserData = () => {
    localStorage.removeItem('vsUserData')
};

// Admin session
export const setAdminAccessToken = (data) => {
    localStorage.setItem('admin', JSON.stringify(data))
};

export const getAdminAccessToken = () => {
    return JSON.parse(localStorage.getItem('admin'))
};

export const removeAdminSessionToken = () => {
    localStorage.removeItem('admin');
    window.location.href = '/admin';
};

export const setAdminLocalStorageUserData = (data) => {
    localStorage.setItem('adminUserData', JSON.stringify(data))
};

export const getAdminLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('adminUserData'))
};

export const removeAdminLocalStorageUserData = () => {
    localStorage.removeItem('admin');
    localStorage.removeItem('adminUserData');
};
