import { ADMIN_PROPTECHS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
import { companyProfileTabVCDTO } from "../../../vc/utils/dtos/companyProfileDTO";
import { designReviewTabVCDTO } from "../../../vc/utils/dtos/designReviewVC";
import { marketInsightsTabVCDTO } from "../../../vc/utils/dtos/marketInsightsVC";
import { marketTabVCDTO } from "../../../vc/utils/dtos/marketVC";
import { numbersTabVCDTO } from "../../../vc/utils/dtos/numbersVC";
import { overviewTabVCDTO } from "../../../vc/utils/dtos/overviewVC";
import { raiseOverviewTabVCDTO } from "../../../vc/utils/dtos/raiseOverviewVC";
import { raiseTabVCDTO } from "../../../vc/utils/dtos/raiseVc";
import { teamTabVCDTO } from "../../../vc/utils/dtos/teamTabVC";

export const getProptechDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}`).then((res) => {
        dispatch(Actions.VCProptechDetails.setProptechDetails, res?.data?.data);
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getOverviewDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/overview`).then((res) => {
        dispatch(Actions.VCProptechDetails.setOverviewDetails, overviewTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getMarketDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/market`).then((res) => {
        dispatch(Actions.VCProptechDetails.setMarketDetails, marketTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getNumbersDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/numbers`).then((res) => {
        dispatch(Actions.VCProptechDetails.setNumbersDetails, numbersTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getRaiseDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/raise`).then((res) => {
        dispatch(Actions.VCProptechDetails.setRaiseDetails, raiseTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getTeamsDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/team`).then((res) => {
        dispatch(Actions.VCProptechDetails.setTeamsDetails, teamTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getDesignReviewDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/designReview`).then((res) => {
        dispatch(Actions.VCProptechDetails.setDesignReviewDetails, designReviewTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getRaiseOverviewDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/raiseOverview`).then((res) => {
        dispatch(Actions.VCProptechDetails.setRaiseOverview, raiseOverviewTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getMarketInsightsDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/marketInsights`).then((res) => {
        dispatch(Actions.VCProptechDetails.setMarketInsightsDetails, marketInsightsTabVCDTO(res?.data?.data?.marketInsight));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}

export const getCompanyProfileDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/companyProfile`).then((res) => {
        dispatch(Actions.VCProptechDetails.setCompanyProfileDetails, companyProfileTabVCDTO(res?.data?.data));
        dispatch(Actions.AdminUser.SetLoading, false)
        handleSuccess(res)
    }).catch((err) => {
        dispatch(Actions.AdminUser.SetLoading, false)
        handleError(err)
    })
}
