import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from 'yup';
import Axios from 'axios';
import LeftLogo from '../../../components/LeftLogo';
import PTInput from '../../../components/GenericComponents/PTInput';
import PTButton from '../../../components/GenericComponents/PTButton';
import { setAdminAccessToken, setAdminLocalStorageUserData } from '../../../utils/session';
import { ADMIN_LOGIN } from '../../../constants/APIs';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { startLoading, stopLoading } from '../../../utils/utility/loader';
import { configs } from '../../../constants/Configs';

function Login() {
  const [loginError, setLoginError] = useState(false)
  const initialValues = {
    email: '',
    password: '',
    captcha: ''
  };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .nullable()
        .email('This email is not a valid format.')
        .required('Required'),
      password: Yup.string()
        .required('Required'),
      // .min(6, 'Password is too short - should be 6 chars minimum.')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Numarical)'),
      captcha: Yup.string()
        .required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      handleLogin(values)
    }
  });

  const handleLogin = (values) => {
    startLoading()
    const formData = {
      email: values?.email,
      password: values?.password,
    };
    Axios.post(ADMIN_LOGIN, formData).then((res) => {
      if (res?.data?.code === 200) {
        stopLoading();
        handleSuccess(res);
        setAdminAccessToken(res.data.data.token);
        setAdminLocalStorageUserData(res.data.data);
        navigate('/admin/dashboard', { replace: true });
      } else {
        stopLoading();
        handleError(res?.data);
        setLoginError(res?.message);
      }
    }).catch((error) => {
      stopLoading()
      handleError(error)
      setLoginError(error?.response?.data?.message);
    })
  }

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5  items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="justify-start flex-col">
            <div className="text-wrapper"><h2>Admin Log in</h2></div>
            {loginError ?
              <div className="danger-login">
                <span>
                  {loginError}
                </span>
              </div>
              : null
            }
            <form onSubmit={formik.handleSubmit}>
              <div className='form-wrapper'>
                <div className={"form-group " + (formik.touched.email && formik.errors.email ? "error-parent" : "") + (!formik.errors.email && formik.values.email ? "success-attempt" : "")}>
                  <PTInput
                    id="email"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && <span className="error-txt">{formik.errors.email}</span>}
                </div>
                <div className={"form-group " + (formik.touched.password && formik.errors.password ? "error-parent" : "") + (!formik.errors.password && formik.values.password ? "success-attempt" : "")}>
                  <PTInput
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    label="Password"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password && <span className="error-txt">{formik.errors.password}</span>}
                </div>
                <div className='g-recaptcha form-group error-parent'>
                  <ReCAPTCHA
                    sitekey={configs.siteKey}
                    name="captcha"
                    onErrored={(e) => formik.setFieldValue('captcha', e)}
                    onExpired={(e) => formik.setFieldValue('captcha', e)}
                    onChange={(e) => formik.setFieldValue('captcha', e)}
                  />
                  {formik.touched.captcha && formik.errors.captcha && <span className="error-txt">{formik.errors.captcha}</span>}
                </div>
                <PTButton label={'Log in'} type="submit" className="normal-case fill-orange btn" size='large' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
