import { Navigate } from 'react-router-dom';
import { getProptechAccessToken } from '../../../utils/session';

// Access of Routes For logged in user
const PrivateRoute = ({ children }) => {
    const isLogin = getProptechAccessToken()

    return (
      <>
        {isLogin ? children : <Navigate to='/proptech/login' />}
      </>
    );
}
export default PrivateRoute;