import React, { useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  updateActivetatus,
  updateArchiveStatus,
} from '../../../../utils/middleware/_admin/Proptechs'
import { Store } from '../../../../store/configureStore'
import { Actions } from '../../../../store/actions'
import { dispatch } from '../../../../utils/utility/store'
import ArrowRightIcon from '../../../../assets/Icons/admin-arrow-right.svg'
import ArrowOpened from '../../../../assets/Icons/admin-arrow-opened.svg'
import './MarketInsightCard.scss'
import Loader from '../../../../components/Loading'
import ConfirmModal from '../../ConfirmModal'
import { showMessage } from '../../../../utils/utility/snackbar'
import { UNSAVED_CHANGES_MESSAGE } from '../../../constants'
import { MessageType } from '../../../../constants/General'
import { setActiveMarketInsightTabData } from '../../../utils/middleware/AdminMarketInsightReports'

export default function MarketInsightCard(props) {
  //  **** props ****
  const { report, unsaveChange } = props
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModal2, setOpenModal2] = useState(false)
  const [activeStatus] = useState(null)

  // Store
  const isMarketInsightSelected = useSelector(
    (state) => state.adminMarketInsightReports.isMarketInsightSelected,
  )
  const selectedMarketInsightReport = useSelector(
    (state) => state.adminMarketInsightReports.selectedMarketInsightReport,
  )

  const activeTabIndex = useSelector((state) => state.adminMarketInsightReports.activeMarketInsightTab)
  const adminDropdowns = useSelector((state) => state.adminDropdowns)
  const { activeSort, sortOrder, isArchived, search } = useSelector(
    (state) => state.adminMarketInsightReports,
  )

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleActiveStatus = async (statusCode) => {
    setIsLoading(true)
    try {
      await updateActivetatus(
        report?.id,
        { status: statusCode },
        report,
        isArchived,
        activeSort,
        sortOrder,
        search,
      )
      setOpenModal2(false)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      setOpenModal2(false)
      console.log('error')
    }
  }

  const handleSelected = () => {
    if (!unsaveChange.current) {
      let isSelected = selectedMarketInsightReport?.id !== report.id ? true : !isMarketInsightSelected
      Store.dispatch({ type: Actions.AdminMarketInsightReports.SetIsSelectedMarketInsightReport, payload: isSelected })
      if (isSelected) {
        if (report.id !== selectedMarketInsightReport?.id) {
          dispatch(Actions.AdminMarketInsightReports.SetSelectedProptechsForReport, [])
        }
        setActiveMarketInsightTabData(activeTabIndex, report?.id, report, adminDropdowns)
      }
      Store.dispatch({ type: Actions.AdminMarketInsightReports.SetSelectedMarketInsightReport, payload: report })
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  }

  const handleArchive = async () => {
    setIsLoading(true)
    try {
      await updateArchiveStatus(
        report?.id,
        { isArchived: !report?.isArchived },
        report,
        isArchived,
        activeSort,
        sortOrder,
        search,
      )
      handleCloseModal()
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      console.log('error')
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="border-solid border-t border-[#111D43] opacity-40" />
      <div
        id={`report-${report?.id}`}
        className={`w-full cursor-pointer bg-[#DFEEE2]`}
        onClick={() => handleSelected()}
      >
        <div className="proptech-tab-heading">
          <div className="flex box-border items-center ">
            <div className="grow">
              <div className="flex justify-between items-center">
                <p className="font-medium text-sm leading-5 not-italic text-[#111D43]">
                  {report?.name}
                </p>
              </div>
            </div>
            <img
              src={
                isMarketInsightSelected &&
                selectedMarketInsightReport?.id === report?.id
                  ? ArrowOpened
                  : ArrowRightIcon
              }
              alt="Icon"
              className="ml-[20px]"
            />
          </div>
        </div>
      </div>
      {isMarketInsightSelected && selectedMarketInsightReport?.id === report.id && (
        <>
          <div className={`w-full flex items-center justify-between box-border bg-[#DFEEE2] detail-block`}>
            <div className="sidebar-info-wrap">
              <span className="detail-label">DATE CREATED</span>
              <p className="text-sm">
                {report?.createdAt
                  ? moment(report?.createdAt).format('DD MMM YYYY')
                  : '-'}
              </p>
            </div>
            <div className="sidebar-info-wrap mt-2">
              <span className="detail-label">DATE UPDATED</span>
              <p className="text-sm">
                {report?.updatedAt
                  ? moment(report?.updatedAt).format('DD MMM YYYY')
                  : '-'}
              </p>
            </div>
          </div>
        </>
      )}

      {openModal && (
        <ConfirmModal
          open={openModal}
          handleCloseModal={handleCloseModal}
          handleSucces={handleArchive}
        />
      )}
      {openModal2 && (
        <ConfirmModal
          open={openModal2}
          handleCloseModal={() => setOpenModal2(false)}
          handleSucces={() => handleActiveStatus(activeStatus)}
        />
      )}
    </>
  )
}
