import { ADMIN_PROPTECHS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { Store } from "../../../store/configureStore";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError } from "../../../utils/utility/snackbar";
import { companyProfileTabTBDTO } from "../dtos/previewScreenPlaceDTO/companyProfileDTO";
import { deployAndUseDTO } from "../dtos/previewScreenPlaceDTO/deployAndUseTab";
import { designReviewTabTBDTO } from "../dtos/previewScreenPlaceDTO/designReviewTB";
import { marketInsightsTabTBDTO } from "../dtos/previewScreenPlaceDTO/marketInsightsTB";
import { marketTabTBDTO } from "../dtos/previewScreenPlaceDTO/marketTB";
import { numbersTabTBDTO } from "../dtos/previewScreenPlaceDTO/numbersTB";
import { overviewTabTBDTO } from "../dtos/previewScreenPlaceDTO/overviewTB";
import { proptechDetailsDTO } from "../dtos/previewScreenPlaceDTO/proptechDetailsDTO";
import { dispatch } from "../../../utils/utility/store";

export const getProptechDetailsAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBProptechDetails,
        payload: proptechDetailsDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { proptechDetails: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getOverviewAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/overview`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBOverviewDetails,
        payload: overviewTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { overview: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getOfferingAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/market`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBMarketDetails,
        payload: marketTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { market: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getTrackRecordAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  getPastProjectsData(proptechCompanyDetailsId)
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/numbers`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBNumbersDetails,
        payload: numbersTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { numbers: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getDeployAndUseAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/deployUse`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBDeployAndUseDetails,
        payload: deployAndUseDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { deployAndUse: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getDesignReviewAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/designReview`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBDesignReviewDetails,
        payload: designReviewTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { designReview: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getMarketInsightsAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/marketInsights`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBMarketInsightsDetails,
        payload: marketInsightsTabTBDTO(res?.data?.data?.marketInsight),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { marketInsights: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getCompanyProfileAPI = (proptechCompanyDetailsId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/companyProfile`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBCompanyProfileDetails,
        payload: companyProfileTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { companyProfile: false },
      });
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.status;
    });
};

export const getPastProjectsData = (proptechCompanyDetailsId) => {
  return ADMIN_API().get(`${ADMIN_PROPTECHS}/${proptechCompanyDetailsId}/pastProjects`).then((res) => {
    Store.dispatch({ type: Actions.TBProptechDetails.setTBPastProjectsDetails, payload: (res?.data?.data) });
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}