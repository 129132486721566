import { ADMIN_VENTURE_SCOUTS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { INVESTOR_TAB_FILTERS } from "../../constants"
import { investorInteractionProptechsListDTO } from "../dtos/adminInvestor"
import { ventureScoutListDTO } from "../dtos/adminVentureScout"
import { loadVentureScoutProfileTabData } from "./ventureScoutProfile"

export const getVentureScouts = (activeSort, sortOrder, search, clear = true) => {
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { 'order': sortOrder } : {}),
    ...(!!activeSort ? { 'orderBy': activeSort } : {}),
  }
  dispatch(Actions.VentureScouts.SetLoading, true)
  ADMIN_API().get(ADMIN_VENTURE_SCOUTS, { params })
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.VentureScouts.VentureScoutListSet, ventureScoutListDTO(res?.data?.data?.rows))
      if (clear) {
        dispatch(Actions.VentureScouts.SetSelectedVentureScout, null)
        dispatch(Actions.VentureScouts.SetIsSelectedVentureScout, false)
      }
    }).catch(err => {
      dispatch(Actions.VentureScouts.SetLoading, false)
      handleError(err)
    })
}

export const updateActivetatus = (id, status, data, activeSort, sortOrder, search, setOpenModal) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().put(`${ADMIN_VENTURE_SCOUTS}/${id}/status`, status)
    .then(res => {
      handleSuccess(res);
      getVentureScouts(activeSort, sortOrder, search);
      dispatch(Actions.VentureScouts.SetSelectedVentureScout, { ...data, ...status })
      setOpenModal(false)
    })
    .catch(err => {
      handleError(err)
      setOpenModal(false)
    });
}

export const deleteVentureScout = (id, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().delete(`${ADMIN_VENTURE_SCOUTS}/permanently-delete/${id}`)
    .then(res => {
      handleSuccess(res);
      getVentureScouts(activeSort, sortOrder, search);
      handleClose(false)
      dispatch(Actions.AdminInvestor.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.AdminInvestor.SetLoading, false)
    });
}

export const getInteractionsProptech = (id, status) => {
  dispatch(Actions.VentureScouts.SetLoading, true)

  const params = {
    ...(!!status ? { status } : {}),
  }
  ADMIN_API().get(`${ADMIN_VENTURE_SCOUTS}/${id}/meetingInteractions`, { params })
    .then(res => {
      handleSuccess(res);
      dispatch(Actions.VentureScouts.SetLoading, false)
      dispatch(Actions.VentureScouts.SetInterationsProptechs, investorInteractionProptechsListDTO(res.data.data.rows))
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.VentureScouts.SetInterationsProptechs, [])
      dispatch(Actions.VentureScouts.SetLoading, false)
    });
}

export const setActiveTab = (tab, id, adminVentureScout, adminDropdowns) => {
  switch (tab) {
    case 0: id && loadVentureScoutProfileTabData(id, adminVentureScout, adminDropdowns)
      break;

    case 1: id && getInteractionsProptech(id, INVESTOR_TAB_FILTERS.total)
      break;

    default:
      break;
  }
}