import React, { useState } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import closeIcon from '../../../assets/Icons/ic-cross.svg';
import PTButton from '../../../components/GenericComponents/PTButton';
import PTInput from '../../../components/GenericComponents/PTInput';
import { CancelMeetingModalStyle } from '../../constants/General';

export default function CancelMeetingModal(props) {
  // Props
  const { openCancelModal, handleCloseCancelMeetingModal, cancelMeeting } = props;

  // State
  const [reason, setReason] = useState('')

  return <Modal
    open={openCancelModal}
    onClose={handleCloseCancelMeetingModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="meeting-cancel-modal modal"
  >
    <>
      <Box sx={CancelMeetingModalStyle} >
        <IconButton className="close-btn" onClick={handleCloseCancelMeetingModal}>
          <img src={closeIcon} alt="Icon" />
        </IconButton>
        <div className="modal-outer relative">
          <div className="modal-inner">
            <h2>Reason</h2>
          </div>
          <form>
            <div className="form-wrapper">
              <div className="form-group">
                <PTInput
                  id="note"
                  name="note"
                  className='form-control textarea-input'
                  multiline="multiline"
                  placeholder="Enter a reason"
                  label="Enter a reason"
                  fullWidth
                  value={reason}
                  handleOnChangeEvent={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="btn-wrapper">
          <PTButton label='Close' className="fill-gray btn" handleOnClickEvent={handleCloseCancelMeetingModal}
          />
          <PTButton label='Cancel' handleOnClickEvent={() => cancelMeeting(reason)} className="fill-blue-admin btn" />
        </div>
      </Box>
    </>
  </Modal>
}
