export function raiseOverviewTabVCDTO(data) {
  return {
    id: data?.id || 0,
    raiseDate: data?.raiseDate || null,
    priorRaiseDate: data?.priorRaiseDate || null,
    isNotRaising: data?.isNotRaising || false,
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || '-',
    },
    investmentRange: {
      id: data?.investmentRange?.id || 0,
      name: data?.investmentRange?.name || '-',
    },
    valuationRange: {
      id: data?.valuationRange?.id || 0,
      name: data?.valuationRange?.name || '-',
    },
    investmentType: {
      id: data?.investmentType?.id || 0,
      name: data?.investmentType?.name || '-',
    },
    keyBizModel: {
      id: data?.keyBizModel?.id || 0,
      name: data?.keyBizModel?.name || '-',
    },
    priorInvestmentRound: {
      id: data?.priorInvestmentRound?.id || 0,
      name: data?.priorInvestmentRound?.name || '-',
    },
    priorInvestmentRange: {
      id: data?.priorInvestmentRange?.id || 0,
      name: data?.priorInvestmentRange?.name || '-',
    },
    priorValuationRange: {
      id: data?.priorValuationRange?.id || 0,
      name: data?.priorValuationRange?.name || '-',
    },
    priorInvestmentType: {
      id: data?.priorInvestmentType?.id || 0,
      name: data?.priorInvestmentType?.name || '-',
    },
    priorKeyBizModel: {
      id: data?.priorKeyBizModel?.id || 0,
      name: data?.priorKeyBizModel?.name || '-',
    }
  }
}
