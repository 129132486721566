export function marketTabDTO(data) {
  return {
    id: data?.id || 0,
    competition: data?.competition || 0,
    costToScale: data?.costToScale || 0,
    currentPipeline: data?.currentPipeline || 0,
    geographyAgnostic: data?.geographyAgnostic || 0,
    highDemand: data?.highDemand || 0,
    marketSize: data?.marketSize || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || '',
    proptechCompanyDetails: {
      marketOpportunityAdmin: data?.proptechCompanyDetails?.marketOpportunityAdmin || '',
    },
    proptechAnalysisChargingModel1: {
      id: data?.proptechAnalysisChargingModel1?.id || 0,
      name: data?.proptechAnalysisChargingModel1?.name || '',
    },
    proptechAnalysisChargingModel2: {
      id: data?.proptechAnalysisChargingModel2?.id || 0,
      name: data?.proptechAnalysisChargingModel2?.name || '',
    },
    proptechAnalysisChargingModel3: {
      id: data?.proptechAnalysisChargingModel3?.id || 0,
      name: data?.proptechAnalysisChargingModel3?.name || '',
    },
    proptechAnalysisTechBuyer1: {
      id: data?.proptechAnalysisTechBuyer1?.id || 0,
      name: data?.proptechAnalysisTechBuyer1?.name || '',
    },
    proptechAnalysisTechBuyer2: {
      id: data?.proptechAnalysisTechBuyer2?.id || 0,
      name: data?.proptechAnalysisTechBuyer2?.name || '',
    },
    proptechAnalysisTechBuyer3: {
      id: data?.proptechAnalysisTechBuyer3?.id || 0,
      name: data?.proptechAnalysisTechBuyer3?.name || '',
    },
    proptechAnalysisValueKPI1: {
      id: data?.proptechAnalysisValueKPI1?.id || 0,
      name: data?.proptechAnalysisValueKPI1?.name || '',
    },
    proptechAnalysisValueKPI2: {
      id: data?.proptechAnalysisValueKPI2?.id || 0,
      name: data?.proptechAnalysisValueKPI2?.name || '',
    },
    proptechAnalysisValueKPI3: {
      id: data?.proptechAnalysisValueKPI3?.id || 0,
      name: data?.proptechAnalysisValueKPI3?.name || '',
    },
    proptechAnalysisValueKPI4: {
      id: data?.proptechAnalysisValueKPI4?.id || 0,
      name: data?.proptechAnalysisValueKPI4?.name || '',
    },
    chargingModel1: data?.chargingModel1 || null,
    chargingModel2: data?.chargingModel2 || null,
    chargingModel3: data?.chargingModel3 || null,
    techBuyer1: data?.techBuyer1 || null,
    techBuyer2: data?.techBuyer2 || null,
    techBuyer3: data?.techBuyer3 || null,
    valueKPI1: data?.valueKPI1 || null,
    valueKPI2: data?.valueKPI2 || null,
    valueKPI3: data?.valueKPI3 || null,
    valueKPI4: data?.valueKPI4 || null,
    placeRadars: data?.proptechCompanyDetails?.proptechRadarValues
  }
}