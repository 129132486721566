import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import moment from 'moment';
import { useSelector } from 'react-redux';
import icDownload from '../../../../assets/Icons/ic-download.svg';
import PTButton from '../../../../components/GenericComponents/PTButton';
import { KeyFeatureLabel, KeySpanLogo, MetricsItem, TextWithHeading, VerticalLogoWithHeading } from '../../Label';
import InfoIcon from '../../../../components/InfoIcon';
import { MessageType, TooltipText } from '../../../../constants/General';
import './style.scss';
import { showMessage } from '../../../../utils/utility/snackbar';
import { UNSAVED_CHANGES_MESSAGE } from '../../../../_admin/constants';

export default function CompanyProfileTab() {
  // Store
  const { companyProfileDetails } = useSelector(state => state.vcProptechDetails)
  const vcPlanDetails = useSelector(state => state.vcUser.vcPlanDetails);

  // State
  const [handleInfo, setHandleInfo] = useState([])

  const currentYear = (new Date()).getFullYear() % 100;

  return (
    <>
      {
        companyProfileDetails ?
          <div className="tabpanel-wrapper company-profile-tab">
            <div className="watchlist-card">
              <div className="watchlist-data">
                <div className="left">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#FFD84F" />
                    <path d="M13 6.42V6.57C13.0002 6.68683 12.9596 6.80004 12.885 6.89L8.38501 12.32C8.28983 12.4343 8.14873 12.5002 8.00001 12.5C7.8513 12.5002 7.71019 12.4343 7.61501 12.32L3.11501 6.89C3.04047 6.80004 2.99979 6.68683 3.00001 6.57V6.42C2.99909 6.29436 3.0455 6.17297 3.13001 6.08L5.20501 3.825C5.39379 3.61865 5.66035 3.50079 5.94001 3.5H10.06C10.3397 3.50079 10.6062 3.61865 10.795 3.825L12.87 6.08C12.9545 6.17297 13.0009 6.29436 13 6.42Z" fill="#FFF5CB" />
                  </svg>
                  This Proptech is curated to match your company profile
                </div>
                <div className="right">
                  <PTButton id="download-summary-btn" label='Download summary' iconBtn={icDownload} className="normal-case fill-yellow btn"
                    handleOnClickEvent={() => {
                      if (vcPlanDetails?.isHavingDownloadOnePager) {
                        window.print()
                      } else {
                        showMessage(UNSAVED_CHANGES_MESSAGE.upgradePlan, MessageType.Error)
                      }
                    }} />
                </div>
              </div>
            </div>
            <div className='market-overview'>
              {/* Key themes block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-4'>Key themes</h2>
                    <InfoIcon
                      label='Key themes'
                      title='Key themes'
                      info={handleInfo}
                      handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                      handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                    />
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Sectors block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-4'>Sectors</h2>
                    <InfoIcon
                      label='Sectors'
                      title='Sectors'
                      info={handleInfo}
                      handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                      handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                    />
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector1?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector2?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector3?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Asset lifecycle block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-4'>Asset lifecycle</h2>
                    <InfoIcon
                      label={TooltipText.assetLifecycle}
                      title={TooltipText.assetLifecycle}
                      info={handleInfo}
                      handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                      handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                    />
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1?.icon} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2?.icon} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3?.icon} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Tech offering block */}
              <div className="item-market col-6">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-4'>Tech offering</h2>
                    <InfoIcon
                      label='Tech offering'
                      title='Tech offering'
                      info={handleInfo}
                      handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                      handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                    />
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Pricing block */}
              <div className="item-market col-6">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-4'>Pricing</h2>
                    <InfoIcon
                      label='Pricing'
                      title='Pricing'
                      info={handleInfo}
                      handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                      handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                    />
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.icon} text={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.icon} text={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.icon} text={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.name} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* About the company block */}
            <h2 className="left-bordered-text orange-border">About the company</h2>
            <div className="market-growth">
              <div className='flex items-start'>
                <h2 className="pr-4"><span className="number-txt">1.</span> Commercial problem</h2>
                <InfoIcon
                  label={TooltipText.problem}
                  title={TooltipText.problem}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.problemSynopsis}</p>
              <div className="growth-info">
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem1Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem1Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem2Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem2Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem3Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem3Text} />
              </div>
            </div>
            <div className="st-20">&nbsp;</div>
            <div className="market-growth">
              <div className='flex items-start'>
                <h2 className="pr-4"><span className="number-txt">2.</span> Solution</h2>
                <InfoIcon
                  label='Solution'
                  title='Solution'
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.solutionSynopsis}</p>
              <div className="growth-info">
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution1Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution1Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution2Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution2Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution3Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution3Text} />
              </div>
            </div>
            <div className="market-growth key-metrics has-numbers">
              <div className='flex items-start'>
                <h2 className="pr-4"><span className="number-txt">3.</span> Business model + go to market</h2>
                <InfoIcon
                  label={TooltipText.businessModelAndGoToMarket}
                  title={TooltipText.businessModelAndGoToMarket}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <div className="modal-design">
                <div className="col-6">
                  <span>Business model</span>
                  <div className="growth-info">
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel1Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel1Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel2Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel2Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel3Heading}</h3>
                      <p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel3Text}</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <span>GO TO MARKET</span>
                  <div className="growth-info">
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket1Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket1Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket2Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket2Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket3Heading}</h3>
                      <p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket3Text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="st-330">&nbsp;</div>
            <div className="market-growth">
              <div className='flex items-start'>
                <h2 className="pr-4"><span className="number-txt">4.</span> Customers + validation</h2>
                <InfoIcon
                  label={TooltipText.customersAndValidation}
                  title={TooltipText.customersAndValidation}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <div className="modal-design">
                <div className="col-6">
                  <span>KEY CUSTOMER TYPE</span>
                  <div className="detail-sectors">
                    <ul className="menu-item">
                      <KeyFeatureLabel icon={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.icon} label={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.name} />
                      <KeyFeatureLabel icon={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.icon} label={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.name} />
                      <KeyFeatureLabel icon={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.icon} label={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.name} />
                    </ul>
                  </div>
                  {/* </div> */}
                  <div className="col-666">
                    <span>KEY CUSTOMERS</span>
                    <div className="supporting-award w-full">
                      {companyProfileDetails?.keyCustomer1 || companyProfileDetails?.keyCustomer2 || companyProfileDetails?.keyCustomer3 ?
                        <div className="  key-customers py-1">
                          <ul className="menu-item key-customer-logo">
                            <Tooltip title={companyProfileDetails?.keyCustomer1} arrow >
                              {companyProfileDetails?.keyCustomer1Logo ?
                                <li className="keyLogo"><img src={companyProfileDetails?.keyCustomer1Logo} alt="Icon" /></li>
                                : <li className="keyLogo"><HideImageOutlinedIcon /></li>
                              }
                            </Tooltip>
                            <Tooltip title={companyProfileDetails?.keyCustomer2} arrow >
                              {companyProfileDetails?.keyCustomer2Logo ?
                                <li className="keyLogo"><img src={companyProfileDetails?.keyCustomer2Logo} alt="Icon" /></li>
                                : <li className="keyLogo"><HideImageOutlinedIcon /></li>
                              }
                            </Tooltip>
                            <Tooltip title={companyProfileDetails?.keyCustomer3} arrow >
                              {companyProfileDetails?.keyCustomer3Logo ?
                                <li className="keyLogo"><img src={companyProfileDetails?.keyCustomer3Logo} alt="Icon" /></li>
                                : <li className="keyLogo"><HideImageOutlinedIcon /></li>
                              }
                            </Tooltip>
                          </ul>
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="metrics-col">
                    <span>METRICS</span>
                    <div className="metrics-wrapper has-three-col">
                      <div className="metrics-item">
                        <span className='font-normal'>CY{currentYear - 1} revenue</span>
                        <p>{companyProfileDetails?.revenueRange?.name}</p>
                      </div>
                      <div className="metrics-item">
                        <span className='font-normal'>Gross margin</span>
                        <p>{companyProfileDetails?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin?.name}</p>
                      </div>
                      <div className="metrics-item">
                        <span className='font-normal'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat?.name}</span>
                        <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.currentTraction2}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <span>BREAkDOWN</span>
                  {/* <div className="growth-info"> */}
                  <div className="growth-info none-mb-sm has-para">
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown1Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown1Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown2Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown2Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown3Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown3Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.noBreakdown === true &&
                      <div><p>No breadown details provided.</p></div>
                    }
                    {/* </div> */}
                  </div>
                  <span>KEY POINTS</span>
                  <div className="growth-info has-para">
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.noKeyPoint === true &&
                      <div><p>No key point provided.</p></div>
                    }
                  </div>
                </div>
              </div>
              <div className="modal-design">
                <div className="col-6">
                </div>
                <div className="col-6">
                </div>
              </div>
              <div className="modal-design">
                <div className="col-6">
                </div>
              </div>
            </div>
            <div className="market-growth key-metrics">
              <div className='flex items-start'>
                <h2 className="pr-4"><span className="number-txt">5.</span> Forecast + metrics</h2>
                <InfoIcon
                  label='Forecast + metrics'
                  title='Forecast + metrics'
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <div className="metrics-wrapper">
                <div className="metrics-item">
                  <span className="capitalize-txt">CY{currentYear + 1} REVENUE</span>
                  <span>Gross margin</span>
                  <p>{companyProfileDetails?.proptechAnalysisNumber?.proptechAnalysisFutureGrossMargin?.name}</p>
                </div>
                <div className="metrics-item">
                  <span className="capitalize-txt">CY{currentYear + 1} METRICS</span>
                  <span>{companyProfileDetails?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric1?.name}</span>
                  <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.futureMetric1Text}</p>
                </div>
                <div className="metrics-item">
                  <span>{companyProfileDetails?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric2?.name}</span>
                  <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.futureMetric2Text}</p>
                </div>
                <div className="metrics-item">
                  <span>{companyProfileDetails?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric3?.name}</span>
                  <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.futureMetric3Text}</p>
                </div>
              </div>
            </div>
            <div className="market-growth">
              <div className='flex items-start'>
                <h2 className="pr-4"><span className="number-txt">6.</span> Value proposition</h2>
                <InfoIcon
                  label={TooltipText.valueProposition}
                  title={TooltipText.valueProposition}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <div className="modal-design">
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI1Text} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI2Text} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal pb-0" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI3Text} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal pb-0" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI4Text} />
                  </div>
                </div>
              </div>
            </div>
            {/* What they’re raising block */}
            <h2 className="left-bordered-text blue-border st-155">What they’re raising</h2>
            <div className="blue-theme">
              <div className="market-growth key-metrics">
                <div className='flex items-start'>
                  <h2 className="pr-4"><span className="number-txt">A.</span> Offering</h2>
                  <InfoIcon
                    label={TooltipText.offering}
                    title={TooltipText.offering}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <div className="metrics-wrapper has-five-col">
                  <div className="metrics-item">
                    <span>Round</span>
                    <p>{companyProfileDetails?.investmentRound?.name}</p>
                  </div>
                  <div className="metrics-item">
                    <span>Raising range</span>
                    <p>{companyProfileDetails?.investmentRange?.name}</p>
                  </div>
                  <div className="metrics-item">
                    <span>Committed</span>
                    <p>{companyProfileDetails?.committed?.name}</p>
                  </div>
                  <div className="metrics-item">
                    <span>Val range</span>
                    <p>{companyProfileDetails?.valuationRange?.name}</p>
                  </div>
                  <div className="metrics-item">
                    <span>Instrument</span>
                    <p>{companyProfileDetails?.investmentType?.name}</p>
                  </div>
                </div>
              </div>
              <div className="market-growth information-growth">
                <div className='flex items-start'>
                  <h2 className="pr-4"><span className="number-txt">B.</span> Why it’s a good fit</h2>
                  <InfoIcon
                    label={TooltipText.whyGoodFit}
                    title={TooltipText.whyGoodFit}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <div className="growth-info">
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisRaise?.proptechAnalysisStyle1?.icon} heading={companyProfileDetails?.proptechAnalysisRaise?.proptechAnalysisStyle1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.whyGoodFit1Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisRaise?.proptechAnalysisTrait1?.icon} heading={companyProfileDetails?.proptechAnalysisRaise?.proptechAnalysisTrait1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.whyGoodFit2Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisRaise?.proptechAnalysisTrait2?.icon} heading={companyProfileDetails?.proptechAnalysisRaise?.proptechAnalysisTrait2?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.whyGoodFit3Text} />
                </div>
              </div>
              <div className="market-growth information-growth">
                <div className='flex items-start'>
                  <h2 className="pr-4"><span className="number-txt">C.</span> Defensibility and timing</h2>
                  <InfoIcon
                    label={TooltipText.defensibilityAndTiming}
                    title={TooltipText.defensibilityAndTiming}
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <div className="growth-info">
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1?.icon} heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.defensibilityTiming1Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2?.icon} heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.defensibilityTiming2Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3?.icon} heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.defensibilityTiming3Text} />
                </div>
              </div>
              <div className="market-growth key-metrics">
                <div className='flex items-start'>
                  <h2 className="pr-4"><span className="number-txt">D.</span> Prior funding</h2>
                  <InfoIcon
                    label='Prior funding'
                    title='Prior funding'
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <div className="metrics-wrapper align-top has-five-col">
                  <MetricsItem heading='Round' text={companyProfileDetails?.priorInvestmentRound?.name} />
                  <MetricsItem heading='Raising range' text={companyProfileDetails?.priorInvestmentRange?.name} />
                  <MetricsItem heading='Val range' text={companyProfileDetails?.priorValuationRange?.name} />
                  <MetricsItem heading='Instrument' text={companyProfileDetails?.priorInvestmentType?.name} />
                  <div className="metrics-item">
                    <span>Closing date</span>
                    <p>
                      {companyProfileDetails?.priorRaiseDate ? moment(companyProfileDetails?.priorRaiseDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="market-growth information-growth">
                <div className='flex items-start'>
                  <h2 className="pr-4"><span className="number-txt">E.</span> Team</h2>
                  <InfoIcon
                    label='Team'
                    title='Team'
                    info={handleInfo}
                    handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                    handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  />
                </div>
                <div className="growth-info">
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.icon} heading={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.skill1Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.icon} heading={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.skill2Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.icon} heading={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.skill3Text} />
                </div>
              </div>
            </div>
          </div>
          : null
      }
    </>
  )
}
