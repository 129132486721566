import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import HeaderRight from "../../components/HeaderRight";
import OverviewTab from '../../components/ProptechDetails/OverviewTab';
import MarketTab from '../../components/ProptechDetails/MarketTab';
import leftArowIcon from '../../../assets/Icons/ic-right-arrow.svg';
import RaiseOverview from '../../components/ProptechDetails/RaiseOverview';
import TopBlock from '../../components/ProptechDetails/TopBlock';
import NumberTab from '../../components/ProptechDetails/NumberTab';
import RaiseTab from '../../components/ProptechDetails/RaiseTab';
import "./style.scss";
import TeamTab from '../../components/ProptechDetails/TeamTab';
import MarketInsightTab from '../../components/ProptechDetails/MarketInsightTab';
import CompanyProfileTab from '../../components/ProptechDetails/CompanyProfileTab';
import DesignReview from '../../components/ProptechDetails/DesignReview';
import { getRaiseOverviewAPI, getProptechDetailsAPI, getOverviewAPI, getRaiseAPI, getTeamAPI, getCompanyProfileAPI, getDesignReviewAPI, getMarketInsightsAPI, getMarketAPI, getNumbersAPI } from '../../utils/middleware/ProptechDetails';
import Loader from '../../../components/Loading';
import { proptchDetailsTab, proptechDetailsArray } from '../../constants';
import ChangePassword from '../../../components/ChangePassword';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import { VC_CHANGE_PASSWORD_API } from '../../../constants/APIs';
import { VC_API } from '../../../utils/utility/API-call';
import { handleError, handleSuccess, showMessage } from '../../../utils/utility/snackbar';
import { MessageType } from '../../../constants/General';
import { UNSAVED_CHANGES_MESSAGE } from '../../../_admin/constants';
import { dispatch } from '../../../utils/utility/store';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ProptechDetail() {
  // Store
  const vcProptechDetails = useSelector(state => state.vcProptechDetails)
  const {proptechTabsAssigned, activeMainTab} = useSelector(state => state.vcUser);


  // State
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const navigate = useNavigate()

  // API call for Propech Details 
  const getProptechDetails = async () => {
    if (vcProptechDetails.apiTimeout.proptechDetails ||
      !vcProptechDetails.proptechDetails ||
      pathName[pathName.length - 1] !== vcProptechDetails.id
    ) {
      setIsLoading(true)
      await getProptechDetailsAPI(pathName[pathName.length - 1]).then((res) => {
        setIsLoading(false);
      }).catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
    }
  }

  // API call for Raise Overview Details 
  const getRaiseOverviewDetails = async () => {
    if (vcProptechDetails.apiTimeout.raiseOverview ||
      !vcProptechDetails.raiseOverviewDetails ||
      !pathName[pathName.length - 1] !== vcProptechDetails.id
    ) {
      await getRaiseOverviewAPI(pathName[pathName.length - 1])
    }
  }

  useEffect(() => {
    if (pathName) {
      getProptechDetails();
      setValue(0);
      setActiveTabData(0, pathName[pathName.length - 1], true)
    }
    if(activeMainTab === null) {
      dispatch(Actions.VCUser.SetActiveMainTab, location?.state?.activeTab || 1);
    }
  }, [location])  // eslint-disable-line react-hooks/exhaustive-deps

  // Handle tab change
  function setActiveTabData(tab, id, getRaiseOverviewData = false) {
    if (getRaiseOverviewData || pathName[pathName.length - 1] !== vcProptechDetails.id) {
      getRaiseOverviewDetails(id, setIsLoading)
    }

    switch (tab) {
      case 0: getOverviewAPI(id, setIsLoading)
        break;

      case 1: getMarketAPI(id, setIsLoading)
        break;

      case 2: getNumbersAPI(id, setIsLoading)
        break;

      case 3: getRaiseAPI(id, setIsLoading)
        break;

      case 4: getTeamAPI(id, setIsLoading)
        break;

      case 5: getDesignReviewAPI(id, setIsLoading)
        break;

      case 6: getMarketInsightsAPI(id, setIsLoading)
        break;

      case 7: getCompanyProfileAPI(id, setIsLoading)
        break;

      default: getOverviewAPI(id, setIsLoading)
        break;
    }
  }

  const handleChange = (event, newValue) => {
    let isAllowed = proptechTabsAssigned.some(i => i.navigation === activeMainTab && i.proptechTabs?.includes(newValue + 1))
    if(isAllowed) {
      setValue(newValue);
      if (vcProptechDetails.apiTimeout[proptchDetailsTab[newValue]] || !vcProptechDetails[proptechDetailsArray[newValue]]) {
        setIsLoading(true)
        setActiveTabData(newValue, pathName[pathName.length - 1])
      }
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.upgradePlan, MessageType.Error)
    }
    //old code
    // if (newValue === 5 || newValue === 6) {
    //   if (vcPlanDetails?.isHavingMarketInsight || vcPlanDetails?.isHavingDesignReview) {
    //     setValue(newValue);
    //     if (vcProptechDetails.apiTimeout[proptchDetailsTab[newValue]] || !vcProptechDetails[proptechDetailsArray[newValue]]) {
    //       setIsLoading(true)
    //       setActiveTabData(newValue, pathName[pathName.length - 1])
    //     }
    //   } else {
    //     showMessage(UNSAVED_CHANGES_MESSAGE.upgradePlan, MessageType.Error)
    //   }
    // } else {
    //   setValue(newValue);
    //   if (vcProptechDetails.apiTimeout[proptchDetailsTab[newValue]] || !vcProptechDetails[proptechDetailsArray[newValue]]) {
    //     setIsLoading(true)
    //     setActiveTabData(newValue, pathName[pathName.length - 1])
    //   }
    // }
  };

  const handleBackClick = () => {
    navigate(-1);
  }

  const changePassword = (payload, resetForm) => {
    Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: true });
    VC_API().put(VC_CHANGE_PASSWORD_API, payload)
      .then(res => {
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
        resetForm({ values: '' });
        handleSuccess(res);
        setChangePasswordModal(false)
      })
      .catch((error) => {
        Store.dispatch({ type: Actions.VCProptechs.VCSetLoading, payload: false });
        handleError(error);
      })
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="h-screen w-screen proptech-detail-wrapper">
        <div className="grow bg-[#F7F7F7]">
          <div className="navigation-top">
            <div className="btn-wrapper">
              <a onClick={() => handleBackClick()} className="icon-btn left-arrow cursor-pointer" href={() => false}> <img src={leftArowIcon} alt="Icon" />Back</a>
            </div>
            <div className='right-nav'>
              <HeaderRight setChangePasswordModal={setChangePasswordModal} />
            </div>
          </div>
          <TopBlock />
        </div>
        <div className=" protech-detail-inner">
          <div className="tab-wrapper">
            <Box sx={{ width: '100%' }}>
              <Box className='tab-heading-wrapper md:sticky lg:top-[62px] xlg:top-[67px] z-10'>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Market" {...a11yProps(1)} />
                  <Tab label="Numbers" {...a11yProps(2)} />
                  <Tab label="Raise" {...a11yProps(3)} />
                  <Tab label="Team" {...a11yProps(4)} />
                  <Tab label="Design review" {...a11yProps(5)} />
                  <Tab label={
                    <div>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" rx="8" fill="#FFD84F" />
                        <path d="M13 6.42V6.57C13.0002 6.68683 12.9596 6.80004 12.885 6.89L8.38501 12.32C8.28983 12.4343 8.14873 12.5002 8.00001 12.5C7.8513 12.5002 7.71019 12.4343 7.61501 12.32L3.11501 6.89C3.04047 6.80004 2.99979 6.68683 3.00001 6.57V6.42C2.99909 6.29436 3.0455 6.17297 3.13001 6.08L5.20501 3.825C5.39379 3.61865 5.66035 3.50079 5.94001 3.5H10.06C10.3397 3.50079 10.6062 3.61865 10.795 3.825L12.87 6.08C12.9545 6.17297 13.0009 6.29436 13 6.42Z" fill="#FFF5CB" />
                      </svg>
                      Market insights
                    </div>
                  } {...a11yProps(5)} />
                  <Tab label={
                    <div>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" rx="8" fill="#FFD84F" />
                        <path d="M13 6.42V6.57C13.0002 6.68683 12.9596 6.80004 12.885 6.89L8.38501 12.32C8.28983 12.4343 8.14873 12.5002 8.00001 12.5C7.8513 12.5002 7.71019 12.4343 7.61501 12.32L3.11501 6.89C3.04047 6.80004 2.99979 6.68683 3.00001 6.57V6.42C2.99909 6.29436 3.0455 6.17297 3.13001 6.08L5.20501 3.825C5.39379 3.61865 5.66035 3.50079 5.94001 3.5H10.06C10.3397 3.50079 10.6062 3.61865 10.795 3.825L12.87 6.08C12.9545 6.17297 13.0009 6.29436 13 6.42Z" fill="#FFF5CB" />
                      </svg>
                      Company profile
                    </div>
                  } {...a11yProps(6)} />
                </Tabs>
              </Box>
              <div className="relative md:flex md:items-start">
                <div className="tab-panels">
                  <TabPanel value={value} index={0}>
                    <OverviewTab />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <MarketTab />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <NumberTab />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <RaiseTab />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <TeamTab />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <DesignReview />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <MarketInsightTab />
                  </TabPanel>
                  <TabPanel value={value} index={7}>
                    <CompanyProfileTab />
                  </TabPanel>
                </div>
                <RaiseOverview />
              </div>
            </Box>
          </div>
        </div>
      </div>
      {changePasswordModal &&
        <ChangePassword
          open={changePasswordModal}
          handleClose={() => setChangePasswordModal(false)}
          handleSuccess={changePassword}
          cancelButtonLabel="Cancel"
          cancelButtonStyle="mr-4 fill-gray btn"
          changeButtonLabel="Change"
          changeButtonStyle='fill-orange btn small-size' />}
    </>
  )
}
export default ProptechDetail;
