import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getVcAccessToken } from '../../../utils/session';
import { getMeetingCounts } from '../../utils/middleware/PermissionsAndMeetingCounts';

// Access of Routes For logged in user
const PrivateRoute = ({ children }) => {
  const isLogin = getVcAccessToken()

  useEffect(() => {
    getMeetingCounts();
  }, [])

  return (
    <>
      {isLogin ? children : <Navigate to='/investor/login' />}
    </>
  );
}
export default PrivateRoute;