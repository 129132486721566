export const Actions = {
  Loader: {
    LoadingStart: "LOADING_START",
    LoadingStop: "LOADING_STOP",
  },
  VCAuth: {
    VCLoginSuccess: "VC_AUTH_LOGIN_SUCCESS",
    VCLoginFailed: "VC_AUTH_LOGIN_FAILED",
    VCReset: "VC_AUTH_RESET",
  },
  VCUser: {
    VCUserSet: "VC_USER_SET",
    VCUserRemove: "VC_USER_REMOVE",
    SetNotificationCheckUnread: "VC_NOTIFICATION_CHECK_UNREAD",
    SetNotifications: "VC_SET_NOTIFICATIONS",
    setNotificationLoading: "SET_NOTIFICATION_LOADING",
    SetUpcomingMeetings: "SET_VC_UPCOMING_MEETINGS",
    SetPreviousMeetings: "SET_VC_PREVIOUS_MEETINGS",
    SetActivePlan: "SET_VC_ACTIVE_PLAN",
    SetCountDetails: "SET_VC_COUNT_DETAILS",
    SetChangePlanModal: "SET_VC_CHANGE_PLAN_MODAL",
    VCUserReset: "VC_USER_RESET",
    SetNavTabs: "SET_VC_NAV_TABS",
    SetActiveMainTab: "SET_ACTIVE_MAIN_TAB",
  },
  VCProptechs: {
    VCProptechsSet: "VC_PROPTECHS_LIST_SET",
    VCWatchlistSet: "VC_WATCHLIST_SET",
    VCWatchlistPageSet: "VC_WATCHLIST_PAGE_SET",
    VCWatchlistPageSizeSet: "VC_WATCHLIST_PAGESIZE_SET",
    VCResetList: "VC_RESET_LIST",
    VCProptechsSelectedSet: "SELECTED_VC_PROPTECH_SET",
    VCProptechsReset: "VC_PROPTECHS_RESET",
    VCShowModal: "VC_SHOW_MODAL",
    VCSetFilter: "VC_SET_FILTER_OPTION",
    VCSetRevenueRangeId: "VC_SET_REVENUE_RANGE_ID",
    VCSetOrder: "VC_SET_ORDER",
    VCSetOrderBy: "VC_SET_ORDER_BY",
    VCSetPage: "VC_SET_PAGE",
    VCSetPageSize: "VC_SET_PAGESIZE",
    VCSetLoading: "VC_SET_LOADING",
    VCSetMasterData: "VC_SET_MASTERDATA",
    VCSetFeaturedList: "VC_SET_FEATUREDLIST",
    VCSetActiveUsers: "VC_SET_ACTIVEUSERS",
    VCSetActiveTab: "VC_SET_ACTIVETAB",
    VCSetRevenue: "VC_SET_REVENUE",
    VCSetMeetingTabId: "VC_SET_MEETING_TAB_ID",
  },
  VCForYou: {
    VCForyouSet: "VC_FORYOU_LIST_SET",
    VCSetPage: "VC_SET_PAGE_FORYOU",
    VCSetPageSize: "VC_SET_PAGESIZE_FORYOU",
  },
  VCProptechDetails: {
    setProptechDetails: "SET_PROPTECH_DETAILS",
    setRaiseOverview: "SET_RAISEOVERVIEW_DETAILS",
    setOverviewDetails: "SET_OVERVIEW_DETAILS",
    setMarketDetails: "SET_MARKET_DETAILS",
    setNumbersDetails: "SET_NUMBERS_DETAILS",
    setRaiseDetails: "SET_RAISE_DETAILS",
    setTeamsDetails: "SET_TEAMS_DETAILS",
    setDesignReviewDetails: "SET_DESIGNREVIEW_DETAILS",
    setMarketInsightsDetails: "SET_MARKETINSIGHTS_DETAILS",
    setCompanyProfileDetails: "SET_COMPANYPROFILE_DETAILS",
    setApiTimeout: "SET_APITIMEOUT",
    ProptechDetailsReset: "PROPTECH_DETAILS_RESET",
  },
  vcCompanyProfile: {
    vcKeybizModelsSet: "VC_KEYBIZMODELS_SET",
    vcCompanyProfileSet: "VC_COMPANYPROFILE_SET",
    vcCompanyProfileSetLoading: "VC_COMPANYPROFILE_SETLOADING",
  },
  TBProptechDetails: {
    setTBProptechDetails: "SET_TB_PROPTECH_DETAILS",
    setTBOverviewDetails: "SET_TB_OVERVIEW_DETAILS",
    setTBMarketDetails: "SET_TB_MARKET_DETAILS",
    setTBNumbersDetails: "SET_TB_NUMBERS_DETAILS",
    setTBDeployAndUseDetails: "SET_TB_DEPLOY_AND_USE_DETAILS",
    setTBDesignReviewDetails: "SET_TB_DESIGNREVIEW_DETAILS",
    setTBMarketInsightsDetails: "SET_TB_MARKETINSIGHTS_DETAILS",
    setTBCompanyProfileDetails: "SET_TB_COMPANYPROFILE_DETAILS",
    setTBApiTimeout: "SET_TB_APITIMEOUT",
    TBProptechDetailsReset: "TB_PROPTECH_DETAILS_RESET",
    setTBKeyAttributesDetails: "SET_TB_KEY_ATTRIBUTES_DETAILS",
    setTBPastProjectsDetails: "SET_TB_PAST_PROJECT_DETAILS",
  },
  RFFProptechDetails: {
    setRFFProptechDetails: "SET_RFF_PROPTECH_DETAILS",
    setRFFOverviewDetails: "SET_RFF_OVERVIEW_DETAILS",
    setRFFMarketDetails: "SET_RFF_MARKET_DETAILS",
    setRFFNumbersDetails: "SET_RFF_NUMBERS_DETAILS",
    setRFFDeployAndUseDetails: "SET_RFF_DEPLOY_AND_USE_DETAILS",
    setRFFDesignReviewDetails: "SET_RFF_DESIGNREVIEW_DETAILS",
    setRFFMarketInsightsDetails: "SET_RFF_MARKETINSIGHTS_DETAILS",
    setRFFCompanyProfileDetails: "SET_RFF_COMPANYPROFILE_DETAILS",
    setRFFApiTimeout: "SET_RFF_APITIMEOUT",
    RFFProptechDetailsReset: "RFF_PROPTECH_DETAILS_RESET",
    setRFFKeyAttributesDetails: "SET_RFF_KEY_ATTRIBUTES_DETAILS",
    setRFFPastProjectsDetails: "SET_RFF_PAST_PROJECT_DETAILS",
  },
  ProptechAuth: {
    ProptechLoginSuccess: "PROPTECH_AUTH_LOGIN_SUCCESS",
    ProptechLoginFailed: "PROPTECH_AUTH_LOGIN_FAILED",
    ProptechReset: "PROPTECH_AUTH_RESET",
  },
  ProptechUser: {
    SetLoading: "PROPTECH_USER_SET_LOADING",
    ProptechUserSet: "PROPTECH_USER_SET",
    ProptechUserRemove: "PROPTECH_USER_REMOVE",
    ProptechSetCompanyDetails: "PROPTECH_SET_COMPANY_DETAILS",
    ProptechRemoveCompanyDetails: "PROPTECH_REMOVE_COMPANY_DETAILS",
    SetNotificationCheckUnread: "PROPTECH_NOTIFICATION_CHECK_UNREAD",
    SetNotifications: "PROPTECH_SET_NOTIFICATIONS",
    SetPlans: "PROPTECH_SET_PLANS",
    ProptechUserReset: "PROPTECH_USER_RESET",
  },
  VSUser: {
    VSUserSet: "VS_USER_SET",
    VSUserRemove: "VS_USER_REMOVE",
    SetNotificationCheckUnread: "VS_NOTIFICATION_CHECK_UNREAD",
    SetNotifications: "VS_SET_NOTIFICATIONS",
    VSUserReset: "VS_USER_RESET",
  },
  VSDashboard: {
    VSDashboardSet: "VS_PROFILE_SET",
    VSSetLoading: "VS_SET_LOADING",
    VSSetStatistics: "VS_SET_STATISTICS",
    VSSetChart: "VS_SET_CHART",
  },
  Snackbar: {
    SnackbarShow: "SNACKBAR_SHOW",
    SnackbarHide: "SNACKBAR_HIDE",
    SnackbarMessage: "SNACKBAR_MESSAGE",
    SnackbarMessageType: "SNACKBAR_MESSAGE_TYPE",
    SnackbarReset: "SNACKBAR_RESET",
  },
  PublicProptechUser: {
    SetId: "SET_ID",
    SetIsVerified: "SET_IS_VERIFIED",
    SetUserReset: "USER_RESET",
  },
  Admin: {
    SetActiveAdminTab: "SET_ADMIN_ACTIVE_TAB",
    SetIsRedirect: "SET_ADMIN_IS_REDIRECT",
    ResetAdmin: "ADMIN_RESET",
  },
  AdminUser: {
    SetLoading: "PROPTECHS_SET_LOADING",
    ProptechsListSet: "PROPTECHS_LIST_SET",
    SetSelectedProptech: "SELECTED_PROPTECH_SET",
    SetIsSelectedProptech: "SET_IS_SELECTED_PROPTECH",
    SetActiveSort: "SET_ACTIVE_SORT",
    SetSortOrder: "SET_SORT_ORDER",
    SetIsArchived: "SET_IS_ARCHIVED",
    SetSearch: "SET_SEARCH",
    SetPreview: "SET_PREVIEW",
    SetForYouProptechs: "SET_FOR_YOU_PROPTECHS",
    SetForYouProptechsPlace: "SET_FOR_YOU_PROPTECHS_PLACE",
    SetForYouProptechsRFF: "SET_FOR_YOU_PROPTECHS_RFF",
    SetHomeProptechs: "SET_HOME_PROPTECHS",
    SetHomeProptechsPlace: "SET_HOME_PROPTECHS_PLACE",
    SetHomeProptechsRFF: "SET_HOME_PROPTECHS_RFF",
    SetWatchlistProptechs: "SET_WATCHLIST_PROPTECHS",
    SetWatchlistProptechsPlace: "SET_WATCHLIST_PROPTECHS_PLACE",
    SetWatchlistProptechsRFF: "SET_WATCHLIST_PROPTECHS_RFF",
    SetProptechActiveTabIndex: "SET_PROPTECH_TAB_ACTIVE_TAB_INDEX",
    SetProptechForYouActiveTabIndex:
      "SET_PROPTECH_TAB_ACTIVE_FOR_YOU_TAB_INDEX",
    SetProptechWatchlistActiveTabIndex:
      "SET_PROPTECH_TAB_ACTIVE_WATCHLIST_TAB_INDEX",
    SetProptechHomeActiveTabIndex: "SET_PROPTECH_TAB_ACTIVE_HOME_TAB_INDEX",
    AdminUserReset: "ADMIN_USER_RESET",
  },
  AdminMarketInsightReports: {
    SetLoading: "MARKET_INSIGHT_REPORTS_SET_LOADING",
    MarketInsightReportsSet: "MARKET_INSIGHT_REPORTS_SET",
    SetSelectedMarketInsightReport: "SELECTED_MARKET_INSIGHT_REPORT_SET",
    SetIsSelectedMarketInsightReport: "SET_IS_SELECTED_MARKET_INSIGHT_REPORT",
    SetActiveSort: "SET_ACTIVE_SORT",
    SetSortOrder: "SET_SORT_ORDER",
    SetIsArchived: "SET_IS_ARCHIVED",
    SetSearch: "SET_SEARCH",
    SetPreview: "SET_PREVIEW",
    SetMarketInsightsTabIndex: "SET_MARKET_INSIGHTS_TAB_ACTIVE_TAB_INDEX",
    SetMarketInsightsDetails: "SET_MARKET_INSIGHTS_DETAILS",
    AdminReportsReset: "ADMIN_REPORTS_RESET",
    SetProptechsForReports: "SET_ADMIN_PROPTECHS_FOR_REPORTS",
    SetSelectedProptechsForReport: "SET_SELECTED_ADMIN_PROPTECHS_FOR_REPORT",
  },
  AdminInvestor: {
    SetLoading: "ADMIN_INVESTORS_SET_LOADING",
    InvestorListSet: "ADMIN_INVESTORS_LIST_SET",
    SetSelectedInvestor: "SELECTED_ADMIN_INVESTOR_SET",
    SetIsSelectedInvestor: "SET_IS_SELECTED_ADMIN_INVESTOR",
    SetActiveSort: "SET_ADMIN_INVESTORS_ACTIVE_SORT",
    SetSortOrder: "SET_ADMIN_INVESTORS_SORT_ORDER",
    SetIsArchived: "SET_ADMIN_INVESTORS_IS_ARCHIVED",
    SetSearch: "SET_ADMIN_INVESTORS_SEARCH",
    SetSearchProptech: "SET_ADMIN_INVESTORS_SEARCH_PROPTECH",
    SetActiveTab: "SET_ADMIN_INVESTORS_ACTIVE_TAB",
    SetProfileTabData: "SET_ADMIN_INVESTORS_PROFILE_TAB_DATA",
    SetProfileTabTagsData: "SET_ADMIN_SELECTED_TAGS_INVESTORS_PROFILE_TAB_DATA",
    SetProptechsData: "SET_ADMIN_INVESTORS_PROPTECHS_DATA",
    SetForYouProptechsData: "SET_ADMIN_INVESTORS_FOR_YOU_PROPTECHS_DATA",
    SetHomeProptechsData: "SET_ADMIN_INVESTORS_HOME_PROPTECHS_DATA",
    SetWatchlistProptechsData: "SET_ADMIN_INVESTORS_WATCHLIST_PROPTECHS",
    SetInterationsFilter: "SET_ADMIN_INVESTORS_INTERACTIONS_FILTER",
    SetInterationsProptechs: "SET_ADMIN_INVESTORS_INTERACTIONS_PROPTECHS",
    SetAdminNavigationVcTabs: "SET_ADMIN_NAV_VC_TABS",
    SetAdminSelectedProptechVcTabs: "SET_ADMIN_SELECTED_VC_PROPTECH_TABS",
    SetActivePermissionsTab: "SET_ADMIN_PERMISSIONS_ACTIVE_TAB",
    AdminInvestorReset: "ADMIN_INVESTORS_RESET",
  },
  AdminTechBuyer: {
    SetLoading: "ADMIN_TECH_BUYERS_SET_LOADING",
    TechBuyersListSet: "ADMIN_TECH_BUYERS_LIST_SET",
    SetSelectedTechBuyer: "SELECTED_ADMIN_TECH_BUYER_SET",
    SetIsSelectedTechBuyer: "SET_IS_SELECTED_ADMIN_TECH_BUYER",
    SetActiveSort: "SET_ADMIN_TECH_BUYERS_ACTIVE_SORT",
    SetSortOrder: "SET_ADMIN_TECH_BUYERS_SORT_ORDER",
    SetIsArchived: "SET_ADMIN_TECH_BUYERS_IS_ARCHIVED",
    SetSearch: "SET_ADMIN_TECH_BUYERS_SEARCH",
    SetSearchProptech: "SET_ADMIN_TECH_BUYERS_SEARCH_PROPTECH",
    SetActiveTab: "SET_ADMIN_TECH_BUYERS_ACTIVE_TAB",
    SetProfileTabData: "SET_ADMIN_TECH_BUYERS_PROFILE_TAB_DATA",
    SetProfileTabTagsData:
      "SET_ADMIN_SELECTED_TAGS_TECH_BUYERS_PROFILE_TAB_DATA",
    SetProptechsData: "SET_ADMIN_TECH_BUYERS_PROPTECHS_DATA",
    SetForYouProptechsData: "SET_ADMIN_TECH_BUYERS_FOR_YOU_PROPTECHS_DATA",
    SetHomeProptechsData: "SET_ADMIN_TECH_BUYERS_HOME_PROPTECHS_DATA",
    SetWatchlistProptechsData: "SET_ADMIN_TECH_BUYERS_WATCHLIST_PROPTECHS",
    SetInterationsFilter: "SET_ADMIN_TECH_BUYERS_INTERACTIONS_FILTER",
    SetInterationsProptechs: "SET_ADMIN_TECH_BUYERS_INTERACTIONS_PROPTECHS",
    SetAdminNavigationTbTabs: "SET_ADMIN_NAV_TB_TABS",
    SetAdminSelectedProptechTbTabs: "SET_ADMIN_SELECTED_TB_PROPTECH_TABS",
    SetActivePermissionsTab: "SET_ADMIN_PERMISSIONS_ACTIVE_TAB",
    AdminTechBuyersReset: "ADMIN_TECH_BUYERS_RESET",
  },
  AdminRFF: {
    SetLoading: "ADMIN_RFF_SET_LOADING",
    RFFListSet: "ADMIN_RFF_LIST_SET",
    SetSelectedRFF: "SELECTED_ADMIN_RFF_SET",
    SetIsSelectedRFF: "SET_IS_SELECTED_ADMIN_RFF",
    SetActiveSort: "SET_ADMIN_RFF_ACTIVE_SORT",
    SetSortOrder: "SET_ADMIN_RFF_SORT_ORDER",
    SetIsArchived: "SET_ADMIN_RFF_IS_ARCHIVED",
    SetSearch: "SET_ADMIN_RFF_SEARCH",
    SetSearchProptech: "SET_ADMIN_RFF_SEARCH_PROPTECH",
    SetActiveTab: "SET_ADMIN_RFF_ACTIVE_TAB",
    SetProfileTabData: "SET_ADMIN_RFF_PROFILE_TAB_DATA",
    SetProfileTabTagsData: "SET_ADMIN_SELECTED_TAGS_RFF_PROFILE_TAB_DATA",
    SetProptechsData: "SET_ADMIN_RFF_PROPTECHS_DATA",
    SetForYouProptechsData: "SET_ADMIN_RFF_FOR_YOU_PROPTECHS_DATA",
    SetHomeProptechsData: "SET_ADMIN_RFF_HOME_PROPTECHS_DATA",
    SetWatchlistProptechsData: "SET_ADMIN_RFF_WATCHLIST_PROPTECHS",
    SetInterationsFilter: "SET_ADMIN_RFF_INTERACTIONS_FILTER",
    SetInterationsProptechs: "SET_ADMIN_RFF_INTERACTIONS_PROPTECHS",
    SetAdminNavigationRFFTabs: "SET_ADMIN_NAV_RFF_TABS",
    SetAdminSelectedProptechRFFTabs: "SET_ADMIN_SELECTED_RFF_PROPTECH_TABS",
    SetActivePermissionsTab: "SET_ADMIN_PERMISSIONS_ACTIVE_TAB",
    AdminRFFReset: "ADMIN_RFF_RESET",
  },
  Newsfeeds: {
    SetLoading: "NEWSFEEDS_SET_LOADING",
    NewsfeedsListSet: "NEWSFEEDS_LIST_SET",
    SetSelectedNewsfeed: "SELECTED_NEWSFEED_SET",
    SetIsSelectedNewsfeed: "SET_IS_SELECTED_NEWSFEED",
    SetActiveSort: "SET_NEWSFEED_ACTIVE_SORT",
    SetSortOrder: "SET_NEWSFEED_SORT_ORDER",
    SetIsActive: "SET_NEWSFEED_IS_ACTIVE",
    SetSearch: "SET_NEWSFEED_SEARCH",
    ResetNewsfeeds: "RESET_NEWSFEEDS",
  },
  VentureScouts: {
    SetLoading: "VENTURE_SCOUTS_SET_LOADING",
    VentureScoutListSet: "VENTURE_SCOUTS_LIST_SET",
    SetSelectedVentureScout: "SELECTED_VENTURE_SCOUT_SET",
    SetIsSelectedVentureScout: "SET_IS_SELECTED_VENTURE_SCOUT",
    SetActiveSort: "SET_VENTURE_SCOUT_ACTIVE_SORT",
    SetSortOrder: "SET_VENTURE_SCOUT_SORT_ORDER",
    SetSearch: "SET_VENTURE_SCOUT_SEARCH",
    SetActiveTab: "SET_VENTURE_SCOUT_ACTIVE_TAB",
    SetProfileTabData: "SET_VENTURE_SCOUT_PROFILE_TAB_DATA",
    SetInterationsFilter: "SET_ADMIN_VENTURE_SCOUT_INTERACTIONS_FILTER",
    SetInterationsProptechs: "SET_ADMIN_VENTURE_SCOUT_INTERACTIONS_PROPTECHS",
    ResetVentureScouts: "RESET_VENTURE_SCOUTS",
  },
  Contacts: {
    SetLoading: "ADMIN_CONTACTS_SET_LOADING",
    ContactsListSet: "ADMIN_CONTACTS_LIST_SET",
    SetSelectedContact: "SELECTED_ADMIN_CONTACT_SET",
    SetIsSelectedContact: "SET_IS_SELECTED_ADMIN_CONTACT",
    SetAlreadySelectedContact: "SET_ALREADY_SELECTED_ADMIN_CONTACT",
    SetActiveSort: "SET_ADMIN_CONTACTS_ACTIVE_SORT",
    SetSortOrder: "SET_ADMIN_CONTACTS_SORT_ORDER",
    SetIsPrimaryUser: "SET_ADMIN_CONTACTS_IS_PRIMARY_USER",
    SetSearch: "SET_ADMIN_CONTACTS_SEARCH",
    SetActiveFilter: "SET_ADMIN_CONTACTS_ACTIVE_FILTER",
    ResetContacts: "ADMIN_CONTACTS_RESET",
  },
  AdminAnalysisTab: {
    SetActiveAnalysisTab: "SET_ACTIVE_ANALYSIS_TAB",
    SetCompanyTabData: "SET_COMPANY_TAB_DATA",
    SetOverviewTabData: "SET_OVERVIEW_TAB_DATA",
    SetMarketTabData: "SET_MARKET_TAB_DATA",
    SetNumbersTabData: "SET_NUMBERS_TAB_DATA",
    SetRaiseTabData: "SET_RAISE_TAB_DATA",
    SetTeamsTabData: "SET_TEAMS_TAB_DATA",
    SetDesignReviewTabData: "SET_DESIGN_REVIEW_TAB_DATA",
    SetRaiseOverviewTabData: "SET_RAISE_OVERVIEW_TAB_DATA",
    SetMarketInsightsTabData: "SET_MARKET_INSIGHTS_TAB_DATA",
    SetCompanyProfileTabData: "SET_COMPANY_PROFILE_TAB_DATA",
    AdminAnalysisTabReset: "ADMIN_ANALYSIS_TAB_RESET",
    SetRadarDetails: "SET_ADMIN_RADAR_DETAILS",
    SetDeployAndUseTabData: "SET_DEPLOY_AND_USE_TAB_DATA",
  },
  AdminProjectTab: {
    SetActiveProjectTab: "SET_ACTIVE_PROJECT_TAB",
    SetCompanyOverviewData: "SET_COMPANY_OVERVIEW_TAB_DATA",
    SetStrategyDiagnosticData: "SET_STRATEGY_DIAGNOSTIC_TAB_DATA",
    SetAssessmentCriteriaData: "SET_ASSESSMENT_CRITERIA_TAB_DATA",
    SetTransformationData: "SET_TRANSFORMATION_TAB_DATA",
    SetImplementationData: "SET_IMPLEMENTATION_TAB_DATA",
    SetAdoptionData: "SET_ADOPTION_TAB_DATA",
    SetAttachmentsData: "SET_ATTACHMENTS_TAB_DATA",
    AdminProjectTabReset: "ADMIN_PROJECT_TAB_RESET",
  },
  AdminDropdowns: {
    SetCountries: "SET_ADMIN_COUNTRIES_DROPDOWN",
    SetRevenueranges: "SET_ADMIN_REVENUERANGES_DROPDOWN",
    SetMegatrends: "SET_ADMIN_MEGATRENDS_DROPDOWN",
    SetLanguages: "SET_ADMIN_LANGUAGES_DROPDOWN",
    SetTechbuyers: "SET_ADMIN_TECH_BUYERS_DROPDOWN",
    SetRFF: "SET_ADMIN_RFF_DROPDOWN",
    SetChargingmodels: "SET_ADMIN_CHARGING_MODELS_DROPDOWN",
    SetValuekpis: "SET_ADMIN_VALUE_KPIS_DROPDOWN",
    SetSectors: "SET_ADMIN_SECTORS_DROPDOWN",
    SetUsecases: "SET_ADMIN_USE_CASES_DROPDOWN",
    SetTechofferings: "SET_ADMIN_TECH_OFFERINGS_DROPDOWN",
    SetNoOfcustomers: "SET_ADMIN_NO_OF_CUSTOMERS_DROPDOWN",
    SetNoOfcountries: "SET_ADMIN_NO_OF_COUNTRIES_DROPDOWN",
    SetGrossmargins: "SET_ADMIN_GROSS_MARGINS_DROPDOWN",
    SetGrowthblockers: "SET_ADMIN_GROWTH_BLOCKERS_DROPDOWN",
    SetInvestmentRounds: "SET_ADMIN_INVESTMENT_ROUNDS_DROPDOWN",
    SetInvestmentRanges: "SET_ADMIN_INVESTMENT_RANGES_DROPDOWN",
    SetInvestmentTypes: "SET_ADMIN_INVESTMENT_TYPES_DROPDOWN",
    SetValuationRanges: "SET_ADMIN_VALUATION_RANGES_DROPDOWN",
    SetNoOfRounds: "SET_NO_OF_ROUNDS_DROPDOWN",
    SetAlignments: "SET_ALIGNMENTS_DROPDOWN",
    SetNoOfEmployees: "SET_NO_OF_EMPLOYEES_DROPDOWN",
    SetTeamSkills: "SET_TEAM_SKILLS_DROPDOWN",
    SetKeyHireMappings: "SET_KEY_HIRE_MAPPINGS_DROPDOWN",
    SetKeyBizModels: "SET_KEY_BIZ_MODELS_DROPDOWN",
    SetTrends: "SET_TRENDS_DROPDOWN",
    SetPredictions: "SET_PREDICTIONS_DROPDOWN",
    SetGrowth: "SET_GROWTH_DROPDOWN",
    SetNeedToKnow: "SET_NEED_TO_KNOW_DROPDOWN",
    SetInvestorStyle: "SET_INVESTOR_STYLE_DROPDOWN",
    SetInvestorTraits: "SET_INVESTOR_TRAITS_DROPDOWN",
    SetAssetLifeCycles: "SET_ASSET_LIFE_CYCLES_DROPDOWN",
    SetDefensibilityTimings: "SET_DEFENSIBILITY_TIMINGS_DROPDOWN",
    SetTags: "SET_TAGS_DROPDOWN",
    SetDropdownsReset: "SET_ADMIN_DROPDOWNS_RESET",
    SetInvestmentStages: "SET_ADMIN_INVESTMENT_STAGES_DROPDOWN",
    SetKeyPainPoints: "SET_ADMIN_KEY_PAIN_POINTS_DROPDOWN",
    SetAssetTypes: "SET_ADMIN_ASSET_TYPES_DROPDOWN",
    SetKeyIssues: "SET_ADMIN_KEY_ISSUES_DROPDOWN",
    SetKeyRisks: "SET_ADMIN_KEY_RISKS_DROPDOWN",
    SetIntegrations: "SET_ADMIN_INTEGRATIONS_DROPDOWN",
    SetRegions: "SET_ADMIN_REGIONS_DROPDOWN",
    SetProjectRisks: "SET_ADMIN_PROJECT_RISKS_DROPDOWN",
    SetTechAlignment: "SET_ADMIN_TECH_ALIGNMENT_DROPDOWN",
    SetTechContactPointVendors: "SET_ADMIN_TECH_CONTACT_POINT_VENDORS_DROPDOWN",
    SetTechPastProjectRange: "SET_ADMIN_TECH_PAST_PROJECT_RANGE_DROPDOWN",
    SetTechProjectScales: "SET_ADMIN_TECH_PROJECT_SCALES_DROPDOWN",
    SetProjectKeyUsers: "SET_ADMIN_KEY_USERS_DROPDOWN",
  },
};
