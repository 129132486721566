import React from 'react';
import { SnapshotQuestions1, SnapshotQuestions2 } from '../../../../proptech/constants/General';
import { useSelector } from 'react-redux';
import { formatTime } from '../../../../utils/utility/utility';
import { DateFormat } from '../../../../constants/General';
import './SnapshotBlock.scss';

export default function SnapshotBlock() {
  // Store
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)

  return (
    <div className='snapshot-block'>
      <div className='flex justify-between heading-block items-end'>
        <h3>Company Overview 1</h3>
        {selectedProptech?.signupCompletedAt ?
          <span className='uppercase font-normal text-xs'>
            date submitted{` `}
            <span className='font-semibold'>
              {formatTime(selectedProptech?.signupCompletedAt, DateFormat.DayMonthFormat3)} GMT
            </span>
          </span>
          : null
        }
      </div>
      {
        Object.keys(SnapshotQuestions1).map((snapshot, index) => {
          return (
            <div key={index} className='flex justify-between content-wrapper'>
              <div className='left-block'>
                <p className='text-sm'>{SnapshotQuestions1[snapshot]}</p>
              </div>
              <div className='right-block break-all'>
                <p className='text-xs'>{selectedProptech[snapshot] ? selectedProptech[snapshot] : '-'}</p>
              </div>
            </div>
          )
        })
      }
      <div className='flex justify-between heading-block items-end'>
        <h3 className=''>Company Overview 2</h3>
      </div>
      {
        Object.keys(SnapshotQuestions2).map((snapshot, index) => {
          return (
            <div key={index} className='flex justify-between content-wrapper'>
              <div className='left-block'>
                <p className='text-sm'>{SnapshotQuestions2[snapshot]}</p>
              </div>
              <div className='right-block break-all'>
                <p className='text-xs'>{selectedProptech[snapshot] ? selectedProptech[snapshot] : '-'}</p>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
