import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import defaultProfile from '../../../../assets/Icons/default-profile.svg';
import { MessageType } from '../../../../constants/General';
import { Actions } from '../../../../store/actions';
import { showMessage } from '../../../../utils/utility/snackbar';
import { dispatch } from '../../../../utils/utility/store';
import { UNSAVED_CHANGES_MESSAGE } from '../../../constants';
import ProptechCardLabel from '../../ProptechCardLabel';

export default function NewsfeedCard(props) {
  //  **** props ****
  const { newsfeed, unsaveChange } = props

  // Store
  const { isNewsfeedSelected, selectedNewsfeed } = useSelector(state => state.newsfeeds);

  const handleSelected = () => {
    if (!unsaveChange.current) {
      let isSelected = selectedNewsfeed?.id !== newsfeed.id ? true : !isNewsfeedSelected
      dispatch(Actions.Newsfeeds.SetIsSelectedNewsfeed, isSelected)
      if (newsfeed.id === selectedNewsfeed?.id) {
        dispatch(Actions.Newsfeeds.SetIsSelectedNewsfeed, false)
        dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, null)
      } else {
        dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, newsfeed)
      }
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  }

  return (
    <>
      <div className='border-solid border-t border-[#111D43] opacity-40' />
      <div
        className={`w-full cursor-pointer ${selectedNewsfeed?.id === newsfeed.id ? `bg-[#E7E8EC]` : `bg-[#FFFFFF]`}
        `}
        onClick={() => handleSelected()}
      >
        <div className='proptech-tab-heading'>
          <div className='flex box-border items-center '>
            <div className='profile-img mr-[15px] w-[35px] h-[35px] xlg:h-[46px] xlg:w-[46px] rounded-full min-w-[35px] xlg:min-w-[46px]'>
              <img
                src={newsfeed?.image || defaultProfile}
                className="rounded-full bg-[#70778E] h-full w-full object-cover"
                alt="Profile"
              />
            </div>
            <div className='grow'>
              <div className='flex justify-between'>
                <span className='font-medium text-sm leading-5 not-italic text-[#111D43]'>{newsfeed.title}</span>
              </div>
              <div className='flex justify-between'>
                <ProptechCardLabel
                  header="Created"
                  className="xlg:min-w-[104px] has-direction-row"
                  label={newsfeed?.createdAt ? moment.tz(newsfeed?.createdAt, "Australia/Sydney").format('DD MMM YYYY') : '-'}
                  style={{ marginRight: '4px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
