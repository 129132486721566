import React, { useState } from "react";
import { TextField, Chip } from "@mui/material";

const InputWithChips = ({ setEmails }) => {
  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent page reload
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let isExist = chips?.find((item) => item === inputValue);
      if (isExist) {
        setEmailError("Email already exists!");
        return;
      }
      if (emailRegex.test(inputValue.trim())) {
        setChips([...chips, inputValue.trim()]);
        setEmails([...chips, inputValue.trim()]);
        setInputValue("");
        setEmailError(null);
      } else {
        setEmailError("Enter valid email");
      }
    }
  };

  const handleChipDelete = (idx) => () => {
    setChips((chips) => chips.filter((chip, index) => idx !== index));
    setEmails((chips) => chips.filter((chip, index) => idx !== index));
  };

  return (
    <div>
      <TextField
        label="To (Email address)"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        fullWidth
        error={emailError}
        helperText={emailError ? emailError : "*Press enter to add email"}
      />
      <div>
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleChipDelete(index)}
            style={{ margin: "5px" }}
          />
        ))}
      </div>
    </div>
  );
};

export default InputWithChips;
