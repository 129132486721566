import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpIcon from '../../../assets/Icons/admin-arrow-up.svg';
import ArrowDownIcon from '../../../assets/Icons/admin-arrow-down.svg';
import { dispatch } from '../../../utils/utility/store';
import { Actions } from '../../../store/actions';
import PTInput from '../../../components/GenericComponents/PTInput';
import ProptechCard from '../ProptechCard';

export default function ProptechListSidebar(props) {
  const { unsaveChange } = props

  // State
  const [activeSearchbar, setActiveSearchbar] = useState(false)
  const [searchText, setSearchText] = useState('')

  // Store
  const { isProptechSelected, selectedProptech, activeSort, sortOrder, isArchived } = useSelector(state => state.adminUser)
  const { proptechs } = useSelector(state => state.adminUser);

  const handleSort = (sort) => {
    dispatch(Actions.AdminUser.SetActiveSort, sort)
    dispatch(Actions.AdminUser.SetSortOrder, sort === activeSort ? sortOrder === 'ASC' ? 'DESC' : 'ASC' : 'ASC')
  }

  const handleArchived = () => {
    dispatch(Actions.AdminUser.SetIsArchived, !isArchived)
  }

  const handleSearch = (e) => {
    let text = e.target.value ? e.target.value.trim() : ''
    setSearchText(e.target.value)
    if (e.key === 'Enter') {
      setSearchText(text)
      dispatch(Actions.AdminUser.SetSearch, text)
      dispatch(Actions.AdminUser.SetIsSelectedProptech, false)
      dispatch(Actions.AdminUser.SetSelectedProptech, null)
      dispatch(Actions.AdminAnalysisTab.SetActiveAnalysisTab, 0)
    }
  }

  const handleCloseSearch = () => {
    dispatch(Actions.AdminUser.SetSearch, '')
    setActiveSearchbar(false)
    setSearchText('')
  }

  useEffect(() => {
    const element = document.getElementById('proptechList');
    let totalHight = 0;
    if (isProptechSelected && proptechs?.length) {
      proptechs.every(v => {
        let cardHeight = document.getElementById(`proptech-${v?.id}`)?.clientHeight || 0
        if (selectedProptech?.id && selectedProptech?.id === v?.id) {
          dispatch(Actions.AdminUser.SetSelectedProptech, v)
          return false
        } else totalHight += cardHeight;
        return true;
      });
    }
    if (element.clientHeight < totalHight) {
      element.scrollTo(0, totalHight);
    }
  }, [isProptechSelected, selectedProptech, proptechs])

  return (
    <>
      <div className='w-full h-[80px] flex items-center justify-between box-border pl-[15px] pr-[15px] xlg:pl-[30px] xlg:pr-[24px] bg-[#E7E8EC]'>
        {activeSearchbar ?
          <PTInput label="Search" fullWidth value={searchText} onKeyPress={handleSearch} handleOnChangeEvent={handleSearch} variant="standard" placeholder='' icon={<CloseIcon className='cursor-pointer' onClick={handleCloseSearch} />} /> :
          <>
            <div className='search-icon-wrap h-[30px] w-[30px] rounded-full bg-[#fff] cursor-pointer inline-flex items-center justify-center' onClick={() => setActiveSearchbar(true)}>
              <SearchIcon className='cursor-pointer text-[#70778E] w-[20px] h-[20px]' />
            </div>
            <div className='flex font-semibold text-[12px] whitespace-nowrap not-italic uppercase'>
              <div className={`flex cursor-pointer opacity-${isArchived ? '100' : '40'}`} style={{ marginRight: '10px' }} onClick={handleArchived}>
                <input type="checkbox" checked={isArchived} className='mr-[6px] bg-[#E7E8EC] accent-[#111D43]' />
                <span>archived</span>
              </div>
              <div className={`flex cursor-pointer opacity-${activeSort === 'name' ? '100' : '40'}`} style={{ marginRight: '10px' }} onClick={() => handleSort('name')}>
                <img src={activeSort === 'name' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>A-Z</span>
              </div>
              <div className={`flex cursor-pointer opacity-${activeSort === 'createdAt' ? '100' : '40'}`} style={{ marginRight: '' }} onClick={() => handleSort('createdAt')}>
                <img src={activeSort === 'createdAt' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>Date Created</span>
              </div>
            </div>
          </>}
      </div>
      <div id="proptechList" className='mr-[-4px] md:mr-[0] h-[168px] md:h-[calc(100%-50px)] xlg:h-[calc(100%-80px)] overflow-y-auto hide-scrollbar'>
        {proptechs.length !== 0 && proptechs.map(proptech => {
          return <ProptechCard
            key={proptech?.id}
            companyDetails={proptech}
            unsaveChange={unsaveChange}
          />
        })}
      </div>
      {/* <ProptechSortMenu anchorEl={anchorEl} open={open} handleClose={handleClose} setSelectesFilter={setSelectesFilter} /> */}
    </>
  )
}
