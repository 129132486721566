export function deployAndUseDTO(data) {
    return {
      id: data?.id || 0,
      proptechCompanyDetails: {
        marketOpportunityAdmin: data?.proptechCompanyDetails?.marketOpportunityAdmin || '',
      },
      assetFit: data?.assetFit || null,
      integration1: data?.integration1 || null,
      integration2: data?.integration2 || null,
      integration3: data?.integration3 || null,
      techBuyer1: data?.techBuyer1 || null,
      techBuyer2: data?.techBuyer2 || null,
      techBuyer3: data?.techBuyer3 || null,
      risk1: data?.risk1 || null,
      risk2: data?.risk2 || null,
      risk3: data?.risk3 || null,
      placeRadars: data?.proptechCompanyDetails?.proptechRadarValues
    }
  }