import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { raiseOverviewTabDTO } from "../dtos/raiseOverviewTab"
import { getGrossMarginData, getInvestmentRangesData, getInvestmentRoundsData, getInvestmentTypesData, getKeyBizModelsData, getValuationRangesData } from "../dropdowns"

export const loadRaiseOverviewTabData = (id, adminAnalysisTab, adminDropdowns) => {
    if (!adminAnalysisTab.raiseOverviewTabData) {
        getRaiseOverviewDetails(id)
    }
    loadRaiseOverviewTabDropdowns(adminDropdowns.keyBizModels, adminDropdowns.investmentRounds, adminDropdowns.investmentRanges, adminDropdowns.valuationRanges, adminDropdowns.investmentTypes, adminDropdowns.grossMargins)
}

export const getRaiseOverviewDetails = (id) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/raiseOverview`)
        .then(res => {
            dispatch(Actions.AdminUser.SetLoading, false)
            dispatch(Actions.AdminAnalysisTab.SetRaiseOverviewTabData, raiseOverviewTabDTO(res.data.data))
            handleSuccess(res)
        }).catch(err => {
            dispatch(Actions.AdminUser.SetLoading, false)
            handleError(err)
        })
}

export const updateRaiseOverviewDetails = (id, data) => {
    dispatch(Actions.AdminUser.SetLoading, true)
    return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/raiseOverview`, data)
        .then(res => {
            handleSuccess(res)
            getRaiseOverviewDetails(id)
            return res?.status;
        }).catch(err => {
            dispatch(Actions.AdminUser.SetLoading, false)
            handleError(err);
            return err?.response?.status;
        })
}

export const loadRaiseOverviewTabDropdowns = (keyBizModels, investmentRounds, investmentRanges, valuationRanges, investmentTypes, grossMargins) => {
    if (!keyBizModels.length) {
        getKeyBizModelsData();
    }
    if (!grossMargins.length) {
        getGrossMarginData();
    }
    if (!investmentRounds.length) {
        getInvestmentRoundsData();
    }
    if (!investmentRanges.length) {
        getInvestmentRangesData();
    }
    if (!valuationRanges.length) {
        getValuationRangesData();
    }
    if (!investmentTypes.length) {
        getInvestmentTypesData();
    }
}