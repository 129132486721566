import { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { flexContainerSx, ManageUsersModalSx, TableContainerSx, TableRowSx } from '../../constants/General';
import './ManageUsers.scss';

// icons
import closeIcon from '../../../assets/Icons/ic-cross.svg';
import PTButton from '../../../components/GenericComponents/PTButton';
import { TooltipText } from '../../../constants/General';
import InfoIcon from '../../../components/InfoIcon';

export default function ManageUsers(props) {
  // Props  
  const { title, isLoading, open, isPrimaryUser, handleClose, getUsers, users, handleUpdateStatus, openInviteModal, isVcPortal, isTbPortal } = props;

  // State
  const [handleInfo, setHandleInfo] = useState([])

  useEffect(() => {
    getUsers();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return <Modal
    open={open}
    onClose={handleClose}
  >
    <Box sx={ManageUsersModalSx} className="w-10/12 md:w-1/2">
      <IconButton className="close-btn" onClick={handleClose}>
        <img src={closeIcon} alt="Icon" />
      </IconButton>
      <div className="relative">
        <div className="manageusers-modal-heading">
          <div className='flex items-baseline'>
            <h2 className='pr-4'>{title}</h2>
            <InfoIcon
              label={TooltipText.manageUserInfo}
              info={handleInfo}
              handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
              handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
            />
          </div>
          {isVcPortal || isTbPortal ?
            <PTButton
              label='Invite'
              className={`normal-case btn mr-5`}
              size='large'
              handleOnClickEvent={() => openInviteModal()}
            />
            : null}
        </div>
      </div>
      {!isLoading ? users && !users.length ?
        <Box sx={flexContainerSx}>
          No users found
        </Box>
        : users && users.length > 0 ?
          <TableContainer className='mt-4' sx={TableContainerSx}>
            <Table size='small' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  {isPrimaryUser && <TableCell>Status</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={TableRowSx}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th">{row.email}</TableCell>
                    {isPrimaryUser && <TableCell component="th">
                      <Tooltip title={TooltipText.manageUserStatus} arrow >
                        <Switch checked={row.status} onChange={(e) => handleUpdateStatus(row.id, e.target.checked)} />
                      </Tooltip>
                    </TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> : <></> : <></>}
    </Box>
  </Modal >
}