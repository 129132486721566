import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess, } from "../../../utils/utility/snackbar"
import { designReviewTabDTO } from "../dtos/designReviewTab"

export const loadDesignReviewTabData = (id, adminAnalysisTab, radarDetails) => {
  if (!adminAnalysisTab.designReviewTabData) {
    getDesignReviewDetails(id)
  }
  if (!radarDetails?.designReviewRadar) {
    getDesignReviewRadarDetails()
  }
}

export const getDesignReviewDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/designReview`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetDesignReviewTabData, designReviewTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateDesignReviewDetails = (id, data, radars) => {
  if(radars?.length) {
    data['placeRadarPoints'] = radars?.map(i => ({radarId: i.id, value: i.value}))
  }
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/designReview`, data)
    .then(res => {
      handleSuccess(res)
      getDesignReviewDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

//design review tab radar
export const getDesignReviewRadarDetails = () => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/radar/design_review`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetRadarDetails, { designReviewRadar: res.data.data })
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}
