export default function vcForYou(
  initialState = {
    vcForYou: null,
    forYouPage: 0,
    forYouPageSize: 10,
  },
  action
) {
  switch (action.type) {

    case 'VC_FORYOU_LIST_SET':
      return {
        ...initialState,
        vcForYou: action.payload,
      };

    case 'VC_SET_PAGE_FORYOU':
      return {
        ...initialState,
        forYouPage: action.payload
      }

    case 'VC_SET_PAGESIZE_FORYOU':
      return {
        ...initialState,
        forYouPageSize: action.payload
      }

    case 'VC_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload
      }

    case 'VC_RESET_LIST':
      return {
        ...initialState,
        vcForYou: null,
      }

    case 'VC_FORYOU_RESET':
      return {
        ...initialState,
        vcForYou: null,
        forYouPage: 0,
        forYouPageSize: 10,
      };

    default:
      return initialState;
  }
}
