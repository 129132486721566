import { VC_COMPANYPROFILE_API } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { VC_API } from "../../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { investorsProfileDTO } from "../../dtos/investorsProfileDTO";

export const getCompanyProfileModels = () => {
  return VC_API().get(VC_COMPANYPROFILE_API)
    .then(res => {
      if (res.data.data) {
        dispatch(Actions.vcCompanyProfile.vcCompanyProfileSet, investorsProfileDTO(res.data.data))
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error)
      console.log(error)
    })
}

export const updateCompanyProfile = (data) => {
  dispatch(Actions.vcCompanyProfile.vcCompanyProfileSetLoading, true)
  return VC_API().put(VC_COMPANYPROFILE_API, data)
    .then(res => {
      dispatch(Actions.vcCompanyProfile.vcCompanyProfileSetLoading, false)
      if (res.data.data && res.data.code === 200) {
        handleSuccess(res);
        getCompanyProfileModels()
        return res.data.data;
      } else {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      dispatch(Actions.vcCompanyProfile.vcCompanyProfileSetLoading, false)
      handleError(error)
      console.log(error)
    })
}
