export function vsProfileDTO(data) {
  return {
    id: data?.id || 0,
    name: data?.name || '-',
    companyName: data?.companyName || '-',
    email: data?.email || '-',
    profileImage: data?.profileImage || '',
    phone: data?.phone || '-',
    linkedInURL: data?.linkedInURL || '-',
    code: data?.code || '-',
    status: data?.status || '-',
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || '-',
    }
  }
}
