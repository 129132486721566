import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PTButton from '../GenericComponents/PTButton';
import PTInput from '../GenericComponents/PTInput';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/Icons/ic-cross.svg';
import { ChangePasswordFooter, ChangePasswordModal } from '../../constants/General/styles';
import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import './ChangePassword.scss';

export default function ChangePassword(props) {
  // Props
  const { open, handleClose, handleSuccess, cancelButtonLabel, cancelButtonStyle, changeButtonLabel, changeButtonStyle } = props
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Handle form values
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required('Required'),
      // .min(6, 'Password is too short - should be 6 chars minimum.')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'),
      password: Yup.string()
        .required('Required')
        .min(6, 'Password is too short - should be 6 chars minimum.')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'),
      confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('password'), null], "Password doesn't match"),
    }),
    onSubmit: (values, { resetForm }) => {
      handleSuccess({
        oldPassword: values.currentPassword,
        newPassword: values.password
      }, resetForm)
    }
  });

  return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="modal"
  >
    <Box sx={ChangePasswordModal}>
      <IconButton className="close-btn" onClick={handleClose}>
        <img src={closeIcon} alt="Icon" />
      </IconButton>
      <div className="modal-outer relative">
        <div className="changepassword-modal-heading">
          <h2>Change Password</h2>
        </div>
        <div className="change-password-modal">
          <div className="justify-start flex-col">
            <form onSubmit={formik.handleSubmit}>
              <div className='form-wrapper p-0'>
                <div className={"form-group " + (formik.touched.currentPassword && formik.errors.currentPassword ? "error-parent" : "") + (!formik.errors.currentPassword && formik.values.currentPassword ? "success-attempt-password" : "")}>
                  <PTInput
                    id="currentPassword"
                    name="currentPassword"
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Current password"
                    label="Current password"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.currentPassword}
                    icon={<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        onMouseDown={() => setShowCurrentPassword(!showCurrentPassword)}
                      >
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>}
                  />
                  {formik.touched.currentPassword && formik.errors.currentPassword && <span className="error-txt">{formik.errors.currentPassword}</span>}
                </div>
                <div className={"form-group " + (formik.touched.password && formik.errors.password ? "error-parent" : "") + (!formik.errors.password && formik.values.password ? "success-attempt-password" : "")}>
                  <PTInput
                    id="password"
                    name="password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New password"
                    label="New password"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    icon={<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>}
                  />
                  {formik.touched.password && formik.errors.password && <span className="error-txt">{formik.errors.password}</span>}
                </div>
                <div className="password-requirement">
                  <p>Password must contain at least:</p>
                  <ul>
                    <li>1 uppercase letter</li>
                    <li>1 number</li>
                    <li>1 special character</li>
                  </ul>
                </div>
                <div className={"form-group " + (formik.touched.confirmPassword && formik.errors.confirmPassword ? "error-parent" : "") + (!formik.errors.confirmPassword && formik.values.confirmPassword ? "success-attempt-password" : "")}>
                  <PTInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm new password"
                    label="Confirm new password"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    icon={<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && <span className="error-txt">{formik.errors.confirmPassword}</span>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Typography id="modal-modal-description" sx={ChangePasswordFooter}>
        <PTButton
          label={cancelButtonLabel}
          className={cancelButtonStyle}
          handleOnClickEvent={handleClose} />
        <PTButton
          label={changeButtonLabel}
          className={changeButtonStyle}
          handleOnClickEvent={formik.submitForm}
        />
      </Typography>
    </Box>
  </Modal >
}
