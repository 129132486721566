import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import PTButton from '../../../components/GenericComponents/PTButton';
import PTInput from '../../../components/GenericComponents/PTInput';
import LeftLogo from '../../../components/LeftLogo';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { startLoading, stopLoading } from '../../../utils/utility/loader';
import { VS_FORGOT_PASSWORD_API } from '../../../constants/APIs';
import { ForgotPasswordLabels } from '../../constants/general';
import { FORM_FIELDS } from '../../../vc/constants';
import './forgotPassword.scss';

export default function ForgotPassword() {
  const initialValues = {
    email: '',
    password: '',
    captcha: ''
  };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email(FORM_FIELDS.emailValidFormat)
        .required(FORM_FIELDS.required),
    }),
    onSubmit: (values, { resetForm }) => {
      sendForgotPasswordLink({
        email: values.email,
      }, resetForm)
    }
  });

  const sendForgotPasswordLink = (payload, resetForm) => {
    startLoading()
    axios.post(VS_FORGOT_PASSWORD_API, payload)
      .then(res => {
        stopLoading();
        resetForm({ values: '' });
        handleSuccess(res)
        navigate('/venture-scout/checkemail', { replace: true });
      })
      .catch((error) => {
        stopLoading();
        handleError(error)
      })
  }

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="text-wrapper">
            <h2>{ForgotPasswordLabels.title}</h2>
            <p className='p-0'>{ForgotPasswordLabels.text}</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='form-wrapper'>
              <div className={"form-group " + (formik.touched.email && formik.errors.email ? "error-parent" : "") + (!formik.errors.email && formik.values.email ? "success-attempt" : "")}>
                <PTInput
                  id={ForgotPasswordLabels.mail}
                  name={ForgotPasswordLabels.mail}
                  placeholder={ForgotPasswordLabels.email}
                  label={ForgotPasswordLabels.email}
                  fullWidth
                  className='form-control'
                  handleOnChangeEvent={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && <span className="error-txt">{formik.errors.email}</span>}
              </div>
              <PTButton label={ForgotPasswordLabels.sendResetLink} type="submit" className="normal-case mt-[13px] fill-orange btn" size='large' />
            </div>
          </form>
          <div className="not-have-account">
            <span>
              {ForgotPasswordLabels.rememberYourPassword}
              <span className="cursor-pointer block" onClick={() => window.location.href = '/venture-scout/login'}>
                {ForgotPasswordLabels.backToLogin}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>)
}
