import { MenuItem } from '@mui/material';
import React from 'react';
import PTInput from '../../../components/GenericComponents/PTInput';
import { AnalysisLabelStyle } from '../../../proptech/constants/General';

export default function DropdownWithTextField(props) {
  // Props
  const { editedDetails, setEditedDetails, errors, dropdown, dropdownOptions,
    dropdownLabel, dropdownValue, textLabel, textValue, data, disabled, showChar } = props;

  return (
    <div className='dropdown-items flex'>
      <div className='dropdown-inner-item'>
        <PTInput
          labelStyle={AnalysisLabelStyle}
          variant="standard"
          label={dropdownLabel}
          placeholder=''
          maxLength={70}
          select={dropdown || false}
          className={dropdown && "custom-dropdown-icon"}
          selectChildren={dropdown && dropdownOptions && dropdownOptions.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
          value={editedDetails?.[dropdownValue] !== null ? editedDetails?.[dropdownValue] : data?.[dropdownValue] || ''}
          handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, [dropdownValue]: e.target.value })}
          error={errors?.[dropdownValue]}
          helperText={errors?.[dropdownValue] && `${dropdownLabel} Required.`}
          disabled={disabled}
        />
      </div>
      <div className='dropdown-inner2-item'>
        <PTInput
          labelStyle={AnalysisLabelStyle}
          variant="standard"
          label={textLabel}
          placeholder=''
          multiline
          maxRows={4}
          maxLength={255}
          value={editedDetails?.[textValue] !== null ? editedDetails?.[textValue] : data?.[textValue] || ''}
          handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, [textValue]: e.target.value })}
          error={errors?.[textValue]}
          helperText={errors?.[textValue] && `${textLabel} Required.`}
          disabled={disabled}
        />
        {showChar && <p className='text-xs text-right pt-1 font-semibold uppercase text-light-grey'>{editedDetails?.[textValue] !== null ? editedDetails?.[textValue]?.length : data?.[textValue]?.length || 0}/<span>255</span> CHARACTERS</p>}
      </div>
    </div>
  )
}
