import { ADMIN_MASTER_API, ADMIN_RFF } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
// import { INVESTOR_TAB_FILTERS } from "../../constants"
import {
  buyerInteractionProptechsListDTO,
  buyerListDTO,
  buyerWatchlistProptechsListDTO,
} from "../dtos/adminRFF";
import { loadProjectOverviewTabData } from "./projectOverviewTab";
import { loadRFFForYouData } from "./rffForYouTab";
import { loadRFFHomeData } from "./rffHomeTab";
import { loadRFFProfileTabData } from "./rffProfileTab";

export const getRFFs = (
  isArchived,
  activeSort,
  sortOrder,
  search,
  clear = true
) => {
  const params = {
    filter: { isArchived: isArchived },
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { order: sortOrder } : {}),
    ...(!!activeSort ? { orderBy: activeSort } : {}),
  };
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF, { params })
    .then((res) => {
      handleSuccess(res);
      let buyers = buyerListDTO(res?.data?.data?.rows);
      dispatch(Actions.AdminRFF.RFFListSet, buyers);
      if (clear) {
        dispatch(Actions.AdminRFF.SetSelectedRFF, null);
        dispatch(Actions.AdminRFF.SetIsSelectedRFF, false);
      }
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const updateArchiveStatus = (
  id,
  status,
  isArchived,
  activeSort,
  sortOrder,
  search,
  setOpenModal
) => {
  ADMIN_API()
    .put(`${ADMIN_RFF}/${id}/archive`, status)
    .then((res) => {
      handleSuccess(res);
      getRFFs(isArchived, activeSort, sortOrder, search);
      setOpenModal(false);
    })
    .catch((err) => {
      handleError(err);
      setOpenModal(false);
    });
};

export const updateActivetatus = (
  id,
  status,
  data,
  isArchived,
  activeSort,
  sortOrder,
  search,
  setOpenModal2
) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .put(`${ADMIN_RFF}/${id}/status`, status)
    .then((res) => {
      handleSuccess(res);
      getRFFs(isArchived, activeSort, sortOrder, search);
      dispatch(Actions.AdminRFF.SetSelectedRFF, { ...data, ...status });
      setOpenModal2(false);
    })
    .catch((err) => {
      handleError(err);
      setOpenModal2(false);
    });
};

export const getInteractionsProptech = (id, status) => {
  dispatch(Actions.AdminRFF.SetLoading, true);

  const params = {
    ...(!!status ? { status } : {}),
  };
  ADMIN_API()
    .get(`${ADMIN_RFF}/${id}/meetingInteractions`, { params })
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetInterationsProptechs,
        buyerInteractionProptechsListDTO(res.data.data.rows)
      );
    })
    .catch((err) => {
      handleError(err);
      dispatch(Actions.AdminRFF.SetInterationsProptechs, []);
      dispatch(Actions.AdminRFF.SetLoading, false);
    });
};

export const deleteRFF = (
  id,
  isArchived,
  activeSort,
  sortOrder,
  search,
  handleClose
) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .delete(`${ADMIN_RFF}/permanently-delete/${id}`)
    .then((res) => {
      handleSuccess(res);
      getRFFs(isArchived, activeSort, sortOrder, search);
      handleClose(false);
    })
    .catch((err) => {
      handleError(err);
      dispatch(Actions.AdminRFF.SetLoading, false);
    });
};

export const getWatchlistProptech = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(`${ADMIN_RFF}/${id}/watchList`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetWatchlistProptechsData,
        buyerWatchlistProptechsListDTO(res.data.data.rows)
      );
    })
    .catch((err) => {
      handleError(err);
      dispatch(Actions.AdminRFF.SetLoading, false);
    });
};

export const getAllTabs = (id) => {
  getAdminNavTabs();
  getAdminSelectedRFFTabs(id);
};
export const getAdminNavTabs = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(`${ADMIN_MASTER_API}/navigations`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(Actions.AdminRFF.SetAdminNavigationRFFTabs, res.data.data);
    })
    .catch((err) => {
      handleError(err);
      dispatch(Actions.AdminRFF.SetLoading, false);
    });
};

export const getAdminSelectedRFFTabs = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(`${ADMIN_RFF}/getProptechTabs/` + id)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetAdminSelectedProptechRFFTabs,
        res.data.data?.rows
      );
    })
    .catch((err) => {
      console.log(err);
      handleError(err);
      dispatch(Actions.AdminRFF.SetLoading, false);
    });
};

export const updateSelectedRFFTabs = (data, id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .post(`${ADMIN_RFF}/assignProptechTab/` + id, data)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminRFF.SetLoading, false);
      getAdminSelectedRFFTabs(id);
    })
    .catch((err) => {
      console.log(err);
      handleError(err);
      dispatch(Actions.AdminRFF.SetLoading, false);
    });
};

export const setActiveTab = (tab, id, adminRFF, adminDropdowns) => {
  switch (tab) {
    case 0:
      id && loadRFFProfileTabData(id, adminRFF, adminDropdowns);
      break;

    case 1:
      id && loadProjectOverviewTabData(id, adminRFF, adminDropdowns);
      break;

    case 2:
      id && loadRFFHomeData(id, adminRFF, adminDropdowns);
      break;

    case 3:
      id && loadRFFForYouData(id, adminRFF, adminDropdowns);
      break;

    case 4:
      id && getWatchlistProptech(id, adminRFF, adminDropdowns);
      break;

    case 6:
      id && getAllTabs(id, adminRFF, adminDropdowns);
      break;

    default:
      break;
  }
};
