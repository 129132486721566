import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete } from "@material-ui/icons";
import { PROJECT_ADOPTION_PAYLOAD } from "../../../../utils/payloads/payloads";
import { AnalysisLabelStyle } from "../../../../../proptech/constants/General";
import PTInput from "../../../../../components/GenericComponents/PTInput";
import ConfirmModal from "../../../ConfirmModal";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import { dispatch } from "../../../../../utils/utility/store";
import { isUnsaveChanges } from "../../../../utils/middleware/projectTab";
import { Actions } from "../../../../../store/actions";
import Loader from "../../../../../components/Loading";
import { updateProjectAdoptionDetails } from "../../../../utils/middleware/projectAdoptionTab";
import {
  cloneDeepArray,
  cloneDeepObject,
} from "../../../../utils/dtos/projectTabs";

const Adoption = (props) => {
  const { setUnsaveChange } = props;
  const adoptionData = useSelector(
    (state) => state.adminProjectTab.adoptionData
  );
  const { isLoading } = useSelector((state) => state.adminUser);
  const { selectedTechBuyer } = useSelector((state) => state.adminTechBuyer);

  const [editedDetails, setEditedDetails] = useState(PROJECT_ADOPTION_PAYLOAD);
  const [openModal, setOpenModal] = useState(false);
  const [errorFields1, setErrorFields1] = useState([]);
  const [errorFields2, setErrorFields2] = useState([]);

  useEffect(() => {
    if (adoptionData?.projectDecisionProcess?.length !== 0 && !isLoading) {
      setEditedDetails((details) => ({
        ...details,
        decisionMakingProcess: cloneDeepArray(
          adoptionData?.projectDecisionProcess
        ) || [{ heading: "", value: "" }],
      }));
    }
    if (adoptionData?.projectTimeline?.length !== 0 && !isLoading) {
      setEditedDetails((details) => ({
        ...details,
        timeline: cloneDeepArray(adoptionData?.projectTimeline) || [
          { heading: "", value: "" },
        ],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adoptionData]);

  useEffect(() => {
    isUnsaveChanges(
      editedDetails,
      setUnsaveChange,
      "adoption",
      adoptionData,
      null
    );
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let fields1 = editedDetails.timeline?.map((field, index) => {
      let errorFields = {};
      Object.entries(field).forEach(([key, val], idx) => {
        if (typeof val === "string" && val?.trim().length === 0) {
          errorFields[key] = `${key} ${index + 1} is required`;
        }
      });
      return Object.values(errorFields).length && errorFields;
    });
    let fields2 = editedDetails.decisionMakingProcess?.map((field, index) => {
      let errorFields = {};
      Object.entries(field).forEach(([key, val], idx) => {
        if (typeof val === "string" && val?.trim().length === 0) {
          errorFields[key] = `${key} ${index + 1} is required`;
        }
      });
      return Object.values(errorFields).length && errorFields;
    });
    setErrorFields1([...fields1]);
    setErrorFields2([...fields2]);

    if (
      fields1.filter(Boolean).length !== 0 ||
      fields2.filter(Boolean).length !== 0
    ) {
      return true;
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = adoptionData?.[key]));

    handleCloseModal();
    try {
      let res = await updateProjectAdoptionDetails(selectedTechBuyer?.id, {
        ...Object.fromEntries(
          Object.entries(editedDetails).filter(([_, v]) => !!v)
        ),
        ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
      });
      if (res === 200) setEditedDetails(PROJECT_ADOPTION_PAYLOAD);
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
    }
  };

  const addAdoptionRow = (type) => {
    let temp = {
      heading: "",
      value: "",
    };
    let tempState = cloneDeepObject(editedDetails);
    tempState[type]?.push(temp);
    setEditedDetails(tempState);
  };
  const deleteAdoptionRow = (index, type) => {
    const filteredStops = editedDetails[type]?.filter(
      (item, idx) => index !== idx
    );

    let tempState = cloneDeepObject(editedDetails);
    tempState[type] = filteredStops;
    setEditedDetails(tempState);
  };

  const handleInputChange = (value, index, name, type) => {
    let tempState = cloneDeepObject(editedDetails);
    tempState[type][index][name] = value;
    setEditedDetails(tempState);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="mt-4 mb-[22px]">
        <div className="analysis-dropdown-wrapper horizontal">
          <div className="dropdown-title flex w-[90%] items-center justify-between text-sm not-italic text-[#111D43]">
            <p>Timeline</p>
            <p
              className="underline hover:opacity-70 cursor-pointer"
              onClick={() => addAdoptionRow("timeline")}
            >
              Add more
            </p>
          </div>
          {editedDetails?.timeline?.map((item, idx) => {
            return (
              <div className="dropdown-items flex w-[90%]" key={idx}>
                <div className="dropdown-inner-item">
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    variant="standard"
                    label={`Key heading ${idx + 1}`}
                    placeholder=""
                    maxLength={70}
                    value={item.heading}
                    handleOnChangeEvent={(e) => {
                      handleInputChange(
                        e.target.value,
                        idx,
                        "heading",
                        "timeline"
                      );
                    }}
                    error={errorFields1[idx]?.heading}
                    helperText={errorFields1[idx]?.heading}
                  />
                </div>
                <div className="dropdown-inner2-item">
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    variant="standard"
                    label={`Timeline text ${idx + 1}`}
                    placeholder=""
                    multiline
                    maxRows={4}
                    maxLength={255}
                    value={item.value}
                    handleOnChangeEvent={(e) => {
                      handleInputChange(
                        e.target.value,
                        idx,
                        "value",
                        "timeline"
                      );
                    }}
                    error={errorFields1[idx]?.value}
                    helperText={errorFields1[idx]?.value}
                  />
                </div>
                {idx !== 0 && (
                  <Delete
                    className="cursor-pointer"
                    onClick={() => deleteAdoptionRow(idx, "timeline")}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="analysis-dropdown-wrapper horizontal">
          <div className="dropdown-title flex w-[90%] items-center justify-between text-sm not-italic text-[#111D43]">
            <p>Decision making process</p>
            <p
              className="underline hover:opacity-70 cursor-pointer"
              onClick={() => addAdoptionRow("decisionMakingProcess")}
            >
              Add more
            </p>
          </div>
          {editedDetails?.decisionMakingProcess?.map((item, idx) => {
            return (
              <div className="dropdown-items flex w-[90%]" key={idx}>
                <div className="dropdown-inner-item">
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    variant="standard"
                    label={`Decision making Process heading ${idx + 1}`}
                    placeholder=""
                    maxLength={70}
                    value={item.heading}
                    handleOnChangeEvent={(e) => {
                      handleInputChange(
                        e.target.value,
                        idx,
                        "heading",
                        "decisionMakingProcess"
                      );
                    }}
                    error={errorFields2[idx]?.heading}
                    helperText={errorFields2[idx]?.heading}
                  />
                </div>
                <div className="dropdown-inner2-item">
                  <PTInput
                    labelStyle={AnalysisLabelStyle}
                    variant="standard"
                    label={`Decision making Process text ${idx + 1}`}
                    placeholder=""
                    multiline
                    maxRows={4}
                    maxLength={255}
                    value={item.value}
                    handleOnChangeEvent={(e) => {
                      handleInputChange(
                        e.target.value,
                        idx,
                        "value",
                        "decisionMakingProcess"
                      );
                    }}
                    error={errorFields2[idx]?.value}
                    helperText={errorFields2[idx]?.value}
                  />
                </div>
                {idx !== 0 && (
                  <Delete
                    className="cursor-pointer"
                    onClick={() =>
                      deleteAdoptionRow(idx, "decisionMakingProcess")
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-end bottom-btn-wrapper w-[95%]">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails({
                ...PROJECT_ADOPTION_PAYLOAD,
                decisionMakingProcess: cloneDeepArray(
                  adoptionData?.projectDecisionProcess?.length
                    ? adoptionData?.projectDecisionProcess
                    : [{ heading: "", value: "" }]
                ),
                timeline: cloneDeepArray(
                  adoptionData?.projectTimeline?.length
                    ? adoptionData?.projectTimeline
                    : [{ heading: "", value: "" }]
                ),
              });
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() =>
              !checkValidation() === true && handleOpenModal()
            }
          />
        </div>
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
};

export default Adoption;
