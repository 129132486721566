import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import closeIcon from '../../../../../assets/Icons/ic-cross.svg';
import PTButton from '../../../../../components/GenericComponents/PTButton';
import defaultProfile from '../../../../../assets/Icons/default-profile.svg';
import { ChangePasswordFooter, EditTagModal } from '../../../../../constants/General/styles';
import Tags from '../../../Tags';
import { updateInvestorForYouTags } from '../../../../utils/middleware/investorForYouTab';
import '../forYouTab.scss';

export default function Proptechcard(props) {
  // Props
  const { investorId, proptechId, logo, title, publishedAt, onCardClick, checked, onChecked, editTags, selectedTags, meetings } = props

  // State
  const [open, setOpen] = useState(false)
  const [editedTags, setEditedTags] = useState(selectedTags);
  const [limitReached, setLimitReached] = useState(selectedTags?.length >= 5);

  // Store
  const tags = useSelector(state => state.adminDropdowns.tags)

  const handleUpdate = () => {
    updateInvestorForYouTags(investorId, {
      proptechCompanyDetailsId: proptechId,
      tagIds: editedTags.map(a => a.id)
    }, clearData)
  }

  const clearData = () => {
    setOpen(false)
    setEditedTags(null)
    setLimitReached(false)
  }

  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list?.length; i++) {
      if (list[i]?.id === obj?.id) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading ${checked && 'bg-[#E7E8EC]'}`}>
          <div className='flex box-border items-center flex-wrap'>
            <div className='image-container' onClick={onCardClick}>
              <img
                src={logo || defaultProfile}
                className="logo object-contain"
                alt="Profile"
              />
            </div>
            <div className='grow'>
              <div className='flex justify-between'>
                <span className='title' onClick={onCardClick}>{title}</span>
              </div>
              <div className='w-full flex'>
                <div className="label w-1/2">
                  <span className='header'>Published</span>
                  <span className='value'>{publishedAt ? moment.tz(publishedAt, "Australia/Sydney").format('DD MMM YYYY') : '-'}</span>
                </div>
                <div className="label mr-1">
                  <span className='header'>Meetings</span>
                  <span className='value'>{meetings || 0}</span>
                </div>
              </div>
            </div>
            <div>
              {editTags &&
                <IconButton color="primary" onClick={(e) => setOpen(true)}>
                  <EditIcon />
                </IconButton>}
              <Checkbox style={{
                transform: "scale(1.2)",
              }} className="!p-0 admin-form-checkbox" id="remember" checked={checked} onChange={onChecked} />
            </div>
          </div>
        </div>
      </div>


      <Modal
        open={open}
        onClose={clearData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Box sx={EditTagModal}>
          <IconButton className="close-btn" onClick={clearData}>
            <img src={closeIcon} alt="Icon" />
          </IconButton>
          <div className="modal-outer relative">
            <div className="changepassword-modal-heading">
              <h2>Edit Tags</h2>
            </div>
            <div className="change-password-modal">
              <div className="justify-start flex-col">
                <Tags
                  getOptionDisabled={(option) => limitReached && !containsObject(option, editedTags)}
                  value={editedTags !== null ? editedTags : selectedTags}
                  onChange={(e, value) => {
                    setEditedTags(value)
                    setLimitReached(value.length >= 5)
                  }}
                  options={tags}
                />
              </div>
            </div>
          </div>
          <Typography id="modal-modal-description" sx={ChangePasswordFooter}>
            <PTButton
              label="Cancel"
              className="modal-cancel-button"
              handleOnClickEvent={clearData} />
            <PTButton
              label="Update"
              className="modal-change-button"
              handleOnClickEvent={handleUpdate}
            />
          </Typography>
        </Box>
      </Modal >
    </>
  )
}
