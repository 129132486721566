export function investorsProfileDTO(data) {
  return {
    id: data?.id || 0,
    isProfileCompleted: data?.isProfileCompleted !== null ? data?.isProfileCompleted : false,
    companyInfo: data?.companyInfo || null,
    companyLogo: data?.companyLogo || null,
    websiteURL: data?.websiteURL || null,
    countryId: data?.countryId || null,
    regionId: data?.regionId || null,
    investmentRoundId: data?.investmentRoundId || 1,
    revenueRange: data?.revenueRange || 0,
    investmentRange: data?.investmentRange || 0,
    metric1: data?.metric1 || null,
    metric1Text: data?.metric1Text || null,
    metric2: data?.metric2 || null,
    metric2Text: data?.metric2Text || null,
    metric3: data?.metric3 || null,
    metric3Text: data?.metric3Text || null,
    notes: data?.notes || null,
    isArchived: data?.isArchived !== null ? data.isArchived : false,
    status: data?.status !== null ? data.status : false,
    lastLoginAt: data?.lastLoginAt || null,
    watchlistCount: data?.watchlistCount || null,
    createdAt: data?.createdAt || null,
    updatedAt: data?.updatedAt || null,
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || '',
      regionId: data?.country?.regionId || null,
    },
    vcCompany: {
      id: data?.vcCompany?.id || 0,
      name: data?.vcCompany?.name || '',
    },
    region: {
      id: data?.region?.id || 0,
      name: data?.region?.name || '',
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || '',
    },
    keyBizModelMetric1: {
      id: data?.keyBizModelMetric1?.id || 0,
      name: data?.keyBizModelMetric1?.name || '',
    },
    keyBizModelMetric2: {
      id: data?.keyBizModelMetric2?.id || 0,
      name: data?.keyBizModelMetric2?.name || '',
    },
    keyBizModelMetric3: {
      id: data?.keyBizModelMetric3?.id || 0,
      name: data?.keyBizModelMetric3?.name || '',
    },
  }
}