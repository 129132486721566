export function buyerListDTO(data) {
  let buyerList = [];
  if (data && data.length) {
    buyerList = data.map((buyer) => {
      return {
        id: buyer?.id || 0,
        companyLogo: buyer?.companyLogo || "",
        domain: buyer?.domain || null,
        lastLoginAt: buyer?.lastLoginAt || null,
        createdAt: buyer?.createdAt || null,
        updatedAt: buyer?.updatedAt || null,
        totalMeetingCount: buyer?.totalMeetingCount || 0,
        cancelledMeetingCount: buyer?.cancelledMeetingCount || 0,
        status: buyer?.status !== null ? buyer.status : 0,
        watchlistCount:
          buyer?.watchlistCount !== null ? buyer.watchlistCount : 0,
        isArchived: buyer?.isArchived !== null ? buyer.isArchived : false,
        planExpiryDate: buyer?.planExpiryDate || "",
        rffPlanId: buyer?.rffPlanId || "",
        rffPlanDetails: {
          freeNoOfMeetings: buyer?.rffPlanDetails?.freeNoOfMeetings
            ? buyer.rffPlanDetails.freeNoOfMeetings
            : 0,
          durationOfDays: buyer?.rffPlanDetails?.durationOfDays
            ? buyer.rffPlanDetails.durationOfDays
            : "",
          name: buyer?.rffPlanDetails?.name ? buyer.rffPlanDetails.name : "",
          price: buyer?.rffPlanDetails?.price ? buyer.rffPlanDetails.price : "",
        },
        rffCompany:
          buyer?.rffCompany !== null
            ? {
                id: buyer?.rffCompany?.id || 0,
                name: buyer?.rffCompany?.name || null,
                rffUsers:
                  buyer?.rffCompany?.rffUsers?.length !== 0
                    ? buyer?.rffCompany?.rffUsers.map((user) => {
                        return {
                          id: user?.id || 0,
                          name: user?.name || null,
                          email: user?.email || null,
                        };
                      })
                    : [],
              }
            : null,
        rffUser:
          buyer?.rffUser !== null
            ? {
                id: buyer?.rffUser?.id || 0,
                primaryUserName: buyer?.rffUser?.primaryUserName || null,
                email: buyer?.rffUser?.email || null,
              }
            : null,
        country:
          buyer?.country !== null
            ? {
                id: buyer?.country?.id || 0,
                name: buyer?.country?.name || null,
                regionId: buyer?.country?.regionId || 0,
              }
            : null,
        region:
          buyer?.region !== null
            ? {
                id: buyer?.region?.id || 0,
                name: buyer?.region?.name || null,
              }
            : null,
      };
    });
  }

  return buyerList;
}

export function buyerProfileTabDTO(data) {
  return {
    id: data?.id || 0,
    companyInfo: data?.companyInfo || null,
    companyLogo: data?.companyLogo || null,
    websiteURL: data?.websiteURL || null,
    countryId: data?.countryId || null,
    regionId: data?.regionId || null,
    investmentRoundId: data?.investmentRoundId || 1,
    revenueRange: data?.revenueRange || 0,
    investmentRange: data?.investmentRange || 0,
    metric1: data?.metric1 || null,
    metric1Text: data?.metric1Text || null,
    metric2: data?.metric2 || null,
    metric2Text: data?.metric2Text || null,
    metric3: data?.metric3 || null,
    metric3Text: data?.metric3Text || null,
    notes: data?.notes || null,
    isArchived: data?.isArchived !== null ? data.isArchived : false,
    status: data?.status !== null ? data.status : false,
    lastLoginAt: data?.lastLoginAt || null,
    watchlistCount: data?.watchlistCount || null,
    createdAt: data?.createdAt || null,
    updatedAt: data?.updatedAt || null,
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || "",
      regionId: data?.country?.regionId || null,
    },
    rffCompany: {
      id: data?.rffCompany?.id || 0,
      name: data?.rffCompany?.name || "",
    },
    region: {
      id: data?.region?.id || 0,
      name: data?.region?.name || "",
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || "",
    },
    keyBizModelMetric1: {
      id: data?.keyBizModelMetric1?.id || 0,
      name: data?.keyBizModelMetric1?.name || "",
    },
    keyBizModelMetric2: {
      id: data?.keyBizModelMetric2?.id || 0,
      name: data?.keyBizModelMetric2?.name || "",
    },
    keyBizModelMetric3: {
      id: data?.keyBizModelMetric3?.id || 0,
      name: data?.keyBizModelMetric3?.name || "",
    },
  };
}

export function buyerProfileTabTagsListDTO(data) {
  let tags = [];
  if (data && data.length) {
    tags = data.map((datum) => {
      return {
        id: datum?.tag?.id || 0,
        name: datum?.tag?.name || "",
      };
    });
  }

  return tags;
}

export function buyerProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || "",
        publishedAt: datum?.publishedAt || "",
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany
          ? {
              id: datum?.proptechCompany?.id || 0,
              name: datum?.proptechCompany?.name || "",
            }
          : null,
        rffProptechForYou:
          datum?.rffProptechForYou?.length !== 0
            ? datum?.rffProptechForYou.map((user) => {
                return {
                  id: user?.id || 0,
                  status: user?.status !== null ? user.status : false,
                };
              })
            : [],
      };
    });
  }

  return proptechs;
}

export function buyerForYouProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || "",
        publishedAt: datum?.publishedAt || "",
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany
          ? {
              id: datum?.proptechCompany?.id || 0,
              name: datum?.proptechCompany?.name || "",
            }
          : null,
        rffProptechForYou:
          datum?.rffProptechForYou?.length !== 0
            ? datum?.rffProptechForYou.map((user) => {
                return {
                  id: user?.id || 0,
                  status: user?.status !== null ? user.status : false,
                  rffProptechForYouTag:
                    user?.rffProptechForYouTag?.length !== 0
                      ? user.rffProptechForYouTag.map((tag) => {
                          return {
                            id: tag?.tag?.id || 0,
                            name: tag?.tag?.name || "",
                          };
                        })
                      : [],
                };
              })
            : [],
      };
    });
  }

  return proptechs;
}

export function buyerHomeProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || "",
        publishedAt: datum?.publishedAt || "",
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany
          ? {
              id: datum?.proptechCompany?.id || 0,
              name: datum?.proptechCompany?.name || "",
            }
          : null,
        rffProptechHome:
          datum?.rffProptechHome?.length !== 0
            ? datum?.rffProptechHome.map((user) => {
                return {
                  id: user?.id || 0,
                  status: user?.status !== null ? user.status : false,
                  // rffProptechHomeTag: user?.rffProptechHomeTag?.length !== 0 ? user.rffProptechHomeTag.map(tag => {
                  //   return {
                  //     id: tag?.tag?.id || 0,
                  //     name: tag?.tag?.name || '',
                  //   }
                  // }) : [],
                };
              })
            : [],
      };
    });
  }

  return proptechs;
}

export function buyerWatchlistProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || "",
        proptechCompanyDetails: datum?.proptechCompanyDetails
          ? {
              id: datum?.proptechCompanyDetails?.id || 0,
              companyLogo: datum?.proptechCompanyDetails?.companyLogo || "",
              elevatorPitchAdmin:
                datum?.proptechCompanyDetails?.elevatorPitchAdmin || "",
              founderPitchVideo:
                datum?.proptechCompanyDetails?.founderPitchVideo || "",
              publishedAt: datum?.proptechCompanyDetails?.publishedAt || "",
              country: {
                id: datum?.proptechCompanyDetails?.country?.id || 0,
                name: datum?.proptechCompanyDetails?.country?.name || "",
              },
              investmentRange: {
                id: datum?.proptechCompanyDetails?.investmentRange?.id || 0,
                name:
                  datum?.proptechCompanyDetails?.investmentRange?.name || "",
              },
              investmentRound: {
                id: datum?.proptechCompanyDetails?.investmentRound?.id || 0,
                name:
                  datum?.proptechCompanyDetails?.investmentRound?.name || "",
              },
              language: {
                id: datum?.proptechCompanyDetails?.language?.id || 0,
                name: datum?.proptechCompanyDetails?.language?.name || "",
              },
              proptechCompany: {
                id: datum?.proptechCompanyDetails?.proptechCompany?.id || 0,
                name:
                  datum?.proptechCompanyDetails?.proptechCompany?.name || "",
              },
              revenueRange: {
                id: datum?.proptechCompanyDetails?.revenueRange?.id || 0,
                name: datum?.proptechCompanyDetails?.revenueRange?.name || "",
              },
            }
          : null,
      };
    });
  }

  return proptechs;
}

export function buyerInteractionProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || "",
        elevatorPitchAdmin: datum?.elevatorPitchAdmin || "",
        founderPitchVideo: datum?.founderPitchVideo || "",
        meetingCount: datum?.meetingCount || 0,
        createdAt: datum?.createdAt || "",
        publishedAt: datum?.publishedAt || "",
        updatedAt: datum?.updatedAt || "",
        archivedAt: datum?.archivedAt || "",
        lastMeetingTime: datum?.lastMeetingTime || "",
        proptechCompany: {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        },
        country: {
          id: datum?.country?.id || 0,
          name: datum?.country?.name || "",
        },
        investmentRound: {
          id: datum?.investmentRound?.id || 0,
          name: datum?.investmentRound?.name || "",
        },
        language: {
          id: datum?.language?.id || 0,
          name: datum?.language?.name || "",
        },
        revenueRange: {
          id: datum?.revenueRange?.id || 0,
          name: datum?.revenueRange?.name || "",
        },
      };
    });
  }

  return proptechs;
}
