import React, { useState } from "react";
import { useFormik } from 'formik';
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import PTCStepper from '../../../components/GenericComponents/PTCStepper';
import PTInput from "../../../components/GenericComponents/PTInput";
import PTButton from "../../../components/GenericComponents/PTButton";
import { Steps } from "../../constants/Stepper";
import { LabelStyle, SnapshotQuestions1, TooltipText } from "../../constants/General";
import { sendHeading1Data } from "../../../utils/middleware/Heading1";
import InfoIcon from "../../../components/InfoIcon";

function Heading1({ setActiveStep }) {
  // Store
  const { companyDetails } = useSelector(state => state.proptechUser);

  // State
  const [handleInfo, setHandleInfo] = useState([])

  const initialValues = {
    technologyProduct: companyDetails?.technologyProduct,
    marketOpportunity: companyDetails?.marketOpportunity,
    futureGoals: companyDetails?.futureGoals,
  };

  // Handle form values
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      technologyProduct: Yup.string().trim().min(100, "Must be 100 characters or more").max(400, "Must be 400 characters or less").required('Required'),
      marketOpportunity: Yup.string().trim().min(100, "Must be 100 characters or more").max(400, "Must be 400 characters or less").required('Required'),
      futureGoals: Yup.string().trim().min(50, "Must be 50 characters or more").max(400, "Must be 400 characters or less").required('Required'),
    }),
    onSubmit: (values, { resetForm }) => {
      if (companyDetails?.isPrimaryUser) {
        sendHeading1Data(
          Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== '')),
          companyDetails,
          setActiveStep,
          resetForm)
      } else { setActiveStep(2) }
    }
  });

  return (
    <div className="p-16 heading1-block-wrapper flex flex-col justify-between rounded-3xl bg-[#FFFFFF] right-step-wrapper boxshadow-box">
      <form onSubmit={formik.handleSubmit}>
        <div className="font-light text-4xl main-title not-italic">Company overview 1</div>
        <section>
          <div className="md:mt-14 mt-10">
            <PTInput
              className="has-icon-field"
              labelStyle={LabelStyle}
              variant="standard"
              name="technologyProduct"
              shrink
              label={SnapshotQuestions1.technologyProduct}
              fullWidth
              multiline
              maxRows={4}
              maxLength={400}
              disabled={!companyDetails?.isPrimaryUser}
              icon={
                <InfoIcon
                  title='technologyProduct'
                  label={TooltipText.technologyProduct}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  className="info-icon"
                />
              }
              error={formik.touched.technologyProduct && formik.errors.technologyProduct}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.technologyProduct} />
            <div className="mt-2 flex justify-between items-center">
              <span className='font-normal text-xs text-left tracking-wide text-[#d32f2f]'>{formik.touched.technologyProduct && formik.errors.technologyProduct}</span>
              <span className='text-xs font-semibold' style={{ color: 'rgba(17, 29, 67, 0.4)' }}>{formik.values.technologyProduct?.trim()?.length}/<span>400</span> CHARACTERS</span>
            </div>
          </div>
          <div className="md:mt-14 mt-10">
            <PTInput
              className="has-icon-field"
              labelStyle={LabelStyle}
              variant="standard"
              name="marketOpportunity"
              shrink
              label={SnapshotQuestions1.marketOpportunity}
              fullWidth
              multiline
              maxRows={4}
              maxLength={400}
              disabled={!companyDetails?.isPrimaryUser}
              icon={
                <InfoIcon
                  title='marketOpportunity'
                  label={TooltipText.marketOpportunity}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  className="info-icon"
                />
              }
              error={formik.touched.marketOpportunity && formik.errors.marketOpportunity}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.marketOpportunity} />
            <div className="mt-2 flex justify-between items-center">
              <span className='font-normal text-xs text-left tracking-wide text-[#d32f2f]'>{formik.touched.marketOpportunity && formik.errors.marketOpportunity}</span>
              <span className='text-xs font-semibold' style={{ color: 'rgba(17, 29, 67, 0.4)' }}>{formik.values.marketOpportunity?.trim()?.length}/<span>400</span> CHARACTERS</span>
            </div>
          </div>
          <div className="md:mt-14 mt-10">
            <PTInput
              className="has-icon-field"
              labelStyle={LabelStyle}
              variant="standard"
              name="futureGoals"
              shrink
              label={SnapshotQuestions1.futureGoals}
              fullWidth
              multiline
              maxRows={4}
              maxLength={400}
              disabled={!companyDetails?.isPrimaryUser}
              icon={
                <InfoIcon
                  title='futureGoals'
                  label={TooltipText.futureGoals}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                  className="info-icon"
                />
              }
              error={formik.touched.futureGoals && formik.errors.futureGoals}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.futureGoals} />
            <div className="mt-2 flex justify-between items-center">
              <span className='font-normal text-xs text-left tracking-wide text-[#d32f2f]'>{formik.touched.futureGoals && formik.errors.futureGoals}</span>
              <span className='text-xs font-semibold' style={{ color: 'rgba(17, 29, 67, 0.4)' }}>{formik.values.futureGoals?.trim()?.length}/<span>400</span> CHARACTERS</span>
            </div>
          </div>
        </section>
      </form>
      <div className="mt-5 proptech-stepper">
        <div className="sm:mb-10 mb-5 flex justify-end">
          <PTButton variant="contained" label={<span className="!font-semibold !h-5 !text-sm !leading-5 !text-center !not-italic !w-20 !text-[#111D43]">Back</span>} className='!h-12 !w-32 !mr-5 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]' handleOnClickEvent={() => setActiveStep(0)} />
          <PTButton variant="contained" label="Next" type="submit" className='!h-12 !w-32 !rounded-xl !border-solid !bg-[#111D43]' handleOnClickEvent={(e) => companyDetails?.isPrimaryUser ? formik.submitForm(e) : setActiveStep(2)} />
        </div>
        <PTCStepper
          steps={Steps}
          activeStep={1}
          setStepper={(tab) => {
            companyDetails?.signupProgress >= tab &&
              setActiveStep(tab)
          }} />
      </div>
    </div>
  )
}

export default Heading1;
