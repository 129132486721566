import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function Chart(props) {
  const { items, labels, label, minimumValue, step, maximumValue, color="#FF4100", bgColor="rgba(255, 65, 0, 0.15)" } = props;
  const data = {
    labels: labels ? labels : ['Data 1', 'Data 2', 'Data 3', 'Data 4', 'Data 5', 'Data 6'],
    datasets: [
      {
        label: label || '',
        data: items ? items : [2.7, 2.5, 4.5, 0.7, 1.6, 4.3],
        borderWidth: 1.5,
        pointBorderColor: '#ffffff',
        pointBorderWidth: 0,
        backgroundColor: bgColor,
        borderColor: color,
        pointRadius: 0,
        pointBackgroundColor: "#000000",
      },
    ],
  };
  return <Radar
    data={data}
    options={{
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          ticks: {
            display: false,
            min: minimumValue || 1,
            max: 10,
            stepSize: step || 1,
            showLabelBackdrop: false,
            backdropColor: color
          },
          pointLabels: {
            font: {
              size: 12,
              color: '#111D43',
              family: "Poppins",
            }
          },
          max: maximumValue || undefined,
          min: minimumValue || 0,
          angleLines: {
            lineWidth: 0.5,
            color: "#111D43"
          },
          grid: {
            circular: false,
            lineWidth: 0
          }
        },
      },
      plugins: {
        legend: {
          display: false
        },
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
        }
      },
    }} />;
}
