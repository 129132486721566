import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import PTButton from '../../../../components/GenericComponents/PTButton';
import { AnalysisLabelStyle, } from '../../../../proptech/constants/General';
import Ratings from '../../../../components/GenericComponents/Ratings';
import DropdownColumn from '../../DropdownColumn';
import { updateRaiseDetails } from '../../../utils/middleware/raiseTab';
import Loader from '../../../../components/Loading';
import PTInput from '../../../../components/GenericComponents/PTInput';
import ConfirmModal from '../../ConfirmModal';
import Chart from '../../Chart';
import { RAISE_TAB_ERRORS, RAISE_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { trimQuery } from '../../../../utils/utility/utility';
import { RATING_CHART } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function RaiseTab(props) {
  const { setUnsaveChange } = props

  // Store
  const raiseTabData = useSelector(state => state.adminAnalysisTab.raiseTabData)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { investmentRounds, investmentRanges, investmentStages, investmentTypes, valuationRanges, noOfRounds, investorStyle, investorTraits } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(RAISE_TAB_PAYLOAD);
  const [errors, setErrors] = useState(RAISE_TAB_ERRORS);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : raiseTabData.hasOwnProperty(error) ? !raiseTabData?.[error] : !raiseTabData?.proptechCompanyDetails?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = raiseTabData?.[key] || raiseTabData?.proptechCompanyDetails?.[key]));

    handleCloseModal()
    try {
      await updateRaiseDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null)),
        })
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const totalStartCount = () => {
    return (editedDetails?.team !== null ? editedDetails?.team : raiseTabData?.team) +
      (editedDetails?.model !== null ? editedDetails?.model : raiseTabData?.model) +
      (editedDetails?.technology !== null ? editedDetails?.technology : raiseTabData?.technology) +
      (editedDetails?.timing !== null ? editedDetails?.timing : raiseTabData?.timing) +
      (editedDetails?.network !== null ? editedDetails?.network : raiseTabData?.network) +
      (editedDetails?.defensibility !== null ? editedDetails?.defensibility : raiseTabData?.defensibility) || 0
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='flex flex-col justify-between'>
        <div className='analysis-rating-chart-block flex'>
          <div className='rating-block'>
            <Ratings className='flex justify-between rating-items' label="Team" value={editedDetails?.team !== null ? editedDetails?.team : raiseTabData?.team} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, team: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, team: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Model" value={editedDetails?.model !== null ? editedDetails?.model : raiseTabData?.model} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, model: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, model: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Technology" value={editedDetails?.technology !== null ? editedDetails?.technology : raiseTabData?.technology} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, technology: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, technology: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Timing" value={editedDetails?.timing !== null ? editedDetails?.timing : raiseTabData?.timing} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, timing: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, timing: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Network" value={editedDetails?.network !== null ? editedDetails?.network : raiseTabData?.network} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, network: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, network: 0 })} />
            <Ratings className='flex justify-between last-rating-items' label="Defensibility" value={editedDetails?.defensibility !== null ? editedDetails?.defensibility : raiseTabData?.defensibility} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, defensibility: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, defensibility: 0 })}
              total={<p className={`text-xs text-right font-semibold uppercase ${totalStartCount() >= RATING_CHART.limitForError ? 'text-error' : 'text-light-grey'}`}>
                {totalStartCount()}/<span>{RATING_CHART.maxLimit}</span></p>} />
          </div>
          <div className='chart-block'>
            <div className='box-border bg-[#F3F3F5]'>
              <Chart labels={["Team", "Model", "Technology", "Timing", "Network", "Defensibility"]} items={[
                editedDetails?.team !== null ? editedDetails?.team : raiseTabData?.team,
                editedDetails?.model !== null ? editedDetails?.model : raiseTabData?.model,
                editedDetails?.technology !== null ? editedDetails?.technology : raiseTabData?.technology,
                editedDetails?.timing !== null ? editedDetails?.timing : raiseTabData?.timing,
                editedDetails?.network !== null ? editedDetails?.network : raiseTabData?.network,
                editedDetails?.defensibility !== null ? editedDetails?.defensibility : raiseTabData?.defensibility
              ]}
                step={2}
                maximumValue={10} />
            </div>
          </div>
        </div>
        <div className='flex justify-between analysis-dropdown-wrapper'>
          <DropdownColumn
            header="Numbers"
            dropdown1Label="Invest Round"
            dropdown1Options={investmentRounds}
            dropdown1Value={editedDetails?.investmentRoundId || raiseTabData?.proptechCompanyDetails?.investmentRoundId}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, investmentRoundId: e.target.value })}
            dropdown1Error={errors.investmentRoundId}
            dropdown1HelperText={errors.investmentRoundId && "Invest Round Required."}
            dropdown2Label="invest Range"
            dropdown2Options={investmentRanges}
            dropdown2Value={editedDetails?.investmentRangeId || raiseTabData?.proptechCompanyDetails?.investmentRangeId}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, investmentRangeId: e.target.value })}
            dropdown2Error={errors.investmentRangeId}
            dropdown2HelperText={errors.investmentRangeId && "Invest Range Required."}
            dropdown3Label="Val Range"
            dropdown3Options={valuationRanges}
            dropdown3Value={editedDetails?.valuationRangeId || raiseTabData?.proptechCompanyDetails?.valuationRangeId}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, valuationRangeId: e.target.value })}
            dropdown3Error={errors.valuationRangeId}
            dropdown3HelperText={errors.valuationRangeId && "Val Range Required."}
            children={
              <PTInput
              labelStyle={AnalysisLabelStyle}
              className='custom-dropdown-icon'
              variant="standard"
              label="Investment Stages"
              InputPropsStyle={{ fontSize: '12px' }}
              fullWidth
              select
              selectChildren={investmentStages.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
              placeholder=''
              value={editedDetails?.investmentStageId || raiseTabData?.proptechCompanyDetails?.investmentStageId}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, investmentStageId: e.target.value })}
              error={errors.investmentStageId}
              helperText={errors.investmentStageId && "Invest Type Required."} 
              /> 
            }
          />
          <div className="dropdown-items">
            <div className='dropdown-title not-italic text-[#111D43]'>Round Offering</div>
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className='custom-dropdown-icon'
              variant="standard"
              label="Invest Type"
              InputPropsStyle={{ fontSize: '12px' }}
              fullWidth
              select
              selectChildren={investmentTypes.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
              placeholder=''
              value={editedDetails?.investmentTypeId || raiseTabData?.proptechCompanyDetails?.investmentTypeId}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, investmentTypeId: e.target.value })}
              error={errors.investmentTypeId}
              helperText={errors.investmentTypeId && "Invest Type Required."} />
            <PTInput
              labelStyle={AnalysisLabelStyle}
              className='custom-dropdown-icon'
              variant="standard"
              label="No. of Rounds"
              InputPropsStyle={{ fontSize: '12px' }}
              fullWidth
              select
              selectChildren={noOfRounds.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
              placeholder=''
              value={editedDetails?.noOfRoundsId || raiseTabData?.proptechCompanyDetails?.noOfRoundsId}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, noOfRoundsId: e.target.value })}
              error={errors.noOfRoundsId}
              helperText={errors.noOfRoundsId && "No. of Rounds Required."} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Raise Date"
                className='custom-date-picker'
                views={['year', 'month']}
                inputFormat="MM/yyyy"
                value={editedDetails?.raiseDate !== null ? editedDetails?.raiseDate : raiseTabData?.proptechCompanyDetails?.raiseDate ? raiseTabData?.proptechCompanyDetails?.raiseDate : ''}
                onChange={(date) => setEditedDetails({ ...editedDetails, raiseDate: moment.tz(date, "Australia/Sydney").format('YYYY-MM-DD') })}
                renderInput={(params) => <TextField fullWidth {...params} value={editedDetails?.raiseDate !== null ? editedDetails?.raiseDate : raiseTabData?.proptechCompanyDetails?.raiseDate ? raiseTabData?.proptechCompanyDetails?.raiseDate : ''} variant="standard" InputProps={{ ...params.InputProps, style: { fontSize: '12px' } }} InputLabelProps={{ ...params.InputLabelProps, style: AnalysisLabelStyle }} sx={{
                  svg: { color: '#111D43' },
                  input: { color: '#111D43' },
                  label: { color: '#111D43' }
                }}
                  error={errors.raiseDate}
                  helperText={errors.raiseDate && "Raise Date Required."} />}
              />
            </LocalizationProvider>
          </div>
          <DropdownColumn
            header="Alignments"
            dropdown1Label="Investor Style 1"
            dropdown1Options={investorStyle}
            dropdown1Value={editedDetails?.alignment1 || raiseTabData?.alignment1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, alignment1: e.target.value })}
            dropdown1Error={errors.alignment1}
            dropdown1HelperText={errors.alignment1 && "Investor Style 1 Required."}
            dropdown2Label="Investor Trait 1"
            dropdown2Options={investorTraits}
            dropdown2Value={editedDetails?.alignment2 || raiseTabData?.alignment2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, alignment2: e.target.value })}
            dropdown2Error={errors.alignment2}
            dropdown2HelperText={errors.alignment2 && "Investor Trait 1 Required."}
            dropdown3Label="Investor Trait 2"
            dropdown3Options={investorTraits}
            dropdown3Value={editedDetails?.alignment3 || raiseTabData?.alignment3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, alignment3: e.target.value })}
            dropdown3Error={errors.alignment3}
            dropdown3HelperText={errors.alignment3 && "Investor Trait 2 Required."}
          />
        </div>
        <div className='w-full flex justify-end bottom-btn-wrapper'>
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>} type="submit"
            className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => setEditedDetails(RAISE_TAB_PAYLOAD)} />
          <PTButton variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>} type="submit"
            className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()} />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}

      </div>
    </>
  )
}
