export default function adminVentureScout(
  initialState = {
    isLoading: false,
    ventureScouts: [],
    isVentureScoutSelected: false,
    selectedVentureScout: null,
    activeSort: 'createdAt',
    sortOrder: 'DESC',
    search: '',
    activeTab: 0,
    profileTabData: null,
    interactionFilter: 'total',
    interactionsProptechs: [],
  },
  action
) {
  switch (action.type) {
    case 'VENTURE_SCOUTS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'VENTURE_SCOUTS_LIST_SET':
      return {
        ...initialState,
        ventureScouts: action.payload,
        isLoading: false,
      };

    case 'SELECTED_VENTURE_SCOUT_SET':
      return {
        ...initialState,
        selectedVentureScout: action.payload,
      };

    case 'SET_IS_SELECTED_VENTURE_SCOUT':
      return {
        ...initialState,
        isVentureScoutSelected: action.payload,
      };

    case 'SET_VENTURE_SCOUT_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_VENTURE_SCOUT_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_VENTURE_SCOUT_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_VENTURE_SCOUT_ACTIVE_TAB':
      return {
        ...initialState,
        activeTab: action.payload,
      };


    case 'SET_VENTURE_SCOUT_PROFILE_TAB_DATA':
      return {
        ...initialState,
        profileTabData: action.payload,
      };

    case 'SET_ADMIN_VENTURE_SCOUT_INTERACTIONS_FILTER':
      return {
        ...initialState,
        interactionFilter: action.payload,
      };

    case 'SET_ADMIN_VENTURE_SCOUT_INTERACTIONS_PROPTECHS':
      return {
        ...initialState,
        interactionsProptechs: action.payload,
      };

    case 'RESET_VENTURE_SCOUTS':
      return {
        ...initialState,
        isLoading: false,
        ventureScouts: [],
        isVentureScoutSelected: false,
        selectedVentureScout: null,
        activeSort: 'createdAt',
        sortOrder: 'DESC',
        search: '',
        activeTab: 0,
        profileTabData: null,
        interactionFilter: 'total',
        interactionsProptechs: [],
      };

    default:
      return initialState;
  }
}
