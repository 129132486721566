export function ventureScoutListDTO(data) {
  let ventureScoutList = [];
  if (data && data.length) {
    ventureScoutList = data.map((ventureScout) => {
      return {
        id: ventureScout?.id || 0,
        name: ventureScout?.name || '',
        companyName: ventureScout?.companyName || '',
        email: ventureScout?.email || null,
        profileImage: ventureScout?.profileImage || '',
        linkedInURL: ventureScout?.linkedInURL || null,
        code: ventureScout?.code || null,
        status: ventureScout?.status !== null ? ventureScout.status : 0,
        onboardCommission: ventureScout?.onboardCommission !== null ? ventureScout.onboardCommission : 0,
        meetingCommission: ventureScout?.meetingCommission !== null ? ventureScout.meetingCommission : 0,
        onboardedProptechs: ventureScout?.onboardedProptechs !== null ? ventureScout.onboardedProptechs : 0,
        rejectedProptechs: ventureScout?.rejectedProptechs !== null ? ventureScout.rejectedProptechs : 0,
        lastLoginAt: ventureScout?.lastLoginAt || null,
        createdAt: ventureScout?.createdAt || null,
        updatedAt: ventureScout?.updatedAt || null,
        country: ventureScout?.country !== null ? {
          id: ventureScout?.country?.id || 0,
          name: ventureScout?.country?.name || null,
        } : null,
        region: ventureScout?.region !== null ? {
          id: ventureScout?.region?.id || 0,
          name: ventureScout?.region?.name || null,
        } : null,
      }
    })
  }

  return ventureScoutList;
}

export function ventureScoutProfileTabDTO(data) {
  return {
    id: data?.id || 0,
    companyName: data?.companyName || null,
    profileImage: data?.profileImage || null,
    phone: data?.phone || null,
    linkedInURL: data?.linkedInURL || null,
    code: data?.code || null,
    countryId: data?.countryId || null,
    onboardCommission: data?.onboardCommission !== null ? data?.onboardCommission : null,
    meetingCommission: data?.meetingCommission !== null ? data?.meetingCommission : null,
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || '',
    },
  }
}
