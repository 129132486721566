import { ADMIN_TECH_BUYERS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { getTagsData } from "../dropdowns"
import { buyerHomeProptechsListDTO } from "../dtos/adminTechBuyer"

export const loadTechBuyerHomeData = (id, adminTechBuyer, adminDropdowns) => {
  getTechBuyerAllProptechs(id, adminTechBuyer.searchProptech)
  getTechBuyerHomeProptechs(id)
  loadTechBuyerHomeDropdowns(adminDropdowns.tags)
}

export const getTechBuyerAllProptechs = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  }

  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS + `/${id}/proptechs/home`, { params })
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetProptechsData, buyerHomeProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      console.log(err)
      handleError(err)
    })
}

export const getTechBuyerHomeProptechs = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS + `/${id}/home`)
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetHomeProptechsData, buyerHomeProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err)
    })
}

export const updateTechBuyerHomeProptechs = (id, data, searchProptech, adminDropdowns) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().put(ADMIN_TECH_BUYERS + `/${id}/home`, data)
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      loadTechBuyerHomeData(id, { searchProptech }, adminDropdowns)
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      console.log(err)
      handleError(err)
    })
}

export const updateTechBuyerHomeTags = (id, data, clearData) => {
  ADMIN_API().put(ADMIN_TECH_BUYERS + `/${id}/forYouTags`, data)
    .then(res => {
      handleSuccess(res)
      getTechBuyerHomeProptechs(id)
      clearData()
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err);
    })
}

export const loadTechBuyerHomeDropdowns = (tags) => {
  if (!tags.length) {
    getTagsData();
  }
}