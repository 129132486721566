import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import { getNewsfeeds } from '../../../utils/middleware/newsfeed';
import AddNewsFeed from '../AddNewsfeed';
import NewsfeedCardDetails from '../NewsfeedCardDetails';
import NewsfeedListCard from '../NewsfeedListCard';
import Loader from '../../../../components/Loading';
import './newsfeedTab.scss';
import { dispatchNoPayload } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { showMessage } from '../../../../utils/utility/snackbar';
import { UNSAVED_CHANGES_MESSAGE } from '../../../constants';
import { MessageType } from '../../../../constants/General';

export default function NewsfeedTab(props) {
  const { unsaveChange, setUnsaveChange } = props

  // State
  const [openModal, setOpenModal] = useState(false)

  // Store
  const { isLoading, selectedNewsfeed, activeSort, sortOrder, isActive, search } = useSelector(state => state.newsfeeds);

  useEffect(() => {
    getNewsfeeds(isActive, activeSort, sortOrder, search)
  }, [activeSort, sortOrder, isActive, search]);

  useEffect(() => () => {
    dispatchNoPayload(Actions.Newsfeeds.ResetNewsfeeds)
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-full h-full box-border p-[30px] bg-[#FAFAFB] admin-proptech-tab investor-proptech-tab'>
        <div className='w-full h-full flex flex-wrap rounded-[32px] overflow-hidden bg-[#FFFFFF]'>
          <div className='h-full left-block'>
            <NewsfeedListCard unsaveChange={unsaveChange} />
          </div>
          <div className='h-full right-block'>
            <>
              <div className='w-full h-[80px] flex items-center justify-end right-top-heading bg-[#111D43] text-[#FFFFFF]'>
                <div className="investor-radio-wrapper">
                  <PTButton
                    label="Add"
                    size='large'
                    className='normal-case fill-white-admin btn'
                    handleOnClickEvent={() => {
                      if (!unsaveChange.current) {
                        setOpenModal(true)
                      } else {
                        showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                      }
                    }}
                  />
                </div>
              </div>
              <div className='md:h-[calc(100%-80px)] md:overflow-auto bg-[#FFFFFF] investor-card-wrapper'>
                {selectedNewsfeed && <NewsfeedCardDetails setUnsaveChange={setUnsaveChange} />}
              </div>
            </>
          </div>
        </div>
      </div>
      {openModal && <AddNewsFeed open={openModal} handleClose={() => setOpenModal(false)} />}
    </>
  )
}
