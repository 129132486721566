import React from "react";
import { useSelector } from "react-redux";
import PTCStepper from '../../../components/GenericComponents/PTCStepper';
import PTInput from "../../../components/GenericComponents/PTInput";
import PTButton from "../../../components/GenericComponents/PTButton";
import { LabelStyle, Questions } from "../../constants/General";
import { Steps } from "../../constants/Stepper";
import ReviewQuestionAnswer from "../ReviewQuestionAnswer";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";

function Review({ setActiveStep }) {
  // Store
  const { companyDetails } = useSelector(state => state.proptechUser);

  return (
    <div className="p-16 flex flex-col justify-between rounded-3xl bg-[#FFFFFF] right-step-wrapper boxshadow-box">
      <div>
        <div className="font-light text-4xl main-title not-italic">Review</div>
        <section>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around">
            <PTInput
              labelStyle={LabelStyle}
              disabled={!companyDetails?.isPrimaryUser}
              variant="standard"
              className='mt-2 sm:mt-0 w-full'
              label="www.google.com"
              fullWidth
              value={companyDetails?.websiteURL}
            />
            <PTInput
              labelStyle={LabelStyle}
              disabled={!companyDetails?.isPrimaryUser}
              variant="standard"
              className='sm:!ml-10 mt-2 sm:mt-0 w-full'
              label="AUD"
              fullWidth
              value={companyDetails?.currency?.name}
            />
          </div>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around">
            <PTInput
              labelStyle={LabelStyle}
              disabled={!companyDetails?.isPrimaryUser}
              variant="standard"
              className='mt-2 sm:mt-0 w-full'
              label="venture scout code"
              fullWidth
              value={companyDetails?.ventureScoutCode}
            />
            <PTInput
              labelStyle={LabelStyle}
              disabled={!companyDetails?.isPrimaryUser}
              variant="standard"
              className='sm:!ml-10 mt-2 sm:mt-0 w-full'
              label="investment range"
              fullWidth
              value={companyDetails?.investmentRange?.name}
            />
          </div>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around sm:w-1/2 sm:pr-5">
            <PTInput
              labelStyle={LabelStyle}
              disabled={!companyDetails?.isPrimaryUser}
              variant="standard"
              className='mt-2 sm:mt-0 w-full'
              label="investment round"
              fullWidth
              value={companyDetails?.investmentRound?.name}
            />
          </div>
          <div className="sm:mt-6 brief-overview">
            <div className="font-semibold text-sm leading-normal not-italic font-['Poppins'] text-[#111D43]">
              Brief business overview / elevator pitch
            </div>
            <div className="mt-4 font-normal text-xs leading-normal not-italic">
              {companyDetails?.elevatorPitch}
            </div>
          </div>
          <div className="sm:mt-8 sm:mb-8 mt-4 mb-4 border-solid border border-[#70778E]" />
          {Object.keys(Questions).map((que, index) => {
            return <>{index > 0 && <ReviewQuestionAnswer question={Questions[que]} answer={companyDetails?.[que]} />}</>
          })}
        </section>
      </div>
      <div className="mt-5 proptech-stepper">
        <div className="sm:mb-10 mb-5 flex justify-end">
          <PTButton variant="contained" label={<span className="!font-semibold !h-5 !text-sm !leading-5 !text-center !not-italic !w-20 !text-[#111D43]">Back</span>} className='!h-12 !w-32 !mr-5 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]' handleOnClickEvent={() => setActiveStep(2)} />
          <PTButton variant="contained" label="Next" className="!h-12 !w-32 !rounded-xl !border-solid !bg-[#111D43] !font-['Poppins']"
            handleOnClickEvent={(e) => {
              Store.dispatch({
                type: Actions.ProptechUser.ProptechSetCompanyDetails, payload: {
                  ...companyDetails,
                  signupProgress: 4
                }
              });
              setActiveStep(4)
            }}
          />
        </div>
        <PTCStepper
          steps={Steps}
          activeStep={3}
          setStepper={(tab) => {
            companyDetails?.signupProgress >= tab && setActiveStep(tab)
          }}
        />
      </div>
    </div>
  )
}

export default Review;
