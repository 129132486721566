import { marketInsightsTabDTO } from "../dtos/marketInsightsTab";
import { loadMarketInsightsTabDropdowns } from "./marketInsightsTab";
import {
  ADMIN_MARKET_INSIGHT_PROPTECHS,
  ADMIN_MARKET_INSIGHT_REPORTS,
} from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const getMarketInsightReports = (
  isArchived,
  activeSort,
  sortOrder,
  search,
  clear = true,
  adminDropdowns
) => {
  const params = {
    filter: { isArchived: isArchived },
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { order: sortOrder } : {}),
    ...(!!activeSort ? { orderBy: activeSort } : {}),
  };
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  return ADMIN_API()
    .get(ADMIN_MARKET_INSIGHT_REPORTS, { params })
    .then((res) => {
      handleSuccess(res);
      if (res?.data?.data) {
        let reports = res?.data?.data?.rows;
        dispatch(Actions.AdminMarketInsightReports.MarketInsightReportsSet, reports);
        if (clear) {
          dispatch(Actions.AdminMarketInsightReports.SetIsSelectedMarketInsightReport, false);
          dispatch(Actions.AdminMarketInsightReports.SetSelectedMarketInsightReport, null);
        }
        loadMarketInsightsTabDropdowns(adminDropdowns?.trends, adminDropdowns?.predictions, adminDropdowns?.growth, adminDropdowns?.needToKnow);
        dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
        return res?.status;
      }
    })
    .catch((err) => {
      handleError(err);
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      return err?.response?.status;
    });
};

export const setActiveMarketInsightTabData = (
  tab,
  id,
  report,
  adminDropdowns,
  search
) => {
  dispatch(Actions.AdminMarketInsightReports.SetMarketInsightsTabIndex, tab);
  switch (tab) {
    case 0:
      getMarketInsightsTabDetails(report);
      break;
    case 1:
      loadProptechsForReports(id);
      break;
    default:
      break;
  }
};

//market insight reports
export const getMarketInsightsTabDetails = (data) => {
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  dispatch(
    Actions.AdminMarketInsightReports.SetMarketInsightsDetails,
    marketInsightsTabDTO(data)
  );
  dispatch(
    Actions.AdminMarketInsightReports.SetSelectedMarketInsightReport,
    marketInsightsTabDTO(data)
  );
  dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
};

export const addNewMarketInsightReportsDetails = (data, adminDropdowns) => {
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  return ADMIN_API()
    .post(ADMIN_MARKET_INSIGHT_REPORTS + "/add", data)
    .then((res) => {
      handleSuccess(res);
      getMarketInsightReports(false, false, false, false, true, adminDropdowns);
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const updateMarketInsightReportsDetails = (id, data, adminDropdowns) => {
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_MARKET_INSIGHT_REPORTS + `/${id}`, data)
    .then((res) => {
      handleSuccess(res);
      getMarketInsightReports(false, false, false, false, true, adminDropdowns);
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const deleteMarketInsightReportsDetails = (id, setOpenDeleteModal, adminDropdowns) => {
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  return ADMIN_API()
    .delete(ADMIN_MARKET_INSIGHT_REPORTS + `/permanently-delete/${id}`)
    .then((res) => {
      handleSuccess(res);
      getMarketInsightReports(false, false, false, false, true, adminDropdowns);
      setOpenDeleteModal(false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

// proptechs for market insight reports
const loadProptechsForReports = (id) => {
  if(id) {
    getProptechsForReport();
    getSelectedProptechsForReport(id);
  }
};

export const getProptechsForReport = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  };

  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_MARKET_INSIGHT_PROPTECHS, { params })
    .then((res) => {
      dispatch(Actions.AdminMarketInsightReports.SetProptechsForReports, res.data.data.rows);
      handleSuccess(res);
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
    })
    .catch((err) => {
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      handleError(err);
    });
};

export const getSelectedProptechsForReport = (id) => {
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_MARKET_INSIGHT_REPORTS + `/${id}/proptechs`)
    .then((res) => {
      dispatch(
        Actions.AdminMarketInsightReports.SetSelectedProptechsForReport,
        res.data.data.rows
      );
      handleSuccess(res);
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
    })
    .catch((err) => {
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      handleError(err);
    });
};

export const updateProptechsForReport = (
  id,
  data,
  searchProptech,
  adminDropdowns
) => {
  dispatch(Actions.AdminMarketInsightReports.SetLoading, true);
  ADMIN_API()
    .put(ADMIN_MARKET_INSIGHT_REPORTS + `/${id}/proptech`, data)
    .then((res) => {
      loadProptechsForReports(id);
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminMarketInsightReports.SetLoading, false);
      console.log(err);
      handleError(err);
    });
};


export const isUnsaveMarketChanges = (data, setUnsaveChange) => {
  let unsave = data && Object.entries(data)
    .filter(([, value]) => value === '')?.length

  setUnsaveChange(!!unsave)
}