import {
  getIntegrationsData,
  getKeyIssuesData,
  getKeyRisksData,
} from "../dropdowns";
import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectImplementationTabData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.implementationData) {
    getProjectImplementationDetails(id);
  }
  loadProjectImplementationTabDropdowns(
    adminDropdowns.keyIssues,
    adminDropdowns.keyRisks,
    adminDropdowns.integrations
  );
};

export const getProjectImplementationDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/implementation`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminProjectTab.SetImplementationData, res.data.data);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      return err?.response?.status;
    });
};

export const updateProjectImplementationDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_TECH_BUYERS + `/${id}/project/implementation`, data)
    .then((res) => {
      handleSuccess(res);
      getProjectImplementationDetails(id);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const loadProjectImplementationTabDropdowns = (
  keyIssues,
  keyRisks,
  integrations
) => {
  if (!keyIssues.length) {
    getKeyIssuesData();
  }
  if (!keyRisks.length) {
    getKeyRisksData();
  }
  if (!integrations.length) {
    getIntegrationsData();
  }
};
