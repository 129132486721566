import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PTInput from "../../../../components/GenericComponents/PTInput";
import ArrowUpIcon from '../../../../assets/Icons/admin-arrow-up.svg';
import ArrowDownIcon from '../../../../assets/Icons/admin-arrow-down.svg';
import { Actions } from "../../../../store/actions";
import { dispatch } from "../../../../utils/utility/store";
import VentureScoutCard from '../VentureScoutCard'
import { useSelector } from 'react-redux';
import '../ventureScout.scss'
import { Form_Field } from '../../../constants';

export default function VentureScoutListCard(props) {
  const { unsaveChange } = props

  // State
  const [activeSearchbar, setActiveSearchbar] = useState(false)
  const [searchText, setSearchText] = useState('')

  // Store
  const { ventureScouts, activeSort, sortOrder } = useSelector(state => state.adminVentureScout)

  const handleSort = (sort) => {
    dispatch(Actions.VentureScouts.SetActiveSort, sort)
    dispatch(Actions.VentureScouts.SetSortOrder, sort === activeSort ? sortOrder === 'ASC' ? 'DESC' : 'ASC' : 'ASC')
  }

  const handleSearch = (e) => {
    let text = e.target.value ? e.target.value.trim() : ''
    setSearchText(e.target.value)
    if (e.key === 'Enter') {
      setSearchText(text)
      dispatch(Actions.VentureScouts.SetSearch, text)
      dispatch(Actions.VentureScouts.SetIsSelectedVentureScout, false)
      dispatch(Actions.VentureScouts.SetSelectedVentureScout, null)
    }
  }

  const handleCloseSearch = () => {
    dispatch(Actions.VentureScouts.SetSearch, '')
    setActiveSearchbar(false)
    setSearchText('')
  }

  return (
    <>
      <div className='venture-scout-left-side-search-container xlg:pl-[30px] xlg:pr-[24px]'>
        {activeSearchbar ?
          <PTInput label="Search" fullWidth value={searchText} onKeyPress={handleSearch} handleOnChangeEvent={handleSearch} variant="standard" placeholder='' icon={<CloseIcon className='cursor-pointer' onClick={handleCloseSearch} />} /> :
          <>
            <div className='search-icon-wrap search' onClick={() => setActiveSearchbar(true)}>
              <SearchIcon className='cursor-pointer text-[#70778E] w-[20px] h-[20px]' />
            </div>
            <div className='sort'>
              <div className={`sort-child opacity-${activeSort === 'name' ? '100' : '40'}`} onClick={() => handleSort('name')}>
                <img src={activeSort === 'name' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>{Form_Field.name}</span>
              </div>
              <div className={`sort-last-child opacity-${activeSort === 'createdAt' ? '100' : '40'}`} onClick={() => handleSort('createdAt')}>
                <img src={activeSort === 'createdAt' && sortOrder === 'DESC' ? ArrowDownIcon : ArrowUpIcon} alt="Icon" style={{ marginRight: '6px' }} />
                <span>{Form_Field.dateCreated}</span>
              </div>
            </div>
          </>}
      </div>
      <div id="ventureScoutList" className='venture-scout-card-container md:mr-[0] md:h-[calc(100%-50px)] xlg:h-[calc(100%-80px)] hide-scrollbar'>
        {ventureScouts.length !== 0 && ventureScouts.map(ventureScout => {
          return <VentureScoutCard
            key={ventureScout?.id}
            ventureScout={ventureScout}
            unsaveChange={unsaveChange}
          />
        })}
      </div>
    </>
  )
}
