import { getSectorsData, getTechBuyersData } from "../dropdowns";
import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectOverviewTabData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.companyOverviewData) {
    getProjectCompanyOverviewDetails(id);
  }
  loadProjectOverviewTabDropdowns(
    adminDropdowns.techBuyers,
    adminDropdowns.sectors
  );
};

export const getProjectCompanyOverviewDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/overview`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(Actions.AdminProjectTab.SetCompanyOverviewData, res.data.data);
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
    });
};

export const updateProjectOverviewDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_TECH_BUYERS + `/${id}/project/overview`, data)
    .then((res) => {
      handleSuccess(res);
      getProjectCompanyOverviewDetails(id)
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const loadProjectOverviewTabDropdowns = (techBuyers, sectors) => {
  if (!techBuyers.length) {
    getTechBuyersData();
  }
  if (!sectors.length) {
    getSectorsData();
  }
};
