export function companyProfileTabDTO(data) {
  return {
    id: data?.id || 0,
    raiseDate: data?.raiseDate || null,
    priorRaiseDate: data?.priorRaiseDate || null,
    keyCustomer1: data?.keyCustomer1 || null,
    keyCustomer2: data?.keyCustomer2 || null,
    keyCustomer3: data?.keyCustomer3 || null,
    keyCustomer1Logo: data?.keyCustomer1Logo || null,
    keyCustomer2Logo: data?.keyCustomer2Logo || null,
    keyCustomer3Logo: data?.keyCustomer3Logo || null,
    revenueRange: {
      id: data?.revenueRange?.id || 0,
      name: data?.revenueRange?.name || null,
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || null,
    },
    investmentRange: {
      id: data?.investmentRange?.id || 0,
      name: data?.investmentRange?.name || null,
    },
    valuationRange: {
      id: data?.valuationRange?.id || 0,
      name: data?.valuationRange?.name || null,
    },
    investmentType: {
      id: data?.investmentType?.id || 0,
      name: data?.investmentType?.name || null,
    },
    keyBizModel: {
      id: data?.keyBizModel?.id || 0,
      name: data?.keyBizModel?.name || null,
    },
    priorInvestmentRound: {
      id: data?.priorInvestmentRound?.id || 0,
      name: data?.priorInvestmentRound?.name || null,
    },
    priorInvestmentRange: {
      id: data?.priorInvestmentRange?.id || 0,
      name: data?.priorInvestmentRange?.name || null,
    },
    priorValuationRange: {
      id: data?.priorValuationRange?.id || 0,
      name: data?.priorValuationRange?.name || null,
    },
    priorInvestmentType: {
      id: data?.priorInvestmentType?.id || 0,
      name: data?.priorInvestmentType?.name || null,
    },
    priorKeyBizModel: {
      id: data?.priorKeyBizModel?.id || 0,
      name: data?.priorKeyBizModel?.name || null,
    },
    committed: {
      id: data?.committed?.id || 0,
      name: data?.committed?.name || null,
    },
    proptechAnalysisOverview: {
      id: data?.proptechAnalysisOverview?.id || 0,
      proptechAnalysisUseCase1: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.name || null,
      },
      proptechAnalysisUseCase2: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.name || null,
      },
      proptechAnalysisUseCase3: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.name || null,
      },
      proptechAnalysisSector1: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.name || null,
      },
      proptechAnalysisSector2: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.name || null,
      },
      proptechAnalysisSector3: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisSector3?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisSector3?.name || null,
      },
      proptechAnalysisTechOffering1: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.name || null,
      },
      proptechAnalysisTechOffering2: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.name || null,
      },
      proptechAnalysisTechOffering3: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.id || 0,
        name: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.name || null,
      }
    },
    proptechAnalysisMarket: {
      id: data?.proptechAnalysisMarket?.id || 0,
      proptechAnalysisChargingModel1: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.name || null,
      },
      proptechAnalysisChargingModel2: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.name || null,
      },
      proptechAnalysisChargingModel3: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.name || null,
      },
      proptechAnalysisTechBuyer1: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.name || null,
      },
      proptechAnalysisTechBuyer2: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.name || null,
      },
      proptechAnalysisTechBuyer3: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.name || null,
      },
      proptechAnalysisValueKPI1: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.name || null,
      },
      proptechAnalysisValueKPI2: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.name || null,
      },
      proptechAnalysisValueKPI3: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.name || null,
      },
      proptechAnalysisValueKPI4: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.id || 0,
        name: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.name || null,
      }
    },
    proptechAnalysisNumber: {
      id: data?.proptechAnalysisNumber?.id || 0,
      proptechAnalysisCurrentGrossMargin: {
        id: data?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin?.id || 0,
        name: data?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin?.name || null,
      },
      proptechAnalysisFutureGrossMargin: {
        id: data?.proptechAnalysisNumber?.proptechAnalysisFutureGrossMargin?.id || 0,
        name: data?.proptechAnalysisNumber?.proptechAnalysisFutureGrossMargin?.name || null,
      }
    },
    proptechAnalysisRaise: {
      id: data?.proptechAnalysisRaise?.id || 0,
      proptechAnalysisStyle1: {
        id: data?.proptechAnalysisRaise?.proptechAnalysisStyle1?.id || 0,
        name: data?.proptechAnalysisRaise?.proptechAnalysisStyle1?.name || null,
      },
      proptechAnalysisTrait1: {
        id: data?.proptechAnalysisRaise?.proptechAnalysisTrait1?.id || 0,
        name: data?.proptechAnalysisRaise?.proptechAnalysisTrait1?.name || null,
      },
      proptechAnalysisTrait2: {
        id: data?.proptechAnalysisRaise?.proptechAnalysisTrait2?.id || 0,
        name: data?.proptechAnalysisRaise?.proptechAnalysisTrait2?.name || null,
      }
    },
    proptechAnalysisTeam: {
      id: data?.proptechAnalysisTeam?.id || 0,
      proptechAnalysisTeamSkill1: {
        id: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.id || 0,
        name: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.name || null,
      },
      proptechAnalysisTeamSkill2: {
        id: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.id || 0,
        name: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.name || null,
      },
      proptechAnalysisTeamSkill3: {
        id: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.id || 0,
        name: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.name || null,
      }
    },
    proptechAnalysisCompanyProfile: {
      id: data?.proptechAnalysisCompanyProfile?.id || 0,
      assetLifecycle1: data?.proptechAnalysisCompanyProfile?.assetLifecycle1 || null,
      assetLifecycle2: data?.proptechAnalysisCompanyProfile?.assetLifecycle2 || null,
      assetLifecycle3: data?.proptechAnalysisCompanyProfile?.assetLifecycle3 || null,
      problemSynopsis: data?.proptechAnalysisCompanyProfile?.problemSynopsis || null,
      problem1Heading: data?.proptechAnalysisCompanyProfile?.problem1Heading || null,
      problem1Text: data?.proptechAnalysisCompanyProfile?.problem1Text || null,
      problem2Heading: data?.proptechAnalysisCompanyProfile?.problem2Heading || null,
      problem2Text: data?.proptechAnalysisCompanyProfile?.problem2Text || null,
      problem3Heading: data?.proptechAnalysisCompanyProfile?.problem3Heading || null,
      problem3Text: data?.proptechAnalysisCompanyProfile?.problem3Text || null,
      solutionSynopsis: data?.proptechAnalysisCompanyProfile?.solutionSynopsis || null,
      solution1Heading: data?.proptechAnalysisCompanyProfile?.solution1Heading || null,
      solution1Text: data?.proptechAnalysisCompanyProfile?.solution1Text || null,
      solution2Heading: data?.proptechAnalysisCompanyProfile?.solution2Heading || null,
      solution2Text: data?.proptechAnalysisCompanyProfile?.solution2Text || null,
      solution3Heading: data?.proptechAnalysisCompanyProfile?.solution3Heading || null,
      solution3Text: data?.proptechAnalysisCompanyProfile?.solution3Text || null,
      bizModel1Heading: data?.proptechAnalysisCompanyProfile?.bizModel1Heading || null,
      bizModel1Text: data?.proptechAnalysisCompanyProfile?.bizModel1Text || null,
      bizModel2Heading: data?.proptechAnalysisCompanyProfile?.bizModel2Heading || null,
      bizModel2Text: data?.proptechAnalysisCompanyProfile?.bizModel2Text || null,
      bizModel3Heading: data?.proptechAnalysisCompanyProfile?.bizModel3Heading || null,
      bizModel3Text: data?.proptechAnalysisCompanyProfile?.bizModel3Text || null,
      goToMarket1Heading: data?.proptechAnalysisCompanyProfile?.goToMarket1Heading || null,
      goToMarket1Text: data?.proptechAnalysisCompanyProfile?.goToMarket1Text || null,
      goToMarket2Heading: data?.proptechAnalysisCompanyProfile?.goToMarket2Heading || null,
      goToMarket2Text: data?.proptechAnalysisCompanyProfile?.goToMarket2Text || null,
      goToMarket3Heading: data?.proptechAnalysisCompanyProfile?.goToMarket3Heading || null,
      goToMarket3Text: data?.proptechAnalysisCompanyProfile?.goToMarket3Text || null,
      customerBreakdown1Text: data?.proptechAnalysisCompanyProfile?.customerBreakdown1Text || null,
      customerBreakdown2Text: data?.proptechAnalysisCompanyProfile?.customerBreakdown2Text || null,
      customerBreakdown3Text: data?.proptechAnalysisCompanyProfile?.customerBreakdown3Text || null,
      customerKeyPoint1Text: data?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text || null,
      customerKeyPoint2Text: data?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text || null,
      customerKeyPoint3Text: data?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text || null,
      currentTraction2Stat: data?.proptechAnalysisCompanyProfile?.currentTraction2Stat || null,
      currentTraction2: data?.proptechAnalysisCompanyProfile?.currentTraction2 || null,
      futureMetric1: data?.proptechAnalysisCompanyProfile?.futureMetric1 || null,
      futureMetric1Text: data?.proptechAnalysisCompanyProfile?.futureMetric1Text || null,
      futureMetric2: data?.proptechAnalysisCompanyProfile?.futureMetric2 || null,
      futureMetric2Text: data?.proptechAnalysisCompanyProfile?.futureMetric2Text || null,
      futureMetric3: data?.proptechAnalysisCompanyProfile?.futureMetric3 || null,
      futureMetric3Text: data?.proptechAnalysisCompanyProfile?.futureMetric3Text || null,
      valueKPI1Text: data?.proptechAnalysisCompanyProfile?.valueKPI1Text || null,
      valueKPI2Text: data?.proptechAnalysisCompanyProfile?.valueKPI2Text || null,
      valueKPI3Text: data?.proptechAnalysisCompanyProfile?.valueKPI3Text || null,
      valueKPI4Text: data?.proptechAnalysisCompanyProfile?.valueKPI4Text || null,
      whyGoodFit1Text: data?.proptechAnalysisCompanyProfile?.whyGoodFit1Text || null,
      whyGoodFit2Text: data?.proptechAnalysisCompanyProfile?.whyGoodFit2Text || null,
      whyGoodFit3Text: data?.proptechAnalysisCompanyProfile?.whyGoodFit3Text || null,
      defensibilityTiming1: data?.proptechAnalysisCompanyProfile?.defensibilityTiming1 || null,
      defensibilityTiming1Text: data?.proptechAnalysisCompanyProfile?.defensibilityTiming1Text || null,
      defensibilityTiming2: data?.proptechAnalysisCompanyProfile?.defensibilityTiming2 || null,
      defensibilityTiming2Text: data?.proptechAnalysisCompanyProfile?.defensibilityTiming2Text || null,
      defensibilityTiming3: data?.proptechAnalysisCompanyProfile?.defensibilityTiming3 || null,
      defensibilityTiming3Text: data?.proptechAnalysisCompanyProfile?.defensibilityTiming3Text || null,
      skill1Text: data?.proptechAnalysisCompanyProfile?.skill1Text || null,
      skill2Text: data?.proptechAnalysisCompanyProfile?.skill2Text || null,
      skill3Text: data?.proptechAnalysisCompanyProfile?.skill3Text || null,
      valueKPI1: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.id || 0,
      valueKPI2: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.id || 0,
      valueKPI3: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.id || 0,
      valueKPI4: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.id || 0,
      chargingModel1: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.id || 0,
      chargingModel2: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.id || 0,
      chargingModel3: data?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.id || 0,
      sector1: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.id || 0,
      sector2: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.id || 0,
      sector3: data?.proptechAnalysisOverview?.proptechAnalysisSector3?.id || 0,
      techOffering1: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.id || 0,
      techOffering2: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.id || 0,
      techOffering3: data?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.id || 0,
      useCase1: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.id || 0,
      useCase2: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.id || 0,
      useCase3: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.id || 0,
      contactPointVendor1: data?.proptechPlaceCompanyDetails?.contactPointVendor1 || 0,
      contactPointVendor2 : data?.proptechPlaceCompanyDetails?.contactPointVendor2 || 0,
      contactPointVendor3 : data?.proptechPlaceCompanyDetails?.contactPointVendor3 || 0,
      futureSynopsis: data?.proptechPlaceCompanyDetails?.futureSynopsis || null,
      future1Heading: data?.proptechPlaceCompanyDetails?.future1Heading || null,
      future1: data?.proptechPlaceCompanyDetails?.future1 || null,
      future2Heading: data?.proptechPlaceCompanyDetails?.future2Heading || null,
      future2: data?.proptechPlaceCompanyDetails?.future2 || null,
      future3Heading: data?.proptechPlaceCompanyDetails?.future3Heading || null,
      future3: data?.proptechPlaceCompanyDetails?.future3 || null,
      pastProjectRangeId: data?.proptechPlaceCompanyDetails?.pastProjectRangeId || null,
      projectScaleId: data?.proptechPlaceCompanyDetails?.projectScaleId || null,
      geographyId: data?.proptechPlaceCompanyDetails?.geographyId || null,
      keyCustomer1: data?.keyCustomer1 || null,
      keyCustomer2: data?.keyCustomer2 || null,
      keyCustomer3: data?.keyCustomer3 || null,
      keyCustomer1Logo: data?.keyCustomer1Logo || null,
      keyCustomer2Logo: data?.keyCustomer2Logo || null,
      keyCustomer3Logo: data?.keyCustomer3Logo || null,
      integrationText1: data?.proptechAnalysisDeployUse?.integrationText1,
      integrationText2: data?.proptechAnalysisDeployUse?.integrationText2,
      integrationText3: data?.proptechAnalysisDeployUse?.integrationText3,
      riskText1: data?.proptechAnalysisDeployUse?.riskText1,
      riskText2: data?.proptechAnalysisDeployUse?.riskText2,
      riskText3: data?.proptechAnalysisDeployUse?.riskText3,
      integration1: data?.proptechAnalysisDeployUse?.integrationKey1?.id,
      integration2: data?.proptechAnalysisDeployUse?.integrationKey2?.id,
      integration3: data?.proptechAnalysisDeployUse?.integrationKey3?.id,
      risk1: data?.proptechAnalysisDeployUse?.proptechRisk1?.id,
      risk2: data?.proptechAnalysisDeployUse?.proptechRisk2?.id,
      risk3: data?.proptechAnalysisDeployUse?.proptechRisk3?.id,
      proptechAnalysisLifecycle1: {
        id: data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1?.name || null,
      },
      proptechAnalysisLifecycle2: {
        id: data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2?.name || null,
      },
      proptechAnalysisLifecycle3: {
        id: data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3?.name || null,
      },
      keyBizModelTraction2Stat: {
        id: data?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat?.name || null,
      },
      keyBizModelFutureMetric1: {
        id: data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric1?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric1?.name || null,
      },
      keyBizModelFutureMetric2: {
        id: data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric2?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric2?.name || null,
      },
      keyBizModelFutureMetric3: {
        id: data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric3?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric3?.name || null,
      },
      proptechAnalysisDefTiming1: {
        id: data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1?.name || null,
      },
      proptechAnalysisDefTiming2: {
        id: data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2?.name || null,
      },
      proptechAnalysisDefTiming3: {
        id: data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3?.id || 0,
        name: data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3?.name || null,
      }
    }
  }
}
