import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectAttachmentsData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.attachmentsData) {
    getProjectAttachments(id);
  }
};

export const getProjectAttachments = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/attachments`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminProjectTab.SetAttachmentsData, res.data.data);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      return err?.response?.status;
    });
};

export const uploadProjectAttachment = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .post(ADMIN_TECH_BUYERS + `/${id}/project/attachment`, data)
    .then((res) => {
      handleSuccess(res);
      getProjectAttachments(id);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const deleteProjectAttachment = (id, attachmentId) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .delete(ADMIN_TECH_BUYERS + `/${id}/project/attachment/${attachmentId}`,)
    .then((res) => {
      handleSuccess(res);
      getProjectAttachments(id);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};
