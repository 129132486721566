import { compareArraysOfObjects } from "../dtos/projectTabs";
import { loadProjectAdoptionTabData } from "./projectAdoptionTab";
import { loadProjectAttachmentsData } from "./projectAttachmentsTab";
import { loadProjectCriteriaTabData } from "./projectCriteriaTab";
import { loadProjectImplementationTabData } from "./projectImplementationTab";
import { loadProjectOverviewTabData } from "./projectOverviewTab";
import { loadProjectStrategyTabData } from "./projectStrategyTab";
import { loadProjectTransformationTabData } from "./projectTransformationTab";

export function setActiveTabData(tab, id, adminProjectTab, adminDropdowns) {
  switch (tab) {
    case 0:
      loadProjectOverviewTabData(id, adminProjectTab, adminDropdowns);
      break;

    case 1:
      loadProjectStrategyTabData(id, adminProjectTab, adminDropdowns);
      break;

    case 2:
      loadProjectCriteriaTabData(id, adminProjectTab, adminDropdowns);
      break;

    case 3:
      loadProjectTransformationTabData(id, adminProjectTab, adminDropdowns);
      break;

    case 4:
      loadProjectImplementationTabData(id, adminProjectTab, adminDropdowns);
      break;

    case 5:
      loadProjectAdoptionTabData(id, adminProjectTab, adminDropdowns);
      break;

    case 6:
      loadProjectAttachmentsData(id, adminProjectTab, adminDropdowns);
      break;

    default:
      break;
  }
}

export const isUnsaveChanges = (
  temp,
  setUnsaveChange,
  tabName,
  response,
  nestedData
) => {
  let data = { ...temp };
  if (tabName !== null && tabName !== "adoption") {
    let a = compareArraysOfObjects(nestedData, response);
    data[tabName] = null;
    if ((Array.isArray(response) && response?.length === 0) || !response) {
      a = false;
    } else if (!response && !nestedData) {
      a = false;
    }
    if (a) {
      setUnsaveChange(a ? 1 : 0);
      return;
    }
  } else if (tabName !== null && tabName?.trim() === "adoption") {
    let a = compareArraysOfObjects(
      response?.projectDecisionProcess.length === 0
        ? [{ heading: "", value: "" }]
        : response?.projectDecisionProcess,
      temp?.decisionMakingProcess
    );
    let b = compareArraysOfObjects(
      response?.projectTimeline.length === 0
        ? [{ heading: "", value: "" }]
        : response?.projectTimeline,
      temp?.timeline
    );
    if (!response && !nestedData) {
      a = false;
      b = false;
    }
    setUnsaveChange(!!a ? 1 : 0 || !!b ? 1 : 0);
    return;
  }

  let unsave =
    data && Object.entries(data).filter(([, value]) => value !== null)?.length;

  setUnsaveChange(!!unsave);
};
