import { ADMIN_INVESTORS } from '../../../constants/APIs'
import { Actions } from '../../../store/actions'
import { ADMIN_API } from '../../../utils/utility/API-call'
import { handleError, handleSuccess } from '../../../utils/utility/snackbar'
import { dispatch } from '../../../utils/utility/store'
import { getCountriesData, getInvestmentRoundsData, getKeyBizModelsData, getTagsData } from '../dropdowns'
import { investorProfileTabDTO, investorProfileTabTagsListDTO } from '../dtos/adminInvestor'
import { getInvestors } from './adminInvestors'

export const loadInvestorProfileTabData = (id, adminInvestor, adminDropdowns) => {
  getInvestorProfileDetails(id)
  getInvestorProfileTagDetails(id)
  loadInvestorProfileTabDropdowns(adminDropdowns.countries, adminDropdowns.investmentRounds, adminDropdowns.keyBizModels, adminDropdowns.tags)
}

export const getInvestorProfileDetails = (id) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS + `/${id}`)
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetProfileTabData, investorProfileTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const getInvestorProfileTagDetails = (id) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS + `/${id}/tags`)
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetProfileTabTagsData, investorProfileTabTagsListDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const updateInvestorProfileDetails = (id, data, tags, isArchived, activeSort, sortOrder, search) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)

  if (tags) {
    updateInvestorProfileTagDetails(id, tags)
  }

  ADMIN_API().put(ADMIN_INVESTORS + `/${id}`, data)
    .then(res => {
      handleSuccess(res)
      getInvestorProfileDetails(id)
      getInvestors(isArchived, activeSort, sortOrder, search, false);
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err);
    })
}

export const updateInvestorProfileTagDetails = (id, data) => {
  ADMIN_API().put(ADMIN_INVESTORS + `/${id}/tags`, { tagIds: data.map(a => a.id) })
    .then(res => {
      handleSuccess(res)
      getInvestorProfileTagDetails(id)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err);
    })
}


export const loadInvestorProfileTabDropdowns = (countries, investmentRounds, keyBizModels, tags) => {
  if (!countries.length) {
    getCountriesData();
  }
  if (!investmentRounds.length) {
    getInvestmentRoundsData();
  }
  if (!keyBizModels.length) {
    getKeyBizModelsData();
  }
  if (!tags.length) {
    getTagsData();
  }
}