import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from 'yup';
import Axios from 'axios';
import LeftLogo from '../../../components/LeftLogo';
import PTInput from '../../../components/GenericComponents/PTInput';
import PTButton from '../../../components/GenericComponents/PTButton';
import { VS_LOGIN_API } from '../../../constants/APIs';
import { Store } from '../../../store/configureStore'
import { Actions } from '../../../store/actions';
import { getRemenberIdVs, setRemenberIdVs, setVsAccessToken, setVsLocalStorageUserData } from '../../../utils/session';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { startLoading, stopLoading } from '../../../utils/utility/loader';
import { FORM_FIELDS } from '../../../vc/constants';
import { configs } from '../../../constants/Configs';

function Login() {
  const rememberId = getRemenberIdVs()
  const [loginError, setLoginError] = useState(false)
  const [rememberMe, setRememberMe] = useState(!!rememberId)
  const initialValues = {
    email: rememberId,
    password: '',
    captcha: ''
  }

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .nullable()
        .email(FORM_FIELDS?.emailValidFormat)
        .required(FORM_FIELDS.required),
      password: Yup.string()
        .required(FORM_FIELDS.required),
      // .min(6, FORM_FIELDS.sizeLimit.tooShortPassword)
      // .matches(FORM_FIELDS.passwordRegex, FORM_FIELDS.sizeLimit.passwordContain),
      captcha: Yup.string()
        .required(FORM_FIELDS.required),
    }),
    onSubmit: (values, { resetForm }) => {
      userLogin({
        email: values.email,
        password: values.password
      }, resetForm)
    }
  });

  // Login API call
  const userLogin = (userData, resetForm) => {
    startLoading()
    Axios.post(VS_LOGIN_API, userData)
      .then(res => {
        if (res?.data?.code === 200) {
          stopLoading()
          // Store.dispatch({ type: Actions.VCAuth.VCLoginSuccess });
          Store.dispatch({ type: Actions.VSUser.VSUserSet, payload: res.data.data });
          // Store.dispatch({ type: Actions.VCProptechs.VCShowModal, payload: true });
          setVsLocalStorageUserData(res.data.data);
          setVsAccessToken(res?.data?.data?.token);
          resetForm({ values: '' });
          handleSuccess(res)
          navigate('/venture-scout/dashboard', { replace: true });
          rememberMe ? setRemenberIdVs(res?.data?.data?.email) : setRemenberIdVs('')
        } else {
          stopLoading()
          // Store.dispatch({ type: Actions.VCAuth.VCLoginFailed });
          Store.dispatch({ type: Actions.VSUser.VSUserRemove });
          setLoginError(res?.response?.data?.message);
          handleError(res?.data)
        }
      })
      .catch((error) => {
        stopLoading()
        // Store.dispatch({ type: Actions.VCAuth.VCLoginFailed });
        Store.dispatch({ type: Actions.VSUser.VSUserRemove });
        setLoginError(error?.response?.data?.message);
        handleError(error)
      })
  }

  return (
    <div className="w-screen flex proptech-layout-wrapper flex-wrap">
      <div className="w-full md:w-3/5">
        <LeftLogo />
      </div>
      <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
        <div className="wrapper-container">
          <div className="justify-start flex-col">
            <div className="text-wrapper"><h2>{FORM_FIELDS.logIn}</h2></div>
            {loginError ?
              <div className="danger-login">
                <span>
                  {loginError}
                </span>
              </div>
              : null
            }
            <form onSubmit={formik.handleSubmit}>
              <div className='form-wrapper'>
                <div className={"form-group " + (formik.touched.email && formik.errors.email ? "error-parent" : "") + (!formik.errors.email && formik.values.email ? "success-attempt" : "")}>
                  <PTInput
                    id="email"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && <span className="error-txt">{formik.errors.email}</span>}
                </div>
                <div className={"form-group " + (formik.touched.password && formik.errors.password ? "error-parent" : "") + (!formik.errors.password && formik.values.password ? "success-attempt" : "")}>
                  <PTInput
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    label="Password"
                    fullWidth
                    className='form-control'
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password && <span className="error-txt">{formik.errors.password}</span>}
                </div>
                <div className="w-full flex items-center mt-6 mb-6">
                  <Checkbox className="!p-0 form-checkbox" id="remember" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                  <label htmlFor='remember' className="flex flex-grow justify-between items-center">
                    <span className="text-sm leading-3 not-italic pl-1 font-['Poppins'] text-[#111D43]">{FORM_FIELDS.rememerMe}</span>
                    <span className="font-semibold text-sm leading-3 text-right not-italic font-['Poppins'] text-[#111D43] cursor-pointer" onClick={() => navigate('/venture-scout/forgotpassword')}>{FORM_FIELDS.forgotPassword}</span>
                  </label>
                </div>
                <div className='g-recaptcha form-group error-parent'>
                  <ReCAPTCHA
                    sitekey={configs.siteKey}
                    name="captcha"
                    onErrored={(e) => formik.setFieldValue('captcha', e)}
                    onExpired={(e) => formik.setFieldValue('captcha', e)}
                    onChange={(e) => formik.setFieldValue('captcha', e)}
                  />
                  {formik.touched.captcha && formik.errors.captcha && <span className="error-txt">{formik.errors.captcha}</span>}
                </div>
                <PTButton label={'Log in'} type="submit" className="normal-case fill-orange btn" size='large' />
              </div>
            </form>
          </div>
          <div className="not-have-account">
            <span>{FORM_FIELDS.notRegistered}  <span onClick={() => navigate(`/venture-scout/signup`)} className='font-semibold cursor-pointer'>{FORM_FIELDS.createAccount}</span></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
