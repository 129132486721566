import React, { useEffect, useRef, useState } from "react";
import { FormHelperText, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import PTButton from "../../../../components/GenericComponents/PTButton";
import PTInput from "../../../../components/GenericComponents/PTInput";
import Loader from "../../../../components/Loading";
import ConfirmModal from "../../ConfirmModal";
import placeholderImage from "../../../../assets/Icons/placeholder.png";
import FullWidthTextField from "../../FullWidthTextField";
import {
  TECH_BUYER_PROFILE_ERROR,
  TECH_BUYER_PROFILE_PAYLOAD,
} from "../../../utils/payloads/payloads";
import { uploadImage } from "../../../utils/middleware/companyTab";
import { trimQuery, urlValidate } from "../../../../utils/utility/utility";
import { dispatch } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";
import { isUnsaveChanges } from "../../../utils/middleware/analysisTab";
import Tags from "../../Tags";
import "./techBuyerProfile.scss";
import { updateRFFProfileDetails } from "../../../utils/middleware/rffProfileTab";

export default function RFFProfileTab(props) {
  const { setUnsaveChange } = props;

  // Store
  const {
    isLoading,
    selectedRFF,
    activeSort,
    sortOrder,
    isArchived,
    search,
    profileTabData,
  } = useSelector((state) => state.adminRFF);
  const { countries } = useSelector((state) => state.adminDropdowns);

  // State
  const [editedDetails, setEditedDetails] = useState(
    TECH_BUYER_PROFILE_PAYLOAD
  );
  const [errors, setErrors] = useState(TECH_BUYER_PROFILE_ERROR);
  const [otherCountries, setOtherCountries] = useState(null);
  const [files, setFiles] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherCountriesError, setOtherCountriesError] = useState(false);
  const inputRef = useRef();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange);
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? error === "websiteURL"
              ? !urlValidate(editedDetails?.[error])
              : !editedDetails?.[error]
            : error === "websiteURL"
            ? !urlValidate(profileTabData?.[error])
            : !profileTabData?.[error],
      };
    });
    setErrors(data);
    let allCountries =
      otherCountries !== null
        ? otherCountries?.map((i) => i.id)
        : profileTabData?.otherCountries?.map((i) => i?.countryId);
    if (!allCountries || allCountries?.length === 0) {
      setOtherCountriesError(true);
      return true;
    } else {
      setOtherCountriesError(false);
    }
    return Object.keys(data).some((k) => data[k]);
  };

  const handleImage = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true);
      setFiles(e.target.files[0]);
      uploadImage(e.target.files[0], "tb-company-logo")
        .then((res) => {
          setLoading(false);
          setEditedDetails({
            ...editedDetails,
            companyLogo: res.data.fileName,
          });
        })
        .catch(() => setLoading(false));
    }
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(
        ([key, value]) =>
          (newData[key] =
            key === "companyLogo"
              ? profileTabData?.[key]?.split("/").slice(-2).join("/")
              : profileTabData?.[key])
      );
    handleCloseModal();

    let allCountries =
      otherCountries !== null
        ? otherCountries?.map((i) => i.id)
        : profileTabData?.otherCountries?.map((i) => i?.countryId);

    try {
      await updateRFFProfileDetails(
        selectedRFF?.id,
        {
          ...Object.fromEntries(
            Object.entries(editedDetails).filter(([_, v]) => v !== null)
          ),
          ...Object.fromEntries(
            Object.entries(newData).filter(([_, v]) => v !== null)
          ),
        },
        null,
        isArchived,
        activeSort,
        sortOrder,
        search,
        allCountries
      );
    } catch {
      dispatch(Actions.AdminRFF.SetLoading, false);
      console.log("error");
    }
  };

  return (
    <div className="admin-profile-wrapper">
      {isLoading && <Loader />}
      <div className="h-full flex flex-col overflow-x-hidden overflow-y-auto">
        <div className="w-full flex company-two-col-block mb-12">
          <input
            style={{ display: "none" }}
            id="file"
            ref={inputRef}
            type="file"
            className="form-control"
            value=""
            accept={["jpg", "jpeg", "png"]
              .map(function (x) {
                return "." + x;
              })
              .join(",")}
            onChange={handleImage}
          />
          <div className="left-form-block">
            <div className="flex justify-between form-item-wrap items-center">
              <span>Company logo</span>
              <div className="flex flex-col justify-end items-end">
                <PTButton
                  variant="contained"
                  label={
                    !loading && (
                      <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">
                        Upload
                      </span>
                    )
                  }
                  className="w-[120px] h-[44px] !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]"
                  loading={loading}
                  handleOnClickEvent={() => {
                    inputRef.current.click();
                  }}
                />
                {errors.companyLogo && (
                  <span className="font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]">
                    Image Required.
                  </span>
                )}
              </div>
            </div>
            <div className="flex justify-between form-item-wrap items-center">
              <span>Country</span>
              <PTInput
                variant="standard"
                label="Country"
                className="custom-dropdown-icon"
                select
                selectChildren={countries.map((country) => (
                  <MenuItem value={country.id}>{country.name}</MenuItem>
                ))}
                placeholder=""
                disabled={loading}
                value={editedDetails?.countryId || profileTabData?.countryId}
                handleOnChangeEvent={(e) =>
                  setEditedDetails({
                    ...editedDetails,
                    countryId: e.target.value,
                  })
                }
                error={errors.countryId}
                helperText={errors.countryId && "Country Required."}
              />
            </div>
            <div className="analysis-dropdown-wrapper horizontal">
              <span className="text-sm text-[#111D43]">Other countries</span>
              <Tags
                value={
                  otherCountries !== null
                    ? otherCountries
                    : profileTabData?.otherCountries?.map((i) => {
                        return {
                          ...i,
                          id: i.countryId,
                          name: i.tbCountries?.name,
                        };
                      }) || []
                }
                onChange={(e, value) => setOtherCountries(value)}
                options={
                  countries &&
                  countries?.length &&
                  countries?.filter(
                    (i) =>
                      i.id !==
                      (editedDetails?.countryId || profileTabData?.countryId)
                  )
                }
              />
              {otherCountriesError && (
                <FormHelperText error={otherCountriesError}>
                  Other countries required
                </FormHelperText>
              )}
            </div>
            <FullWidthTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors}
              data={profileTabData}
              textLabel="Website URL"
              textValue="websiteURL"
              validUrlText
            />
            <div className="flex justify-between form-item-wrap items-center">
              <span>Owner first name</span>
              <PTInput
                variant="standard"
                label="Owner first name"
                placeholder=""
                disabled={loading}
                value={
                  editedDetails?.ownerFirstName !== null
                    ? editedDetails?.ownerFirstName
                    : profileTabData?.ownerFirstName || ""
                }
                handleOnChangeEvent={(e) =>
                  setEditedDetails({
                    ...editedDetails,
                    ownerFirstName: e.target.value,
                  })
                }
                error={errors.ownerFirstName}
                helperText={errors.ownerFirstName && "First name Required."}
              />
            </div>
            <div className="flex justify-between form-item-wrap items-center">
              <span>Owner last name</span>
              <PTInput
                variant="standard"
                label="Owner last name"
                placeholder=""
                disabled={loading}
                value={
                  editedDetails?.ownerLastName !== null
                    ? editedDetails?.ownerLastName
                    : profileTabData?.ownerLastName || ""
                }
                handleOnChangeEvent={(e) =>
                  setEditedDetails({
                    ...editedDetails,
                    ownerLastName: e.target.value,
                  })
                }
                error={errors.ownerLastName}
                helperText={errors.ownerLastName && "Last Required."}
              />
            </div>
          </div>
          <div className="right-image-block">
            {files || profileTabData?.companyLogo ? (
              <div className="inner-image-block">
                <img
                  src={
                    files
                      ? URL.createObjectURL(files)
                      : profileTabData?.companyLogo
                  }
                  alt="Profile"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            ) : (
              <div className="inner-image-block">
                <img src={placeholderImage} alt="placeholder" />
              </div>
            )}
          </div>
        </div>

        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          maxLength={null}
          data={profileTabData}
          textLabel="Company Info"
          textValue="companyInfo"
        />

        <div className="bottom-btn-wrapper flex justify-end">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails(TECH_BUYER_PROFILE_PAYLOAD);
              setOtherCountries(null);
              setFiles(null);
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43]"
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>
      </div>

      {openModal && (
        <ConfirmModal
          open={openModal}
          handleCloseModal={handleCloseModal}
          handleSucces={updateData}
        />
      )}
    </div>
  );
}
