import React from 'react';
import { useSelector } from 'react-redux';
import Chart from '../../Chart';
import { KeyFeatureLabel } from '../../Label';
import { Radar_Chart_Labels } from '../../../constants';
import { deployAndUseChartLabels } from '../../../utils/chartData';

export default function DeployAndUse() {
  // Store
  const { deployAndUseDetails } = useSelector(state => state.tbProptechDetails)
  const { id } = useSelector((state) => state.publicProptechUser);

  return (
    <>
      {deployAndUseDetails ?
        <div className={`tabpanel-wrapper-place market-tab ${ id && "!max-w-full"}`}>
          <div className="left_col-place">
            <h2>Asset fit</h2>
            <p>{deployAndUseDetails?.assetFit || 'Not provided.'}</p>
            <div className="detail-sector-wrapper">
            <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Integration process</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel  icon={deployAndUseDetails?.integrationKey1?.icon} label={deployAndUseDetails?.integrationKey1?.name} />
                  <KeyFeatureLabel  icon={deployAndUseDetails?.integrationKey2?.icon} label={deployAndUseDetails?.integrationKey2?.name} />
                  <KeyFeatureLabel  icon={deployAndUseDetails?.integrationKey3?.icon} label={deployAndUseDetails?.integrationKey3?.name} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Risk incurred</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={deployAndUseDetails?.proptechRisk1?.icon} label={deployAndUseDetails?.proptechRisk1?.name} />
                  <KeyFeatureLabel icon={deployAndUseDetails?.proptechRisk2?.icon} label={deployAndUseDetails?.proptechRisk2?.name} />
                  <KeyFeatureLabel icon={deployAndUseDetails?.proptechRisk3?.icon} label={deployAndUseDetails?.proptechRisk3?.name} />
                </ul>
              </div>

              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Tech buyer alignment</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={deployAndUseDetails?.proptechTechBuyerTrait1?.icon} label={deployAndUseDetails?.proptechTechBuyerTrait1?.name} />
                  <KeyFeatureLabel icon={deployAndUseDetails?.proptechTechBuyerTrait2?.icon} label={deployAndUseDetails?.proptechTechBuyerTrait2?.name} />
                  <KeyFeatureLabel icon={deployAndUseDetails?.proptechTechBuyerTrait3?.icon} label={deployAndUseDetails?.proptechTechBuyerTrait3?.name} />
                </ul>
              </div>
            </div>
          </div>
          <div className="right_col-place" onClick={(e) => e.stopPropagation()}>
            <div className='flex items-start'>
              <h2 className='pr-4'>{Radar_Chart_Labels.deployAndUse}</h2>
            </div>
            <div className="chart-wrapper">
              <Chart
                labels={deployAndUseDetails?.placeRadars?.length ? deployAndUseDetails?.placeRadars?.map((i) => i.placeRadarPoint?.rada?.split(" ")) : deployAndUseChartLabels}
                items={deployAndUseDetails?.placeRadars?.map((i) => i.value) || []}
                step={2}
                label={'Rating'}
                maximumValue={10}
              />
            </div>
          </div>

        </div>
        : null}
    </>
  )
}
