export function marketInsightsTabDTO(data) {
    return {
        id: data?.id || 0,
        name: data?.name || null,
        stat1: data?.stat1 || null,
        stat1Type: data?.stat1Type || null,
        stat2: data?.stat2 || null,
        stat2Type: data?.stat2Type || null,
        stat3: data?.stat3 || null,
        stat3Type: data?.stat3Type || null,
        stat4: data?.stat4 || null,
        stat4Type: data?.stat4Type || null,
        futureSynopsis: data?.futureSynopsis || null,
        future1: data?.future1 || null,
        future1Heading: data?.future1Heading || null,
        future2: data?.future2 || null,
        future2Heading: data?.future2Heading || null,
        future3: data?.future3 || null,
        future3Heading: data?.future3Heading || null,
        needToKnow1Heading: data?.needToKnow1Heading || null,
        needToKnow1Text: data?.needToKnow1Text || null,
        needToKnow2Heading: data?.needToKnow2Heading || null,
        needToKnow2Text: data?.needToKnow2Text || null,
        needToKnow3Heading: data?.needToKnow3Heading || null,
        needToKnow3Text: data?.needToKnow3Text || null,
        trend1: data?.trend1 || null,
        trend2: data?.trend2 || null,
        trend3: data?.trend3 || null,
        prediction1: data?.prediction1 || null,
        prediction2: data?.prediction2 || null,
        prediction3: data?.prediction3 || null,
        growth1: data?.growth1 || null,
        growth2: data?.growth2 || null,
        growth3: data?.growth3 || null,
    }
}