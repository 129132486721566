import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { tabPanels } from '../../utils/tabPanels';
import Connector from '../../../assets/Icons/connector.svg';
import { dispatch } from '../../../utils/utility/store';
import { Actions } from '../../../store/actions';
import './LeftNavbar.scss';
import { showMessage } from '../../../utils/utility/snackbar';
import { UNSAVED_CHANGES_MESSAGE } from '../../constants';
import { MessageType } from '../../../constants/General';

export default function LeftNavbar({ value, unsaveChange }) {
  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    if (!unsaveChange.current) {
      dispatch(Actions.Admin.SetActiveAdminTab, newValue)
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  };

  return (
    <div className="left-col left-menu h-full bg-[#111D43]">
      <div className="relative top-0 admin-logo">
        <img src={Connector} alt="Connect by the PTC" className='cursor-pointer m-auto' />
      </div>
      <div className='h-full'>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
              fontFamily: "Poppins"
            },
          }}
          style={{
            height: '100%'
          }}
        >
          {tabPanels.map((tabPanel, index) => {
            return (
              <Tab key={index} label={
                <>
                  <div className="w-full flex flex-col items-center justify-center pl-0 menu-item mb-4">
                    <img src={tabPanel?.src} alt={tabPanel?.panel} className='mb-2' />
                    <p className="flex items-center justify-center text-[#FFFFFF] not-italic font-['Poppins'] font-light normal-case" >{tabPanel?.panel}</p>
                  </div>
                </>
              } {...a11yProps(index)} />
            )
          })}
        </Tabs>
      </div>
    </div>
  )
}
