import React from 'react'
import { useSelector } from 'react-redux'
import { DateFormat } from '../../../../constants/General'
import { formatTime } from '../../../../utils/utility/utility'

export default function Plan() {
  // Store
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)

  return (
    <div className='px-5 pt-5 overflow-y-auto'>
      <div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading`}>
          <div className='w-full flex justify-center items-center'>
            <div className='w-1/4'>Plan name</div>
            <div className='w-3/4'>{selectedProptech.proptechPlanDetails.name || '-'}</div>
          </div>
        </div>
      </div>
      <div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading`}>
          <div className='w-full flex justify-center items-center'>
            <div className='w-1/4'>Expiry</div>
            <div className='w-3/4'>{selectedProptech.planExpiryDate ? formatTime(selectedProptech.planExpiryDate, DateFormat.DayMonthFormat) : '-'}</div>
          </div>
        </div>
      </div><div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading`}>
          <div className='w-full flex justify-center items-center'>
            <div className='w-1/4'>Free meetings</div>
            <div className='w-3/4'>{selectedProptech.proptechPlanDetails.freeNoOfMeetings || '-'}</div>
          </div>
        </div>
      </div><div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading`}>
          <div className='w-full flex justify-center items-center'>
            <div className='w-1/4'>Plan duration</div>
            <div className='w-3/4'>{selectedProptech.proptechPlanDetails.durationOfDays ? `${selectedProptech.proptechPlanDetails.durationOfDays} Days` : '-'}</div>
          </div>
        </div>
      </div><div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading`}>
          <div className='w-full flex justify-center items-center'>
            <div className='w-1/4'>Price</div>
            <div className='w-3/4'>{selectedProptech.proptechPlanDetails.price ? `$ ${selectedProptech.proptechPlanDetails.price}` : '-'}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
