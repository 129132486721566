export default function adminInvestor(
  initialState = {
    isLoading: false,
    investors: [],
    isInvestorSelected: false,
    selectedInvestor: null,
    activeSort: 'createdAt',
    sortOrder: 'DESC',
    isArchived: false,
    search: '',
    searchProptech: '',
    activeTab: 0,
    profileTabData: null,
    selectedTags: [],
    proptechs: null,
    forYouProptech: null,
    homeProptech: null,
    watchlistProptechs: [],
    interactionFilter: 'total',
    interactionsProptechs: [],
    navigationTabs: [],
    selectedProptechTabs: [],
    activePermissionsTab: 0
  },
  action
) {
  switch (action.type) {
    case 'ADMIN_INVESTORS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'ADMIN_INVESTORS_LIST_SET':
      return {
        ...initialState,
        investors: action.payload,
        isLoading: false,
      };

    case 'SELECTED_ADMIN_INVESTOR_SET':
      return {
        ...initialState,
        selectedInvestor: action.payload,
      };

    case 'SET_IS_SELECTED_ADMIN_INVESTOR':
      return {
        ...initialState,
        isInvestorSelected: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_IS_ARCHIVED':
      return {
        ...initialState,
        isArchived: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_SEARCH_PROPTECH':
      return {
        ...initialState,
        searchProptech: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_ACTIVE_TAB':
      return {
        ...initialState,
        activeTab: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_PROFILE_TAB_DATA':
      return {
        ...initialState,
        profileTabData: action.payload,
      };

    case 'SET_ADMIN_SELECTED_TAGS_INVESTORS_PROFILE_TAB_DATA':
      return {
        ...initialState,
        selectedTags: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_PROPTECHS_DATA':
      return {
        ...initialState,
        proptechs: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_FOR_YOU_PROPTECHS_DATA':
      return {
        ...initialState,
        forYouProptech: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_HOME_PROPTECHS_DATA':
      return {
        ...initialState,
        homeProptech: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_WATCHLIST_PROPTECHS':
      return {
        ...initialState,
        watchlistProptechs: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_INTERACTIONS_FILTER':
      return {
        ...initialState,
        interactionFilter: action.payload,
      };

    case 'SET_ADMIN_INVESTORS_INTERACTIONS_PROPTECHS':
      return {
        ...initialState,
        interactionsProptechs: action.payload,
      };

    case 'SET_ADMIN_NAV_VC_TABS':
      return {
        ...initialState,
        navigationTabs: action.payload,
      };

    case 'SET_ADMIN_SELECTED_VC_PROPTECH_TABS':
        return {
        ...initialState,
        selectedProptechTabs: action.payload,
      };

    case 'SET_ADMIN_PERMISSIONS_ACTIVE_TAB':
      return {
        ...initialState,
        activePermissionsTab: action.payload,
      };

    case 'ADMIN_INVESTORS_RESET':
      return {
        ...initialState,
        isLoading: false,
        investors: [],
        isInvestorSelected: false,
        selectedInvestor: null,
        activeSort: 'createdAt',
        sortOrder: 'DESC',
        isArchived: false,
        search: '',
        searchProptech: '',
        activeTab: 0,
        profileTabData: null,
        selectedTags: [],
        proptechs: null,
        forYouProptech: null,
        homeProptech: null,
        watchlistProptechs: [],
        interactionFilter: 'total',
        interactionsProptechs: [],
        navigationTabs: [],
        selectedProptechTabs: [],
        activePermissionsTab: 0
      };

    default:
      return initialState;
  }
}
