export default function vcProptechs(
  initialState = {
    vcProptechs: null,
    vcWatchList: null,
    vcWatchListPage: 0,
    vcWatchListPageSize: 10,
    vcMasterData: null,
    vcActiveUsers: [],
    vcFeaturedList: null,
    selectedVcProptech: null,
    showModal: false,
    filter: null,
    revenueRangeId: null,
    orderBy: 'createdAt',
    order: 'DESC',
    revenue: null,
    page: 0,
    size: 10,
    isLoading: false,
    activeTabId: 0,
    meetingTabId: 0,
  },
  action
) {
  switch (action.type) {
    case 'VC_SHOW_MODAL':
      return {
        ...initialState,
        showModal: action.payload,
      };

    case 'VC_PROPTECHS_LIST_SET':
      return {
        ...initialState,
        vcProptechs: action.payload,
      };

    case 'VC_WATCHLIST_SET':
      return {
        ...initialState,
        vcWatchList: action.payload,
      };

    case 'VC_WATCHLIST_PAGE_SET':
      return {
        ...initialState,
        vcWatchListPage: action.payload,
      };

    case 'VC_WATCHLIST_PAGESIZE_SET':
      return {
        ...initialState,
        vcWatchListPageSize: action.payload,
      };

    case 'SELECTED_VC_PROPTECH_SET':
      return {
        ...initialState,
        selectedVcProptech: action.payload,
      };

    case 'VC_SET_ORDER':
      return {
        ...initialState,
        order: action.payload,
      }

    case 'VC_SET_FILTER_OPTION':
      return {
        ...initialState,
        filter: action.payload,
      }

    case 'VC_SET_REVENUE_RANGE_ID':
      return {
        ...initialState,
        revenueRangeId: action.payload,
      }

    case 'VC_SET_ORDER_BY':
      return {
        ...initialState,
        orderBy: action.payload,
      }

    case 'VC_SET_REVENUE':
      return {
        ...initialState,
        revenue: action.payload,
      }

    case 'VC_SET_PAGE':
      return {
        ...initialState,
        page: action.payload
      }

    case 'VC_SET_PAGESIZE':
      return {
        ...initialState,
        size: action.payload
      }

    case 'VC_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload
      }

    case 'VC_SET_MASTERDATA':
      return {
        ...initialState,
        vcMasterData: action.payload
      }

    case 'VC_SET_FEATUREDLIST':
      return {
        ...initialState,
        vcFeaturedList: action.payload,
      }
    case 'VC_SET_ACTIVEUSERS':
      return {
        ...initialState,
        vcActiveUsers: action.payload
      }

    case 'VC_SET_ACTIVETAB':
      return {
        ...initialState,
        activeTabId: action.payload
      }

    case 'VC_SET_MEETING_TAB_ID':
      return {
        ...initialState,
        meetingTabId: action.payload
      }

    case 'VC_RESET_LIST':
      return {
        ...initialState,
        vcProptechs: null,
        vcWatchList: null,
      }

    case 'VC_PROPTECHS_RESET':
      return {
        ...initialState,
        vcProptechs: null,
        vcWatchList: null,
        vcWatchListPage: 0,
        vcWatchListPageSize: 10,
        vcMasterData: null,
        vcFeaturedList: null,
        vcActiveUsers: [],
        selectedVcProptech: null,
        showModal: false,
        filter: null,
        revenueRangeId: null,
        orderBy: 'createdAt',
        order: 'DESC',
        revenue: null,
        page: 1,
        size: 10,
        isLoading: false,
        activeTabId: 0
      };

    default:
      return initialState;
  }
}
