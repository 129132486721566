import React from 'react'

export default function ReviewQuestionAnswer({ question, answer }) {
  return (
    <div className="sm:mt-6 mt-4 break-all">
      <div className="font-semibold text-sm leading-normal not-italic font-['Poppins'] text-[#111D43]">
        {question}
      </div>
      <div className="mt-4 font-normal text-xs leading-normal not-italic">
        {answer}
      </div>
    </div>
  )
}
