import React from "react";
import PTButton from "../../components/GenericComponents/PTButton";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <>
      <div className="thanks-page flex items-center md:min-h-screen flex-wrap min-h-full">
        <div className="thanks-wrapper max-w-full">
          <div className="thanks-inner not-found-inner">
            <h2>404!</h2>
            <h2>Oops! That page can't be found.</h2>
            <p>It looks like nothing found on this location.</p>
            <PTButton
              label="Return home"
              className={`fill-orange btn`}
              size="large"
              handleOnClickEvent={() => (window.location.href = "/")}
            />
          </div>
        </div>
      </div>
    </>
  );
}
