import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { DeleteMarketInsightMessage, MARKET_INSIGHTS_TAB, UNSAVED_CHANGES_MESSAGE } from '../../constants';
import { Actions } from '../../../store/actions';
import Loader from '../../../components/Loading';
import { dispatch, dispatchNoPayload } from '../../../utils/utility/store';
import ConfirmModal from '../../components/ConfirmModal';
import ForYouTab from './ForYouTab';
import './MarketInsights.scss';
import { showMessage } from '../../../utils/utility/snackbar';
import { MessageType } from '../../../constants/General';
import MarketInsightsTab from './MarketInsightsTab';
import { deleteMarketInsightReportsDetails, getMarketInsightReports, setActiveMarketInsightTabData } from '../../utils/middleware/AdminMarketInsightReports';
import MarketInsightListSidebar from './MarketInsightListSidebar';
import AddNewReportModal from './CreateNewModal';
import { Tooltip } from '@mui/material';
import { DeleteOutlineOutlined } from '@material-ui/icons';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

export default function MarketInsightTab(props) {
  const { unsaveChange, setUnsaveChange } = props
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addNewModal, setAddNewModalNewModal] = useState(false)

  // Store
  const { activeMarketInsightTab: activeTabIndex, isMarketInsightSelected, activeSort, selectedMarketInsightReport, sortOrder, isArchived, search, isLoading :loading} = useSelector(state => state.adminMarketInsightReports);
  const isRedirect = useSelector(state => state.admin.isRedirect);
  const adminDropdowns = useSelector(state => state.adminDropdowns)


  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleToGetMarketInsightReports = async (isArchived, activeSort, sortOrder, search, clear = true) => {
    setIsLoading(true)
    try {
      await getMarketInsightReports(isArchived, activeSort, sortOrder, search, clear, adminDropdowns);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      console.log('error');
    }
  }

  useEffect(() => {
    handleToGetMarketInsightReports(isArchived, activeSort, sortOrder, search, !isRedirect)
  }, [activeSort, sortOrder, isArchived, search]);  // eslint-disable-line react-hooks/exhaustive-deps

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className='h-full'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className='h-full'>{children}</div>
        )}
      </div>
    );
  }

  useEffect(() => () => {
    isRedirect ? dispatch(Actions.Admin.SetIsRedirect, false) : dispatchNoPayload(Actions.AdminMarketInsightReports.AdminReportsReset)
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading || loading ? <Loader /> : null}
      <div className="w-full h-full box-border p-[30px] bg-[#FAFAFB] admin-proptech-tab-mi">
        <div className="w-full h-full flex flex-wrap rounded-[32px] overflow-hidden bg-[#FFFFFF]">
          <div className="h-full left-block">
            <MarketInsightListSidebar unsaveChange={unsaveChange} />
          </div>
          <div className="h-full right-block">
            <>
              <div className="flex items-center justify-between right-top-heading-admin bg-[#111D43] text-[#FFFFFF]">
                <div className="flex items-center top-heading-listing-admin">
                  {MARKET_INSIGHTS_TAB.map((tab, index) => {
                    return (
                      <div key={index}>
                        <span
                          className={`text-sm not-italic uppercase cursor-pointer ${
                            activeTabIndex === index
                              ? `font-semibold`
                              : `font-light`
                          }`}
                          onClick={() => {
                            if (!unsaveChange.current) {
                              setActiveMarketInsightTabData(
                                index,
                                selectedMarketInsightReport?.id,
                                selectedMarketInsightReport || null,
                                adminDropdowns
                              );
                            } else {
                              showMessage(
                                UNSAVED_CHANGES_MESSAGE.message,
                                MessageType.Error
                              );
                            }
                          }}
                        >
                          {tab?.panel}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="flex gap-3">
                  <Tooltip
                    title="Add new report"
                    className="cursor-pointer"
                    onClick={() => setAddNewModalNewModal(!addNewModal)}
                  >
                    <NoteAddOutlinedIcon />
                  </Tooltip>
                  {isMarketInsightSelected && (
                    <Tooltip
                      title="Delete"
                      className="cursor-pointer"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      <DeleteOutlineOutlined
                        style={{ color: "red" }}
                        handleOnClickEvent={() => setOpenDeleteModal(true)}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              {isMarketInsightSelected ? (
                <div className="md:h-[calc(100%-80px)] md:overflow-auto p-4 pb-12">
                  <TabPanel value={activeTabIndex} index={0}>
                    <MarketInsightsTab
                      unsaveChange={unsaveChange}
                      setUnsaveChange={setUnsaveChange}
                    />
                  </TabPanel>
                  <TabPanel value={activeTabIndex} index={1}>
                    <ForYouTab />
                  </TabPanel>
                </div>
              ) : null}
            </>
          </div>
        </div>
      </div>

      {addNewModal && (
        <AddNewReportModal closeModal={(e) => setAddNewModalNewModal(e)} />
      )}
      {openModal && (
        <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} />
      )}
      {openDeleteModal && (
        <ConfirmModal
          open={openDeleteModal}
          handleCloseModal={() => setOpenDeleteModal(false)}
          message={DeleteMarketInsightMessage}
          handleSucces={() =>
            deleteMarketInsightReportsDetails(
              selectedMarketInsightReport?.id,
              setOpenDeleteModal,
              adminDropdowns
            )
          }
        />
      )}
    </>
  );
}
