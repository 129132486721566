import React from 'react';
import { MenuItem } from '@mui/material';
import PTInput from '../../../components/GenericComponents/PTInput';
import { AnalysisLabelStyle } from '../../../proptech/constants/General';

export default function DropdownColumn(props) {
  // Props
  const { header,
    dropdown1Label, dropdown1Options, dropdown1Value, dropdown1HandleChange, dropdown1Error, dropdown1HelperText,
    dropdown2Label, dropdown2Options, dropdown2Value, dropdown2HandleChange, dropdown2Error, dropdown2HelperText,
    dropdown3Label, dropdown3Options, dropdown3Value, dropdown3HandleChange, dropdown3Error, dropdown3HelperText, disabled, children } = props

  return (
    <div className="dropdown-items">
      <div className='dropdown-title not-italic text-[#111D43]'>{header}</div>
      <PTInput
        labelStyle={AnalysisLabelStyle}
        className='custom-dropdown-icon'
        variant="standard"
        label={dropdown1Label}
        InputPropsStyle={{ fontSize: '12px' }}
        disabled={disabled}
        fullWidth
        select
        selectChildren={dropdown1Options.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
        placeholder=''
        value={dropdown1Value}
        handleOnChangeEvent={dropdown1HandleChange}
        error={dropdown1Error}
        helperText={dropdown1HelperText} />
      <PTInput
        labelStyle={AnalysisLabelStyle}
        className='custom-dropdown-icon'
        variant="standard"
        label={dropdown2Label}
        disabled={disabled}
        InputPropsStyle={{ fontSize: '12px' }}
        fullWidth
        select
        selectChildren={dropdown2Options.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
        placeholder=''
        value={dropdown2Value}
        handleOnChangeEvent={dropdown2HandleChange}
        error={dropdown2Error}
        helperText={dropdown2HelperText} />
      <PTInput
        labelStyle={AnalysisLabelStyle}
        className='custom-dropdown-icon'
        variant="standard"
        label={dropdown3Label}
        disabled={disabled}
        InputPropsStyle={{ fontSize: '12px' }}
        fullWidth
        select
        selectChildren={dropdown3Options.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
        placeholder=''
        value={dropdown3Value}
        handleOnChangeEvent={dropdown3HandleChange}
        error={dropdown3Error}
        helperText={dropdown3HelperText} />
      {children && children}
    </div>
  )
}
