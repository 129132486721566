export default function newsfeeds(
  initialState = {
    isLoading: false,
    newsfeeds: [],
    isNewsfeedSelected: false,
    selectedNewsfeed: null,
    activeSort: 'createdAt',
    sortOrder: 'DESC',
    isActive: true,
    search: '',
  },
  action
) {
  switch (action.type) {
    case 'NEWSFEEDS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'NEWSFEEDS_LIST_SET':
      return {
        ...initialState,
        newsfeeds: action.payload,
        isLoading: false,
      };

    case 'SELECTED_NEWSFEED_SET':
      return {
        ...initialState,
        selectedNewsfeed: action.payload,
      };

    case 'SET_IS_SELECTED_NEWSFEED':
      return {
        ...initialState,
        isNewsfeedSelected: action.payload,
      };

    case 'SET_NEWSFEED_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_NEWSFEED_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_NEWSFEED_IS_ACTIVE':
      return {
        ...initialState,
        isActive: action.payload,
      };

    case 'SET_NEWSFEED_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'RESET_NEWSFEEDS':
      return {
        ...initialState,
        isLoading: false,
        newsfeeds: [],
        isNewsfeedSelected: false,
        selectedNewsfeed: null,
        activeSort: 'createdAt',
        sortOrder: 'DESC',
        isActive: true,
        search: '',
      };

    default:
      return initialState;
  }
}
