import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './PTCheckbox.scss';

export default function PTCheckbox(props) {
  //  ***** props *****
  const {
    className,
    label,
    disabled,
    defaultChecked,
    id,
    onSelection
  } = props

  return (
    <FormControlLabel
      control={<Checkbox defaultChecked={defaultChecked ? defaultChecked : null} className={className ? className : null} id={id ? id : null} onClick={onSelection ? onSelection : null} />}
      label={label}
      disabled={disabled ? disabled : null}
    />
  );
}