import React from "react";
import Checkbox from "@mui/material/Checkbox";
import "../permissionTab.scss";

export default function Proptechcard(props) {
  // Props
  const {
    title,
    onChecked,
    onCardClick,
    checked,
  } = props;
  // State

  return (
    <>
      <div className={`proptech-card-border`}>
        <div className={`proptech-tab-heading ${checked && "bg-[#E7E8EC]"}`}>
          <div className="flex box-border items-center ">
            <div className="grow">
              <div className="flex justify-between">
                <span className="title" onClick={onCardClick}>
                  {title}
                </span>
              </div>
            </div>
            <div>
              <Checkbox
                style={{
                  transform: "scale(1.2)",
                }}
                className="!p-0 admin-form-checkbox"
                id="remember"
                checked={checked}
                onChange={onChecked}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
