import React from "react";
import { useFormik } from 'formik';
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import PTInput from "../../../components/GenericComponents/PTInput";
import PTButton from "../../../components/GenericComponents/PTButton";
import PTCStepper from '../../../components/GenericComponents/PTCStepper';
import { Steps } from "../../constants/Stepper";
import { BASIC_DETAILS_FORM_FIELDS, LabelStyle } from "../../constants/General";
import { sendBasicDetailData } from "../../../utils/middleware/BasicInfo";
import { URL_REGEX } from "../../../constants/General";

function BasicInfo({ setActiveStep, countries, currencies, investmentRounds, investmentRanges }) {
  // Store
  const { companyDetails } = useSelector(state => state.proptechUser);

  const initialValues = {
    websiteURL: companyDetails?.websiteURL,
    linkedInURL: companyDetails?.linkedInURL,
    phone: companyDetails?.phone,
    ventureScoutCode: companyDetails?.ventureScoutCode,
    countryId: companyDetails?.countryId,
    currencyId: companyDetails?.currencyId,
    investmentRoundId: companyDetails?.investmentRoundId,
    investmentRangeId: companyDetails?.investmentRangeId,
    elevatorPitch: companyDetails?.elevatorPitch
  };

  // Handle form values
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      websiteURL: Yup.string().matches(URL_REGEX, BASIC_DETAILS_FORM_FIELDS.validUrl).required(BASIC_DETAILS_FORM_FIELDS.required),
      linkedInURL: Yup.string().matches(URL_REGEX, BASIC_DETAILS_FORM_FIELDS.validUrl).required(BASIC_DETAILS_FORM_FIELDS.required),
      phone: Yup.string().required(BASIC_DETAILS_FORM_FIELDS.required),
      ventureScoutCode: Yup.string(),
      countryId: Yup.string().required(BASIC_DETAILS_FORM_FIELDS.required),
      currencyId: Yup.string().required(BASIC_DETAILS_FORM_FIELDS.required),
      investmentRoundId: Yup.string().required(BASIC_DETAILS_FORM_FIELDS.required),
      investmentRangeId: Yup.string().required(BASIC_DETAILS_FORM_FIELDS.required),
      elevatorPitch: Yup.string().trim().min(100, BASIC_DETAILS_FORM_FIELDS.must100CharMore).max(250, BASIC_DETAILS_FORM_FIELDS.must250CharLess).required(BASIC_DETAILS_FORM_FIELDS.required),
    }),
    onSubmit: (values, { resetForm }) => {
      if (companyDetails?.isPrimaryUser) {
        sendBasicDetailData(
          Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== '')),
          companyDetails,
          countries,
          currencies,
          investmentRounds,
          investmentRanges,
          setActiveStep,
          resetForm)
      } else { setActiveStep(1) }
    }
  });

  return (
    <div className="p-16 flex flex-col justify-between rounded-3xl bg-[#FFFFFF] right-step-wrapper boxshadow-box">
      <form onSubmit={formik.handleSubmit}>
        <div className="font-light text-4xl main-title not-italic">Basic info</div>
        <section>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around">
            <PTInput
              labelStyle={LabelStyle}
              variant="standard"
              label="Website URL"
              name="websiteURL"
              fullWidth
              className="mt-2 sm:mt-0 w-full"
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.websiteURL}
              helperText={formik.errors.websiteURL}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.websiteURL} />
            <PTInput labelStyle={LabelStyle}
              name="linkedInURL"
              variant="standard"
              className='mt-2 sm:!ml-10 sm:mt-0 w-full'
              label="linkedin url"
              fullWidth
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.linkedInURL}
              helperText={formik.errors.linkedInURL}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.linkedInURL} />
          </div>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around">
            <PTInput
              labelStyle={LabelStyle}
              name="phone"
              variant="standard"
              label="Business Phone"
              fullWidth
              className="mt-2 sm:mt-0 w-full"
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.phone}
              helperText={formik.errors.phone}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone} />
            <PTInput
              labelStyle={LabelStyle}
              name="ventureScoutCode"
              variant="standard"
              className='mt-2 sm:mt-0 w-full sm:!ml-10'
              label="venture scout code"
              fullWidth
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.touched.ventureScoutCode && formik.errors.ventureScoutCode}
              helperText={formik.touched.ventureScoutCode && formik.errors.ventureScoutCode}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ventureScoutCode} />
          </div>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around">
            <PTInput
              labelStyle={LabelStyle}
              name="countryId"
              variant="standard"
              label="Country"
              fullWidth
              select
              selectChildren={countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
              className="custom-dropdown-icon proptech-dropdown mt-2 sm:mt-0 w-full"
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.countryId}
              helperText={formik.errors.countryId}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.countryId} />
            <PTInput
              labelStyle={LabelStyle}
              name="currencyId"
              variant="standard"
              className='sm:!ml-10 custom-dropdown-icon proptech-dropdown mt-2 sm:mt-0 w-full'
              label="Currency"
              fullWidth
              select
              selectChildren={currencies.map(currency => <MenuItem value={currency.id}>{currency.name}</MenuItem>)}
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.currencyId}
              helperText={formik.errors.currencyId}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currencyId} />
          </div>
          <div className="sm:mt-6 flex-col sm:flex-row flex justify-around">
            <PTInput
              labelStyle={LabelStyle}
              name="investmentRoundId"
              variant="standard"
              label="investment round"
              fullWidth
              className="custom-dropdown-icon proptech-dropdown mt-2 sm:mt-0 w-full"
              select
              selectChildren={investmentRounds.map(round => <MenuItem value={round.id}>{round.name}</MenuItem>)}
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.investmentRoundId}
              helperText={formik.errors.investmentRoundId}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.investmentRoundId} />
            <PTInput
              labelStyle={LabelStyle}
              name="investmentRangeId"
              variant="standard"
              className='sm:!ml-10 custom-dropdown-icon proptech-dropdown mt-2 sm:mt-0 w-full'
              label="investment range"
              fullWidth
              select
              selectChildren={investmentRanges.map(range => <MenuItem value={range.id}>{range.name}</MenuItem>)}
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.investmentRangeId}
              helperText={formik.errors.investmentRangeId}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.investmentRangeId} />
          </div>
          <div className="sm:mt-6 flex-col sm:flex-row">
            <PTInput
              labelStyle={LabelStyle}
              name="elevatorPitch"
              variant="standard"
              label="brief business overview / elevator pitch"
              fullWidth
              className="mt-2 sm:mt-0 w-full"
              multiline
              maxRows={4}
              maxLength={250}
              disabled={!companyDetails?.isPrimaryUser}
              error={formik.errors.elevatorPitch}
              handleOnChangeEvent={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.elevatorPitch} />
            <div className="mt-2 flex justify-between items-center">
              <span className='font-normal text-xs text-left tracking-wide text-[#d32f2f]'>{formik.errors.elevatorPitch}</span>
              <span className='text-xs font-semibold' style={{ color: 'rgba(17, 29, 67, 0.4)' }}>{formik.values.elevatorPitch?.trim()?.length || '0'}/<span>250</span> CHARACTERS</span>
            </div>
          </div>
        </section>
      </form>
      <div className="mt-5 proptech-stepper">
        <div className="sm:mb-10 mb-5 flex justify-end">
          <PTButton variant="contained" label="Next" type="submit" className='!h-12 !w-32 !rounded-xl !border-solid !bg-[#111D43]' handleOnClickEvent={(e) => companyDetails?.isPrimaryUser ? formik.submitForm(e) : setActiveStep(1)} />
        </div>
        <PTCStepper steps={Steps} activeStep={0}
          setStepper={(tab) => {
            companyDetails?.signupProgress >= tab &&
              setActiveStep(tab)
          }}
        />
      </div>
    </div>
  )
}

export default BasicInfo;
