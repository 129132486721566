import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { TagStyle } from '../../constants';

export default function Tags(props) {
  const { value, onChange, options, getOptionDisabled, disabled } = props

  return <Autocomplete
    multiple
    id="tags-standard"
    options={options}
    value={value}
    getOptionDisabled={getOptionDisabled}
    getOptionLabel={(option) => option.name}
    onChange={onChange}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    disabled={disabled}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          variant="outlined"
          style={TagStyle}
          label={option.name}
          {...getTagProps({ index })} />
      ))
    }
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        placeholder="Search tags"
      />
    )}
  />
}
