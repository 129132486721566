import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { deleteVentureScout, getVentureScouts, setActiveTab } from '../../../utils/middleware/adminVentureScout';
import VentureScoutListCard from '../VentureScoutListCard'
import Loader from '../../../../components/Loading';
import { DeleteUserMessage, UNSAVED_CHANGES_MESSAGE, VENTURE_SCOUT_TABS } from '../../../constants';
import { dispatch, dispatchNoPayload } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import VentureScoutProfileTab from '../VentureScoutProfileTab';
import VentureScoutInteractionsTab from '../VentureScoutInteractionsTab';
import '../ventureScout.scss'
import { showMessage } from '../../../../utils/utility/snackbar';
import { MessageType } from '../../../../constants/General';
import PTButton from '../../../../components/GenericComponents/PTButton';
import ConfirmModal from '../../ConfirmModal';

export default function VentureScoutTab(props) {
  const { unsaveChange, setUnsaveChange } = props

  // Store
  const { selectedVentureScout, isVentureScoutSelected, isLoading, activeSort, sortOrder, search, activeTab } = useSelector(state => state.adminVentureScout);
  const adminVentureScout = useSelector(state => state.adminVentureScout);
  const adminDropdowns = useSelector(state => state.adminDropdowns);

  // State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    getVentureScouts(activeSort, sortOrder, search)
  }, [activeSort, sortOrder, search]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className='h-full'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className='h-full'>{children}</div>
        )}
      </div>
    );
  }

  useEffect(() => () => dispatchNoPayload(Actions.VentureScouts.ResetVentureScouts), [])

  return (
    <>
      {isLoading && <Loader />}
      <div className='venture-scout-tab admin-proptech-tab investor-proptech-tab'>
        <div className='venture-scout-tab-inner'>
          <div className='h-full left-block'>
            <VentureScoutListCard unsaveChange={unsaveChange} />
          </div>
          <div className='h-full right-block'>
            <>
              <div className='venture-scout-right-side-tabpanel right-top-heading-admin'>
                <div className='flex top-heading-listing-admin'>
                  {
                    VENTURE_SCOUT_TABS.map((tab, index) => {
                      return (
                        <div key={index}>
                          <span
                            className={`venture-scout-right-side-tabpanel-text ${activeTab === index ? `font-semibold` : `font-light`}`}
                            onClick={() => {
                              if (!unsaveChange.current) {
                                setActiveTab(index, selectedVentureScout?.id, adminVentureScout, adminDropdowns)
                                dispatch(Actions.VentureScouts.SetActiveTab, index)
                              } else {
                                showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                              }
                            }}
                          >
                            {tab?.panel}
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
                {isVentureScoutSelected && <PTButton
                  label="DELETE"
                  size='large'
                  className='normal-case fill-delete-admin btn white-border'
                  handleOnClickEvent={() => setOpenDeleteModal(true)} />}

              </div>
              {isVentureScoutSelected &&
                <div className='md:h-[calc(100%-80px)] md:overflow-auto'>
                  <TabPanel value={activeTab} index={0}>
                    <VentureScoutProfileTab setUnsaveChange={setUnsaveChange} />
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                    <VentureScoutInteractionsTab />
                  </TabPanel>
                </div>
              }
            </>
          </div>
        </div>
      </div>

      {openDeleteModal && <ConfirmModal open={openDeleteModal} handleCloseModal={() => setOpenDeleteModal(false)} message={DeleteUserMessage} handleSucces={() => deleteVentureScout(selectedVentureScout?.id, activeSort, sortOrder, search, setOpenDeleteModal)} />}
    </>
  )
}
