export function newsfeedListDTO(data) {
  let newsfeedList = [];
  if (data && data.length) {
    newsfeedList = data.map((newsfeed) => {
      return {
        id: newsfeed?.id || 0,
        title: newsfeed?.title || null,
        description: newsfeed?.description || null,
        image: newsfeed?.image || null,
        redirectionURL: newsfeed?.redirectionURL || null,
        status: newsfeed?.status !== null ? newsfeed.status : false,
        createdAt: newsfeed?.createdAt || null,
        updatedAt: newsfeed?.updatedAt || null,
      }
    })
  }

  return newsfeedList;
}