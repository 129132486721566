export function contactListDTO(data) {
  let contactList = [];
  if (data && data.length) {
    contactList = data.map((contact) => {
      return {
        id: contact?.id || 0,
        name: contact?.name || null,
        companyName: contact?.companyName || null,
        email: contact?.email || null,
        profileImage: contact?.profileImage || null,
        phone: contact?.phone || null,
        userStatus: contact?.userStatus !== null ? contact.userStatus : null,
        companyStatus: contact?.companyStatus !== null ? contact.companyStatus : 0,
        isPrimaryUser: contact?.isPrimaryUser !== null ? contact.isPrimaryUser : false,
        isPublished: contact?.isPublished !== null ? contact.isPublished : false,
        linkedInURL: contact?.linkedInURL || null,
        lastLoginAt: contact?.lastLoginAt || null,
        userCreatedDate: contact?.userCreatedDate || null,
        countryName: contact?.countryName || null,
        languageName: contact?.languageName || null,
        userType: contact?.userType || null,
        associatedContactList: contact?.associatedContactList?.length ? contact?.associatedContactList.map(user => {
          return {
            id: user?.id ? user.id : 0,
            name: user?.name ? user.name : '',
          }
        }) : []
      }
    })
  }

  return contactList;
}