import React from 'react';
import { styled } from "@mui/material/styles";
import Rating from '@mui/material/Rating';
import { makeStyles } from "@material-ui/core/styles";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const useStyles = makeStyles((theme) => ({
  "& .MuiRating-iconFilled": {
    color: "#FF4100"
  },
  "& .MuiRating-iconHover": {
    color: "#FF4100"
  },
  emptyStar: {
    color: "#FF4100"
  }
}));

export default function Ratings(props) {
  const { className, label, value, max, onChange, onResetClick, total, color="#FF4100" } = props;

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: color
    },
    "& .MuiRating-iconHover": {
      color: color
    },
  });

  const classes = useStyles();

  return (
    <>
      <div className={className}>
        <span className='rating-title'>{label}</span>
        <div className='flex'>
          <StyledRating
            value={value}
            max={max}
            emptyIcon={
              <StarBorderIcon fontSize="inherit" className={color ? `${`text-[${color}]`}` :classes.emptyStar} />
            }
            onChange={onChange}
          />
          <RestartAltIcon className='text-[20px] cursor-pointer' onClick={onResetClick} />
        </div>
      </div>
      {total && total}
    </>
  )
}
