import React from 'react';
import { useNavigate } from 'react-router-dom';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import eyeIcon from '../../../assets/Icons/ic-eye.svg';
import openDetail from '../../../assets/Icons/openDetail.svg';
import calenderIcon from '../../../assets/Icons/ic-calender.svg';
import './FeaturedCard.scss';
import moment from 'moment';
import { KeyFeatureLabel } from '../Label';
import { FEATURED_CARD } from '../../constants';

// Featured block card used in vc dashboard
export default function FeaturedCard({ proptech }) {
  const navigate = useNavigate()

  return (
    <div className='featured-card-wrapper'>
      <div className='top-featured'>
        <span>{FEATURED_CARD.featured}</span>
      </div>
      <div className='company-detail-block'>
        <div className='flex justify-between align-top left-content'>
          <div className='company-logo'>
            {proptech?.companyLogo ?
              <img src={proptech?.companyLogo} alt="Logo" /> :
              <HideImageOutlinedIcon />
            }
          </div>
          <div className="card-info">
            <h2 ><span>{proptech?.proptechCompany?.name}</span></h2>
            <ul className="listing-item flex">
              <li ><img src={eyeIcon} alt="Icon" />{`${proptech?.watchingCount} Watching`}</li>
              <li ><img src={calenderIcon} alt="Icon" />{`Listed ${moment.tz(proptech?.publishedAt, "YYYYMMDD", "Australia/Sydney").fromNow()}`}</li>
            </ul>
          </div>
        </div>
        <div className='right-link'><img src={openDetail} alt="Logo" onClick={(e) => { navigate(`/investor/proptech/${proptech?.id}`) }} className='cursor-pointer' /></div>
      </div>
      <div className="bottom-menu-card flex content-center font-bold">
        <div className="important-sectors py-1">
          <h2 className='opacity-60'>{FEATURED_CARD.keyThemes}</h2>
          <ul className="menu-item">
            {<KeyFeatureLabel feature icon={proptech?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.icon} label={proptech?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.name || '-'} />}
            {<KeyFeatureLabel feature icon={proptech?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.icon} label={proptech?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.name || '-'} />}
            {<KeyFeatureLabel feature icon={proptech?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.icon} label={proptech?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.name || '-'} />}
          </ul>
        </div>
      </div>
    </div>
  )
}
