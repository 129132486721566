export default function adminContacts(
  initialState = {
    isLoading: false,
    contacts: [],
    isContactSelected: false,
    selectedContact: null,
    alreadySelectedContact: null,
    activeSort: 'userCreatedDate',
    sortOrder: 'DESC',
    isPrimaryUser: false,
    activefilter: 1,
    search: '',
  },
  action
) {
  switch (action.type) {
    case 'ADMIN_CONTACTS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'ADMIN_CONTACTS_LIST_SET':
      return {
        ...initialState,
        contacts: action.payload,
        isLoading: false,
      };

    case 'SELECTED_ADMIN_CONTACT_SET':
      return {
        ...initialState,
        selectedContact: action.payload,
      };

    case 'SET_IS_SELECTED_ADMIN_CONTACT':
      return {
        ...initialState,
        isContactSelected: action.payload,
      };

    case 'SET_ALREADY_SELECTED_ADMIN_CONTACT':
      return {
        ...initialState,
        alreadySelectedContact: action.payload,
      };

    case 'SET_ADMIN_CONTACTS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_ADMIN_CONTACTS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_ADMIN_CONTACTS_IS_PRIMARY_USER':
      return {
        ...initialState,
        isPrimaryUser: action.payload,
      };

    case 'SET_ADMIN_CONTACTS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_ADMIN_CONTACTS_ACTIVE_FILTER':
      return {
        ...initialState,
        activefilter: action.payload,
      };

    case 'ADMIN_CONTACTS_RESET':
      return {
        ...initialState,
        isLoading: false,
        contacts: [],
        isContactSelected: false,
        selectedContact: null,
        activeSort: 'userCreatedDate',
        sortOrder: 'DESC',
        isPrimaryUser: false,
        activefilter: 1,
        search: '',
      };

    default:
      return initialState;
  }
}
