export function vsChartDTO(data) {
  const chartData = {
    labels: [],
    proptechCounts: [],
    meetingCounts: [],
  };
  if (data && data.length) {
    data.forEach((obj) => {
      Object.keys(obj).forEach((k) => {
        chartData.labels.push(k);
        chartData.proptechCounts.push(obj[k].totalOnboardedProptechsCount);
        chartData.meetingCounts.push(obj[k].totalProptechMeetingsCount);
      });
    });
  }
  return chartData;
}
