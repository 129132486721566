import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, FormGroup, FormControlLabel, Link, CircularProgress } from '@mui/material';
import PTButton from "../../../components/GenericComponents/PTButton";
import Info from '../../../assets/Icons/info.svg';
import TickIcon from '../../../assets/Icons/ic-tickmark-white.svg';
import CrossIcon from '../../../assets/Icons/ic-cross-white.svg';
import PTCStepper from "../../../components/GenericComponents/PTCStepper";
import { Steps } from "../../constants/Stepper";
import { PROPTECH_API } from "../../../utils/utility/API-call";
import { PROPTECH_CHOOSE_PLAN_API } from "../../../constants/APIs";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { planFeatures, planTexts } from "../../constants/General";
import PaymentDetails from "../PaymentDetails";
import { Actions } from "../../../store/actions";
import { dispatch } from '../../../utils/utility/store'
import Loader from "../../../components/Loading";
import { signupCompleted } from "../../../utils/middleware/Payment";
import './selectPackage.scss'

function SelectPackage({ setActiveStep, plans, activePlan }) {
  // Store
  const isLoading = useSelector(state => state.proptechUser.isLoading);
  const companyDetails = useSelector(state => state.proptechUser.companyDetails);

  // State
  const [selectedPlan, setSelectedPlan] = useState(activePlan)
  const [openModal, setOpenModal] = useState(false)
  const [termAndConditions, setTermAndConditions] = useState(false)
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiry: '',
    cvc: '',
    name: ''
  })

  const closeModal = () => {
    setOpenModal(false)
    setCardDetails({
      cardNumber: '',
      expiry: '',
      cvc: '',
      name: ''
    })
  }

  // Handle update payment details
  const submitPaymentDetails = () => {
    dispatch(Actions.ProptechUser.SetLoading, true)
    PROPTECH_API().post(PROPTECH_CHOOSE_PLAN_API, {
      signupProgress: 4,
      planId: plans[selectedPlan]?.id,
      cardNumber: cardDetails.cardNumber,
      expiryMonth: new Date(cardDetails.expiry).getMonth() + 1,
      expiryYear: new Date(cardDetails.expiry).getFullYear(),
      cvc: cardDetails.cvc,
      cardHolderName: cardDetails.name,
    }).then(res => {
      handleSuccess(res)
      dispatch(Actions.ProptechUser.SetLoading, false)
      closeModal()
      dispatch({
        type: Actions.ProptechUser.ProptechSetCompanyDetails, payload: {
          ...companyDetails,
          signupProgress: companyDetails?.signupProgress > 4 ? companyDetails?.signupProgress : 3
        }
      });
      signupCompleted()
    }).catch(err => {
      dispatch(Actions.ProptechUser.SetLoading, false)
      handleError(err)
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="p-16 flex flex-col justify-between rounded-3xl bg-[#FFFFFF] right-step-wrapper boxshadow-box">
        <div className="select-package-page flex flex-col grow">
          <div className="font-light text-4xl not-italic pb-[30px] box-border">Select a package</div>
          {plans?.length ? <><TableContainer component={Paper} className="package-table mb-8">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-lg font-semibold leading-tight font-['Poppins']">Features</TableCell>
                  <TableCell className={`text-lg font-semibold leading-tight font-['Poppins'] w-[118px] ${selectedPlan === 0 && 'selected-package-header'}`} align="center">{plans[0]?.name}</TableCell>
                  <TableCell className={`text-lg font-semibold leading-tight font-['Poppins'] w-[118px] ${selectedPlan === 1 && 'selected-package-header'}`} align="center">{plans[1]?.name}</TableCell>
                  <TableCell className={`text-lg font-semibold leading-tight font-['Poppins'] w-[118px] ${selectedPlan === 2 && 'selected-package-header'}`} align="center">{plans[2]?.name}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planFeatures.map((data, index) => {
                  return <TableRow>
                    <TableCell className="py-2.5">
                      <div className="feature-info flex items-center">
                        <p className="pr-4 leading-tight font-['Poppins'] text-xs text-[#111D43]">{data.label}</p>
                        <img src={Info} alt="Info" className="w-5 h-5" />
                      </div>
                    </TableCell>
                    <TableCell className={`py-2.5 ${selectedPlan === 0 && (index === planFeatures?.length - 1 ? 'selected-package-footer' : 'selected-package-data')}`} align="center">
                      {data.icon ? plans[0]?.[data.value] ?
                        <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                          <img src={TickIcon} alt="Info" />
                        </span> : <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                          <img src={CrossIcon} alt="Cross Icon" />
                        </span>
                        : <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">{plans[0]?.[data.value] && data.value === 'additionalMeetingsWithPTC' ? `${plans[0]?.[data.value]} hours` : plans[0]?.[data.value] || 'Nil'}</p>}
                    </TableCell>
                    <TableCell className={`py-2.5 ${selectedPlan === 1 && (index === planFeatures?.length - 1 ? 'selected-package-footer' : 'selected-package-data')}`} align="center">
                      {data.icon ? plans[1]?.[data.value] ?
                        <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                          <img src={TickIcon} alt="Info" />
                        </span> : <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                          <img src={CrossIcon} alt="Cross Icon" />
                        </span>
                        : <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">{plans[1]?.[data.value] && data.value === 'additionalMeetingsWithPTC' ? `${plans[1]?.[data.value]} hours` : plans[1]?.[data.value] || 'Nil'}</p>}
                    </TableCell>
                    <TableCell className={`py-2.5 ${selectedPlan === 2 && (index === planFeatures?.length - 1 ? 'selected-package-footer' : 'selected-package-data')}`} align="center">
                      {data.icon ? plans[2]?.[data.value] ?
                        <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                          <img src={TickIcon} alt="Info" />
                        </span> : <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                          <img src={CrossIcon} alt="Cross Icon" />
                        </span>
                        : <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">{plans[2]?.[data.value] && data.value === 'additionalMeetingsWithPTC' ? `${plans[2]?.[data.value]} hours` : plans[2]?.[data.value] || 'Nil'}</p>}
                    </TableCell>
                  </TableRow>
                })}
                <TableRow>
                  <TableCell className="py-4"></TableCell>
                  {Array.from(Array(3).keys()).map(data => {
                    return <TableCell className="py-4" align="center">
                      <PTButton variant="contained" label="Select" handleOnClickEvent={() => setSelectedPlan(data)} className="font-semibold font-['Poppins'] !h-12 !w-20 shadow-none normal-case !rounded-xl !border-solid !bg-[#111D43]" />
                    </TableCell>
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
            <div className="cost-section">
              <div className="cost-wrapper flex flex-wrap justify-between border-t-2 border-solid border-[#FF4100]">
                <div className="cost-inner review-title pl-0">
                  <div className="text-lg font-semibold leading-tight font-['Poppins'] text-[#FF4100] max-w-[225px]">{planTexts.reviewSelection}</div>
                </div>
                <div className="w-1/4 cost-inner text-center bg-[#FF4100] sm:pt-7 sm:pb-7 pt-4 pb-4">
                  <span className="text-lg font-semibold leading-tight font-['Poppins'] mb-1.5 block text-[#FFFFFF]">{selectedPlan !== null ? `$${plans[selectedPlan]?.price}` : 'Select plan'}</span>
                  <p className="text-[10px] leading-3	font-semibold uppercase font-['Poppins'] text-[#FFFFFF]">total cost</p>
                </div>
              </div>
              <div className="terms-condition-section flex items-center justify-between mt-6">
                <p className="text-sm leading-tight text-[#111D43]">Find out more about our terms and conditions <Link href={planTexts.termAndConditionURL} className="decoration-[#111D43]" target="_blank">here</Link>.</p>
                <FormGroup>
                  <FormControlLabel checked={termAndConditions} control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />} label="Agree and continue" onChange={(e) => setTermAndConditions(e.target.checked)} />
                </FormGroup>
              </div>
            </div></> : <div className='w-full grow flex justify-center items-center'>
            <CircularProgress />
          </div>}
        </div>
        <div className="mt-5 proptech-stepper">
          <div className="sm:mb-10 mb-5 flex justify-end">
            <PTButton variant="contained"
              label={<span className="!font-semibold !h-5 !text-sm !leading-5 !text-center !not-italic !w-20 !text-[#111D43]">Back</span>}
              className='!h-12 !w-32 !mr-5 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
              handleOnClickEvent={() => setActiveStep(3)} />
            <PTButton variant="contained" label="Next"
              disabled={!(selectedPlan !== null && termAndConditions)}
              className="!h-12 !w-32 !rounded-xl !border-solid !bg-[#111D43] !font-['Poppins'] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]"
              handleOnClickEvent={(e) => selectedPlan !== null && setOpenModal(true)} />
          </div>
          <PTCStepper
            steps={Steps}
            activeStep={4}
            setStepper={(tab) => {
              companyDetails?.signupProgress >= tab &&
                setActiveStep(tab)
            }}
          />
        </div>

        {openModal && <PaymentDetails
          openModal={openModal}
          closeModal={closeModal}
          cardDetails={cardDetails}
          setCardDetails={setCardDetails}
          handleSubmit={submitPaymentDetails}
        />}

      </div >
    </>
  )
}

export default SelectPackage;
