import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { userType } from '../../../../constants/General';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';

export default function ContectFilterMenu(props) {
  //  **** props ****
  const {
    anchorEl,
    open,
    handleClose,
    activefilter
  } = props

  const handleChange = (value) => {
    dispatch(Actions.Contacts.SetActiveFilter, value)
    handleClose()
  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <MenuItem selected={activefilter === userType.all} onClick={() => handleChange(userType.all)}>All</MenuItem>
      <MenuItem selected={activefilter === userType.proptech} onClick={() => handleChange(userType.proptech)}>Proptechs</MenuItem>
      <MenuItem selected={activefilter === userType.vc} onClick={() => handleChange(userType.vc)}>VC</MenuItem>
      <MenuItem selected={activefilter === userType.vs} onClick={() => handleChange(userType.vs)}>Venture-scouts</MenuItem>
      <MenuItem selected={activefilter === userType.tb} onClick={() => handleChange(userType.tb)}>TB</MenuItem>
    </Menu>
  )
}
