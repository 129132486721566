import { getProjectKeyUsersData, getValueKPIsData } from "../dropdowns";
import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectCriteriaTabData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.assessmentCriteriaData) {
    getProjectCriteriaDetails(id);
  }
  loadProjectCriteriaTabDropdowns(
    adminDropdowns.projectKeyUsers,
    adminDropdowns.valueKPIs
  );
};

export const getProjectCriteriaDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/criteria`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminProjectTab.SetAssessmentCriteriaData,
        res.data.data
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
    });
};

export const updateProjectCriteriaDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_TECH_BUYERS + `/${id}/project/criteria`, data)
    .then((res) => {
      handleSuccess(res);
      getProjectCriteriaDetails(id);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const loadProjectCriteriaTabDropdowns = (projectKeyUsers, valueKPIs) => {
  if (!projectKeyUsers.length) {
    getProjectKeyUsersData();
  }
  if (!valueKPIs.length) {
    getValueKPIsData();
  }
};
