import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import mapIcon from "../../../../assets/Icons/ic-map-place.svg";
import {
  getCompanyProfileAPI,
  getOverviewAPI,
  getOfferingAPI,
  getTrackRecordAPI,
  getMarketInsightsAPI,
  getDeployAndUseAPI,
  getDesignReviewAPI,
  getProptechDetailsAPI,
} from "../../../utils/middleware/previewScreenPlace";
import { Actions } from "../../../../store/actions";
import "../previewScreen.scss";
import CompanyProfileTab from "../../../../place/components/ProptechDetails/CompanyProfileTab";
import Loader from "../../../../components/Loading";
import OfferingTab from "../../../../place/components/ProptechDetails/OfferingTab";
import TrackRecordTab from "../../../../place/components/ProptechDetails/TrackRecordTab";
import MarketInsightTab from "../../../../place/components/ProptechDetails/MarketInsightTab";
import DeployAndUse from "../../../../place/components/ProptechDetails/DeployAndUse";
import OverviewTab from "../../../../place/components/ProptechDetails/OverviewTab";
import DesignReview from "../../../../place/components/ProptechDetails/DesignReviewTab";
import { dispatchNoPayload } from "../../../../utils/utility/store";
import CompanyLogo from "../../../../place/components/CompanyLogo";
import { Tooltip } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full"
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="h-full">
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PlacePreviewScreen({tabId}) {
  const [value, setValue] = useState(0);

  const { proptechDetails } = useSelector((state) => state.tbProptechDetails);
  const selectedProptech = useSelector(
    (state) => state.adminUser.selectedProptech
  );
  const tbProptechDetails = useSelector((state) => state.tbProptechDetails);
  const isLoading = useSelector((state) => state.adminUser.isLoading);

  useEffect(() => {
    if (selectedProptech?.id && tabId === 2) {
      if (!tbProptechDetails?.proptechDetails && tabId === 2) {
        getProptechDetailsAPI(selectedProptech.id);
      }
      setActiveTabData(0, selectedProptech.id, tbProptechDetails);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setActiveTabData(tab, id, tbProptechDetails) {
    switch (tab) {
      case 0:
        !tbProptechDetails?.overviewDetails && getOverviewAPI(id);
        break;

      case 1:
        !tbProptechDetails?.marketDetails && getOfferingAPI(id);
        break;

      case 2:
        !tbProptechDetails?.numbersDetails && getTrackRecordAPI(id);
        break;

      case 3:
        !tbProptechDetails?.marketInsightsDetails && getMarketInsightsAPI(id);
        break;

      case 4:
        !tbProptechDetails?.deployAndUseDetails && getDeployAndUseAPI(id);
        break;

      case 5:
        !tbProptechDetails?.designReviewDetails && getDesignReviewAPI(id);
        break;

      case 6:
        !tbProptechDetails?.companyProfileDetails && getCompanyProfileAPI(id);
        break;

      default:
        !tbProptechDetails?.overviewDetails && getOverviewAPI(id);
        break;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTabData(newValue, selectedProptech.id, tbProptechDetails);
  };
  useEffect(
    () => () =>
      dispatchNoPayload(Actions.TBProptechDetails.TBProptechDetailsReset),
    []
  );

  return (
    <>
      {isLoading && <Loader />}

      <div className="w-full h-full flex flex-col proptech-detail-wrapper-place overflow-y-auto md:overflow-inherit">
        <div className="top-menu-place bg-[#F7F7F7]">
          <div className="left_col-place">
            <div className="nav-top flex">
              <CompanyLogo
                companyLogo={proptechDetails?.companyLogo}
                name={proptechDetails?.proptechCompany?.name}
              />
              <div className="card-info">
                <h2>{proptechDetails?.proptechCompany?.name || "No Name"}</h2>
                <ul className="listing-item-place badge-item">
                  <Tooltip title={"Region"} arrow>
                    <li>
                      <img src={mapIcon} alt="Icon" />
                      {proptechDetails?.region?.name || "-"}
                    </li>
                  </Tooltip>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="protech-detail-inner-place">
          <div className="tab-wrapper-place">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className="tab-heading-wrapper md:sticky lg:top-0  z-10">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Offering" {...a11yProps(1)} />
                  <Tab label="Track record" {...a11yProps(2)} />
                  <Tab label="Market insights" {...a11yProps(3)} />
                  <Tab label="Deploy + use" {...a11yProps(4)} />
                  <Tab label="Design review" {...a11yProps(5)} />
                  <Tab label="Company profile" {...a11yProps(6)} />
                </Tabs>
              </Box>
              <div className="relative md:flex md:items-start">
                <div className="tab-panels-place">
                  <TabPanel value={value} index={0}>
                    <OverviewTab />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <OfferingTab />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <TrackRecordTab />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <MarketInsightTab />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <DeployAndUse />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <DesignReview />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <CompanyProfileTab />
                  </TabPanel>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
