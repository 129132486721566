import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { List, ListItem } from '@mui/material';
import { Navigation } from "swiper";
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import Clock from '../../../assets/Icons/ic-slider-clock.svg';
import Edit from '../../../assets/Icons/ic-slider-edit.svg';
import { formatTime } from '../../../utils/utility/utility';
import Location from '../../../assets/Icons/ic-slider-location.svg';
import { DateFormat, meetingStatus } from '../../../constants/General';
import { SWIPER } from '../../constants/General';
import { PROPTECH_API } from '../../../utils/utility/API-call';
import { PROPTECH_MEETINGS_API } from '../../../constants/APIs';
import '../../pages/Dashboard/dashboard.scss';
import "swiper/css";
import "swiper/css/navigation";
import moment from 'moment';

export default function UpcomingMeeting(props) {
  const { upcomingMeetings, setOpenRescheduleModal, setOpenCancelModal, setSelectedMeetingId } = props

  // Handle join meeting
  const redirectToMeeting = async (meeting) => {
    if (meeting.meetingLink) {
      await PROPTECH_API().put(`${PROPTECH_MEETINGS_API}/${meeting.id}/join`);
      window.open(meeting?.meetingLink?.match(/^https?:/) ? meeting?.meetingLink : '//' + meeting?.meetingLink)
    }
  }

  return <Swiper
    navigation={true}
    slidesPerView={SWIPER.slidesPerView}
    spaceBetween={SWIPER.spaceBetween}
    modules={[Navigation]}
    breakpoints={SWIPER.breakpoints}
    className="upcoming-slider"
  >
    {upcomingMeetings.map(meeting => {
      return <SwiperSlide className="px-[2px] pt-0 pb-[5px]">
        <div className="slide-item shadow-[0px_2px_5px_rgba(0,0,0,0.1)] rounded-2xl">
          <div className={`slide-image relative h-[125px] rounded-t-2xl bg-[rgba(112,119,142,0.2)] ${meeting?.vcCompanyDetails?.companyLogo ? "pb-[58.85%]" : ""}`}>
            {meeting?.vcCompanyDetails?.companyLogo ?
              <img src={meeting.vcCompanyDetails.companyLogo} alt="Slider Map" className="w-full h-full object-cover absolute inset-0 rounded-t-2xl mix-blend-multiply" /> :
              <HideImageOutlinedIcon className='w-full h-full' />
            }
          </div>
          <div className="slide-content-wrapper py-3 px-[10px]">
            <span className="text-xs font-semibold mb-2 block text-[#111D43]">{meeting?.vcCompanyDetails?.vcCompany?.name}</span>
            <List className="p-0">
              <ListItem className="p-0 items-start mb-1.5">
                <img src={Clock} alt="Clock" className="mr-1.5 w-[12px] h-[12px]" />
                <p className="text-[8px] text-[#111D43]">{meeting.startTime ? formatTime(meeting.startTime, DateFormat.DayMonthFormat4) : '-'}</p>
              </ListItem>
              <ListItem className="p-0 items-start mb-1.5 text-[8px]">
                <img src={Location} alt="Location" className="mr-1.5 w-[12px] h-[12px]" />
                <div className={`font-semibold text-[#0000FF]/[0.4] hover:cursor-pointer hover:text-[#0000FF] hover:underline ${!([meetingStatus.pending, meetingStatus.inProgress].includes(meeting.status) && moment().isBetween(moment(meeting.startTime).subtract(5, 'minutes'), moment(meeting.endTime))) && 'pointer-events-none'}`}
                  onClick={() => redirectToMeeting(meeting)}>
                  Join Meeting
                </div>
              </ListItem>
              <ListItem className="p-0 items-start mb-0 text-[8px]">
                <img src={Edit} alt="Edit" className="mr-1.5 w-[12px] h-[12px]" />
                {meeting?.rescheduleMeetingCount === 0 && <div className='mr-1 font-semibold text-[#0000FF]/[0.4] hover:cursor-pointer hover:text-[#0000FF] hover:underline'
                  onClick={(e) => {
                    setOpenRescheduleModal(true)
                    setSelectedMeetingId(meeting.id)
                  }}>
                  Reschedule
                </div>}
                {meeting?.rescheduleMeetingCount === 0 && <span className='mr-1'>/</span>}
                <div className='font-semibold text-[#0000FF]/[0.4] hover:cursor-pointer hover:text-[#0000FF] hover:underline'
                  onClick={(e) => {
                    setOpenCancelModal(true)
                    setSelectedMeetingId(meeting.id)
                  }}>
                  Cancel
                </div>
              </ListItem>
            </List>
          </div>
        </div>
      </SwiperSlide>
    })}
  </Swiper>
}
