import React from 'react'
import defaultProfile from '../../../../../assets/Icons/default-profile.svg';
import { DateFormat } from '../../../../../constants/General';
import { formatTime } from '../../../../../utils/utility/utility';

export default function ProptechListCard(props) {
  // Props
  const { tags, logo, title, createdAt, onCardClick } = props

  return (
    <div className={`proptech-card-border`}>
      <div className={`proptech-tab-heading`}>
        <div className='flex box-border items-center flex-wrap'>
          <div className='image-container' onClick={onCardClick}>
            <img
              src={logo || defaultProfile}
              className="logo object-contain"
              alt="Profile"
            />
          </div>
          <div className='grow middle-title-text'>
            <div className='flex justify-between'>
              <span className='title' onClick={onCardClick}>{title}</span>
            </div>
            {createdAt && <div className='w-full flex'>
              <div className="label" style={{ marginRight: '4px' }}>
                <span className='header'>Created</span>
                <span className='value'>{formatTime(createdAt, DateFormat.DayMonthFormat)}</span>
              </div>
            </div>}
          </div>
          <div className='tag-container'>
            {!!tags?.length && tags.map(tag => {
              return <div className='edit-tag-container'>
                <div className='edit-tag-button'>
                  {tag.name}
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
