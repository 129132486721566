import Analytics from "../../assets/Icons/icon-analytics.svg";
import Calendar from "../../assets/Icons/icon-calendar.svg";
import Contacts from "../../assets/Icons/icon-contacts.svg";
import Investors from "../../assets/Icons/icon-investors.svg";
import TechBuyers from "../../assets/Icons/icon-techBuyer.svg";
import MarketInsights from "../../assets/Icons/icon-marketInsights.svg";
import News from "../../assets/Icons/news.svg";
import PropTechs from "../../assets/Icons/icon-proptech.svg";
import Settings from "../../assets/Icons/icon-settings.svg";
import VentureScouts from "../../assets/Icons/icon-vcscouts.svg";
import RFF from "../../assets/Icons/admin-rff-icon.svg";

export const tabPanels = [
  {
    panel: "Proptechs",
    src: PropTechs,
  },
  {
    panel: "Investors",
    src: Investors,
  },
  {
    panel: "Tech Buyers",
    src: TechBuyers,
  },
  {
    panel: "RFF",
    src: RFF,
  },
  {
    panel: "Market Insights",
    src: MarketInsights,
  },
  {
    panel: "Newsfeed",
    src: News,
  },
  {
    panel: "Venture Scouts",
    src: VentureScouts,
  },
  {
    panel: "Contacts",
    src: Contacts,
  },
  {
    panel: "Analytics",
    src: Analytics,
  },
  {
    panel: "Calendar",
    src: Calendar,
  },
  {
    panel: "Settings",
    src: Settings,
  },
];
