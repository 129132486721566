import React, { useEffect, useState } from "react";
import DropdownWithMultiTextField from "../../../DropdownWithMultiTextFiled";
import { useSelector } from "react-redux";
import {
  PROJECT_TRANSFORMATION_ERROR,
  PROJECT_TRANSFORMATION_PAYLOAD,
} from "../../../../utils/payloads/payloads";
import { dispatch } from "../../../../../utils/utility/store";
import { Actions } from "../../../../../store/actions";
import { isUnsaveChanges } from "../../../../utils/middleware/projectTab";
import { updateProjectTransformationDetails } from "../../../../utils/middleware/projectTransformationTab";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import ConfirmModal from "../../../ConfirmModal";
import { trimQuery } from "../../../../../utils/utility/utility";
import Loader from "../../../../../components/Loading";

const Transformation = (props) => {
  const { setUnsaveChange } = props;

  const { techOfferings } = useSelector((state) => state.adminDropdowns);
  const transformationData = useSelector(
    (state) => state.adminProjectTab.transformationData
  );
  const { isLoading } = useSelector((state) => state.adminUser);
  const { selectedTechBuyer } = useSelector((state) => state.adminTechBuyer);

  const [editedDetails, setEditedDetails] = useState(
    PROJECT_TRANSFORMATION_PAYLOAD
  );
  const [errors, setErrors] = useState(PROJECT_TRANSFORMATION_ERROR);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange);
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !transformationData?.[error],
      };
    });
    setErrors(data);
    return Object.keys(data).some((k) => data[k]);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = transformationData?.[key]));

    handleCloseModal();
    try {
      let res = await updateProjectTransformationDetails(
        selectedTechBuyer?.id,
        {
          ...Object.fromEntries(
            Object.entries(editedDetails).filter(([_, v]) => !!v)
          ),
          ...Object.fromEntries(
            Object.entries(newData).filter(([_, v]) => !!v)
          ),
        }
      );
      if (res === 200) setEditedDetails(PROJECT_TRANSFORMATION_PAYLOAD);
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="mt-4 mb-[22px] w-full">
        <div className="analysis-dropdown-wrapper horizontal">
          <div className="flex items-center w-[90%] justify-between">
            <p className="text-sm not-italic mb-2 text-[#111D43]">
              Current technology
            </p>
          </div>
          <div>
            <DropdownWithMultiTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors.currentTechnology1}
              dropdown
              dropdownLabel={"Tech 1"}
              label1={"Associated issue 1"}
              label2={"Associated issue 2"}
              label3={"Associated issue 3"}
              data={transformationData}
              dropdownOptions={techOfferings}
              dropdownValue={
                editedDetails.currentTechnology1 ||
                transformationData?.currentTechnology1
              }
              handleOnChangeEventDropdown={(e) => {
                setEditedDetails({
                  ...editedDetails,
                  currentTechnology1: e.target.value,
                });
              }}
              value1={
                editedDetails?.currentTech1KeyPoint1 !== null
                  ? editedDetails?.currentTech1KeyPoint1
                  : transformationData?.currentTech1KeyPoint1 || ""
              }
              value2={
                editedDetails?.currentTech1KeyPoint2 !== null
                  ? editedDetails?.currentTech1KeyPoint2
                  : transformationData?.currentTech1KeyPoint2 || ""
              }
              value3={
                editedDetails?.currentTech1KeyPoint3 !== null
                  ? editedDetails?.currentTech1KeyPoint3
                  : transformationData?.currentTech1KeyPoint3 || ""
              }
              value1Error={errors.currentTech1KeyPoint1}
              value2Error={errors.currentTech1KeyPoint2}
              value3Error={errors.currentTech1KeyPoint3}
              handleOnChangeEvent1={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech1KeyPoint1: e.target.value,
                })
              }
              handleOnChangeEvent2={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech1KeyPoint2: e.target.value,
                })
              }
              handleOnChangeEvent3={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech1KeyPoint3: e.target.value,
                })
              }
            />
            <DropdownWithMultiTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors.currentTechnology2}
              dropdown
              data={transformationData}
              dropdownOptions={techOfferings}
              dropdownLabel={"Tech 2"}
              label1={"Associated issue 1"}
              label2={"Associated issue 2"}
              label3={"Associated issue 3"}
              dropdownValue={
                editedDetails.currentTechnology2 ||
                transformationData?.currentTechnology2
              }
              handleOnChangeEventDropdown={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTechnology2: e.target.value,
                })
              }
              value1={
                editedDetails?.currentTech2KeyPoint1 !== null
                  ? editedDetails?.currentTech2KeyPoint1
                  : transformationData?.currentTech2KeyPoint1 || ""
              }
              value2={
                editedDetails?.currentTech2KeyPoint2 !== null
                  ? editedDetails?.currentTech2KeyPoint2
                  : transformationData?.currentTech2KeyPoint2 || ""
              }
              value3={
                editedDetails?.currentTech2KeyPoint3 !== null
                  ? editedDetails?.currentTech2KeyPoint3
                  : transformationData?.currentTech2KeyPoint3 || ""
              }
              value1Error={errors.currentTech2KeyPoint1}
              value2Error={errors.currentTech2KeyPoint2}
              value3Error={errors.currentTech2KeyPoint3}
              handleOnChangeEvent1={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech2KeyPoint1: e.target.value,
                })
              }
              handleOnChangeEvent2={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech2KeyPoint2: e.target.value,
                })
              }
              handleOnChangeEvent3={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech2KeyPoint3: e.target.value,
                })
              }
            />
            <DropdownWithMultiTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors.currentTechnology3}
              dropdown
              dropdownLabel={"Tech 3"}
              label1={"Associated issue 1"}
              label2={"Associated issue 2"}
              label3={"Associated issue 3"}
              data={transformationData}
              dropdownOptions={techOfferings}
              dropdownValue={
                editedDetails.currentTechnology3 ||
                transformationData?.currentTechnology3
              }
              handleOnChangeEventDropdown={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTechnology3: e.target.value,
                })
              }
              value1={
                editedDetails?.currentTech3KeyPoint1 !== null
                  ? editedDetails?.currentTech3KeyPoint1
                  : transformationData?.currentTech3KeyPoint1 || ""
              }
              value2={
                editedDetails?.currentTech3KeyPoint2 !== null
                  ? editedDetails?.currentTech3KeyPoint2
                  : transformationData?.currentTech3KeyPoint2 || ""
              }
              value3={
                editedDetails?.currentTech3KeyPoint3 !== null
                  ? editedDetails?.currentTech3KeyPoint3
                  : transformationData?.currentTech3KeyPoint3 || ""
              }
              value1Error={errors.currentTech3KeyPoint1}
              value2Error={errors.currentTech3KeyPoint2}
              value3Error={errors.currentTech3KeyPoint3}
              handleOnChangeEvent1={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech3KeyPoint1: e.target.value,
                })
              }
              handleOnChangeEvent2={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech3KeyPoint2: e.target.value,
                })
              }
              handleOnChangeEvent3={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  currentTech3KeyPoint3: e.target.value,
                })
              }
            />
          </div>
          <div className="flex items-center w-[90%] justify-between mb-2">
            <p className="text-sm not-italic text-[#111D43]">
              Suggested technology
            </p>
          </div>
          <div>
            <DropdownWithMultiTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors.suggestedTechnology1}
              dropdown
              dropdownLabel={"Tech 1"}
              label1={"Associated issue 1"}
              label2={"Associated issue 2"}
              label3={"Associated issue 3"}
              data={transformationData}
              dropdownOptions={techOfferings}
              dropdownValue={
                editedDetails.suggestedTechnology1 ||
                transformationData?.suggestedTechnology1
              }
              handleOnChangeEventDropdown={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTechnology1: e.target.value,
                })
              }
              value1={
                editedDetails?.suggestedTech1KeyPoint1 !== null
                  ? editedDetails?.suggestedTech1KeyPoint1
                  : transformationData?.suggestedTech1KeyPoint1 || ""
              }
              value2={
                editedDetails?.suggestedTech1KeyPoint2 !== null
                  ? editedDetails?.suggestedTech1KeyPoint2
                  : transformationData?.suggestedTech1KeyPoint2 || ""
              }
              value3={
                editedDetails?.suggestedTech1KeyPoint3 !== null
                  ? editedDetails?.suggestedTech1KeyPoint3
                  : transformationData?.suggestedTech1KeyPoint3 || ""
              }
              value1Error={errors.suggestedTech1KeyPoint1}
              value2Error={errors.suggestedTech1KeyPoint2}
              value3Error={errors.suggestedTech1KeyPoint3}
              handleOnChangeEvent1={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech1KeyPoint1: e.target.value,
                })
              }
              handleOnChangeEvent2={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech1KeyPoint2: e.target.value,
                })
              }
              handleOnChangeEvent3={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech1KeyPoint3: e.target.value,
                })
              }
            />
            <DropdownWithMultiTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors.suggestedTechnology2}
              dropdown
              data={transformationData}
              dropdownOptions={techOfferings}
              dropdownLabel={"Tech 2"}
              label1={"Associated issue 1"}
              label2={"Associated issue 2"}
              label3={"Associated issue 3"}
              dropdownValue={
                editedDetails.suggestedTechnology2 ||
                transformationData?.suggestedTechnology2
              }
              handleOnChangeEventDropdown={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTechnology2: e.target.value,
                })
              }
              value1={
                editedDetails?.suggestedTech2KeyPoint1 !== null
                  ? editedDetails?.suggestedTech2KeyPoint1
                  : transformationData?.suggestedTech2KeyPoint1 || ""
              }
              value2={
                editedDetails?.suggestedTech2KeyPoint2 !== null
                  ? editedDetails?.suggestedTech2KeyPoint2
                  : transformationData?.suggestedTech2KeyPoint2 || ""
              }
              value3={
                editedDetails?.suggestedTech2KeyPoint3 !== null
                  ? editedDetails?.suggestedTech2KeyPoint3
                  : transformationData?.suggestedTech2KeyPoint3 || ""
              }
              value1Error={errors.suggestedTech2KeyPoint1}
              value2Error={errors.suggestedTech2KeyPoint2}
              value3Error={errors.suggestedTech2KeyPoint3}
              handleOnChangeEvent1={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech2KeyPoint1: e.target.value,
                })
              }
              handleOnChangeEvent2={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech2KeyPoint2: e.target.value,
                })
              }
              handleOnChangeEvent3={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech2KeyPoint3: e.target.value,
                })
              }
            />
            <DropdownWithMultiTextField
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              errors={errors.suggestedTechnology3}
              dropdownLabel={"Tech 3"}
              label1={"Associated issue 1"}
              label2={"Associated issue 2"}
              label3={"Associated issue 3"}
              dropdown
              data={transformationData}
              dropdownOptions={techOfferings}
              dropdownValue={
                editedDetails.suggestedTechnology3 ||
                transformationData?.suggestedTechnology3
              }
              handleOnChangeEventDropdown={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTechnology3: e.target.value,
                })
              }
              value1={
                editedDetails?.suggestedTech3KeyPoint1 !== null
                  ? editedDetails?.suggestedTech3KeyPoint1
                  : transformationData?.suggestedTech3KeyPoint1 || ""
              }
              value2={
                editedDetails?.suggestedTech3KeyPoint2 !== null
                  ? editedDetails?.suggestedTech3KeyPoint2
                  : transformationData?.suggestedTech3KeyPoint2 || ""
              }
              value3={
                editedDetails?.suggestedTech3KeyPoint3 !== null
                  ? editedDetails?.suggestedTech3KeyPoint3
                  : transformationData?.suggestedTech3KeyPoint3 || ""
              }
              value1Error={errors.suggestedTech3KeyPoint1}
              value2Error={errors.suggestedTech3KeyPoint2}
              value3Error={errors.suggestedTech3KeyPoint3}
              handleOnChangeEvent1={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech3KeyPoint1: e.target.value,
                })
              }
              handleOnChangeEvent2={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech3KeyPoint2: e.target.value,
                })
              }
              handleOnChangeEvent3={(e) =>
                setEditedDetails({
                  ...editedDetails,
                  suggestedTech3KeyPoint3: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="flex justify-end bottom-btn-wrapper w-[95%]">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => {
              setEditedDetails(PROJECT_TRANSFORMATION_PAYLOAD);
            }}
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() =>
              !checkValidation() === true && handleOpenModal()
            }
          />
        </div>
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
};

export default Transformation;
