import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProptechCard from '../../components/ProtechCard';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import Pagination from '../../components/Pagination';
import { dispatch } from '../../../utils/utility/store';

export default function WatchlistTab({ tab }) {
  // Store
  const { vcWatchList, vcWatchListPage, vcWatchListPageSize } = useSelector(state => state.vcProptechs);
  
  useEffect(() => {
    Store.dispatch({ type: Actions.VCProptechDetails.ProptechDetailsReset });
  }, [])

  const handlePageChange = (v) => {
    dispatch(Actions.VCProptechs.VCWatchlistPageSet, v)
    // setHomeCount(1)
  }

  const handleRowChange = (v) => {
    dispatch(Actions.VCProptechs.VCWatchlistPageSizeSet, v)
    dispatch(Actions.VCProptechs.VCWatchlistPageSet, 0)
    // setHomeCount(1)
  }


  return (
    <>
      <div className="proptech-card-wrapper watchlist-card">
        {/*****TODO: Uncomment After Exclusive account functionality******/}
        {/* <div className="watchlist-data">
          <div className="left">
            <img src={IcStar} alt="Icon" />
            2/3  <span><strong>W</strong>atchlist spots filled</span>
          </div>
          <div className="right">
            <span>Upgrade to Exclusive for more</span>
            <PTButton label='Upgrade' className="normal-case fill-yellow btn" />
          </div>
        </div> */}
        {
          vcWatchList?.rows && vcWatchList?.rows.length ? vcWatchList?.rows.map((proptech) => {
            return (<ProptechCard proptech={proptech} key={proptech?.id} tab={tab} />)
          }) : null
        }
      </div>
      <div className='flex justify-between items-center'>
        {vcWatchList?.count ? <span className='text-sm font-normal'>All values in USD</span> : null}
        {vcWatchList?.count ?
          <Pagination
            length={vcWatchList?.count}
            tab="Proptechs"
            page={vcWatchListPage}
            size={vcWatchListPageSize}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
          : null}
      </div>
    </>
  )
}