import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@material-ui/icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { sampleBulkUploadFile } from "../../constants";
import PTButton from "../../../components/GenericComponents/PTButton";

export default function BulkUploadModal(props) {
  const { open, handleCloseModal, handleSuccess } = props;
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    let selectedFile = e.dataTransfer.files[0];
    let files = e.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop().toLowerCase();
      if (file.type !== "application/vnd.ms-excel" && extension !== "xlsx") {
        // Invalid file type or extension
        selectedFile = ""; // Clear the file input
        alert("Please select only .xls or .xlsx files.");
        return;
      }

      if (file.webkitRelativePath !== "") {
        // File is a folder
        selectedFile = ""; // Clear the file input
        alert("Please do not select folders.");
        return;
      }
    }
    setFile(selectedFile);
  };

  const handleDownloadFile = () => {
    window.open(sampleBulkUploadFile, "_blank");
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 4,
          border: "none",
          p: 2,
        }}
        className="w-[90%] sm:w-[60%] lg:max-w-[50%]"
      >
        <div>
          <h2 className="text-lg">Proptech bulk upload</h2>
          <p className="label opacity-70 text-sm">upload .xlsx, .xls file</p>
          <IconButton className="close-btn" onClick={handleCloseModal}>
            <CloseOutlined alt="Icon" />
          </IconButton>
        </div>
        <div
          className={`my-2 p-4 flex items-center justify-center text-center h-[300px]`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{
            border:
              file || isDragOver ? "2px dashed #111D43" : "2px dashed grey",
            background: isDragOver ? "lightGrey" : "white",
          }}
        >
          {file ? (
            <div>
              <InsertDriveFileIcon className="text-8xl text-[#111D43]" />
              <p className="text-blue-500">
                {file.name}{" "}
                <span
                  className="text-xs cursor-pointer text-error  underline"
                  onClick={() => setFile(null)}
                >
                  remove
                </span>
              </p>
            </div>
          ) : (
            <div
              style={{
                opacity: isDragOver ? 0.5 : 1,
              }}
            >
              <p className="text-gray-500">Drag a file here</p>
              <p className="text-gray-500 mb-2">or</p>
              <label
                htmlFor="fileInput"
                className="cursor-pointer bg-[#FF4100] text-sm text-white shadow-lg px-4 py-2 rounded hover:opacity-75"
              >
                Browse
              </label>
              <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                accept=".xls,.xlsx"
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          <a
            href="##"
            className="text-sm underline mt-6 cursor-pointer hover:opacity-80"
            onClick={handleDownloadFile}
          >
            Download sample file
          </a>
          <PTButton
            label="Upload"
            disabled={file === null}
            handleOnClickEvent={() => handleSuccess(file)}
            color="primary"
            className="capitalize font-semibold"
          />
        </div>
      </Box>
    </Modal>
  );
}
