import React from 'react';
import { useSelector } from 'react-redux';
import defaultProfile from '../../../assets/Icons/default-profile.svg';
import { DASHBOARD } from '../../constants/dashboard';
import './ProfileBlock.scss'

export default function ProfileBlock() {
  const { profile } = useSelector(state => state.vsDashboard)

  return (
    <div className='dashboard-left-block px-3'>
      <div className="py-8 px-9 rounded-3xl bg-[#FFFFFF] h-full boxshadow-box">
        {/* Top block with logo, and status */}
        <div className="box-border pb-4 md:pb-10 flex flex-col justify-between items-center">
          <div className="w-20 sm:w-40 h-20 sm:h-40 rounded-full flex justify-center items-center cursor-pointer bg-[#E7E8EC] overflow-hidden">
            <img
              src={profile?.profileImage || defaultProfile}
              alt="Logo"
              className="h-full w-full max-w-none object-cover" />
          </div>
          <div className="mt-2 md:mt-10">
            <div className="box-border font-semibold text-lg leading-5 text-center not-italic text-[#111D43]">{profile.name}</div>
            <div className="font-semibold mt-1 text-xs leading-4 text-center not-italic uppercase">
              <span className="text-[#111D43] opacity-40">{`${DASHBOARD.status} `}</span>
              {profile?.status === 1 ?
                <span className="text-[#39B54A] opacity-100">{DASHBOARD.active}</span> :
                <span className="text-[#FF4100] opacity-100">{DASHBOARD.inactive}
                </span>
              }
            </div>
          </div>
        </div>
        <div className="border-t" />
        <div>
          {/* Profile details */}
          <div className="mt-4 md:mt-10">
            <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.company}</div>
            <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43]">{profile.companyName}</div>
          </div>
          <div className="mt-4 md:mt-10">
            <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.ref}</div>
            <div className="mt-2 font-normal text-sm leading-5 text-center not-italic capitalize text-[#111D43]">{profile.code}</div>
          </div>
          <div className="mt-4 md:mt-10">
            <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.email}</div>
            <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43] break-words">{profile.email}</div>
          </div>
          <div className="mt-4 md:mt-10">
            <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.phone}</div>
            <div className="mt-2 font-normal text-sm leading-5 text-center not-italic capitalize text-[#111D43]">{profile.phone}</div>
          </div>
          <div className="mt-4 md:mt-10">
            <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.linkedIn}</div>
            <div className="mt-2 font-normal text-sm leading-5 text-center not-italic text-[#111D43] break-words">{profile.linkedInURL}</div>
          </div>
          <div className="mt-4 md:mt-10">
            <div className="font-semibold text-xs leading-3 text-center not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.country}</div>
            <div className="mt-2 font-normal text-sm leading-5 text-center not-italic capitalize text-[#111D43]">{profile?.country?.name}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
