import { ADMIN_PROPTECHS, } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { dispatch } from "../../../utils/utility/store"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { getAssetLifeCyclesData, getDefensibilityTimingsData, getKeyBizModelsData,getChargingModelsData, getValueKPIsData, getSectorsData, getTechOfferingsData, getUsecasesData, getTechProjectScalesData, getRegionsData, getTechProjectRangeData, getTechContactPointVendorsData, getProjectRisksData, getIntegrationsData } from "../dropdowns"
import { companyProfileTabDTO } from "../dtos/companyProfileTab"

export const loadCompanyProfileTabData = (id, adminAnalysisTab, adminDropdowns) => {
  if (!adminAnalysisTab.companyProfileTabData) {
    getCompanyProfileDetails(id)
  }
  loadCompanyProfileTabDropdowns(adminDropdowns.assetLifeCycles, adminDropdowns.defensibilityTimings, adminDropdowns.keyBizModels, adminDropdowns.chargingModels, adminDropdowns.valueKPIs, adminDropdowns.sectors, adminDropdowns.useCases, adminDropdowns.techOfferings, adminDropdowns.contactPointVendors, adminDropdowns.projectRange, adminDropdowns.projectScales, adminDropdowns.regions, adminDropdowns.projectRisks,
    adminDropdowns.integrations)
}

export const getCompanyProfileDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  ADMIN_API().get(ADMIN_PROPTECHS + `/${id}/companyProfile`)
    .then(res => {
      dispatch(Actions.AdminUser.SetLoading, false)
      dispatch(Actions.AdminAnalysisTab.SetCompanyProfileTabData, companyProfileTabDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err)
    })
}

export const updateCompanyProfileDetails = (id, data, isArchived, activeSort, sortOrder, search) => {
  dispatch(Actions.AdminUser.SetLoading, true)
  return ADMIN_API().put(ADMIN_PROPTECHS + `/${id}/companyProfile`, data)
    .then(res => {
      handleSuccess(res)
      getCompanyProfileDetails(id)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.AdminUser.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const loadCompanyProfileTabDropdowns = (assetLifeCycles, defensibilityTimings, keyBizModels, chargingModels, valueKPIs, sectors, useCases, techOfferings, contactPointVendors, projectRange, projectScales, regions, projectRisks, integrations) => {
  if (!assetLifeCycles.length) {
    getAssetLifeCyclesData();
  }
  if (!defensibilityTimings.length) {
    getDefensibilityTimingsData();
  }
  if (!keyBizModels.length) {
    getKeyBizModelsData();
  }
  if (!chargingModels.length) {
    getChargingModelsData();
  }
  if (!valueKPIs.length) {
    getValueKPIsData();
  }
  if (!sectors.length) {
    getSectorsData();
  }
  if (!useCases.length) {
    getUsecasesData();
  }
  if (!techOfferings.length) {
    getTechOfferingsData();
  }
  if(!contactPointVendors.length) {
    getTechContactPointVendorsData()
  }
  if(!projectRange?.length) {
    getTechProjectRangeData()
  }
  if(!projectScales?.length) {
    getTechProjectScalesData()
  }
  if(!regions?.length) {
    getRegionsData()
  }
  if (!projectRisks.length) {
    getProjectRisksData();
  }
  if (!integrations.length) {
    getIntegrationsData();
  }
}