export default function adminProjectTab(
  initialState = {
    activeProjectTab: 0,
    companyOverviewData: null,
    strategyDiagnosticData: null,
    assessmentCriteriaData: null,
    transformationData: null,
    implementationData: null,
    adoptionData: null,
    attachmentsData: null,
  },
  action
) {
  switch (action.type) {
    case "SET_ACTIVE_PROJECT_TAB":
      return {
        ...initialState,
        activeProjectTab: action.payload,
      };

    case "SET_COMPANY_OVERVIEW_TAB_DATA":
      return {
        ...initialState,
        companyOverviewData: action.payload,
      };

    case "SET_STRATEGY_DIAGNOSTIC_TAB_DATA":
      return {
        ...initialState,
        strategyDiagnosticData: action.payload,
      };

    case "SET_ASSESSMENT_CRITERIA_TAB_DATA":
      return {
        ...initialState,
        assessmentCriteriaData: action.payload,
      };

    case "SET_TRANSFORMATION_TAB_DATA":
      return {
        ...initialState,
        transformationData: action.payload,
      };

    case "SET_IMPLEMENTATION_TAB_DATA":
      return {
        ...initialState,
        implementationData: action.payload,
      };

    case "SET_ADOPTION_TAB_DATA":
      return {
        ...initialState,
        adoptionData: action.payload,
      };

    case "SET_ATTACHMENTS_TAB_DATA":
      return {
        ...initialState,
        attachmentsData: action.payload,
      };

    case "ADMIN_PROJECT_TAB_RESET":
      return {
        ...initialState,
        activeProjectTab: 0,
        companyOverviewData: null,
        strategyDiagnosticData: null,
        assessmentCriteriaData: null,
        transformationData: null,
        implementationData: null,
        adoptionData: null,
        attachmentsData: null,
      };

    default:
      return initialState;
  }
}
