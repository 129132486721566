import React from 'react'

export default function ProptechCardLabel(props) {
  //  **** props ****
  const {
    header,
    label,
    style,
    className,
  } = props

  return (
    <div className={`flex items-center flex-col xlg:flex-row ${className}`} style={style}>
      <span className='mr-1 font-medium leading-5 not-italic text-[9px] opacity-60'>{header}</span>
      <span className={`font-semibold leading-3 not-italic uppercase text-[9px]`}>{label}</span>
    </div>
  )
}
