export function teamTabVCDTO(data) {
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    productVideo: data?.productVideo || '',
    yearFounded: data?.yearFounded || '-',
    noOfOffices: data?.noOfOffices || '-',
    award1: data?.award1 || '-',
    award1Image: data?.award1Image || '',
    award2: data?.award2 || '-',
    award2Image: data?.award2Image || '',
    award3: data?.award3 || '-',
    award3Image: data?.award3Image || '',
    location1: data?.location1 || '',
    location1Latitude: data?.location1Latitude || '',
    location1Longitude: data?.location1Longitude || '',
    location2: data?.location2 || '',
    location2Latitude: data?.location2Latitude || '',
    location2Longitude: data?.location2Longitude || '',
    location3: data?.location3 || '',
    location3Latitude: data?.location3Latitude || '',
    location3Longitude: data?.location3Longitude || '',
    foundedYearIcon: data?.foundedYearIcon || null,
    noOfOfficesIcon: data?.noOfOfficesIcon || null,
    locations: [
      { latitude: data?.location1Latitude || undefined, longitude: data?.location1Longitude || undefined, title: data?.location1 || undefined },
      { latitude: data?.location2Latitude || undefined, longitude: data?.location2Longitude || undefined, title: data?.location2 || undefined },
      { latitude: data?.location3Latitude || undefined, longitude: data?.location3Longitude || undefined, title: data?.location3 || undefined },
    ],
    proptechCompanyDetails: {
      id: data?.proptechCompanyDetails?.id || 0,
      teamStrengthAdmin: data?.proptechCompanyDetails?.teamStrengthAdmin || '-',
    },
    proptechAnalysisNoOfEmployee: {
      id: data?.proptechAnalysisNoOfEmployee?.id || 0,
      icon: data?.proptechAnalysisNoOfEmployee?.icon || null,
      name: data?.proptechAnalysisNoOfEmployee?.name || '-',
    },
    proptechAnalysisTeamSkill1: {
      id: data?.proptechAnalysisTeamSkill1?.id || 0,
      icon: data?.proptechAnalysisTeamSkill1?.icon || null,
      name: data?.proptechAnalysisTeamSkill1?.name || '-',
    },
    proptechAnalysisTeamSkill2: {
      id: data?.proptechAnalysisTeamSkill2?.id || 0,
      icon: data?.proptechAnalysisTeamSkill2?.icon || null,
      name: data?.proptechAnalysisTeamSkill2?.name || '-',
    },
    proptechAnalysisTeamSkill3: {
      id: data?.proptechAnalysisTeamSkill3?.id || 0,
      icon: data?.proptechAnalysisTeamSkill3?.icon || null,
      name: data?.proptechAnalysisTeamSkill3?.name || '-',
    },
    proptechAnalysisKeyHireMapping1: {
      id: data?.proptechAnalysisKeyHireMapping1?.id || 0,
      icon: data?.proptechAnalysisKeyHireMapping1?.icon || null,
      name: data?.proptechAnalysisKeyHireMapping1?.name || '-',
    },
    proptechAnalysisKeyHireMapping2: {
      id: data?.proptechAnalysisKeyHireMapping2?.id || 0,
      icon: data?.proptechAnalysisKeyHireMapping2?.icon || null,
      name: data?.proptechAnalysisKeyHireMapping2?.name || '-',
    },
    proptechAnalysisKeyHireMapping3: {
      id: data?.proptechAnalysisKeyHireMapping3?.id || 0,
      icon: data?.proptechAnalysisKeyHireMapping3?.icon || null,
      name: data?.proptechAnalysisKeyHireMapping3?.name || '-',
    },
  }
}
