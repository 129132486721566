import { PROPTECH_COUNTRY_API, PROPTECH_CURRENCY_API, PROPTECH_INVESTMENT_RANGE_API, PROPTECH_INVESTMENT_ROUND_API } from "../../../constants/APIs"
import { PROPTECH_API } from "../../utility/API-call"

export const getCountriesData = (setCountries) => {
  PROPTECH_API().get(PROPTECH_COUNTRY_API)
    .then(res => {
      if (res.data.data) {
        setCountries(res.data.data)
      }
    })
    .catch((error) => console.log(error))
}

export const getCurrenciesData = (setCurrencies) => {
  PROPTECH_API().get(PROPTECH_CURRENCY_API)
    .then(res => {
      if (res.data.data) {
        setCurrencies(res.data.data)
      }
    })
    .catch((error) => console.log(error))
}

export const getInvestmentRoundsData = (setInvestmentRounds) => {
  PROPTECH_API().get(PROPTECH_INVESTMENT_ROUND_API)
    .then(res => {
      if (res.data.data) {
        setInvestmentRounds(res.data.data)
      }
    })
    .catch((error) => console.log(error))
}

export const getInvestmentRangesData = (setInvestmentRanges) => {
  PROPTECH_API().get(PROPTECH_INVESTMENT_RANGE_API)
    .then(res => {
      if (res.data.data) {
        setInvestmentRanges(res.data.data)
      }
    })
    .catch((error) => console.log(error))
}