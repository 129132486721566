export const DASHBOARD = {
  yourNum: 'Your numbers',
  status: 'Status',
  active: 'Active',
  inactive: 'Inactive',
  company: 'Company',
  ref: 'reference #',
  email: 'business email',
  phone: 'business phone',
  linkedIn: 'linkedin url',
  country: 'Country',
  navbar: {
    changePass: 'Change password',
    contactUs: 'If your details need updating, contact us at',
    welcome: 'welcome'
  },
  recommendblock: {
    yourRec: 'Your recommendations',
    onboard: 'proptechs onboarded / rejected',
    heroZero: 'hero / zero',
    meetingCreated: 'meetings created / cancelled'
  }
}

export const NAVBAR_MENU_STYLE = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 100,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

export const CALENDER_STYLE = {
  width: '150px',
  height: '30px',
  fontSize: '14px',
  borderColor: 'rgba(17, 29, 67, 0.1)',
  'input': {
    padding: '6px 10px',
    paddingRight: '0px',
    fontSize: '14px',
  },
  'svg': {
    width: '14px',
    height: '14px',
  }
}

export const Upcoming_Day_Style = {
  backgroundColor: '#FFFFFF !important',
  color: '#70778E !important',
  borderTopLeftRadius: "50%",
  borderBottomLeftRadius: "50%",
  borderTopRightRadius: "50%",
  borderBottomRightRadius: "50%"
}

export const Previous_Day_Style = {
  backgroundColor: 'rgba(255, 65, 0, 0.2) !important',
  color: '#70778E !important',
  borderTopLeftRadius: "50%",
  borderBottomLeftRadius: "50%",
  borderTopRightRadius: "50%",
  borderBottomRightRadius: "50%"
}

export const COPYRIGHT = 'Connect by PTC is a company by The Proptech Connection © Copyright 2022. Please see our Privacy Policy';