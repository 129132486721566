import { List, ListItem } from '@mui/material';
import React, { useState } from 'react';
import { DateFormat } from '../../../../constants/General';
import { formatTime } from '../../../../utils/utility/utility';
import { INVESTOR_INTERACTIONS_TAB_LABELS } from '../../../constants';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PlayVideo from '../../PlayVideo';
import './InvestorCardDetails.scss';

export default function InvestorCardDetails(props) {
  // Props
  const { proptech, onCardClick } = props

  // State
  const [videoPlayer, setVideoPlayer] = useState(false)

  return (
    <>
      <div className="investor-card-outer border-t border-[#70778E]">
        <div className="investor-card-inner flex flex-wrap xl:p-11 p-[25px]">
          <div className="xl:flex-[0_0_160px] xl:max-w-[160px] flex-[0_0_120px] max-w-[120px] text-center">
            <img src={proptech?.companyLogo} alt="Icon" className="info-icon xl:w-[160px] xl:h-[160px] w-[120px] h-[120px] rounded-full object-cover xl:mb-[33px] mb-[20px]" />
            <div className="button-wrapper">
              <PTButton
                label={'Play Video'}
                color='primary'
                size='large'
                handleOnClickEvent={() => setVideoPlayer(true)}
                className='text-[10px] btn uppercase tracking-[0.07em] blue-border w-[120px] xl:mb-[12px] mb-[8px]' />
              <PTButton
                label={'View Profile'}
                color='primary'
                size='large'
                handleOnClickEvent={onCardClick}
                className='text-[10px] btn uppercase tracking-[0.07em] fill-blue-admin w-[120px]' />
            </div>
          </div>
          <div className="xl:flex-[0_0_calc(100%-160px)] xl:max-w-[calc(100%-160px)] flex-[0_0_calc(100%-120px)] max-w-[calc(100%-120px)] xl:pl-[43px] pl-[25px] flex flex-col justify-between">
            <div className="xl:pb-8 xl:mb-8 pb-[20px] mb-[20px] border-b border-[#70778E]">
              <h3 className="text-lg leading-5 font-semibold text-[#111D43] mb-4 pt-2.5">{proptech?.proptechCompany?.name}</h3>
              <p className="text-xs leading-[1.6] text-[#111D43]">{proptech?.elevatorPitchAdmin}</p>
            </div>
            <List className="p-0 flex flex-wrap mx-[-10px] mb-[-16px] investor-details-list">
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.country}</span>
                <p className="investor-card-details-value">{proptech?.country?.name}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.stage}</span>
                <p className="investor-card-details-value">{proptech?.investmentRound?.name}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.publishDate}</span>
                <p className="investor-card-details-value">{formatTime(proptech?.publishedAt, DateFormat.DayMonthFormat)}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.meetingsBooked}</span>
                <p className="investor-card-details-value">{proptech?.meetingCount}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.language}</span>
                <p className="investor-card-details-value">{proptech?.language?.name}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.amount}</span>
                <p className="investor-card-details-value">{proptech?.revenueRange?.name}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.archiveDate}</span>
                <p className="investor-card-details-value">{proptech?.archivedAt ? formatTime(proptech?.archivedAt, DateFormat.DayMonthFormat) : '-'}</p>
              </ListItem>
              <ListItem className="px-[10px] py-0 block flex-[0_0_25%] max-w-[25%] mb-4">
                <span className="text-[10px] mb-[2px] text-[rgba(17,29,67,0.4)] uppercase font-semibold block">{INVESTOR_INTERACTIONS_TAB_LABELS.meetingDates}</span>
                <p className="text-[10px] text-[#111D43] capitalize inline-block bg-[rgba(17,29,67,0.1)] rounded-[32px] py-1.5 px-3 mt-1">{proptech?.lastMeetingTime ? formatTime(proptech?.lastMeetingTime, DateFormat.DayMonthFormat) : '-'}</p>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
      {videoPlayer && <PlayVideo videoURL={proptech?.founderPitchVideo} closeIconClick={() => setVideoPlayer(false)} />}
    </>
  )
}
