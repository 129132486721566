export default function proptechUser(
  initialState = {
    isLoading: false,
    user: null,
    companyDetails: null,
    notificationCheckUnread: false,
    notifications: [],
    plans: []
  },
  action
) {
  switch (action.type) {
    case 'PROPTECH_USER_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'PROPTECH_USER_SET':
      return {
        ...initialState,
        user: action.payload,
      };

    case 'PROPTECH_USER_REMOVE':
      return {
        ...initialState,
        user: null,
      };

    case 'PROPTECH_SET_COMPANY_DETAILS':
      return {
        ...initialState,
        companyDetails: action.payload,
      };

    case 'PROPTECH_REMOVE_COMPANY_DETAILS':
      return {
        ...initialState,
        companyDetails: null,
      };

    case 'PROPTECH_NOTIFICATION_CHECK_UNREAD':
      return {
        ...initialState,
        notificationCheckUnread: action.payload,
      };

    case 'PROPTECH_SET_NOTIFICATIONS':
      return {
        ...initialState,
        notifications: action.payload,
      };

    case 'PROPTECH_SET_PLANS':
      return {
        ...initialState,
        plans: action.payload,
      };

    case 'PROPTECH_USER_RESET':
      return {
        ...initialState,
        isLoading: false,
        user: null,
        companyDetails: null,
        notificationCheckUnread: false,
        notifications: [],
        plans: []
      };

    default:
      return initialState;
  }
}
