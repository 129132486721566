import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from '../../Chart';
import { KeyFeatureLabel } from '../../Label';
import { numbersChartData, numbersChartLabels } from '../../../utils/chartData';
import './style.scss';
import InfoIcon from '../../../../components/InfoIcon';
import { Radar_Chart_Labels } from '../../../constants';
import { TooltipText } from '../../../../constants/General';

export default function NumberTab() {
  // Store
  const { numbersDetails } = useSelector(state => state.vcProptechDetails)
  const { id } = useSelector((state) => state.publicProptechUser);

  const [handleInfo, setHandleInfo] = useState([])

  return (
    <>
      {numbersDetails ?
        <>
          <div className='text-xs font-normal mb-3 font-["Poppins"] text-["#F7F7F7"]'>Note: All #s in USD</div>
          <div className={`tabpanel-wrapper number-tab ${ id && "!max-w-full"}`}>
            <div className="left_col">
              <div className='flex items-start'>
                {/* Traction block */}
                <h2 className='pr-4'>Traction</h2>
                <InfoIcon
                  label={TooltipText.traction}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <p>{numbersDetails?.proptechCompanyDetails?.futureGoalsAdmin}</p>
              {/* Customers, Countries, Gross margin block */}
              <div className="number-calculation sm:max-w-[434px]">
                <ul>
                  <li className="title-number">
                    <div></div>
                    <div>
                      <span>Current</span>
                    </div>
                    <div>
                      <span>Projected</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span>Customers</span>
                    </div>
                    <div>
                      <span className="gray-bg item-square">{numbersDetails?.proptechAnalysisCurrentNoOfCustomer?.name}</span>
                    </div>
                    <div>
                      <span className="red-bg item-square">{numbersDetails?.proptechAnalysisFutureNoOfCustomer?.name}</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span>Countries</span>
                    </div>
                    <div>
                      <span className="gray-bg item-square">{numbersDetails?.proptechAnalysisCurrentNoOfCountry?.name}</span>
                    </div>
                    <div>
                      <span className="red-bg item-square">{numbersDetails?.proptechAnalysisFutureNoOfCountry?.name}</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span>Gross margin</span>
                    </div>
                    <div>
                      <span className="gray-bg item-square">{numbersDetails?.proptechAnalysisCurrentGrossMargin?.name}</span>
                    </div>
                    <div>
                      <span className="red-bg item-square">{numbersDetails?.proptechAnalysisFutureGrossMargin?.name}</span>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Growth blockers block */}
              <div className="detail-sector-wrapper">
                <div className="detail-sectors">
                  <div className='flex items-start'>
                    <h2 className='pr-4'>Growth blockers</h2>
                    <InfoIcon
                      label={TooltipText.growthBlockers}
                      title={TooltipText.growthBlockers}
                      info={handleInfo}
                      handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                      handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                    />
                  </div>
                  <ul className="menu-item">
                    <KeyFeatureLabel icon={numbersDetails?.proptechAnalysisGrowthBlocker1?.icon} label={numbersDetails?.proptechAnalysisGrowthBlocker1?.name} />
                    <KeyFeatureLabel icon={numbersDetails?.proptechAnalysisGrowthBlocker2?.icon} label={numbersDetails?.proptechAnalysisGrowthBlocker2?.name} />
                    <KeyFeatureLabel icon={numbersDetails?.proptechAnalysisGrowthBlocker3?.icon} label={numbersDetails?.proptechAnalysisGrowthBlocker3?.name} />
                  </ul>
                </div>
              </div>
            </div>
            {/* Numbers Chart block */}
            <div className="right_col" onClick={(e) => e.stopPropagation()}>
              <div className='flex items-start'>
                <h2 className='pr-4'>{Radar_Chart_Labels.numbers}</h2>
                <InfoIcon
                  label={TooltipText.numbersRadar}
                  info={handleInfo}
                  handleTooltipClose={(v) => setHandleInfo(handleInfo.filter((i) => handleInfo.indexOf(i) === -1))}
                  handleTooltipOpen={(v) => setHandleInfo([...handleInfo, v])}
                />
              </div>
              <div className="chart-wrapper">
                <Chart
                  items={numbersChartData.map((val) => numbersDetails[val])}
                  labels={numbersChartLabels}
                  step={2}
                  label={'Rating'}
                  maximumValue={10}
                />
              </div>
            </div>
          </div>
        </>
        : null}
    </>
  )
}
