export default function adminTechBuyer(
  initialState = {
    isLoading: false,
    techBuyers: [],
    isTechBuyerSelected: false,
    selectedTechBuyer: null,
    activeSort: 'createdAt',
    sortOrder: 'DESC',
    isArchived: false,
    search: '',
    searchProptech: '',
    activeTab: 0,
    profileTabData: null,
    selectedTags: [],
    proptechs: null,
    forYouProptech: null,
    homeProptech: null,
    watchlistProptechs: [],
    interactionFilter: 'total',
    interactionsProptechs: [],
    navigationTabs: [],
    selectedProptechTabs: [],
    activePermissionsTab: 0
  },
  action
) {
  switch (action.type) {
    case 'ADMIN_TECH_BUYERS_SET_LOADING':
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case 'ADMIN_TECH_BUYERS_LIST_SET':
      return {
        ...initialState,
        techBuyers: action.payload,
        isLoading: false,
      };

    case 'SELECTED_ADMIN_TECH_BUYER_SET':
      return {
        ...initialState,
        selectedTechBuyer: action.payload,
      };

    case 'SET_IS_SELECTED_ADMIN_TECH_BUYER':
      return {
        ...initialState,
        isTechBuyerSelected: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_IS_ARCHIVED':
      return {
        ...initialState,
        isArchived: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_SEARCH_PROPTECH':
      return {
        ...initialState,
        searchProptech: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_ACTIVE_TAB':
      return {
        ...initialState,
        activeTab: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_PROFILE_TAB_DATA':
      return {
        ...initialState,
        profileTabData: action.payload,
      };

    case 'SET_ADMIN_SELECTED_TAGS_TECH_BUYERS_PROFILE_TAB_DATA':
      return {
        ...initialState,
        selectedTags: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_PROPTECHS_DATA':
      return {
        ...initialState,
        proptechs: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_FOR_YOU_PROPTECHS_DATA':
      return {
        ...initialState,
        forYouProptech: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_HOME_PROPTECHS_DATA':
      return {
        ...initialState,
        homeProptech: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_WATCHLIST_PROPTECHS':
      return {
        ...initialState,
        watchlistProptechs: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_INTERACTIONS_FILTER':
      return {
        ...initialState,
        interactionFilter: action.payload,
      };

    case 'SET_ADMIN_TECH_BUYERS_INTERACTIONS_PROPTECHS':
      return {
        ...initialState,
        interactionsProptechs: action.payload,
      };

    case 'SET_ADMIN_NAV_TB_TABS':
      return {
        ...initialState,
        navigationTabs: action.payload,
      };
  
    case 'SET_ADMIN_SELECTED_TB_PROPTECH_TABS':
        return {
        ...initialState,
        selectedProptechTabs: action.payload,
      };
  
    case 'SET_ADMIN_PERMISSIONS_ACTIVE_TAB':
      return {
        ...initialState,
        activePermissionsTab: action.payload,
      };

    case 'ADMIN_TECH_BUYERS_RESET':
      return {
        ...initialState,
        isLoading: false,
        techBuyers: [],
        isTechBuyerSelected: false,
        selectedTechBuyer: null,
        activeSort: 'createdAt',
        sortOrder: 'DESC',
        isArchived: false,
        search: '',
        searchProptech: '',
        activeTab: 0,
        profileTabData: null,
        selectedTags: [],
        proptechs: null,
        forYouProptech: null,
        homeProptech: null,
        watchlistProptechs: [],
        interactionFilter: 'total',
        interactionsProptechs: [],
        navigationTabs: [],
        selectedProptechTabs: [],
        activePermissionsTab: 0
      };

    default:
      return initialState;
  }
}
