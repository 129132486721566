import { MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PTButton from '../../../../components/GenericComponents/PTButton'
import PTInput from '../../../../components/GenericComponents/PTInput'
import Loader from '../../../../components/Loading'
import { LabelStyle } from '../../../../proptech/constants/General'
import { Actions } from '../../../../store/actions'
import { dispatch } from '../../../../utils/utility/store'
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab'
import { MARKET_INSIGHTS_TAB_ERRORS, MARKET_INSIGHTS_TAB_PAYLOAD } from '../../../utils/payloads/payloads'
import DropdownColumn from '../../DropdownColumn'
import { setActiveMarketInsightTabData } from '../../../utils/middleware/AdminMarketInsightReports'

export default function MarketInsightsTab(props) {
  const { setUnsaveChange } = props
  // Store
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const marketInsightsTabData = useSelector(state => state.adminAnalysisTab.marketInsightsTabData)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { trends, predictions, growth, needToKnow } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(MARKET_INSIGHTS_TAB_PAYLOAD);
  const [errors] = useState(MARKET_INSIGHTS_TAB_ERRORS);


  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToReports = () => {
    dispatch(Actions.Admin.SetActiveAdminTab, 3)
    if(marketInsightsTabData?.id) {
        dispatch(Actions.Admin.SetActiveAdminTab, 3)
        dispatch(Actions.Admin.SetIsRedirect, true)
        dispatch(Actions.AdminMarketInsightReports.SetIsSelectedMarketInsightReport, true)
        dispatch(Actions.AdminMarketInsightReports.SetSelectedMarketInsightReport,  marketInsightsTabData)
        setActiveMarketInsightTabData(0, marketInsightsTabData?.id, marketInsightsTabData, adminDropdowns)
    }
  }

  const allInputs = () => {
    return <>
      <div >
        <PTButton variant="contained"
          label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Edit</span>}
          className='w-[142px] h-[44px] mr-5 !rounded-xl float-right !border-solid !bg-[#111D43]'
          handleOnClickEvent={redirectToReports}
        />
      </div>
        <div className='analysis-dropdown-wrapper horizontal'>
        <div className='mb-[25px]'>
          <PTInput
            labelStyle={LabelStyle}
            variant="standard"
            label="Market insight report name"
            placeholder=''
            fullWidth
            multiline
            maxRows={4}
            maxLength={255}
            value={marketInsightsTabData?.name || ''}
            handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, name: e.target.value })}
            error={errors.name}
            helperText={errors.name && "Martket insight report name Required."}
            disabled
          />
        </div>
      </div>
    <div className='mb-[28px] flex justify-between analysis-dropdown-wrapper'>
        <DropdownColumn
          header="Trends"
          dropdown1Label="Trend 1"
          dropdown1Options={trends}
          dropdown1Value={editedDetails?.trend1 || marketInsightsTabData?.trend1}
          dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, trend1: e.target.value })}
          dropdown1Error={errors.trend1}
          dropdown1HelperText={errors.trend1 && "Trend 1 Required."}
          dropdown2Label="Trend 2"
          dropdown2Options={trends}
          dropdown2Value={editedDetails?.trend2 || marketInsightsTabData?.trend2}
          dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, trend2: e.target.value })}
          dropdown2Error={errors.trend2}
          dropdown2HelperText={errors.trend2 && "Trend 2 Required."}
          dropdown3Label="Trend 3"
          dropdown3Options={trends}
          dropdown3Value={editedDetails?.trend3 || marketInsightsTabData?.trend3}
          dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, trend3: e.target.value })}
          dropdown3Error={errors.trend3}
          dropdown3HelperText={errors.trend3 && "Trend 3 Required."}
          disabled
        />
        <DropdownColumn
          header="Predictions"
          dropdown1Label="Prediction 1"
          dropdown1Options={predictions}
          dropdown1Value={editedDetails?.prediction1 || marketInsightsTabData?.prediction1}
          dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, prediction1: e.target.value })}
          dropdown1Error={errors.prediction1}
          dropdown1HelperText={errors.prediction1 && "Prediction 1 Required."}
          dropdown2Label="Prediction 2"
          dropdown2Options={predictions}
          dropdown2Value={editedDetails?.prediction2 || marketInsightsTabData?.prediction2}
          dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, prediction2: e.target.value })}
          dropdown2Error={errors.prediction2}
          dropdown2HelperText={errors.prediction2 && "Prediction 2 Required."}
          dropdown3Label="Prediction 3"
          dropdown3Options={predictions}
          dropdown3Value={editedDetails?.prediction3 || marketInsightsTabData?.prediction3}
          dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, prediction3: e.target.value })}
          dropdown3Error={errors.prediction3}
          dropdown3HelperText={errors.prediction3 && "Prediction 3 Required."}
          disabled
        />
        <DropdownColumn
          header="Growth"
          dropdown1Label="Growth 1"
          dropdown1Options={growth}
          dropdown1Value={editedDetails?.growth1 || marketInsightsTabData?.growth1}
          dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, growth1: e.target.value })}
          dropdown1Error={errors.growth1}
          dropdown1HelperText={errors.growth1 && "Growth 1 Required."}
          dropdown2Label="Growth 2"
          dropdown2Options={growth}
          dropdown2Value={editedDetails?.growth2 || marketInsightsTabData?.growth2}
          dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, growth2: e.target.value })}
          dropdown2Error={errors.growth2}
          dropdown2HelperText={errors.growth2 && "Growth 2 Required."}
          dropdown3Label="Growth 3"
          dropdown3Options={growth}
          dropdown3Value={editedDetails?.growth3 || marketInsightsTabData?.growth3}
          dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, growth3: e.target.value })}
          dropdown3Error={errors.growth3}
          dropdown3HelperText={errors.growth3 && "Growth 3 Required."}
          disabled
        />
      </div>
      <div className='analysis-dropdown-wrapper horizontal'>
        <div className="dropdown-title not-italic text-[#111D43]">Where the market is going</div>
        <div className='mb-[25px]'>
          <PTInput
            disabled
            labelStyle={LabelStyle}
            variant="standard"
            label="Future Synopsis"
            placeholder=''
            fullWidth
            multiline
            maxRows={4}
            maxLength={255}
            value={ marketInsightsTabData?.futureSynopsis || ''}
            handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, futureSynopsis: e.target.value })}
            error={errors.futureSynopsis}
            helperText={errors.futureSynopsis && "Future Synopsis Required."}
          />
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Future 1 Heading"
              placeholder=''
              value={editedDetails?.future1Heading !== null ? editedDetails?.future1Heading : marketInsightsTabData?.future1Heading || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, future1Heading: e.target.value })}
              error={errors.future1Heading}
              helperText={errors.future1Heading && "Future 1 Heading Required."}
            />
          </div>
          <div className='dropdown-inner2-item'>
            <PTInput
              disabled
              variant="standard"
              label="Future 1 Text"
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.future1 !== null ? editedDetails?.future1 : marketInsightsTabData?.future1 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, future1: e.target.value })}
              error={errors.future1}
              helperText={errors.future1 && "Future 1 Text Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Future 2 Heading"
              placeholder=''
              value={editedDetails?.future2Heading !== null ? editedDetails?.future2Heading : marketInsightsTabData?.future2Heading || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, future2Heading: e.target.value })}
              error={errors.future2Heading}
              helperText={errors.future2Heading && "Future 2 Heading Required."}
            />
          </div>
          <div className='dropdown-inner2-item'>
            <PTInput
              disabled
              variant="standard"
              label="Future 2 Text"
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.future2 !== null ? editedDetails?.future2 : marketInsightsTabData?.future2 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, future2: e.target.value })}
              error={errors.future2}
              helperText={errors.future2 && "Future 2 Text Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Future 3 Heading"
              placeholder=''
              value={editedDetails?.future3Heading !== null ? editedDetails?.future3Heading : marketInsightsTabData?.future3Heading || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, future3Heading: e.target.value })}
              error={errors.future3Heading}
              helperText={errors.future3Heading && "Future 3 Heading Required."}
            />
          </div>
          <div className='dropdown-inner2-item'>
            <PTInput
              disabled
              variant="standard"
              label="Future 3 Text"
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.future3 !== null ? editedDetails?.future3 : marketInsightsTabData?.future3 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, future3: e.target.value })}
              error={errors.future3}
              helperText={errors.future3 && "Future 3 Text Required."}
            />
          </div>
        </div>
      </div>
      <div className='analysis-dropdown-wrapper horizontal mb-[28px]'>
        <div className="dropdown-title not-italic text-[#111D43]">Key metrics</div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Statistics 1 Type"
              placeholder=''
              value={editedDetails?.stat1Type !== null ? editedDetails?.stat1Type : marketInsightsTabData?.stat1Type || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat1Type: e.target.value })}
              error={errors.stat1Type}
              helperText={errors.stat1Type && "Statistics 1 Type Required."}
            />
          </div>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              variant="standard"
              label="Statistics 1 Value"
              placeholder=''
              className="custom-dropdown-icon"
              value={editedDetails?.stat1 !== null ? editedDetails?.stat1 : marketInsightsTabData?.stat1 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat1: e.target.value })}
              error={errors.stat1}
              helperText={errors.stat1 && "Statistics 1 Value Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Statistics 2 Type"
              placeholder=''
              value={editedDetails?.stat2Type !== null ? editedDetails?.stat2Type : marketInsightsTabData?.stat2Type || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat2Type: e.target.value })}
              error={errors.stat2Type}
              helperText={errors.stat2Type && "Statistics 2 Type Required."}
            />
          </div>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              variant="standard"
              label="Statistics 2 Value"
              placeholder=''
              className="custom-dropdown-icon"
              value={editedDetails?.stat2 !== null ? editedDetails?.stat2 : marketInsightsTabData?.stat2 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat2: e.target.value })}
              error={errors.stat2}
              helperText={errors.stat2 && "Statistics 2 Value Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Statistics 3 Type"
              placeholder=''
              value={editedDetails?.stat3Type !== null ? editedDetails?.stat3Type : marketInsightsTabData?.stat3Type || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat3Type: e.target.value })}
              error={errors.stat3Type}
              helperText={errors.stat3Type && "Statistics 3 Type Required."}
            />
          </div>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              variant="standard"
              label="Statistics 3 Value"
              placeholder=''
              className="custom-dropdown-icon"
              value={editedDetails?.stat3 !== null ? editedDetails?.stat3 : marketInsightsTabData?.stat3 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat3: e.target.value })}
              error={errors.stat3}
              helperText={errors.stat3 && "Statistics 3 Value Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Statistics 4 Type"
              placeholder=''
              value={editedDetails?.stat4Type !== null ? editedDetails?.stat4Type : marketInsightsTabData?.stat4Type || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat4Type: e.target.value })}
              error={errors.stat4Type}
              helperText={errors.stat4Type && "Statistics 4 Type Required."}
            />
          </div>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              variant="standard"
              label="Statistics 4 Value"
              placeholder=''
              className="custom-dropdown-icon"
              value={editedDetails?.stat4 !== null ? editedDetails?.stat4 : marketInsightsTabData?.stat4 || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, stat4: e.target.value })}
              error={errors.stat4}
              helperText={errors.stat4 && "Statistics 4 Value Required."}
            />
          </div>
        </div>
      </div>
      <div className='analysis-dropdown-wrapper horizontal'>
        <div className="dropdown-title not-italic text-[#111D43]">Need to know</div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Need To Know 1 Heading"
              placeholder=''
              select
              className="custom-dropdown-icon"
              selectChildren={needToKnow.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
              value={editedDetails?.needToKnow1Heading !== null ? editedDetails?.needToKnow1Heading : marketInsightsTabData?.needToKnow1Heading}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, needToKnow1Heading: e.target.value })}
              error={errors.needToKnow1Heading}
              helperText={errors.needToKnow1Heading && "Need To Know 1 Heading Required."}
            />
          </div>
          <div className='dropdown-inner2-item'>
            <PTInput
              disabled
              variant="standard"
              label="Need To Know 1 Text"
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.needToKnow1Text !== null ? editedDetails?.needToKnow1Text : marketInsightsTabData?.needToKnow1Text || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, needToKnow1Text: e.target.value })}
              error={errors.needToKnow1Text}
              helperText={errors.needToKnow1Text && "Need To Know 1 Text Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Need To Know 2 Heading"
              placeholder=''
              select
              className="custom-dropdown-icon"
              selectChildren={needToKnow.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
              value={editedDetails?.needToKnow2Heading !== null ? editedDetails?.needToKnow2Heading : marketInsightsTabData?.needToKnow2Heading}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, needToKnow2Heading: e.target.value })}
              error={errors.needToKnow2Heading}
              helperText={errors.needToKnow2Heading && "Need To Know 2 Heading Required."}
            />
          </div>
          <div className='dropdown-inner2-item'>
            <PTInput
              disabled
              variant="standard"
              label="Need To Know 2 Text"
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.needToKnow2Text !== null ? editedDetails?.needToKnow2Text : marketInsightsTabData?.needToKnow2Text || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, needToKnow2Text: e.target.value })}
              error={errors.needToKnow2Text}
              helperText={errors.needToKnow2Text && "Need To Know 2 Text Required."}
            />
          </div>
        </div>
        <div className='dropdown-items flex'>
          <div className='dropdown-inner-item'>
            <PTInput
              disabled
              labelStyle={LabelStyle}
              variant="standard"
              label="Need To Know 3 Heading"
              placeholder=''
              select
              className="custom-dropdown-icon"
              selectChildren={needToKnow.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
              value={editedDetails?.needToKnow3Heading !== null ? editedDetails?.needToKnow3Heading : marketInsightsTabData?.needToKnow3Heading}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, needToKnow3Heading: e.target.value })}
              error={errors.needToKnow3Heading}
              helperText={errors.needToKnow3Heading && "Need To Know 3 Heading Required."}
            />
          </div>
          <div className='dropdown-inner2-item'>
            <PTInput
              disabled
              variant="standard"
              label="Need To Know 3 Text"
              placeholder=''
              multiline
              maxRows={4}
              maxLength={255}
              value={editedDetails?.needToKnow3Text !== null ? editedDetails?.needToKnow3Text : marketInsightsTabData?.needToKnow3Text || ''}
              handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, needToKnow3Text: e.target.value })}
              error={errors.needToKnow3Text}
              helperText={errors.needToKnow3Text && "Need To Know 3 Text Required."}
            />
          </div>
        </div>
      </div>
    </>
  }

  return (
    <>
      {isLoading && <Loader />}
      {
        marketInsightsTabData?.id ?
          <div>
            {allInputs()}
          </div>
         : <div className='text-center'>
          <p className='mb-2'>There are currently no attached reports for this proptech.</p>
          <PTButton
            variant="contained"
            label={"Create / Attach Report"}
            handleOnClickEvent={redirectToReports}
        />
        </div>
      }
    </>
  )
}
