import {
  getAssetTypesData,
  getKeyPainPointsData,
  getRegionsData,
  getUsecasesData,
  getValueKPIsData,
} from "../dropdowns";
import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectStrategyTabData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.strategyDiagnosticData) {
    getProjectStrategyDetails(id);
  }
  loadStrategyTabDropDowns(
    adminDropdowns.keyPainPoints,
    adminDropdowns.useCases,
    adminDropdowns.valueKPIs,
    adminDropdowns.assetTypes,
    adminDropdowns.regions
  );
};

export const getProjectStrategyDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/strategy`)
    .then((res) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      dispatch(
        Actions.AdminProjectTab.SetStrategyDiagnosticData,
        res.data.data
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
    });
};

export const updateProjectStrategyDetails = (id, data, regions) => {
  if(regions) {
    data.regions = regions
  }
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_TECH_BUYERS + `/${id}/project/strategy`, data)
    .then((res) => {
      handleSuccess(res);
      getProjectStrategyDetails(id)
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};

export const loadStrategyTabDropDowns = (
  keyPainPoints,
  useCases,
  valueKPIs,
  assetTypes,
  regions
) => {
  if (!keyPainPoints.length) {
    getKeyPainPointsData();
  }
  if (!useCases.length) {
    getUsecasesData();
  }
  if (!valueKPIs.length) {
    getValueKPIsData();
  }
  if (!assetTypes.length) {
    getAssetTypesData();
  }
  if (!regions.length) {
    getRegionsData();
  }
};
