import moment from "moment"
import { VS_CALENDAR_API, VS_CHANGEPASSWORD_API, VS_GRAPH_API, VS_PROFILE_API, VS_STATISTICS_API } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { VS_API } from "../../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar"
import { dispatch } from "../../../../utils/utility/store"
import { calendarDataDTO } from "../../dtos/calendar"
import { vsChartDTO } from "../../dtos/vcChart"
import { vsProfileDTO } from "../../dtos/vsProfile"
import { vsStatisticsDTO } from "../../dtos/vsStatastics"

export const getVSProfile = () => {
  dispatch(Actions.VSDashboard.VSSetLoading, true)
  return VS_API().get(VS_PROFILE_API).then(res => {
    dispatch(Actions.VSDashboard.VSSetLoading, false)
    handleSuccess(res);
    if (res.data.data && res.data.code === 200) {
      dispatch(Actions.VSDashboard.VSDashboardSet, vsProfileDTO(res.data.data))
      return res.data.data;
    }
    return (res.data)
  }).catch((error) => {
    handleError(error)
    dispatch(Actions.VSDashboard.VSSetLoading, false)
  }
  )
}

export const getCalendarData = () => {
  dispatch(Actions.VSDashboard.VSSetLoading, true)
  return VS_API().get(VS_CALENDAR_API).then(res => {
    dispatch(Actions.VSDashboard.VSSetLoading, false)
    handleSuccess(res);
    if (res.data.data && res.data.code === 200) {
      return calendarDataDTO(res.data.data);
    }
    return calendarDataDTO(res.data.data)
  }).catch((error) => {
    handleError(error)
    dispatch(Actions.VSDashboard.VSSetLoading, false)
  }
  )
}

export const getVSStatistics = () => {
  dispatch(Actions.VSDashboard.VSSetLoading, true)
  VS_API().get(VS_STATISTICS_API).then(res => {
    dispatch(Actions.VSDashboard.VSSetLoading, false)
    if (res.data.data && res.data.code === 200) {
      handleSuccess(res);
      dispatch(Actions.VSDashboard.VSSetStatistics, vsStatisticsDTO(res.data.data))
      return res.data.data;
    }
    handleError(res?.data);
    return (res.data)
  }).catch((error) => {
    handleError(error)
    dispatch(Actions.VSDashboard.VSSetLoading, false)
  });
}

export const getVSChartData = (startDate, endDate, setLoading) => {
  setLoading(true);
  const url = `${VS_GRAPH_API}?startMonth=${moment(startDate).month() + 1}&startYear=${moment(startDate).year()}&endMonth=${moment(endDate).month() + 1}&endYear=${moment(endDate).year()}`;
  VS_API().get(url).then(res => {
    setLoading(false)
    if (res.data.data && res.data.code === 200) {
      handleSuccess(res);
      dispatch(Actions.VSDashboard.VSSetChart, vsChartDTO(res.data.data))
      return res.data.data;
    }
    handleError(res?.data);
    return (res.data)
  }).catch((error) => {
    handleError(error)
    setLoading(false)
  })
}

export const changePassword = (payload, resetForm, setChangePasswordModal) => {
  dispatch(Actions.VSDashboard.VSSetLoading, true)
  VS_API().put(VS_CHANGEPASSWORD_API, payload)
    .then(res => {
      dispatch(Actions.VSDashboard.VSSetLoading, false)
      resetForm({ values: '' });
      handleSuccess(res);
      setChangePasswordModal(false)
    })
    .catch((error) => {
      dispatch(Actions.VSDashboard.VSSetLoading, false)
      handleError(error);
    })
}
