export default function vsUser(
  initialState = {
    user: null,
    notificationCheckUnread: false,
    notifications: []
  },
  action
) {
  switch (action.type) {
    case 'VS_USER_SET':
      return {
        ...initialState,
        user: action.payload,
      };

    case 'VS_USER_REMOVE':
      return {
        ...initialState,
        user: null,
      };

    case 'VS_NOTIFICATION_CHECK_UNREAD':
      return {
        ...initialState,
        notificationCheckUnread: action.payload,
      };

    case 'VS_SET_NOTIFICATIONS':
      return {
        ...initialState,
        notifications: action.payload,
      };

    case 'VS_USER_RESET':
      return {
        ...initialState,
        user: null,
        notificationCheckUnread: false,
        notifications: []
      };

    default:
      return initialState;
  }
}
