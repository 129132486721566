import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';
import PTButton from '../../../../components/GenericComponents/PTButton';
import Ratings from '../../../../components/GenericComponents/Ratings';
import DropdownColumn from '../../DropdownColumn';
import { updateMarketDetails } from '../../../utils/middleware/marketTab';
import ConfirmModal from '../../ConfirmModal';
import Loader from '../../../../components/Loading';
import Chart from '../../Chart';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { trimQuery } from '../../../../utils/utility/utility';
import { MARKET_TAB_ERRORS, MARKET_TAB_PAYLOAD } from '../../../utils/payloads/payloads';
import { AnalysisLabelStyle } from '../../../../proptech/constants/General';
import { RATING_CHART } from '../../../constants';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function MarketTab(props) {
  const { setUnsaveChange } = props

  // Store
  const { marketTabData, radarDetails } = useSelector(state => state.adminAnalysisTab)
  const selectedProptech = useSelector(state => state.adminUser.selectedProptech)
  const isLoading = useSelector(state => state.adminUser.isLoading)
  const { techBuyers, chargingModels, valueKPIs } = useSelector(state => state.adminDropdowns)
  // State
  const [editedDetails, setEditedDetails] = useState(MARKET_TAB_PAYLOAD);
  const [errors, setErrors] = useState(MARKET_TAB_ERRORS);
  const [openModal, setOpenModal] = useState(false);
  const [placeRadars, setPlaceRadars] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const setPlaceRadarDetails = () => {
    let updatedData = marketTabData?.placeRadars?.length
      ? radarDetails?.offeringRadar?.rows?.map(i => ({
          ...i,
          value: Number(marketTabData?.placeRadars?.find(j => j.placeRadarPoint.id === i.id)?.value),
        }))
      : radarDetails?.offeringRadar?.rows?.map(i => ({ ...i, value: 0 }));
    setPlaceRadars(updatedData || []);
  };

  useEffect(() => {
    setPlaceRadarDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange, placeRadars, marketTabData?.placeRadars)
  }, [editedDetails, placeRadars]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ? !editedDetails?.[error] : !marketTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = marketTabData?.[key]));

    handleCloseModal()
    try {
      await updateMarketDetails(
        selectedProptech?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => v !== null)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null)),
        }, placeRadars)
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false)
      console.log('error')
    }
  }

  const totalStartCount = () => {
    return (editedDetails?.highDemand !== null ? editedDetails?.highDemand : marketTabData?.highDemand) +
      (editedDetails?.costToScale !== null ? editedDetails?.costToScale : marketTabData?.costToScale) +
      (editedDetails?.competition !== null ? editedDetails?.competition : marketTabData?.competition) +
      (editedDetails?.marketSize !== null ? editedDetails?.marketSize : marketTabData?.marketSize) +
      (editedDetails?.currentPipeline !== null ? editedDetails?.currentPipeline : marketTabData?.currentPipeline) +
      (editedDetails?.geographyAgnostic !== null ? editedDetails?.geographyAgnostic : marketTabData?.geographyAgnostic) || 0
  }

  const onChangePlaceRadarValue = (id, val) => {
    setPlaceRadars(placeRadars?.map(i => (i.id === id ? { ...i, value: val } : i)));
  };

  const totalPlaceRadarsCount = () => {
    return placeRadars.reduce((sum, item) => sum + Number(item.value), 0);
  }

  const onResetRadarPoint = (id) => {
    setPlaceRadars(placeRadars.map(i => (i.id === id ? { ...i, value: 0 } : i)));
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className='flex flex-col justify-between pb-7'>
        <h2 className='mb-2'>Connect radar</h2>
        <div className='analysis-rating-chart-block flex'>
          <div className='rating-block'>
            <Ratings className='flex justify-between rating-items' label="High demand" value={editedDetails?.highDemand !== null ? editedDetails?.highDemand : marketTabData?.highDemand} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, highDemand: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, highDemand: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Cost to scale" value={editedDetails?.costToScale !== null ? editedDetails?.costToScale : marketTabData?.costToScale} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, costToScale: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, costToScale: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Competition" value={editedDetails?.competition !== null ? editedDetails?.competition : marketTabData?.competition} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, competition: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, competition: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Market size" value={editedDetails?.marketSize !== null ? editedDetails?.marketSize : marketTabData?.marketSize} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, marketSize: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, marketSize: 0 })} />
            <Ratings className='flex justify-between rating-items' label="Current pipeline" value={editedDetails?.currentPipeline !== null ? editedDetails?.currentPipeline : marketTabData?.currentPipeline} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, currentPipeline: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, currentPipeline: 0 })} />
            <Ratings className='flex justify-between last-rating-items' label="Geography agnostic" value={editedDetails?.geographyAgnostic !== null ? editedDetails?.geographyAgnostic : marketTabData?.geographyAgnostic} max={10} onChange={(event, newValue) => setEditedDetails({ ...editedDetails, geographyAgnostic: newValue })} onResetClick={() => setEditedDetails({ ...editedDetails, geographyAgnostic: 0 })}
              total={<p className={`text-xs text-right font-semibold uppercase ${totalStartCount() >= RATING_CHART.limitForError ? 'text-error' : 'text-light-grey'}`}>
                {totalStartCount()}/<span>{RATING_CHART.maxLimit}</span></p>} />
          </div>
          <div className='chart-block'>
            <div className='box-border bg-[#F3F3F5]'>
              <Chart labels={["High demand", ["Cost to", "scale"], "Competition", "Market size", ["Current", "pipeline"], ["Geography", "agnostic"]]} items={[
                editedDetails?.highDemand !== null ? editedDetails?.highDemand : marketTabData?.highDemand,
                editedDetails?.costToScale !== null ? editedDetails?.costToScale : marketTabData?.costToScale,
                editedDetails?.competition !== null ? editedDetails?.competition : marketTabData?.competition,
                editedDetails?.marketSize !== null ? editedDetails?.marketSize : marketTabData?.marketSize,
                editedDetails?.currentPipeline !== null ? editedDetails?.currentPipeline : marketTabData?.currentPipeline,
                editedDetails?.geographyAgnostic !== null ? editedDetails?.geographyAgnostic : marketTabData?.geographyAgnostic
              ]}
                step={2}
                maximumValue={10} />
            </div>
          </div>
        </div>
        <h2 className='mb-2'>Place radar</h2>
        <div className='analysis-rating-chart-block flex'>
          <div className='rating-block'>
            {
              placeRadars?.map((item, idx) => (
                <Ratings color="#009BE2" key={item?.id} className='flex justify-between rating-items' label={item?.radarName} value={item?.value} max={10} onChange={(event, newValue) => onChangePlaceRadarValue(item.id, newValue)}
                onResetClick={() => onResetRadarPoint(item.id)}
                total={idx === placeRadars.length -1 && <p className={`text-xs text-right font-semibold uppercase ${totalPlaceRadarsCount() >= RATING_CHART.limitForError ? 'text-error' : 'text-light-grey'}`}>
                {totalPlaceRadarsCount()}/<span>{RATING_CHART.maxLimit}</span></p>} 
                />
              ))
            }
          </div>
          <div className='chart-block'>
            <div className='box-border bg-[#F3F3F5]'>
              <Chart color="#009BE2" bgColor="#009BE233" labels={placeRadars?.map(i => i.radarName?.split(" "))} items={placeRadars?.map(i => i.value)}
                step={2}
                maximumValue={10} />
            </div>
          </div>
        </div>
        <div className='flex justify-between analysis-dropdown-wrapper'>
          <DropdownColumn
            header="Tech buyer / Customer types(P)"
            dropdown1Label="Tech buyer 1"
            dropdown1Options={techBuyers}
            dropdown1Value={editedDetails?.techBuyer1 || marketTabData?.techBuyer1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, techBuyer1: e.target.value })}
            dropdown1Error={errors.techBuyer1}
            dropdown1HelperText={errors.techBuyer1 && "Tech Buyer 1 Required."}
            dropdown2Label="Tech buyer 2"
            dropdown2Options={techBuyers}
            dropdown2Value={editedDetails?.techBuyer2 || marketTabData?.techBuyer2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, techBuyer2: e.target.value })}
            dropdown2Error={errors.techBuyer2}
            dropdown2HelperText={errors.techBuyer2 && "Tech Buyer 2 Required."}
            dropdown3Label="Tech buyer 3"
            dropdown3Options={techBuyers}
            dropdown3Value={editedDetails?.techBuyer3 || marketTabData?.techBuyer3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, techBuyer3: e.target.value })}
            dropdown3Error={errors.techBuyer3}
            dropdown3HelperText={errors.techBuyer3 && "Tech Buyer 3 Required."}
          />
          <DropdownColumn
            header="Charging model / Pricing(P)"
            dropdown1Label="Changing model 1"
            dropdown1Options={chargingModels}
            dropdown1Value={editedDetails?.chargingModel1 || marketTabData?.chargingModel1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, chargingModel1: e.target.value })}
            dropdown1Error={errors.chargingModel1}
            dropdown1HelperText={errors.chargingModel1 && "Changing Model 1 Required."}
            dropdown2Label="Changing model 2"
            dropdown2Options={chargingModels}
            dropdown2Value={editedDetails?.chargingModel2 || marketTabData?.chargingModel2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, chargingModel2: e.target.value })}
            dropdown2Error={errors.chargingModel2}
            dropdown2HelperText={errors.chargingModel2 && "Changing Model 2 Required."}
            dropdown3Label="Changing model 3"
            dropdown3Options={chargingModels}
            dropdown3Value={editedDetails?.chargingModel3 || marketTabData?.chargingModel3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, chargingModel3: e.target.value })}
            dropdown3Error={errors.chargingModel3}
            dropdown3HelperText={errors.chargingModel3 && "Changing Model 3 Required."}
          />
          <DropdownColumn
            header="Value KPI / value delivered(P)"
            dropdown1Label="Value kpi 1"
            dropdown1Options={valueKPIs}
            dropdown1Value={editedDetails?.valueKPI1 || marketTabData?.valueKPI1}
            dropdown1HandleChange={(e) => setEditedDetails({ ...editedDetails, valueKPI1: e.target.value })}
            dropdown1Error={errors.valueKPI1}
            dropdown1HelperText={errors.valueKPI1 && "Value KPI 1 Required."}
            dropdown2Label="Value kpi 2"
            dropdown2Options={valueKPIs}
            dropdown2Value={editedDetails?.valueKPI2 || marketTabData?.valueKPI2}
            dropdown2HandleChange={(e) => setEditedDetails({ ...editedDetails, valueKPI2: e.target.value })}
            dropdown2Error={errors.valueKPI2}
            dropdown2HelperText={errors.valueKPI2 && "Value KPI 2 Required."}
            dropdown3Label="Value kpi 3"
            dropdown3Options={valueKPIs}
            dropdown3Value={editedDetails?.valueKPI3 || marketTabData?.valueKPI3}
            dropdown3HandleChange={(e) => setEditedDetails({ ...editedDetails, valueKPI3: e.target.value })}
            dropdown3Error={errors.valueKPI3}
            dropdown3HelperText={errors.valueKPI3 && "Value KPI 3 Required."}
            children={
              <PTInput
                labelStyle={AnalysisLabelStyle}
                className='custom-dropdown-icon'
                variant="standard"
                label="Value kpi 4"
                InputPropsStyle={{ fontSize: '12px' }}
                fullWidth
                select
                selectChildren={valueKPIs.map(option => <MenuItem value={option.id}>{option.name}</MenuItem>)}
                placeholder=''
                value={editedDetails?.valueKPI4 || marketTabData?.valueKPI4}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, valueKPI4: e.target.value })}
                error={errors.valueKPI4}
                helperText={errors.valueKPI4 && "Value KPI 4 Required."} />

            }
          />
        </div>
        <div className='flex justify-end bottom-btn-wrapper'>
          <PTButton
            variant="contained"
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>} type="submit"
            className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => {
              setEditedDetails(MARKET_TAB_PAYLOAD)
              setPlaceRadarDetails()
            }} />
          <PTButton variant="contained" label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Save & Continue</span>}
            type="submit"
            className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()} />
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
      </div>
    </>
  )
}
