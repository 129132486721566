import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PTButton from "../../../../components/GenericComponents/PTButton";
import { ADMIN_RFF, VC_PLANS_API } from "../../../../constants/APIs";
import { DateFormat } from "../../../../constants/General";
import { Actions } from "../../../../store/actions";
import { ADMIN_API } from "../../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { formatTime } from "../../../../utils/utility/utility";
import ChangePlan from "../../../../rff/components/ChangePlan";
import { getRFFs } from "../../../utils/middleware/adminRFF";

export default function PlanTab() {
  // Store
  const { isLoading, selectedRFF, activeSort, sortOrder, isArchived, search } =
    useSelector((state) => state.adminRFF);
  const isRedirect = useSelector((state) => state.admin.isRedirect);

  // State
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (openChangePlanModal) {
      getPlanDetails();
    }
  }, [openChangePlanModal]);

  const getPlanDetails = () => {
    ADMIN_API()
      .get(VC_PLANS_API)
      .then((res) => {
        setPlans(res.data.data);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const changeVCPlan = (selectedPlan) => {
    dispatch(Actions.AdminRFF.SetLoading, true);
    ADMIN_API()
      .post(`${ADMIN_RFF}/activatePlan/${selectedRFF?.id}`, {
        planId: selectedPlan,
      })
      .then((res) => {
        getRFFs(isArchived, activeSort, sortOrder, search, !isRedirect);
        dispatch(Actions.AdminRFF.SetLoading, false);
        handleSuccess(res);
      })
      .catch((err) => {
        dispatch(Actions.AdminRFF.SetLoading, false);
        handleError(err);
      });
  };

  return (
    <>
      <div className="px-5 pt-5 overflow-y-auto">
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className="w-full flex justify-center items-center">
              <div className="w-1/4">Plan name</div>
              <div className="w-3/4">
                {selectedRFF.rffPlanDetails.name || "-"}
              </div>
            </div>
          </div>
        </div>
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className="w-full flex justify-center items-center">
              <div className="w-1/4">Expiry</div>
              <div className="w-3/4">
                {selectedRFF.planExpiryDate
                  ? formatTime(
                      selectedRFF.planExpiryDate,
                      DateFormat.DayMonthFormat
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className="w-full flex justify-center items-center">
              <div className="w-1/4">Free meetings</div>
              <div className="w-3/4">
                {selectedRFF.rffPlanDetails.freeNoOfMeetings || "-"}
              </div>
            </div>
          </div>
        </div>
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className="w-full flex justify-center items-center">
              <div className="w-1/4">Plan duration</div>
              <div className="w-3/4">
                {selectedRFF.rffPlanDetails.durationOfDays
                  ? `${selectedRFF.rffPlanDetails.durationOfDays} Days`
                  : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className="w-full flex justify-center items-center">
              <div className="w-1/4">Price</div>
              <div className="w-3/4">
                {selectedRFF.rffPlanDetails.price
                  ? `$ ${selectedRFF.rffPlanDetails.price}`
                  : "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end bottom-btn-wrapper">
          <PTButton
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Change plan
              </span>
            }
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => setOpenChangePlanModal(true)}
          />
        </div>
      </div>

      {openChangePlanModal && (
        <ChangePlan
          openChangePlanModal={openChangePlanModal}
          closeChangePlanModal={() => {
            setPlans([]);
            setOpenChangePlanModal(false);
          }}
          currentPlan={selectedRFF?.rffPlanId}
          plans={plans}
          loading={isLoading}
          handleSuccess={changeVCPlan}
        />
      )}
    </>
  );
}
