import React from 'react';
import { useSelector } from 'react-redux';
import Chart from '../../Chart';
import { KeyFeatureLabel } from '../../Label';
import { Radar_Chart_Labels } from '../../../constants';
import { marketChartLabels } from '../../../utils/chartData';

export default function OfferingTab() {
  // Store
  const { marketDetails } = useSelector(state => state.tbProptechDetails)
  const { id } = useSelector((state) => state.publicProptechUser);

  return (
    <>
      {marketDetails ?
        <div className={`tabpanel-wrapper-place market-tab ${ id && "!max-w-full"}`}>
          <div className="left_col-place">
            <h2>Market overview</h2>
            <p>{marketDetails?.proptechCompanyDetails?.marketOpportunityAdmin}</p>
            <div className="detail-sector-wrapper">
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Customer types</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisTechBuyer1?.icon} label={marketDetails?.proptechAnalysisTechBuyer1?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisTechBuyer2?.icon} label={marketDetails?.proptechAnalysisTechBuyer2?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisTechBuyer3?.icon} label={marketDetails?.proptechAnalysisTechBuyer3?.name} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Value delivered</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisValueKPI1?.icon} label={marketDetails?.proptechAnalysisValueKPI1?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisValueKPI2?.icon} label={marketDetails?.proptechAnalysisValueKPI2?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisValueKPI3?.icon} label={marketDetails?.proptechAnalysisValueKPI3?.name} />
                </ul>
              </div>
              <div className="detail-sectors">
                <div className='flex items-start'>
                  <h2 className='pr-4'>Pricing</h2>
                </div>
                <ul className="menu-item">
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisChargingModel1?.icon} label={marketDetails?.proptechAnalysisChargingModel1?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisChargingModel2?.icon} label={marketDetails?.proptechAnalysisChargingModel2?.name} />
                  <KeyFeatureLabel icon={marketDetails?.proptechAnalysisChargingModel3?.icon} label={marketDetails?.proptechAnalysisChargingModel3?.name} />
                </ul>
              </div>
            </div>
          </div>
          <div className="right_col-place" onClick={(e) => e.stopPropagation()}>
            <div className='flex items-start'>
              <h2 className='pr-4'>{Radar_Chart_Labels.offering}</h2>
            </div>
            <div className="chart-wrapper">
              <Chart
                labels={marketDetails?.placeRadars?.length ? marketDetails?.placeRadars?.map((i) => i.placeRadarPoint?.rada?.split(" ")) : marketChartLabels}
                items={marketDetails?.placeRadars?.map((i) => i.value) || []}
                step={2}
                label={'Rating'}
                maximumValue={10}
              />
            </div>
          </div>
        </div>
        : null}
    </>
  )
}
