import { PROPTECH_COMPANY_DETAILS_API } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { Store } from "../../../store/configureStore";
import { PROPTECH_API } from "../../utility/API-call";
import { companyDetailsDTO } from "../../dtos/CompanyDetails";
import { handleError } from "../../utility/snackbar";

export const getCompanyDetails = (setActiveStep) => {
  PROPTECH_API().get(PROPTECH_COMPANY_DETAILS_API)
    .then(res => {
      if (res.data.data) {
        let companyDetail = companyDetailsDTO(res.data.data)
        Store.dispatch({ type: Actions.ProptechUser.ProptechSetCompanyDetails, payload: companyDetail });
        setActiveStep(res.data.data?.signupProgress || 0)
      }
    }).catch(err => {
      console.log(err)
      handleError(err)
    })
}

