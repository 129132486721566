import { makeStyles } from "@material-ui/core/styles";

export const PROPTEC_DETAILS_TAB = [
  { panel: "Snapshot" },
  { panel: "Answers" },
  { panel: "Analysis" },
  { panel: "Home" },
  { panel: "For You" },
  { panel: "Watchlist" },
  { panel: "Plan" },
];
export const SHARE_MODAL = {
  share: "Share",
  shareLink: "Or share this link",
  shareMsg: "Hey, check out this proptech. Keen to hear what you think!",
};
export const MARKET_INSIGHTS_TAB = [
  { panel: "Market Insights" },
  { panel: "Assign" },
];

export const PROPTECH_DETAILS_ANALYSIS_TAB = [
  { panel: "Company" },
  { panel: "Overview" },
  { panel: "Market / Offering(P)" },
  { panel: "Numbers / Track record(P)" },
  { panel: "Raise" },
  { panel: "Team" },
  { panel: "Raise Overview" },
  { panel: "Design Review" },
  { panel: "Market Insights" },
  { panel: "Company Profile" },
  { panel: "Deploy + Use(P)" },
];

export const TECH_BUYER_PROJECT_TAB = [
  { panel: "Company overview" },
  { panel: "Strategy diagnostic" },
  { panel: "Assessment criteria" },
  { panel: "Transformation" },
  { panel: "Implementation" },
  { panel: "Adoption" },
  { panel: "Attachments" },
];

export const RFF_PROJECT_TAB = [
  { panel: "Company overview" },
  { panel: "Strategy diagnostic" },
  { panel: "Assessment criteria" },
  { panel: "Transformation" },
  { panel: "Implementation" },
  { panel: "Adoption" },
  { panel: "Attachments" },
];

export const VENTURE_SCOUT_TABS = [
  { panel: "Profile" },
  { panel: "Interactions" },
];

export const Form_Field = {
  name: "A-Z",
  dateCreated: "Date Created",
};

export const COMPANY_ERROR_TEXT = {
  url: "Enter valid URL.",
};

export const RATING_CHART = {
  maxLimit: 60,
  limitForError: 40,
  maxLimitOverview: 70,
  designReviewMaxLimit: 30,
  designReviewLimitForError: 20,
};

export const COMPANY_PROFILE_TAB_COMMERCIAL_PROBLEM = [
  {
    dropdownLabel: "Problem 1 Heading",
    dropdownValue: "problem1Heading",
    textLabel: "Problem 1 Text",
    textValue: "problem1Text",
  },
  {
    dropdownLabel: "Problem 2 Heading",
    dropdownValue: "problem2Heading",
    textLabel: "Problem 2 Text",
    textValue: "problem2Text",
  },
  {
    dropdownLabel: "Problem 3 Heading",
    dropdownValue: "problem3Heading",
    textLabel: "Problem 3 Text",
    textValue: "problem3Text",
  },
];

export const COMPANY_PROFILE_TAB_SOLUTION = [
  {
    dropdownLabel: "Solution 1 Heading",
    dropdownValue: "solution1Heading",
    textLabel: "Solution 1 Text",
    textValue: "solution1Text",
  },
  {
    dropdownLabel: "Solution 2 Heading",
    dropdownValue: "solution2Heading",
    textLabel: "Solution 2 Text",
    textValue: "solution2Text",
  },
  {
    dropdownLabel: "Solution 3 Heading",
    dropdownValue: "solution3Heading",
    textLabel: "Solution 3 Text",
    textValue: "solution3Text",
  },
];

export const COMPANY_PROFILE_TAB_BUSINESS_MODEL = [
  {
    dropdownLabel: "Business Model 1 Heading",
    dropdownValue: "bizModel1Heading",
    textLabel: "Business Model 1 Text",
    textValue: "bizModel1Text",
  },
  {
    dropdownLabel: "Business Model 2 Heading",
    dropdownValue: "bizModel2Heading",
    textLabel: "Business Model 2 Text",
    textValue: "bizModel2Text",
  },
  {
    dropdownLabel: "Business Model 3 Heading",
    dropdownValue: "bizModel3Heading",
    textLabel: "Business Model 3 Text",
    textValue: "bizModel3Text",
  },
];

export const COMPANY_PROFILE_TAB_GO_TO_MARKET = [
  {
    dropdownLabel: "Go To Market 1 Heading",
    dropdownValue: "goToMarket1Heading",
    textLabel: "Go To Market 1 Text",
    textValue: "goToMarket1Text",
  },
  {
    dropdownLabel: "Go To Market 2 Heading",
    dropdownValue: "goToMarket2Heading",
    textLabel: "Go To Market 2 Text",
    textValue: "goToMarket2Text",
  },
  {
    dropdownLabel: "Go To Market 3 Heading",
    dropdownValue: "goToMarket3Heading",
    textLabel: "Go To Market 3 Text",
    textValue: "goToMarket3Text",
  },
];

export const COMPANY_PROFILE_TAB_CUSTOMER_BREAKDOWN = [
  { text: "Customer Breakdown 1 Text", value: "customerBreakdown1Text" },
  { text: "Customer Breakdown 2 Text", value: "customerBreakdown2Text" },
  { text: "Customer Breakdown 3 Text", value: "customerBreakdown3Text" },
];

export const COMPANY_PROFILE_TAB_CUSTOMER_KEY_POINT = [
  { text: "Customer Key Point 1 Text", value: "customerKeyPoint1Text" },
  { text: "Customer Key Point 2 Text", value: "customerKeyPoint2Text" },
  { text: "Customer Key Point 3 Text", value: "customerKeyPoint3Text" },
];

export const COMPANY_PROFILE_TAB_FORECAST_AND_METRICS = [
  {
    dropdownLabel: "Future Metric 1",
    dropdownValue: "futureMetric1",
    textLabel: "Future Metric 1 Value",
    textValue: "futureMetric1Text",
  },
  {
    dropdownLabel: "Future Metric 2",
    dropdownValue: "futureMetric2",
    textLabel: "Future Metric 2 Value",
    textValue: "futureMetric2Text",
  },
  {
    dropdownLabel: "Future Metric 3",
    dropdownValue: "futureMetric3",
    textLabel: "Future Metric 3 Value",
    textValue: "futureMetric3Text",
  },
];

export const COMPANY_PROFILE_TAB_VALUE_PROPOSITION = [
  { text: "Value KPI 1 Text", value: "valueKPI1Text" },
  { text: "Value KPI 2 Text", value: "valueKPI2Text" },
  { text: "Value KPI 3 Text", value: "valueKPI3Text" },
  { text: "Value KPI 4 Text", value: "valueKPI4Text" },
];

export const COMPANY_PROFILE_TAB_WHY_GOOD_FIT = [
  { text: "Why Good Fit 1 Text", value: "whyGoodFit1Text" },
  { text: "Why Good Fit 2 Text", value: "whyGoodFit2Text" },
  { text: "Why Good Fit 3 Text", value: "whyGoodFit3Text" },
];

export const COMPANY_PROFILE_TAB_DEFENSIBILITY_AND_TIMING = [
  {
    dropdownLabel: "Defensibility Timing 1",
    dropdownValue: "defensibilityTiming1",
    textLabel: "Defensibility Timing 1 Value",
    textValue: "defensibilityTiming1Text",
  },
  {
    dropdownLabel: "Defensibility Timing 2",
    dropdownValue: "defensibilityTiming2",
    textLabel: "Defensibility Timing 2 Value",
    textValue: "defensibilityTiming2Text",
  },
  {
    dropdownLabel: "Defensibility Timing 3",
    dropdownValue: "defensibilityTiming3",
    textLabel: "Defensibility Timing 3 Value",
    textValue: "defensibilityTiming3Text",
  },
];

export const COMPANY_PROFILE_TAB_SKILL = [
  { text: "Skill 1 Text", value: "skill1Text" },
  { text: "Skill 2 Text", value: "skill2Text" },
  { text: "Skill 3 Text", value: "skill3Text" },
];

const lorem =
  "Loremscscv ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu pretium.";

export const SNAPSHOTS = {
  heading1: [
    {
      key: "What is the business overview / elevator pitch?",
      value: lorem,
    },
    {
      key: "What is the technology product and / or solution?",
      value: lorem,
    },
    {
      key: "What is the target market and reason for purchase?",
      value: lorem,
    },
    {
      key: "What makes this such a big opportunity?",
      value: lorem,
    },
  ],
};

export const ANALYSIS_TABS_HEIGHTS = [
  81.22, 72.52, 53.41, 72.17, 42, 45.44, 113, 119, 118, 131,
];

// Investor
export const INVESTOR_DETAILS_TAB = [
  { panel: "Profile" },
  { panel: "Interactions" },
  { panel: "Home" },
  { panel: "For You" },
  { panel: "Watchlist" },
  { panel: "Plan" },
  { panel: "Permissions" },
];

// Tech buyer
export const TECH_BUYER_DETAILS_TAB = [
  { panel: "Profile" },
  { panel: "Project" },
  { panel: "Home" },
  { panel: "For You" },
  { panel: "Watchlist" },
  { panel: "Plan" },
  { panel: "Permissions" },
];

// RFF
export const RFF_DETAILS_TAB = [
  { panel: "Profile" },
  { panel: "" },
  { panel: "Home" },
  { panel: "For You" },
  { panel: "Watchlist" },
  { panel: "Plan" },
  { panel: "Permissions" },
];

export const INVESTOR_INTERACTIONS_TAB_LABELS = {
  country: "Country",
  stage: "Stage",
  publishDate: "Publish Date",
  meetingsBooked: "Meetings Booked",
  language: "Language",
  amount: "Amount",
  archiveDate: "Archive Date",
  meetingDates: "Meeting Dates",
  videoNotAvailable: "Video not available.",
};

export const INVESTOR_INTERACTIONS_TAB_FILTERS = {
  total: "Total",
  upcoming: "Upcoming",
  past: "Past",
  cancelled: "Cancelled",
  bookings: "Bookings",
};

export const INVESTOR_PROFILE_TAB_METRICS = [
  {
    dropdownLabel: "Metric 1",
    dropdownValue: "metric1",
    textLabel: "Metric 1 Value",
    textValue: "metric1Text",
  },
  {
    dropdownLabel: "Metric 2",
    dropdownValue: "metric2",
    textLabel: "Metric 2 Value",
    textValue: "metric2Text",
  },
  {
    dropdownLabel: "Metric 3",
    dropdownValue: "metric3",
    textLabel: "Metric 3 Value",
    textValue: "metric3Text",
  },
];

export const INVESTOR_TAB_FILTERS = {
  total: "total",
  upcoming: "upcoming",
  past: "past",
  cancelled: "cancelled",
};

export const investmentSize = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 10000000,
    label: "$10m+",
  },
];

export const TagStyle = {
  backgroundColor: "#FFD84F",
  color: "#111D43",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "18px",
  border: "none",
  margin: "5px",
};

export const TabsStyle = makeStyles(() => ({
  root: {
    height: 24,
  },
  tabs: {
    minHeight: "24px !important",
    "& .MuiButtonBase-root.MuiTab-root": {
      fontSize: "16px !important",
      padding: 0,
      marginRight: 15,
      fontStyle: "normal !important",
      fontWeight: "300",
      fontFamily: "Poppins !important",
      minHeight: "24px !important",
      minWidth: "16px !important",
      textTransform: "none",
    },
    "& .MuiTabs-indicator": {
      display: "none",
      backgroundColor: "#FFFFFF",
    },
    "& .Mui-selected": {
      textDecoration: "none",
      fontWeight: "600 !important",
    },
  },
}));

// Venture Scout
export const VENTURE_SCOUT_PROFILE_TAB_FIELDS = [
  {
    textLabel: "Company Name",
    textValue: "companyName",
    maxLength: 255,
    validUrlText: false,
  },
  { textLabel: "LinkedIn URL", textValue: "linkedInURL", validUrlText: true },
  {
    textLabel: "Phone",
    textValue: "phone",
    maxLength: 70,
    validUrlText: false,
  },
  {
    textLabel: "Reference #",
    textValue: "code",
    maxLength: 70,
    validUrlText: false,
  },
];

export const VENTURE_SCOUT_PROFILE_TAB_COMMISSIONS_FIELDS = [
  {
    textLabel: "Onboard Commission (in %)",
    textValue: "onboardCommission",
    maxLength: 3,
  },
  {
    textLabel: "Meeting Commission (in %)",
    textValue: "meetingCommission",
    maxLength: 3,
  },
];

export const Venture_Scout_Card_Labels = {
  company: "Company",
  email: "Email",
  linkedinUrl: "Linkedin URL",
  reference: "reference #",
  commission: "Commission",
  lastUpdated: "Last Updated",
  lastActive: "Last Active",
  proptechsOnboardedRejected: "Proptechs Onboarded / Rejected",
};

export const UNSAVED_CHANGES_MESSAGE = {
  message: "Please save or revert unsaved changes first.",
  upgradePlan: "Please contact us for further engagement.",
};

export const AdminUserSettingPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 100,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export const DeleteUserMessage =
  "Are you sure? You want to permanently delete this company? This action can not be undone.";

export const DeleteMarketInsightMessage =
  "Are you sure? You want to permanently delete this Market Insight Report? This action can not be undone.";

export const sampleBulkUploadFile =
  "https://proptech-files-dev.s3.ap-southeast-2.amazonaws.com/proptech-bulk-upload/SampleBulkUpload.xlsx";

export const adminProptechForYouTabs = [
  {
    name: "Investor",
    id: 0,
  },
  {
    name: "Tech buyer",
    id: 1,
  },
  {
    name: "RFF",
    id: 2,
  },
];

export const forYouTabMessage1 = "Not aligned yet to any Investor.";
export const forYouTabMessage2 = "Not aligned yet to any Tech Buyer.";
export const forYouTabMessage3 = "Not aligned yet to any RFF.";
