import { ADMIN_TECH_BUYERS } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";

export const loadProjectAdoptionTabData = (
  id,
  adminProjectTab,
  adminDropdowns
) => {
  if (!adminProjectTab.adoptionData) {
    getProjectAdoptionDetails(id);
  }
};

export const getProjectAdoptionDetails = (id) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .get(ADMIN_TECH_BUYERS + `/${id}/project/adoption`)
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.AdminProjectTab.SetAdoptionData, res.data.data);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      return err?.response?.status;
    });
};

export const updateProjectAdoptionDetails = (id, data) => {
  dispatch(Actions.AdminUser.SetLoading, true);
  return ADMIN_API()
    .put(ADMIN_TECH_BUYERS + `/${id}/project/adoption`, data)
    .then((res) => {
      handleSuccess(res);
      getProjectAdoptionDetails(id);
      dispatch(Actions.AdminUser.SetLoading, false);
      return res?.status;
    })
    .catch((err) => {
      dispatch(Actions.AdminUser.SetLoading, false);
      handleError(err);
      return err?.response?.status;
    });
};
