import React from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../utils/utility/store";
import { Actions } from "../../../../../store/actions";
import Loader from "../../../../../components/Loading";
import ProptechListCard from "../../ForYouTab/ProptechListCard";
import { setActiveTab } from "../../../../utils/middleware/adminTechBuyer";
import { forYouTabMessage2 } from "../../../../constants";

export default function AdminPlaceWatchlistTab() {
  // Store
  const watchlistProptechsPlace = useSelector(
    (state) => state.adminUser.watchlistProptechsPlace
  );
  const isLoading = useSelector((state) => state.adminUser.isLoading);
  const adminDropdowns = useSelector((state) => state.adminDropdowns);
  const adminTechBuyer = useSelector((state) => state.adminTechBuyer);

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 2);
    dispatch(Actions.AdminTechBuyer.SetActiveTab, 0);
    dispatch(Actions.Admin.SetIsRedirect, true);
    dispatch(Actions.AdminTechBuyer.SetIsSelectedTechBuyer, true);
    dispatch(Actions.AdminTechBuyer.SetSelectedTechBuyer, { id });
    setActiveTab(0, id, adminTechBuyer, adminDropdowns);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="overflow-y-auto">
        {!!watchlistProptechsPlace?.length ? (
          watchlistProptechsPlace.map((proptech, index) => {
            return (
              <ProptechListCard
                key={index}
                logo={proptech?.tbCompanyDetails?.companyLogo}
                onCardClick={() =>
                  handleCardClick(proptech?.tbCompanyDetails?.id)
                }
                title={proptech.tbCompanyDetails.tbCompany.name}
                createdAt={proptech.createdAt}
              />
            );
          })
        ) : (
          <div>
            <h3>{forYouTabMessage2}</h3>
          </div>
        )}
      </div>
    </>
  );
}
