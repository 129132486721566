import { ADMIN_NEWSFEED } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { newsfeedDTO, newsfeedListDTO } from "../dtos/newsfeed"

export const getNewsfeeds = (isActive, activeSort, sortOrder, search, clear = true) => {
  const params = {
    filter: { "status": isActive },
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { 'order': sortOrder } : {}),
    ...(!!activeSort ? { 'orderBy': activeSort } : {}),
  }
  dispatch(Actions.Newsfeeds.SetLoading, true)
  ADMIN_API().get(ADMIN_NEWSFEED, { params })
    .then(res => {
      handleSuccess(res)
      let newsfeeds = newsfeedListDTO(res?.data?.data?.rows)
      dispatch(Actions.Newsfeeds.NewsfeedsListSet, newsfeeds)
      if (clear) {
        dispatch(Actions.Newsfeeds.SetIsSelectedNewsfeed, false)
        dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, null)
      }
    }).catch(err => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      handleError(err)
    })
}

export const getNewsfeedDetails = (id) => {
  dispatch(Actions.Newsfeeds.SetLoading, true)
  ADMIN_API().get(ADMIN_NEWSFEED + `/${id}`)
    .then(res => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, newsfeedDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      handleError(err)
    })
}

export const updateNewsfeedDetails = (id, data, isActive, activeSort, sortOrder, search) => {
  dispatch(Actions.Newsfeeds.SetLoading, true)
  return ADMIN_API().put(ADMIN_NEWSFEED + `/${id}`, data)
    .then(res => {
      handleSuccess(res)
      getNewsfeedDetails(id)
      getNewsfeeds(isActive, activeSort, sortOrder, search, false)
      return res?.status;
    }).catch(err => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      handleError(err);
      return err?.response?.status;
    })
}

export const addNewsfeedDetails = (data, isActive, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.Newsfeeds.SetLoading, true)
  ADMIN_API().post(ADMIN_NEWSFEED, data)
    .then(res => {
      handleSuccess(res)
      getNewsfeeds(isActive, activeSort, sortOrder, search)
      handleClose()
    }).catch(err => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      handleError(err);
    })
}

export const deleteNewsfeedDetails = (id, isActive, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.Newsfeeds.SetLoading, true)
  ADMIN_API().delete(ADMIN_NEWSFEED + `/${id}`)
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, null)
      getNewsfeeds(isActive, activeSort, sortOrder, search)
      handleClose()
    }).catch(err => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      handleClose()
      handleError(err);
    })
}

export const changeStatusofNewsfeed = (id, status, isActive, activeSort, sortOrder, search, handleClose) => {
  dispatch(Actions.Newsfeeds.SetLoading, true)
  ADMIN_API().put(ADMIN_NEWSFEED + `/${id}/status`, status)
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.Newsfeeds.SetSelectedNewsfeed, null)
      getNewsfeeds(isActive, activeSort, sortOrder, search)
      handleClose()
    }).catch(err => {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      handleClose()
      handleError(err);
    })
}