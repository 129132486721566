import React from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

function PTCStepper({ activeStep, steps, setStepper }) {

  const stepStyle = {
    '& .MuiStepLabel-root .Mui-completed': {
      color: '#39B54A', // circle color (COMPLETED)
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
    {
      color: 'rgba(17, 29, 67, 0.4)', // Just text label (COMPLETED)
    },

    '& .MuiStepLabel-root .Mui-active': {
      color: '#FF4100', // circle color (ACTIVE)
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
    {
      color: '#111D43', // Just text label (ACTIVE)
    },
    '& svg': {
      width: '20px',
      color: "#E7E8EC"

    },
    '& .MuiStepLabel-label': {
      fontSize: "14px"
    }
  }

  return <Stepper activeStep={activeStep} alternativeLabel>
    {steps.map((label, index) => {
      return (
        <Step key={label} sx={stepStyle}>
          <StepLabel onClick={() => setStepper(index)}>
            {label}
          </StepLabel>
        </Step>
      );
    })}
  </Stepper >

}

export default PTCStepper;