import React, { useEffect, useState } from 'react';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { getAdminLocalStorageUserData, removeAdminSessionToken } from '../../../utils/session';
import PTButton from '../../../components/GenericComponents/PTButton';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ChangePassword from '../../../components/ChangePassword';
import { ADMIN_API } from '../../../utils/utility/API-call';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import { ADMIN_CHANGE_PASSWORD_API } from '../../../constants/APIs';
import Loader from '../../../components/Loading';
import { AdminUserSettingPaperProps } from '../../constants';
import './TopBar.scss';
import { formatTime } from '../../../utils/utility/utility';

export default function TopBar() {
  // State
  const [adminUserData, setAdminUserData] = useState({});
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userMenu, setUserMenu] = useState();

  useEffect(() => {
    setAdminUserData(getAdminLocalStorageUserData())
  }, []);

  const openUser = Boolean(userMenu);
  const handleClick_u = (event) => {
    setUserMenu(event.currentTarget);
  };
  const handleClose_u = () => {
    setUserMenu(null);
  };

  const handleLogout = () => {
    removeAdminSessionToken()
  };
  function mobilemenu() {
    document.body.classList.toggle('mobile-menu-open');
    document.documentElement.toggle('mobile-menu-open');
  }

  const changePassword = (payload, resetForm) => {
    setLoading(true)
    ADMIN_API().put(ADMIN_CHANGE_PASSWORD_API, payload)
      .then(res => {
        setLoading(false);
        resetForm({ values: '' });
        handleSuccess(res)
        setOpenModal(false)
      })
      .catch((error) => {
        setLoading(false);
        handleError(error)
      })
  }

  return (
    <>
      {loading && <Loader />}
      <div className='bg-[#E7E8EC] flex flex-row items-center justify-between admin-top-nav'>
        <div className='flex items-center'>
          <div className="hamburger-menu lg:hidden" onClick={mobilemenu}><span></span><span></span><span></span></div>
          <div className='left-logo-block'>
            <h2 className='text-[32px] text-[#FF4100] font-light'>Welcome back <span className="font-bold">{adminUserData?.name}</span></h2>
            <p className='text-[#70778E] text-[14px] font-bold uppercase'>{adminUserData?.lastLoginAt ? formatTime(adminUserData?.lastLoginAt, 'dddd, DD MMMM YYYY') : ''}</p>
          </div>
        </div>
        <div className='flex flex-row justify-evenly items-center navbar-rightside'>
          <div style={{ borderRadius: '50%' }} onClick={(e) => handleClick_u(e)} className='bg-white h-[30px] w-[30px] xsm:h-[40px] xsm:w-[40px] lg:h-[54px] lg:w-[54px] flex justify-center items-center cursor-pointer text-xl xsm:text-2xl lg:text-3xl'>
            {adminUserData?.name?.split(' ').map(word => word[0]).join('')}
          </div>
        </div>
      </div >

      {openUser && <Menu
        anchorEl={userMenu}
        id="user-menu"
        className="dropdown_menu"
        open={openUser}
        onClose={handleClose_u}
        onClick={handleClose_u}
        PaperProps={AdminUserSettingPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="profile-wrapper border-b border-solid border-[#E7E8EC]">
          <div className="user-wrapper flex flex-col border-none pb-0">
            <Avatar sx={{ width: 80, height: 80 }}> {adminUserData?.name?.split(' ').map(word => word[0]).join('')}</Avatar>
            <h2>{adminUserData?.name}</h2>
            <p>{adminUserData?.email}</p>
            <PTButton label='Log Out' color='primary' className="normal-case btn mt-10" size='large' handleOnClickEvent={() => handleLogout()} />
          </div>
        </div>
        <MenuItem onClick={() => setOpenModal(true)} className='pt-5'>
          <em className='img-icon'><ManageAccountsOutlinedIcon style={{ color: "#70778E" }} /></em>
          <span>Change password</span>
        </MenuItem>
      </Menu>}

      {/* change password modal */}
      {openModal && <ChangePassword
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSuccess={changePassword}
        cancelButtonLabel="Cancel"
        cancelButtonStyle="modal-cancel-button"
        changeButtonLabel="Change"
        changeButtonStyle='modal-change-button' />}
    </>
  )
}
