export function raiseTabVCDTO(data) {
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    team: data?.team || data?.team === 0 ? data?.team : null,
    model: data?.model || data?.model === 0 ? data?.model : null,
    technology: data?.technology || data?.technology === 0 ? data?.technology : null,
    timing: data?.timing || data?.timing === 0 ? data?.timing : null,
    network: data?.network || data?.network === 0 ? data?.network : null,
    defensibility: data?.defensibility || data?.defensibility === 0 ? data?.defensibility : null,
    raiseDateIcon: data?.raiseDateIcon || null,
    proptechAnalysisStyle1: {
      id: data?.proptechAnalysisStyle1?.id || 0,
      icon: data?.proptechAnalysisStyle1?.icon || null,
      name: data?.proptechAnalysisStyle1?.name || '-',
    },
    proptechAnalysisTrait1: {
      id: data?.proptechAnalysisTrait1?.id || 0,
      icon: data?.proptechAnalysisTrait1?.icon || null,
      name: data?.proptechAnalysisTrait1?.name || '-',
    },
    proptechAnalysisTrait2: {
      id: data?.proptechAnalysisTrait2?.id || 0,
      icon: data?.proptechAnalysisTrait2?.icon || null,
      name: data?.proptechAnalysisTrait2?.name || '-',
    },
    proptechCompanyDetails: {
      investabilityAdmin: data?.proptechCompanyDetails?.investabilityAdmin || '-',
      raiseDate: data?.proptechCompanyDetails?.raiseDate || '',
      investmentRound: {
        id: data?.proptechCompanyDetails?.investmentRound?.id || 0,
        icon: data?.proptechCompanyDetails?.investmentRound?.icon || null,
        name: data?.proptechCompanyDetails?.investmentRound?.name || '-',
      },
      investmentRange: {
        id: data?.proptechCompanyDetails?.investmentRange?.id || 0,
        icon: data?.proptechCompanyDetails?.investmentRange?.icon || null,
        name: data?.proptechCompanyDetails?.investmentRange?.name || '-',
      },
      valuationRange: {
        id: data?.proptechCompanyDetails?.valuationRange?.id || 0,
        icon: data?.proptechCompanyDetails?.valuationRange?.icon || null,
        name: data?.proptechCompanyDetails?.valuationRange?.name || '-',
      },
      investmentType: {
        id: data?.proptechCompanyDetails?.investmentType?.id || 0,
        icon: data?.proptechCompanyDetails?.investmentType?.icon || null,
        name: data?.proptechCompanyDetails?.investmentType?.name || '-',
      },
      noOfRound: {
        id: data?.proptechCompanyDetails?.noOfRound?.id || 0,
        icon: data?.proptechCompanyDetails?.noOfRound?.icon || null,
        name: data?.proptechCompanyDetails?.noOfRound?.name || '-',
      }
    }
  }
}
