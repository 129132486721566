export function calendarDataDTO(data) {
    return {
        upcomingProptechMeetings: data?.upcomingProptechMeetings?.length ? data?.upcomingProptechMeetings.map(meeting => {
            return {
                id: meeting?.id || 0,
                startTime: meeting?.startTime || '',
                status: meeting?.status !== null ? meeting.status : 0
            }
        }) : [],
        previousProptechMeetings: data?.previousProptechMeetings?.length ? data?.previousProptechMeetings.map(meeting => {
            return {
                id: meeting?.id || 0,
                startTime: meeting?.startTime || '',
                status: meeting?.status !== null ? meeting.status : 0
            }
        }) : [],
    }
}
