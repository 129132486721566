export const LabelStyle = {
  fontSize: '13px',
  lineHeight: '12px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  color: 'rgba(17, 29, 67, 0.5)',
}

export const AnalysisLabelStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '12px',
  letterSpacing: '0.07em',
  textTransform: 'uppercase'
}

export const ManageUsersModalSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  minHeight: 440,
  'th': {
    fontFamily: 'Poppins, sans-serif',
  }
}

export const RescheduleMeetingModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxWidth: 660,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  'th': {
    fontFamily: 'Poppins, sans-serif',
  }
}

export const CancelMeetingModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ModalFooter = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '28px' }

export const TableRowSx = {
  '&:last-child td, &:last-child th': {
    border: 0
  }
}

export const TableContainerSx = { height: '320px' };

export const flexContainerSx = {
  width: '100%',
  height: '320px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const AnalysisTabRadarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  layout: {
    padding: {
      top: 30,
      bottom: 30,
    }
  },
  scale: {
    ticks: {
      display: false,
      min: 0,
      max: 10,
      stepSize: 10,
      showLabelBackdrop: false,
      backdropColor: "#FF4100"
    },
    angleLines: {
      color: "#111D43",
      lineWidth: 1
    },
    gridLines: {
      color: "#F3F3F5",
      circular: false
    },
  }
}

export const Questions = {
  elevatorPitch: 'Brief business overview / elevator pitch',
  technologyProduct: "What is your technology product / solution and what does it do?",
  marketOpportunity: "What makes this such a big opportunity?",
  futureGoals: "Outline key traction milestones and future goals?",
  investability: "What makes investing in you compelling?",
  teamStrength: "From an investment point of view, what makes your team stand out?",
  KPIs: "Provide 2 key metrics?KPIs/numbers to highlight for an investor?"
}

export const SnapshotQuestions1 = {
  elevatorPitch: 'What is the business overview / elevator pitch?',
  technologyProduct: "What is your technology product / solution and what does it do?",
  marketOpportunity: "What makes this such a big opportunity?",
  futureGoals: "Outline key traction milestones and future goals?",
}

export const SnapshotQuestions2 = {
  investability: "What makes investing in you compelling?",
  teamStrength: "From an investment point of view, what makes your team stand out?",
  KPIs: "Provide 2 key metrics?KPIs/numbers to highlight for an investor?"
}

export const AdminQuestions1 = {
  elevatorPitchAdmin: 'What is the business overview / elevator pitch?',
  technologyProductAdmin: "What is your technology product / solution and what does it do?",
  marketOpportunityAdmin: "What makes this such a big opportunity?",
  futureGoalsAdmin: "Outline key traction milestones and future goals?",
}

export const AdminQuestions2 = {
  investabilityAdmin: "What makes investing in you compelling?",
  teamStrengthAdmin: "From an investment point of view, what makes your team stand out?",
  KPIsAdmin: "Provide 2 key metrics?KPIs/numbers to highlight for an investor?"
}

export const MANAGEUSERS_MODAL_HEADER = "Manage users";

export const BASIC_DETAILS_FORM_FIELDS = {
  required: 'Required',
  validUrl: 'Enter valid URL.',
  must100CharMore: 'Must be 100 characters or more',
  must250CharLess: 'Must be 250 characters or less',
}

export const SWIPER = {
  slidesPerView: 1.5,
  spaceBetween: 8,
  breakpoints: {
    425: {
      slidesPerView: 2.5,
    },
    768: {
      slidesPerView: 2.15,
    },
    1366: {
      slidesPerView: 2.5,
    },
  }
}

export const Upcoming_Day_Style = {
  backgroundColor: '#FFFFFF !important',
  color: '#70778E !important',
  borderTopLeftRadius: "50%",
  borderBottomLeftRadius: "50%",
  borderTopRightRadius: "50%",
  borderBottomRightRadius: "50%"
}

export const Previous_Day_Style = {
  backgroundColor: 'rgba(255, 65, 0, 0.2) !important',
  color: '#70778E !important',
  borderTopLeftRadius: "50%",
  borderBottomLeftRadius: "50%",
  borderTopRightRadius: "50%",
  borderBottomRightRadius: "50%"
}

export const TooltipText = {
  elevatorPitch: 'Please avoid assertions while providing an overview of your business',
  technologyProduct: "Please provide an overview of your product/solution.",
  marketOpportunity: "Please provide an overview of the opportunity in regards to market climate, macrotrends and product fit",
  futureGoals: "Please provide key traction milestones and future goals",
  investability: "Please provide synopsis highlighting why your company and solution is unique",
  teamStrength: "Please provide an overview of your teams strengths",
  KPIs: "Select 2 pivotal metrics you would like to highlight for an Investor"
}

export const PaymentDetailsModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
}

export const CardStyle = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#00FF00',
  margin: '10px 0'
}

export const CardContentStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: '12px',
  paddingBottom: 16
}

export const ChangePlanModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxHeight: '84%',
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  overflow: 'auto'
}

export const planFeatures = [
  { icon: true, label: "VC pitch analysed and curated", value: "vcPitchAnalysedAndCurated", tooltipText: "Utilise the Connect platform to structure and curate your pitch" },
  { icon: true, label: "Access to global PTC network", value: "accessToGlobalPTCNetwork", tooltipText: "Gain access to a global network of investors" },
  { icon: true, label: "Profile published", value: "profilePublished", tooltipText: "Have your company profile on connect published" },
  { icon: true, label: "Integrated calendar", value: "integratedCalendar", tooltipText: "Intergrated calendar system for meetings" },
  { icon: true, label: "Video hosted on PTC platform", value: "videoHostedOnPTCPlatform", tooltipText: "Videos attached to company profile" },
  { icon: false, label: "Number of days on app", value: "durationOfDays", tooltipText: "Amount of days your account will be live on platform" },
  { icon: false, label: "Free meetings", value: "freeNoOfMeetings", tooltipText: "Included free meetings" },
  { icon: false, label: "Meeting price", value: "perMeetingPrice", tooltipText: "Price per meeting" },
  { icon: false, label: "Additional meetings with PTC", value: "additionalMeetingsWithPTC", tooltipText: "Additional meetings with the PTC team" },
  { icon: true, label: "Video and pitch training", value: "videoAndPitchTraining", tooltipText: "The PTC raise collateral advisory " },
  { icon: true, label: "Professional video production", value: "professionalVideoProduction", tooltipText: "Utilise The PTC resource to develop video" },
  { icon: true, label: "Network promotion", value: "networkPromotion", tooltipText: "Leverage The PTC network to promote your brand" },
  { icon: true, label: "VC insights (pre-meeting)", value: "vcInsightsPreMeeting", tooltipText: "Be provided with relevant insights about potential investors pre-meeting" },
]

export const planTexts = {
  title: 'Change plan',
  reviewSelection: 'Review your selection for monthly payment',
  totalCost: 'Total cost',
  termAndConditions: 'Find out more about our terms and conditions ',
  upgradePlan: "You do not have any active plans. Please go to 'Choose Plan' under the user profile.",
  termAndConditionURL: "http://www.connectbytheptc.com/terms-conditions",
  privacyPolicyURL: "https://www.theproptechconnection.com/legal/privacy-policy",
}