import { Navigate } from 'react-router-dom';
import { getVcAccessToken } from '../../../utils/session';

// For public user
const PublicRoute = ({ children }) => {
  const isLogin = getVcAccessToken();

  return (
    <>
      {!isLogin ? children : <Navigate to='/investor/dashboard' />}
    </>
  );
};
export default PublicRoute;