import moment from 'moment'
import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import defaultProfile from '../../../../../assets/Icons/default-profile.svg';
import '../homeTab.scss';
import { Tooltip } from '@mui/material';

export default function Proptechcard(props) {
  // Props
  const { logo, title, publishedAt, onCardClick, checked, onChecked, meetings, type } = props

  return (
    <>
      <div className={`proptech-card-border`}>
      <Tooltip title={type === "all" && checked ? "The proptech has been selected" : null}>
        <div className={`proptech-tab-heading ${checked && 'bg-[#E7E8EC]'}`}>
          <div className='flex box-border items-center flex-wrap'>
            <div className='image-container' onClick={onCardClick}>
              <img
                src={logo || defaultProfile}
                className="logo object-contain"
                alt="Profile"
              />
            </div>
            <div className='grow'>
                <div className='flex justify-between'>
                  <span className='title' onClick={onCardClick}>{title}</span>
                </div>
                <div className='w-full flex'>
                  <div className="label w-1/2">
                    <span className='header'>Published</span>
                    <span className='value'>{publishedAt ? moment.tz(publishedAt, "Australia/Sydney").format('DD MMM YYYY') : '-'}</span>
                  </div>
                  <div className="label mr-1">
                    <span className='header'>Meetings</span>
                    <span className='value'>{meetings || 0}</span>
                  </div>
                </div>
              </div>
            <div>
              <Checkbox style={{
                transform: "scale(1.2)",
              }} 
              disabled={type === "all" && checked ? true : false}
              className="!p-0 admin-form-checkbox" id="remember" checked={checked} onChange={onChecked} />
            </div>
          </div>
        </div>
        </Tooltip>
      </div>
    </>
  )
}
