import { PROPTECH_SIGNUP_COMPLETED_API } from "../../../constants/APIs";
import { removeProptechAccessToken, removeProptechLocalStorageUserData } from "../../session";
import { PROPTECH_API } from "../../utility/API-call";
import { startLoading, stopLoading } from "../../utility/loader";
import { handleError, handleSuccess } from "../../utility/snackbar";

export const signupCompleted = () => {
  startLoading();
  PROPTECH_API().put(PROPTECH_SIGNUP_COMPLETED_API, { isSignupCompleted: 1 })
    .then((res) => {
      stopLoading();
      handleSuccess(res)
      removeProptechAccessToken()
      removeProptechLocalStorageUserData()
      window.location.href = '/proptech/login';
    })
    .catch(err => {
      stopLoading();
      handleError(err)
    })
}