import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./previewScreen.scss";
import Loader from "../../../components/Loading";
import PTInput from "../../../components/GenericComponents/PTInput";
import { MenuItem } from "@mui/material";
import ConnectPreviewScreen from "./connect";
import PlacePreviewScreen from "./place";

export default function PreviewScreen(props) {
  const { closeIconClick } = props;

  const isLoading = useSelector((state) => state.adminUser.isLoading);

  const [viewState, setViewState] = useState(1);

  const onChangePreviewState = (e) => {
    setViewState(e.target.value);
  };

  const renderView = (id) => {
    switch (id) {
      case 1:
        return <ConnectPreviewScreen tabId={id}/>;
      case 2:
        return <PlacePreviewScreen tabId={id}/>;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="bg-black box-border h-full p-5 fixed top-0 left-0 w-full z-[100] preview-screen">
        <div className="bg-white rounded-xl flex flex-col h-full relative w-full overflow-hidden">
          <div className="absolute right-0 top-0 flex bg-[#F7F7F7] z-20">
            <PTInput
              variant="standard"
              label=""
              className="custom-dropdown-icon m-2 flex items-center"
              select
              selectChildren={[
                { name: "Connect", id: 1 },
                { name: "Place", id: 2 },
              ].map((item) => (
                <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
              ))}
              placeholder=""
              disabled={isLoading}
              value={viewState}
              handleOnChangeEvent={onChangePreviewState}
            />
            <IconButton aria-label="delete" size="small">
              <HighlightOffIcon fontSize="large" onClick={closeIconClick} />
            </IconButton>
          </div>
          {renderView(viewState)}
        </div>
      </div>
    </>
  );
}
