import { ADMIN_INVESTORS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { getTagsData } from "../dropdowns"
import { investorHomeProptechsListDTO } from "../dtos/adminInvestor"

export const loadInvestorHomeData = (id, adminInvestor, adminDropdowns) => {
  getInvestorAllProptechs(id, adminInvestor.searchProptech)
  getInvestorHomeProptechs(id)
  loadInvestorHomeDropdowns(adminDropdowns.tags)
}

export const getInvestorAllProptechs = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  }

  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS + `/${id}/proptechs/home`, { params })
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetProptechsData, investorHomeProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const getInvestorHomeProptechs = (id) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().get(ADMIN_INVESTORS + `/${id}/home`)
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      dispatch(Actions.AdminInvestor.SetHomeProptechsData, investorHomeProptechsListDTO(res.data.data.rows))
      handleSuccess(res)
    }).catch(err => {
      // dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err)
    })
}

export const updateInvestorHomeProptechs = (id, data, searchProptech, adminDropdowns) => {
  dispatch(Actions.AdminInvestor.SetLoading, true)
  ADMIN_API().put(ADMIN_INVESTORS + `/${id}/home`, data)
    .then(res => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      loadInvestorHomeData(id, { searchProptech }, adminDropdowns)
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      console.log(err)
      handleError(err)
    })
}

export const updateInvestorHomeTags = (id, data, clearData) => {
  ADMIN_API().put(ADMIN_INVESTORS + `/${id}/forYouTags`, data)
    .then(res => {
      handleSuccess(res)
      getInvestorHomeProptechs(id)
      clearData()
    }).catch(err => {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      handleError(err);
    })
}

export const loadInvestorHomeDropdowns = (tags) => {
  if (!tags.length) {
    getTagsData();
  }
}