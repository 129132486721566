import React, { useEffect, useState } from "react";
import PTInput from "../../../../../components/GenericComponents/PTInput";
import { AnalysisLabelStyle } from "../../../../../proptech/constants/General";
import DropdownColumn from "../../../DropdownColumn";
import { useSelector } from "react-redux";
import {
  PROJECT_OVERVIEW_PAYLOAD,
  PROJECT_OVERVIEW_ERROR,
} from "../../../../utils/payloads/payloads.js";
import PTButton from "../../../../../components/GenericComponents/PTButton";
import { trimQuery } from "../../../../../utils/utility/utility";
import ConfirmModal from "../../../ConfirmModal";
import { dispatch } from "../../../../../utils/utility/store";
import { updateProjectOverviewDetails } from "../../../../utils/middleware/projectOverviewTab";
import { Actions } from "../../../../../store/actions";
import Loader from "../../../../../components/Loading";
import { isUnsaveChanges } from "../../../../utils/middleware/projectTab";

const CompanyOverview = (props) => {
  const { setUnsaveChange } = props;

  const companyOverviewData = useSelector(
    (state) => state.adminProjectTab.companyOverviewData
  );
  const selectedTechBuyer = useSelector(
    (state) => state.adminTechBuyer.selectedTechBuyer
  );
  const { isLoading } = useSelector((state) => state.adminUser);
  const { techBuyers, sectors } = useSelector((state) => state.adminDropdowns);

  const [editedDetails, setEditedDetails] = useState(PROJECT_OVERVIEW_PAYLOAD);
  const [errors, setErrors] = useState(PROJECT_OVERVIEW_ERROR);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange);
  }, [editedDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails));
    Object.keys(data).forEach((error) => {
      data = {
        ...data,
        [error]:
          editedDetails?.[error] !== null
            ? !editedDetails?.[error]
            : !companyOverviewData?.[error],
      };
    });
    setErrors(data);
    return Object.keys(data).some((k) => data[k]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = companyOverviewData?.[key]));

    handleCloseModal();
    try {
      let res = await updateProjectOverviewDetails(selectedTechBuyer?.id, {
        ...Object.fromEntries(
          Object.entries(editedDetails).filter(([_, v]) => !!v)
        ),
        ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
      });
      if (res === 200) setEditedDetails(PROJECT_OVERVIEW_PAYLOAD);
    } catch {
      dispatch(Actions.AdminUser.SetLoading, false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="mb-[22px] pb-10 w-[95%]">
        <div className="url-list-item">
          <PTInput
            labelStyle={AnalysisLabelStyle}
            variant="standard"
            label="Summary"
            fullWidth
            placeholder=""
            multiline
            maxRows={4}
            maxLength={400}
            value={
              editedDetails?.overview !== null
                ? editedDetails?.overview
                : companyOverviewData?.overview || ""
            }
            handleOnChangeEvent={(e) =>
              setEditedDetails({
                ...editedDetails,
                overview: e.target.value,
              })
            }
            error={errors.overview}
            helperText={errors.overview && "Overview Required."}
          />
          <p className="text-xs text-right pt-1 font-semibold uppercase text-light-grey">
            {editedDetails?.overview !== null
              ? editedDetails?.overview?.length
              : companyOverviewData?.overview?.length || 0}
            /<span>400</span> CHARACTERS
          </p>
        </div>
        <div className="mb-[22px] mt-2 flex analysis-dropdown-wrapper">
          <DropdownColumn
            header="Strategic Focus"
            dropdown1Label="Strategic Focus 1"
            dropdown1Options={techBuyers}
            dropdown1Value={
              editedDetails?.focus1 || companyOverviewData?.focus1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, focus1: e.target.value })
            }
            dropdown1Error={errors.focus1}
            dropdown1HelperText={errors.focus1 && "Strategic focus 1 Required."}
            dropdown2Label="Strategic Focus 2"
            dropdown2Options={techBuyers}
            dropdown2Value={
              editedDetails?.focus2 || companyOverviewData?.focus2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, focus2: e.target.value })
            }
            dropdown2Error={errors.focus2}
            dropdown2HelperText={errors.focus2 && "Strategic focus 2 Required."}
            dropdown3Label="Strategic Focus 3"
            dropdown3Options={techBuyers}
            dropdown3Value={
              editedDetails?.focus3 || companyOverviewData?.focus3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({ ...editedDetails, focus3: e.target.value })
            }
            dropdown3Error={errors.focus3}
            dropdown3HelperText={errors.focus3 && "Strategic focus 3 Required."}
          />
          <DropdownColumn
            header="Asset sectors"
            dropdown1Label="Asset sectors 1"
            dropdown1Options={sectors}
            dropdown1Value={
              editedDetails?.sector1 || companyOverviewData?.sector1
            }
            dropdown1HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                sector1: e.target.value,
              })
            }
            dropdown1Error={errors.sector1}
            dropdown1HelperText={errors.sector1 && "Asset Sector 1 Required."}
            dropdown2Label="Asset sectors 2"
            dropdown2Options={sectors}
            dropdown2Value={
              editedDetails?.sector2 || companyOverviewData?.sector2
            }
            dropdown2HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                sector2: e.target.value,
              })
            }
            dropdown2Error={errors.sector2}
            dropdown2HelperText={errors.sector2 && "Asset Sector 2 Required."}
            dropdown3Label="Asset sectors 3"
            dropdown3Options={sectors}
            dropdown3Value={
              editedDetails?.sector3 || companyOverviewData?.sector3
            }
            dropdown3HandleChange={(e) =>
              setEditedDetails({
                ...editedDetails,
                sector3: e.target.value,
              })
            }
            dropdown3Error={errors.sector3}
            dropdown3HelperText={errors.sector3 && "Asset Sector 3 Required."}
          />
        </div>
        <div className="flex justify-end bottom-btn-wrapper">
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Revert
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() =>
              setEditedDetails(PROJECT_OVERVIEW_PAYLOAD)
            }
          />
          <PTButton
            variant="contained"
            label={
              <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">
                Save & Continue
              </span>
            }
            type="submit"
            className="w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]"
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>
        {openModal && (
          <ConfirmModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            handleSucces={updateData}
          />
        )}
      </div>
    </>
  );
};

export default CompanyOverview;
