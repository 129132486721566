import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { List, ListItem } from '@mui/material';
import { Navigation } from 'swiper';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import Clock from '../../../assets/Icons/ic-slider-clock.svg';
import { SWIPER } from '../../constants/General';
import { formatTime } from '../../../utils/utility/utility';
import { DateFormat } from '../../../constants/General';
import '../../pages/Dashboard/dashboard.scss';
import 'swiper/css';
import 'swiper/css/navigation';

export default function PreviousMeetings(props) {
  const { previousMeetings } = props;

  // Handle meeting status label color
  const MeeetingStatueLabel = (status) => {
    return status === 1 ? (
      <span className="text-[#39B54A]">Meeting attended</span>
    ) : status === 2 ? (
      <span className="text-error">Cancelled</span>
    ) : status === 3 ? (
      <span className="text-[#FF4100]">Unattended</span>
    ) : (
      <span>Upcoming</span>
    );
  };

  return (
    <Swiper
      navigation={true}
      slidesPerView={SWIPER.slidesPerView}
      spaceBetween={SWIPER.spaceBetween}
      modules={[Navigation]}
      breakpoints={SWIPER.breakpoints}
      className="upcoming-slider">
      {previousMeetings.map((meeting) => {
        return (
          <SwiperSlide className="px-[2px] pt-0 pb-[5px]">
            <div className="slide-item shadow-[0px_2px_5px_rgba(0,0,0,0.1)] rounded-2xl">
              <div
                className={`slide-image relative h-[125px] rounded-t-2xl bg-[rgba(112,119,142,0.2)] ${meeting?.vcCompanyDetails?.companyLogo ? 'pb-[58.85%]' : ''
                  }`}>
                {meeting?.vcCompanyDetails?.companyLogo ? (
                  <img
                    src={meeting.vcCompanyDetails.companyLogo}
                    alt="Slider Map"
                    className="w-full h-full object-cover absolute inset-0 rounded-t-2xl mix-blend-multiply"
                  />
                ) : (
                  <HideImageOutlinedIcon className="w-full h-full" />
                )}
                {/* <img src={meeting.vcCompanyDetails.companyLogo} alt="Slider Map" className="w-full h-full object-cover absolute inset-0 rounded-t-2xl mix-blend-multiply" /> */}
              </div>
              <div className="slide-content-wrapper py-3 px-[10px]">
                <span className="text-xs font-semibold mb-1.5 block text-[#111D43]">
                  {meeting?.vcCompanyDetails?.vcCompany?.name}
                </span>
                <List className="p-0 ">
                  <ListItem className="p-0 mb-2 font-semibold not-italic text-[10px] leading-[10px]">
                    {MeeetingStatueLabel(meeting.status)}
                  </ListItem>
                  <ListItem className="p-0 items-start mb-1.5">
                    <img src={Clock} alt="Clock" className="mr-1.5 w-[12px] h-[12px]" />
                    <p className="text-[8px] text-[#111D43]">
                      {meeting.startTime ? formatTime(meeting.startTime, DateFormat.DayMonthFormat4) : '-'}
                    </p>
                  </ListItem>
                </List>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
