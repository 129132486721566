import { ADMIN_RFF } from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { dispatch } from "../../../utils/utility/store";
import { getTagsData } from "../dropdowns";
import {
  buyerForYouProptechsListDTO,
  buyerProptechsListDTO,
} from "../dtos/adminRFF";

export const loadRFFForYouData = (id, adminRFF, adminDropdowns) => {
  getRFFProptechs(id, adminRFF.searchProptech);
  getRFFForYouProptechs(id);
  loadRFFForYouDropdowns(adminDropdowns.tags);
};

export const getRFFProptechs = (id, search) => {
  const params = {
    ...(!!search ? { search } : {}),
  };

  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF + `/${id}/proptechs`, { params })
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetProptechsData,
        buyerProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const getRFFForYouProptechs = (id) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .get(ADMIN_RFF + `/${id}/forYou`)
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      dispatch(
        Actions.AdminRFF.SetForYouProptechsData,
        buyerForYouProptechsListDTO(res.data.data.rows)
      );
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const updateRFFForYouProptechs = (
  id,
  data,
  searchProptech,
  adminDropdowns
) => {
  dispatch(Actions.AdminRFF.SetLoading, true);
  ADMIN_API()
    .put(ADMIN_RFF + `/${id}/forYou`, data)
    .then((res) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      loadRFFForYouData(id, { searchProptech }, adminDropdowns);
      handleSuccess(res);
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      console.log(err);
      handleError(err);
    });
};

export const updateRFFForYouTags = (id, data, clearData) => {
  ADMIN_API()
    .put(ADMIN_RFF + `/${id}/forYouTags`, data)
    .then((res) => {
      handleSuccess(res);
      getRFFForYouProptechs(id);
      clearData();
    })
    .catch((err) => {
      dispatch(Actions.AdminRFF.SetLoading, false);
      handleError(err);
    });
};

export const loadRFFForYouDropdowns = (tags) => {
  if (!tags.length) {
    getTagsData();
  }
};
