import { ADMIN_CONTACTS } from "../../../constants/APIs"
import { Actions } from "../../../store/actions"
import { ADMIN_API } from "../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../utils/utility/snackbar"
import { dispatch } from "../../../utils/utility/store"
import { contactListDTO } from "../dtos/adminContacts"

export const getContacts = (isPrimaryUser, activeSort, sortOrder, search, activefilter, alreadySelectedContact = null, clear = true) => {
  const params = {
    ...({ "isPrimaryUserOnly": isPrimaryUser }),
    ...({ "isPaginationRequired": false }),
    ...({ "userType": activefilter }),
    ...(!!search ? { search } : {}),
    ...(!!sortOrder ? { 'order': sortOrder } : {}),
    ...(!!activeSort ? { 'orderBy': activeSort } : {}),
  }
  dispatch(Actions.Contacts.SetLoading, true)
  ADMIN_API().get(ADMIN_CONTACTS, { params })
    .then(res => {
      handleSuccess(res)
      let contacts = contactListDTO(res?.data?.data?.rows)
      dispatch(Actions.Contacts.ContactsListSet, contacts)
      if (clear) {
        dispatch(Actions.Contacts.SetIsSelectedContact, false)
        dispatch(Actions.Contacts.SetSelectedContact, null)
      }
      if (contacts?.length && alreadySelectedContact) {
        contacts.every(user => {
          if (user?.id === alreadySelectedContact?.id && user?.userType === alreadySelectedContact?.userType) {
            dispatch(Actions.Contacts.SetSelectedContact, user)
            dispatch(Actions.Contacts.SetIsSelectedContact, true)
            return false;
          }
          return true
        })
      }
    }).catch(err => {
      dispatch(Actions.Contacts.SetLoading, false)
      handleError(err)
    })
}

export const changeStatus = (id, userType, isActive, isPrimaryUser, activeSort, sortOrder, search, activefilter) => {
  dispatch(Actions.Contacts.SetLoading, true)
  ADMIN_API().put(`${ADMIN_CONTACTS}/${id}`, {
    userType: userType, isActive
  })
    .then(res => {
      handleSuccess(res)
      getContacts(isPrimaryUser, activeSort, sortOrder, search, activefilter)
      dispatch(Actions.Contacts.SetLoading, false)
    }).catch(err => {
      dispatch(Actions.Contacts.SetLoading, false)
      handleError(err)
    })
}