export const chartData = [
  "evolvingAssets",
  "userExperience",
  "dealClosing",
  "decisionMaking",
  "smarterBuilds",
  "connectivity",
];

export const chartLabels = [
  "Evolving assets",
  ["User", "experience"],
  ["Deal", "closing"],
  "Decision making",
  ["Smarter", "builds"],
  "Connectivity",
];

export const marketChartData = [
  "geographyAgnostic",
  "currentPipeline",
  "marketSize",
  "competition",
  "costToScale",
  "highDemand",
];

export const marketChartLabels = [
  "Geography agnostic",
  ["Current", "pipeline"],
  "Market size",
  "Competition",
  ["Cost to scale"],
  ["High", "demand"]
];

export const numbersChartData = [
  "cashProfile",
  "profitability",
  "stickiness",
  "speedToScale",
  "salesConversion",
  "customerType",
];

export const numbersChartLabels = [
  "Cash profile",
  "Profitability",
  "Stickiness",
  "Speed to Scale",
  ["Sales", "conversion"],
  ["Customer", "type"],
];

export const raiseChartData = [
  "team",
  "model",
  "technology",
  "timing",
  "network",
  "defensibility",
]

export const raiseChartLabels = [
  "Team",
  "Model",
  "Technology",
  "Timing",
  "Network",
  "Defensibility",
]

export const designReviewChartData = [
  "visualDesignBranding",
  "intuitiveness",
  "efficiency",
  "feedback",
  "errorPreventionRecovery",
  "support",
]

export const designReviewChartLabels = [
  "Visual design & branding",
  "Intuitiveness",
  "Efficiency",
  "Feedback",
  ["Error", "prevention", "& recovery"],
  "Support",
]
