export default function vcProptechDetails(
  initialState = {
    proptechDetails: null,
    raiseOverviewDetails: null,
    overviewDetails: null,
    marketDetails: null,
    numbersDetails: null,
    raiseDetails: null,
    teamDetails: null,
    designReviewDetails: null,
    marketInsightsDetails: null,
    companyProfileDetails: null,
    apiTimeout: {},
  },
  action
) {
  switch (action.type) {
    case 'SET_PROPTECH_DETAILS':
      return {
        ...initialState,
        proptechDetails: action.payload
      };

    case 'SET_OVERVIEW_DETAILS':
      return {
        ...initialState,
        overviewDetails: action.payload
      };

    case 'SET_MARKET_DETAILS':
      return {
        ...initialState,
        marketDetails: action.payload
      };

    case 'SET_NUMBERS_DETAILS':
      return {
        ...initialState,
        numbersDetails: action.payload
      };

    case 'SET_RAISE_DETAILS':
      return {
        ...initialState,
        raiseDetails: action.payload
      };

    case 'SET_TEAMS_DETAILS':
      return {
        ...initialState,
        teamDetails: action.payload
      };

    case 'SET_RAISEOVERVIEW_DETAILS':
      return {
        ...initialState,
        raiseOverviewDetails: action.payload
      };

    case 'SET_DESIGNREVIEW_DETAILS':
      return {
        ...initialState,
        designReviewDetails: action.payload
      };

    case 'SET_MARKETINSIGHTS_DETAILS':
      return {
        ...initialState,
        marketInsightsDetails: action.payload
      };

    case 'SET_COMPANYPROFILE_DETAILS':
      return {
        ...initialState,
        companyProfileDetails: action.payload
      };

    case 'SET_APITIMEOUT':
      return {
        ...initialState,
        apiTimeout: { ...initialState.apiTimeout, ...action.payload }
      };

    case 'PROPTECH_DETAILS_RESET':
      return {
        ...initialState,
        proptechDetails: null,
        raiseOverviewDetails: null,
        overviewDetails: null,
        marketDetails: null,
        numbersDetails: null,
        raiseDetails: null,
        teamDetails: null,
        designReviewDetails: null,
        marketInsightsDetails: null,
        companyProfileDetails: null,
        apiTimeout: {}
      };

    default:
      return initialState;
  }
}
