import moment from "moment";

export function meetingDTO(response) {
  let meetingData = [];
  if (response && response.length) {
    meetingData = response.map((data) => {
      return {
        id: data?.id || 0,
        topic: data?.topic || '',
        startTime: data?.startTime || moment().format(),
        endTime: data?.endTime || moment().format(),
        duration: data?.duration || 0,
        meetingLink: data?.meetingLink || "",
        rescheduleLink: data?.rescheduleLink || "",
        vcUserName: data?.vcUserName || "",
        requestedRescheduleTime: data?.requestedRescheduleTime || null,
        rescheduleMeetingCount: data?.rescheduleMeetingCount || 0,
        status: data?.status || 0,
        feedbackType: data?.feedbackType || 0,
        createdAt: data?.createdAt || moment().format(),
        updatedAt: data?.updatedAt || moment().format(),
        proptechCompanyDetails: data?.proptechCompanyDetails || {},
      };
    });
  }
  return meetingData;
}
