import { MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import PTInput from '../../../../components/GenericComponents/PTInput';
import Loader from '../../../../components/Loading';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { trimQuery, urlValidate } from '../../../../utils/utility/utility';
import { uploadImage } from '../../../utils/middleware/companyTab';
import placeholderImage from '../../../../assets/Icons/placeholder.png';
import { updateVentureScoutProfileDetails } from '../../../utils/middleware/ventureScoutProfile';
import { VENTURE_SCOUT_PROFILE_ERROR, VENTURE_SCOUT_PROFILE_PAYLOAD } from '../../../utils/payloads/payloads';
import FullWidthTextField from '../../FullWidthTextField';
import ConfirmModal from '../../ConfirmModal';
import { VENTURE_SCOUT_PROFILE_TAB_COMMISSIONS_FIELDS, VENTURE_SCOUT_PROFILE_TAB_FIELDS } from '../../../constants';
import { AnalysisLabelStyle } from '../../../../proptech/constants/General';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';

export default function VentureScoutProfileTab(props) {
  const { setUnsaveChange } = props

  // Store
  const { isLoading, selectedVentureScout, activeSort, sortOrder, profileTabData, search } = useSelector(state => state.adminVentureScout);
  const { countries } = useSelector(state => state.adminDropdowns)

  // State
  const [editedDetails, setEditedDetails] = useState(VENTURE_SCOUT_PROFILE_PAYLOAD);
  const [errors, setErrors] = useState(VENTURE_SCOUT_PROFILE_ERROR);
  const [files, setFiles] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef();

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ?
          error === 'linkedInURL' ? editedDetails?.[error] ? !urlValidate(editedDetails?.[error]) : false : !editedDetails?.[error] :
          error === 'linkedInURL' ? profileTabData?.[error] ? !urlValidate(profileTabData?.[error]) : false : !profileTabData?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const handleImage = (e, key) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFiles(e.target.files[0])
      uploadImage(e.target.files[0], 'vs-user-profile-image')
        .then(res => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, 'profileImage': res.data.fileName })
        })
        .catch(() => setLoading(false))
    }
  }

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = key === 'profileImage' ? profileTabData?.[key]?.split("/").slice(-2).join("/") : profileTabData?.[key]));
    handleCloseModal()
    try {
      await updateVentureScoutProfileDetails(
        selectedVentureScout?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => (v !== null && v !== ''))),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => v !== null))
        }, activeSort, sortOrder, search)
    } catch {
      dispatch(Actions.AdminInvestor.SetLoading, false)
      console.log('error')
    }
  }

  return (
    <div className='admin-profile-wrapper'>
      {isLoading && <Loader />}
      <div className='profile-container'>
        <div className='w-full flex company-two-col-block mb-12'>
          <input
            id="file"
            ref={inputRef}
            type="file"
            className="form-control input-field"
            value=''
            accept={['jpg', 'jpeg', 'png']
              .map(function (x) {
                return '.' + x;
              })
              .join(',')}
            onChange={handleImage}
          />
          <div className='left-form-block'>
            <div className='left-field form-item-wrap'>
              <span>Profile Image</span>
              <div className='profile-image'>
                <PTButton
                  variant="contained"
                  label={!loading && <span className="upload-button-label">Upload</span>}
                  className='upload-button'
                  loading={loading}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                  }}
                />
                {errors.profileImage && <span className='error-message'>Image Required.</span>}
              </div>
            </div>
            <div className='left-field form-item-wrap'>
              <span>Country</span>
              <PTInput
                variant="standard"
                label="Country"
                className="custom-dropdown-icon"
                select
                selectChildren={countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                placeholder=''
                disabled={loading}
                value={editedDetails?.countryId || profileTabData?.countryId}
                handleOnChangeEvent={(e) => setEditedDetails({ ...editedDetails, countryId: e.target.value })}
                error={errors.countryId}
                helperText={errors.countryId && "Country Required."}
              />
            </div>

            {VENTURE_SCOUT_PROFILE_TAB_FIELDS.map(field => {
              return <FullWidthTextField
                editedDetails={editedDetails}
                setEditedDetails={setEditedDetails}
                errors={errors}
                maxLength={field?.maxLength}
                type={field?.type}
                data={profileTabData}
                textLabel={field.textLabel}
                textValue={field.textValue}
                validUrlText={field.validUrlText}
              />
            })}

            {VENTURE_SCOUT_PROFILE_TAB_COMMISSIONS_FIELDS.map(field => {
              return <div className='mb-[25px]'>
                <PTInput
                  labelStyle={AnalysisLabelStyle}
                  variant="standard"
                  label={field.textLabel}
                  placeholder=''
                  fullWidth
                  type="number"
                  maxLength={field?.maxLength}
                  value={editedDetails?.[field.textValue] !== null ? editedDetails?.[field.textValue] : profileTabData?.[field.textValue] || 0}
                  handleOnChangeEvent={(e) => {
                    var value = parseInt(e.target.value, 10);

                    if (value > 100) value = 100;
                    if (value < 0) value = 0;

                    setEditedDetails({ ...editedDetails, [field.textValue]: value })
                  }}
                  error={errors?.[field.textValue]}
                  helperText={errors?.[field.textValue] && `${field.textLabel} Required.`}
                />
              </div>
            })}

          </div>
          <div className='right-image-block'>
            {(files || profileTabData?.profileImage) ?
              <div className='inner-image-block'><img src={files ? URL.createObjectURL(files) : profileTabData?.profileImage} alt="Profile" width={'100%'} height={'100%'} /></div> :
              <div className='inner-image-block'><img src={placeholderImage} alt="placeholder" /></div>}
          </div>
        </div>

        <div className='bottom-btn-wrapper flex justify-end'>
          <PTButton variant="contained"
            label={<span className="submit-button-label">Revert</span>}
            type="submit" className='submit-button mr-5'
            handleOnClickEvent={() => {
              setEditedDetails(VENTURE_SCOUT_PROFILE_PAYLOAD)
              setFiles(null)
            }}
          />
          <PTButton
            variant="contained"
            label={<span className="submit-button-label">Save & Continue</span>}
            type="submit"
            className='submit-button'
            handleOnClickEvent={() => !checkValidation() && handleOpenModal()}
          />
        </div>
      </div>

      {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={updateData} />}
    </div>
  )
}
