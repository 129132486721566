import React from 'react';
import { Box, Card, CardContent, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import PTButton from '../../../components/GenericComponents/PTButton';
import PTInput from '../../../components/GenericComponents/PTInput';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CardContentStyle, CardStyle, PaymentDetailsModal } from '../../constants/General';

export default function PaymentDetails(props) {
  const { loading, openModal, activePaymentDetails, closeModal, cardDetails, setCardDetails, handleSubmit } = props;

  return <Modal
    open={openModal}
    onClose={closeModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <>
      <Box sx={PaymentDetailsModal} >
        <Typography id="modal-modal-title" variant="h6" className='mb-5' component="h2">
          Payment details
        </Typography>
        {activePaymentDetails && <>
          <h1>Saved card details</h1>
          <Card style={CardStyle}>
            <CardContent style={CardContentStyle}>
              {loading ? <div className='w-full h-full flex justify-center items-center'>
                <CircularProgress />
              </div> : <>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis' title={activePaymentDetails.cardHolderName}>{activePaymentDetails.cardHolderName}</span>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis' title={activePaymentDetails.cardBrand}>xxxx{activePaymentDetails.lastFourDigit} | <span>{activePaymentDetails.cardBrand}</span></span></>}
            </CardContent>
          </Card>
          <div className='mb-2'>Enter new card details</div>
        </>}
        <PTInput type='number' label="Card number" className='w-full' value={cardDetails.cardNumber} variant="standard"
          handleOnChangeEvent={(e) => {
            if (e.target.value?.length < 17) {
              setCardDetails({ ...cardDetails, cardNumber: e.target.value })
            }
          }} />
        <div className="flex my-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Expiry"
              className='card-expiry w-2/3 mr-5'
              views={['year', 'month']}
              minDate={new Date()}
              inputFormat="MM/yyyy"
              value={cardDetails.expiry}
              onChange={(date) => setCardDetails({ ...cardDetails, expiry: date })}
              renderInput={(params) => <TextField className="w-2/3" {...params} error={false} value={cardDetails.expiry} variant="standard" InputProps={{ ...params.InputProps }} InputLabelProps={{ ...params.InputLabelProps }} />}
            />
          </LocalizationProvider>
          <PTInput variant='standard' type='number' className='w-1/3' label="CVC" value={cardDetails.cvc} handleOnChangeEvent={(e) => {
            if (e.target.value <= 999) {
              setCardDetails({ ...cardDetails, cvc: e.target.value })
            }
          }} />
        </div>
        <PTInput label="Card holder name" className='w-full mb-8' value={cardDetails.name} variant="standard" handleOnChangeEvent={(e) => setCardDetails({ ...cardDetails, name: e.target.value })} />
        <div className="btn-wrapper p-0">
          <PTButton
            label="Cancel"
            className={"modal-cancel-button"}
            handleOnClickEvent={closeModal}
          />
          <PTButton
            label="Submit"
            className={"modal-change-button"}
            handleOnClickEvent={handleSubmit}
          />
        </div>
      </Box>
    </>
  </Modal >
}
