import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

export const StyledValueLabel = withStyles({
    offset: {
        top: 22,
        left: 20
    },
    label: {
        backgroundColor: '#FFFFFF',
        color: "#111D43",
        fontSize: 13,
        fontWeight: 600,
        transform: 'rotate(0deg)',
    }
})(ValueLabel);

export const CustomSlider = withStyles({
    root: {
        height: 3,
    },
    thumb: {
        height: 24,
        width: 24,
        color: "#FF4100",
        marginTop: "-7px !important",
        marginLeft: "-7px !important"
    },
    track: {
        height: 1,
        color: "rgba(17, 29, 67, 0.4)"
    },
    rail: {
        color: "rgba(17, 29, 67, 0.4)",
        opacity: 1,
        height: 1
    },
    markActive: {
        backgroundColor: "rgba(17, 29, 67, 0.4)",
        fontWeight: 700
    },
    markLabel: {
        color: 'rgba(17, 29, 67, 0.4)',
        fontFamily: "'Poppins'",
        fontWeight: 600,
        fontSize: 13,
        '&[data-index="0"]': {
            transform: 'none'
        },
    },
})(Slider);
