export function userListDTO(data) {
  let usersList = [];
  if (data && data.length) {
    usersList = data.map((user) => {
      return {
        id: user?.id || 0,
        name: user?.name || null,
        email: user?.email || null,
        status: user?.status !== null ? user.status : false,
        lastLoginAt: user?.lastLoginAt || null,
        createdAt: user?.createdAt || null,
      }
    });
  }

  return usersList;
}