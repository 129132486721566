import { ADMIN_TECH_BUYERS } from '../../../constants/APIs'
import { Actions } from '../../../store/actions'
import { ADMIN_API } from '../../../utils/utility/API-call'
import { handleError, handleSuccess } from '../../../utils/utility/snackbar'
import { dispatch } from '../../../utils/utility/store'
import { getCountriesData } from '../dropdowns'
import {  buyerProfileTabTagsListDTO } from '../dtos/adminTechBuyer'
import { getTechBuyers } from './adminTechBuyer'

export const loadTechBuyerProfileTabData = (id, adminTechBuyer, adminDropdowns) => {
  getTechBuyerProfileDetails(id)
  // getTechBuyerProfileTagDetails(id)
  loadTechBuyerProfileTabDropdowns(adminDropdowns.countries)
}

export const getTechBuyerProfileDetails = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS + `/${id}`)
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetProfileTabData, res.data.data);
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err)
    })
}

export const getTechBuyerProfileTagDetails = (id) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  ADMIN_API().get(ADMIN_TECH_BUYERS + `/${id}/tags`)
    .then(res => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      dispatch(Actions.AdminTechBuyer.SetProfileTabTagsData, buyerProfileTabTagsListDTO(res.data.data))
      handleSuccess(res)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err)
    })
}

export const updateTechBuyerProfileDetails = (id, data, tags, isArchived, activeSort, sortOrder, search, otherCountries) => {
  dispatch(Actions.AdminTechBuyer.SetLoading, true)
  if(otherCountries) {
    data.otherCountries = otherCountries
  }
  if (tags) {
    updateTechBuyerProfileTagDetails(id, tags)
  }

  ADMIN_API().put(ADMIN_TECH_BUYERS + `/${id}`, data)
    .then(res => {
      handleSuccess(res)
      getTechBuyerProfileDetails(id)
      getTechBuyers(isArchived, activeSort, sortOrder, search, false);
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err);
    })
}

export const updateTechBuyerProfileTagDetails = (id, data) => {
  ADMIN_API().put(ADMIN_TECH_BUYERS + `/${id}/tags`, { tagIds: data.map(a => a.id) })
    .then(res => {
      handleSuccess(res)
      getTechBuyerProfileTagDetails(id)
    }).catch(err => {
      dispatch(Actions.AdminTechBuyer.SetLoading, false)
      handleError(err);
    })
}


export const loadTechBuyerProfileTabDropdowns = (countries) => {
  if (!countries.length) {
    getCountriesData();
  }
}