import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton';
import Loader from '../../../../components/Loading';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import { trimQuery, urlValidate } from '../../../../utils/utility/utility';
import { uploadImage } from '../../../utils/middleware/companyTab';
import { changeStatusofNewsfeed, deleteNewsfeedDetails, updateNewsfeedDetails } from '../../../utils/middleware/newsfeed';
import { NEWSFEED_ERROR, NEWSFEED_PAYLOAD } from '../../../utils/payloads/payloads';
import placeholderImage from '../../../../assets/Icons/placeholder.png';
import FullWidthTextField from '../../FullWidthTextField';
import ConfirmModal from '../../ConfirmModal';
import { isUnsaveChanges } from '../../../utils/middleware/analysisTab';
import './newsfeedCardDetails.scss'

export default function NewsfeedCardDetails(props) {
  const { setUnsaveChange } = props

  // State
  const [editedDetails, setEditedDetails] = useState(NEWSFEED_PAYLOAD);
  const [errors, setErrors] = useState(NEWSFEED_ERROR);
  const [files, setFiles] = useState(null)
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [modalAccess, setModalAccess] = useState('')
  const inputRef = useRef();

  // Store
  const selectedNewsfeed = useSelector(state => state.newsfeeds.selectedNewsfeed)
  const isLoading = useSelector(state => state.newsfeeds.isLoading)
  const { activeSort, sortOrder, isActive, search } = useSelector(state => state.newsfeeds);


  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    isUnsaveChanges(editedDetails, setUnsaveChange)
  }, [editedDetails]);  // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = () => {
    let data = errors;
    setEditedDetails(trimQuery(editedDetails))
    Object.keys(data).forEach(error => {
      data = {
        ...data, [error]: editedDetails?.[error] !== null ?
          error === 'redirectionURL' ? editedDetails?.[error] ? !urlValidate(editedDetails?.[error]) : false : !editedDetails?.[error] :
          error === 'redirectionURL' ? selectedNewsfeed?.[error] ? !urlValidate(selectedNewsfeed?.[error]) : false : !selectedNewsfeed?.[error]
      }
    })
    setErrors(data)
    return Object.keys(data).some(k => data[k])
  }

  const handleImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFiles(e.target.files[0])
      uploadImage(e.target.files[0], 'newsfeed')
        .then(res => {
          setLoading(false);
          setEditedDetails({ ...editedDetails, image: res.data.fileName })
        })
        .catch(() => setLoading(false))
    }
  }

  useEffect(() => {
    setFiles(null)
    setEditedDetails(NEWSFEED_PAYLOAD)
    setErrors(NEWSFEED_ERROR)
  }, [selectedNewsfeed])

  const updateData = async () => {
    const newData = {};
    Object.entries(editedDetails)
      .filter(([, value]) => value === null)
      .forEach(([key, value]) => (newData[key] = key === 'image' ? selectedNewsfeed?.[key]?.split("/").slice(-2).join("/") : selectedNewsfeed?.[key]));

    handleCloseModal()
    try {
      await updateNewsfeedDetails(
        selectedNewsfeed?.id,
        {
          ...Object.fromEntries(Object.entries(editedDetails).filter(([_, v]) => !!v)),
          ...Object.fromEntries(Object.entries(newData).filter(([_, v]) => !!v)),
        }, isActive, activeSort, sortOrder, search)
    } catch {
      dispatch(Actions.Newsfeeds.SetLoading, false)
      console.log('error')
    }
  }

  const confirmAction = () => {
    if (modalAccess === 'delete') {
      deleteNewsfeedDetails(selectedNewsfeed?.id, isActive, activeSort, sortOrder, search, handleCloseModal)
    } else if (modalAccess === 'status') {
      changeStatusofNewsfeed(selectedNewsfeed?.id, { status: !selectedNewsfeed?.status }, isActive, activeSort, sortOrder, search, handleCloseModal)
    } else {
      updateData()
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='newsfeed-card-details horizontal  '>
        <input
          style={{ display: 'none' }}
          id="file"
          ref={inputRef}
          type="file"
          className="form-control"
          value=''
          accept={['jpg', 'jpeg', 'png']
            .map(function (x) {
              return '.' + x;
            })
            .join(',')}
          onChange={(e) => handleImage(e)}
        />
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          maxLength={70}
          disabled={loading}
          data={selectedNewsfeed}
          textLabel="Title"
          textValue="title"
        />
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          disabled={loading}
          data={selectedNewsfeed}
          textLabel="Description"
          textValue="description"
        />
        <FullWidthTextField
          editedDetails={editedDetails}
          setEditedDetails={setEditedDetails}
          errors={errors}
          disabled={loading}
          data={selectedNewsfeed}
          textLabel="Redirection URL"
          textValue="redirectionURL"
          validUrlText
        />
        <div className='w-full flex company-two-col-block'>
          <div className='left-form-block'>
            <div className='mt-2 flex justify-between form-item-wrap items-center'>
              <span>Image</span>
              <div className='flex flex-col'>
                <PTButton
                  variant="contained"
                  label={!loading && <span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#111D43]">Upload</span>}
                  className='w-[120px] h-[44px] !mr-4 !rounded-xl !border-solid !border !border-[#111d43] !bg-[#FFFFFF]'
                  loading={loading}
                  handleOnClickEvent={() => {
                    inputRef.current.click()
                  }}
                />
                {errors.image && <span className='font-normal text-xs mx-0 mb-0 mt-2 text-left tracking-wide text-[#d32f2f]'>Image Required.</span>}
              </div>
            </div>
          </div>
          <div className='right-image-block'>
            {(files || selectedNewsfeed?.image) ?
              <div className='inner-image-block'><img src={files ? URL.createObjectURL(files) : selectedNewsfeed?.image} alt="Profile" width={'100%'} height={'100%'} /></div> :
              <div className='inner-image-block'><img src={placeholderImage} alt="placeholder" /></div>}
          </div>
        </div>
        <div className='newsfeed-btn-wrapper flex justify-between'>
          <div>
            <PTButton variant="contained"
              label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">{selectedNewsfeed.status ? 'Inactive' : 'Active'}</span>}
              type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
              handleOnClickEvent={() => {
                setModalAccess('status')
                handleOpenModal()
              }}
            />
            <PTButton
              variant="contained"
              label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">Delete</span>}
              type="submit"
              className='w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43]'
              handleOnClickEvent={() => {
                setModalAccess('delete')
                handleOpenModal()
              }}
            />
          </div>
          <div>
            <PTButton variant="contained"
              label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Revert</span>}
              type="submit" className='w-[142px] h-[44px] mr-5 !rounded-xl !border-solid !bg-[#111D43]'
              handleOnClickEvent={() => {
                setEditedDetails(NEWSFEED_PAYLOAD)
                setFiles(null)
              }}
            />
            <PTButton
              variant="contained"
              label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider text-[#FFFFFF]">Save & Continue</span>}
              type="submit"
              disabled={loading}
              className='w-[142px] h-[44px] rounded-xl border-solid bg-[#111D43] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]'
              handleOnClickEvent={() => {
                if (!checkValidation()) {
                  setModalAccess('update');
                  handleOpenModal()
                }
              }}
            />
          </div>
        </div>

        {openModal && <ConfirmModal open={openModal} handleCloseModal={handleCloseModal} handleSucces={confirmAction} />}
      </div>
    </>
  )
}
