import React from 'react';
import { useSelector } from 'react-redux';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DASHBOARD } from '../../constants/dashboard';
import '../../pages/VsDashboard/style.scss'
import { getVSStatistics } from '../../utils/middleware/Dashboard';

export default function RecommendBlock() {
  // Store
  const { statistics } = useSelector(state => state.vsDashboard);

  React.useEffect(() => {
    getVSStatistics();
  }, []);
  return (
    <div className="dashboard-inner px-3 pb-8">
      <div className="dashboard-widget relative py-8 px-9 rounded-[32px] h-full bg-[#ffffff]">
        <div className='font-semibold text-lg leading-7 text-[#111D43] pb-4'>{DASHBOARD.recommendblock.yourRec}</div>
        <Swiper
          // navigation={true}
          slidesPerView={1}
          spaceBetween={16}
          modules={[Navigation]}
          className="recommendation-slider"
        >
          <SwiperSlide className="px-1 pt-0 pb-[5px] w-full">
            <div>
              <div className="mt-4 md:mt-10">
                <div className="font-semibold text-xs leading-3 text-left not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.recommendblock.onboard}</div>
                <div className="mt-2 font-normal text-sm leading-5 text-left not-italic capitalize text-[#111D43]">{statistics.onboardedProptechs} / {statistics.rejectedProptechs}</div>
              </div>
              <div className="mt-4 md:mt-10">
                <div className="font-semibold text-xs leading-3 text-left not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.recommendblock.heroZero}</div>
                <div className="mt-2 font-normal text-sm leading-5 text-left not-italic capitalize text-[#111D43]">{statistics.favorite} / {statistics.notFavorite}</div>
              </div>
              <div className="mt-4 md:mt-10">
                <div className="font-semibold text-xs leading-3 text-left not-italic uppercase text-[#111D43] opacity-40">{DASHBOARD.recommendblock.meetingCreated}</div>
                <div className="mt-2 font-normal text-sm leading-5 text-left not-italic capitalize text-[#111D43]">{statistics.totalProptechMeetings} / {statistics.cancelProptechMeetings}</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}
