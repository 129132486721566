import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PTButton from '../../../../components/GenericComponents/PTButton'
import defaultProfile from '../../../../assets/Icons/default-profile.svg';
import { Actions } from '../../../../store/actions';
import { dispatch } from '../../../../utils/utility/store';
import ArrowRightIcon from '../../../../assets/Icons/admin-arrow-right.svg';
import ArrowOpened from '../../../../assets/Icons/admin-arrow-opened.svg';
import ProptechCardLabel from '../../ProptechCardLabel';
import { formatTime } from '../../../../utils/utility/utility';
import { DateFormat, MessageType } from '../../../../constants/General';
import ConfirmModal from '../../ConfirmModal';
import { setActiveTab, updateActivetatus } from '../../../utils/middleware/adminVentureScout';
import { UNSAVED_CHANGES_MESSAGE, Venture_Scout_Card_Labels } from '../../../constants';
import '../ventureScout.scss'
import { showMessage } from '../../../../utils/utility/snackbar';

export default function VentureScoutCard(props) {
  //  **** props ****
  const { ventureScout, unsaveChange } = props

  // State
  const [activeStatus, setActiveStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  // Store
  const { isVentureScoutSelected, selectedVentureScout, activeSort, sortOrder, search, activeTab } = useSelector(state => state.adminVentureScout)
  const adminVentureScout = useSelector(state => state.adminVentureScout);
  const adminDropdowns = useSelector(state => state.adminDropdowns);

  const handleSelected = () => {
    if (!unsaveChange.current) {
      let isSelected = selectedVentureScout?.id !== ventureScout.id ? true : !isVentureScoutSelected
      dispatch(Actions.VentureScouts.SetIsSelectedVentureScout, isSelected)
      if (ventureScout.id === selectedVentureScout?.id) {
        dispatch(Actions.VentureScouts.SetIsSelectedVentureScout, false)
        dispatch(Actions.VentureScouts.SetSelectedVentureScout, null)
      } else {
        dispatch(Actions.VentureScouts.SetSelectedVentureScout, ventureScout)
        setActiveTab(activeTab, ventureScout?.id, adminVentureScout, adminDropdowns)
      }
    } else {
      showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
    }
  }

  const handleActiveStatus = (e, status) => {
    e.stopPropagation()
    setOpenModal(true)
    setActiveStatus(status)
  }

  return (
    <>
      <div className='venture-scout-card-border' />
      <div
        id={`investor-${ventureScout?.id}`}
        className={`venture-scout-card-bg-1 ${ventureScout?.status === 1 ? 'bg-2'
          : ventureScout?.status === 2 && 'bg-3'}`}
        onClick={() => handleSelected()}
      >
        <div className='proptech-tab-heading'>
          <div className='flex box-border items-center '>
            <div className='profile-img mr-[15px] w-[35px] h-[35px] xlg:h-[46px] xlg:w-[46px] rounded-full min-w-[35px] xlg:min-w-[46px]'>
              <img
                src={ventureScout?.profileImage || defaultProfile}
                className="profile-image"
                alt="Profile"
              />
            </div>
            <div className='grow'>
              <div className='flex justify-between'>
                <span className='name'>{ventureScout?.name}</span>
              </div>
              <div className='flex'>
                <ProptechCardLabel
                  header="Created"
                  className="xlg:min-w-[104px]"
                  label={ventureScout?.createdAt ? formatTime(ventureScout?.createdAt, DateFormat.DayMonthFormat) : '-'}
                  style={{ marginRight: '4px' }} />
                <ProptechCardLabel
                  header="Country"
                  className="xlg:min-w-[90px]"
                  label={ventureScout?.country?.name || '-'}
                  style={{ marginRight: '4px' }}
                />
              </div>
            </div>
            <img src={isVentureScoutSelected && selectedVentureScout.id === ventureScout.id ? ArrowOpened : ArrowRightIcon} alt="Icon" className='ml-[20px]' />
          </div>
          <div className='status proptech-heading-btn'>
            {ventureScout?.status === 0 ?
              <PTButton
                variant="contained"
                label={<span className="button-label">Reject</span>}
                type="submit"
                className='button-container'
                handleOnClickEvent={(e) => {
                  if (!unsaveChange.current) {
                    handleActiveStatus(e, 2)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
              : null}
            {ventureScout?.status !== 1 ?
              <PTButton
                variant="contained"
                label={<span className="button-label">Accept</span>}
                type="submit"
                className='button-container'
                handleOnClickEvent={(e) => {
                  if (!unsaveChange.current) {
                    handleActiveStatus(e, 1)
                  } else {
                    showMessage(UNSAVED_CHANGES_MESSAGE.message, MessageType.Error)
                  }
                }}
              />
              : null}
          </div>
        </div>
      </div>
      {
        isVentureScoutSelected && selectedVentureScout.id === ventureScout.id &&
        <>
          <div className='venture-scout-card-border' />
          <div className={`venture-scout-card-bg-1 ${ventureScout?.status === 1 ? 'bg-2'
            : ventureScout?.status === 2 && 'bg-3'}
        detail-block`}>
            <div className='sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.company}</span>
              <p className='text-sm'>{ventureScout?.companyName || '-'}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.email}</span>
              <p className='text-sm'>{ventureScout?.email || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.linkedinUrl}</span>
              <p className='text-sm'>{ventureScout?.linkedInURL || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.reference}</span>
              <p className='text-sm'>{ventureScout?.code || `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.commission}</span>
              <p className='text-sm'>{ventureScout?.onboardCommission || 0}% onboard / {ventureScout?.meetingCommission || 0}% meeting</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.lastUpdated}</span>
              <p className='text-sm'>{ventureScout?.updatedAt ? formatTime(ventureScout?.updatedAt, DateFormat.DayMonthFormat) : `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.lastActive}</span>
              <p className='text-sm'>{ventureScout?.lastLoginAt ? formatTime(ventureScout?.lastLoginAt, DateFormat.DayMonthFormat) : `-`}</p>
            </div>
            <div className='my-4 xlg:my-7 sidebar-info-wrap'>
              <span className='detail-label'>{Venture_Scout_Card_Labels.proptechsOnboardedRejected}</span>
              <p className='text-sm'>{ventureScout?.onboardedProptechs || 0} / {ventureScout?.rejectedProptechs || 0}</p>
            </div>
          </div>
        </>
      }

      {openModal && <ConfirmModal open={openModal} handleCloseModal={() => setOpenModal(false)} handleSucces={() => updateActivetatus(ventureScout?.id, { 'status': activeStatus }, ventureScout, activeSort, sortOrder, search, setOpenModal)} />}
    </>
  )
}
