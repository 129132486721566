import { Box, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import PTButton from "../../../components/GenericComponents/PTButton";
import closeIcon from "../../../assets/Icons/ic-cross.svg";
import "./shareModalStyles.scss";
import { SHARE_MODAL } from "../../constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputWithChips from "../EmailField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ShareProptechModal({
  openShareModal,
  handleShareCloseModal,
  handleSuccess,
}) {
  const [shareMessage, setShareMessage] = useState(SHARE_MODAL.shareMsg);
  const [emailList, setEmailList] = useState([]);
  const [portal, setPortal] = useState("connect");

  const handleChange = (event) => {
    setPortal(event.target.value);
  };

  const handleCloseModal = () => {
    handleShareCloseModal();
    setEmailList([]);
    setShareMessage(SHARE_MODAL.shareMsg);
  };

  return (
    <div>
      <Modal
        open={openShareModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal share-modal"
      >
        <>
          <Box sx={style}>
            <IconButton
              className="close-btn"
              onClick={() => handleCloseModal()}
            >
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>{SHARE_MODAL.share}</h2>
              </div>
              <form>
                <div className="form-wrapper">
                  <div className="form-group">
                    <InputWithChips setEmails={setEmailList} />
                    <RadioGroup
                      value={portal}
                      onChange={handleChange}
                      className="flex mt-2"
                    >
                      <FormControlLabel
                        value="connect"
                        control={<Radio />}
                        label="Connect"
                      />
                      <FormControlLabel
                        value="place"
                        control={<Radio />}
                        label="Place"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrapper-share-modal">
              <PTButton
                label="Share"
                handleOnClickEvent={() => handleSuccess({ emails: emailList, portal })}
                size="large"
                className={`${
                  emailList.length &&
                  shareMessage
                    ? portal === "connect"
                      ? "fill-orange"
                      : "fill-blue"
                    : "disable-share-btn"
                } btn mb-[24px]`}
                disabled={
                  !emailList.length ||
                  !shareMessage
                }
              />
            </div>
          </Box>
        </>
      </Modal>
    </div>
  );
}
