export function forYouDTO(response) {
  let forYouList = [];
  if (response && response.rows.length) {
    forYouList.count = response?.count || 0;
    forYouList.rows = response.rows.map((data) => {
      return {
        id: data?.id || 0,
        regionId: data?.regionId || 0,
        revenueRangeId: data?.revenueRangeId || 0,
        investmentRoundId: data?.investmentRoundId || 0,
        elevatorPitchAdmin: data?.elevatorPitchAdmin || '',
        companyLogo: data?.companyLogo || '',
        keyCustomer1: data?.keyCustomer1 || '-',
        keyCustomer2: data?.keyCustomer2 || '-',
        keyCustomer1Logo: data?.keyCustomer1Logo || '',
        keyCustomer2Logo: data?.keyCustomer2Logo || '',
        watchingCount: data?.watchingCount || '',
        publishedAt: data?.publishedAt || '',
        createdAt: data?.createdAt || '',
        proptechCompany: {
          id: data?.proptechCompany?.id || 0,
          name: data?.proptechCompany?.name || '',
        },
        region: {
          id: data?.region?.id || 0,
          name: data?.region?.name || '',
        },
        investmentRound: {
          id: data?.investmentRound?.id || 0,
          name: data?.investmentRound?.name || '',
        },
        revenueRange: {
          id: data?.revenueRange?.id || 0,
          name: data?.revenueRange?.name || '',
        },
        proptechAnalysisOverview: {
          id: data?.proptechAnalysisOverview?.id || 0,
          userExperience: data?.proptechAnalysisOverview?.userExperience || 0,
          dealClosing: data?.proptechAnalysisOverview?.dealClosing || 0,
          evolvingAssets: data?.proptechAnalysisOverview?.evolvingAssets || 0,
          decisionMaking: data?.proptechAnalysisOverview?.decisionMaking || 0,
          smarterBuilds: data?.proptechAnalysisOverview?.smarterBuilds || 0,
          connectivity: data?.proptechAnalysisOverview?.connectivity || 0,
          sector1: data?.proptechAnalysisOverview?.sector1 || 0,
          sector2: data?.proptechAnalysisOverview?.sector2 || 0,
          proptechAnalysisSector1: {
            id: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.id || 0,
            icon: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.icon || null,
            name: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.name || '',
          },
          proptechAnalysisSector2: {
            id: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.id || 0,
            icon: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.icon || null,
            name: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.name || '',
          }
        },
        vcProptechFavorite: data?.vcProptechFavorite.length ? data?.vcProptechFavorite : [],
        vcProptechNote: [
          {
            note: data?.vcProptechNote[0]?.note || '',
          }
        ],
        vcProptechForYou:
          [
            {
              id: data?.vcProptechForYou[0].id || 0,
              vcProptechForYouTag: [
                data?.vcProptechForYou[0].vcProptechForYouTag.map((forYouTag) => {
                  return {
                    id: forYouTag?.id || 0,
                    tag: {
                      id: forYouTag?.tag?.id || 0,
                      name: forYouTag?.tag?.name || '',
                    }
                  }
                })
              ]
            }
          ]
      }
    })
  }

  return forYouList;
}
