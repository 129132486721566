export function investorListDTO(data) {
  let investorList = [];
  if (data && data.length) {
    investorList = data.map((investor) => {
      return {
        id: investor?.id || 0,
        companyLogo: investor?.companyLogo || '',
        domain: investor?.domain || null,
        lastLoginAt: investor?.lastLoginAt || null,
        createdAt: investor?.createdAt || null,
        updatedAt: investor?.updatedAt || null,
        totalMeetingCount: investor?.totalMeetingCount || 0,
        cancelledMeetingCount: investor?.cancelledMeetingCount || 0,
        status: investor?.status !== null ? investor.status : 0,
        watchlistCount: investor?.watchlistCount !== null ? investor.watchlistCount : 0,
        isArchived: investor?.isArchived !== null ? investor.isArchived : false,
        planExpiryDate: investor?.planExpiryDate || '',
        vcPlanId: investor?.vcPlanId || '',
        vcPlanDetails: {
          freeNoOfMeetings: investor?.vcPlanDetails?.freeNoOfMeetings ? investor.vcPlanDetails.freeNoOfMeetings : 0,
          durationOfDays: investor?.vcPlanDetails?.durationOfDays ? investor.vcPlanDetails.durationOfDays : '',
          name: investor?.vcPlanDetails?.name ? investor.vcPlanDetails.name : '',
          price: investor?.vcPlanDetails?.price ? investor.vcPlanDetails.price : '',
        },
        vcCompany: investor?.vcCompany !== null ? {
          id: investor?.vcCompany?.id || 0,
          name: investor?.vcCompany?.name || null,
          vcUsers: investor?.vcCompany?.vcUsers?.length !== 0 ? investor?.vcCompany?.vcUsers.map(user => {
            return {
              id: user?.id || 0,
              name: user?.name || null,
              email: user?.email || null,
            }
          }) : [],
        } : null,
        vcUser: investor?.vcUser !== null ? {
          id: investor?.vcUser?.id || 0,
          primaryUserName: investor?.vcUser?.primaryUserName || null,
          email: investor?.vcUser?.email || null,
        } : null,
        country: investor?.country !== null ? {
          id: investor?.country?.id || 0,
          name: investor?.country?.name || null,
          regionId: investor?.country?.regionId || 0,
        } : null,
        region: investor?.region !== null ? {
          id: investor?.region?.id || 0,
          name: investor?.region?.name || null,
        } : null,
      }
    })
  }

  return investorList;
}

export function investorProfileTabDTO(data) {
  return {
    id: data?.id || 0,
    companyInfo: data?.companyInfo || null,
    companyLogo: data?.companyLogo || null,
    websiteURL: data?.websiteURL || null,
    countryId: data?.countryId || null,
    regionId: data?.regionId || null,
    investmentRoundId: data?.investmentRoundId || 1,
    revenueRange: data?.revenueRange || 0,
    investmentRange: data?.investmentRange || 0,
    metric1: data?.metric1 || null,
    metric1Text: data?.metric1Text || null,
    metric2: data?.metric2 || null,
    metric2Text: data?.metric2Text || null,
    metric3: data?.metric3 || null,
    metric3Text: data?.metric3Text || null,
    notes: data?.notes || null,
    isArchived: data?.isArchived !== null ? data.isArchived : false,
    status: data?.status !== null ? data.status : false,
    lastLoginAt: data?.lastLoginAt || null,
    watchlistCount: data?.watchlistCount || null,
    createdAt: data?.createdAt || null,
    updatedAt: data?.updatedAt || null,
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || '',
      regionId: data?.country?.regionId || null,
    },
    vcCompany: {
      id: data?.vcCompany?.id || 0,
      name: data?.vcCompany?.name || '',
    },
    region: {
      id: data?.region?.id || 0,
      name: data?.region?.name || '',
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || '',
    },
    keyBizModelMetric1: {
      id: data?.keyBizModelMetric1?.id || 0,
      name: data?.keyBizModelMetric1?.name || '',
    },
    keyBizModelMetric2: {
      id: data?.keyBizModelMetric2?.id || 0,
      name: data?.keyBizModelMetric2?.name || '',
    },
    keyBizModelMetric3: {
      id: data?.keyBizModelMetric3?.id || 0,
      name: data?.keyBizModelMetric3?.name || '',
    },
  }
}

export function investorProfileTabTagsListDTO(data) {
  let tags = [];
  if (data && data.length) {
    tags = data.map((datum) => {
      return {
        id: datum?.tag?.id || 0,
        name: datum?.tag?.name || '',
      }
    })
  }

  return tags;
}

export function investorProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || '',
        publishedAt: datum?.publishedAt || '',
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany ? {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        } : null,
        vcProptechForYou: datum?.vcProptechForYou?.length !== 0 ? datum?.vcProptechForYou.map(user => {
          return {
            id: user?.id || 0,
            status: user?.status !== null ? user.status : false,
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function investorForYouProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || '',
        publishedAt: datum?.publishedAt || '',
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany ? {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        } : null,
        vcProptechForYou: datum?.vcProptechForYou?.length !== 0 ? datum?.vcProptechForYou.map(user => {
          return {
            id: user?.id || 0,
            status: user?.status !== null ? user.status : false,
            vcProptechForYouTag: user?.vcProptechForYouTag?.length !== 0 ? user.vcProptechForYouTag.map(tag => {
              return {
                id: tag?.tag?.id || 0,
                name: tag?.tag?.name || '',
              }
            }) : [],
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function investorHomeProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || '',
        publishedAt: datum?.publishedAt || '',
        totalMeetingCount: datum?.totalMeetingCount || 0,
        proptechCompany: datum?.proptechCompany ? {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || "",
        } : null,
        vcProptechHome: datum?.vcProptechHome?.length !== 0 ? datum?.vcProptechHome.map(user => {
          return {
            id: user?.id || 0,
            status: user?.status !== null ? user.status : false,
            // vcProptechHomeTag: user?.vcProptechHomeTag?.length !== 0 ? user.vcProptechHomeTag.map(tag => {
            //   return {
            //     id: tag?.tag?.id || 0,
            //     name: tag?.tag?.name || '',
            //   }
            // }) : [],
          }
        }) : [],
      }
    })
  }

  return proptechs;
}

export function investorWatchlistProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        createdAt: datum?.createdAt || '',
        proptechCompanyDetails: datum?.proptechCompanyDetails ? {
          id: datum?.proptechCompanyDetails?.id || 0,
          companyLogo: datum?.proptechCompanyDetails?.companyLogo || "",
          elevatorPitchAdmin: datum?.proptechCompanyDetails?.elevatorPitchAdmin || "",
          founderPitchVideo: datum?.proptechCompanyDetails?.founderPitchVideo || "",
          publishedAt: datum?.proptechCompanyDetails?.publishedAt || "",
          country: {
            id: datum?.proptechCompanyDetails?.country?.id || 0,
            name: datum?.proptechCompanyDetails?.country?.name || '',
          },
          investmentRange: {
            id: datum?.proptechCompanyDetails?.investmentRange?.id || 0,
            name: datum?.proptechCompanyDetails?.investmentRange?.name || '',
          },
          investmentRound: {
            id: datum?.proptechCompanyDetails?.investmentRound?.id || 0,
            name: datum?.proptechCompanyDetails?.investmentRound?.name || '',
          },
          language: {
            id: datum?.proptechCompanyDetails?.language?.id || 0,
            name: datum?.proptechCompanyDetails?.language?.name || '',
          },
          proptechCompany: {
            id: datum?.proptechCompanyDetails?.proptechCompany?.id || 0,
            name: datum?.proptechCompanyDetails?.proptechCompany?.name || '',
          },
          revenueRange: {
            id: datum?.proptechCompanyDetails?.revenueRange?.id || 0,
            name: datum?.proptechCompanyDetails?.revenueRange?.name || '',
          },
        } : null,
      }
    })
  }

  return proptechs;
}

export function investorInteractionProptechsListDTO(data) {
  let proptechs = [];
  if (data && data.length) {
    proptechs = data.map((datum) => {
      return {
        id: datum?.id || 0,
        companyLogo: datum?.companyLogo || "",
        elevatorPitchAdmin: datum?.elevatorPitchAdmin || "",
        founderPitchVideo: datum?.founderPitchVideo || "",
        meetingCount: datum?.meetingCount || 0,
        createdAt: datum?.createdAt || '',
        publishedAt: datum?.publishedAt || "",
        updatedAt: datum?.updatedAt || "",
        archivedAt: datum?.archivedAt || "",
        lastMeetingTime: datum?.lastMeetingTime || "",
        proptechCompany: {
          id: datum?.proptechCompany?.id || 0,
          name: datum?.proptechCompany?.name || '',
        },
        country: {
          id: datum?.country?.id || 0,
          name: datum?.country?.name || '',
        },
        investmentRound: {
          id: datum?.investmentRound?.id || 0,
          name: datum?.investmentRound?.name || '',
        },
        language: {
          id: datum?.language?.id || 0,
          name: datum?.language?.name || '',
        },
        revenueRange: {
          id: datum?.revenueRange?.id || 0,
          name: datum?.revenueRange?.name || '',
        },
      }
    })
  }

  return proptechs;
}