import { VC_MEETINGCOUNTS_API } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { VC_API } from "../../../../utils/utility/API-call";
import { handleError } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { navTabsVCDTO } from "../../dtos/navigationTabsDTO";

export const getMeetingCounts = () => {
    VC_API().get(VC_MEETINGCOUNTS_API)
      .then((res) => {
        if (res.data.data) {
          dispatch(Actions.VCUser.SetNavTabs, navTabsVCDTO(res?.data?.data?.proptechTabsAssigned))
          dispatch(Actions.VCUser.SetActivePlan, res?.data?.data?.vcPlanDetails)
          dispatch(Actions.VCUser.SetCountDetails, res?.data?.data)
        }
        if (res?.data?.code !== 200) {
          handleError(res?.data);
        }
      }).catch((err) => {
        handleError(err);
      });
  }