import React from 'react'
import { useSelector } from 'react-redux';
import { Navigation, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedCard from '../FeaturedCard'

import "swiper/css";
import "swiper/css/navigation";
import "./Featured.scss";

//Featured block component for vc portal dashboard in top
export default function Featured() {
  const { vcFeaturedList } = useSelector(state => state.vcProptechs);

  return (
    <div>
      <Swiper
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        slidesPerView={1}
        spaceBetween={10}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className="featured-carousel"
        breakpoints={{
          "575": {
            slidesPerView: 'auto',
            spaceBetween: 23
          },
        }}
      >
        {
          vcFeaturedList && vcFeaturedList?.rows?.length ?
            vcFeaturedList?.rows.map((feature, index) => {
              return (
                <SwiperSlide>
                  <FeaturedCard key={index} proptech={feature} />
                </SwiperSlide>
              )
            })
            : null
        }
        {/* left gradient on carousel */}
        {/* <div className='swiper-gradient left' /> */}
        {/* right gradient on carousel */}
        {/* <div className='swiper-gradient right' /> */}
      </Swiper>
    </div>
  )
}
