export default function vcCompanyProfile(
  initialState = {
    companyProfileData: null,
    keyBizModelsData: null,
    isLoading: false
  },
  action
) {
  switch (action.type) {
    case 'VC_COMPANYPROFILE_SET':
      return {
        ...initialState,
        companyProfileData: action.payload,
      };

    case 'VC_COMPANYPROFILE_SETLOADING':
      return {
        ...initialState,
        isLoading: action.payload
      };

    case 'VC_KEYBIZMODELS_SET':
      return {
        ...initialState,
        keyBizModelsData: action.payload,
      };

    case 'VC_COMPANYPROFILE_RESET':
      return {
        ...initialState,
        companyProfileData: null,
        keyBizModelsData: null,
        isLoading: false
      };

    default:
      return initialState;
  }
}
