export const cloneDeepArray = (arr) => {
  return arr?.map((item) => {
    if (Array.isArray(item)) {
      return cloneDeepArray(item);
    } else if (typeof item === "object" && item !== null) {
      return cloneDeepObject(item);
    } else {
      return item;
    }
  });
};

export const cloneDeepObject = (obj) => {
  const clone = {};

  for (let key in obj) {
    const value = obj[key];

    if (Array.isArray(value)) {
      clone[key] = cloneDeepArray(value);
    } else if (typeof value === "object" && value !== null) {
      clone[key] = cloneDeepObject(value);
    } else {
      clone[key] = value;
    }
  }

  return clone;
};

//compare two array of object return true if not match
export const compareArraysOfObjects = (arr1, arr2) => {
  return (
    arr1?.length !== arr2?.length ||
    arr1?.some((obj, index) => !compareObjects(obj, arr2[index]))
  );
};

function compareObjects(obj1, obj2) {
  const keys1 = Object?.keys(obj1);
  const keys2 = Object?.keys(obj2);

  return (
    keys1?.length === keys2?.length &&
    keys1?.every((key) => {
      const value1 = obj1[key];
      const value2 = obj2[key];

      return typeof value1 !== "object" || typeof value2 !== "object"
        ? value1 === value2
        : compareObjects(value1, value2);
    })
  );
}
