import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { getInvestorProptechs, updateInvestorForYouProptechs } from '../../../utils/middleware/investorForYouTab';
import Proptechcard from './ProptechCard';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { dispatch } from '../../../../utils/utility/store';
import { Actions } from '../../../../store/actions';
import { setActivetabData } from '../../../utils/middleware/adminProptechTab';

export default function ForYouTab() {
  // Store
  const { selectedInvestor, searchProptech } = useSelector(state => state.adminInvestor);
  const { proptechs, forYouProptech } = useSelector(state => state.adminInvestor);
  const adminDropdowns = useSelector(state => state.adminDropdowns);
  const adminAnalysisTab = useSelector(state => state.adminAnalysisTab)

  // State
  const [searchText, setSearchText] = useState(searchProptech)

  const handleCheckboxSelection = (e, id) => {
    updateInvestorForYouProptechs(selectedInvestor?.id, {
      proptechCompanyDetailsId: id,
      status: e.target.checked
    }, searchProptech, adminDropdowns)
  }

  const handleSearch = (e) => {
    let text = e.target.value ? e.target.value.trim() : ''
    setSearchText(e.target.value)
    if (e.key === 'Enter') {
      setSearchText(text)
      getInvestorProptechs(selectedInvestor?.id, text)
      dispatch(Actions.AdminInvestor.SetSearchProptech, text)
    }
  }

  const handleCloseSearch = () => {
    dispatch(Actions.AdminInvestor.SetSearchProptech, '')
    getInvestorProptechs(selectedInvestor?.id)
    setSearchText('')
  }

  const handleCardClick = (id) => {
    dispatch(Actions.Admin.SetActiveAdminTab, 0)
    dispatch(Actions.Admin.SetIsRedirect, true)
    dispatch(Actions.AdminUser.SetIsSelectedProptech, true)
    dispatch(Actions.AdminUser.SetSelectedProptech, { id })
    setActivetabData(2, id, adminAnalysisTab, adminDropdowns)
  }

  return (
    <div className='for-you-tab'>
      <PTInput label="Search" className='w-1/2' value={searchText} onKeyPress={handleSearch} handleOnChangeEvent={handleSearch} variant="standard" placeholder='' icon={<CloseIcon className='cursor-pointer' onClick={handleCloseSearch} />} />
      <div className='main-container'>
        <div className='left-column'>
          <div className='heading'>All</div>
          {!!proptechs?.length && proptechs.map((proptech, index) => {
            return <Proptechcard logo={proptech.companyLogo} key={index} onCardClick={() => handleCardClick(proptech?.id)} title={proptech.proptechCompany.name} publishedAt={proptech.publishedAt} checked={!!proptech.vcProptechForYou?.length} onChecked={(e) => handleCheckboxSelection(e, proptech.id)} meetings={proptech?.totalMeetingCount} />
          })}
        </div>
        <div className='right-column'>
          <div className='heading'>Selected</div>
          {!!forYouProptech?.length && forYouProptech.map((proptech, index) => {
            return <Proptechcard investorId={selectedInvestor?.id} key={index} onCardClick={() => handleCardClick(proptech?.id)} proptechId={proptech?.id} logo={proptech.companyLogo} title={proptech.proptechCompany.name} publishedAt={proptech.publishedAt} checked={!!proptech.vcProptechForYou?.length} onChecked={(e) => handleCheckboxSelection(e, proptech.id)} editTags selectedTags={proptech.vcProptechForYou[0].vcProptechForYouTag} meetings={proptech?.totalMeetingCount} />
          })}
        </div>
      </div>
    </div>
  )
}
