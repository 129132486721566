import {
  ADMIN_ALIGNMENTS_API,
  ADMIN_ASSET_LIFE_CYCLES_API,
  ADMIN_CHARGING_MODELS_API,
  ADMIN_COUNTRY_API,
  ADMIN_DEFENSIBILITY_TIMINGS_API,
  ADMIN_GROSS_MARGINS_API,
  ADMIN_GROWTH_API,
  ADMIN_GROWTH_BLOCKERS_API,
  ADMIN_INVESTMENT_RANGES_API,
  ADMIN_INVESTMENT_ROUNDS_API,
  ADMIN_INVESTMENT_STAGES_API,
  ADMIN_INVESTMENT_TYPES_API,
  ADMIN_INVESTOR_STYLE_API,
  ADMIN_INVESTOR_TRAITS_API,
  ADMIN_KEY_BIZ_MODELS_API,
  ADMIN_KEY_HIRE_MAPPINGS_API,
  ADMIN_LANGUAGES_API,
  ADMIN_MEGA_TRENDS_API,
  ADMIN_NEED_TO_KNOW_API,
  ADMIN_NO_OF_COUNTRIES_API,
  ADMIN_NO_OF_CUSTOMERS_API,
  ADMIN_NO_OF_EMPLOYEES_API,
  ADMIN_NO_OF_ROUNDS_API,
  ADMIN_PREDICTIONS_API,
  ADMIN_PROJECT_KEY_USERS,
  ADMIN_PROJECT_RISKS_API,
  ADMIN_REGIONS_API,
  ADMIN_REVENUE_RANGE_API,
  ADMIN_SECTORS_API,
  ADMIN_TAGS_API,
  ADMIN_TEAM_SKILLS_API,
  ADMIN_TECH_ALIGNMENT_API,
  ADMIN_TECH_BUYERS_API,
  ADMIN_TECH_BUYER_ASSET_TYPE,
  ADMIN_TECH_BUYER_INTEGRATIONS,
  ADMIN_TECH_BUYER_KEY_ISSUES,
  ADMIN_TECH_BUYER_KEY_PAIN_POINTS,
  ADMIN_TECH_BUYER_KEY_RISKS,
  ADMIN_TECH_CONTACT_POINT_VENDORS,
  ADMIN_TECH_OFFERINGS_API,
  ADMIN_TECH_PAST_PROJECT_RANGE_API,
  ADMIN_TECH_PROJECT_SCALES_API,
  ADMIN_TRENDS_API,
  ADMIN_USE_CASES_API,
  ADMIN_VALUATION_RANGES_API,
  ADMIN_VALUE_KPIS_API,
  ADMIN_RFF_API,
} from "../../../constants/APIs";
import { Actions } from "../../../store/actions";
import { ADMIN_API } from "../../../utils/utility/API-call";
import { dispatch } from "../../../utils/utility/store";

export const getCountriesData = () => {
  ADMIN_API()
    .get(ADMIN_COUNTRY_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetCountries, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getRevenueRangesData = () => {
  ADMIN_API()
    .get(ADMIN_REVENUE_RANGE_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetRevenueranges, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getMegaTrendsData = () => {
  ADMIN_API()
    .get(ADMIN_MEGA_TRENDS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetMegatrends, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getLanguagesData = () => {
  ADMIN_API()
    .get(ADMIN_LANGUAGES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetLanguages, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTechBuyersData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_BUYERS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTechbuyers, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getRFFsData = () => {
  ADMIN_API()
    .get(ADMIN_RFF_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetRFFs, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getChargingModelsData = () => {
  ADMIN_API()
    .get(ADMIN_CHARGING_MODELS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetChargingmodels, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getValueKPIsData = () => {
  ADMIN_API()
    .get(ADMIN_VALUE_KPIS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetValuekpis, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getSectorsData = () => {
  ADMIN_API()
    .get(ADMIN_SECTORS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetSectors, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getUsecasesData = () => {
  ADMIN_API()
    .get(ADMIN_USE_CASES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetUsecases, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTechOfferingsData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_OFFERINGS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTechofferings, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getNoOfCustomersData = () => {
  ADMIN_API()
    .get(ADMIN_NO_OF_CUSTOMERS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetNoOfcustomers, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getNoOfCountriesData = () => {
  ADMIN_API()
    .get(ADMIN_NO_OF_COUNTRIES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetNoOfcountries, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getGrossMarginData = () => {
  ADMIN_API()
    .get(ADMIN_GROSS_MARGINS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetGrossmargins, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getGrowthBlockersData = () => {
  ADMIN_API()
    .get(ADMIN_GROWTH_BLOCKERS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetGrowthblockers, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getInvestmentRoundsData = () => {
  ADMIN_API()
    .get(ADMIN_INVESTMENT_ROUNDS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetInvestmentRounds, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getInvestmentRangesData = () => {
  ADMIN_API()
    .get(ADMIN_INVESTMENT_RANGES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetInvestmentRanges, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getInvestmentTypesData = () => {
  ADMIN_API()
    .get(ADMIN_INVESTMENT_TYPES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetInvestmentTypes, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getValuationRangesData = () => {
  ADMIN_API()
    .get(ADMIN_VALUATION_RANGES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetValuationRanges, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getNoOfRoundsData = () => {
  ADMIN_API()
    .get(ADMIN_NO_OF_ROUNDS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetNoOfRounds, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getAlignmentsData = () => {
  ADMIN_API()
    .get(ADMIN_ALIGNMENTS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetAlignments, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getNoOfEmployeesData = () => {
  ADMIN_API()
    .get(ADMIN_NO_OF_EMPLOYEES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetNoOfEmployees, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTeamSkillsData = () => {
  ADMIN_API()
    .get(ADMIN_TEAM_SKILLS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTeamSkills, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getKeyHireMappingsData = () => {
  ADMIN_API()
    .get(ADMIN_KEY_HIRE_MAPPINGS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetKeyHireMappings, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getKeyBizModelsData = () => {
  ADMIN_API()
    .get(ADMIN_KEY_BIZ_MODELS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetKeyBizModels, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTrendsData = () => {
  ADMIN_API()
    .get(ADMIN_TRENDS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTrends, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getPredictionsData = () => {
  ADMIN_API()
    .get(ADMIN_PREDICTIONS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetPredictions, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getGrowthData = () => {
  ADMIN_API()
    .get(ADMIN_GROWTH_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetGrowth, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getNeedToKnowData = () => {
  ADMIN_API()
    .get(ADMIN_NEED_TO_KNOW_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetNeedToKnow, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getInvestorStyleData = () => {
  ADMIN_API()
    .get(ADMIN_INVESTOR_STYLE_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetInvestorStyle, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getInvestmentStagesData = () => {
  ADMIN_API()
    .get(ADMIN_INVESTMENT_STAGES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetInvestmentStages, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getInvestorTraitsData = () => {
  ADMIN_API()
    .get(ADMIN_INVESTOR_TRAITS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetInvestorTraits, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getAssetLifeCyclesData = () => {
  ADMIN_API()
    .get(ADMIN_ASSET_LIFE_CYCLES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetAssetLifeCycles, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getDefensibilityTimingsData = () => {
  ADMIN_API()
    .get(ADMIN_DEFENSIBILITY_TIMINGS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetDefensibilityTimings, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTagsData = () => {
  ADMIN_API()
    .get(ADMIN_TAGS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTags, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getKeyPainPointsData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_BUYER_KEY_PAIN_POINTS)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetKeyPainPoints, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getAssetTypesData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_BUYER_ASSET_TYPE)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetAssetTypes, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getKeyIssuesData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_BUYER_KEY_ISSUES)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetKeyIssues, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getKeyRisksData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_BUYER_KEY_RISKS)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetKeyRisks, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getIntegrationsData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_BUYER_INTEGRATIONS)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetIntegrations, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getRegionsData = () => {
  ADMIN_API()
    .get(ADMIN_REGIONS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetRegions, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getProjectRisksData = () => {
  ADMIN_API()
    .get(ADMIN_PROJECT_RISKS_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetProjectRisks, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTechAlignmentData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_ALIGNMENT_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTechAlignment, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTechContactPointVendorsData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_CONTACT_POINT_VENDORS)
    .then((res) => {
      if (res.data.data) {
        dispatch(
          Actions.AdminDropdowns.SetTechContactPointVendors,
          res.data.data
        );
      }
    })
    .catch((error) => console.log(error));
};

export const getTechProjectRangeData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_PAST_PROJECT_RANGE_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTechPastProjectRange, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getTechProjectScalesData = () => {
  ADMIN_API()
    .get(ADMIN_TECH_PROJECT_SCALES_API)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetTechProjectScales, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getProjectKeyUsersData = () => {
  ADMIN_API()
    .get(ADMIN_PROJECT_KEY_USERS)
    .then((res) => {
      if (res.data.data) {
        dispatch(Actions.AdminDropdowns.SetProjectKeyUsers, res.data.data);
      }
    })
    .catch((error) => console.log(error));
};
