import moment from 'moment';
import React, { useState } from 'react';
import FeedProfile from '../../../assets/Images/Connect_by_the_PTC___Profile_logo.jpg';

export default function NewsfeedCard(props) {
  // Props
  const { title, createdAt, description, url, image } = props

  // State
  const [readMore, setReadMore] = useState(true)

  return (
    <div className="news-feed-outer">
      <div className="news-feed-inner cursor-pointer px-6 pt-5 pb-[25px] no-underline shadow-[0px_2px_5px_rgba(0,0,0,0.1)] rounded-2xl block" onClick={() => url && window.open(url.match(/^https?:/) ? url : '//' + url)}>
        <div className="flex items-center">
          <div className="w-8 h-8">
            <img src={FeedProfile} alt="Feed Profile" className="w-full h-full rounded-full object-cover" />
          </div>
          <div className="pl-4 w-[calc(100%-32px)]">
            <span className="text-xs font-semibold text-[#111D43] block leading-tight mb-1">{title}</span>
            <p className="text-[8px] text-[rgba(17,29,67,0.6)]">{moment(createdAt).format('HH:mm a, DD MMM YYYY')}</p>
          </div>
        </div>
        <div className={`text-xs leading-relaxed text-[#111D43] mt-3.5 break-words ${description?.length > 100 && readMore && 'line-clamp-2'}`}>
          {description}
        </div>
        {description?.length > 100 && <div className='w-full text-right font-semibold text-[#0000FF]/[0.4] hover:cursor-pointer hover:text-[#0000FF] hover:underline text-[14px]' onClick={(e) => {
          e.stopPropagation()
          setReadMore(!readMore)
        }}>
          {readMore ? 'read more' : 'read less'}
        </div>}
        <div className="pb-[61.55%] relative rounded-2xl mt-5">
          <img src={image} alt="Feed Detail" className="w-full h-full object-cover absolute inset-0 rounded-2xl" />
        </div>
      </div>
    </div >
  )
}
