import React from 'react';
import ReactPlayer from 'react-player';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { INVESTOR_INTERACTIONS_TAB_LABELS } from '../../constants';

export default function PlayVideo(props) {
  const { videoURL, closeIconClick } = props;

  return (
    <div className='bg-black bg-opacity-50  box-border h-full p-5 flex flex-col justify-center items-center fixed top-0 left-0 w-full z-[100] preview-screen'>
      <div className='bg-grey rounded-xl flex flex-col h-1/2 relative w-3/4 overflow-hidden'>
        <div className='absolute left-0 top-0 z-20'>
          <IconButton aria-label="delete" size="small">
            <HighlightOffIcon fontSize="large" onClick={closeIconClick} />
          </IconButton>
        </div>
        <div className='w-full h-full flex flex-col justify-center items-center proptech-detail-wrapper overflow-y-auto md:overflow-inherit'>
          {videoURL ? <ReactPlayer controls url={videoURL} width="100%" height="100%" />
            : <h1>{INVESTOR_INTERACTIONS_TAB_LABELS.videoNotAvailable}</h1>}
        </div>
      </div>
    </div>
  )
}
