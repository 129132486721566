import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PTButton from '../../../../components/GenericComponents/PTButton'
import { ADMIN_TECH_BUYERS, VC_PLANS_API } from '../../../../constants/APIs'
import { DateFormat } from '../../../../constants/General'
import { Actions } from '../../../../store/actions'
import { ADMIN_API } from '../../../../utils/utility/API-call'
import { handleError, handleSuccess } from '../../../../utils/utility/snackbar'
import { dispatch } from '../../../../utils/utility/store'
import { formatTime } from '../../../../utils/utility/utility'
import ChangePlan from '../../../../place/components/ChangePlan'
import { getTechBuyers } from '../../../utils/middleware/adminTechBuyer'

export default function PlanTab() {
  // Store
  const { isLoading, selectedTechBuyer, activeSort, sortOrder, isArchived, search } = useSelector(state => state.adminTechBuyer);
  const isRedirect = useSelector(state => state.admin.isRedirect);

  // State
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false)
  const [plans, setPlans] = useState([])

  useEffect(() => {
    if (openChangePlanModal) {
      getPlanDetails();
    }
  }, [openChangePlanModal]);

  const getPlanDetails = () => {
    ADMIN_API()
      .get(VC_PLANS_API)
      .then((res) => {
        setPlans(res.data.data);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const changeVCPlan = (selectedPlan) => {
    dispatch(Actions.AdminTechBuyer.SetLoading, true)
    ADMIN_API().post(`${ADMIN_TECH_BUYERS}/activatePlan/${selectedTechBuyer?.id}`, { planId: selectedPlan })
      .then((res) => {
        getTechBuyers(isArchived, activeSort, sortOrder, search, !isRedirect)
        dispatch(Actions.AdminTechBuyer.SetLoading, false)
        handleSuccess(res);
      })
      .catch((err) => {
        dispatch(Actions.AdminTechBuyer.SetLoading, false)
        handleError(err);
      });
  }

  return (
    <>
      <div className='px-5 pt-5 overflow-y-auto'>
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className='w-full flex justify-center items-center'>
              <div className='w-1/4'>Plan name</div>
              <div className='w-3/4'>{selectedTechBuyer.tbPlanDetails.name || '-'}</div>
            </div>
          </div>
        </div>
        <div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className='w-full flex justify-center items-center'>
              <div className='w-1/4'>Expiry</div>
              <div className='w-3/4'>{selectedTechBuyer.planExpiryDate ? formatTime(selectedTechBuyer.planExpiryDate, DateFormat.DayMonthFormat) : '-'}</div>
            </div>
          </div>
        </div><div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className='w-full flex justify-center items-center'>
              <div className='w-1/4'>Free meetings</div>
              <div className='w-3/4'>{selectedTechBuyer.tbPlanDetails.freeNoOfMeetings || '-'}</div>
            </div>
          </div>
        </div><div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className='w-full flex justify-center items-center'>
              <div className='w-1/4'>Plan duration</div>
              <div className='w-3/4'>{selectedTechBuyer.tbPlanDetails.durationOfDays ? `${selectedTechBuyer.tbPlanDetails.durationOfDays} Days` : '-'}</div>
            </div>
          </div>
        </div><div className={`proptech-card-border`}>
          <div className={`proptech-tab-heading`}>
            <div className='w-full flex justify-center items-center'>
              <div className='w-1/4'>Price</div>
              <div className='w-3/4'>{selectedTechBuyer.tbPlanDetails.price ? `$ ${selectedTechBuyer.tbPlanDetails.price}` : '-'}</div>
            </div>
          </div>
        </div>

        <div className='w-full flex justify-end bottom-btn-wrapper'>
          <PTButton
            label={<span className="text-[10px] font-semibold leading-3 text-center not-italic uppercase tracking-wider !text-[#FFFFFF]">Change plan</span>}
            className='w-[142px] h-[44px] !rounded-xl !border-solid !bg-[#111D43]'
            handleOnClickEvent={() => setOpenChangePlanModal(true)}
          />
        </div>
      </div>

      {openChangePlanModal && <ChangePlan
        openChangePlanModal={openChangePlanModal}
        closeChangePlanModal={() => {
          setPlans([])
          setOpenChangePlanModal(false)
        }}
        currentPlan={selectedTechBuyer?.tbPlanId}
        plans={plans}
        loading={isLoading}
        handleSuccess={changeVCPlan}
      />}
    </>
  )
}
