import React, { useEffect, useState } from 'react';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CircularProgress, Link, TextField, Tooltip } from '@mui/material';
import ProptechNavbar from '../../components/ProptechNavbar';
import { PROPTECH_API } from '../../../utils/utility/API-call';
import { PROPTECH_ACTIVE_PAYMENT_METHOD_API, PROPTECH_CHANGE_PASSWORD_API, PROPTECH_MEETINGS_API, PROPTECH_METRICS_API, PROPTECH_NEWSFEED, PROPTECH_PLANS_API, PROPTECH_UPDATE_PAYMENT_METHOD_API, PROPTECH_UPGRADE_PLAN_API, PROPTECH_USERS_API } from '../../../constants/APIs';
import { newsfeedListDTO } from '../../utils/dtos/newsfeed';
import { handleError, handleSuccess } from '../../../utils/utility/snackbar';
import NewsfeedCard from '../../components/NewsfeedCard';
import Loader from '../../../components/Loading';
import ChangePassword from '../../../components/ChangePassword';
import ManageUsers from '../../components/ManageUsers';
import { userListDTO } from '../../utils/dtos/users';
import { getProptechLocalStorageUserData, setProptechLocalStorageUserData } from '../../../utils/session';
import { MANAGEUSERS_MODAL_HEADER, planTexts, Previous_Day_Style, Upcoming_Day_Style } from '../../constants/General';
import { setBadgeVisibility } from '../../utils/middleware/notifications';
import RescheduleMeeting from '../../components/RescheduleMeeting';
import CancelMeetingModal from '../../components/CancelMeetingModal';
import { previousMeetingsListDTO, upcomingMeetingsListDTO } from '../../utils/dtos/meetings';
import { metricsDTO } from '../../utils/dtos/metrics';
import UpcomingMeeting from '../../components/UpcomingMeeting';
import PreviousMeetings from '../../components/PreviousMeetings';
import PreviewProfile from '../../components/PreviewProfile';
import ChangePlanModal from '../../components/ChangePlanModal';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../utils/utility/store';
import { Actions } from '../../../store/actions';
import PTButton from '../../../components/GenericComponents/PTButton';
import PaymentDetails from '../../components/PaymentDetails';
import './dashboard.scss';
import "swiper/css";
import "swiper/css/navigation";
import { meetingStatus, TooltipText } from '../../../constants/General';

export default function Dashboard() {
  // State
  const [openModal, setOpenModal] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openManageUsersModal, setOpenManageUsersModal] = useState(false);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [rescheduleTime, setRescheduleTime] = useState(new Date().getTime());
  const [selectedMeetingId, setSelectedMeetingId] = useState(1);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  // Change Plan
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [termAndConditions, setTermAndConditions] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [newsfeeds, setNewsfeds] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [previousMeetings, setPreviousMeetings] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openChangePaymentMethodModal, setOpenChangePaymentMethodModal] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiry: '',
    cvc: '',
    name: ''
  })
  const [activePaymentMethodLoading, setActivePaymentMethodLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [activePaymentData, setActivePaymentData] = useState({});
  const [userData, setUserData] = useState({})
  const [isPlanExpired, setIsPlanExpired] = useState(getProptechLocalStorageUserData()?.isPlanExpired)

  // Store
  const plans = useSelector(state => state.proptechUser.plans)

  // Get proptech user data from local storage
  useEffect(() => {
    setUserData(getProptechLocalStorageUserData())
  }, [])

  const getNewsfeeeds = () => {
    setIsLoading(true)
    PROPTECH_API().get(PROPTECH_NEWSFEED).then(res => {
      handleSuccess(res)
      setNewsfeds(newsfeedListDTO(res.data.data.rows))
      setIsLoading(false)
    }).catch((error) => {
      setIsLoading(false)
      handleError(error)
    })
  }

  const getMetricsData = () => {
    setIsLoading(true)
    PROPTECH_API().get(PROPTECH_METRICS_API).then(res => {
      handleSuccess(res)
      setMetrics(metricsDTO(res.data.data))
      setIsLoading(false)
    }).catch((error) => {
      setIsLoading(false)
      handleError(error);
    });
  }

  // Get news feed, meeting data and metric data from API
  useEffect(() => {
    getNewsfeeeds()
    getUpcomingMeetings()
    getPreviousMeetings()
    setBadgeVisibility()  // check unread notification
    getMetricsData();   // get metrics data
    setUserData(getProptechLocalStorageUserData())
  }, [])


  // Get Upcoming Meetings
  const getUpcomingMeetings = () => {
    setIsLoading(true);
    setLoadingCalendar(true);
    PROPTECH_API().get(`${PROPTECH_MEETINGS_API}/upcoming`)
      .then(res => {
        handleSuccess(res)
        setUpcomingMeetings(upcomingMeetingsListDTO(res.data.data.rows))
        setIsLoading(false);
        setLoadingCalendar(false);
      }).catch((error) => {
        setIsLoading(false);
        setLoadingCalendar(false);
        handleError(error);
      })
  }

  // Get Previous Meetings
  const getPreviousMeetings = () => {
    setIsLoading(true);
    setLoadingCalendar(true);
    PROPTECH_API().get(`${PROPTECH_MEETINGS_API}/previous`)
      .then(res => {
        handleSuccess(res)
        setPreviousMeetings(previousMeetingsListDTO(res.data.data.rows))
        setIsLoading(false);
        setLoadingCalendar(false);
      }).catch((error) => {
        setIsLoading(false);
        setLoadingCalendar(false);
        handleError(error);
      })
  }

  const changePassword = (payload, resetForm) => {
    setIsLoading(true)
    PROPTECH_API().put(PROPTECH_CHANGE_PASSWORD_API, payload)
      .then(res => {
        setIsLoading(false);
        resetForm({ values: '' });
        handleSuccess(res)
        setOpenModal(false)
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error)
      })
  }

  // fetch list of users
  const getUsers = () => {
    setIsLoading(true);
    PROPTECH_API().get(PROPTECH_USERS_API).then(res => {
      handleSuccess(res)
      setUsers(userListDTO(res.data.data));
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      handleError(error);
    });
  }

  // update status of user
  const handleUpdateStatus = (id, status) => {
    setIsLoading(true);
    PROPTECH_API().put(`${PROPTECH_USERS_API}/${id}/status`, {
      status
    }).then(res => {
      setUsers((users) => {
        const updatedUsers = users.map((u) => {
          if (u.id === id) {
            u.status = status;
          }
          return u;
        });
        return updatedUsers;
      })
      handleSuccess(res)
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      handleError(error);
    })
  };

  // Request for reschedule meeting api call
  const rescheduleMeetingRequest = (requestedRescheduleTime) => {
    setIsLoading(true);
    PROPTECH_API().put(`${PROPTECH_MEETINGS_API}/${selectedMeetingId}/reschedule`, {
      requestedRescheduleTime
    }).then(res => {
      handleSuccess(res)
      getUpcomingMeetings()
      getPreviousMeetings()
      handleCloseRescheduleMeetingModal()
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleCloseRescheduleMeetingModal = () => {
    setOpenRescheduleModal(false);
    setRescheduleTime(new Date().getTime())
  }

  // Request for cancel meeting api call
  const cancelMeeting = (cancelReason) => {
    setIsLoading(true);
    PROPTECH_API().put(`${PROPTECH_MEETINGS_API}/${selectedMeetingId}/cancel`,
      cancelReason ? { cancelReason } : {}
    ).then(res => {
      handleSuccess(res)
      getUpcomingMeetings()
      getPreviousMeetings()
      handleCloseCancelMeetingModal()
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleCloseCancelMeetingModal = () => {
    setOpenCancelModal(false);
  }

  const isToday = (day) => {
    return moment(new Date()).startOf('day').toISOString() === moment(day).startOf('day').toISOString()
  }

  useEffect(() => {
    if (openChangePlanModal) {
      getPlans()
    }

    if (openChangePaymentMethodModal) {
      getActivePaymentdata()
    }
  }, [openChangePlanModal, openChangePaymentMethodModal])

  const getPlans = () => {
    PROPTECH_API().get(PROPTECH_PLANS_API).then(res => {
      handleSuccess(res)
      setSelectedPlan(res.data.data.activePlan ? res.data.data.activePlan - 1 : res.data.data.activePlan)
      dispatch(Actions.ProptechUser.SetPlans, res.data.data.plans)
    }).catch((error) => {
      handleError(error)
    })
  }

  // API call for upgrade plan 
  const changePlan = () => {
    setIsLoading(true);
    PROPTECH_API().post(PROPTECH_UPGRADE_PLAN_API,
      { planId: plans[selectedPlan]?.id }
    ).then(res => {
      handleSuccess(res)
      setIsPlanExpired(false)
      setProptechLocalStorageUserData({ ...getProptechLocalStorageUserData(), isPlanExpired: false })
      setProptechLocalStorageUserData({ ...getProptechLocalStorageUserData(), planName: plans[selectedPlan]?.name })
      closeChangePlanModal()
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      handleError(error);
    })
  }

  const closeChangePlanModal = () => {
    setOpenChangePlanModal(false)
    setSelectedPlan(null)
    setTermAndConditions(false)
    dispatch(Actions.ProptechUser.SetPlans, [])
  }

  const closeChangePaymentMethodModal = () => {
    setOpenChangePaymentMethodModal(false)
    setCardDetails({
      cardNumber: '',
      expiry: '',
      cvc: '',
      name: ''
    })
  }

  // API call for updating payment method
  const changePaymentMethod = () => {
    setIsLoading(true);
    PROPTECH_API().post(PROPTECH_UPDATE_PAYMENT_METHOD_API, {
      cardHolderName: cardDetails?.name,
      cardNumber: cardDetails?.cardNumber,
      expiryMonth: new Date(cardDetails.expiry).getMonth() + 1,
      expiryYear: new Date(cardDetails.expiry).getFullYear(),
      cvc: cardDetails?.cvc
    }).then(res => {
      handleSuccess(res)
      closeChangePaymentMethodModal()
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      handleError(error);
    })
  }

  const getActivePaymentdata = () => {
    PROPTECH_API().get(PROPTECH_ACTIVE_PAYMENT_METHOD_API).then(res => {
      handleSuccess(res)
      setActivePaymentData(res.data.data)
      setActivePaymentMethodLoading(false)
    }).catch((error) => {
      setActivePaymentMethodLoading(false)
      handleError(error);
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-screen h-screen'>
        <ProptechNavbar setOpenManageUsersModal={setOpenManageUsersModal} setOpenModal={setOpenModal} setOpenProfile={setOpenProfile} setOpenChangePlanModal={setOpenChangePlanModal} setOpenChangePaymentMethodModal={setOpenChangePaymentMethodModal} setActivePaymentMethodLoading={setActivePaymentMethodLoading} />
        <div className='w-full bg-[#E5E5E5] box-border lg:pt-[120px] sm:pt-[82px] pt-[70px]'>
          <div className="px-9 py-12 min-h-[calc(100vh-120px)] overflow-y-auto proptech-dashboard-page">
            <div className='flex flex-wrap proptech-dashboard-wrapper min-h-[calc(100vh-260px)]'>
              <div className='dashboard-left-block px-3'>
                <div className="dashboard-outer flex flex-wrap">
                  {/* Meeting calender */}
                  <div className="dashboard-inner px-3 pb-8">
                    <div className={`${isPlanExpired && 'blur'} dashboard-widget py-8 px-9 rounded-[32px] h-full calender-block`}>
                      {loadingCalendar ? <div className='w-full h-full flex justify-center items-center'>
                        <CircularProgress />
                      </div> : <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                          displayStaticWrapperAs="desktop"
                          renderLoading={() => <span data-mui-test="loading-progress">...</span>}
                          onChange={() => { }}
                          renderInput={(params) => <TextField {...params} />}
                          renderDay={(day, selectedDays, pickersDayProps) => {
                            let previousDay = false
                            let upcomingDay = false
                            const upcomingMeets = upcomingMeetings.filter(item => moment(item.startTime).startOf('day').toISOString() === moment(day).startOf('day').toISOString());
                            const previousMeets = previousMeetings.filter(item => moment(item.startTime).startOf('day').toISOString() === moment(day).startOf('day').toISOString() && item.status !== meetingStatus.cancelled);

                            if (!isToday(day) && upcomingMeets.length > 0) {
                              upcomingDay = true
                            }
                            if (previousMeets.length > 0 && !isToday(day) && !upcomingDay) {
                              previousDay = true
                            }
                            return (
                              <Tooltip
                                title={(upcomingMeets.length > 0 || previousMeets.length > 0) ? <ul>
                                  {upcomingMeets.length > 0 && <li>{upcomingMeets.length} {TooltipText.upcomingDay}</li>}
                                  {previousMeets.length > 0 && <li>{previousMeets.length} {TooltipText.previousDay}</li>}
                                </ul> : isToday(day) ? <>{TooltipText.presentDay}</> : ''}>
                                <PickersDay
                                  {...pickersDayProps}
                                  today={isToday(day)}
                                  {...(previousDay && {
                                    sx: Previous_Day_Style,
                                  })}
                                  {...(upcomingDay && {
                                    sx: Upcoming_Day_Style,
                                  })}
                                />
                              </Tooltip>
                            );
                          }}
                        />
                      </LocalizationProvider>}
                    </div>
                  </div>
                  {/* Upcoming Meetings block */}
                  <div className="dashboard-inner px-3 pb-8 mobile-order3 has-slider">
                    <div className={`${isPlanExpired && 'blur'} dashboard-widget relative py-8 pl-9 pr-0 rounded-[32px] h-full bg-[#ffffff]`}>
                      <div className='font-semibold text-lg leading-7 text-[#111D43] pb-4'>Upcoming <span className='text-base'>{upcomingMeetings?.length !== 0 && `(${upcomingMeetings?.length})`}</span></div>
                      <UpcomingMeeting upcomingMeetings={upcomingMeetings} setOpenRescheduleModal={setOpenRescheduleModal} setOpenCancelModal={setOpenCancelModal} selectedMeetingId={selectedMeetingId} setSelectedMeetingId={setSelectedMeetingId} />
                      <div className='text-right absolute bottom-0 left-0 pl-4 py-4'>
                        <span className='text-xs ml-2'>Note: All times is in UTC Timezone</span>
                      </div>
                    </div>
                  </div>
                  {/* Metrics block */}
                  <div className="dashboard-inner px-3 mobile-order2 h-96 sm:h-auto box-border">
                    <div className={`${isPlanExpired && 'blur'} dashboard-widget py-8 px-9 box-border rounded-[32px] h-full bg-[#ffffff] relative`}>
                      <div className='font-semibold text-lg leading-7 text-[#111D43]'>Metrics</div>
                      <div className="metrics-block-wrapper flex flex-wrap flex-1 h-full">
                        <div className="metrics-outer px-5 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
                          <div className="metrics-inner xl:w-[124px] xl:h-[124px] w-[124px] h-[124px] rounded-full bg-[#ffd70066] inline-flex items-center justify-center flex-col">
                            <span className="text-2xl font-semibold text-[#111D43] capitalize">{metrics?.rank || ""}</span>
                            <p className="text-xs text-[#111D43] mt-1">Click Rank</p>
                          </div>
                        </div>
                        <div className="metrics-outer px-5 absolute top-3/4 left-1/3 -translate-x-1/2 -translate-y-1/2">
                          <div className="metrics-inner xl:w-[124px] xl:h-[124px] w-[124px] h-[124px] rounded-full bg-[#E7E8EC] inline-flex items-center justify-center flex-col">
                            <span className="text-2xl font-semibold text-[#111D43]">{metrics?.completedMeetings || 0}</span>
                            <p className="text-xs text-[#111D43] text-center mt-1">Completed Meetings</p>
                          </div>
                        </div>
                        <div className="metrics-outer px-5 absolute top-3/4 left-2/3 -translate-x-1/2 -translate-y-1/2">
                          <div className="metrics-inner xl:w-[124px] xl:h-[124px] w-[124px] h-[124px] rounded-full bg-[#E7E8EC] inline-flex items-center justify-center flex-col">
                            <span className="text-lg font-semibold text-[#111D43] text-center">{metrics?.region || ""}</span>
                            <p className="text-xs text-[#111D43] text-center mt-1">Region of<br />Interest</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Previous meetings block */}
                  <div className="dashboard-inner px-3 mobile-order4 has-slider">
                    <div className={`${isPlanExpired && 'blur'} dashboard-widget relative py-8 pl-9 pr-0 rounded-[32px] h-full bg-[#ffffff]`}>
                      <div className='font-semibold text-lg leading-7 text-[#111D43] pb-4'>Previous <span className='text-base'>{previousMeetings?.length !== 0 && `(${previousMeetings?.length})`}</span></div>
                      <PreviousMeetings previousMeetings={previousMeetings} />
                      <div className='text-right absolute bottom-0 left-0 pl-4 py-4'>
                        <span className='text-xs ml-2'>Note: All times is in UTC Timezone</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* News feed block */}
              <div className='dashboard-right-block px-3'>
                <div className={`${isPlanExpired && 'blur'} dashboard-widget h-full py-8 px-9 rounded-[32px] bg-[#ffffff]`}>
                  <div className='font-semibold text-lg leading-7 text-[#111D43] pb-4'>Newsfeed</div>
                  {newsfeeds?.length !== 0 &&
                    <div className="news-feed-wrapper">
                      {newsfeeds.map(newsfeed => {
                        return <NewsfeedCard title={newsfeed.title} createdAt={newsfeed.createdAt} description={newsfeed.description} url={newsfeed.redirectionURL} image={newsfeed.image} />
                      })}
                    </div>}
                </div>
              </div>
            </div>
            <div className='font-normal leading-tight not-italic pt-7 px-3 text-[10px] text-[#70778E]'>Connect by PTC is a company by The Proptech Connection © Copyright 2022. Please see our Privacy Policy <Link href={planTexts.privacyPolicyURL} target="_blank" className="text-[#70778E]">here</Link>.</div>
          </div>
        </div>
      </div>

      {/* Exired plan modal */}
      {isPlanExpired && <div className='bg-black bg-opacity-50  box-border h-full p-5 flex flex-col justify-center items-center fixed top-0 left-0 w-full z-[98] preview-screen'>
        <div className='bg-grey rounded-xl flex flex-col relative overflow-hidden'>
          <div className='w-full h-full p-10  flex flex-col justify-center items-center proptech-detail-wrapper overflow-y-auto md:overflow-inherit'>
            <span>{planTexts.upgradePlan}</span>
            <div className="mt-5 proptech-stepper">
              <div className="flex justify-end">
                <PTButton variant="contained" label="Purchase"
                  className="!h-12 !w-32 !rounded-xl !border-solid !bg-[#111D43] !font-['Poppins'] disabled:text-[#888] disabled:bg-[#DDD]/[0.6]"
                  handleOnClickEvent={() => setOpenChangePlanModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>}

      {/* change password modal */}
      {openModal && <ChangePassword
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSuccess={changePassword}
        cancelButtonLabel="Cancel"
        cancelButtonStyle="modal-cancel-button"
        changeButtonLabel="Change"
        changeButtonStyle='modal-change-button' />}

      {/* manage users modal */}
      {openManageUsersModal && <ManageUsers
        isPrimaryUser={userData?.isPrimaryUser || false}
        isLoading={isLoading}
        title={MANAGEUSERS_MODAL_HEADER}
        open={openManageUsersModal}
        handleClose={() => {
          setOpenManageUsersModal(false)
          setUsers(null)
        }}
        setIsLoading={setIsLoading}
        getUsers={getUsers}
        handleUpdateStatus={handleUpdateStatus}
        users={users} />}

      {/* View Profile */}
      {openProfile && <PreviewProfile
        closeIconClick={() => setOpenProfile(false)}
      />}

      {/* manage reschedule meeting request modal */}
      {openRescheduleModal && <RescheduleMeeting
        openRescheduleModal={openRescheduleModal}
        handleCloseRescheduleMeetingModal={handleCloseRescheduleMeetingModal}
        rescheduleTime={rescheduleTime}
        setRescheduleTime={setRescheduleTime}
        rescheduleMeetingRequest={rescheduleMeetingRequest}
      />}

      {/* manage cancel meeting modal */}
      {openCancelModal && <CancelMeetingModal
        openCancelModal={openCancelModal}
        handleCloseCancelMeetingModal={handleCloseCancelMeetingModal}
        cancelMeeting={cancelMeeting}
      />}

      {/* Change Plan modal */}
      {openChangePlanModal && <ChangePlanModal
        openChangePlanModal={openChangePlanModal}
        closeChangePlanModal={closeChangePlanModal}
        plans={plans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        termAndConditions={termAndConditions}
        setTermAndConditions={setTermAndConditions}
        handleChange={changePlan} />}

      {/* Change paymrnt Method */}
      {openChangePaymentMethodModal && <PaymentDetails
        openModal={openChangePaymentMethodModal}
        loading={activePaymentMethodLoading}
        activePaymentDetails={activePaymentData}
        closeModal={closeChangePaymentMethodModal}
        cardDetails={cardDetails}
        setCardDetails={setCardDetails}
        handleSubmit={changePaymentMethod}
      />}
    </>
  )
}
