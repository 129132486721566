import { compareArraysOfObjects } from "../dtos/projectTabs";
import { loadCompanyProfileTabData } from "./companyProfileTab";
import { loadCompanyTabData } from "./companyTab";
import { loadDeployAndUseTabData } from "./deployAndUseTab";
import { loadDesignReviewTabData } from "./designReviewTab";
import { loadMarketInsightsTabData } from "./marketInsightsTab";
import { loadMarketTabData, } from "./marketTab";
import { loadNumbersTabData, } from "./numbersTab";
import { loadOverviewTabData, } from "./overviewTab";
import { loadRaiseOverviewTabData, } from "./raiseOverviewTab";
import { loadRaiseTabData, } from "./raiseTab";
import { loadTeamsTabData, } from "./teamTab";

export function setActiveTabData(tab, id, adminAnalysisTab, adminDropdowns, radarDetails) {
  switch (tab) {
    case 0: loadCompanyTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 1: loadOverviewTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 2: loadMarketTabData(id, adminAnalysisTab, adminDropdowns, radarDetails);
      break;

    case 3: loadNumbersTabData(id, adminAnalysisTab, adminDropdowns, radarDetails);
      break;

    case 4: loadRaiseTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 5: loadTeamsTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 6: loadRaiseOverviewTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 7: loadDesignReviewTabData(id, adminAnalysisTab, radarDetails)
      break;

    case 8: loadMarketInsightsTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 9: loadCompanyProfileTabData(id, adminAnalysisTab, adminDropdowns);
      break;

    case 10: loadDeployAndUseTabData(id, adminAnalysisTab, adminDropdowns, radarDetails);
      break;

    default: loadCompanyTabData(id, adminAnalysisTab, adminDropdowns);
      break;
  }
}


export const isUnsaveChanges = (data, setUnsaveChange, radars1, radars2) => {
  let unsave = data && Object.entries(data)
    .filter(([, value]) => value !== null)?.length

    if(radars1?.length && radars2?.length) {
      let arr1 = radars1?.map(i => ({id: i.id, value: Number(i.value)}))?.sort((a, b) => a.id - b.id);
      let arr2 = radars2?.map(i => ({id: i?.placeRadarPoint?.id, value: Number(i.value)}))?.sort((a, b) => a.id - b.id);
      let result = compareArraysOfObjects(arr1, arr2)
      if(result) {
        setUnsaveChange(!!result ? 1 : 0);
        return
      }
    }

  setUnsaveChange(!!unsave)
}